import React, { useState }from 'react';
import DashboardIcon from '@mui/icons-material/Dashboard';
import FeedIcon from '@mui/icons-material/Feed';
import SettingsIcon from '@mui/icons-material/Settings';
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next"
import { Link } from 'react-router-dom';
import { Navigate } from 'react-router-dom'; 
import { ToastContainer } from 'react-toastify'; 
import { TodayIs } from 'components/utils/TodayIs';
import ImportScript from 'components/utils/ImportScript';
import Footer from "pages/main/Footer";
import Alerts from 'pages/alerts/Alerts';
import Messages from 'pages/messages/Messages';
import LogoutIcon from '@mui/icons-material/Logout';
import ColorSettings  from "pages/settings/ColorSettings";
import TypesOfTaxes from "pages/settings/TypesOfTaxesSettings"
import CodeLerSettings from "pages/settings/CodeLerSettings";
import ArticlesFilter from 'pages/articles/articlesFilterList/ArticleFilter'

const initialFiltersArticle= {
    "idFrom":"",
    "idTo":"",
    "codeFrom":"",
    "codeTo":"",
    "nameesFrom":"",
    "nameesTo":"",
    "nameenFrom":"",
    "nameenTo":"",
    "enabled":true,
    "notenabled":true,
    "typeinside":true,
    "typeoutside":true,
    "ticketweight":true,
    "tickenotweight":true,
    "typesoftaxFromES":"",
    "typesoftaxToES":"",
    "typesoftaxFromEN":"",
    "typesoftaxToEN":"",
    "codelerFrom":"",
    "codelerTo":""  
};

const HomeArticle = (typeList) => {
    const {t,i18n} = useTranslation(['listDataArticles']);
    const enabled = useSelector((state) => state.loginUser.enabled);
    const roles = useSelector((state) => state.loginUser.roles);  
    const defaultStore = useSelector((state) => state.loginUser.defaultStore)
    const [applyFiltersArticle, setApplyFiltersArticle]= useState(initialFiltersArticle);
    const [showModalGeneralSettings, setShowModalGeneralSettings] = useState(false);
    const [indexShowSettings,setIndexShowSettings]=useState(0);
    const [showModalColorSettings, setShowModalColorSettings] = useState(false);
    const [showModalTypesOfTaxes, setShowModalTypesOfTaxes] = useState(false);
    const [showModalCodeLerSettings, setShowModalCodeLerSettings] = useState(false);
    const [showModalEmployeeSettings, setShowModalEmployeeSettings] = useState(false); 
    const [showModalStoreSettings, setShowModalStoreSettings] = useState(false); 
    const [showModalFormSettings, setShowModalFormSettings] = useState(false);  
    const [showModalTicketsSettings, setShowModalTicketsSettings] = useState(false); 
    const [showModalContractSettings, setShowModalContractSettings] = useState(false); 
    const [showModalArticlesSettings, setShowModalArticlesSettings] = useState(true); 
    const [showModalPurchasesDeliverySettings, setShowModalPurchasesDeliverySettings] = useState(false); 
    const [showModalRegisterBookSettings, setShowModalRegisterBookSettings] = useState(false); 
   
    const [typeOfList] = useState(typeList.typeList);

    const scriptUrl = '/js/sb-admin-2.min.js'; 
   
    function changeSelectedLanguage(){
        i18n.language==="es"?i18n.changeLanguage("en"):i18n.changeLanguage("es");
        i18n.language==="es"?
        setApplyFiltersArticle(applyFiltersArticle => ({
            ...applyFiltersArticle,
            "typesoftaxFromEN":"",
            "typesoftaxToEN":""
            

          })):
        setApplyFiltersArticle(applyFiltersArticle => ({
            ...applyFiltersArticle,
            "typesoftaxFromES":"",
            "typesoftaxToES":"" 
        }));
    };
 

    const closeModalArticles = () => {    
        setApplyFiltersArticle(initialFiltersArticle);
        setShowModalGeneralSettings(false);
        setIndexShowSettings(0);
        setIndexShowSettings(indexShowSettings);
        setShowModalColorSettings(false);
        setShowModalTypesOfTaxes(false)
        setShowModalCodeLerSettings(false);
        setShowModalEmployeeSettings(showModalEmployeeSettings);
        setShowModalStoreSettings(showModalStoreSettings);
        setShowModalFormSettings(showModalFormSettings);
        setShowModalTicketsSettings(showModalTicketsSettings);
        setShowModalContractSettings(showModalContractSettings);
        setShowModalArticlesSettings(showModalArticlesSettings);
        setShowModalRegisterBookSettings(showModalRegisterBookSettings);
        setShowModalPurchasesDeliverySettings(showModalPurchasesDeliverySettings);
       
        return () => {};
    };
   
    function openModaColorsSettings(){
        setIndexShowSettings(1);
        setIndexShowSettings(indexShowSettings);
        setShowModalColorSettings(true);
        setShowModalTypesOfTaxes(false)
        setShowModalCodeLerSettings(false);
        setShowModalEmployeeSettings(false);
        setShowModalStoreSettings(false);
        setShowModalFormSettings(false);
        setShowModalTicketsSettings(false);
        setShowModalContractSettings(false);
        setShowModalArticlesSettings(false);
        setShowModalPurchasesDeliverySettings(false);
        setShowModalRegisterBookSettings(false);
        setShowModalGeneralSettings(showModalGeneralSettings);
    }

    function openModaTypesOfTaxesSettings(){
        setIndexShowSettings(8);
        setIndexShowSettings(indexShowSettings);
        setShowModalColorSettings(false);
        setShowModalTypesOfTaxes(true)
        setShowModalCodeLerSettings(false);
        setShowModalEmployeeSettings(false);
        setShowModalStoreSettings(false);
        setShowModalFormSettings(false);
        setShowModalTicketsSettings(false);
        setShowModalContractSettings(false);
        setShowModalArticlesSettings(false);
        setShowModalPurchasesDeliverySettings(false);
        setShowModalRegisterBookSettings(false);
        setShowModalGeneralSettings(showModalGeneralSettings);
    }

    function openModaCodeLerSettings(){
        setIndexShowSettings(8);
        setIndexShowSettings(indexShowSettings);
        setShowModalColorSettings(false);
        setShowModalTypesOfTaxes(false)
        setShowModalCodeLerSettings(true);
        setShowModalEmployeeSettings(false);
        setShowModalStoreSettings(false);
        setShowModalFormSettings(false);
        setShowModalTicketsSettings(false);
        setShowModalContractSettings(false);
        setShowModalArticlesSettings(false);
        setShowModalRegisterBookSettings(false);
        setShowModalPurchasesDeliverySettings(false);
        setShowModalGeneralSettings(showModalGeneralSettings);
    }
    
    return (
    <>
        <ImportScript resourceUrl={scriptUrl} />
        <ToastContainer/>
        {enabled===false ? <Navigate to="/"></Navigate>:
        <div id="page-top">
           
            {/* Page Wrapper */}
            <div id="wrapper">

                {/* Sidebar */}
                <ul className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="accordionSidebar">

                    {/* Sidebar - Brand */}
                    <Link className="sidebar-brand d-flex align-items-center justify-content-center" to={typeOfList==="1"?"/home":"/purchasedelivery"}>
                        <div className="sidebar-brand-icon rotate-n-15">
                            <DashboardIcon/>
                        </div>
                        <div className="sidebar-brand-text mx-3">{t('Text_Title')}</div>
                    </Link>

                    {/* Divider */}
                    <hr className="sidebar-divider my-0"/>

                    {/* Nav Item - Dashboard */}
                    <li className="nav-item active">
                        <div className="nav-link">
                            <i className="fas fa-fw fa-tachometer-alt"></i>
                            <span>{t('Menu_Main')}</span></div>
                    </li>

                    {/* Divider */}
                    <hr className="sidebar-divider"/>

                    {/* Sidebar - INFORMATION FILTROS APLICADOS */}
                    <li className="nav-item active">
                        <Link className="nav-link" to={typeOfList==="1"?"/article":"/articlepurchase"} >
                            <FeedIcon/>
                            <span> {t('Text_Information')}</span>
                        </Link>
                    </li>
                    <div className={applyFiltersArticle.idFrom===''?"sidebar-heading":"sidebar-heading text-white"}>   
                        {t('Text_Filter_Id_From')}
                    </div>
                    <li className={applyFiltersArticle.idFrom===''?"sidebar-heading text-warning":"sidebar-heading text-warning"}>
                        { applyFiltersArticle.idFrom==='' ? t('Text_Filter_Not_Apply'): applyFiltersArticle.idFrom}
                    </li>
                    <div className={applyFiltersArticle.idTo===''?"sidebar-heading":"sidebar-heading text-white"}>   
                        {t('Text_Filter_Id_To')}
                    </div>
                    <li className={applyFiltersArticle.idTo===''?"sidebar-heading text-warning":"sidebar-heading text-warning"}>
                        { applyFiltersArticle.idTo==='' ? t('Text_Filter_Not_Apply'): applyFiltersArticle.idTo}
                    </li> 
                    <div className={applyFiltersArticle.codeFrom===''?"sidebar-heading":"sidebar-heading text-white"}>   
                        {t('Text_Filter_Code_From')}
                    </div>
                    <li className={applyFiltersArticle.codeFrom===''?"sidebar-heading text-warning":"sidebar-heading text-warning"}>
                        { applyFiltersArticle.codeFrom==='' ? t('Text_Filter_Not_Apply'): applyFiltersArticle.codeFrom}
                    </li>
                    <div className={applyFiltersArticle.codeTo===''?"sidebar-heading":"sidebar-heading text-white"}>   
                        {t('Text_Filter_Code_To')}
                    </div>
                    <li className={applyFiltersArticle.codeTo===''?"sidebar-heading text-warning":"sidebar-heading text-warning"}>
                        { applyFiltersArticle.codeTo==='' ? t('Text_Filter_Not_Apply'): applyFiltersArticle.codeTo}
                    </li> 
                    <div className={applyFiltersArticle.nameesFrom===''?"sidebar-heading":"sidebar-heading text-white"}>   
                        {t('Text_Filter_Namees_From')}
                    </div>
                    <li className={applyFiltersArticle.nameesFrom===''?"sidebar-heading text-warning":"sidebar-heading text-warning"}>
                        { applyFiltersArticle.nameesFrom==='' ? t('Text_Filter_Not_Apply'): applyFiltersArticle.nameesFrom}
                    </li>
                    <div className={applyFiltersArticle.nameesTo===''?"sidebar-heading":"sidebar-heading text-white"}>   
                        {t('Text_Filter_Namees_To')}
                    </div>
                    <li className={applyFiltersArticle.nameesTo===''?"sidebar-heading text-warning":"sidebar-heading text-warning"}>
                        { applyFiltersArticle.nameesTo==='' ? t('Text_Filter_Not_Apply'): applyFiltersArticle.nameesTo}
                    </li>

                    <div className={applyFiltersArticle.nameenFrom===''?"sidebar-heading":"sidebar-heading text-white"}> 
                        {t('Text_Filter_Nameen_From')}
                    </div>
                    <li className={applyFiltersArticle.nameenFrom===''?"sidebar-heading text-warning":"sidebar-heading text-warning"}>
                        { applyFiltersArticle.nameenFrom==='' ? t('Text_Filter_Not_Apply'): applyFiltersArticle.nameenFrom}
                    </li>
                    <div className={applyFiltersArticle.nameenTo===''?"sidebar-heading":"sidebar-heading text-white"}> 
                        {t('Text_Filter_Nameen_To')}
                    </div>
                    <li className={applyFiltersArticle.nameenTo===''?"sidebar-heading text-warning":"sidebar-heading text-warning"}>
                        { applyFiltersArticle.nameenTo==='' ? t('Text_Filter_Not_Apply'): applyFiltersArticle.nameenTo}
                    </li> 

                    <div className={applyFiltersArticle.codelerFrom===''?"sidebar-heading":"sidebar-heading text-white"}>   
                        {t('Text_Filter_CodeLER_From')}
                    </div>
                    <li className={applyFiltersArticle.codelerFrom===''?"sidebar-heading text-warning":"sidebar-heading text-warning"}>
                        { applyFiltersArticle.codelerFrom==='' ? t('Text_Filter_Not_Apply'): applyFiltersArticle.codelerFrom}
                    </li>
                    <div className={applyFiltersArticle.codelerTo===''?"sidebar-heading":"sidebar-heading text-white"}>   
                        {t('Text_Filter_CodeLER_To')}
                    </div>
                    <li className={applyFiltersArticle.codelerTo===''?"sidebar-heading text-warning":"sidebar-heading text-warning"}>
                        { applyFiltersArticle.codelerTo==='' ? t('Text_Filter_Not_Apply'): applyFiltersArticle.codelerTo}
                    </li>

                    <li className="sidebar-heading text-warning">
                    &nbsp;
                    </li>
                    <div className={!applyFiltersArticle.typeinside?"sidebar-heading":"sidebar-heading text-white"}>
                        <i className="fas fa-solid fa-circle" style={{color:(applyFiltersArticle.typeinside)?"#32CD32":"#FFFFFF"}}></i> {t('Text_Filter_Inside')}
                    </div>
                    <div className={!applyFiltersArticle.typeoutside?"sidebar-heading":"sidebar-heading text-white"}>
                        <i className="fas fa-solid fa-circle" style={{color:(applyFiltersArticle.typeoutside)?"#32CD32":"#FFFFFF"}}></i> {t('Text_Filter_Outside')}
                    </div>
                    <div className={!applyFiltersArticle.enabled?"sidebar-heading":"sidebar-heading text-white"}>
                        <i className="fas fa-solid fa-circle" style={{color:(applyFiltersArticle.enabled)?"#32CD32":"#FFFFFF"}}></i> {t('Text_Filter_Habilty')}
                    </div>
                    <div className={!applyFiltersArticle.notenabled?"sidebar-heading":"sidebar-heading text-white"}>
                        <i className="fas fa-solid fa-circle" style={{color:(applyFiltersArticle.notenabled)?"#32CD32":"#FFFFFF"}}></i> {t('Text_Filter_Not_Habilty')}
                    </div>
                    <li className="sidebar-heading text-warning">
                    &nbsp;
                    </li>
                    {/* Divider */}
                    <hr className="sidebar-divider"/>

                    {/* Heading */}
                    {/* Nav Item - Admin Collapse Menu */}
                    {roles[0].listmenus.find((key)=>(key.name==="Menu_Settings"))&&
                        <li className="nav-item">
                            <Link className="nav-link collapsed" to={typeOfList==="1"?"/article":"/articlepurchase"} data-toggle="collapse" data-target="#collapseAdmin"
                                aria-expanded="true" aria-controls="collapseAdmin">
                                <SettingsIcon/>
                                <span>{t('Menu_Settings')}</span>
                            </Link>
                            <div id="collapseAdmin" className="collapse" aria-labelledby="headingAdmin"
                                data-parent="#accordionSidebar">
                                <div className="bg-white py-2 collapse-inner rounded">
                                    <h6 className="collapse-header">{t('Custom_Menu_Settings')}</h6>
                                    
                                    {roles[0].listmenus.find((key)=>(key.name==="SubMenu_Settings_Colors"))&&
                                    <Link className="collapse-item"  to={typeOfList==="1"?"/article":"/articlepurchase"} onClick={openModaColorsSettings}>{t('SubMenu_Settings_Type_Colors')} </Link> }
                                     
                                    {roles[0].listmenus.find((key)=>(key.name==="SubMenu_Settings_TypesOfTax"))&&
                                    <Link className="collapse-item"  to={typeOfList==="1"?"/article":"/articlepurchase"} onClick={openModaTypesOfTaxesSettings}>{t('SubMenu_Settings_Type_Taxes')} </Link> }
                                     
                                    {roles[0].listmenus.find((key)=>(key.name==="SubMenu_Settings_CodeLers"))&&
                                    <Link className="collapse-item"  to={typeOfList==="1"?"/article":"/articlepurchase"} onClick={openModaCodeLerSettings}>{t('SubMenu_Settings_Type_CodeLers')} </Link>}
                                 
                                    <Link className="collapse-item" to={typeOfList==="1"?"/article":"/articlepurchase"} onClick={changeSelectedLanguage}>{i18n.language==="es"?t('SubMenu_Settings_ChangeToEnglish'):t('SubMenu_Settings_ChangeToSpanish')}</Link>
                                   
                                </div>
                            </div>
                        </li>
                    }
                    {/* Nav Item - Charts */}                  
                    <li className="nav-item">
                        <Link className="nav-link" to={typeOfList==="1"?"/home":"/purchasedelivery"} onClick={closeModalArticles}>
                            <LogoutIcon/>
                            <span>{t('Button_Back')}</span>
                        </Link>
                    </li>

                    {/* Divider */}
                    <hr className="sidebar-divider d-none d-md-block"/>

                    {/* Sidebar Toggler (Sidebar) */}
                    <div className="text-center d-none d-md-inline">
                        <button className="rounded-circle border-0" id="sidebarToggle"></button>
                    </div>

          
                </ul>
                {/* End of Sidebar */}

                {/* Content Wrapper */}
                <div id="content-wrapper" className="d-flex flex-column">

                {/* Main Content */}
                <div id="content">

                    {/* Topbar  */}
                    <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">

                        {/* Sidebar Toggle (Topbar)  */}
                        <button id="sidebarToggleTop" className="btn btn-link d-md-none rounded-circle mr-3">
                            <i className="fa fa-bars"></i>
                        </button>
                        
                        {/* Topbar Navbar */}
                        <ul className="navbar-nav ml-auto">
                            <div className="topbar-divider d-none d-sm-block"></div>
                                <div className="text-center">
                                    <br/>
                                    {t('Text_StoreSelected')}&nbsp;{ defaultStore.tradename===null ? t('Text_StoreEmpty'):defaultStore.tradename }
                                </div>   
                            <div className="topbar-divider d-none d-sm-block"></div>
                            {/* Nav Item - Alerts */}
                            <Alerts/>
                            {/* Nav Item - Messages */}
                            <Messages/>
                            <div className="topbar-divider d-none d-sm-block"></div>
                            {/* Nav Item - User Information */}
                            <li className="nav-item dropdown no-arrow mx-1">
                                <br/>
                                <span className="mr-2 d-none d-lg-inline text-gray-600 small">
                                    <TodayIs/>  
                                </span>
                            </li>
                        </ul>
                    </nav>
                    {/* End of Topbar */}

                {/* Show Modals */}  
                <ArticlesFilter 
                    applyFiltersArticle={applyFiltersArticle}
                    setApplyFiltersArticle={setApplyFiltersArticle}
                    showModalArticlesSettings={showModalArticlesSettings}
                    setShowModalArticlesSettings={setShowModalArticlesSettings}  
                    typeOfList={typeOfList}
                />
                <ColorSettings  
                    showModalColorSettings={showModalColorSettings}
                    setShowModalColorSettings={setShowModalColorSettings}
                    setIndexShowSettings={setIndexShowSettings}
                    setShowModalGeneralSettings={setShowModalGeneralSettings}
                    setShowModalEmployeeSettings={setShowModalEmployeeSettings}
                    isEmployee={false}
                    setShowModalStoreSettings={setShowModalStoreSettings}
                    isStore={false}
                    setShowModalArticlesSettings={setShowModalArticlesSettings}
                    isArticle={true}
                /> 
                <TypesOfTaxes  
                    showModalTypesOfTaxes={showModalTypesOfTaxes}
                    setShowModalTypesOfTaxes={setShowModalTypesOfTaxes} 
                    setIndexShowSettings={setIndexShowSettings} 
                    setShowModalGeneralSettings={setShowModalGeneralSettings} 
                    setShowModalArticlesSettings={setShowModalArticlesSettings}
                    isArticle={true}  
                />
                <CodeLerSettings  
                    showModalCodeLerSettings={showModalCodeLerSettings}
                    setShowModalCodeLerSettings={setShowModalCodeLerSettings} 
                    setIndexShowSettings={setIndexShowSettings} 
                    setShowModalGeneralSettings={setShowModalGeneralSettings} 
                    setShowModalEmployeeSettings={setShowModalEmployeeSettings}
                    isEmployee={false} 
                    setShowModalStoreSettings={setShowModalStoreSettings}
                    isStore={false} 
                    setShowModalFormSettings={setShowModalFormSettings}
                    isForm={false}
                    setShowModalContractSettings={setShowModalContractSettings}
                    isContract={false}
                    setShowModalTicketsSettings={setShowModalTicketsSettings}
                    isWweigVehicles={false}
                    setShowModalArticlesSettings={setShowModalArticlesSettings}
                    isArticle={true}
                    setShowModalPurchasesDeliverySettings={setShowModalPurchasesDeliverySettings}
                    isPurchasesDelivery={false}
                    setShowModalRegisterBookSettings={setShowModalRegisterBookSettings}
                    isRegisterBook={false}
                />
                 
                </div>
                {/* End of Main Content */}

                {/* Footer */}
                <Footer/>
                {/* End of Footer */}

            </div>
            {/* End of Content Wrapper */}

          </div>
          {/* End of Page Wrapper */}
         
      </div>}
      {/* End of Body */}

    </>
  )
};

export default HomeArticle;
