import React, {  useEffect, useState, useCallback } from 'react';
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux"; 
import { useTranslation } from "react-i18next" 
import { useNavigate } from 'react-router-dom';
import { NumericFormat } from 'react-number-format';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton'; 
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Box } from '@mui/material'; 
import { TextField } from '@mui/material';
import { Typography} from '@mui/material'; 
import { Checkbox } from '@mui/material'; 
import "css/generalSettings.css";
import { successErrorAction,warningErrorAction } from "actions/ErrorActions"; 
import { helpHttp } from "service/HelpHttp";
import { Loading } from "components/utils/Loading";
import PropTypes from 'prop-types';
 
const initialStateArticle = {
  "id" : "",
  "code" : "",
  "namees" : "",
  "nameen" : "",
  "enabled" : true,
  "saleprice" : 0,
  "modifystock" : false,
  "allownegative" :true,
  "minimumstock" : 0,
  "maximumstock" : 0,
  "actualstock" : 0,
  "typeinside" :true,
  "typeoutside" :false,
  "ticketweight":true,
  "color_id" : 0,
  "color_codehex":"",
  "typesoftax_id" : 1,
  "codeler_id" : 0
};

const initialTypesOfTaxesToSelect = [{
  "id": "",
  "namees": "",
  "nameen": ""
}];

const initialCodeLERToSelect = [{
  "id": "",
  "code":"",
  "namees": "",
  "nameen": ""
}];
 
const initialColorsToSelect = [{
  "id":0,
  "namees":"",
  "nameen":"",
  "codehex":""
}];
 

const ArticleEditOrCreate = ({id, showModalArticlesSettings,typeOfList}) => {
  const {t,i18n} = useTranslation(['listDataArticles']);
  const token = useSelector((state) => state.loginUser.token); 
  const roles = useSelector((state) => state.loginUser.roles);  
  const ENDPOINT = useSelector((state) => state.variablesUser.ENDPOINT); 
  const DEFAULTCOLOR = useSelector((state) => state.variablesUser.DEFAULTCOLOR); 
  const [formArticle, setFormArticle] = useState(initialStateArticle);
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [isLoadingErrorData, setIsLoadingErrorData] = useState(false);
  const [errorsArticle, setErrorsArticle] = useState({});
  const [foundCodeArticle, setFoundCodeArticle ]= useState(false);
  const [colorsToSelect,setColorsToSelect]= useState(initialColorsToSelect);
  const [typesOfTaxesToSelect,setTypesOfTaxesToSelect]= useState(initialTypesOfTaxesToSelect);
  const [codeLERToSelect,setCodeLERToSelect]= useState(initialCodeLERToSelect);
  const dispatch = useDispatch();
  const navigate = useNavigate();  

  const keyPress = useCallback(
    e => {
      if (e.key === 'Escape' && showModalArticlesSettings) {
        setFormArticle(initialStateArticle);
        setIsLoadingData(false);
        setIsLoadingErrorData(false);
        setErrorsArticle({});
        setFoundCodeArticle(false);
        setColorsToSelect(initialColorsToSelect);
        setTypesOfTaxesToSelect(initialTypesOfTaxesToSelect);
        setCodeLERToSelect(initialCodeLERToSelect);
        navigate(typeOfList==="1"?"/article":"/articlepurchase"); 
      }
    },
    [
      setFormArticle,
      setIsLoadingData,
      setIsLoadingErrorData,
      setErrorsArticle,
      setFoundCodeArticle,
      setColorsToSelect,
      setTypesOfTaxesToSelect,
      setCodeLERToSelect,
      showModalArticlesSettings,
      typeOfList,
      navigate]
  );
  
  const handleCloseDataEditArticle = () => {
    setFormArticle(initialStateArticle);
    setIsLoadingData(false);
    setIsLoadingErrorData(false);
    setErrorsArticle({});
    setFoundCodeArticle(false);
    setColorsToSelect(initialColorsToSelect);
    setTypesOfTaxesToSelect(initialTypesOfTaxesToSelect);
    setCodeLERToSelect(initialCodeLERToSelect);
    navigate(typeOfList==="1"?"/article":"/articlepurchase"); 
	};

  const handleChangeEnabledArticle = (e) => {
    setFormArticle(formArticle => ({
        ...formArticle,
        enabled: !formArticle.enabled
    }));
  };

  const handleChangeDataEditArticle = (e) => {
    const { name, value } = e.target;
    setFormArticle(formArticle => ({
      ...formArticle,
      [name]: value,
    }));  
  };

  const withValueCap = (inputObj) => {
    const { value } = inputObj;
    if (value <= 100000000) return true;
    return false;
  };
  
  const handleBlurDataEditArticle = (e) => {
    handleChangeDataEditArticle(e);
    setErrorsArticle(validateformArticle());
  };

  const handleChangeDataEditFormAmount = (e) => {
    const { name, value } = e.target;
    let newValue=value!==null?Number((value.toLocaleString()).replaceAll(",","").replaceAll(" €","")):0;
    setFormArticle(formArticle => ({
      ...formArticle,
      [name]: newValue,
    }));
  };

  const handleBlurDataEditFormAmount = (e) => {
    handleChangeDataEditFormAmount(e);
    setErrorsArticle(validateformArticle());
  };

  const handleChangeEnabledOptions = (e) => {
    const { name,checked } = e.target;
    setFormArticle(formArticle => ({
      ...formArticle,
      [name]: checked
    }));
  };

  const handleChangeSelectColor = (event) => {
    let colorHex="#FFFFFF";
    if (event.target.value!==""){
      colorHex=colorsToSelect.find((key=>key.id===Number(event.target.value))).codehex;
    }

    setFormArticle(formArticle => ({
        ...formArticle,
        color_id:event.target.value,
        color_codehex:colorHex
    }));
  };

  const handleBlurSelectColor = (e) => {
    handleChangeSelectColor(e);
    setErrorsArticle(validateformArticle());
  };

  const copyData = (e) => {
    let idx=codeLERToSelect.find((key)=>(key.id===formArticle.codeler_id));
    setFormArticle(formArticle => ({
      ...formArticle,
      namees: idx.namees,
      nameen: idx.nameen
    }));
  };
  
  const validateformArticle = () => {
    let errorsArticle = {}; 
    let regexInvalidCharacters = /[\\!\\$\\%\\&\\|\\<\\>\\?\\{\\}\\~\\"\\']+/;

    if (!formArticle.code || formArticle.code==="") {
      errorsArticle.code = 'Text_FieldCodeRequired';
    }else {
      if (regexInvalidCharacters.test(formArticle.code)) {
        errorsArticle.code = 'Text_TextNoValid';
      }else{
        if (foundCodeArticle){
          errorsArticle.code='Text_FieldCodeExist';
        }
      }
    } 
    if (!formArticle.namees || formArticle.namees==="") {
      errorsArticle.namees = 'Text_FieldCodeRequired'; 
    } else {
      if (regexInvalidCharacters.test(formArticle.namees)) {
        errorsArticle.namees = 'Text_TextNoValid';
      }
    } 
    
    if (!formArticle.nameen || formArticle.nameen==="") {
      errorsArticle.nameen = 'Text_FieldNameenRequired'; 
    } else {
      if (regexInvalidCharacters.test(formArticle.nameen)) {
        errorsArticle.nameen = 'Text_TextNoValid';
      }
    }  
    if (formArticle.color_id===undefined || formArticle.color_id===null ||formArticle.color_id==="" || formArticle.color_id===0 || formArticle.color_id==="0") {
      errorsArticle.color_id = 'Text_FieldColorRequired';
    }

    if (formArticle.typesoftax_id===undefined || formArticle.typesoftax_id===null ||formArticle.typesoftax_id==="" || formArticle.typesoftax_id===0 || formArticle.typesoftax_id==="0") {
      errorsArticle.typesoftax_id = 'Text_FieldTypeOfTaxesRequired';
    } 

    return errorsArticle;
  };

  const handleSubmitDataEditArticle = () => {
    const errors = validateformArticle();
    setErrorsArticle(errors);
    let isMounted = true;
    if ((Object.keys(errors).length === 0) && formArticle.code!==''  && formArticle.namees!==''  
      && formArticle.color_id!=='' && formArticle.color_id!=='0' && formArticle.color_id!==0
        && formArticle.typesoftax_id!=='' && formArticle.typesoftax_id!=='0' && formArticle.typesoftax_id!==0) {
      const fetchData = async () => {
        setIsLoadingData(true);
        try {
          const getData = id !== "0"
            ? await helpHttp().put(ENDPOINT, `article/updatearticle`, token, formArticle)
            : await helpHttp().post(ENDPOINT, `article/savearticle`, token, formArticle);
          if (getData.status === "OK") {
            dispatch(successErrorAction(t('Text_SaveData')));
            handleCloseDataEditArticle();
          } else {
            dispatch(warningErrorAction(t(getData.cause)));
          }
        } catch (error) {
          dispatch(warningErrorAction(t('Text_IncorrectData')));
          setIsLoadingData(false);
        }
        if (isMounted) {
          setIsLoadingData(false);
        }
      };
      fetchData();
      return () => { isMounted = false;};
    } else {
      if (errors.code) {
        dispatch(warningErrorAction(t(errors.code)));
      } else if (errors.namees) {
        dispatch(warningErrorAction(t(errors.namees)));
      } else if (errors.nameen) {
        dispatch(warningErrorAction(t(errors.nameen)));
      } else if (errors.color_id) {
        dispatch(warningErrorAction(t(errors.color_id)));
      } else if (errors.typesoftax_id) {
        dispatch(warningErrorAction(t(errors.typesoftax_id)));
      } else {
        dispatch(warningErrorAction(t('Text_IncorrectData')));
      }
    }
  };

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event && typeof event === 'object' && event.key) {
        keyPress(event);
      }
    };
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [keyPress]);

  useEffect(() => {
    let isMounted = true;
    const fetchExistCode = async () => {
      try {
        const getData = await helpHttp().get2(ENDPOINT, `article/getarticlebycode/`, token,formArticle.code);
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          setFoundCodeArticle(getData!==formArticle.id && getData>0)
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };
    if (formArticle.code!==""){
      fetchExistCode();
    }
    return () => { isMounted = false };
  }, [ENDPOINT,t,token,dispatch,i18n,formArticle.id,formArticle.code]);
 
  useEffect(() => {
    let isMounted = true;

    const fetchDataColor = async () => {
      try {
        const getData = i18n.language==="es"
        ? await helpHttp().get(ENDPOINT, `article/colorsorderbynamees`, token)
        : await  helpHttp().get(ENDPOINT,`article/colorsorderbynameen`,token);
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          setColorsToSelect(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };
  
    const fetchDataTypesOfTaxes = async () => {
      try {
        const getData = i18n.language === "es"
        ? await helpHttp().get(ENDPOINT, `article/typesoftaxesorderbynamees`, token)
        : await helpHttp().get(ENDPOINT, `article/typesoftaxesorderbynameen`, token);
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          setTypesOfTaxesToSelect(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };

    const fetchDataCodeLER = async () => {
      try {
        const getData =   await helpHttp().get(ENDPOINT, `article/codelersorderbycode`, token);
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          setCodeLERToSelect(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    }; 
    if (formArticle.id!==null ){
      fetchDataColor();
      fetchDataTypesOfTaxes();
      fetchDataCodeLER();
    }
    return () => { isMounted = false };

  }, [ENDPOINT,t,token,dispatch,i18n,roles,formArticle.id,showModalArticlesSettings]);
 
useEffect(() => {
  let isMounted = true;
  const fetchDataArticle = async () => {
    setIsLoadingData(true);
    try {
      const getData = await helpHttp().get2(ENDPOINT, `article/getarticlebyid/`, token, id);
      if (getData.err || getData.message === "Load failed") {
        if (isMounted) {
          setFormArticle(initialStateArticle);
          dispatch(warningErrorAction(t(getData.message === "Load failed" ? 'Text_ConnectionFailied' : 'Text_InvalidCredentials')));
        }
      } else if (isMounted) {
        setFormArticle(getData);
      }
    } catch (error) {
      dispatch(warningErrorAction(t('Text_IncorrectData')));
      setIsLoadingData(false); 
    }
    if (isMounted) {
      setIsLoadingData(false);
    }
    
  };
  if (id !== "0") {
    if (formArticle.id === "") {
      fetchDataArticle();
    }
  } else {
    setFormArticle(initialStateArticle);
    setFormArticle(formArticle => ({
      ...formArticle,
      color_id:Number(DEFAULTCOLOR)
    }));
    setIsLoadingData(false);
  }
  return () => { isMounted = false };

}, [ENDPOINT,dispatch,id,t,token,formArticle.id,isLoadingData,DEFAULTCOLOR]); 
  return (
    <>
      {showModalArticlesSettings ? ( 
        <div className="container-fluid">
          <Box sx={{ bgcolor: '#ffffff', width: '900px'}}>
            <form className="createarticle" onSubmit={handleCloseDataEditArticle}>  
              {!isLoadingData ?
                <>
                  <div className="row"> 
                    <div className="col-xl-12 mb-4"> 
                      <div  className={formArticle.enabled?"card border-info shadow":"card border-dark shadow"}>
                        <div className="card-body"> 
                          <div className="row no-gutters align-items-center">
                            <div className="col mr-2">
                              <div id="myDIV">
                                <h1 className={formArticle.enabled?"h2NewStyle mb-2":"h2NewStyleDark"}>
                                  {isLoadingErrorData?
                                  <i className="fas fa-solid fa-exclamation fa-2x text-danger"/>:
                                  (isLoadingData?
                                  <CircularProgress sx={{ color : "#f6c23e"}}/>:
                                  <i className={formArticle.enabled?"fas fa-regular fa-shop fa text-warning":"fas fa-regular fa-shop fa text-dark"}></i>)} 
                                  &nbsp;&nbsp;{i18n.language==='es'?formArticle.namees:formArticle.nameen}
                                </h1>
                                <div>
                                  {isLoadingData?
                                    <CircularProgress sx={{ color : "#f6c23e"}}/>:
                                      <>
                                        <button type="button" className="buttonCreate" onClick={handleSubmitDataEditArticle}>
                                          {id==="0"?t('Button_Create'):t('Button_Update')}
                                        </button><> </>
                                        <button type="button" className="buttonBack" onClick={handleCloseDataEditArticle}>{t('Button_Cancel')}</button>
                                      </>
                                  }
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">    
                    <div className="col-xl-3 mb-2"> 
                      <div  className={formArticle.enabled?"card border-info shadow":"card border-dark shadow"}>
                        <div className="card-body"> 
                          <div className="row align-items-center">
                            <div className="col mr-2">
                              <div>
                                <Typography style={{color:formArticle.enabled?"#4e73df":"#858796", fontSize: 16, fontWeight: 'bold',textDecorationLine:formArticle.enabled?'none':'line-through'}}>
                                  {t('Text_Id')}
                                </Typography>
                                <Typography  style={{color:formArticle.enabled?"#616161":"#858796", fontSize: 26, fontWeight: 'bold', textDecorationLine:formArticle.enabled?'none':'line-through'}}> 
                                  {id==="0"?t('Text_New'): formArticle.id}
                                </Typography>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {roles[0].listmenus.find((key)=>(key.name==='Handle_Articles_Hability'))?
                      <div className="col-xl-9 mb-4"> 
                        <div  className={formArticle.enabled?"card border-info shadow":"card border-dark shadow"}>
                          <div className="card-body"> 
                            <div className="row no-gutters align-items-center">
                              <div className="col mr-2">
                                <div id="myDIV">
                                  <h2 className={formArticle.enabled?"h2Enabled mb-2":"h2Disabled"}>
                                    {formArticle.enabled?t('Text_enabled_Upper'):t('Text_NOT_enabled_Upper')}
                                  </h2>
                                  <div>
                                    <button type="button" className={formArticle.enabled?"buttonEnabledArticle":"buttonDisabledArticle"} onClick={handleChangeEnabledArticle}>{formArticle.enabled?t('Button_DISABLED'):t('Button_ENABLED')}</button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      :
                      <></>
                    }
                  </div>
                  <br/>  

                  <div className="row"> 
                    <div className="col-xl-12 mb-4"> 
                      <div  className={formArticle.enabled?"card border-info shadow":"card border-dark shadow"}>
                        <div className="card-body"> 
                          <Typography sx={{ flex: '1 1 100%', color: formArticle.enabled?"#4e73df":"#858796", fontSize: 24, paddingLeft:2 }} id="tableTitle" component="div">
                            {t('Text_Data')}
                          </Typography>
                          <hr className="sidebar-divider d-none d-md-block"/>            
                          <div className="row p-2"> 
                            <div className="col-4 text-right">
                              <label className={formArticle.enabled?"text-primary":"text-secondary"}>{t('Text_Code')} :</label>
                            </div>  
                            <div className="col-8 ">
                              <TextField
                                id="code" 
                                name="code"
                                value={formArticle.code}
                                inputProps={{ style: {width: 200, textDecorationLine:formArticle.enabled?'none':'line-through'}, maxLength: 10 }}
                                label={errorsArticle.code!=null?t(errorsArticle.code):t('PlaceHolder_Code')}
                                placeholder={t('PlaceHolder_Code')}
                                variant="standard"
                                onBlur={handleBlurDataEditArticle}
                                onChange={handleChangeDataEditArticle}
                                disabled={!formArticle.enabled}
                              />
                            </div>
                          </div>
                          <div className="row p-2"> 
                            <div className="col-4 text-right">
                              <label className={formArticle.enabled?"text-primary":"text-secondary"}>{t('Text_Namees')} :</label>
                            </div>  
                            <div className="col-8 ">
                              <TextField
                                id="namees" 
                                name="namees"
                                value={formArticle.namees}
                                inputProps={{ style: {width: 500, textDecorationLine:formArticle.enabled?'none':'line-through'}, maxLength: 100 }}
                                label={errorsArticle.namees!=null?t(errorsArticle.namees):t('PlaceHolder_Namees')}
                                placeholder={t('PlaceHolder_Namees')}
                                variant="standard"
                                onBlur={handleBlurDataEditArticle}
                                onChange={handleChangeDataEditArticle}
                                disabled={!formArticle.enabled}
                              />
                            </div>
                          </div>
                          <div className="row p-2"> 
                            <div className="col-4 text-right">
                              <label className={formArticle.enabled?"text-primary":"text-secondary"}>{t('Text_Nameen')} :</label>
                            </div>  
                            <div className="col-8 ">
                              <TextField
                                id="nameen" 
                                name="nameen"
                                value={formArticle.nameen}
                                inputProps={{ style: {width: 500, textDecorationLine:formArticle.enabled?'none':'line-through'}, maxLength: 100 }}
                                label={errorsArticle.nameen!=null?t(errorsArticle.nameen):t('PlaceHolder_Nameen')}
                                placeholder={t('PlaceHolder_Nameen')}
                                variant="standard"
                                onBlur={handleBlurDataEditArticle}
                                onChange={handleChangeDataEditArticle}
                                disabled={!formArticle.enabled}
                              />
                            </div>
                          </div>
                          <div className="row p-2"> 
                            <div className="col-4 text-right">
                              <label className={formArticle.enabled?"text-primary":"text-secondary"}>{t('Text_SalePrice')} :</label>
                            </div>  
                            <div className="col-8 ">
                                <NumericFormat 
                                  thousandSeparator={''} 
                                  decimalSeparator={'.'} 
                                  decimalScale= {2}
                                  fixedDecimalScale= {true}
                                  allowNegative= {false}
                                  isAllowed={withValueCap}
                                  id="saleprice" 
                                  name="saleprice"
                                  suffix={" €"}
                                  value={formArticle.saleprice}
                                  label={errorsArticle.saleprice!=null?t(errorsArticle.saleprice):t('PlaceHolder_SalePrice')}
                                  placeholder={t('PlaceHolder_SalePrice')}
                                  style={{width: 200, textDecorationLine:formArticle.enabled?'none':'line-through'}}
                                  customInput={TextField}
                                  onBlur={handleBlurDataEditFormAmount}
                                  onChange={handleChangeDataEditFormAmount}
                                  disabled={!formArticle.enabled}
                                />
                            </div>
                          </div>
                          <div className="row p-2"> 
                            <div className="col-4 text-right">
                              <label className={formArticle.enabled?"text-primary":"text-secondary"}>{t('Text_ModifyStock')} :</label>
                            </div>  
                            <div className="col-8">
                               <Checkbox  
                                style={{color: formArticle.enabled?"#4e73df":"#858796", textDecorationLine:formArticle.enabled?'none':'line-through'}} 
                                id="modifystock"
                                name="modifystock"
                                checked={formArticle.modifystock} 
                                onChange={handleChangeEnabledOptions} 
                                disabled={!formArticle.enabled}
                              />
                            </div>
                          </div>
                          <div className="row p-2"> 
                            <div className="col-4 text-right">
                              <label className={formArticle.enabled?"text-primary":"text-secondary"}>{t('Text_AllowNegative')} :</label>
                            </div>  
                            <div className="col-8">
                               <Checkbox  
                                style={{color: formArticle.enabled?"#4e73df":"#858796", textDecorationLine:formArticle.enabled?'none':'line-through'}} 
                                id="allownegative"
                                name="allownegative"
                                checked={formArticle.allownegative} 
                                onChange={handleChangeEnabledOptions} 
                                disabled={!formArticle.enabled}
                              />
                            </div>
                          </div> 
                          <div className="row p-2"> 
                            <div className="col-4 text-right">
                              <label className={formArticle.enabled?"text-primary":"text-secondary"}>{t('Text_MinimunStock')} :</label>
                            </div>  
                            <div className="col-8 ">
                                <NumericFormat 
                                  thousandSeparator={''} 
                                  decimalSeparator={'.'} 
                                  decimalScale= {0}
                                  fixedDecimalScale= {true}
                                  allowNegative= {false}
                                  isAllowed={withValueCap}
                                  id="minimumstock" 
                                  name="minimumstock" 
                                  value={formArticle.minimumstock}
                                  label={errorsArticle.minimumstock!=null?t(errorsArticle.minimumstock):t('PlaceHolder_MinimunStock')}
                                  placeholder={t('PlaceHolder_MinimunStock')}
                                  style={{width: 200, textDecorationLine:formArticle.enabled?'none':'line-through'}}
                                  customInput={TextField}
                                  onBlur={handleBlurDataEditFormAmount}
                                  onChange={handleChangeDataEditFormAmount}
                                  disabled={!formArticle.enabled}
                                />
                            </div>
                          </div> 
                          <div className="row p-2"> 
                            <div className="col-4 text-right">
                              <label className={formArticle.enabled?"text-primary":"text-secondary"}>{t('Text_MaximumStock')} :</label>
                            </div>  
                            <div className="col-8 ">
                                <NumericFormat 
                                  thousandSeparator={''} 
                                  decimalSeparator={'.'} 
                                  decimalScale= {0}
                                  fixedDecimalScale= {true}
                                  allowNegative= {false}
                                  isAllowed={withValueCap}
                                  id="maximumstock" 
                                  name="maximumstock" 
                                  value={formArticle.maximumstock}
                                  label={errorsArticle.maximumstock!=null?t(errorsArticle.maximumstock):t('PlaceHolder_MaximumStock')}
                                  placeholder={t('PlaceHolder_MaximumStock')}
                                  style={{width: 200, textDecorationLine:formArticle.enabled?'none':'line-through'}}
                                  customInput={TextField}
                                  onBlur={handleBlurDataEditFormAmount}
                                  onChange={handleChangeDataEditFormAmount}
                                  disabled={!formArticle.enabled}
                                />
                            </div>
                          </div>
                          <div className="row p-2"> 
                            <div className="col-4 text-right">
                              <label className={formArticle.enabled?"text-primary":"text-secondary"}>{t('Text_ActualStock')} :</label>
                            </div>  
                            <div className="col-8 ">
                                <NumericFormat 
                                  thousandSeparator={''} 
                                  decimalSeparator={'.'} 
                                  decimalScale= {0}
                                  fixedDecimalScale= {true}
                                  allowNegative= {false}
                                  isAllowed={withValueCap}
                                  id="actualstock" 
                                  name="actualstock" 
                                  value={formArticle.actualstock}
                                  label={errorsArticle.actualstock!=null?t(errorsArticle.actualstock):t('PlaceHolder_ActualStock')}
                                  placeholder={t('PlaceHolder_ActualStock')}
                                  style={{width: 200, textDecorationLine:formArticle.enabled?'none':'line-through'}}
                                  customInput={TextField}
                                  onBlur={handleBlurDataEditFormAmount}
                                  onChange={handleChangeDataEditFormAmount}
                                  disabled={!formArticle.enabled}
                                />
                            </div>
                          </div>
                          <div className="row p-2"> 
                            <div className="col-4 text-right">
                              <label className={formArticle.enabled?"text-primary":"text-secondary"}>{t('Text_Filter_Inside')} :</label>
                            </div>  
                            <div className="col-8">
                               <Checkbox  
                                style={{color: formArticle.enabled?"#4e73df":"#858796", textDecorationLine:formArticle.enabled?'none':'line-through'}} 
                                id="typeinside"
                                name="typeinside"
                                checked={formArticle.typeinside} 
                                onChange={handleChangeEnabledOptions} 
                                disabled={!formArticle.enabled}
                              />
                            </div>
                          </div>
                          <div className="row p-2"> 
                            <div className="col-4 text-right">
                              <label className={formArticle.enabled?"text-primary":"text-secondary"}>{t('Text_Filter_Outside')} :</label>
                            </div>  
                            <div className="col-8">
                               <Checkbox  
                                style={{color: formArticle.enabled?"#4e73df":"#858796", textDecorationLine:formArticle.enabled?'none':'line-through'}} 
                                id="typeoutside"
                                name="typeoutside"
                                checked={formArticle.typeoutside} 
                                onChange={handleChangeEnabledOptions} 
                                disabled={!formArticle.enabled}
                              />
                            </div>
                          </div>

                          <div className="row p-2"> 
                            <div className="col-4 text-right">
                              <label className={formArticle.enabled?"text-primary":"text-secondary"}>{t('Text_Filter_Weight')} :</label>
                            </div>  
                            <div className="col-8">
                               <Checkbox  
                                style={{color: formArticle.enabled?"#4e73df":"#858796", textDecorationLine:formArticle.enabled?'none':'line-through'}} 
                                id="ticketweight"
                                name="ticketweight"
                                checked={formArticle.ticketweight} 
                                onChange={handleChangeEnabledOptions} 
                                disabled={!formArticle.enabled}
                              />
                            </div>
                          </div>

                          <div className="row p-2"> 
                            <div className="col-4 text-right">
                              <label className={formArticle.enabled?"text-primary":"text-secondary"}>{t('Text_Color')} :</label>
                            </div>  
                            <div className="col-8">
                              <TextField
                                id="color_id"
                                name="color_id"
                                value={ (formArticle.color_id === undefined || formArticle.color_id ===null || formArticle.color_id ===""
                                      || formArticle.color_id ==="0" || formArticle.color_id ===0)?Number(DEFAULTCOLOR):formArticle.color_id}
                                inputProps={{ style: {color:formArticle.enabled?formArticle.color_codehex:"#858796", width: 460, textDecorationLine:formArticle.enabled?'none':'line-through' }, maxLength: 100, }}
                                variant="outlined"
                                onBlur={handleBlurSelectColor}
                                onChange={handleChangeSelectColor}
                                disabled={!formArticle.enabled}
                                select
                                SelectProps={{
                                  native: true,
                                  value: (formArticle.color_id === undefined || formArticle.color_id ===null || formArticle.color_id ===""
                                      || formArticle.color_id ==="0" || formArticle.color_id ===0)?Number(DEFAULTCOLOR): formArticle.color_id
                                }}>
                                <option key={0} value=""/>
                                {colorsToSelect.map((option) => (
                                <option key={option.id} value={option.id}>
                                    {i18n.language==="es"?option.namees:option.nameen}
                                </option>
                                ))}
                              </TextField>
                            </div>
                          </div>
                          <div className="row p-2"> 
                            <div className="col-4 text-right">
                              <label className={formArticle.enabled?"text-primary":"text-secondary"}>{t('Text_TypeOfTaxes')} :</label>
                            </div>  
                            <div className="col-8">
                              <TextField
                                id="typesoftax_id"
                                name="typesoftax_id"
                                value={ (formArticle.typesoftax_id === undefined || formArticle.typesoftax_id ===null || formArticle.typesoftax_id ===""
                                      || formArticle.typesoftax_id ==="0" || formArticle.typesoftax_id ===0)?1:formArticle.typesoftax_id}
                                inputProps={{ style: {color:formArticle.enabled?"#000000":"#858796", width: 460, textDecorationLine:formArticle.enabled?'none':'line-through' }, maxLength: 100, }}
                                variant="outlined"
                                onBlur={handleBlurDataEditFormAmount}
                                onChange={handleChangeDataEditFormAmount}
                                disabled={!formArticle.enabled}
                                select
                                SelectProps={{
                                  native: true,
                                  value: (formArticle.typesoftax_id === undefined || formArticle.typesoftax_id ===null || formArticle.typesoftax_id ===""
                                      || formArticle.typesoftax_id ==="0" || formArticle.typesoftax_id ===0)?1: formArticle.typesoftax_id
                                }}>
                                <option key={0} value=""/>
                                {typesOfTaxesToSelect.map((option) => (
                                <option key={option.id} value={option.id}>
                                    {i18n.language==="es"?option.namees:option.nameen}
                                </option>
                                ))}
                              </TextField>
                            </div>
                          </div>

                          <div className="row p-2"> 
                            <div className="col-4 text-right">
                              <label className={formArticle.enabled?"text-primary":"text-secondary"}>{t('Text_CodeLER')} :</label>
                            </div>  
                            <div className="col-8">
                              <TextField
                                id="codeler_id"
                                name="codeler_id"
                                value={ (formArticle.codeler_id === undefined || formArticle.codeler_id ===null || formArticle.codeler_id ===""
                                      || formArticle.codeler_id ==="0" || formArticle.codeler_id ===0)?1:formArticle.codeler_id}
                                inputProps={{ style: {color:formArticle.enabled?"#000000":"#858796", width: 460, textDecorationLine:formArticle.enabled?'none':'line-through' }, maxLength: 100, }}
                                variant="outlined"
                                onBlur={handleBlurDataEditFormAmount}
                                onChange={handleChangeDataEditFormAmount}
                                disabled={!formArticle.enabled}
                                select
                                SelectProps={{
                                  native: true,
                                  value: (formArticle.codeler_id === undefined || formArticle.codeler_id ===null || formArticle.codeler_id ===""
                                      || formArticle.codeler_id ==="0" || formArticle.codeler_id ===0)?1: formArticle.codeler_id
                                }}>
                                <option key={0} value=""/>
                                {codeLERToSelect.map((option) => (
                                <option key={option.id} value={option.id}>
                                    {option.code} - {i18n.language==="es"?option.namees.substring(0,100):option.nameen.substring(0,100)}
                                </option>
                                ))}
                              </TextField>
                              &nbsp;&nbsp;
                              <IconButton  size="small"  style={{color:formArticle.enabled?"#4e73df":"#858796", fontSize: 18}} onClick={copyData}  disabled={!formArticle.enabled}>
                                <ContentCopyIcon/>
                              </IconButton>
                            </div>
                          </div>
                          
                        </div>
                      </div>
                    </div>
                  </div>
                </>:
                <Loading isLoadingData={isLoadingData}/>
              }
            </form>
          </Box>
          <br/> 
        </div>
        //* End Page Content *//
      ) :  null }
    </>
  )    
}

ArticleEditOrCreate.propTypes = {
  id: PropTypes.string.isRequired,
  showModalArticlesSettings: PropTypes.bool.isRequired,
  typeOfList: PropTypes.string.isRequired
};

export default ArticleEditOrCreate;