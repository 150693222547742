import React, {  useEffect, useState,useCallback } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CircularProgress from '@mui/material/CircularProgress';
import { SwipeableDrawer } from '@mui/material';
import { Container, Box } from '@mui/material';
import { TextField } from '@mui/material';
import { Button } from '@mui/material';
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next" 
import { useDispatch } from "react-redux";  
import "css/generalSettings.css";
import { successErrorAction,warningErrorAction } from "actions/ErrorActions";
import { helpHttp } from "service/HelpHttp";
import TableSorting from "./SortingTables";
import PropTypes from 'prop-types';

const initialFilters= {
  "idFrom":"",
  "idTo":"",
  "codeFrom":"",
  "codeTo":"",
  "nameesFrom":"",
  "nameesTo":"",
  "nameenFrom":"",
  "nameenTo":"",
  "codeHexFrom":"",
  "codeHexTo":"",
  "codeCotGroupFrom":"",
	"codeCotGroupTo":"",
  "code_iso2From":"",
  "code_iso2To":"",
  "code_iso3From":"",
  "code_iso3To":"",
  "extphoneFrom":"",
  "extphoneTo":"",
  "extinternetFrom":"",
  "extinternetTo":"",
  "isEnabled":true,
  "isNotEnabled":true
};

const initialState = [{
  "id" : "",
  "name" : ""
}];

const headCells = [
  {
    id: 'id',
    numeric: false,
    disablePadding: false,
    label: 'Text_Code',
    canOrder:true,
  },
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Text_Name',
    canOrder:true,
  }
];

const DocumentTypeSettings = ({ showDocumentTypeSettings, setShowModalDocumentTypeSettings,setIndexShowSettings,setShowModalGeneralSettings,setShowModalEmployeeSettings,isEmployee,setShowModalStoreSettings,isStore,setShowModalFormSettings,isForm,setShowModalContractSettings,isContract,setShowModalTicketsSettings,isWweigVehicles,setShowModalPurchasesDeliverySettings,isPurchasesDelivery,setShowModalShippingsSettings,isShipping}) => {
  
  const {t} = useTranslation(['listDataGeneralSettings']);
  const token = useSelector((state) => state.loginUser.token); 
  const roles = useSelector((state) => state.loginUser.roles);  
  const ENDPOINT = useSelector((state) => state.variablesUser.ENDPOINT); 
  const LIMITPAGESIZE = useSelector((state) => state.variablesUser.LIMITPAGESIZE); 
  const NON_ERASABLE_DOCUMENTTYPES_ID = useSelector((state) => state.variablesUser.NON_ERASABLE_DOCUMENTTYPES_ID); 
  const DELETE_CODE = useSelector((state) => state.formatDIUser.delete_code); 
  const [data, setData] = useState(initialState);
  const [form, setForm] = useState(initialState[0]);
  const [filters, setFilters] = useState(initialFilters);
  const [applyFilters, setApplyFilters] = useState(initialFilters);
  const [numTotReg, setNumTotReg] = useState(0);
  const [numTotRegFilter, setNumTotRegFilter] = useState(0);
  const [errorsFilters, setErrorsFilters] = useState({});
  const [errors, setErrors] = useState({});
  const [inputText, setInputText] = useState("");
  const [openCreateData, setOpenCreateData] = useState(false);
  const [openDeleteRow, setOpenDeleteRow] = useState(false);
  const [openEditRow, setOpenEditRow] = useState(false);
  const [openEditFilter, setOpenEditFilter] = useState(false);
  const [rowEdit, setRowEdit] = useState(initialState[0]);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingError, setIsLoadingError] = useState(false);
  const [idCodeDelete, setIdCodeDelete] = useState(DELETE_CODE);
  const dispatch = useDispatch();
  const [activeOffset, setActiveOffset] = useState(false);
  const [currencyOffset, setCurrencyOffset] = useState(0);
  const [rangeOffset, setRangeOffset] = useState([{}]);
  const [openCreateProvince,setOpenCreateProvince]=useState(false);  
 
  const keyPress = useCallback(
    (e) => {
      if (e.key === 'Escape' && showDocumentTypeSettings) {
        setData(initialState);
        setForm(initialState[0]);
        setFilters(initialFilters);
        setApplyFilters(initialFilters);
        setNumTotReg(0);
        setNumTotRegFilter(0);
        setErrorsFilters({});
        setErrors({});
        setInputText("");
        setOpenCreateData(false);
        setOpenDeleteRow(false);
        setOpenEditRow(false);
        setOpenEditFilter(false);
        setRowEdit(initialState[0]);
        setIsLoading(false);
        setIsLoadingError(false);
        setIdCodeDelete(DELETE_CODE);
        setActiveOffset(false);
        setCurrencyOffset(0);
        setRangeOffset([{}]);
        setOpenCreateProvince(openCreateProvince);
        setIndexShowSettings(0);
        setShowModalDocumentTypeSettings(false);
        setShowModalGeneralSettings(true);
        setShowModalEmployeeSettings(isEmployee);
        setShowModalStoreSettings(isStore);
        setShowModalFormSettings(isForm);
        setShowModalTicketsSettings(isWweigVehicles);
        setShowModalContractSettings(isContract);
        setShowModalPurchasesDeliverySettings(isPurchasesDelivery);
        setShowModalShippingsSettings(isShipping);
      }
    },
    [
      setData,
      setForm,
      setFilters,
      setApplyFilters,
      setNumTotReg,
      setNumTotRegFilter,
      setErrorsFilters,
      setErrors,
      setInputText,
      setOpenCreateData,
      setOpenDeleteRow,
      setOpenEditRow,
      setOpenEditFilter,
      setRowEdit,
      setIsLoading,
      setIsLoadingError,
      setIdCodeDelete,
      setActiveOffset,
      setCurrencyOffset,
      setRangeOffset,
      openCreateProvince,
      setOpenCreateProvince,
      showDocumentTypeSettings,
      setShowModalDocumentTypeSettings,
      setIndexShowSettings,
      setShowModalGeneralSettings,
      setShowModalEmployeeSettings,
      isEmployee,
      setShowModalStoreSettings,
      isStore,
      setShowModalFormSettings,
      isForm,
      setShowModalTicketsSettings,
      isWweigVehicles,
      setShowModalContractSettings,
      isContract,
      setShowModalPurchasesDeliverySettings,
      isPurchasesDelivery,
      setShowModalShippingsSettings,
      isShipping,
      DELETE_CODE
    ]
  );

  const closeModalDocumentTypeSettings = () => {    
    setData(initialState);
    setForm(initialState[0]);
    setFilters(initialFilters);
    setApplyFilters(initialFilters);
    setNumTotReg(0);
    setNumTotRegFilter(0);
    setErrorsFilters({});
    setErrors({});
    setInputText("");
    setOpenCreateData(false);
    setOpenDeleteRow(false);
    setOpenEditRow(false);
    setOpenEditFilter(false);
    setRowEdit(initialState[0]);
    setIsLoading(false);
    setIsLoadingError(false);
    setIdCodeDelete(DELETE_CODE);
    setActiveOffset(false);
    setCurrencyOffset(0);
    setRangeOffset([{}]);
    setOpenCreateProvince(false);
    setIndexShowSettings(0);
    setShowModalDocumentTypeSettings(false);
    setShowModalGeneralSettings(true);
    setShowModalEmployeeSettings(isEmployee);
    setShowModalStoreSettings(isStore);
    setShowModalFormSettings(isForm);
    setShowModalTicketsSettings(isWweigVehicles);
    setShowModalContractSettings(isContract);
    setShowModalPurchasesDeliverySettings(isPurchasesDelivery);
    setShowModalShippingsSettings(isShipping);
  };

  const openCreateOrEditData = () => {
    setInputText("");
    setOpenCreateData(true);
  };

  const openEditFilters = () => {
    setFilters(applyFilters);
    setOpenEditFilter(!openEditFilter);
  };

  const handleSubmitFilterEdit = () => {
    setErrorsFilters(validateFormFilter());
    if (Object.keys(errorsFilters).length === 0) {
      setApplyFilters(filters);
      setFilters(initialFilters);
      setErrorsFilters({});
      setOpenEditFilter(false);
    }  else{
      dispatch(warningErrorAction(t('Text_Error_Filter')));
    }  
  }
  
  const handleDeleteFilters = () => {
    setErrorsFilters({});
    setFilters(initialFilters);
    setApplyFilters(initialFilters);
    setOpenEditFilter(!openEditFilter);
  };

  const handleCancelFilters = () => {
    setErrorsFilters({});
    setFilters(initialFilters);
    setOpenEditFilter(!openEditFilter);
  };

  const handleChangeFilterEdit = (e) => {
    const { name, value } = e.target;
    setFilters(filters => ({
      ...filters,
      [name]: value
    }));
  };

  const handleBlurFilterEdit = (e) => {
    setErrorsFilters(validateFormFilter());
    handleChangeFilterEdit(e);
  };

  const toggleDrawerFilterEdit = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    if (event.type === 'keydown') {
      event.preventDefault();
      setErrorsFilters({});
      setFilters(initialFilters);
      setOpenEditFilter(!openEditFilter);
    }
  };

  const validateFormFilter = () => {

    let errorsFilters = {};
    let regexNumber = /^[0-9]+$/;
    let regexInvalidCharacters = /[\\!\\$\\%\\&\\|\\<\\>\\?\\{\\}\\~\\"\\']+/;

    if (filters.idFrom!=="" & !regexNumber.test(filters.idFrom)) {
      errorsFilters.idFrom = 'Text_TextNoNumber';
    }
    if (filters.idTo!=="" & !regexNumber.test(filters.idTo)) {
      errorsFilters.idTo = 'Text_TextNoNumber';
    }
    if (filters.idFrom!=="" & !errorsFilters.idFrom!==undefined){
      if (filters.idTo!=="" & !errorsFilters.idTo!==undefined){
        if (parseInt(filters.idTo)<parseInt(filters.idFrom)){
          errorsFilters.idTo = 'Text_TextGreater_Id';
        }
      }
    }
    if (regexInvalidCharacters.test(filters.nameesFrom)) {
      errorsFilters.nameesFrom = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(filters.nameesTo)) {
      errorsFilters.nameesTo = 'Text_TextNoValid';
    } 
    
    if (filters.nameesTo!=="" & filters.nameesTo<filters.nameesFrom){
      errorsFilters.nameesTo = 'Text_TextGreater_Namees';
    }
    if (filters.nameenTo!=="" & filters.nameenTo<filters.nameenFrom){
      errorsFilters.nameenTo = 'Text_TextGreater_Nameen';
    }
    return errorsFilters;
  }  

  const filteredData = data.filter((el) => {
    if (inputText === '') {
        return el;
    }
    else {
        let dataTranslate=t(el.id+el.name) ;
        return dataTranslate.toLowerCase().includes(inputText);
    }
  })

  let inputHandler = (e) => {
    var lowerCase = e.target.value.toLowerCase();
    setInputText(lowerCase);
  };

  const handleChangeDeleteRow = (e) => {
    const value  = e.target.value;
    setIdCodeDelete(value);
  };

  const handleCancelDeleteRow = () => {
    setErrors({});
    setRowEdit(initialState[0]);
    setForm(initialState[0]);
    setIsLoading(false);
    setIsLoadingError(false);
    setIdCodeDelete(DELETE_CODE);
    setOpenDeleteRow(false);
  };

  const handleCloseDeleteRow = (e) => {
    if (idCodeDelete !== DELETE_CODE) {
      dispatch(warningErrorAction(t('Text_InvalidCode')));
      return;
    }
  
    if (e.key !== 'Escape') {
      if (rowEdit.id !== '') {
        const fetchData = async () => {
          setIsLoading(true);
          try {
            const getData = await helpHttp().del(ENDPOINT, `documenttype/deletedocumenttype`, token, rowEdit);
            if (getData.status === "OK") {
              dispatch(successErrorAction(t('Text_ValidCode')));
              dispatch(successErrorAction(t('Text_DeleteField')));
            } else {
              dispatch(warningErrorAction(t(getData.cause)));
            }
          } catch (error) {
            dispatch(warningErrorAction(t('Text_IncorrectData')));
            setIsLoading(false);
          } finally {
            setIsLoading(false);
          }
        };
        fetchData();
      } else {
        dispatch(warningErrorAction(t('Text_InvalidId')));
      }
    }
  
    setIdCodeDelete(DELETE_CODE);
    setOpenDeleteRow(false);
    handleCloseDataEdit();
  };

  const handleSubmitDataEdit = () => {
    setErrors(validateForm());
    const errorsToCheck = validateForm();
  
    if (Object.keys(errorsToCheck).length === 0 && form.name !== '') {
      const fetchData = async () => {
        setIsLoading(true);
        try {
          const getData = openEditRow
            ? await helpHttp().put(ENDPOINT, `documenttype/updatedocumenttype`, token, form)
            : await helpHttp().post(ENDPOINT, `documenttype/savedocumenttype`, token, form);
  
          if (getData.status === "OK") {
            dispatch(successErrorAction(t('Text_SaveData')));
            handleCloseDataEdit();
          } else {
            dispatch(warningErrorAction(t(getData.cause)));
          }
        } catch (error) {
          dispatch(warningErrorAction(t('Text_IncorrectData')));
          setIsLoading(false);
        } finally {
          setIsLoading(false);
        }
      };
      fetchData();
    } else {
      if (errorsToCheck.name) {
        dispatch(warningErrorAction(t(errorsToCheck.name)));
      } else {
        dispatch(warningErrorAction(t('Text_IncorrectData')));
      }
    }
  };

  const handleCloseDataEdit = () => {
   setOpenCreateData(false);
    setOpenEditRow(false);
    setErrors({});
    setRowEdit(openEditRow?rowEdit:initialState[0]);
    setForm(initialState[0]);
    setIsLoading(false);
    setIsLoadingError(false);
    toggleDrawerDataEdit(false);
  };

  const handleChangeDataEdit = (e) => {
    const { name, value } = e.target;
    setForm(form => ({
      ...form,
      [name]: value
    }));
  };

  const handleBlurDataEdit = (e) => {
    handleChangeDataEdit(e);
    setErrors(validateForm());
  };
  
  const toggleDrawerDataEdit = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    if (event.type === 'keydown') {
      event.preventDefault()
      handleCloseDataEdit();
    }
  };

  const validateForm = () => {
    let errors = {};
    let regexInvalidCharacters = /[\\!\\$\\%\\&\\|\\<\\>\\?\\{\\}\\~\\"\\']+/;

    if (!form.name || form.name==="") {
      errors.name = 'Text_FieldNameRequired';
    } else {
      if (regexInvalidCharacters.test(form.name)) {
        errors.name = 'Text_TextNoValid';
      } else{
        if (openEditRow){
          if (data.find((key=>(key.name.toLowerCase()===form.name.toLowerCase() && key.id!==rowEdit.id)))) {
            errors.name = 'Text_Document_NameExist';
          }
        } else {
          if (data.find((key=>key.name.toLowerCase()===form.name.toLowerCase()))) {
            errors.name = 'Text_Document_NameExist';
          }
        }   
      }  
    }
    return errors;
  };

  useEffect(() => {
    setForm(openEditRow ? rowEdit : initialState[0]);
  }, [rowEdit, openEditRow]);
  
  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event && typeof event === 'object' && event.key) {
        keyPress(event);
      }
    };
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [keyPress]);
  
  useEffect(() => {
    if (numTotRegFilter > LIMITPAGESIZE) {
      setActiveOffset(true);
      const newRangeOffset = [
        {
          value: 0,
          label: " 1 - " + Number(LIMITPAGESIZE).toLocaleString('de-DE'),
        }
      ];
      for (let i = Number(LIMITPAGESIZE); i < numTotRegFilter; i += Number(LIMITPAGESIZE)) {
        newRangeOffset.push({
          value: i,
          label: " " + (i + 1).toLocaleString('de-DE') + " - " + (i + Number(LIMITPAGESIZE)).toLocaleString('de-DE'),
        });
      }
      setRangeOffset(newRangeOffset);
    } else {
      setCurrencyOffset(0);
      setActiveOffset(false);
      setRangeOffset([{}]);
    }
  }, [numTotRegFilter, LIMITPAGESIZE]);

  useEffect(() => {
    let ignore = false;
  
    const fetchnumTotReg = async () => {
      setIsLoading(true);
      try {
        const getData = await helpHttp().get(ENDPOINT, `documenttype/getinformationtabledocumnettype`, token);
        if (getData.err || getData.message === "Load failed") {
          setIsLoadingError(true);
          setData(initialState);
          dispatch(warningErrorAction(t(getData.message === "Load failed" ? 'Text_ConnectionFailied' : 'Text_InvalidCredentials')));
        } else if (!ignore) {
          setNumTotReg(getData);
        }
      } catch (error) {
        setData(initialState);
        dispatch(warningErrorAction(t('Text_IncorrectData')));
        setIsLoading(false);
      } finally {
        setIsLoading(false);
      }
    };
  
    const fetchnumTotRegFilter = async () => {
      setIsLoading(true);
      try {
        const getData = await helpHttp().post(ENDPOINT, `documenttype/getcountfilterdocumnettypes`, token, applyFilters);
        if (getData.err || getData.message === "Load failed") {
          setData(initialState);
          dispatch(warningErrorAction(t(getData.message === "Load failed" ? 'Text_ConnectionFailied' : 'Text_InvalidCredentials')));
        } else if (!ignore) {
          setNumTotRegFilter(getData);
        }
      } catch (error) {
        setData(initialState);
        dispatch(warningErrorAction(t('Text_IncorrectData')));
        setIsLoading(false);
      } finally {
        setIsLoading(false);
      }
    };
  
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const getData = await helpHttp().get3(ENDPOINT, `documenttype/documenttypes`, token, currencyOffset, LIMITPAGESIZE, applyFilters);
        if (getData.err || getData.message === "Load failed") {
          setIsLoadingError(true);
          setData(initialState);
          dispatch(warningErrorAction(t(getData.message === "Load failed" ? 'Text_ConnectionFailied' : 'Text_InvalidCredentials')));
        } else if (!ignore) {
          setData(getData);
        }
      } catch (error) {
        setData(initialState);
        dispatch(warningErrorAction(t('Text_IncorrectData')));
        setIsLoading(false);
      } finally {
        setIsLoading(false);
      }
    };
  
    if (showDocumentTypeSettings) {
      fetchnumTotReg();
      fetchnumTotRegFilter();
      fetchData();
      return () => { ignore = true };
    }
  }, [ENDPOINT, t, token, dispatch, openCreateData, openDeleteRow, openEditRow, rowEdit, showDocumentTypeSettings, LIMITPAGESIZE, applyFilters, currencyOffset]);
  
  return (
    <div> 
      {showDocumentTypeSettings && ( 
        <div className="container-fluid">
          {/* Search input */}
          <div id="myDIV">
            <h1 className="h1NewStyle mb-2">
            {isLoadingError?<i className="fas fa-solid fa-exclamation fa-2x text-danger"></i>:isLoading?<CircularProgress color={openDeleteRow?"warning":"primary"}/>:<i className="fas fa-regular fa-id-card text-warning"></i>} {t('Text_Document_Config')}</h1>
            {roles[0].listmenus.find((key)=>(key.name==='SubMenu_Document_Create'))?
              <div>
              <button type="button" className="buttonCreate" onClick={openCreateOrEditData}>{t('Button_Create')}</button><> </>
              { isEmployee ?
                <button type="button" className="buttonBack" onClick={closeModalDocumentTypeSettings}>{t('Button_Exit')}</button>:
                <button type="button" className="buttonBack" onClick={closeModalDocumentTypeSettings}>{t('Button_Back')}</button>
              }

            </div>:
            <div>
              {isEmployee  ?
                <button type="button" className="buttonBack" onClick={closeModalDocumentTypeSettings}>{t('Button_Exit')}</button>:
                <button type="button" className="buttonBack" onClick={closeModalDocumentTypeSettings}>{t('Button_Back')}</button>
              }
            </div>
            }
          </div>  
          <br/>
          <div className="searchStyle">
            <TextField
              id="outlined-basic"
              variant="outlined"
              value={inputText}
              placeholder={t('Text_Document_Search')}
              onChange={inputHandler}
              fullWidth
              label={t('Text_Document_Search')}>
            </TextField>
          </div>
          <br/>
          <div id="myDIV">
            <h1 className="h6NewStyle mb-2">
            {t('Text_Filter')}&nbsp;
                <button type="button" className="buttonFilter" onClick={openEditFilters}><i className="fas fa-solid fa-circle" style={{color:(applyFilters.idFrom!=="" || applyFilters.idTo!=="")?"#32CD32":"#FFFFFF"}}></i> {t('Text_Code')}</button><> </>
                <button type="button" className="buttonFilter" onClick={openEditFilters}><i className="fas fa-solid fa-circle" style={{color:(applyFilters.nameesFrom!=="" || applyFilters.nameesTo!=="")?"#32CD32":"#FFFFFF"}}></i> {t('Text_Name')}</button><> </>
                </h1>
          </div>
          <br/>
          <div className="card shadow mb-4">    
            <div className="card-body">
              <TableSorting
                data={filteredData}
                headCells={headCells}
                numTotRegFilter={numTotRegFilter}
                titleTable={t('Text_Document_Table')}
                setOpenDeleteRow={setOpenDeleteRow}
                setOpenEditRow={setOpenEditRow}
                setRowEdit={setRowEdit}
                showEditButton={roles[0].listmenus.find((key)=>(key.name==='SubMenu_Document_Edit'))?true:false}
                showDeleteButton={roles[0].listmenus.find((key)=>(key.name==='SubMenu_Document_Delete'))?true:false}
                typeTable={2}
                setOpenCreateProvince={setOpenCreateProvince}
                showVisibilityButton={false}
                numTotReg={numTotReg}
                activeOffset={activeOffset}
                rangeOffset={rangeOffset}
                currencyOffset={currencyOffset}
                setCurrencyOffset={setCurrencyOffset}
                minvalue={Number(NON_ERASABLE_DOCUMENTTYPES_ID)}>
              </TableSorting>
            </div>             
          </div>
          {openEditFilter &&
            <div>
              <SwipeableDrawer
                anchor='right'
                open={true}
                onClose={toggleDrawerFilterEdit(false)}
                onOpen={toggleDrawerFilterEdit(true)}>
                <Container >
                  <Box sx={{ bgcolor: '#ffffff',height:'100vh', width: '500px' }}>
                    <br/>
                    <div id="myDIV">
                      <h1 className="h1NewStyle px-4 mb-2">{t('Text_Filter')}</h1>
                    </div>
                    <hr className="sidebar-divider d-none d-md-block"/>
                    <form className="createfilter" onSubmit={handleCancelFilters}>  
                      <div className="form-floating mb-3 px-4 primary">
                        <label className="text-primary">{t('Text_Filter_Code_From')} :</label>
                        <div className="form-group">
                          <TextField
                            id="idFrom" 
                            name="idFrom"
                            value={filters.idFrom}
                            inputProps={{ style: {width: 450}, maxLength: 15 }}
                            label={errorsFilters.idFrom!=null?t(errorsFilters.idFrom):t('PlaceHolder_Filter_Code_From')}
                            placeholder={t('PlaceHolder_Filter_Code_From')}
                            variant="standard"
                            onBlur={handleBlurFilterEdit}
                            onChange={handleChangeFilterEdit}/>
                        </div>
                        <label className="text-primary">{t('Text_Filter_Code_To')} :</label>
                        <div className="form-group">
                          <TextField
                            id="idTo" 
                            name="idTo"
                            value={filters.idTo}
                            inputProps={{ style: {width: 450}, maxLength: 15 }}
                            label={errorsFilters.idTo!=null?t(errorsFilters.idTo):t('PlaceHolder_Filter_Code_to')}
                            placeholder={t('PlaceHolder_Filter_Code_to')}
                            variant="standard"
                            onBlur={handleBlurFilterEdit}
                            onChange={handleChangeFilterEdit}/>
                        </div>

                        <label className="text-primary">{t('Text_Filter_Name_From')} :</label>
                        <div className="form-group">
                          <TextField
                            id="nameesFrom" 
                            name="nameesFrom"
                            value={filters.nameesFrom}
                            inputProps={{ style: {width: 450}, maxLength: 100 }}
                            label={errorsFilters.nameesFrom!=null?t(errorsFilters.nameesFrom):t('PlaceHolder_Filter_Name_From')}
                            placeholder={t('PlaceHolder_Filter_Name_From')}
                            variant="standard"
                            onBlur={handleBlurFilterEdit}
                            onChange={handleChangeFilterEdit}/>
                        </div>
                        <label className="text-primary">{t('Text_Filter_Name_To')} :</label>
                        <div className="form-group">
                          <TextField
                            id="nameesTo" 
                            name="nameesTo"
                            value={filters.nameesTo}
                            inputProps={{ style: {width: 450}, maxLength: 100 }}
                            label={errorsFilters.nameesTo!=null?t(errorsFilters.nameesTo):t('PlaceHolder_Filter_Name_to')}
                            placeholder={t('PlaceHolder_Filter_Name_to')}
                            variant="standard"
                            onBlur={handleBlurFilterEdit}
                            onChange={handleChangeFilterEdit}/>
                        </div>
                        <hr className="sidebar-divider d-none d-md-block"/>
                        <div className="btn-group d-grid d-md-flex justify-content-md-end" role="group">
                          <button className="btn btn-primary btn-lg " type="button" onClick={handleSubmitFilterEdit}>{t('Button_Apply')}</button><> </>
                          <button className="btn btn-outline-secondary btn-lg" type="button" onClick={handleDeleteFilters}>{t('Button_Cancel_Filters')}</button>
                        </div>
                      </div>
                    </form>
                  </Box>
                </Container>
              </SwipeableDrawer>
            </div>
          }  
          {(openCreateData || openEditRow) && 
            <div>
              <SwipeableDrawer
                anchor='right'
                open={true}
                onClose={toggleDrawerDataEdit(false)}
                onOpen={toggleDrawerDataEdit(true)}>
                <Container >
                  <Box sx={{ bgcolor: '#ffffff',height:'100vh', width: '500px' }}>
                    <br/>
                    <div id="myDIV">
                      <h1 className="h1NewStyle px-4 mb-2">{openEditRow?t('Text_Document_Edit'):t('Text_Document_Create')}</h1>
                    </div>  
                    <hr className="sidebar-divider d-none d-md-block"/>
                    <form className="createdocumenttye" onSubmit={handleSubmitDataEdit}>  
                      <div className="form-floating mb-3 px-4 primary">
                        <label className="text-primary">{t('Text_Code')} :</label>
                        <div className="form-group">
                          <TextField
                            id="code" 
                            name="code"
                            value={form.id}
                            inputProps={{ style: {width: 200} }}
                            label={t('PlaceHolder_Document_Code')}
                            placeholder={t('PlaceHolder_Document_Code')}
                            variant="standard"
                            disabled/>
                        </div>
                        <label className="text-primary">{t('Text_Name')} :</label>
                        <div className="form-group">
                          <TextField
                            id="name" 
                            name="name"
                            value={form.name}
                            inputProps={{ style: {width: 450}, maxLength: 100 }}
                            label={errors.name!=null?t(errors.name):t('PlaceHolder_Document_Name')}
                            placeholder={t('PlaceHolder_Document_Name')}
                            variant="standard"
                            onBlur={handleBlurDataEdit}
                            onChange={handleChangeDataEdit}
                          />
                        </div>
                        <hr className="sidebar-divider d-none d-md-block"/>
                        <div className="btn-group d-grid d-md-flex justify-content-md-end" role="group">
                          <button className="btn btn-primary btn-lg " type="button" onClick={handleSubmitDataEdit}>{openEditRow?t('Button_Update'):t('Button_Create')}</button><> </>
                          <button className="btn btn-outline-secondary btn-lg" type="button" onClick={handleCloseDataEdit}>{t('Button_Cancel')}</button>
                        </div>
                      </div>
                    </form>
                  </Box>
                </Container>
              </SwipeableDrawer>
            </div>
          } 
          <Dialog open={openDeleteRow} onClose={handleCancelDeleteRow}>
            <DialogTitle style={{color:"#e74a3b"}}>{t('Text_Delete')} {rowEdit.id}  - {rowEdit.name} </DialogTitle>
            <DialogContent>
              <DialogContentText>
                {t('Text_Line1')}<br/>
                {t('Text_Line2')}
              </DialogContentText>
              <TextField
                autoFocus
                margin="dense"
                id="idCode"
                label={t('Text_Label')}
                type="text"
                fullWidth
                variant="standard"
                error
                defaultValue={idCodeDelete}
                onChange={handleChangeDeleteRow}/>
            </DialogContent>
            <DialogActions>
              <Button style={{color:"#e74a3b",fontSize: 16 }} onClick={handleCancelDeleteRow}>{t('Button_Cancel')}</Button>
              <Button variant="contained" style={{color:"#ffffff", background:"#e74a3b",fontSize: 16}} onClick={handleCloseDeleteRow}>{t('Button_Delete')}</Button>
            </DialogActions>
          </Dialog>
          {/* End Content Row */}
        </div>
        //* End Page Content *//
      ) }
     </div>
  )
}

DocumentTypeSettings.propTypes = {
  showDocumentTypeSettings: PropTypes.bool.isRequired,
  setShowModalDocumentTypeSettings: PropTypes.func.isRequired,
  setIndexShowSettings: PropTypes.func.isRequired,
  setShowModalGeneralSettings: PropTypes.func.isRequired,
  setShowModalEmployeeSettings: PropTypes.func.isRequired,
  isEmployee: PropTypes.bool.isRequired,   
  setShowModalStoreSettings: PropTypes.func.isRequired,  
  isStore: PropTypes.bool.isRequired,  
  setShowModalFormSettings: PropTypes.func.isRequired,  
  isForm: PropTypes.bool.isRequired,
  setShowModalTicketsSettings: PropTypes.func.isRequired,
  isWweigVehicles: PropTypes.bool.isRequired,
  setShowModalContractSettings: PropTypes.func.isRequired,  
  isContract: PropTypes.bool.isRequired,
  setShowModalPurchasesDeliverySettings: PropTypes.func.isRequired,
  isPurchasesDelivery: PropTypes.bool.isRequired,
  setShowModalShippingsSettings: PropTypes.func.isRequired,
  isShipping: PropTypes.bool.isRequired
};
  
export default DocumentTypeSettings;

