import React, {  Suspense, useEffect, useState, useCallback } from 'react';
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next"
import { useNavigate } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CircularProgress from '@mui/material/CircularProgress';
import { SwipeableDrawer } from '@mui/material';
import { Container, Box } from '@mui/material';   
import { TextField } from '@mui/material';
import { Button } from '@mui/material'; 
import { Checkbox} from  '@mui/material';
import "css/generalSettings.css";
import { successErrorAction, warningErrorAction } from "actions/ErrorActions";
import { helpHttp } from "service/HelpHttp";
import { Loading } from "components/utils/Loading";
import TableSortingArticle from 'pages/articles/SortingTablesArticles';
import PropTypes from 'prop-types';

const initialFiltersArticle= {
  "idFrom":"",
  "idTo":"",
  "codeFrom":"",
  "codeTo":"",
  "nameesFrom":"",
  "nameesTo":"",
  "nameenFrom":"",
  "nameenTo":"",
  "enabled":true,
  "notenabled":true,
  "typeinside":true,
  "typeoutside":true,
  "ticketweight":true,
  "tickenotweight":true,
  "typesoftaxFromES":"",
  "typesoftaxToES":"",
  "typesoftaxFromEN":"",
  "typesoftaxToEN":"",
  "codelerFrom":"",
  "codelerTo":""  
};

const initialTypesOfTaxesToSelect = [{
  "id": "",
  "namees": "",
  "nameen": ""
}];

const initialCodeLERToSelect = [{
  "id": "",
  "code":"",
  "namees": "",
  "nameen": ""
}];

const initialStateArticle = [{
  "id" : "",
  "code" : "",
  "namees" : "",
  "nameen" : "",
  "saleprice" : 0, 
  "typeinside":true,
  "typeoutside" : true,
  "ticketweight": true,
  "enabled" : true, 
  "typesoftax_id":0,
  "typesoftax_namees": "",
  "typesoftax_nameen": "", 
  "codeler_id":0,
  "codeler_code": "" 
}];

            
const ArticlesFilter = ({typeOfList, applyFiltersArticle,setApplyFiltersArticle,showModalArticlesSettings,setShowModalArticlesSettings}) => {
  const {t,i18n} = useTranslation(['listDataArticles']);
  const token = useSelector((state) => state.loginUser.token); 
  const roles = useSelector((state) => state.loginUser.roles);  
  const ENDPOINT = useSelector((state) => state.variablesUser.ENDPOINT); 
  const LIMITPAGESIZE = useSelector((state) => state.variablesUser.LIMITPAGESIZE); 
  const NON_ERASABLE_ARTICLES_ID = useSelector((state) => state.variablesUser.NON_ERASABLE_ARTICLES_ID); 
  const DELETE_CODE = useSelector((state) => state.formatDIUser.delete_code); 
  const DELETE_CODE_ARTICLE = useSelector((state) => state.formatDIUser.delete_code_formdi); 
  const [dataArticle, setDataArticle] = useState(initialStateArticle);
  const [filtersArticle, setFiltersArticle]= useState(applyFiltersArticle);
  const [numTotRegFilter,setNumTotRegFilter]= useState(0);
  const [numTotRegArticle,setNumTotRegArticle]= useState(0);
  const [errorsFiltersArticle, setErrorsFiltersArticle] = useState({});
  const [inputTextArticle, setInputTextArticle] = useState("");
  const [openCreateDataArticle,setOpenCreateDataArticle]= useState(false);
  const [openDeleteRowArticle,setOpenDeleteRowArticle]= useState(false);
  const [openEditRowArticle,setOpenEditRowArticle]= useState(false);
  const [openEditFilterArticle,setOpenEditFilterArticle]= useState(false);
  const [rowEditArticle,setRowEditArticle]= useState(initialStateArticle[0]);
  const [isLoadingArticle, setIsLoadingArticle] = useState(true);
  const [isLoadingErrorArticle, setIsLoadingErrorArticle] = useState(false);
  const [idCodeDelete,setIdCodeDelete]=useState(DELETE_CODE);
  const dispatch = useDispatch();
  const [activeOffsetArticle, setActiveOffsetArticle]= useState(false);
  const [currencyOffsetArticle, setCurrencyOffsetArticle] = useState(0);
  const [rangeOffsetArticle, setRangeOffsetArticle] = useState([{}]); 
  const [typesOfTaxesToSelect,setTypesOfTaxesToSelect]= useState(initialTypesOfTaxesToSelect);
  const [codeLERToSelect,setCodeLERToSelect]= useState(initialCodeLERToSelect);
  const navigate = useNavigate(); 
 
  const keyPress = useCallback(
    e => {
      if (e.key === 'Escape' && showModalArticlesSettings) {
        setDataArticle(initialStateArticle);
        setFiltersArticle(initialFiltersArticle);
        setNumTotRegFilter(0);
        setNumTotRegArticle(0);
        setErrorsFiltersArticle({});
        setInputTextArticle("");
        setOpenCreateDataArticle(false);
        setOpenDeleteRowArticle(false);
        setOpenEditRowArticle(false);
        setOpenEditFilterArticle(false);
        setRowEditArticle(initialStateArticle[0]);
        setIsLoadingArticle(false);
        setIsLoadingErrorArticle(false);
        setIdCodeDelete(DELETE_CODE);
        setActiveOffsetArticle(false);
        setCurrencyOffsetArticle(0);
        setRangeOffsetArticle([{}]);
        setTypesOfTaxesToSelect(initialTypesOfTaxesToSelect);
        setCodeLERToSelect(initialCodeLERToSelect);
        setShowModalArticlesSettings(false);
        setApplyFiltersArticle(initialFiltersArticle);
        navigate(typeOfList==="1"?"/home":"/purchase");
        return () => {};
      }
    },
    [
      setDataArticle,
      setFiltersArticle,
      setNumTotRegFilter,
      setNumTotRegArticle,
      setErrorsFiltersArticle,
      setInputTextArticle,
      setOpenCreateDataArticle,
      setOpenDeleteRowArticle,
      setOpenEditRowArticle,
      setOpenEditFilterArticle,
      setRowEditArticle,
      setIsLoadingArticle,
      setIsLoadingErrorArticle,
      setIdCodeDelete,
      setActiveOffsetArticle,
      setCurrencyOffsetArticle,
      setRangeOffsetArticle,
      setTypesOfTaxesToSelect,
      setCodeLERToSelect,
      setApplyFiltersArticle,
      showModalArticlesSettings,
      setShowModalArticlesSettings, 
      DELETE_CODE,
      typeOfList,
      navigate]
  );

  const closeModalArticles = () => {    
    setDataArticle(initialStateArticle);
    setFiltersArticle(initialFiltersArticle);
    setNumTotRegFilter(0);
    setNumTotRegArticle(0);
    setErrorsFiltersArticle({});
    setInputTextArticle("");
    setOpenCreateDataArticle(false);
    setOpenDeleteRowArticle(false);
    setOpenEditRowArticle(false);
    setOpenEditFilterArticle(false);
    setRowEditArticle(initialStateArticle[0]);
    setIsLoadingArticle(false);
    setIsLoadingErrorArticle(false);
    setIdCodeDelete(DELETE_CODE);
    setActiveOffsetArticle(false);
    setCurrencyOffsetArticle(0);
    setRangeOffsetArticle([{}]);
    setTypesOfTaxesToSelect(initialTypesOfTaxesToSelect);
    setCodeLERToSelect(initialCodeLERToSelect);
    setShowModalArticlesSettings(false);
    setApplyFiltersArticle(initialFiltersArticle);
    navigate(typeOfList==="1"?"/home":"/purchase");
    return () => {};
  };

  const openCreateOrEditDataArticle = () => {
    setInputTextArticle("");
    setOpenCreateDataArticle(true);
    navigate("/articledetail/0");
  };

  const openEditFiltersArticle = () => {
      setFiltersArticle(applyFiltersArticle);
      setOpenEditFilterArticle(!openEditFilterArticle);
  };

  const handleSubmitFilterEditArticle = () => {
    setErrorsFiltersArticle(validateFormFilterArticle());
    if (Object.keys(errorsFiltersArticle).length === 0) {
      setApplyFiltersArticle(filtersArticle);
      setFiltersArticle(initialFiltersArticle);
      setErrorsFiltersArticle({}); 
      setOpenEditFilterArticle(false);
    }  else{
      dispatch(warningErrorAction(t('Text_Error_Filter')));
    }
  }

  const handleDeleteFiltersArticle = () => {
    setErrorsFiltersArticle({});
    setFiltersArticle(initialFiltersArticle);
    setApplyFiltersArticle(initialFiltersArticle); 
    setOpenEditFilterArticle(!openEditFilterArticle);
  };

  const handleCancelFiltersArticle = () => {
    setErrorsFiltersArticle({});
    setFiltersArticle(initialFiltersArticle); 
    setOpenEditFilterArticle(!openEditFilterArticle);
  };   

  const handleChangeInsideFilter = (e) => {
    setFiltersArticle(filtersArticle => ({
      ...filtersArticle,
      typeinside: !filtersArticle.typeinside
    }));
  };

  const handleChangeOutsideFilter = (e) => {
    setFiltersArticle(filtersArticle => ({
      ...filtersArticle,
      typeoutside: !filtersArticle.typeoutside
    }));
  };


  const handleChangeWeightFilter = (e) => {
    setFiltersArticle(filtersArticle => ({
      ...filtersArticle,
      ticketweight: !filtersArticle.ticketweight
    }));
  };

  const handleChangeNotWeightFilter = (e) => {
    setFiltersArticle(filtersArticle => ({
      ...filtersArticle,
      tickenotweight: !filtersArticle.tickenotweight
    }));
  };


  const handleChangeEnabledFilter = (e) => {
    setFiltersArticle(filtersArticle => ({
      ...filtersArticle,
      enabled: !filtersArticle.enabled
    }));
  };

  const handleChangeNotEnabledFilter = (e) => {
    setFiltersArticle(filtersArticle => ({
      ...filtersArticle,
      notenabled: !filtersArticle.notenabled
    }));
  };

  const handleChangeFilterEditArticle = (e) => {
    const { name, value } = e.target;
    setFiltersArticle(filtersArticle => ({
      ...filtersArticle,
      [name]: value
    }));
  };

  const handleBlurFilterEditArticle = (e) => {
    setErrorsFiltersArticle(validateFormFilterArticle());
    handleChangeFilterEditArticle(e);
  };

  const toggleDrawerFilterEditArticle = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    if (event.type === 'keydown') {
      event.preventDefault();
      handleCloseDataEditArticle();
    }
  };

  const validateFormFilterArticle = () => {

    let errorsFiltersArticle = {};
    let regexNumber = /^[0-9]+$/;
    let regexInvalidCharacters = /[\\!\\$\\%\\&\\|\\<\\>\\?\\{\\}\\~\\"\\']+/;

    if (filtersArticle.idFrom!=="" & !regexNumber.test(filtersArticle.idFrom)) {
      errorsFiltersArticle.idFrom = 'Text_TextNoNumber';
    }
    if (filtersArticle.idTo!=="" & !regexNumber.test(filtersArticle.idTo)) {
      errorsFiltersArticle.idTo = 'Text_TextNoNumber';
    }
    if (filtersArticle.idFrom!=="" & !errorsFiltersArticle.idFrom!==undefined){
      if (filtersArticle.idTo!=="" & !errorsFiltersArticle.idTo!==undefined){
        if (parseInt(filtersArticle.idTo)<parseInt(filtersArticle.idFrom)){
          errorsFiltersArticle.idTo = 'Text_TextGreater_Id';
        }
      }
    }

    if (regexInvalidCharacters.test(filtersArticle.codeFrom)) {
      errorsFiltersArticle.codeFrom = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(filtersArticle.codeTo)) {
      errorsFiltersArticle.codeTo = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(filtersArticle.nameesFrom)) {
      errorsFiltersArticle.nameesFrom = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(filtersArticle.nameesTo)) {
      errorsFiltersArticle.nameesTo = 'Text_TextNoValid';
    } 

    if (regexInvalidCharacters.test(filtersArticle.nameenFrom)) {
      errorsFiltersArticle.nameenFrom = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(filtersArticle.nameenTo)) {
      errorsFiltersArticle.nameenTo = 'Text_TextNoValid';
    } 

    if (filtersArticle.codeTo!=="" & filtersArticle.codeTo<filtersArticle.codeFrom){
      errorsFiltersArticle.codeTo = 'Text_TextGreater_Code';
    }
   
    if (filtersArticle.nameesTo!=="" & filtersArticle.nameesTo<filtersArticle.nameesFrom){
      errorsFiltersArticle.nameesTo = 'Text_TextGreater_Namees';
    }

    if (filtersArticle.nameenTo!=="" & filtersArticle.nameenTo<filtersArticle.nameenFrom){
      errorsFiltersArticle.nameenTo = 'Text_TextGreater_Nameen';
    } 
    
    if (filtersArticle.typesoftaxToES!=="" & filtersArticle.typesoftaxToES<filtersArticle.typesoftaxFromES){
      filtersArticle.typesoftaxToES="";
      errorsFiltersArticle.typesoftaxToES = 'Text_TextGreater_TypesOfTax';
    }    
    if (filtersArticle.typesoftaxToEN!=="" & filtersArticle.typesoftaxToEN<filtersArticle.typesoftaxFromEN){
        filtersArticle.typesoftaxToEN="";
        errorsFiltersArticle.typesoftaxToEN = 'Text_TextGreater_TypesOfTax';
    }
 
    if (filtersArticle.codelerTo!=="" & filtersArticle.phonesTo<filtersArticle.codelerFrom){
      errorsFiltersArticle.codelerTo = 'Text_TextGreater_CodeLer';
    }
 
    return errorsFiltersArticle;
  }  

  const filteredDataArticle = dataArticle.filter((el) => {
    if (inputTextArticle === '') {
        return el;
    }
    else {
        let dataTranslate=el.id+el.code+el.namees+el.nameen+el.codeler_code;
        return dataTranslate.toLowerCase().includes(inputTextArticle);
    }
  })

  let inputHandlerArticle = (e) => {
      var lowerCase = e.target.value.toLowerCase();
      setInputTextArticle(lowerCase);
  };

  const handleChangeDeleteRowArticle = (e) => {
    const value  = e.target.value;
    setIdCodeDelete(value);
  };

  const handleCancelDeleteRowArticle = () => {
    setRowEditArticle(initialStateArticle[0]);
    setIsLoadingArticle(false);
    setIsLoadingErrorArticle(false);
    setIdCodeDelete(DELETE_CODE);
    setOpenDeleteRowArticle(false);
  };
 
  const handleCloseDeleteRowArticle = (e) => {
    if (idCodeDelete !== DELETE_CODE_ARTICLE) {
      dispatch(warningErrorAction(t('Text_InvalidCode')));
      return;
    }
    let isMounted = true;
    if (e.key !== 'Escape') {
      if (rowEditArticle.id !== '') {
     
        const fetchData = async () => {
          setIsLoadingArticle(true);
          try {
            const getData = await helpHttp().del(ENDPOINT, `article/deletearticle`, token, rowEditArticle);
            if (getData.status === "OK") {
              if (isMounted) {
                dispatch(successErrorAction(t('Text_ValidCode')));
                dispatch(successErrorAction(t('Text_DeleteField')));
              }
            } else {
              if (isMounted) {
                dispatch(warningErrorAction(t(getData.cause)));
              }
            }
          } catch (error) {
            dispatch(warningErrorAction(t('Text_IncorrectData')));
            setIsLoadingArticle(false);
          } 
          if (isMounted) {
            setIsLoadingArticle(false); 
          }
        };
        fetchData();
      
      } else {
        dispatch(warningErrorAction(t('Text_InvalidId')));
      }
    }
    setIdCodeDelete(DELETE_CODE);
    setOpenDeleteRowArticle(false);
    setIsLoadingErrorArticle(false);
    handleCloseDataEditArticle();
    return () => { isMounted = false;};
  };
 

  const handleCloseDataEditArticle = () => {
    setOpenCreateDataArticle(false);
    setOpenEditRowArticle(false);
    setRowEditArticle(openEditRowArticle?rowEditArticle:initialStateArticle[0]);
    setIsLoadingArticle(false);
    setIsLoadingErrorArticle(false);
    toggleDrawerFilterEditArticle(false);
	};

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event && typeof event === 'object' && event.key) {
        keyPress(event);
      }
    };
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [keyPress]);
 
  useEffect(() => {
    if (numTotRegArticle > LIMITPAGESIZE) {
      setActiveOffsetArticle(true);
      const newRangeOffset = [
        {
          value: 0,
          label: " 1 - " + Number(LIMITPAGESIZE).toLocaleString('de-DE'),
        }
      ];
      for (let i = Number(LIMITPAGESIZE); i < numTotRegArticle; i += Number(LIMITPAGESIZE)) {
        newRangeOffset.push({
          value: i,
          label: " " + (i + 1).toLocaleString('de-DE') + " - " + (i + Number(LIMITPAGESIZE)).toLocaleString('de-DE'),
        });
      }
      setRangeOffsetArticle(newRangeOffset);
    } else {
      setCurrencyOffsetArticle(0);
      setActiveOffsetArticle(false);
      setRangeOffsetArticle([{}]);
    }
  }, [numTotRegArticle, LIMITPAGESIZE]);

  useEffect(() => {
    let isMounted = true;
    const fetchnumTotRegArticle = async () => {
      setIsLoadingArticle(true);
      try {
        const getData = await helpHttp().get(ENDPOINT, `article/getinformationtablearticle`, token);
        if (getData.err || getData.message === "Load failed") {
          if (isMounted) {
            setDataArticle(initialStateArticle);
            dispatch(warningErrorAction(t(getData.message === "Load failed" ? 'Text_ConnectionFailied' : 'Text_InvalidCredentials')));
          }
        } else if (isMounted) {
          setNumTotRegArticle(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData')));
        setIsLoadingArticle(false);
      }
      if (isMounted) {
        setIsLoadingArticle(false);
      } 
    };

    const fetchnumTotRegFilter = async () => {
      setIsLoadingArticle(true);
      try {
        const getData = await helpHttp().post(ENDPOINT, `article/getcountfilterarticle`, token, applyFiltersArticle);
        if (getData.err || getData.message === "Load failed") {
          if (isMounted) {
            setDataArticle(initialStateArticle);
            dispatch(warningErrorAction(t(getData.message === "Load failed" ? 'Text_ConnectionFailied' : 'Text_InvalidCredentials')));
          }
        } else if (isMounted) {
          setNumTotRegFilter(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData')));
        setIsLoadingArticle(false);
      }
      if (isMounted) {
        setIsLoadingArticle(false);
      } 
    }; 
    const fetchDataTypesOfTaxes = async () => {
      try {
        const getData = i18n.language === "es"
        ? await helpHttp().get(ENDPOINT, `article/typesoftaxesorderbynamees`, token)
        : await helpHttp().get(ENDPOINT, `article/typesoftaxesorderbynameen`, token);
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          setTypesOfTaxesToSelect(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };

    const fetchDataCodeLER = async () => {
      try {
        const getData =   await helpHttp().get(ENDPOINT, `article/codelersorderbycode`, token);
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          setCodeLERToSelect(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };

    const fetchDataArticle = async () => {
      setIsLoadingArticle(true);
      try {
        
        const getData = await helpHttp().get3(ENDPOINT, `article/getarticles`, token, currencyOffsetArticle, LIMITPAGESIZE, applyFiltersArticle);
        if (getData.err || getData.message === "Load failed") {
          if (isMounted) {
            setDataArticle(initialStateArticle);
            dispatch(warningErrorAction(t(getData.message === "Load failed" ? 'Text_ConnectionFailied' : 'Text_InvalidCredentials')));
          }
        } else if (isMounted) {
          setDataArticle(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData')));
        setIsLoadingArticle(false);
      } 
      if (isMounted) {
        setIsLoadingArticle(false);
      }
    };

    if (showModalArticlesSettings){
      fetchnumTotRegArticle();
      fetchnumTotRegFilter();
      fetchDataArticle();
      fetchDataCodeLER();
      fetchDataTypesOfTaxes(); 
      return () => { isMounted = false; };
    }
  }, [ENDPOINT,t,token,dispatch,i18n.language,openCreateDataArticle,openDeleteRowArticle,setOpenDeleteRowArticle,setOpenEditRowArticle,openEditRowArticle,rowEditArticle,showModalArticlesSettings,LIMITPAGESIZE,applyFiltersArticle,currencyOffsetArticle,setIsLoadingArticle]);

  return (
    <>
       {showModalArticlesSettings ? ( 
        <div className="container-fluid">
          {/* Search input */}
          <div id="myDIV">
            <h1 className="h1NewStyle mb-2">
            {isLoadingErrorArticle?<i className="fas fa-solid fa-exclamation fa-2x text-danger"></i>:isLoadingArticle?<CircularProgress color={openDeleteRowArticle?"warning":"primary"}/>:<i className="fas fa-shop text-warning"></i>} {t('Text_Articles_Table')}</h1>
            {roles[0].listmenus.find((key)=>(key.name==='SubMenu_Articles_Create')) ?
              <div>
                <button type="button" className="buttonCreate" onClick={openCreateOrEditDataArticle}>{t('Button_Create')}</button><> </>
                <button type="button" className="buttonBack" onClick={closeModalArticles}>{t('Button_Back')}</button><> </>
              </div>:
              <div>
                <button type="button" className="buttonBack" onClick={closeModalArticles}>{t('Button_Back')}</button><> </>
              </div>
            }
          </div>  
          <br/>
          <div className="searchStyle">
            <TextField
              id="outlined-basic"
              variant="outlined"
              value={inputTextArticle}
              placeholder={t('Text_Article_Search')} 
              onChange={inputHandlerArticle}
              fullWidth
              label={t('Text_Article_Search')}>
            </TextField>
          </div> 
          <br/>
          <div id="myDIV">
            <h1 className="h6NewStyle mb-2">
            {t('Text_Filter')}&nbsp;
                <button type="button" className="buttonFilter" onClick={openEditFiltersArticle}><i className="fas fa-solid fa-circle" style={{color:(applyFiltersArticle.idFrom!=="" || applyFiltersArticle.idTo!=="")?"#32CD32":"#FFFFFF"}}></i> {t('Text_Id')}</button><> </>
                <button type="button" className="buttonFilter" onClick={openEditFiltersArticle}><i className="fas fa-solid fa-circle" style={{color:(applyFiltersArticle.codeFrom!=="" || applyFiltersArticle.codeTo!=="")?"#32CD32":"#FFFFFF"}}></i> {t('Text_Code')}</button><> </>
                <button type="button" className="buttonFilter" onClick={openEditFiltersArticle}><i className="fas fa-solid fa-circle" style={{color:(applyFiltersArticle.nameesFrom!=="" || applyFiltersArticle.nameesTo!=="" || applyFiltersArticle.nameenFrom!=="" || applyFiltersArticle.nameenTo!=="")?"#32CD32":"#FFFFFF"}}></i> {t('Text_Name')}</button><> </>
                <button type="button" className="buttonFilter" onClick={openEditFiltersArticle}><i className="fas fa-solid fa-circle" style={{color:(applyFiltersArticle.typesoftaxFromES!=="" || applyFiltersArticle.typesoftaxToES!=="" || applyFiltersArticle.typesoftaxFromEN!=="" || applyFiltersArticle.typesoftaxToEN!=="")?"#32CD32":"#FFFFFF"}}></i> {t('Text_TypeOfTaxes')}</button><> </> 
                <button type="button" className="buttonFilter" onClick={openEditFiltersArticle}><i className="fas fa-solid fa-circle" style={{color:(applyFiltersArticle.codelerFrom!=="" || applyFiltersArticle.codelerTo!=="")?"#32CD32":"#FFFFFF"}}></i> {t('Text_CodeLER')}</button><> </>
                <button type="button" className="buttonFilter" onClick={openEditFiltersArticle}><i className="fas fa-solid fa-circle" style={{color:(applyFiltersArticle.typeinside)?"#32CD32":"#FFFFFF"}}></i> {t('Text_Inside')}</button><> </>
                <button type="button" className="buttonFilter" onClick={openEditFiltersArticle}><i className="fas fa-solid fa-circle" style={{color:(applyFiltersArticle.typeoutside)?"#32CD32":"#FFFFFF"}}></i> {t('Text_Outside')}</button><> </>
                <button type="button" className="buttonFilter" onClick={openEditFiltersArticle}><i className="fas fa-solid fa-circle" style={{color:(applyFiltersArticle.ticketweight)?"#32CD32":"#FFFFFF"}}></i> {t('Text_Weight')}</button><> </>
                <button type="button" className="buttonFilter" onClick={openEditFiltersArticle}><i className="fas fa-solid fa-circle" style={{color:(applyFiltersArticle.tickenotweight)?"#32CD32":"#FFFFFF"}}></i> {t('Text_NotWeight')}</button><> </>
                <button type="button" className="buttonFilter" onClick={openEditFiltersArticle}><i className="fas fa-solid fa-circle" style={{color:(applyFiltersArticle.enabled)?"#32CD32":"#FFFFFF"}}></i> {t('Text_enabled')}</button><> </>
                <button type="button" className="buttonFilter" onClick={openEditFiltersArticle}><i className="fas fa-solid fa-circle" style={{color:(applyFiltersArticle.notenabled)?"#32CD32":"#FFFFFF"}}></i> {t('Text_NOT_enabled')}</button><> </>
            </h1>
          </div>
          <br/>

          {!isLoadingArticle ?
            <>
              <div className="card shadow mb-4">    
                <div className="card-body">
                  <TableSortingArticle
                    dataArticle={filteredDataArticle}
                    numTotRegFilter={ numTotRegFilter}
                    titleTable={t('Text_Articles_Table')}
                    setOpenDeleteRowArticle={setOpenDeleteRowArticle}
                    setRowEditArticle={setRowEditArticle}
                    showEditButtonArticle={roles[0].listmenus.find((key)=>(key.name==='SubMenu_Articles_Edit'))?true:false}
                    showDeleteButtonArticle={roles[0].listmenus.find((key)=>(key.name==='SubMenu_Articles_Delete'))?true:false}
                    numTotRegArticle={numTotRegArticle}
                    activeOffsetArticle={activeOffsetArticle}
                    rangeOffsetArticle={rangeOffsetArticle}
                    currencyOffsetArticle={currencyOffsetArticle}
                    setCurrencyOffsetArticle={setCurrencyOffsetArticle} 
                    minvalue={Number(NON_ERASABLE_ARTICLES_ID)}> 
                  </TableSortingArticle>
                </div>             
              </div>
              {openEditFilterArticle &&
               <div>
               <SwipeableDrawer
                 anchor='right'
                 open={true}
                 onClose={toggleDrawerFilterEditArticle(false)}
                 onOpen={toggleDrawerFilterEditArticle(true)}>
                 <Container >
                   <Box sx={{ bgcolor: '#ffffff',height:'100vh', width: '850' }}>
                     <br/>
                       <div id="myDIV">
                         <h1 className="h1NewStyle px-4 mb-2">{t('Text_Filter')}</h1>
                       </div>
                       <hr className="sidebar-divider d-none d-md-block"/>
                       <form className="createfilterarticle" onSubmit={handleCancelFiltersArticle}>  
                         <div className="form-floating mb-3 px-4 primary">
                           <div className="form-group">
                             <TextField
                               id="idFrom" 
                               name="idFrom"
                               value={filtersArticle.idFrom}
                               inputProps={{ style: {width: 400}, maxLength: 15 }}
                               label={errorsFiltersArticle.idFrom!=null?t(errorsFiltersArticle.idFrom):t('Text_Filter_Id_From')}
                               placeholder={t('PlaceHolder_Filter_Id_From')}
                               variant="outlined"
                               onBlur={handleBlurFilterEditArticle}
                               onChange={handleChangeFilterEditArticle}/>
                             &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  
                             <TextField
                               id="idTo" 
                               name="idTo"
                               value={filtersArticle.idTo}
                               inputProps={{ style: {width: 400}, maxLength: 15 }}
                               label={errorsFiltersArticle.idTo!=null?t(errorsFiltersArticle.idTo):t('Text_Filter_Id_To')}
                               placeholder={t('PlaceHolder_Filter_Id_To')}
                               variant="outlined"
                               onBlur={handleBlurFilterEditArticle}
                               onChange={handleChangeFilterEditArticle}/>
                           </div>

                           <div className="form-group">
                             <TextField
                               id="codeFrom" 
                               name="codeFrom"
                               value={filtersArticle.codeFrom}
                               inputProps={{ style: {width: 400}, maxLength: 15 }}
                               label={errorsFiltersArticle.codeFrom!=null?t(errorsFiltersArticle.codeFrom):t('Text_Filter_Code_From')}
                               placeholder={t('PlaceHolder_Filter_Code_From')}
                               variant="outlined"
                               onBlur={handleBlurFilterEditArticle}
                               onChange={handleChangeFilterEditArticle}/>
                             &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  
                             <TextField
                               id="codeTo" 
                               name="codeTo"
                               value={filtersArticle.codeTo}
                               inputProps={{ style: {width: 400}, maxLength: 15 }}
                               label={errorsFiltersArticle.codeTo!=null?t(errorsFiltersArticle.codeTo):t('Text_Filter_Code_To')}
                               placeholder={t('PlaceHolder_Filter_Code_To')}
                               variant="outlined"
                               onBlur={handleBlurFilterEditArticle}
                               onChange={handleChangeFilterEditArticle}/>
                           </div>


                           <div className="form-group">
                             <TextField
                               id="nameesFrom" 
                               name="nameesFrom"
                               value={filtersArticle.nameesFrom}
                               inputProps={{ style: {width: 400}, maxLength: 100 }}
                               label={errorsFiltersArticle.nameesFrom!=null?t(errorsFiltersArticle.nameesFrom):t('Text_Filter_Namees_From')}
                               placeholder={t('PlaceHolder_Filter_Namees_From')}
                               variant="outlined"
                               onBlur={handleBlurFilterEditArticle}
                               onChange={handleChangeFilterEditArticle}/>
                             &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  
                             <TextField
                               id="nameesTo" 
                               name="nameesTo"
                               value={filtersArticle.nameesTo}
                               inputProps={{ style: {width: 400}, maxLength: 100 }}
                               label={errorsFiltersArticle.nameesTo!=null?t(errorsFiltersArticle.nameesTo):t('Text_Filter_Namees_To')}
                               placeholder={t('PlaceHolder_Filter_Namees_To')}
                               variant="outlined"
                               onBlur={handleBlurFilterEditArticle}
                               onChange={handleChangeFilterEditArticle}/>
                           </div>

                           <div className="form-group">
                             <TextField
                               id="nameenFrom" 
                               name="nameenFrom"
                               value={filtersArticle.nameenFrom}
                               inputProps={{ style: {width: 400}, maxLength: 100 }}
                               label={errorsFiltersArticle.nameenFrom!=null?t(errorsFiltersArticle.nameenFrom):t('Text_Filter_Nameen_From')}
                               placeholder={t('PlaceHolder_Filter_Nameen_From')}
                               variant="outlined"
                               onBlur={handleBlurFilterEditArticle}
                               onChange={handleChangeFilterEditArticle}/>
                             &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  
                             <TextField
                               id="nameenTo" 
                               name="nameenTo"
                               value={filtersArticle.nameenTo}
                               inputProps={{ style: {width: 400}, maxLength: 100 }}
                               label={errorsFiltersArticle.nameenTo!=null?t(errorsFiltersArticle.nameenTo):t('Text_Filter_Nameen_To')}
                               placeholder={t('PlaceHolder_Filter_Nameen_To')}
                               variant="outlined"
                               onBlur={handleBlurFilterEditArticle}
                               onChange={handleChangeFilterEditArticle}/>
                           </div>
                            
                            <Suspense delayMs={500} fallback={<CircularProgress sx={{ color :"#f6c23e"}}/>}>
                              <div className="form-group">
                                <TextField
                                  id={i18n.language==="es"?"typesoftaxFromES":"typesoftaxFromEN"}
                                  name={i18n.language==="es"?"typesoftaxFromES":"typesoftaxFromEN"}
                                  value={i18n.language==="es"?filtersArticle.typesoftaxFromES:filtersArticle.typesoftaxFromEN}
                                  inputProps={{ style: {width: 382}, maxLength: 100 }}
                                  label={i18n.language==="es"?errorsFiltersArticle.typesoftaxFromES!=null?t(errorsFiltersArticle.typesoftaxFromES):t('Text_Filter_TypeOfTax_From'):errorsFiltersArticle.typesoftaxFromEN!=null?t(errorsFiltersArticle.typesoftaxFromEN):t('Text_Filter_TypeOfTax_From')}
                                  placeholder={t('PlaceHolder_Filter_TypeOfTax_From')}
                                  variant="outlined"
                                  onBlur={handleBlurFilterEditArticle}
                                  onChange={handleChangeFilterEditArticle}
                                  select
                                  SelectProps={{
                                  native: true,
                                  }}>
                                  <option key={0} value=""/>
                                  {typesOfTaxesToSelect.map((option) => (
                                  <option key={option.id} value={i18n.language==="es"?option.namees:option.nameen}>
                                      {i18n.language==="es"?option.namees:option.nameen}
                                  </option>
                                  ))}
                                </TextField>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <TextField
                                    id={i18n.language==="es"?"typesoftaxToES":"typesoftaxToEN"}
                                    name={i18n.language==="es"?"typesoftaxToES":"typesoftaxToEN"}
                                    value={i18n.language==="es"?filtersArticle.typesoftaxToES:filtersArticle.typesoftaxToEN}
                                    inputProps={{ style: {width: 382}, maxLength: 100 }}
                                    label={i18n.language==="es"?errorsFiltersArticle.typesoftaxToES!=null?t(errorsFiltersArticle.typesoftaxToES):t('Text_Filter_TypeOfTax_To'):errorsFiltersArticle.typesoftaxToEN!=null?t(errorsFiltersArticle.typesoftaxToEN):t('PlaceHolder_Filter_TypeOfTax_To')}
                                    placeholder={t('PlaceHolder_Filter_TypeOfTax_To')}
                                    variant="outlined"
                                    onBlur={handleBlurFilterEditArticle}
                                    onChange={handleChangeFilterEditArticle}
                                    select
                                    SelectProps={{
                                    native: true,
                                    }}>
                                    <option key={0} value=""/>
                                    {typesOfTaxesToSelect.filter(item=>(i18n.language==="es"?item.namees>=filtersArticle.typesoftaxFromES:item.nameen>=filtersArticle.typesoftaxFromEN)).map(option => (
                                    <option key={option.id} value={i18n.language==="es"?option.namees:option.nameen}>
                                        {i18n.language==="es"?option.namees:option.nameen}
                                    </option>
                                    ))}
                                </TextField>
                              </div>

                              <div className="form-group">
                                <TextField
                                  id={"codelerFrom"}
                                  name={"codelerFrom"}
                                  value={filtersArticle.codelerFrom}
                                  inputProps={{ style: {width: 382}, maxLength: 100 }}
                                  label={errorsFiltersArticle.codelerFrom!=null?t(errorsFiltersArticle.codelerFrom):t('Text_Filter_CodeLER_From')}
                                  placeholder={t('PlaceHolder_Filter_CodeLER_From')}
                                  variant="outlined"
                                  onBlur={handleBlurFilterEditArticle}
                                  onChange={handleChangeFilterEditArticle}
                                  select
                                  SelectProps={{
                                  native: true,
                                  }}>
                                  <option key={0} value=""/>
                                  {codeLERToSelect.map((option) => (
                                  <option key={option.id} value={option.code}>
                                      {option.code} - {i18n.language==="es"?option.namees:option.nameen}
                                  </option>
                                  ))}
                                </TextField>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <TextField
                                    id={"codelerTo"}
                                    name={"codelerTo"}
                                    value={filtersArticle.codelerTo}
                                    inputProps={{ style: {width: 382}, maxLength: 100 }}
                                    label={errorsFiltersArticle.codelerTo!=null?t(errorsFiltersArticle.codelerTo):t('Text_Filter_CodeLER_To')}
                                    placeholder={t('PlaceHolder_Filter_CodeLER_To')}
                                    variant="outlined"
                                    onBlur={handleBlurFilterEditArticle}
                                    onChange={handleChangeFilterEditArticle}
                                    select
                                    SelectProps={{
                                    native: true,
                                    }}>
                                    <option key={0} value=""/>
                                    {codeLERToSelect.filter(item=>(item.code>=filtersArticle.codelerFrom)).map(option => (
                                    <option key={option.id} value={option.code}>
                                        {i18n.language==="es"?option.namees:option.nameen}
                                    </option>
                                    ))}
                                </TextField>
                              </div>

                            </Suspense>
                            <div className="form-group">
                              <Checkbox
                                id="typeinside" 
                                name="typeinside"
                                checked={filtersArticle.typeinside}
                                onChange={handleChangeInsideFilter}
                                variant="standard"/>
                              &nbsp;&nbsp;    
                              <label className="text-primary">{t('Text_Filter_Inside')}</label>  
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              <Checkbox
                                id="typeoutside" 
                                name="typeoutside"
                                checked={filtersArticle.typeoutside}
                                onChange={handleChangeOutsideFilter}
                                variant="standard"/>
                              &nbsp;&nbsp;    
                              <label className="text-primary">{t('Text_Filter_Outside')}</label>    
                            </div>
                            <div className="form-group"> 
                              <Checkbox
                                id="ticketweight" 
                                name="ticketweight"
                                checked={filtersArticle.ticketweight}
                                onChange={handleChangeWeightFilter}
                                variant="standard"/>
                              &nbsp;&nbsp;    
                              <label className="text-primary">{t('Text_Filter_Weight')}</label>  
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              <Checkbox
                                id="tickenotweight" 
                                name="tickenotweight"
                                checked={filtersArticle.tickenotweight}
                                onChange={handleChangeNotWeightFilter}
                                variant="standard"/>
                              &nbsp;&nbsp;    
                              <label className="text-primary">{t('Text_Filter_Not_Weight')}</label>    
                            </div>
                            <div className="form-group">  
                              <Checkbox
                                id="enabled" 
                                name="enabled"
                                checked={filtersArticle.enabled}
                                onChange={handleChangeEnabledFilter}
                                variant="standard"/>
                              &nbsp;&nbsp;    
                              <label className="text-primary">{t('Text_Filter_Habilty')}</label>  
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              <Checkbox
                                id="notenabled" 
                                name="notenabled"
                                checked={filtersArticle.notenabled}
                                onChange={handleChangeNotEnabledFilter}
                                variant="standard"/>
                              &nbsp;&nbsp;    
                              <label className="text-primary">{t('Text_Filter_Not_Habilty')}</label>   
  
                            </div>

                         </div>
                         <hr className="sidebar-divider d-none d-md-block"/>
                         <div className="btn-group d-grid d-md-flex justify-content-md-end" role="group">
                           <button className="btn btn-primary btn-lg " type="button" onClick={handleSubmitFilterEditArticle}>{t('Button_Apply')}</button><> </>
                           <button className="btn btn-outline-secondary btn-lg" type="button" onClick={handleDeleteFiltersArticle}>{t('Button_Cancel_Filters')}</button>
                         </div>
                     </form>
                   </Box>
                 </Container>
               </SwipeableDrawer>
             </div>
              }  
              <Dialog open={openDeleteRowArticle} onClose={handleCancelDeleteRowArticle}>
                <DialogTitle style={{color:"#e74a3b"}}>{t('Text_Delete')} {rowEditArticle.id}  - {rowEditArticle.code} - {i18n.language==='es'?rowEditArticle.namees:rowEditArticle.nameen}</DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      {t('Text_Line1')}<br/>
                      {t('Text_Line2')}
                    </DialogContentText>
                      <TextField
                        autoFocus
                        margin="dense"
                        id="idCode"
                        label={t('Text_Label')}
                        type="text"
                        fullWidth
                        variant="standard"
                        error
                        defaultValue={idCodeDelete}
                        onChange={handleChangeDeleteRowArticle}/>
                    </DialogContent>
                  <DialogActions>
                  <Button style={{color:"#e74a3b",fontSize: 16 }} onClick={handleCancelDeleteRowArticle}>{t('Button_Cancel')}</Button>
                  <Button variant="contained" style={{color:"#ffffff", background:"#e74a3b",fontSize: 16}} onClick={handleCloseDeleteRowArticle}>{t('Button_Delete')}</Button>
                </DialogActions>
              </Dialog> 
              {/* End Content Row */}
          </>:
            <Loading isLoadingData={isLoadingArticle}/>
          }
        </div>
        //* End Page Content *//
      ) :  null }
    </>
  )
}

ArticlesFilter.propTypes = {
  typeOfList: PropTypes.string.isRequired,
  applyFiltersArticle: PropTypes.object.isRequired,
  setApplyFiltersArticle: PropTypes.func.isRequired,
  showModalArticlesSettings: PropTypes.bool.isRequired,
  setShowModalArticlesSettings: PropTypes.func.isRequired 
};

export default ArticlesFilter;
