import React, {useState, useEffect} from 'react' 
import { useTheme } from '@mui/material/styles';
import { useTranslation } from "react-i18next"
import { TextField } from '@mui/material';
import { NumericFormat } from 'react-number-format';
import { Toolbar,TableFooter,TablePagination,TableSortLabel,Table,TableBody,TableCell,TableContainer, TableHead,TableRow,Paper,Typography } from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton'; 
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import PropTypes from 'prop-types';

const headCellsTicketsDetails = [
  { id: 'id', numeric: false, disablePadding: false, label: 'Text_Code', canOrder: true },
  { id: 'numberticket', numeric: false, disablePadding: false, label: 'Text_TICKETS_NUMBERTICKETS', canOrder: false },
  { id: 'vehiclestore_licenseplate', numeric: false, disablePadding: false, label: 'Text_TICKETS_LICENSEPLATE', canOrder: true },
  { id: 'start_date_ticket', numeric: false, disablePadding: false, label: 'Text_TICKETSDETAILS_FIRSTDATE_WEIGHTS', canOrder: true },
  { id: 'end_date_ticket', numeric: false, disablePadding: false, label: 'Text_TICKETSDETAILS_ENDDATE_WEIGHTS', canOrder: true },
  { id: 'rel_trans_tradename', numeric: false, disablePadding: false, label: 'Text_TICKETS_TRADENAME_TRANS', canOrder: true },
  { id: 'ori_tras_tradename', numeric: false, disablePadding: false, label: 'Text_TICKETS_NAME_STORE', canOrder: true },
  { id: 'totalstartweight', numeric: false, disablePadding: false, label: 'Text_TICKETSDETAILS_FIRST_WEIGHTS', canOrder: true },
  { id: 'totalendweight', numeric: false, disablePadding: false, label: 'Text_TICKETSDETAILS_END_WEIGHTS', canOrder: true },
  { id: 'formDIAssociates', numeric: false, disablePadding: false, label: 'Text_TICKETS_ASSOCIATES_DI', canOrder: true }
];

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
};

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
  
function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
  
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
          return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, t,showPrintButtonTicketsDetails,showDeleteButtonTicketsDetails,showStoreTicketsDetails,showUserAccountTicketsDetails} = props;
  const createSortHandler = (property) => (event) => { onRequestSort(event, property); };
  return (
    <TableHead>
      <TableRow>
        {headCellsTicketsDetails.map((headCell, indexhead) => (
          <TableCell
            key={indexhead}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.canOrder ?(
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              <Typography style={{color:"#4e73df", fontSize: 14}} >  
                {t(headCell.label)}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </Typography>
            </TableSortLabel>):
              <Typography style={{color:"#4e73df", fontSize: 14}} >  
                {t(headCell.label)}
              </Typography>}
          </TableCell>
        ))}
       
        {showPrintButtonTicketsDetails &&
          <TableCell
            key='print'
            align='center'
            padding='normal'
            sortDirection={false}
          >
            <Typography style={{color:"#e74a3b", fontSize: 14}} >
              {t('Button_Print_PDF')}
            </Typography>
          </TableCell>
        }

        {showDeleteButtonTicketsDetails &&
          <TableCell
            key='delete'
            align='center'
            padding='normal'
            sortDirection={false}
          >
            <Typography style={{color:"#e74a3b", fontSize: 14}} > 
              {t('Button_Delete_Back')}
            </Typography>
          </TableCell>
        }
        {showStoreTicketsDetails &&
          <TableCell
            key='tradename'
            align='center'
            padding='normal'
            sortDirection={order}
          >
            <TableSortLabel
              active={orderBy === 'tradename'}
              direction={orderBy === 'tradename' ? order : 'asc'}
              onClick={createSortHandler('tradename')}
            >
              <Typography style={{color:"#4e73df", fontSize: 14}} >  
                {t('Text_Store_Create')}
                {orderBy === 'tradename' ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </Typography>
            </TableSortLabel>
          </TableCell>
        }
        {showUserAccountTicketsDetails &&
          <TableCell
            key='username'
            align='center'
            padding='normal'
            sortDirection={order}
          >
            <TableSortLabel
              active={orderBy === 'username'}
              direction={orderBy === 'username' ? order : 'asc'}
              onClick={createSortHandler('username')}
            >
              <Typography style={{color:"#4e73df", fontSize: 14}} >  
                {t('Text_UserAcount_Create')}
                {orderBy === 'username' ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </Typography>
            </TableSortLabel>
          </TableCell>
        }  
      </TableRow>
    </TableHead>
  );
}

const EnhancedTableToolbar = (props) => {
  const { textHead, textReg, numTotRegFilter,numTotRegTicketsDetails, textOf  } = props;
    return (
      <Toolbar>
        <Typography style={{flex: '1 1 100%',color:"#4e73df", fontSize: 28}} id="tableTitle" component="div">
          {textHead}
        </Typography>
        <Typography style={{flexShrink: 0, ml: 2.5, color:"#4e73df", fontSize: 18}} id="tableTitle" component="div">
          {textReg} {(numTotRegFilter).toLocaleString('de-DE')} {textOf} {(numTotRegTicketsDetails).toLocaleString('de-DE')}
        </Typography>
    </Toolbar>
    );
};

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;
  const {t} = useTranslation(['listDataTickets']);

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label={t('Text_First_Page')}
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label={t('Text_Previous_Page')}
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label={t('Text_Next_Page')}
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label={t('Text_Last_Page')}
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const TableSortingTicketsDetails =(props)=>{
  const { 
    dataListTicketsDetails, 
    numTotRegFilter,
    titleTable,
    setOpenDeleteRowTicketsDetails,
    setRowEditTicketsDetails, 
    showPrintButtonTicketsDetails,
    showDeleteButtonTicketsDetails,
    showStoreTicketsDetails,
    showUserAccountTicketsDetails,
    numTotRegTicketsDetails,
    activeOffsetTicketsDetails,
    rangeOffsetTicketsDetails,
    currencyOffsetTicketsDetails,
    setCurrencyOffsetTicketsDetails,
    setViewPDF
  } = props;
  const {t,i18n} = useTranslation(['listDataTickets']);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [emptyRows, setEmptyRows] = useState(page > 0 ? Math.max(0, (1 + page) * rowsPerPage - dataListTicketsDetails.length) : 0);
  const [countData,setCountData] = useState(dataListTicketsDetails.length);
  
  useEffect(
    () => {
      if (dataListTicketsDetails.length!==countData || dataListTicketsDetails.length<=rowsPerPage){
        setPage(0);
      }  
      setCountData(dataListTicketsDetails.length);
      setEmptyRows(page > 0 ? Math.max(0, (1 + page) * rowsPerPage - dataListTicketsDetails.length) : 0);  
    },
    [dataListTicketsDetails,page,setEmptyRows,rowsPerPage,countData]
  );

  const handleChangeOffset = (event) => {
    setCurrencyOffsetTicketsDetails(event.target.value);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const openModalDeleteRowTickets = (row) => {
    setRowEditTicketsDetails(row);
    setOpenDeleteRowTicketsDetails(true);
  };

  const openModalTicketsPDF = async (row) => {
    setRowEditTicketsDetails(row);
    setViewPDF(true);
  }; 

  const listItems= (
  stableSort(dataListTicketsDetails, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, keyData) => {
    return (
      <TableRow hover tabIndex={-1} key={keyData}>
        <TableCell align="left">
          <Typography style={{color:"#4e73df", fontSize: 14, textDecorationLine: row.enabled?'':'line-through'}} >
            {row.id}
          </Typography>  
        </TableCell>
        <TableCell align="left">
         <Typography style={{color:"#000000", fontSize: 14, textDecorationLine: row.enabled?'':'line-through'}} >
            {row.numberticket}
          </Typography>
        </TableCell>
        <TableCell align="left">
          <Typography style={{color:"#000000", fontSize: 14, textDecorationLine: row.enabled?'':'line-through'}} >
            {row.vehiclestore_licenseplate}
          </Typography>
        </TableCell>
        <TableCell align="left">
          <Typography style={{color:"#000000", fontSize: 14, textDecorationLine: row.enabled?'':'line-through'}} >
          {row.start_date_ticket !== "" 
            ? new Date(row.start_date_ticket).toLocaleDateString(i18n.language === "es" ? "es-ES" : "en-US") + 
              " " + 
              new Date(row.start_date_ticket).toLocaleTimeString(i18n.language === "es" ? "es-ES" : "en-US")
            : ""}
          </Typography>
        </TableCell>
        <TableCell align="left">
          <Typography style={{color:"#000000", fontSize: 14, textDecorationLine: row.enabled?'':'line-through'}} >
            {row.end_date_ticket !== "" 
              ? new Date(row.end_date_ticket).toLocaleDateString(i18n.language === "es" ? "es-ES" : "en-US") + 
                " " + 
                new Date(row.end_date_ticket).toLocaleTimeString(i18n.language === "es" ? "es-ES" : "en-US")
              : ""
            }
           </Typography>
        </TableCell>
        <TableCell align="left">
          <Typography style={{color:"#000000", fontSize: 14, textDecorationLine: row.enabled?'':'line-through'}} >
            {row.rel_trans_tradename}
          </Typography>
        </TableCell>
        <TableCell align="left">
          <Typography style={{color:"#000000", fontSize: 14, textDecorationLine: row.enabled?'':'line-through'}} >
            {row.ori_tras_tradename}
          </Typography>
        </TableCell>
        <TableCell align="left">
          <Typography style={{color:"#000000", fontSize: 14}} >
            <NumericFormat 
              thousandSeparator={','} 
              decimalSeparator={'.'} 
              decimalScale= {0}
              fixedDecimalScale= {true}
              allowNegative= {false}
              id="totalstartweight" 
              name="totalstartweight"
              value={row.totalstartweight}
              style={{width:118, color:"#f5f5f5", background:"#bdbdbd",fontSize: 12, textAlign:'right', paddingRight:5}} 
              disabled
            />
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Typography style={{color:"#000000", fontSize: 14}} >
            <NumericFormat 
              thousandSeparator={','} 
              decimalSeparator={'.'} 
              decimalScale= {0}
              fixedDecimalScale= {true}
              allowNegative= {false}
              id="totalstartweight" 
              name="totalstartweight"
              value={row.totalendweight}
              style={{width:118, color:"#f5f5f5", background:"#bdbdbd",fontSize: 12, textAlign:'right', paddingRight:5}} 
              disabled
            /> 
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Typography style={{color:"#000000", fontSize: 14, textDecorationLine: row.enabled?'':'line-through'}} >
            {(row.formDIAssociates===undefined || row.formDIAssociates===null || row.formDIAssociates==="")?t('Text_NOT_REPORTING_DI'): row.formDIAssociates}
          </Typography>
        </TableCell>

         {(showPrintButtonTicketsDetails && row.id!=="" && row.id!==null ) &&
          <TableCell align="center">
            <IconButton  size="large"  style={{color:"#e74a3b", fontSize: 14}} onClick={()=>openModalTicketsPDF(row)}>
              <PictureAsPdfIcon/>
            </IconButton>
          </TableCell>
        }

        {(showDeleteButtonTicketsDetails && row.id!=="" && row.id!==null) &&
          <TableCell align="center">
            <IconButton  size="large"  style={{color:row.enabled?"#e74a3b":"#ffc107", fontSize: 14}} onClick={()=>openModalDeleteRowTickets(row)}>
              {row.enabled?<DeleteIcon/>:<DeleteForeverIcon/>} 
            </IconButton>
          </TableCell>}
          {showStoreTicketsDetails  &&
            <TableCell align="left">
              <Typography style={{color:"#000000", fontSize: 14, textDecorationLine: row.enabled?'':'line-through'}} >
                {row.tradename}
              </Typography>  
            </TableCell>
        }
        {showUserAccountTicketsDetails  &&
          <TableCell align="left">
            <Typography style={{color:"#000000", fontSize: 14, textDecorationLine: row.enabled?'':'line-through'}} >
              {row.username}
            </Typography>  
          </TableCell>
        } 
      </TableRow>
    );
  }));
  
  return(
    <>
      <Box sx={{ width: '100%' }}> 
        <EnhancedTableToolbar textHead={t(titleTable)} textReg={t('Text_Num_Reg')} numTotRegFilter={numTotRegFilter} numTotRegTicketsDetails={numTotRegTicketsDetails} textOf={t('Text_Of')}/>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size='small'>
              <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} t={t} showPrintButtonTicketsDetails={showPrintButtonTicketsDetails} showDeleteButtonTicketsDetails={showDeleteButtonTicketsDetails} showStoreTicketsDetails={showStoreTicketsDetails} showUserAccountTicketsDetails={showUserAccountTicketsDetails} />
              <TableBody>    
                {listItems}
                {emptyRows > 0 && (
                <TableRow style={{ height: 33  * emptyRows}}>
                  <TableCell colSpan={16} />
                </TableRow>
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                {activeOffsetTicketsDetails && (
                    <TableCell colSpan={2}>
                      <Box sx={{ flexShrink: 0, p: 1}}>
                        <div className="form-group">
                          <TextField
                            id="currencyOffsetTicketsDetails"
                            name="currencyOffsetTicketsDetails"
                            value={currencyOffsetTicketsDetails}
                            inputProps={{ style: {maxLength: 100, color:"#4e73df",fontSize:14}}}
                            placeholder={t('Text_Range_Offset')}
                            variant="standard"
                            onChange={handleChangeOffset}
                            helperText={t('Text_Range_Offset')}
                            select
                            SelectProps={{
                              native: true,
                              value: currencyOffsetTicketsDetails
                            }}>
                            {rangeOffsetTicketsDetails.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                          ))}
                          </TextField>
                        </div>
                      </Box>  
                    </TableCell>
                  )}
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    colSpan={16}
                    count={countData}
                    rowsPerPage={rowsPerPage}
                    page={countData===0?0:page}
                    labelRowsPerPage={t('Text_Rows_Per_Page')}
                    slotProps={{
                      inputProps: {
                        'aria-label': t('Text_Rows_Per_Page'),
                      },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
      </Box>
    </>
  )
}

TableSortingTicketsDetails.propTypes = {
  dataListTicketsDetails: PropTypes.array.isRequired, 
  numTotRegFilter: PropTypes.number.isRequired,
  titleTable : PropTypes.string.isRequired,
  isLoadingTicketsDetails: PropTypes.bool.isRequired,
  setIsLoadingTicketsDetails: PropTypes.func.isRequired,
  setOpenDeleteRowTicketsDetails: PropTypes.func.isRequired, 
  setRowEditTicketsDetails: PropTypes.func.isRequired,   
  showPrintButtonTicketsDetails: PropTypes.bool.isRequired,  
  showDeleteButtonTicketsDetails: PropTypes.bool.isRequired,
  showStoreTicketsDetails: PropTypes.bool.isRequired,
  showUserAccountTicketsDetails: PropTypes.bool.isRequired, 
  activeOffsetTicketsDetails: PropTypes.bool.isRequired,
  rangeOffsetTicketsDetails: PropTypes.array.isRequired,
  currencyOffsetTicketsDetails: PropTypes.number.isRequired,
  setCurrencyOffsetTicketsDetails: PropTypes.func.isRequired,
  setViewPDF: PropTypes.func.isRequired
  
};
  
export default TableSortingTicketsDetails;
