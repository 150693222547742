import React, { Suspense,useEffect, useCallback, useState } from 'react';
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next"
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CircularProgress from '@mui/material/CircularProgress';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment'
import DeleteIcon from '@mui/icons-material/Delete';
import "css/generalSettings.css";
import { successErrorAction,warningErrorAction } from "actions/ErrorActions";
import { helpHttp } from "service/HelpHttp";
import { SwipeableDrawer } from '@mui/material';
import { Checkbox} from  '@mui/material';
import { Container, Box } from '@mui/material';
import { TextField } from '@mui/material';
import { Button } from '@mui/material'; 
import TableSortingUserAccount  from "pages/users/SortingTablesUserAccount";
import { Loading } from "components/utils/Loading";
import PropTypes from 'prop-types';

const initialFilters= {
  "idFrom":"",
  "idTo":"",
  "usernameFrom":"",
  "usernameTo":"",
  "aliasuserFrom":"",
  "aliasuserTo":"",
  "firstNameFrom":"",
  "firstNameTo":"",
  "lastName1From":"",
  "lastName1To":"",
  "lastName2From":"",
  "lastName2To":"",
  "emailFrom":"",
  "emailTo":"",
  "typesdi_nameesFrom":"",
  "typesdi_nameesTo":"",
  "typesdi_nameenFrom":"",
  "typesdi_nameenTo":"",
  "ticketsformat_nameesFrom":"",
  "ticketsformat_nameesTo":"",
  "ticketsformat_nameenFrom":"",
  "ticketsformat_nameenTo":"",
  "isEnabled":true,
  "isNotEnabled":true,
  "isAccountNonLocked":true,
  "isNotAccountNonLocked":true
};

const initialState = [{
  "id" : "",
  "username" : "",
  "aliasuser":"",
  "password" : "",
  "firstName" : "",
  "lastName1" : "",
  "lastName2" : "",
  "email" : "",
  "enabled":true,
  "isAccountNonLocked":true,
  "language_id":1,
  "language_namees":"",
  "language_nameen":"",
  "color_id":2,
  "color_codehex":"",
  "store_id":1,
  "store_name":"",
  "role_id":4,
  "role_name":"",
  "employee_id":0,
  "employee_fullname":"",
  "typesdi_id":1,
  "typesdi_namees":"",
  "typesdi_nameen":"",
  "ticketsformat_id":1,
  "ticketsformat_namees":"",
  "ticketsformat_nameen":""
 }];

const headCells = [
  {
    id: 'color_id',
    numeric: false,
    disablePadding: false,
    label: 'Text_User_Color',
    canOrder:false,
  },
  {
    id: 'id',
    numeric: false,
    disablePadding: false,
    label: 'Text_Code',
    canOrder:true,
  },
  {
    id: 'username',
    numeric: false,
    disablePadding: false,
    label: 'Text_User',
    canOrder:true,
  },
  {
    id: 'aliasuser',
    numeric: false,
    disablePadding: false,
    label: 'Text_AliasUser',
    canOrder:true,
  },
  {
    id: 'firstName',
    numeric: false,
    disablePadding: false,
    label: 'Text_Name',
    canOrder:true,
  },
  {
    id: 'lastName1',
    numeric: false,
    disablePadding: false,
    label: 'Text_LastName1',
    canOrder:true,
  },
  {
    id: 'lastName2',
    numeric: false,
    disablePadding: false,
    label: 'Text_LastName2',
    canOrder:true,
  },
  {
    id: 'email',
    numeric: false,
    disablePadding: false,
    label: 'Text_Email',
    canOrder:true,
  },
  {
    id: 'defaulttype',
    numeric: false,
    disablePadding: false,
    label: 'Text_FormatDI',
    canOrder:true,
  },
  {
    id: 'defaultticketsformat',
    numeric: false,
    disablePadding: false,
    label: 'Text_TicketsFormat',
    canOrder:true,
  },
  {
    id: 'enabled',
    numeric: false,
    disablePadding: false,
    label: 'Text_Enabled',
    canOrder:false,
  },
  {
    id: 'isAccountNonLocked',
    numeric: false,
    disablePadding: false,
    label: 'Text_isAccountNonLocked',
    canOrder:false,
  },
  {
    id: 'language_namees',
    numeric: false,
    disablePadding: false,
    label: 'Text_User_Language',
    canOrder:true,
  },
  {
    id: 'role_name',
    numeric: false,
    disablePadding: false,
    label: 'Text_User_Roles',
    canOrder:true,
  },
  {
    id: 'store_name',
    numeric: false,
    disablePadding: false,
    label: 'Text_User_Stores',
    canOrder:true,
  },
  {
    id: 'employee_id',
    numeric: false,
    disablePadding: false,
    label: 'Text_User_Selected_Employee',
    canOrder:true,
  }
];

const initialTypesToSelect = [{
  "id":0,
  "namees":"",
  "nameen":false
}];

const initialColorsToSelect = [{
  "id":0,
  "namees":"",
  "nameen":"",
  "codehex":""
}];

const initialTicketsFormatToSelect = [{
  "id":0,
  "namees":"",
  "nameen":"",
  "limitsarticleperweight":""
}];

const initialRolesToSelect = [{
  "id":0,
  "name":""
}];

const initialStoreToSelect = [{
  "id":0,
  "deafaultStore":true,
  "name":"",
  "tradename":""
}];
 
const UserSettings = ({setShowPrincipalMenu,showUserSettings, setShowModalUserSettings,setShowModalGeneralSettings}) => {
  const {t,i18n} = useTranslation(['listDataGeneralSettings']);
  const token = useSelector((state) => state.loginUser.token); 
  const roles = useSelector((state) => state.loginUser.roles);  
  const username = useSelector((state) => state.loginUser.username);  
  const ENDPOINT = useSelector((state) => state.variablesUser.ENDPOINT); 
  const LIMITPAGESIZE = useSelector((state) => state.variablesUser.LIMITPAGESIZE); 
  const DEFAULTLANGUAGE = useSelector((state) => state.variablesUser.DEFAULTLANGUAGE); 
  const DEFAULTCOLOR = useSelector((state) => state.variablesUser.DEFAULTCOLOR); 
  const DEFAULTROLE = useSelector((state) => state.variablesUser.DEFAULTROLE); 
  const DEFAULTSTORE = useSelector((state) => state.variablesUser.DEFAULTSTORE); 
  const DEFAULTTYPESDI = useSelector((state) => state.variablesUser.DEFAULTTYPESDI); 
  const NON_ERASABLE_USERACCOUNT_ID = useSelector((state) => state.variablesUser.NON_ERASABLE_USERACCOUNT_ID); 
  const DELETE_CODE = useSelector((state) => state.formatDIUser.delete_code); 
  const [data, setData] = useState(initialState);
  const [form, setForm] = useState(initialState[0]);
  const [filters, setFilters]= useState(initialFilters);
  const [applyFilters, setApplyFilters]= useState(initialFilters);
  const [numTotReg,setNumTotReg]= useState(0);
  const [numTotRegFilter,setNumTotRegFilter]= useState(0);
  const [errorsFilters, setErrorsFilters] = useState({});
  const [errors, setErrors] = useState({});
  const [inputText, setInputText] = useState("");
  const [openCreateData,setOpenCreateData]= useState(false);
  const [openDeleteRow,setOpenDeleteRow]= useState(false);
  const [openEditRow,setOpenEditRow]= useState(false);
  const [openEditFilter,setOpenEditFilter]= useState(false);
  const [rowEdit,setRowEdit]= useState(initialState[0]);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingError, setIsLoadingError] = useState(false);
  const [idCodeDelete,setIdCodeDelete]=useState(DELETE_CODE);
  const dispatch = useDispatch();
  const [activeOffset, setActiveOffset]= useState(false);
  const [currencyOffset, setCurrencyOffset] = useState(1);
  const [rangeOffset, setRangeOffset] = useState([{}]);
  const [showPassword, setShowPassword] = useState(false);
  const [newPassword, setNewPassword] = useState(false);
  const [languagesToSelect,setLanguagesToSelect]= useState(initialTypesToSelect);
  const [colorsToSelect,setColorsToSelect]= useState(initialColorsToSelect);
  const [rolesToSelect,setRolesToSelect]= useState(initialRolesToSelect);
  const [typesDIToSelect,setTypesDIToSelect]= useState(initialTypesToSelect);
  const [ticketsFormatToSelect,setTicketsFormatToSelect]= useState(initialTicketsFormatToSelect);

  const [storesToSelect,setStoresToSelect]= useState(initialStoreToSelect); 
  const userNotToDelete=["Master","Admin","Provider","User","Employee",username];
 
  const keyPress = useCallback(
    e => {
      if (e.key === 'Escape' && showUserSettings) {
        setData(initialState);
        setForm(initialState[0]);
        setFilters(initialFilters);
        setApplyFilters(initialFilters);
        setNumTotReg(0);
        setNumTotRegFilter(0);
        setErrorsFilters({});
        setErrors({});
        setInputText("");
        setOpenCreateData(false);
        setOpenDeleteRow(false);
        setOpenEditRow(false);
        setOpenEditFilter(false);
        setRowEdit(initialState[0]);
        setIsLoading(false);
        setIsLoadingError(false);
        setIdCodeDelete(DELETE_CODE);
        setActiveOffset(false);
        setCurrencyOffset(1);
        setRangeOffset([{}]);
        setShowPassword(false);
        setNewPassword(false);
        setLanguagesToSelect(initialTypesToSelect);
        setColorsToSelect(initialColorsToSelect);
        setRolesToSelect(initialRolesToSelect);
        setTypesDIToSelect(initialTypesToSelect);
        setStoresToSelect(initialStoreToSelect);
        setTicketsFormatToSelect(initialTicketsFormatToSelect);
        setShowPrincipalMenu(true);
        setShowModalUserSettings(false);
        setShowModalGeneralSettings(false);
      }
    },
    [
      setData,
      setForm,
      setFilters,
      setApplyFilters,
      setNumTotReg,
      setNumTotRegFilter,
      setErrorsFilters,
      setErrors,
      setInputText,
      setOpenCreateData,
      setOpenDeleteRow,
      setOpenEditRow,
      setOpenEditFilter,
      setRowEdit,
      setIsLoading,
      setIsLoadingError,
      setIdCodeDelete,
      setActiveOffset,
      setCurrencyOffset,
      setRangeOffset,
      setShowPassword,
      setNewPassword,
      setLanguagesToSelect,
      setColorsToSelect,
      setRolesToSelect,
      setTypesDIToSelect,
      setTicketsFormatToSelect,
      setStoresToSelect,
      setShowPrincipalMenu,
      showUserSettings, 
      setShowModalUserSettings,
      setShowModalGeneralSettings,
      DELETE_CODE]
  );

  const closeModalUserTypeSettings = () => {    
    setData(initialState);
    setForm(initialState[0]);
    setFilters(initialFilters);
    setApplyFilters(initialFilters);
    setNumTotReg(0);
    setNumTotRegFilter(0);
    setErrorsFilters({});
    setErrors({});
    setInputText("");
    setOpenCreateData(false);
    setOpenDeleteRow(false);
    setOpenEditRow(false);
    setOpenEditFilter(false);
    setRowEdit(initialState[0]);
    setIsLoading(false);
    setIsLoadingError(false);
    setIdCodeDelete(DELETE_CODE);
    setActiveOffset(false);
    setCurrencyOffset(1);
    setRangeOffset([{}]);
    setShowPassword(false);
    setNewPassword(false);
    setLanguagesToSelect(initialTypesToSelect);
    setColorsToSelect(initialColorsToSelect);
    setRolesToSelect(initialRolesToSelect);
    setTypesDIToSelect(initialTypesToSelect);
    setStoresToSelect(initialStoreToSelect);
    setTicketsFormatToSelect(initialTicketsFormatToSelect);
    setShowPrincipalMenu(true);
    setShowModalUserSettings(false);
    setShowModalGeneralSettings(false);
  };

  const openCreateOrEditData = () => {
    setInputText("");
    setOpenCreateData(true);
  };

  const openEditFilters = () => {
    setFilters(applyFilters);
    setOpenEditFilter(!openEditFilter);
  };

  const handleSubmitFilterEdit = () => {
    setErrorsFilters(validateFormFilter());
    if (Object.keys(errorsFilters).length === 0) {
      setApplyFilters(filters);
      setFilters(initialFilters);
      setErrorsFilters({});
      setOpenEditFilter(false);
    }  else{
      dispatch(warningErrorAction(t('Text_Error_Filter')));
    }
  }
  
  const handleDeleteFilters = () => {
    setErrorsFilters({});
    setFilters(initialFilters);
    setApplyFilters(initialFilters);
    setOpenEditFilter(!openEditFilter);
  };

  const handleCancelFilters = () => {
    setErrorsFilters({});
    setFilters(initialFilters);
    setOpenEditFilter(!openEditFilter);
  };

  const handleChangeEnabledFilter = (e) => {
    setFilters(filters => ({
      ...filters,
      isEnabled: !filters.isEnabled
    }));
  };

  const handleChangeNotEnabledFilter = (e) => {
    setFilters(filters => ({
      ...filters,
      isNotEnabled: !filters.isNotEnabled
    }));
  };

  const handleChangeisAccountNonLockedFilter = (e) => {
    setFilters(filters => ({
      ...filters,
      isAccountNonLocked: !filters.isAccountNonLocked
    }));
  };

  const handleChangeisNotAccountNonLockedFilter = (e) => {
    setFilters(filters => ({
      ...filters,
      isNotAccountNonLocked: !filters.isNotAccountNonLocked
    }));
  };
  
  const handleChangeFilterEdit = (e) => {
    const { name, value } = e.target;
    setFilters(filters => ({
      ...filters,
      [name]: value
    }));
  };

  const handleBlurFilterEdit = (e) => {
    setErrorsFilters(validateFormFilter());
    handleChangeFilterEdit(e);
  };

  const toggleDrawerFilterEdit = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    if (event.type === 'keydown') {
      event.preventDefault();
      setErrorsFilters({});
      setFilters(initialFilters);
      setOpenEditFilter(!openEditFilter);
    }
  };

  const validateFormFilter = () => {

    let errorsFilters = {};
    let regexInvalidCharacters = /[\\!\\$\\%\\&\\|\\<\\>\\?\\{\\}\\~\\"\\']+/;
    let regexNumber = /^[0-9]+$/;
    if (filters.idFrom!=="" & !regexNumber.test(filters.idFrom)) {
      errorsFilters.idFrom = 'Text_TextNoNumber';
    }
    if (filters.idTo!=="" & !regexNumber.test(filters.idTo)) {
      errorsFilters.idTo = 'Text_TextNoNumber';
    }
    if (filters.idFrom!=="" & !errorsFilters.idFrom!==undefined){
      if (filters.idTo!=="" & !errorsFilters.idTo!==undefined){
        if (parseInt(filters.idTo)<parseInt(filters.idFrom)){
          errorsFilters.idTo = 'Text_TextGreater_Id';
        }
      }
    }

    if (filters.usernameFrom!=="" & regexInvalidCharacters.test(filters.usernameFrom)) {
      errorsFilters.usernameFrom = 'Text_TextNoValid';
    }    
    if (filters.usernameTo!=="" & regexInvalidCharacters.test(filters.usernameTo)) {
      errorsFilters.usernameTo = 'Text_TextNoValid';
    }
    if (filters.usernameTo!=="" & filters.usernameTo<filters.usernameFrom){
      errorsFilters.usernameTo = 'Text_TextGreater_Username';
    }

    if (filters.aliasuserFrom!=="" & regexInvalidCharacters.test(filters.aliasuserFrom)) {
      errorsFilters.aliasuserFrom = 'Text_TextNoValid';
    }    
    if (filters.aliasuserTo!=="" & regexInvalidCharacters.test(filters.aliasuserTo)) {
      errorsFilters.aliasuserTo = 'Text_TextNoValid';
    }
    if (filters.aliasuserTo!=="" & filters.aliasuserTo<filters.aliasuserFrom){
      errorsFilters.aliasuserTo = 'Text_TextGreater_AliasUser';
    }

    if (filters.firstNameFrom!=="" & regexInvalidCharacters.test(filters.firstNameFrom)) {
      errorsFilters.firstNameFrom = 'Text_TextNoValid';
    }    
    if (filters.firstNameTo!=="" & regexInvalidCharacters.test(filters.firstNameTo)) {
      errorsFilters.firstNameTo = 'Text_TextNoValid';
    }
    if (filters.firstNameTo!=="" & filters.firstNameTo<filters.firstNameFrom){
      errorsFilters.firstNameTo = 'Text_TextGreater_FirstName';
    }

    if (filters.lastName1From!=="" & regexInvalidCharacters.test(filters.lastName1From)) {
      errorsFilters.lastName1From = 'Text_TextNoValid';
    }    
    if (filters.lastName1To!=="" & regexInvalidCharacters.test(filters.lastName1To)) {
      errorsFilters.lastName1To = 'Text_TextNoValid';
    }
    if (filters.lastName1To!=="" & filters.lastName1To<filters.lastName1From){
      errorsFilters.lastName1To = 'Text_TextGreater_LastName1';
    }

    if (filters.lastName2From!=="" & regexInvalidCharacters.test(filters.lastName2From)) {
      errorsFilters.lastName2From = 'Text_TextNoValid';
    }    
    if (filters.lastName2To!=="" & regexInvalidCharacters.test(filters.lastName2To)) {
      errorsFilters.lastName2To = 'Text_TextNoValid';
    }
    if (filters.lastName2To!=="" & filters.lastName2To<filters.lastName2From){
      errorsFilters.lastName2To = 'Text_TextGreater_LastName2';
    }

    if (filters.emailFrom!=="" & regexInvalidCharacters.test(filters.emailFrom)) {
      errorsFilters.emailFrom = 'Text_TextNoValid';
    }    
    if (filters.emailTo!=="" & regexInvalidCharacters.test(filters.emailTo)) {
      errorsFilters.emailTo = 'Text_TextNoValid';
    }
    if (filters.typesdi_nameesTo!=="" & filters.typesdi_nameesTo<filters.typesdi_nameesFrom){
      filters.typesdi_nameesTo="";
      errorsFilters.typesdi_nameesTo = 'Text_TextGreater_typeDI';
    }
    if (filters.typesdi_nameenTo!=="" & filters.typesdi_nameenTo<filters.typesdi_nameenFrom){
        filters.typesdi_nameenTo="";
        errorsFilters.typesdi_nameenTo = 'Text_TextGreater_typeDI';
    }

    if (filters.ticketsformat_nameesTo!=="" & filters.ticketsformat_nameesTo<filters.ticketsformat_nameesFrom){
      filters.ticketsformat_nameesTo="";
      errorsFilters.ticketsformat_nameesTo = 'Text_TextGreater_TicketsFormat';
    }
    if (filters.ticketsformat_nameenTo!=="" & filters.ticketsformat_nameenTo<filters.ticketsformat_nameenFrom){
        filters.ticketsformat_nameenTo="";
        errorsFilters.ticketsformat_nameenTo = 'Text_TextGreater_TicketsFormat';
    }

    if (filters.vehicleStoreTo!=="" & filters.vehicleStoreTo<filters.vehicleStoreFrom){
      errorsFilters.vehicleStoreTo = 'Text_TextGreater_vehicles';
    }

    if (filters.emailTo!=="" & filters.emailTo<filters.emailFrom){
      errorsFilters.emailTo = 'Text_TextGreater_Email';
    }
    
    return errorsFilters;
  }  

  const filteredData = data.filter((el) => {
    if (inputText === '') {
        return el;
    }
    else {
      let dataTranslate=t(el.id+el.username+el.aliasuser+el.firstName+el.lastName1+el.lastName2) ;
      return dataTranslate.toLowerCase().includes(inputText);
    }
  })

  let inputHandler = (e) => {
    var lowerCase = e.target.value.toLowerCase();
    setInputText(lowerCase);
  };

  const handleChangeDeleteRow = (e) => {
    const value  = e.target.value;
    setIdCodeDelete(value);
  };

  const handleCancelDeleteRow = () => {
    setErrors({});
    setRowEdit(initialState[0]);
    setForm(initialState[0]);
    setIsLoading(false);
    setIsLoadingError(false);
    setShowPassword(false);
    setNewPassword(false);
    setIdCodeDelete(DELETE_CODE);
    setOpenDeleteRow(false);
  };

  const handleCloseDeleteRow = (e) => {
    if (idCodeDelete !== DELETE_CODE) {
      dispatch(warningErrorAction(t('Text_InvalidCode')));
      return;
    }
    if (e.key !== 'Escape') {
      if (rowEdit.id !== '') {
        const fetchData = async () => {
          setIsLoading(true);
          try {
            const getData = await helpHttp().del(ENDPOINT, `useraccount/deleteuseraccount`, token, rowEdit);
            if (getData.status === "OK") {
              dispatch(successErrorAction(t('Text_ValidCode')));
              dispatch(successErrorAction(t('Text_DeleteField')));
            } else {
              dispatch(warningErrorAction(t(getData.cause)));
            }
          } catch (error) {
            dispatch(warningErrorAction(t('Text_IncorrectData')));
            setIsLoading(false);
          } finally {
            setIsLoading(false);
          }
        };
        fetchData();
      } else {
        dispatch(warningErrorAction(t('Text_InvalidId')));
      }
    }
  
    setIdCodeDelete(DELETE_CODE);
    setOpenDeleteRow(false);
    handleCloseDataEdit();
  };

  const handleSubmitDataEdit = () => {
    setErrors(validateForm());
    const errorsToCheck = validateForm();
  
    if ((Object.keys(errorsToCheck).length === 0) 
      && form.username!==''  
      && form.aliasuser!=='' 
      && form.color_id!==undefined && form.color_id!==null 
      && form.color_id!=='' && form.color_id!=='0' && form.color_id!==0 
      && form.store_id!==undefined && form.store_id!==null 
      && form.store_id!=='' && form.store_id!=='0' && form.store_id!==0 
      && form.typesdi_id!=='' && form.typesdi_id!=='0' && form.typesdi_id!==0 
      && form.ticketsformat_id!=='' && form.ticketsformat_id!=='0' && form.ticketsformat_id!==0 
      && form.useraccount_email!==''
    ) {
      const fetchData = async () => {
        setIsLoading(true);
        try {
          const getData = openEditRow
            ? await helpHttp().put(ENDPOINT, `useraccount/updateuseraccount`, token, form)
            : await helpHttp().post(ENDPOINT, `useraccount/saveuseraccount`, token, form);
  
          if (getData.status === "OK") {
            dispatch(successErrorAction(t('Text_SaveData')));
            handleCloseDataEdit();
          } else {
            dispatch(warningErrorAction(t(getData.cause)));
          }
        } catch (error) {
          dispatch(warningErrorAction(t('Text_IncorrectData')));
          setIsLoading(false);
        } finally {
          setIsLoading(false);
        }
      };
      fetchData();
    } else {
      if (errorsToCheck.username) {
        dispatch(warningErrorAction(t(errorsToCheck.username)));
      } else if (errorsToCheck.aliasuser) {
        dispatch(warningErrorAction(t(errorsToCheck.aliasuser)));
      } else if (errorsToCheck.password) {
        dispatch(warningErrorAction(t(errorsToCheck.password)));
      } else if (errorsToCheck.firstName) {
        dispatch(warningErrorAction(t(errorsToCheck.firstName)));
      } else if (errorsToCheck.lastName1) {
        dispatch(warningErrorAction(t(errorsToCheck.lastName1)));
      } else if (errorsToCheck.email) {
        dispatch(warningErrorAction(t(errorsToCheck.email)));
      } else if (errorsToCheck.language_id) {
        dispatch(warningErrorAction(t(errorsToCheck.language_id)));
      } else if (errorsToCheck.color_id) {
        dispatch(warningErrorAction(t(errorsToCheck.color_id)));
      } else if (errorsToCheck.role_id) {
        dispatch(warningErrorAction(t(errorsToCheck.role_id)));
      } else if (errorsToCheck.store_id) {
        dispatch(warningErrorAction(t(errorsToCheck.store_id)));
      } else if (errorsToCheck.typesdi_namees) {
        dispatch(warningErrorAction(t(errorsToCheck.typesdi_namees)));
      } else if (errorsToCheck.typesdi_nameen) {
        dispatch(warningErrorAction(t(errorsToCheck.typesdi_nameen)));
      } else if (errorsToCheck.ticketsformat_namees) {
        dispatch(warningErrorAction(t(errorsToCheck.ticketsformat_namees)));
      } else if (errorsToCheck.ticketsformat_nameen) {
        dispatch(warningErrorAction(t(errorsToCheck.ticketsformat_nameen)));
      } else if (errorsToCheck.typesdi_id) {
        dispatch(warningErrorAction(t(errorsToCheck.typesdi_id)));
      } else if (errorsToCheck.ticketsformat_id) {
        dispatch(warningErrorAction(t(errorsToCheck.ticketsformat_id)));
      } else {
        dispatch(warningErrorAction(t('Text_IncorrectData')));
      }
    }
  };

  const handleCloseDataEdit = () => {
    setOpenCreateData(false);
    setOpenEditRow(false);
    setErrors({});
    setRowEdit(openEditRow?rowEdit:initialState[0]);
    setForm(initialState[0]);
    setIsLoading(false);
    setIsLoadingError(false);
    setShowPassword(false);
    setNewPassword(false);
    toggleDrawerDataEdit(false);
	};

  const handleBlurSelectStore = (event) => {
    handleChangeSelectStore(event);
    setErrors(validateForm());
  };

  const handleChangeSelectStore = (event) => {
    setForm(form => ({
      ...form,
      store_id:event.target.value 
    }));
  };

  const handleChangeSelectWeighVehiclesFormat = (event) => {
    setForm(form => ({
      ...form,
      ticketsformat_id:event.target.value
    }));
  };

  const handleBlurSelectWeighVehiclesFormat = (e) => {
    handleChangeSelectWeighVehiclesFormat(e);
  };

  const handleChangeSelectTypeDI = (event) => {
    setForm(form => ({
      ...form,
      typesdi_id:event.target.value
    }));
  };

  const handleBlurSelectTypeDI = (e) => {
    handleChangeSelectTypeDI(e);
  };
 
  const handleBlurSelectRole = (e) => {
    handleChangeSelectRole(e);
    setErrors(validateForm());
  };

  const handleChangeSelectRole = (event) => {
    setForm(form => ({
        ...form,
        role_id:event.target.value
    }));
  };


  const handleBlurSelectColor = (e) => {
    handleChangeSelectColor(e);
    setErrors(validateForm());
  };
  
  const handleChangeSelectColor = (event) => {
    let colorHex="#FFFFFF";
    if (event.target.value!==""){
      colorHex=colorsToSelect.find((key=>key.id===Number(event.target.value))).codehex;
    }
    setForm(form => ({
        ...form,
        color_id:event.target.value,
        color_codehex:colorHex
    }));
  };

  const handleBlurSelectLanguage = (e) => {
    handleChangeSelectLanguage(e);
    setErrors(validateForm());
  };

  const handleChangeSelectLanguage = (event) => {
    setForm(form => ({
        ...form,
        language_id:event.target.value
    }));
  };

  const handleisAccountNonLocked = (e) => {
    setForm(form => ({
      ...form,
      isAccountNonLocked: !form.isAccountNonLocked
    }));
  };
  
  const handleChangeEnabled = (e) => {
    setForm(form => ({
      ...form,
      enabled: !form.enabled
    }));
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleDeletePassword = () => {
    setForm(form => ({
      ...form,
      password: ""
    }));
    setNewPassword(true);
  };

  const handleBlurDataEdit = (e) => {
    handleChangeDataEdit(e);
    setErrors(validateForm());
  };
  
  const handleChangeDataEdit = (e) => {
    const { name, value } = e.target;
    setForm(form => ({
      ...form,
      [name]: value
    }));
  };

  const toggleDrawerDataEdit = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    if (event.type === 'keydown') {
      event.preventDefault()
      handleCloseDataEdit();
    }
  };

  const validateForm = () => {
    let errors = {};
    let regexInvalidCharacters = /[\\!\\$\\%\\&\\|\\<\\>\\?\\{\\}\\~\\"\\']+/;
    
    if (!form.username || form.username==="") {
      errors.username = 'Text_FieldUsernameRequired';
    } else {
      if (regexInvalidCharacters.test(form.username)) {
        errors.username = 'Text_TextNoValid';
      } else{
        if (openEditRow){
          if (data.find((key=>(key.username.toLowerCase()===form.username.toLowerCase() && key.id!==rowEdit.id)))) {
            errors.username = 'Text_User_UserNameExist';
          }
        } else {
          if (data.find((key=>key.username.toLowerCase()===form.username.toLowerCase()))) {
            errors.username = 'Text_User_UserNameExist';
          }
        }  
      }   
    }
    if (!form.aliasuser || form.aliasuser==="") {
      errors.aliasuser = 'Text_FieldAliasuserRequired';
    } else {
      if (regexInvalidCharacters.test(form.aliasuser)) {
        errors.aliasuser = 'Text_TextNoValid';
      } else{
        if (openEditRow){
          if (data.find((key=>(key.aliasuser.toLowerCase()===form.aliasuser.toLowerCase() && key.id!==rowEdit.id)))) {
            errors.aliasuser = 'Text_User_AliasuserExist';
          }
        } else {
          if (data.find((key=>key.aliasuser.toLowerCase()===form.aliasuser.toLowerCase()))) {
            errors.aliasuser = 'Text_User_AliasuserExist';
          }
        }   
      }  
    }
   
    if (!form.firstName || form.firstName==="") {
      errors.firstName = 'Text_FieldFirstNameRequired';
    }else {
      if (regexInvalidCharacters.test(form.firstName)) {
        errors.firstName = 'Text_TextNoValid';
      }
    }  

    if (!form.lastName1 || form.lastName1==="") {
      errors.lastName1 = 'Text_FieldLastName1Required';
    }else {
      if (regexInvalidCharacters.test(form.lastName1)) {
        errors.lastName1 = 'Text_TextNoValid';
      }
    } 
    if (form.lastName2!=="" && regexInvalidCharacters.test(form.lastName2)) {
      errors.lastName2 = 'Text_TextNoValid';
    }

    if (!form.email || form.email==="") {
      errors.email = 'Text_FieldEmailRequired';
    } else {
      if (regexInvalidCharacters.test(form.email)) {
        errors.email = 'Text_TextNoValid';
      } else{
      if (openEditRow){
          if (data.find((key=>(key.email.toLowerCase()===form.email.toLowerCase().trim() && key.id!==rowEdit.id)))) {
            errors.email = 'Text_User_EmailExist';
          }
        } else {
          if (data.find((key=>key.email.toLowerCase()===form.email.toLowerCase().trim()))) {
            errors.email = 'Text_User_EmailExist';
          }
        }  
      }     
    }
    if (form.language_id===undefined || form.language_id===null || form.language_id==="" || form.language_id==="0" || form.language_id===0) {
      errors.language_id = 'Text_FieldLanguageRequired';
    }

    if (form.color_id===undefined || form.color_id===null || form.color_id==="" || form.color_id==="0" || form.color_id===0) {
      errors.color_id = 'Text_FieldColorRequired';
    }

    if (form.typesdi_id===undefined || form.typesdi_id===null ||form.typesdi_id==="" || form.typesdi_id===0 || form.typesdi_id==="0") {
      errors.typesdi_id = 'Text_FieldTypesDiRequired';
    }

    if (form.ticketsformat_id===undefined || form.ticketsformat_id===null ||form.ticketsformat_id==="" || form.ticketsformat_id===0 || form.ticketsformat_id==="0") {
      errors.ticketsformat_id = 'Text_FieldTicketsFormatRequired';
    }

    if (form.role_id===undefined || form.role_id===null || form.role_id==="" || form.role_id==="0" || form.role_id===0) {
      errors.role_id = 'Text_FieldRoleRequired';
    }

    if (form.store_id===undefined || form.store_id===null || form.store_id==="" || form.store_id==="0" || form.store_id===0) {
      errors.store_id = 'Text_FieldStoreRequired';
    }
 
    return errors;
  };

  useEffect(() => {
    setForm(openEditRow?rowEdit:initialState[0]);
  },[rowEdit,openEditRow]);

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event && typeof event === 'object' && event.key) {
        keyPress(event);
      }
    };
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [keyPress]);

  useEffect(() => {
    if (numTotRegFilter>LIMITPAGESIZE){
      setActiveOffset(true);
      setRangeOffset([
        { value: 0,
          label: " 1 - "+Number(LIMITPAGESIZE).toLocaleString('de-DE'),
        }
      ]);
      for (let i = Number(LIMITPAGESIZE); i<numTotRegFilter; i=i+ Number(LIMITPAGESIZE)){
        setRangeOffset(
          current=>[
            ...current,
            ...[
              {
                value: i,
                label: " "+(i+1).toLocaleString('de-DE')+" - "+(i+Number(LIMITPAGESIZE)).toLocaleString('de-DE'),
              }
            ]
          ]
        )
      }
    } else{
      setCurrencyOffset(0);
      setActiveOffset(false);
      setRangeOffset([{}]);
    }
  },[numTotRegFilter,LIMITPAGESIZE]);

  useEffect(() => {
    let ignore = false;
    const fetchDataLanguage = async () => {
      try {
        const getData = i18n.language==="es"
        ? await helpHttp().get(ENDPOINT, `language/languagesorderbynamees`, token)
        : await helpHttp().get(ENDPOINT, `language/languagesorderbynameen`, token);
      if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (!ignore) {
          setLanguagesToSelect(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };
  
    const fetchDataColor = async () => {
      try {
        const getData = i18n.language==="es"
        ? await helpHttp().get(ENDPOINT, `color/colorsorderbynamees`, token)
        : await helpHttp().get(ENDPOINT, `color/colorsorderbynameen`, token);
         if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (!ignore) {
          setColorsToSelect(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };
  
    const fetchDataRole = async () => {
      try {
        const getData = await helpHttp().get(ENDPOINT, `roleuser/rolesorderbyname`, token);
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (!ignore) {
          setRolesToSelect(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };

    const fetchDataStore = async () => {
      try {
        const getData = await helpHttp().get(ENDPOINT, `store/storesorderbyname`, token);
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (!ignore) {
          setStoresToSelect(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };

    const fetchDataTypeDI = async () => {
      try {
        const getData = i18n.language==="es"
        ? await helpHttp().get(ENDPOINT, `typesdi/typesdisorderbynamees`, token)
        : await helpHttp().get(ENDPOINT, `typesdi/typesdisorderbynameen`, token);
         if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (!ignore) {
          setTypesDIToSelect(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };

    const fetchDataTicketsFormat = async () => {
      try {
        const getData = i18n.language==="es"
        ? await helpHttp().get(ENDPOINT, `useraccount/ticketsformatorderbynamees`, token)
        : await helpHttp().get(ENDPOINT, `useraccount/ticketsformatorderbynameen`, token);
         if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (!ignore) {
          setTicketsFormatToSelect(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };
  
    if ((openCreateData || openEditRow || openEditFilter)){
      fetchDataLanguage();
      fetchDataColor();
      fetchDataRole();
      fetchDataTypeDI();
      fetchDataTicketsFormat();
      fetchDataStore(); 
      return () => { ignore = true };
    }
  }, [ENDPOINT,t,token,dispatch,i18n,roles,openCreateData,openEditRow,openEditFilter]);
 
  useEffect(() => {
    let ignore = false;  
    const fetchnumTotReg = async () => {
      setIsLoading(true);
      try {
        const getData = await helpHttp().get(ENDPOINT, `useraccount/getinformationtableuseraccount`, token);
        if (getData.err || getData.message === "Load failed") {
          setData(initialState);
          dispatch(warningErrorAction(t(getData.message === "Load failed" ? 'Text_ConnectionFailied' : 'Text_InvalidCredentials')));
        } else if (!ignore) {
          setNumTotReg(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData')));
        setIsLoading(false);
      } finally {
        setIsLoading(false);
      }
    };
  
    const fetchnumTotRegFilter = async () => {
      setIsLoading(true);
      try {
        const getData = await helpHttp().post(ENDPOINT, `useraccount/getcountfilteruseraccounts`, token, applyFilters);
        if (getData.err || getData.message === "Load failed") {
          setData(initialState);
          dispatch(warningErrorAction(t(getData.message === "Load failed" ? 'Text_ConnectionFailied' : 'Text_InvalidCredentials')));
        } else if (!ignore) {
          setNumTotRegFilter(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData')));
        setIsLoading(false);
      } finally {
        setIsLoading(false);
      }
    };
  
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const getData = await helpHttp().get3(ENDPOINT, `useraccount/useraccounts`, token, currencyOffset, LIMITPAGESIZE, applyFilters);
        if (getData.err || getData.message === "Load failed") {
          setData(initialState);
          dispatch(warningErrorAction(t(getData.message === "Load failed" ? 'Text_ConnectionFailied' : 'Text_InvalidCredentials')));
        } else if (!ignore) {
          setData(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData')));
        setIsLoading(false);
      } finally {
        setIsLoading(false);
      }
    };
  
    if (showUserSettings) {
      fetchnumTotReg();
      fetchnumTotRegFilter();
      fetchData();
      return () => { ignore = true; };
    }
  }, [ENDPOINT, t, token, dispatch, openCreateData, openDeleteRow, openEditRow, rowEdit, showUserSettings, LIMITPAGESIZE, applyFilters, currencyOffset]);
  return (
    <>
      {showUserSettings ? ( 
        <div className="container-fluid">
            {/* Search input */}
            <div id="myDIV">
              <h1 className="h1NewStyle mb-2">
                {isLoadingError?
                <i className="fas fa-solid fa-exclamation fa-2x text-danger"></i>
                :isLoading?
                <CircularProgress color={openDeleteRow?"warning":"primary"}/>:
                <i className="fas fa-solid fa-user text-warning"></i>} {t('Text_User_Config')}</h1>
                {isLoading ?
                  <CircularProgress sx={{ color : "#f6c23e"}}/>:
                  <>
                    {roles[0].listmenus.find((key)=>(key.name==='SubMenu_UserAccount_Create'))?
                      <div>
                        <button type="button" className="buttonCreate" onClick={openCreateOrEditData}>{t('Button_Create')}</button><> </>
                        <button type="button" className="buttonBack" onClick={closeModalUserTypeSettings}>{t('Button_Back')}</button>
                      </div>:
                      <div>
                        <button type="button" className="buttonBack" onClick={closeModalUserTypeSettings}>{t('Button_Back')}</button>
                      </div>
                    }
                  </>
              }
          </div>  
          <br/>
          {!isLoading ?
          <>
            <div className="searchStyle">
              <TextField
                id="outlined-basic"
                variant="outlined"
                value={inputText}
                placeholder={t('Text_User_Search')}
                onChange={inputHandler}
                fullWidth
                label={t('Text_User_Search')}>
              </TextField>
            </div>
            <br/>
            <div id="myDIV">
              <h1 className="h6NewStyle mb-2">
              {t('Text_Filter')}&nbsp;
                  <button type="button" className="buttonFilter" onClick={openEditFilters}><i className="fas fa-solid fa-circle" style={{color:(applyFilters.idFrom!=="" || applyFilters.idTo!=="")?"#32CD32":"#FFFFFF"}}></i> {t('Text_Code')}</button><> </>
                  <button type="button" className="buttonFilter" onClick={openEditFilters}><i className="fas fa-solid fa-circle" style={{color:(applyFilters.usernameFrom!=="" || applyFilters.usernameTo!=="")?"#32CD32":"#FFFFFF"}}></i> {t('Text_User')}</button><> </>
                  <button type="button" className="buttonFilter" onClick={openEditFilters}><i className="fas fa-solid fa-circle" style={{color:(applyFilters.aliasuserFrom!=="" || applyFilters.aliasuserTo!=="")?"#32CD32":"#FFFFFF"}}></i> {t('Text_AliasUser')}</button><> </>
                  <button type="button" className="buttonFilter" onClick={openEditFilters}><i className="fas fa-solid fa-circle" style={{color:(applyFilters.firstNameFrom!=="" || applyFilters.firstNameTo!=="")?"#32CD32":"#FFFFFF"}}></i> {t('Text_Name')}</button><> </>
                  <button type="button" className="buttonFilter" onClick={openEditFilters}><i className="fas fa-solid fa-circle" style={{color:(applyFilters.lastName1From!=="" || applyFilters.lastName1To!=="" || applyFilters.lastName2From!=="" || applyFilters.lastName2To!=="")?"#32CD32":"#FFFFFF"}}></i> {t('Text_LastName')}</button><> </>
                  <button type="button" className="buttonFilter" onClick={openEditFilters}><i className="fas fa-solid fa-circle" style={{color:(applyFilters.typesdi_nameesFrom!=="" || applyFilters.typesdi_nameesTo!=="" || applyFilters.typesdi_nameenFrom!=="" || applyFilters.typesdi_nameenTo!=="")?"#32CD32":"#FFFFFF"}}></i> {t('Text_FormatDI')}</button><> </> 
                  <button type="button" className="buttonFilter" onClick={openEditFilters}><i className="fas fa-solid fa-circle" style={{color:(applyFilters.ticketsformat_nameesFrom!=="" || applyFilters.ticketsformat_nameesTo!=="" || applyFilters.ticketsformat_nameenFrom!=="" || applyFilters.ticketsformat_nameenTo!=="")?"#32CD32":"#FFFFFF"}}></i> {t('Text_TicketsFormat')}</button><> </> 
                  <button type="button" className="buttonFilter" onClick={openEditFilters}><i className="fas fa-solid fa-circle" style={{color:(applyFilters.emailFrom!=="" || applyFilters.emailTo!=="")?"#32CD32":"#FFFFFF"}}></i> {t('Text_Email')}</button><> </>
                  <button type="button" className="buttonFilter" onClick={openEditFilters}><i className="fas fa-solid fa-circle" style={{color:(applyFilters.isEnabled)?"#32CD32":"#FFFFFF"}}></i> {t('Text_Enabled')}</button><> </>
                  <button type="button" className="buttonFilter" onClick={openEditFilters}><i className="fas fa-solid fa-circle" style={{color:(applyFilters.isNotEnabled)?"#32CD32":"#FFFFFF"}}></i> {t('Text_Not_Enabled')}</button><> </>
                  <button type="button" className="buttonFilter" onClick={openEditFilters}><i className="fas fa-solid fa-circle" style={{color:(applyFilters.isAccountNonLocked)?"#32CD32":"#FFFFFF"}}></i> {t('Text_Account_Not_Locked')}</button><> </>
                  <button type="button" className="buttonFilter" onClick={openEditFilters}><i className="fas fa-solid fa-circle" style={{color:(applyFilters.isNotAccountNonLocked)?"#32CD32":"#FFFFFF"}}></i> {t('Text_Account_Locked')}</button><> </>
                </h1>
            </div>
            <br/>
            <div className="card shadow mb-4">    
              <div className="card-body">
                <TableSortingUserAccount
                  data={filteredData}
                  headCells={headCells}
                  numTotRegFilter={numTotRegFilter}
                  titleTable={t('Text_User_Table')}
                  setOpenDeleteRow={setOpenDeleteRow}
                  setOpenEditRow={setOpenEditRow}
                  setRowEdit={setRowEdit}
                  showEditButton={roles[0].listmenus.find((key)=>(key.name==='SubMenu_UserAccount_Edit'))?true:false}
                  showDeleteButton={roles[0].listmenus.find((key)=>(key.name==='SubMenu_UserAccount_Delete'))?true:false}
                  numTotReg={numTotReg}
                  activeOffset={activeOffset}
                  rangeOffset={rangeOffset}
                  currencyOffset={currencyOffset}
                  setCurrencyOffset={setCurrencyOffset}
                  username={username}
                  minvalue={Number(NON_ERASABLE_USERACCOUNT_ID)}>
                </TableSortingUserAccount>
              </div>             
            </div>
            {openEditFilter &&
              <div>
                <SwipeableDrawer
                  anchor='right'
                  open={true}
                  onClose={toggleDrawerFilterEdit(false)}
                  onOpen={toggleDrawerFilterEdit(true)}>
                  <Container >
                    <Box sx={{ bgcolor: '#ffffff',height:'100vh', width: '500px' }}>
                      <br/>
                      <div id="myDIV">
                        <h1 className="h1NewStyle px-4 mb-2">{t('Text_Filter')}</h1>
                      </div>
                      <hr className="sidebar-divider d-none d-md-block"/>
                      <form className="createfilter" onSubmit={handleCancelFilters}>  
                        <div className="form-floating mb-3 px-4 primary">
                          <label className="text-primary">{t('Text_Filter_Code_From')} :</label>
                          <div className="form-group">
                            <TextField
                              id="idFrom" 
                              name="idFrom"
                              value={filters.idFrom}
                              inputProps={{ style: {width: 450}, maxLength: 15 }}
                              label={errorsFilters.idFrom!=null?t(errorsFilters.idFrom):t('PlaceHolder_Filter_Code_From')}
                              placeholder={t('PlaceHolder_Filter_Code_From')}
                              variant="standard"
                              onBlur={handleBlurFilterEdit}
                              onChange={handleChangeFilterEdit}/>
                          </div>
                          <label className="text-primary">{t('Text_Filter_Code_To')} :</label>
                          <div className="form-group">
                            <TextField
                              id="idTo" 
                              name="idTo"
                              value={filters.idTo}
                              inputProps={{ style: {width: 450}, maxLength: 15 }}
                              label={errorsFilters.idTo!=null?t(errorsFilters.idTo):t('PlaceHolder_Filter_Code_to')}
                              placeholder={t('PlaceHolder_Filter_Code_to')}
                              variant="standard"
                              onBlur={handleBlurFilterEdit}
                              onChange={handleChangeFilterEdit}/>
                          </div>
                          <label className="text-primary">{t('Text_Filter_UserName_From')} :</label>
                          <div className="form-group">
                            <TextField
                              id="usernameFrom" 
                              name="usernameFrom"
                              value={filters.usernameFrom}
                              inputProps={{ style: {width: 450}, maxLength: 100 }}
                              label={errorsFilters.usernameFrom!=null?t(errorsFilters.usernameFrom):t('PlaceHolder_Filter_UserName_From')}
                              placeholder={t('PlaceHolder_Filter_UserName_From')}
                              variant="standard"
                              onBlur={handleBlurFilterEdit}
                              onChange={handleChangeFilterEdit}/>
                          </div>
                          <label className="text-primary">{t('Text_Filter_UserName_To')} :</label>
                          <div className="form-group">
                            <TextField
                              id="usernameTo" 
                              name="usernameTo"
                              value={filters.usernameTo}
                              inputProps={{ style: {width: 450}, maxLength: 100 }}
                              label={errorsFilters.usernameTo!=null?t(errorsFilters.usernameTo):t('PlaceHolder_Filter_UserName_to')}
                              placeholder={t('PlaceHolder_Filter_UserName_to')}
                              variant="standard"
                              onBlur={handleBlurFilterEdit}
                              onChange={handleChangeFilterEdit}/>
                          </div>
                          <label className="text-primary">{t('Text_Filter_AliasUser_From')} :</label>
                          <div className="form-group">
                            <TextField
                              id="aliasuserFrom" 
                              name="aliasuserFrom"
                              value={filters.aliasuserFrom}
                              inputProps={{ style: {width: 450}, maxLength: 100 }}
                              label={errorsFilters.aliasuserFrom!=null?t(errorsFilters.aliasuserFrom):t('PlaceHolder_Filter_AliasUser_From')}
                              placeholder={t('PlaceHolder_Filter_AliasUser_From')}
                              variant="standard"
                              onBlur={handleBlurFilterEdit}
                              onChange={handleChangeFilterEdit}/>
                          </div>
                          <label className="text-primary">{t('Text_Filter_AliasUser_To')} :</label>
                          <div className="form-group">
                            <TextField
                              id="aliasuserTo" 
                              name="aliasuserTo"
                              value={filters.aliasuserTo}
                              inputProps={{ style: {width: 450}, maxLength: 100 }}
                              label={errorsFilters.aliasuserTo!=null?t(errorsFilters.aliasuserTo):t('PlaceHolder_Filter_AliasUser_to')}
                              placeholder={t('PlaceHolder_Filter_AliasUser_to')}
                              variant="standard"
                              onBlur={handleBlurFilterEdit}
                              onChange={handleChangeFilterEdit}/>
                          </div>
                          <label className="text-primary">{t('Text_Filter_Name_From')} :</label>
                          <div className="form-group">
                            <TextField
                              id="firstNameFrom" 
                              name="firstNameFrom"
                              value={filters.firstNameFrom}
                              inputProps={{ style: {width: 450}, maxLength: 100 }}
                              label={errorsFilters.firstNameFrom!=null?t(errorsFilters.firstNameFrom):t('PlaceHolder_Filter_Name_From')}
                              placeholder={t('PlaceHolder_Filter_Name_From')}
                              variant="standard"
                              onBlur={handleBlurFilterEdit}
                              onChange={handleChangeFilterEdit}/>
                          </div>
                          <label className="text-primary">{t('Text_Filter_Name_To')} :</label>
                          <div className="form-group">
                            <TextField
                              id="firstNameTo" 
                              name="firstNameTo"
                              value={filters.firstNameTo}
                              inputProps={{ style: {width: 450}, maxLength: 100 }}
                              label={errorsFilters.firstNameTo!=null?t(errorsFilters.firstNameTo):t('PlaceHolder_Filter_Name_to')}
                              placeholder={t('PlaceHolder_Filter_Name_to')}
                              variant="standard"
                              onBlur={handleBlurFilterEdit}
                              onChange={handleChangeFilterEdit}/>
                          </div>
                          <label className="text-primary">{t('Text_Filter_LastName1_From')} :</label>
                          <div className="form-group">
                            <TextField
                              id="lastName1From" 
                              name="lastName1From"
                              value={filters.lastName1From}
                              inputProps={{ style: {width: 450}, maxLength: 100 }}
                              label={errorsFilters.lastName1From!=null?t(errorsFilters.lastName1From):t('PlaceHolder_Filter_LastName1_From')}
                              placeholder={t('PlaceHolder_Filter_LastName1_From')}
                              variant="standard"
                              onBlur={handleBlurFilterEdit}
                              onChange={handleChangeFilterEdit}/>
                          </div>
                          <label className="text-primary">{t('Text_Filter_LastName1_To')} :</label>
                          <div className="form-group">
                            <TextField
                              id="lastName1To" 
                              name="lastName1To"
                              value={filters.lastName1To}
                              inputProps={{ style: {width: 450}, maxLength: 100 }}
                              label={errorsFilters.lastName1To!=null?t(errorsFilters.lastName1To):t('PlaceHolder_Filter_LastName1_to')}
                              placeholder={t('PlaceHolder_Filter_LastName1_to')}
                              variant="standard"
                              onBlur={handleBlurFilterEdit}
                              onChange={handleChangeFilterEdit}/>
                          </div>
                          <label className="text-primary">{t('Text_Filter_LastName2_From')} :</label>
                          <div className="form-group">
                            <TextField
                              id="lastName2From" 
                              name="lastName2From"
                              value={filters.lastName2From}
                              inputProps={{ style: {width: 450}, maxLength: 100 }}
                              label={errorsFilters.lastName2From!=null?t(errorsFilters.lastName2From):t('PlaceHolder_Filter_LastName2_From')}
                              placeholder={t('PlaceHolder_Filter_LastName2_From')}
                              variant="standard"
                              onBlur={handleBlurFilterEdit}
                              onChange={handleChangeFilterEdit}/>
                          </div>
                          <label className="text-primary">{t('Text_Filter_LastName2_To')} :</label>
                          <div className="form-group">
                            <TextField
                              id="lastName2To" 
                              name="lastName2To"
                              value={filters.lastName2To}
                              inputProps={{ style: {width: 450}, maxLength: 100 }}
                              label={errorsFilters.lastName2To!=null?t(errorsFilters.lastName2To):t('PlaceHolder_Filter_LastName2_to')}
                              placeholder={t('PlaceHolder_Filter_LastName2_to')}
                              variant="standard"
                              onBlur={handleBlurFilterEdit}
                              onChange={handleChangeFilterEdit}/>
                          </div>
                          <label className="text-primary">{t('Text_Filter_Email_From')} :</label>
                          <div className="form-group">
                            <TextField
                              id="emailFrom" 
                              name="emailFrom"
                              value={filters.emailFrom}
                              inputProps={{ style: {width: 450}, maxLength: 100 }}
                              label={errorsFilters.emailFrom!=null?t(errorsFilters.emailFrom):t('PlaceHolder_Filter_Email_From')}
                              placeholder={t('PlaceHolder_Filter_Email_From')}
                              variant="standard"
                              onBlur={handleBlurFilterEdit}
                              onChange={handleChangeFilterEdit}/>
                          </div>
                          <label className="text-primary">{t('Text_Filter_Email_to')} :</label>
                          <div className="form-group">
                            <TextField
                              id="emailTo" 
                              name="emailTo"
                              value={filters.emailTo}
                              inputProps={{ style: {width: 450}, maxLength: 100 }}
                              label={errorsFilters.emailTo!=null?t(errorsFilters.emailTo):t('PlaceHolder_Filter_Email_to')}
                              placeholder={t('PlaceHolder_Filter_Email_to')}
                              variant="standard"
                              onBlur={handleBlurFilterEdit}
                              onChange={handleChangeFilterEdit}/>
                          </div>
                          <Suspense delayMs={500} fallback={<CircularProgress sx={{ color :"#f6c23e"}}/>}>
                            <label className="text-primary">{t('Text_Filter_typeDI_From')} :</label>
                            <div className="form-group">
                              <TextField
                                id={i18n.language==="es"?"typesdi_nameesFrom":"typesdi_nameenFrom"}
                                name={i18n.language==="es"?"typesdi_nameesFrom":"typesdi_nameenFrom"}
                                value={i18n.language==="es"?filters.typesdi_nameesFrom:filters.typesdi_nameenFrom}
                                inputProps={{ style: {width: 400}, maxLength: 100 }}
                                label={i18n.language==="es"?errorsFilters.typesdi_nameesFrom!=null?t(errorsFilters.typesdi_nameesFrom):t('PlaceHolder_typeDI_From'):errorsFilters.typesdi_nameenFrom!=null?t(errorsFilters.typesdi_nameenFrom):t('Text_Filter_typeDI_From')}
                                placeholder={t('PlaceHolder_typeDI_From')}
                                variant="outlined"
                                onBlur={handleBlurFilterEdit}
                                onChange={handleChangeFilterEdit}
                                select
                                SelectProps={{
                                native: true,
                                }}>
                                <option key={0} value=""/>
                                {typesDIToSelect.map((option) => (
                                <option key={option.id} value={i18n.language==="es"?option.namees:option.nameen}>
                                    {i18n.language==="es"?option.namees:option.nameen}
                                </option>
                                ))}
                              </TextField>
                            </div>
                            <label className="text-primary">{t('Text_Filter_typeDI_To')} :</label>
                            <div className="form-group">
                              <TextField
                                  id={i18n.language==="es"?"typesdi_nameesTo":"typesdi_nameenTo"}
                                  name={i18n.language==="es"?"typesdi_nameesTo":"typesdi_nameenTo"}
                                  value={i18n.language==="es"?filters.typesdi_nameesTo:filters.typesdi_nameenTo}
                                  inputProps={{ style: {width: 400}, maxLength: 100 }}
                                  label={i18n.language==="es"?errorsFilters.typesdi_nameesTo!=null?t(errorsFilters.typesdi_nameesTo):t('PlaceHolder_typeDI_To'):errorsFilters.typesdi_nameenTo!=null?t(errorsFilters.typesdi_nameenTo):t('Text_Filter_typeDI_To')}
                                  placeholder={t('PlaceHolder_typeDI_To')}
                                  variant="outlined"
                                  onBlur={handleBlurFilterEdit}
                                  onChange={handleChangeFilterEdit}
                                  select
                                  SelectProps={{
                                  native: true,
                                  }}>
                                  <option key={0} value=""/>
                                  {typesDIToSelect.filter(item=>(i18n.language==="es"?item.namees>=filters.typesdi_nameesFrom:item.nameen>=filters.typesdi_nameenFrom)).map(option => (
                                  <option key={option.id} value={i18n.language==="es"?option.namees:option.nameen}>
                                      {i18n.language==="es"?option.namees:option.nameen}
                                  </option>
                                  ))}
                                </TextField>
                              </div>

                              <label className="text-primary">{t('Text_Filter_TicketsFormat_From')} :</label>
                              <div className="form-group">
                                <TextField
                                  id={i18n.language==="es"?"ticketsformat_nameesFrom":"ticketsformat_nameenFrom"}
                                  name={i18n.language==="es"?"ticketsformat_nameesFrom":"ticketsformat_nameenFrom"}
                                  value={i18n.language==="es"?filters.ticketsformat_nameesFrom:filters.ticketsformat_nameenFrom}
                                  inputProps={{ style: {width: 400}, maxLength: 100 }}
                                  label={i18n.language==="es"?errorsFilters.ticketsformat_nameesFrom!=null?t(errorsFilters.ticketsformat_nameesFrom):t('PlaceHolder_Filter_TicketsFormat_From'):errorsFilters.ticketsformat_nameenFrom!=null?t(errorsFilters.ticketsformat_nameenFrom):t('Text_Filter_TicketsFormat_From')}
                                  placeholder={t('PlaceHolder_Filter_TicketsFormat_From')}
                                  variant="outlined"
                                  onBlur={handleBlurFilterEdit}
                                  onChange={handleChangeFilterEdit}
                                  select
                                  SelectProps={{
                                  native: true,
                                  }}>
                                  <option key={0} value=""/>
                                  {ticketsFormatToSelect.map((option) => (
                                  <option key={option.id} value={i18n.language==="es"?option.namees:option.nameen}>
                                      {i18n.language==="es"?option.namees:option.nameen}
                                  </option>
                                  ))}
                                </TextField>
                              </div>
                              <label className="text-primary">{t('Text_Filter_TicketsFormat_To')} :</label>
                              <div className="form-group">
                                <TextField
                                    id={i18n.language==="es"?"ticketsformat_nameesTo":"ticketsformat_nameenTo"}
                                    name={i18n.language==="es"?"ticketsformat_nameesTo":"ticketsformat_nameenTo"}
                                    value={i18n.language==="es"?filters.ticketsformat_nameesTo:filters.ticketsformat_nameenTo}
                                    inputProps={{ style: {width: 400}, maxLength: 100 }}
                                    label={i18n.language==="es"?errorsFilters.ticketsformat_nameesTo!=null?t(errorsFilters.ticketsformat_nameesTo):t('PlaceHolder_Filter_TicketsFormat_To'):errorsFilters.ticketsformat_nameenTo!=null?t(errorsFilters.ticketsformat_nameenTo):t('Text_Filter_WeighVehiclesFormat_To')}
                                    placeholder={t('PlaceHolder_Filter_TicketsFormat_To')}
                                    variant="outlined"
                                    onBlur={handleBlurFilterEdit}
                                    onChange={handleChangeFilterEdit}
                                    select
                                    SelectProps={{
                                    native: true,
                                    }}>
                                    <option key={0} value=""/>
                                    {ticketsFormatToSelect.filter(item=>(i18n.language==="es"?item.namees>=filters.ticketsformat_nameesFrom:item.nameen>=filters.ticketsformat_nameenFrom)).map(option => (
                                    <option key={option.id} value={i18n.language==="es"?option.namees:option.nameen}>
                                        {i18n.language==="es"?option.namees:option.nameen}
                                    </option>
                                    ))}
                                  </TextField>
                                </div>
                              
                          </Suspense>
                          <div className="form-group">
                            <Checkbox
                              id="isEnabled" 
                              name="isEnabled"
                              checked={filters.isEnabled}
                              onChange={handleChangeEnabledFilter}
                              variant="standard"/>
                            &nbsp;&nbsp;    
                            <label className="text-primary">{t('Text_Enabled')}</label>  
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;
                            <Checkbox
                              id="isNotEnabled" 
                              name="isNotEnabled"
                              checked={filters.isNotEnabled}
                              onChange={handleChangeNotEnabledFilter}
                              variant="standard"/>
                            &nbsp;&nbsp;    
                            <label className="text-primary">{t('Text_Not_Enabled')}</label>    
                          </div>
                          <div className="form-group">
                          <Checkbox
                              id="isAccountNonLocked" 
                              name="isAccountNonLocked"
                              checked={filters.isAccountNonLocked}
                              onChange={handleChangeisAccountNonLockedFilter}
                              variant="standard"/>
                            &nbsp;&nbsp;    
                            <label className="text-primary">{t('Text_isAccountNonLocked')}</label>  
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <Checkbox
                              id="isNotAccountNonLocked" 
                              name="isNotAccountNonLocked"
                              checked={filters.isNotAccountNonLocked}
                              onChange={handleChangeisNotAccountNonLockedFilter}
                              variant="standard"/>
                            &nbsp;&nbsp;    
                            <label className="text-primary">{t('Text_isNotAccountNonLocked')}</label>    
                          </div>
                          <hr className="sidebar-divider d-none d-md-block"/>
                          <div className="btn-group d-grid d-md-flex justify-content-md-end" role="group">
                            <button className="btn btn-primary btn-lg " type="button" onClick={handleSubmitFilterEdit}>{t('Button_Apply')}</button><> </>
                            <button className="btn btn-outline-secondary btn-lg" type="button" onClick={handleDeleteFilters}>{t('Button_Cancel_Filters')}</button>
                          </div>
                        </div>
                      </form>
                    </Box>
                  </Container>
                </SwipeableDrawer>
              </div>
            }
            {(openCreateData || openEditRow) && 
              <div>
                <SwipeableDrawer
                  anchor='right'
                  open={true}
                  onClose={toggleDrawerDataEdit(false)}
                  onOpen={toggleDrawerDataEdit(true)}>
                  <Container >
                    <Box sx={{ bgcolor: '#ffffff',height:'100vh', width: '500px' }}>
                      <br/>
                      <div id="myDIV">
                        <h1 className="h1NewStyle px-4 mb-2">{openEditRow?t('Text_User_Edit'):t('Text_User_Create')}</h1>
                      </div>  
                      <hr className="sidebar-divider d-none d-md-block"/>
                      <form className="createuseraacount" onSubmit={handleSubmitDataEdit}>  
                        <div className="form-floating mb-3 px-4 primary">
                          <label className="text-primary">{t('Text_Code')} :</label>
                          <div className="form-group">
                            <TextField
                              id="id" 
                              name="id"
                              value={form.id}
                              inputProps={{ style: {width: 200} }}
                              label={t('PlaceHolder_User_Code')}
                              placeholder={t('PlaceHolder_User_Code')}
                              variant="standard"
                              disabled/>
                          </div>                        
                          <label className="text-primary">{t('Text_User')} :</label>
                          <div className="form-group">
                            <TextField
                              id="username" 
                              name="username"
                              value={form.username}
                              inputProps={{ style: {width: 450}, maxLength: 100  }}
                              label={errors.username!=null?t(errors.username):t('PlaceHolder_User_Username')}
                              placeholder={t('PlaceHolder_User_Username')}
                              variant="standard"
                              onBlur={handleBlurDataEdit}
                              onChange={handleChangeDataEdit}
                              disabled={openEditRow}
                              />
                          </div>
                          
                          <label className="text-primary">{t('Text_AliasUser')} :</label>
                          <div className="form-group">
                            <TextField
                              id="aliasuser" 
                              name="aliasuser"
                              value={form.aliasuser}
                              inputProps={{ style: {width: 450}, maxLength: 100 }}
                              label={errors.aliasuser!=null?t(errors.aliasuser):t('PlaceHolder_User_AliasUser')}
                              placeholder={t('PlaceHolder_User_AliasUser')}
                              variant="standard"
                              onBlur={handleBlurDataEdit}
                              onChange={handleChangeDataEdit}/>
                          </div>
                          <label className="text-primary">{t('Text_Password')} :</label>
                          <div className="form-group">
                            <TextField
                              id="password" 
                              name="password"
                              type={showPassword ? 'text' : 'password'}
                              value={form.password}
                              label={errors.password!=null?t(errors.password):t('PlaceHolder_User_Password')}
                              placeholder={t('PlaceHolder_User_Password')}
                              variant="standard"
                              onBlur={handleBlurDataEdit}
                              onChange={handleChangeDataEdit}
                              disabled={openEditRow && ! newPassword}
                              InputProps={{
                                style: {width: 450},
                                endAdornment: (
                                  <InputAdornment position="end"
                                  onClick={handleClickShowPassword}>
                                    {showPassword?<VisibilityOff/>:<Visibility/>}
                                  </InputAdornment>
                                ),
                                startAdornment: (
                                  <InputAdornment position="start"
                                  onClick={handleDeletePassword}>
                                    <DeleteIcon/>
                                  </InputAdornment>
                                )
                              }}
                              />
                          </div>
                          <label className="text-primary">{t('Text_Name')} :</label>
                          <div className="form-group">
                            <TextField
                              id="firstName" 
                              name="firstName"
                              value={form.firstName}
                              inputProps={{ style: {width: 450}, maxLength: 100 }}
                              label={errors.firstName!=null?t(errors.firstName):t('PlaceHolder_User_Name')}
                              placeholder={t('PlaceHolder_User_Name')}
                              variant="standard"
                              onBlur={handleBlurDataEdit}
                              onChange={handleChangeDataEdit}/>
                          </div>
                          <label className="text-primary">{t('Text_LastName1')} :</label>
                          <div className="form-group">
                            <TextField
                              id="lastName1" 
                              name="lastName1"
                              value={form.lastName1}
                              inputProps={{ style: {width: 450}, maxLength: 100 }}
                              label={errors.lastName1!=null?t(errors.lastName1):t('PlaceHolder_User_LastName1')}
                              placeholder={t('PlaceHolder_User_LastName1')}
                              variant="standard"
                              onBlur={handleBlurDataEdit}
                              onChange={handleChangeDataEdit}/>
                          </div>
                          <label className="text-primary">{t('Text_LastName2')} :</label>
                          <div className="form-group">
                            <TextField
                              id="lastName2" 
                              name="lastName2"
                              value={form.lastName2}
                              inputProps={{ style: {width: 450}, maxLength: 100 }}
                              label={errors.lastName2!=null?t(errors.lastName2):t('PlaceHolder_User_LastName2')}
                              placeholder={t('PlaceHolder_User_LastName2')}
                              variant="standard"
                              onBlur={handleBlurDataEdit}
                              onChange={handleChangeDataEdit}/>
                          </div>
                          <label className="text-primary">{t('Text_Email')} :</label>
                          <div className="form-group">
                            <TextField
                              id="email" 
                              name="email"
                              value={form.email}
                              inputProps={{ style: {width: 450}, maxLength: 100 }}
                              label={errors.email!=null?t(errors.email):t('PlaceHolder_User_Email')}
                              placeholder={t('PlaceHolder_User_Email')}
                              variant="standard"
                              onBlur={handleBlurDataEdit}
                              onChange={handleChangeDataEdit}/>
                          </div>
                          <div className="form-group">
                            <Checkbox
                              id="enabled" 
                              name="enabled"
                              checked={form.enabled}
                              onChange={handleChangeEnabled}
                              disabled={userNotToDelete.includes(form.username)}
                              variant="standard"/>
                            &nbsp;&nbsp;    
                            <label className="text-primary">{t('Text_Enabled')}</label>  
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;
                            <Checkbox
                              id="isAccountNonLocked" 
                              name="isAccountNonLocked"
                              checked={form.isAccountNonLocked}
                              onChange={handleisAccountNonLocked}
                              disabled={userNotToDelete.includes(form.username)}
                              variant="standard"/>
                            &nbsp;&nbsp;    
                            <label className="text-primary">{t('Text_isAccountNonLocked')}</label>    
                          </div>
                          <label className="text-primary">{t('Text_User_Language')} :</label>
                          <div className="form-group">
                            <TextField
                              id="language_id"
                              name="language_id"
                              value={ 
                                (form.language_id!== undefined ||
                                form.language_id!== null ||
                                form.language_id!== "" ||
                                form.language_id!== "0" ||
                                form.language_id!== 0 ) ? form.language_id:Number(DEFAULTLANGUAGE)}
                              inputProps={{ style: {width: 405}, maxLength: 100 }}
                              variant="outlined"
                              onBlur={handleBlurSelectLanguage}
                              onChange={handleChangeSelectLanguage}
                              helperText={errors.language_id!=null?t(errors.language_id):""}
                              select
                              SelectProps={{
                                  native: true,
                                  value: 
                                    (form.language_id!== undefined ||
                                      form.language_id!== null ||
                                      form.language_id!== "" ||
                                      form.language_id!== "0" ||
                                      form.language_id!== 0 ) ? form.language_id:Number(DEFAULTLANGUAGE)
                                  }}>
                                  {languagesToSelect.map((option) => (
                                  <option key={option.id} value={option.id}>
                                      {i18n.language==="es"?option.namees:option.nameen}
                                  </option>
                                  ))}
                            </TextField>
                          </div>
                          <label className="text-primary">{t('Text_User_Color')} :</label>
                          <div className="form-group">
                            <TextField
                              id="color_id"
                              name="color_id"
                              value={ 
                                (form.color_id!== undefined ||
                                form.color_id!== null ||
                                form.color_id!== "" ||
                                form.color_id!== "0" ||
                                form.color_id!== 0 ) ? form.color_id:Number(DEFAULTCOLOR)}
                              inputProps={{ style: {width: 405}, maxLength: 100 }}
                              variant="outlined"
                              onBlur={handleBlurSelectColor}
                              onChange={handleChangeSelectColor}
                              helperText={errors.color_id!=null?t(errors.color_id):""}
                              select
                              SelectProps={{
                                  native: true,
                                  value: 
                                    (form.color_id!== undefined ||
                                      form.color_id!== null ||
                                      form.color_id!== "" ||
                                      form.rolecolor_id_id!== "0" ||
                                      form.color_id!== 0 ) ? form.color_id:Number(DEFAULTCOLOR)
                                  }}>
                                  {colorsToSelect.map((option) => (
                                  <option key={option.id} value={option.id}>
                                      {i18n.language==="es"?option.namees:option.nameen}
                                  </option>
                                  ))}
                            </TextField>
                          </div>
 
                          <label className="text-primary">{t('Text_User_Roles')} :</label>
                          <div className="form-group">
                            <TextField
                              id="role_id"
                              name="role_id"
                              value={ 
                                (form.role_id!== undefined ||
                                form.role_id!== null ||
                                form.role_id!== "" ||
                                form.role_id!== "0" ||
                                form.role_id!== 0 ) ? form.role_id:Number(DEFAULTROLE)}
                              inputProps={{ style: {width: 405}, maxLength: 100 }}
                              variant="outlined"
                              onBlur={handleBlurSelectRole}
                              onChange={handleChangeSelectRole}
                              disabled={userNotToDelete.includes(form.username)}
                              helperText={errors.role_id!=null?t(errors.role_id):""}
                              select
                              SelectProps={{
                                  native: true,
                                  value: 
                                    (form.role_id!== undefined ||
                                      form.role_id!== null ||
                                      form.role_id!== "" ||
                                      form.role_id!== "0" ||
                                      form.role_id!== 0 ) ? form.role_id:Number(DEFAULTROLE)
                                  }}>
                                  {rolesToSelect.filter(item=>(roles[0].listmenus.find((key)=>(key.name==='SubMenu_UserAccount_Handle_Role_Master')))?item.id!==0:item.id!==1).map(option => (
                                  <option key={option.id} value={option.id}>
                                      {option.name}
                                  </option>
                                  ))}
                            </TextField>
                          </div>

                          <label className="text-primary">{t('Text_FormatDI')} :</label>
                          <div className="form-group">
                            <TextField
                              id="typesdi_id"
                              name="typesdi_id"
                              value={ 
                                (form.typesdi_id!== undefined ||
                                form.typesdi_id!== null ||
                                form.typesdi_id!== "" ||
                                form.typesdi_id!== "0" ||
                                form.typesdi_id!== 0 ) ? form.typesdi_id:Number(DEFAULTTYPESDI)}
                              inputProps={{ style: {width: 405}, maxLength: 100 }}
                              variant="outlined"
                              onBlur={handleBlurSelectTypeDI}
                              onChange={handleChangeSelectTypeDI}
                              helperText={errors.typesdi_id!=null?t(errors.typesdi_id):""}
                              select
                              SelectProps={{
                                  native: true,
                                  value: 
                                    (form.typesdi_id!== undefined ||
                                      form.typesdi_id!== null ||
                                      form.typesdi_id!== "" ||
                                      form.typesdi_id!== "0" ||
                                      form.typesdi_id!== 0 ) ? form.typesdi_id:Number(DEFAULTTYPESDI)
                                  }}>
                                  {typesDIToSelect.map((option) => (
                                  <option key={option.id} value={option.id}>
                                    {i18n.language==="es"?option.namees:option.nameen}
                                  </option>
                                  ))}
                            </TextField>
                          </div>

                          <label className="text-primary">{t('Text_TicketsFormat')} :</label>
                          <div className="form-group">
                            <TextField
                              id="ticketsformat_id"
                              name="ticketsformat_id"
                              value={ 
                                (form.ticketsformat_id!== undefined ||
                                form.ticketsformat_id!== null ||
                                form.ticketsformat_id!== "" ||
                                form.ticketsformat_id!== "0" ||
                                form.ticketsformat_id!== 0 ) ? form.ticketsformat_id:1}
                              inputProps={{ style: {width: 405}, maxLength: 100 }}
                              variant="outlined"
                              onBlur={handleBlurSelectWeighVehiclesFormat}
                              onChange={handleChangeSelectWeighVehiclesFormat}
                              helperText={errors.typesdi_id!=null?t(errors.typesdi_id):""}
                              select
                              SelectProps={{
                                  native: true,
                                  value: 
                                    (form.ticketsformat_id!== undefined ||
                                      form.ticketsformat_id!== null ||
                                      form.ticketsformat_id!== "" ||
                                      form.ticketsformat_id!== "0" ||
                                      form.ticketsformat_id!== 0 ) ? form.ticketsformat_id:1
                                  }}>
                                  {ticketsFormatToSelect.map((option) => (
                                  <option key={option.id} value={option.id}>
                                    {i18n.language==="es"?option.namees:option.nameen}
                                  </option>
                                  ))}
                            </TextField>
                          </div>
                        

                          <label className="text-primary">{t('Text_User_Stores')} :</label>
                          <div className="form-group">
                            <TextField
                              id="store_id"
                              name="store_id"
                              value={ 
                                (form.store_id!== undefined ||
                                form.store_id!== null ||
                                form.store_id!== "" ||
                                form.store_id!== "0" ||
                                form.store_id!== 0 ) ? form.store_id:Number(DEFAULTSTORE)}
                              inputProps={{ style: {width: 405}, maxLength: 100 }}
                              variant="outlined"
                              onBlur={handleBlurSelectStore}
                              onChange={handleChangeSelectStore}
                              helperText={errors.store_id!=null?t(errors.store_id):""}
                              select
                              SelectProps={{
                                  native: true,
                                  value: 
                                    (form.store_id!== undefined ||
                                      form.store_id!== null ||
                                      form.store_id!== "" ||
                                      form.store_id!== "0" ||
                                      form.store_id!== 0 ) ? form.store_id:Number(DEFAULTSTORE)
                                  }}>
                                  {storesToSelect.map((option) => (
                                  <option key={option.id} value={option.id}>
                                      {option.name}
                                  </option>
                                  ))}
                            </TextField>
                          </div>
                          <hr className="sidebar-divider d-none d-md-block"/>
                          <div className="btn-group d-grid d-md-flex justify-content-md-end" role="group">
                            <button className="btn btn-primary btn-lg " type="button" onClick={handleSubmitDataEdit}>{openEditRow?t('Button_Update'):t('Button_Create')}</button><> </>
                            <button className="btn btn-outline-secondary btn-lg" type="button" onClick={handleCloseDataEdit}>{t('Button_Cancel')}</button>
                          </div>
                        </div>
                      </form>
                    </Box>
                  </Container>
                </SwipeableDrawer>
              </div>
            } 
            <Dialog open={openDeleteRow} onClose={handleCancelDeleteRow}>
              <DialogTitle style={{color:"#e74a3b"}}>{t('Text_Delete')} {rowEdit.id}  - {rowEdit.username}</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  {t('Text_Line1')}<br/>
                  {t('Text_Line2')}
                </DialogContentText>
                <TextField
                  autoFocus
                  margin="dense"
                  id="idCode"
                  label={t('Text_Label')}
                  type="text"
                  fullWidth
                  variant="standard"
                  error
                  defaultValue={idCodeDelete}
                  onChange={handleChangeDeleteRow}/>
              </DialogContent>
              <DialogActions>
                <Button style={{color:"#e74a3b",fontSize: 16 }} onClick={handleCancelDeleteRow}>{t('Button_Cancel')}</Button>
                <Button variant="contained" style={{color:"#ffffff", background:"#e74a3b",fontSize: 16}} onClick={handleCloseDeleteRow}>{t('Button_Delete')}</Button>
              </DialogActions>
            </Dialog>
            {/* End Content Row */}
          </>:
            <Loading isLoadingData={isLoading}/>
          }
        </div>
        //* End Page Content *//
      ) :  null }
    </>
  )
}

UserSettings.propTypes = {
  setShowPrincipalMenu: PropTypes.func.isRequired,
  showUserSettings: PropTypes.bool.isRequired,
  setShowModalUserSettings: PropTypes.func.isRequired,
  setShowModalGeneralSettings: PropTypes.func.isRequired 
};

export default UserSettings;