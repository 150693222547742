import React, {  Suspense,useEffect, useState, useCallback } from 'react';
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next"
import { useNavigate } from 'react-router-dom';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CircularProgress from '@mui/material/CircularProgress';
import { SwipeableDrawer } from '@mui/material';
import { Container, Box } from '@mui/material';   
import { TextField } from '@mui/material';
import { Button } from '@mui/material';  
import "css/generalSettings.css";
import { successErrorAction, warningErrorAction } from "actions/ErrorActions";
import { helpHttp } from "service/HelpHttp";
import { Loading } from "components/utils/Loading";
import TableSortingTicketsDetails from "pages/ticketsDetails/SortingTablesTicketsDetails";
import TicketsPDF from "pages/ticketsDetails/generatePDF/TicketsPDF";
import PropTypes from 'prop-types';

const initialFiltersTicketsDetails= {
  "idFrom":"",
    "idTo":"",
    "defaultStoreIdFrom":"",
    "defaultStoreIdTo":"",
    "useraccountIdFrom":"",
    "useraccountIdTo":"",
    "isFinished":false,
    "isNotFinished":true,
    "numberticketFrom":"",
    "numberticketTo":"",
    "start_date_ticketFrom":null,
	  "start_date_ticketTo":null,
    "end_date_ticketFrom":null,
    "end_date_tickettTo":null,
    "vehiclestore_licenseplateFrom":"",
    "vehiclestore_licenseplateTo":"",
    "rel_trans_store_idFrom":"",
    "rel_trans_store_idTo":"",
    "ori_tras_store_idFrom":"",
    "ori_tras_store_idTo":"",
    "numberDIFROM":"",
    "numberDITO":"",
};
 
const initialStoresToSelect = [{
  "id": "",
  "documentid": "",
  "tradename": ""
}];

const initialUserAccountToSelect = [{
  "id": "",
  "username":"",
  "firstName": "",
  "lastName1": "",
  "lastName2": "",
  "email": "",
  "aliasuser": ""
}];
 
const initialStateInvoiceTickets = [{
  "id" : "",
  "defaultStore_id": 0,
  "tradename": "",
  "useraccount_id": 0,
  "username": "",
  "defaultformatnumber": 1,
  "numberticket":0,
  "vehiclestore_licenseplate" : "",
  "start_date_ticket" : "",
  "end_date_ticket" : "",
  "rel_trans_tradename" : "",
  "ori_tras_tradename" : "",
  "totalstartweight" : 0,
  "totalendweight" : 0,
  "enabled": true ,
  "formDIAssociates": ""
}];  
 

const TicketsDetailsFilter = ({ applyFiltersTicketsDetails,setApplyFiltersTicketsDetails,showModalTicketsDetails}) => {
  const {t,i18n} = useTranslation(['listDataTickets']);
  const token = useSelector((state) => state.loginUser.token); 
  const roles = useSelector((state) => state.loginUser.roles);  
  const useraccount_id = useSelector((state) => state.loginUser.useraccount_id);  
  const defaultStore = useSelector((state) => state.loginUser.defaultStore);  
  const ENDPOINT = useSelector((state) => state.variablesUser.ENDPOINT); 
  const LIMITPAGESIZE = useSelector((state) => state.variablesUser.LIMITPAGESIZE); 
  const DELETE_CODE = useSelector((state) => state.formatDIUser.delete_code); 
  const DELETE_CODE_TICKETS = useSelector((state) => state.formatDIUser.delete_code_formdi); 
  const [dataTicketsDetails, setDataTicketsDetails] = useState(initialStateInvoiceTickets); 
  const [filtersTicketsDetails, setFiltersTicketsDetails]= useState(applyFiltersTicketsDetails);
  const [numTotRegFilter,setNumTotRegFilter]= useState(0);
  const [numTotRegTicketsDetails,setNumTotRegTicketsDetails]= useState(0);
  const [errorsFiltersTicketsDetails, setErrorsFiltersTicketsDetails] = useState({});
  const [inputTextTicketsDetails, setInputTextTicketsDetails] = useState(""); 
  const [openDeleteRowTicketsDetails,setOpenDeleteRowTicketsDetails]= useState(false); 
  const [openEditRowTicketsDetails,setOpenEditRowTicketsDetails]= useState(false);
  const [openEditFilterTicketsDetails,setOpenEditFilterTicketsDetails]= useState(false);
  const [rowEditTicketsDetails,setRowEditTicketsDetails]= useState(initialStateInvoiceTickets[0]);
  const [isLoadingTicketsDetails, setIsLoadingTicketsDetails] = useState(true);
  const [isLoadingErrorTicketsDetails, setIsLoadingErrorTicketsDetails] = useState(false);
  const [idCodeDelete,setIdCodeDelete]=useState(DELETE_CODE);
  const [viewPDF,setViewPDF]=useState(false);  
  const dispatch = useDispatch();
  const [activeOffsetTicketsDetails, setActiveOffsetTicketsDetails]= useState(false);
  const [currencyOffsetTicketsDetails, setCurrencyOffsetTicketsDetails] = useState(0);
  const [rangeOffsetTicketsDetails, setRangeOffsetTicketsDetails] = useState([{}]);
  const [storesToSelect,setStoresToSelect]= useState(initialStoresToSelect);
  const [userAccountsToSelect,setUserAccountsToSelect]= useState(initialUserAccountToSelect); 
  const [trasnporterToSelect,setTrasnporterToSelect]= useState(initialStoresToSelect); 
  const [tradenameToSelect,setTradenameToSelect]= useState(initialStoresToSelect); 
  const [refeshData,setRefeshData]=useState(false);
  
  const navigate = useNavigate(); 

  const keyPress = useCallback(
    e => {
      if (e.key === 'Escape' && showModalTicketsDetails) {
        setDataTicketsDetails(initialStateInvoiceTickets); 
        setFiltersTicketsDetails(initialFiltersTicketsDetails);
        setNumTotRegFilter(0);
        setNumTotRegTicketsDetails(0);
        setErrorsFiltersTicketsDetails({});
        setInputTextTicketsDetails("");
        setOpenDeleteRowTicketsDetails(false); 
        setOpenEditRowTicketsDetails(false);
        setOpenEditFilterTicketsDetails(false);
        setRowEditTicketsDetails(initialStateInvoiceTickets[0]);
        setIsLoadingTicketsDetails(false);
        setIsLoadingErrorTicketsDetails(false);
        setIdCodeDelete(DELETE_CODE);
        setViewPDF(false);
        setRefeshData(false);
        setActiveOffsetTicketsDetails(false);
        setCurrencyOffsetTicketsDetails(0);
        setRangeOffsetTicketsDetails([{}]);
        setStoresToSelect(initialStoresToSelect);
        setUserAccountsToSelect(initialUserAccountToSelect);
        setTrasnporterToSelect(initialStoresToSelect);
        setTradenameToSelect(initialStoresToSelect);
        navigate("/home");
        return () => {};
      }
    },
    [
      setDataTicketsDetails, 
      setFiltersTicketsDetails,
      setNumTotRegFilter,
      setNumTotRegTicketsDetails,
      setErrorsFiltersTicketsDetails,
      setInputTextTicketsDetails,
      setOpenDeleteRowTicketsDetails, 
      setOpenEditRowTicketsDetails,
      setOpenEditFilterTicketsDetails,
      setRowEditTicketsDetails,
      setIsLoadingTicketsDetails,
      setIsLoadingErrorTicketsDetails,
      setIdCodeDelete,
      setViewPDF,
      setRefeshData,
      setActiveOffsetTicketsDetails,
      setCurrencyOffsetTicketsDetails,
      setRangeOffsetTicketsDetails,
      setStoresToSelect,
      setUserAccountsToSelect,
      setTrasnporterToSelect,
      setTradenameToSelect,
      showModalTicketsDetails,  
      navigate,
      DELETE_CODE]
  );

  const closeModalTicketsDetails = () => {    
    setDataTicketsDetails(initialStateInvoiceTickets); 
    setFiltersTicketsDetails(initialFiltersTicketsDetails);
    setNumTotRegFilter(0);
    setNumTotRegTicketsDetails(0);
    setErrorsFiltersTicketsDetails({});
    setInputTextTicketsDetails("");
    setOpenDeleteRowTicketsDetails(false); 
    setOpenEditRowTicketsDetails(false);
    setOpenEditFilterTicketsDetails(false);
    setRowEditTicketsDetails(initialStateInvoiceTickets[0]);
    setIsLoadingTicketsDetails(false);
    setIsLoadingErrorTicketsDetails(false);
    setIdCodeDelete(DELETE_CODE);
    setViewPDF(false);
    setRefeshData(false);
    setActiveOffsetTicketsDetails(false);
    setCurrencyOffsetTicketsDetails(0);
    setRangeOffsetTicketsDetails([{}]);
    setStoresToSelect(initialStoresToSelect);
    setUserAccountsToSelect(initialUserAccountToSelect);
    setTrasnporterToSelect(initialStoresToSelect);
    setTradenameToSelect(initialStoresToSelect);
    navigate("/home");
    return () => {};
  };
 
  const openEditFiltersTicketsDetails  = () => {
      setFiltersTicketsDetails(applyFiltersTicketsDetails);
      setOpenEditFilterTicketsDetails(!openEditFilterTicketsDetails);
  };

  const handleSubmitFilterEditTicketsDetails = () => {
    setErrorsFiltersTicketsDetails(validateFormFilterTicketsDetails());
    if (Object.keys(errorsFiltersTicketsDetails).length === 0) {
      setApplyFiltersTicketsDetails(filtersTicketsDetails);
      setFiltersTicketsDetails(initialStateInvoiceTickets);
      setErrorsFiltersTicketsDetails({});
      setOpenEditFilterTicketsDetails(false);
    }  else{
      dispatch(warningErrorAction(t('Text_Error_Filter')));
    }
  }

  const handleDeleteFiltersTicketsDetails = () => {
    setErrorsFiltersTicketsDetails({});
    setFiltersTicketsDetails(initialFiltersTicketsDetails);
    setApplyFiltersTicketsDetails(initialFiltersTicketsDetails);  
    setOpenEditFilterTicketsDetails(!openEditFilterTicketsDetails);
  };

  const handleCancelFiltersTicketsDetails = () => {
    setErrorsFiltersTicketsDetails({});
    setFiltersTicketsDetails(initialFiltersTicketsDetails); 
    setOpenEditFilterTicketsDetails(!openEditFilterTicketsDetails);
  };
  
  const handleChangeFilterEditTicketsDetails = (e) => {
    const { name, value } = e.target;
    setFiltersTicketsDetails(filtersTicketsDetails => ({
      ...filtersTicketsDetails,
      [name]: value
    }));
  };

  const handleBlurSelectStartDateTicketsDetailsFrom = (e) => {
    handleChangeSelectStartDateTicketsDetailsFrom(e);
    setErrorsFiltersTicketsDetails(validateFormFilterTicketsDetails());
  };

  const handleChangeSelectStartDateTicketsDetailsFrom = (event) => {
    setFiltersTicketsDetails(filtersTicketsDetails => ({
      ...filtersTicketsDetails,
      start_date_ticketFrom: event!==null?new Date(event):null,
      start_date_ticketTo:null
    }));
  };

  const handleBlurSelectStartDateTicketsDetailsTo = (e) => {
    handleChangeSelectStartDateTicketsDetailsTo(e);
    setErrorsFiltersTicketsDetails(validateFormFilterTicketsDetails());
  };

  const handleChangeSelectStartDateTicketsDetailsTo= (event) => {
    setFiltersTicketsDetails(filtersTicketsDetails => ({
      ...filtersTicketsDetails,
      start_date_ticketTo: event!==null?new Date(event):null
    }));
  };

  const handleBlurSelectEndDateTicketsDetailsFrom = (e) => {
    handleChangeSelectEndDateTicketsDetailsFrom(e);
    setErrorsFiltersTicketsDetails(validateFormFilterTicketsDetails());
  };
 
  const handleChangeSelectEndDateTicketsDetailsFrom = (event) => {
    setFiltersTicketsDetails(filtersTicketsDetails => ({
      ...filtersTicketsDetails,
      end_date_ticketFrom: event!==null?new Date(event):null,
      end_date_tickettTo:null
    }));
  };
 
  const handleBlurSelectEndDateTicketsDetailsTo = (e) => {
    handleChangeSelectEndDateTicketsDetailsTo(e);
    setErrorsFiltersTicketsDetails(validateFormFilterTicketsDetails());
  };

  const handleChangeSelectEndDateTicketsDetailsTo = (event) => {
    setFiltersTicketsDetails(filtersTicketsDetails => ({
      ...filtersTicketsDetails,
      end_date_tickettTo: event!==null?new Date(event):null
    }));
  };
   

  const handleBlurFilterEditTicketsDetails = (e) => {
    setErrorsFiltersTicketsDetails(validateFormFilterTicketsDetails());
    handleChangeFilterEditTicketsDetails(e);
  };

  const toggleDrawerFilterEditTicketsDetails = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    if (event.type === 'keydown') {
      event.preventDefault();
      handleCloseDataEditTicketsDetails();
    }
  };

  const validateFormFilterTicketsDetails = () => {

    let errorsFiltersTicketsDetails = {};
    let regexNumber = /^[0-9]+$/;
    let regexInvalidCharacters = /[\\!\\$\\%\\&\\|\\<\\>\\?\\{\\}\\~\\"\\']+/;

    if (filtersTicketsDetails.idFrom!=="" & !regexNumber.test(filtersTicketsDetails.idFrom)) {
      errorsFiltersTicketsDetails.idFrom = 'Text_TextNoNumber';
    }
    if (filtersTicketsDetails.idTo!=="" & !regexNumber.test(filtersTicketsDetails.idTo)) {
      errorsFiltersTicketsDetails.idTo = 'Text_TextNoNumber';
    }
    if (filtersTicketsDetails.idFrom!=="" & !errorsFiltersTicketsDetails.idFrom!==undefined){
      if (filtersTicketsDetails.idTo!=="" & !errorsFiltersTicketsDetails.idTo!==undefined){
        if (parseInt(filtersTicketsDetails.idTo)<parseInt(filtersTicketsDetails.idFrom)){
          errorsFiltersTicketsDetails.idTo = 'Text_TextGreater_Id';
        }
      }
    }

    if (filtersTicketsDetails.numberDIFROM!=="" & !regexNumber.test(filtersTicketsDetails.numberDIFROM)) {
      errorsFiltersTicketsDetails.numberDIFROM = 'Text_TextNoNumber';
    }
    if (filtersTicketsDetails.numberDITO!=="" & !regexNumber.test(filtersTicketsDetails.numberDITO)) {
      errorsFiltersTicketsDetails.numberDITO = 'Text_TextNoNumber';
    }
    if (filtersTicketsDetails.numberDIFROM!=="" & !errorsFiltersTicketsDetails.numberDIFROM!==undefined){
      if (filtersTicketsDetails.numberDITO!=="" & !errorsFiltersTicketsDetails.numberDITO!==undefined){
        if (parseInt(filtersTicketsDetails.numberDITO)<parseInt(filtersTicketsDetails.numberDIFROM)){
          errorsFiltersTicketsDetails.numberDITO = 'Text_TextGreater_FormDI';
        }
      }
    }

    if (regexInvalidCharacters.test(filtersTicketsDetails.numberticketFrom)) {
      errorsFiltersTicketsDetails.numberticketFrom = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(filtersTicketsDetails.numberticketTo)) {
      errorsFiltersTicketsDetails.numberticketTo = 'Text_TextNoValid';
    }

    if (regexInvalidCharacters.test(filtersTicketsDetails.vehiclestore_licenseplateFrom)) {
      errorsFiltersTicketsDetails.vehiclestore_licenseplateFrom = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(filtersTicketsDetails.vehiclestore_licenseplateTo)) {
      errorsFiltersTicketsDetails.vehiclestore_licenseplateTo = 'Text_TextNoValid';
    }
   
    if (filtersTicketsDetails.defaultStoreIdTo!=="" & filtersTicketsDetails.defaultStoreIdTo<filtersTicketsDetails.defaultStoreIdFrom){
      errorsFiltersTicketsDetails.defaultStoreIdTo = 'Text_TextGreater_Store';
    }

    if (filtersTicketsDetails.useraccountIdTo!=="" & filtersTicketsDetails.useraccountIdTo<filtersTicketsDetails.useraccountIdFrom){
      errorsFiltersTicketsDetails.useraccountIdTo = 'Text_TextGreater_UserAccount';
    }

     
    return errorsFiltersTicketsDetails;
  }  

  const filteredDataTicketsDetails = dataTicketsDetails.filter((el) => {
    if (inputTextTicketsDetails === '') {
        return el;
    }
    else {
        let dataTranslate=el.id+el.numberticket+el.vehiclestore_licenseplate+el.rel_trans_tradename+el.ori_tras_tradename;
        return dataTranslate.toLowerCase().includes(inputTextTicketsDetails);
    }
  })

  let inputHandlerTicketsDetails = (e) => {
      var lowerCase = e.target.value.toLowerCase();
      setInputTextTicketsDetails(lowerCase);
  };

  const handleChangeDeleteRowTicketsDetails = (e) => {
    const value  = e.target.value;
    setIdCodeDelete(value);
  };

  const handleCancelDeleteRowTicketsDetails = () => {
    setRowEditTicketsDetails(initialStateInvoiceTickets[0]);
    setIsLoadingErrorTicketsDetails(false);
    setIdCodeDelete(DELETE_CODE);
    setOpenDeleteRowTicketsDetails(false);
  };

  const handleCloseDeleteRowTicketsDetails = (e) => {
    if (idCodeDelete !== DELETE_CODE_TICKETS) {
      dispatch(warningErrorAction(t('Text_InvalidCode_RESET')));
      return;
    }
    let isMounted = true;
    if (e.key !== 'Escape') {
      if (rowEditTicketsDetails.id!=='') {      
        const fetchData = async () => {
          setIsLoadingTicketsDetails(true);
          try {
            const getData = await helpHttp().del(ENDPOINT,`ticketsdetails/deleteresettickets`,token,rowEditTicketsDetails);
            if (getData.status === "OK") {
              if (isMounted) {
                dispatch(successErrorAction(t('Text_ValidCode_RESET')));
                dispatch(successErrorAction(t('Text_DeleteField_RESET')));
                setRefeshData(true);
                handleCloseDataEditTicketsDetails();
              }
            } else {
              if (isMounted) {
                dispatch(warningErrorAction(t(getData.cause)));
              }
            }
          } catch (error) {
            dispatch(warningErrorAction(t('Text_IncorrectData')));
            setIsLoadingTicketsDetails(false);
          } 
          if (isMounted && isLoadingTicketsDetails!==undefined) {
            setIsLoadingTicketsDetails(false);
          }
        };
        fetchData();
      } else {
        dispatch(warningErrorAction(t('Text_InvalidId')));
      }
    }
    setIdCodeDelete(DELETE_CODE);
    setOpenDeleteRowTicketsDetails(false);
    setIsLoadingErrorTicketsDetails(false);
    handleCloseDataEditTicketsDetails(); 
  };

  const handleCloseDataEditTicketsDetails = () => { 
    setOpenEditRowTicketsDetails(false);
    setRowEditTicketsDetails(openEditRowTicketsDetails?rowEditTicketsDetails:initialStateInvoiceTickets[0]);
    setIsLoadingTicketsDetails(false);
    setIsLoadingErrorTicketsDetails(false);
    toggleDrawerFilterEditTicketsDetails(false);  
	};
  
  useEffect(() => {
    if (numTotRegTicketsDetails > Number(LIMITPAGESIZE)) {
      setActiveOffsetTicketsDetails(true);
      const newRangeOffset = [
        {
          value: 0,
          label: " 1 - " + Number(LIMITPAGESIZE).toLocaleString('de-DE'),
        }
      ];
      for (let i = Number(LIMITPAGESIZE); i < numTotRegTicketsDetails; i += Number(LIMITPAGESIZE)) {
        newRangeOffset.push({
          value: i,
          label: " " + (i + 1).toLocaleString('de-DE') + " - " + (i + Number(LIMITPAGESIZE)).toLocaleString('de-DE'),
        });
      }
      setRangeOffsetTicketsDetails(newRangeOffset);
    } else {
      setCurrencyOffsetTicketsDetails(0);
      setActiveOffsetTicketsDetails(false);
      setRangeOffsetTicketsDetails([{}]);
    }
  }, [numTotRegTicketsDetails, LIMITPAGESIZE]);
   
  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event && typeof event === 'object' && event.key) {
        keyPress(event);
      }
    };
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [keyPress]);
 
  useEffect(() => {
    let isMounted = true;

    const fetchDataStore = async () => {
      try {
        const getData = await helpHttp().get(ENDPOINT,`ticketsdetails/storesorderbytradename`,token);
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          setStoresToSelect(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };

    const fetchDataUserAccount = async () => {
      try {
        const getData =(roles[0].listmenus.find((key)=>(key.name==='SubMenu_Settings_TicketsDetails_Store_Master')))?
          await helpHttp().get2(ENDPOINT,`ticketsdetails/useraccountsorderbyusername/`,token,"-1"):
          await helpHttp().get2(ENDPOINT,`ticketsdetails/useraccountsorderbyusername/`,token,defaultStore.id);
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          setUserAccountsToSelect(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };

    const fetchDataTransporter = async () => {
      try {
        const getData = await helpHttp().get(ENDPOINT, `ticketsdetails/getlisttransportertickets`, token);
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          setTrasnporterToSelect(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    }; 

    const fetchDataTradename = async () => {
      try {
        const getData = 
          (roles[0].listmenus.find((key)=>(key.name==='SubMenu_Settings_TicketsDetails_Store_Master')))?
          await helpHttp().get5(ENDPOINT,`ticketsdetails/getlisttransportertickets`,token,"-1","-1"):
          (roles[0].listmenus.find((key)=>(key.name==='SubMenu_Settings_TicketsDetails_User_Master')))?
          await helpHttp().get5(ENDPOINT,`ticketsdetails/getlisttransportertickets`,token,"-1",useraccount_id):
          await helpHttp().get5(ENDPOINT,`ticketsdetails/getlisttransportertickets`,token,defaultStore.id,useraccount_id);
 
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          setTradenameToSelect(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    }; 

    if (showModalTicketsDetails ) { 
      fetchDataTransporter(); 
      fetchDataTradename();
      if (roles[0].listmenus.find((key)=>(key.name==='SubMenu_Settings_TicketsDetails_Store_Master'))){
        fetchDataStore();
      }
      if (roles[0].listmenus.find((key)=>(key.name==='SubMenu_Settings_TicketsDetails_User_Master'))){
        fetchDataUserAccount();
      }
    }
    return () => { isMounted = false };

  }, [ENDPOINT,t,token,dispatch,i18n,roles,applyFiltersTicketsDetails,openEditFilterTicketsDetails,showModalTicketsDetails,defaultStore.id,useraccount_id]);
  

  useEffect(() => {
    let isMounted = true;
    const fetchnumTotRegTicketsDetails = async () => {
      setIsLoadingTicketsDetails(true);
      try {
        const getData  = (roles[0].listmenus.find((key)=>(key.name==='SubMenu_Settings_TicketsDetails_Store_Master')))
          ? await helpHttp().get(ENDPOINT,`ticketsdetails/getinformationtickets`,token)
          : (roles[0].listmenus.find((key)=>(key.name==='SubMenu_Settings_TicketsDetails_User_Master'))
              ? await helpHttp().get2(ENDPOINT,`ticketsdetails/getinformationticketswithstore/`,token,defaultStore.id)
              :await helpHttp().get8(ENDPOINT,`ticketsdetails/getInformationticketswithstoreanduseraccount`,token,defaultStore.id,useraccount_id)
              )
        if (getData.err || getData.message === "Load failed") {
          if (isMounted) {
            setDataTicketsDetails(initialStateInvoiceTickets);
            if (getData.message==="Load failed"){
              dispatch(warningErrorAction(t('Text_ConnectionFailied')));
            }else {
              dispatch(warningErrorAction(t('Text_InvalidCredentials')));
            }  
          }
        } else if (isMounted) {
          setNumTotRegTicketsDetails(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData')));
        setIsLoadingTicketsDetails(false);
      }   
    };

    const fetchnumTotRegFilter = async () => {
      setIsLoadingTicketsDetails(true);
      try {
        const getData = 
          (roles[0].listmenus.find((key)=>(key.name==='SubMenu_Settings_TicketsDetails_Store_Master')))?
          await helpHttp().post4(ENDPOINT,`ticketsdetails/getcountfilterticket`,token,"-1","-1",true,applyFiltersTicketsDetails):
          (roles[0].listmenus.find((key)=>(key.name==='SubMenu_Settings_TicketsDetails_User_Master')))?
          await helpHttp().post4(ENDPOINT,`ticketsdetails/getcountfilterticket`,token,"-1",useraccount_id,true,applyFiltersTicketsDetails):
          await helpHttp().post4(ENDPOINT,`ticketsdetails/getcountfilterticket`,token,defaultStore.id,useraccount_id,true,applyFiltersTicketsDetails);
 
        if (getData.err || getData.message === "Load failed") {
          if (isMounted) {
            setDataTicketsDetails(initialStateInvoiceTickets);
            if (getData.message==="Load failed"){
              dispatch(warningErrorAction(t('Text_ConnectionFailied')));
            }else {
              dispatch(warningErrorAction(t('Text_InvalidCredentials')));
            } 
          }
        } else if (isMounted) {
          setNumTotRegFilter(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData')));
        setIsLoadingTicketsDetails(false);
      } 
    }; 

    const fetchDataTicketsDetails = async () => {
      try {
        const getData = 
          (roles[0].listmenus.find((key)=>(key.name==='SubMenu_Settings_TicketsDetails_Store_Master')))?
          await helpHttp().post7(ENDPOINT,`ticketsdetails/gettickets`,token,currencyOffsetTicketsDetails,LIMITPAGESIZE,"-1","-1",applyFiltersTicketsDetails):
          (roles[0].listmenus.find((key)=>(key.name==='SubMenu_Settings_TicketsDetails_User_Master')))?
          await helpHttp().post7(ENDPOINT,`ticketsdetails/gettickets`,token,currencyOffsetTicketsDetails,LIMITPAGESIZE,"-1",useraccount_id,applyFiltersTicketsDetails):
          await helpHttp().post7(ENDPOINT,`ticketsdetails/gettickets`,token,currencyOffsetTicketsDetails,LIMITPAGESIZE,defaultStore.id,useraccount_id,applyFiltersTicketsDetails);
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) { 
          setDataTicketsDetails(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };
 
    if (showModalTicketsDetails || refeshData){
      fetchnumTotRegTicketsDetails();
      fetchnumTotRegFilter(); 
      fetchDataTicketsDetails(); 
      setIsLoadingTicketsDetails(false);
      setRefeshData(false);
    }  
    return () => { isMounted = false; };
  }, [ENDPOINT,t,roles,token,dispatch,i18n.language,LIMITPAGESIZE,applyFiltersTicketsDetails,showModalTicketsDetails,currencyOffsetTicketsDetails,defaultStore.id,useraccount_id ,openDeleteRowTicketsDetails,setOpenDeleteRowTicketsDetails,setOpenEditRowTicketsDetails,openEditRowTicketsDetails,rowEditTicketsDetails,setIsLoadingTicketsDetails,defaultStore,refeshData ]);

  return (
    <>
      {showModalTicketsDetails ? ( 
        <div className="container-fluid">
          {/* Search input */}
          <div id="myDIV">
            <h1 className={"hANewStyle mb-2"}>
              {isLoadingErrorTicketsDetails?<i className="fas fa-solid fa-exclamation fa-2x text-danger"></i>:isLoadingTicketsDetails?<CircularProgress color={openDeleteRowTicketsDetails?"warning":"primary"}/>:<i className="fas fa-address-card text-warning"></i>} {t('Text_TICKETS_Table')}
            </h1>
            <div>
              <button type="button" className="buttonBack" onClick={closeModalTicketsDetails}>{t('Button_Back')}</button><> </>
            </div> 
          </div>  
          <br/>
          <div className="searchStyle">
            <TextField
              id="outlined-basic"
              variant="outlined"
              value={inputTextTicketsDetails}
              placeholder={t('Text_TICKETS_Search')} 
              onChange={inputHandlerTicketsDetails}
              fullWidth
              label={t('Text_TICKETS_Search')}>
            </TextField>
          </div>
          <br/>
          <div id="myDIV">
            <h1 className="h6NewStyle mb-2">
            {t('Text_Filter')}&nbsp;
                <button type="button" className="buttonFilter" onClick={openEditFiltersTicketsDetails }><i className="fas fa-solid fa-circle" style={{color:(applyFiltersTicketsDetails.idFrom!=="" || applyFiltersTicketsDetails.idTo!=="")?"#32CD32":"#FFFFFF"}}></i> {t('Text_Code')}</button><> </>
                {roles[0].listmenus.find((key)=>(key.name==="SubMenu_Settings_TicketsDetails_Store_Master"))&&
                  <>
                    <button type="button" className="buttonFilter" onClick={openEditFiltersTicketsDetails }><i className="fas fa-solid fa-circle" style={{color:(applyFiltersTicketsDetails.defaultStoreIdFrom!=="" || applyFiltersTicketsDetails.defaultStoreIdTo!=="")?"#32CD32":"#FFFFFF"}}></i> {t('Text_Store')}</button><> </>
                  </>
                }
                 {roles[0].listmenus.find((key)=>(key.name==="SubMenu_Settings_TicketsDetails_User_Master"))&&
                  <>
                    <button type="button" className="buttonFilter" onClick={openEditFiltersTicketsDetails }><i className="fas fa-solid fa-circle" style={{color:(applyFiltersTicketsDetails.useraccountIdFrom!=="" || applyFiltersTicketsDetails.useraccountIdTo!=="")?"#32CD32":"#FFFFFF"}}></i> {t('Text_UserAccount')}</button><> </>
                  </>
                }
                <button type="button" className="buttonFilter" onClick={openEditFiltersTicketsDetails }><i className="fas fa-solid fa-circle" style={{color:(applyFiltersTicketsDetails.numberticketFrom!=="" || applyFiltersTicketsDetails.numberticketTo!=="")?"#32CD32":"#FFFFFF"}}></i> {t('Text_Filter_NumberTicket')}</button><> </>
                <button type="button" className="buttonFilter" onClick={openEditFiltersTicketsDetails }><i className="fas fa-solid fa-circle" style={{color:(applyFiltersTicketsDetails.start_date_ticketFrom!==null || applyFiltersTicketsDetails.start_date_ticketTo!==null || applyFiltersTicketsDetails.end_date_ticketFrom!==null || applyFiltersTicketsDetails.end_date_tickettTo!==null )?"#32CD32":"#FFFFFF"}}></i> {t('Text_Dates')}</button><> </>  
                <button type="button" className="buttonFilter" onClick={openEditFiltersTicketsDetails }><i className="fas fa-solid fa-circle" style={{color:(applyFiltersTicketsDetails.numberDIFROM!=="" || applyFiltersTicketsDetails.numberDITO!=="")?"#32CD32":"#FFFFFF"}}></i> {t('Text_Filter_FormDI')}</button><> </>
                
                <button type="button" className="buttonFilter" onClick={openEditFiltersTicketsDetails }><i className="fas fa-solid fa-circle" style={{color:(
                      applyFiltersTicketsDetails.vehiclestore_licenseplateFrom
                      || applyFiltersTicketsDetails.vehiclestore_licenseplateTo
                      || applyFiltersTicketsDetails.rel_trans_store_idFrom
                      ||  applyFiltersTicketsDetails.rel_trans_store_idTo!=="" 
                      || applyFiltersTicketsDetails.ori_tras_store_idFrom!==""
                      || applyFiltersTicketsDetails.ori_tras_store_idTo!==""
                      )?"#32CD32":"#FFFFFF"}}></i> {t('Text_Others_Filters')}</button><> </> 
                
            </h1>
          </div>
          <br/>
          {!isLoadingTicketsDetails ?
            <>
              <div className="card shadow mb-4">    
                <div className="card-body">
                  <TableSortingTicketsDetails
                    dataListTicketsDetails={filteredDataTicketsDetails} 
                    numTotRegFilter={ numTotRegFilter}
                    titleTable={t('Text_TICKETS_Table')}
                    isLoadingTicketsDetails={isLoadingTicketsDetails}
                    setIsLoadingTicketsDetails={setIsLoadingTicketsDetails}
                    setOpenDeleteRowTicketsDetails={setOpenDeleteRowTicketsDetails} 
                    setRowEditTicketsDetails={setRowEditTicketsDetails}
                    showPrintButtonTicketsDetails={roles[0].listmenus.find((key)=>(key.name==='SubMenu_TicketsDetails_Print'))?true:true}
                    showDeleteButtonTicketsDetails={roles[0].listmenus.find((key)=>(key.name==='SubMenu_TicketsDetails_Delete'))?true:false}
                    showStoreTicketsDetails={roles[0].listmenus.find((key)=>(key.name==='SubMenu_Settings_TicketsDetails_Store_Master'))?true:false}
                    showUserAccountTicketsDetails={roles[0].listmenus.find((key)=>(key.name==='SubMenu_Settings_TicketsDetails_User_Master'))?true:false}
                    numTotRegTicketsDetails={numTotRegTicketsDetails}
                    activeOffsetTicketsDetails={activeOffsetTicketsDetails}
                    rangeOffsetTicketsDetails={rangeOffsetTicketsDetails}
                    currencyOffsetTicketsDetails={currencyOffsetTicketsDetails}
                    setCurrencyOffsetTicketsDetails={setCurrencyOffsetTicketsDetails}
                    setViewPDF={setViewPDF} 
                    >
                  </TableSortingTicketsDetails>
                </div>             
              </div>
              {openEditFilterTicketsDetails &&
                <div>
                <SwipeableDrawer
                  anchor='right'
                  open={true}
                  onClose={toggleDrawerFilterEditTicketsDetails(false)}
                  onOpen={toggleDrawerFilterEditTicketsDetails(true)}>
                  <Container >
                    <Box sx={{ bgcolor: '#ffffff',height:'100vh', width: '850' }}>
                      <br/>
                        <div id="myDIV">
                          <h1 className="h1NewStyle px-4 mb-2">{t('Text_Filter')}</h1>
                        </div>
                        <hr className="sidebar-divider d-none d-md-block"/>
                        <form className="createfiltformdi" onSubmit={handleCancelFiltersTicketsDetails}>  
                          <div className="form-floating mb-3 px-4 primary">
                            <div className="form-group">
                              <TextField
                                id="idFrom" 
                                name="idFrom"
                                value={filtersTicketsDetails.idFrom}
                                inputProps={{ style: {width: 400}, maxLength: 15 }}
                                label={errorsFiltersTicketsDetails.idFrom!=null?t(errorsFiltersTicketsDetails.idFrom):t('Text_Filter_Code_From')}
                                placeholder={t('PlaceHolder_Filter_Code_From')}
                                variant="outlined"
                                onBlur={handleBlurFilterEditTicketsDetails}
                                onChange={handleChangeFilterEditTicketsDetails}
                              />
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
                              <TextField
                                id="idTo" 
                                name="idTo"
                                value={filtersTicketsDetails.idTo}
                                inputProps={{ style: {width: 400}, maxLength: 15 }}
                                label={errorsFiltersTicketsDetails.idTo!=null?t(errorsFiltersTicketsDetails.idTo):t('Text_Filter_Code_To')}
                                placeholder={t('PlaceHolder_Filter_Code_to')}
                                variant="outlined"
                                onBlur={handleBlurFilterEditTicketsDetails}
                                onChange={handleChangeFilterEditTicketsDetails}
                              />
                            </div>
                            {roles[0].listmenus.find((key)=>(key.name==='SubMenu_Settings_TicketsDetails_Store_Master'))   &&
                              <>
                                <Suspense delayMs={500} fallback={<CircularProgress sx={{ color :"#f6c23e"}}/>}>
                                  <div className="form-group">
                                    <TextField
                                      id="defaultStoreIdFrom"
                                      name="defaultStoreIdFrom"
                                      value={filtersTicketsDetails.defaultStoreIdFrom}
                                      inputProps={{ style: {width: 382}, maxLength: 100 }}
                                      label={errorsFiltersTicketsDetails.defaultStoreIdFrom!=null?t(errorsFiltersTicketsDetails.defaultStoreIdFrom):t('Text_Filter_Store_From')}
                                      placeholder={t('PlaceHolder_Filter_tradename_From')}
                                      variant="outlined"
                                      onBlur={handleBlurFilterEditTicketsDetails}
                                      onChange={handleChangeFilterEditTicketsDetails}
                                      select
                                      SelectProps={{
                                      native: true,
                                      }}>
                                      <option key={0} value=""/>
                                      {storesToSelect.map((option) => (
                                          <option key={option.id} value={option.tradename}>
                                              {option.documentid+" - "+option.tradename}
                                          </option>
                                      ))}
                                    </TextField>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  
                                    <TextField
                                      id="defaultStoreIdTo"
                                      name="defaultStoreIdTo"
                                      value={filtersTicketsDetails.defaultStoreIdTo}  
                                      inputProps={{ style: {width: 382}, maxLength: 100 }}
                                      label={errorsFiltersTicketsDetails.defaultStoreIdTo!=null?t(errorsFiltersTicketsDetails.defaultStoreIdTo):t('Text_Filter_Store_To')}
                                      placeholder={t('PlaceHolder_Filter_tradename_to')}
                                      variant="outlined"
                                      onBlur={handleBlurFilterEditTicketsDetails}
                                      onChange={handleChangeFilterEditTicketsDetails}
                                      select
                                      SelectProps={{
                                      native: true,
                                      }}>
                                        <option key={0} value=""/>
                                      {storesToSelect.filter(item=>(item.tradename>=filtersTicketsDetails.defaultStoreIdFrom)).map(option => (
                                        <option key={option.id} value={option.tradename}>
                                            {option.documentid+" - "+option.tradename}
                                        </option>
                                      ))}
                                    </TextField>
                                  </div>
                                </Suspense>
                              </>
                            }   
                            { roles[0].listmenus.find((key)=>(key.name==='SubMenu_Settings_TicketsDetails_User_Master'))   &&
                              <>
                                <Suspense delayMs={500} fallback={<CircularProgress sx={{ color :"#f6c23e"}}/>}>
                                  <div className="form-group">
                                    <TextField
                                      id="useraccountIdFrom"
                                      name="useraccountIdFrom"
                                      value={filtersTicketsDetails.useraccountIdFrom}
                                      inputProps={{ style: {width: 382}, maxLength: 100 }}
                                      label={errorsFiltersTicketsDetails.useraccountIdFrom!=null?t(errorsFiltersTicketsDetails.useraccountIdFrom):t('Text_Filter_UserAccount_From')}
                                      placeholder={t('PlaceHolder_Filter_Store_From')}
                                      variant="outlined"
                                      onBlur={handleBlurFilterEditTicketsDetails}
                                      onChange={handleChangeFilterEditTicketsDetails}
                                      select
                                      SelectProps={{
                                      native: true,
                                      }}>
                                      <option key={0} value=""/>
                                      {userAccountsToSelect.map((option) => (
                                          <option key={option.id} value={option.username}>
                                            {option.username+" - "+option.email}
                                          </option>
                                      ))}
                                    </TextField>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  
                                    <TextField
                                      id="useraccountIdTo"
                                      name="useraccountIdTo"
                                      value={filtersTicketsDetails.useraccountIdTo}  
                                      inputProps={{ style: {width: 382}, maxLength: 100 }}
                                      label={errorsFiltersTicketsDetails.useraccountIdTo!=null?t(errorsFiltersTicketsDetails.useraccountIdTo):t('Text_Filter_UserAccount_To')}
                                      placeholder={t('PlaceHolder_Filter_Store_to')}
                                      variant="outlined"
                                      onBlur={handleBlurFilterEditTicketsDetails}
                                      onChange={handleChangeFilterEditTicketsDetails}
                                      select
                                      SelectProps={{
                                      native: true,
                                      }}>
                                        <option key={0} value=""/>
                                      {userAccountsToSelect.filter(item=>(item.username>=filtersTicketsDetails.useraccountIdFrom)).map(option => (
                                        <option key={option.id} value={option.username}>
                                            {option.username+" - "+option.email}
                                        </option>
                                      ))}
                                    </TextField>
                                  </div>
                                </Suspense>
                              </>
                            }   
                            <div className="form-group">
                              <TextField
                                id="numberticketFrom" 
                                name="numberticketFrom"
                                value={filtersTicketsDetails.numberticketFrom}
                                inputProps={{ style: {width: 400}, maxLength: 20 }}
                                label={errorsFiltersTicketsDetails.numberticketFrom!=null?t(errorsFiltersTicketsDetails.numberticketFrom):t('Text_Filter_NumberTicket_From')}
                                placeholder={t('PlaceHolder_Filter_NumberTicket_From')}
                                variant="outlined"
                                onBlur={handleBlurFilterEditTicketsDetails}
                                onChange={handleChangeFilterEditTicketsDetails}
                              />
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  
                              <TextField
                                id="numberticketTo" 
                                name="numberticketTo"
                                value={filtersTicketsDetails.numberticketTo}
                                inputProps={{ style: {width: 400}, maxLength: 20 }}
                                label={errorsFiltersTicketsDetails.numberticketTo!=null?t(errorsFiltersTicketsDetails.preiddocSheetTo):t('Text_Filter_NumberTicket_To')}
                                placeholder={t('PlaceHolder_Filter_NumberTicket_to')}
                                variant="outlined"
                                onBlur={handleBlurFilterEditTicketsDetails}
                                onChange={handleChangeFilterEditTicketsDetails}
                              />
                            </div>     
                            <div className="form-group">
                              <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DesktopDatePicker
                                  id="start_date_ticketFrom" 
                                  name="start_date_ticketFrom"
                                  value={ filtersTicketsDetails.start_date_ticketFrom!== null ? new Date(filtersTicketsDetails.start_date_ticketFrom): null}
                                  format={i18n.language==="es"?"dd/MM/yyyy":"MM/dd/yyyy"}
                                  label={errorsFiltersTicketsDetails.start_date_ticketFrom!=null?t(errorsFiltersTicketsDetails.start_date_ticketFrom):t('Text_Filter_FirstWeight_From')}
                                  onBlur={handleBlurSelectStartDateTicketsDetailsFrom}
                                  onChange={handleChangeSelectStartDateTicketsDetailsFrom}
                                  slotProps={{
                                    textField: {
                                      placeholder: i18n.language==="es"?"dd/MM/yyyy":"MM/dd/yyyy",
                                      sx: {
                                        color: '#1565c0',
                                        maxLength: '100',
                                        width: 430
                                      }
                                    },
                                  }}
                                />
                              </LocalizationProvider>
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
                              <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DesktopDatePicker
                                  id="start_date_ticketTo" 
                                  name="start_date_ticketTo"
                                  minDate={filtersTicketsDetails.start_date_ticketFrom!== null ? new Date(filtersTicketsDetails.start_date_ticketFrom): null}
                                  value={ filtersTicketsDetails.start_date_ticketTo!== null ? new Date(filtersTicketsDetails.start_date_ticketTo): null}
                                  format={i18n.language==="es"?"dd/MM/yyyy":"MM/dd/yyyy"}
                                  label={errorsFiltersTicketsDetails.start_date_ticketTo!=null?t(errorsFiltersTicketsDetails.start_date_ticketTo):t('Text_Filter_FirstWeight_To')}
                                  onBlur={handleBlurSelectStartDateTicketsDetailsTo}
                                  onChange={handleChangeSelectStartDateTicketsDetailsTo}
                                  slotProps={{
                                    textField: {
                                      placeholder: i18n.language==="es"?"dd/MM/yyyy":"MM/dd/yyyy",
                                      sx: {
                                        color: '#1565c0',
                                        maxLength: '100',
                                        width: 430
                                      
                                      }
                                    },
                                  }}
                                />
                              </LocalizationProvider>
                            </div>
                            <div className="form-group">
                              <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DesktopDatePicker
                                  id="end_date_ticketFrom" 
                                  name="end_date_ticketFrom"
                                  value={ filtersTicketsDetails.end_date_ticketFrom!== null ? new Date(filtersTicketsDetails.end_date_ticketFrom): null}
                                  format={i18n.language==="es"?"dd/MM/yyyy":"MM/dd/yyyy"}
                                  label={errorsFiltersTicketsDetails.end_date_ticketFrom!=null?t(errorsFiltersTicketsDetails.end_date_ticketFrom):t('Text_Filter_EndWeight_From')}
                                  onBlur={handleBlurSelectEndDateTicketsDetailsFrom}
                                  onChange={handleChangeSelectEndDateTicketsDetailsFrom}
                                  slotProps={{
                                    textField: {
                                      placeholder: i18n.language==="es"?"dd/MM/yyyy":"MM/dd/yyyy",
                                      sx: {
                                        color: '#1565c0',
                                        maxLength: '100',
                                        width: 430
                                      }
                                    },
                                  }}
                                />
                              </LocalizationProvider>
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
                              <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DesktopDatePicker
                                  id="end_date_tickettTo" 
                                  name="end_date_tickettTo"
                                  minDate={filtersTicketsDetails.end_date_ticketFrom!== null ? new Date(filtersTicketsDetails.end_date_ticketFrom): null}
                                  value={ filtersTicketsDetails.end_date_tickettTo!== null ? new Date(filtersTicketsDetails.end_date_tickettTo): null}
                                  format={i18n.language==="es"?"dd/MM/yyyy":"MM/dd/yyyy"}
                                  label={errorsFiltersTicketsDetails.end_date_tickettTo!=null?t(errorsFiltersTicketsDetails.end_date_tickettTo):t('Text_Filter_EndWeight_To')}
                                  onBlur={handleBlurSelectEndDateTicketsDetailsTo}
                                  onChange={handleChangeSelectEndDateTicketsDetailsTo}
                                  slotProps={{
                                    textField: {
                                      placeholder: i18n.language==="es"?"dd/MM/yyyy":"MM/dd/yyyy",
                                      sx: {
                                        color: '#1565c0',
                                        maxLength: '100',
                                        width: 430
                                      
                                      }
                                    },
                                  }}
                                />
                              </LocalizationProvider>
                            </div> 
                            <div className="form-group">
                              <TextField
                                id="vehiclestore_licenseplateFrom" 
                                name="vehiclestore_licenseplateFrom"
                                value={filtersTicketsDetails.vehiclestore_licenseplateFrom}
                                inputProps={{ style: {width: 400}, maxLength: 20 }}
                                label={errorsFiltersTicketsDetails.vehiclestore_licenseplateFrom!=null?t(errorsFiltersTicketsDetails.vehiclestore_licenseplateFrom):t('Text_Filter_LicensePlate_From')}
                                placeholder={t('PlaceHolder_Filter_LicensePlate_From')}
                                variant="outlined"
                                onBlur={handleBlurFilterEditTicketsDetails}
                                onChange={handleChangeFilterEditTicketsDetails}
                              />
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  
                              <TextField
                                id="vehiclestore_licenseplateTo" 
                                name="vehiclestore_licenseplateTo"
                                value={filtersTicketsDetails.vehiclestore_licenseplateTo}
                                inputProps={{ style: {width: 400}, maxLength: 20 }}
                                label={errorsFiltersTicketsDetails.vehiclestore_licenseplateTo!=null?t(errorsFiltersTicketsDetails.vehiclestore_licenseplateTo):t('Text_Filter_LicensePlate_To')}
                                placeholder={t('PlaceHolder_Filter_LicensePlate_To')}
                                variant="outlined"
                                onBlur={handleBlurFilterEditTicketsDetails}
                                onChange={handleChangeFilterEditTicketsDetails}
                              />
                            </div>
                            <div className="form-group">
                              <TextField
                                id="numberDIFROM" 
                                name="numberDIFROM"
                                value={filtersTicketsDetails.numberDIFROM}
                                inputProps={{ style: {width: 400}, maxLength: 20 }}
                                label={errorsFiltersTicketsDetails.numberDIFROM!=null?t(errorsFiltersTicketsDetails.numberDIFROM):t('Text_Filter_FormDI_From')}
                                placeholder={t('PlaceHolder_Filter_FormDI_From')}
                                variant="outlined"
                                onBlur={handleBlurFilterEditTicketsDetails}
                                onChange={handleChangeFilterEditTicketsDetails}
                              />
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  
                              <TextField
                                id="numberDITO" 
                                name="numberDITO"
                                value={filtersTicketsDetails.numberDITO}
                                inputProps={{ style: {width: 400}, maxLength: 20 }}
                                label={errorsFiltersTicketsDetails.numberDITO!=null?t(errorsFiltersTicketsDetails.numberDITO):t('Text_Filter_FormDI_To')}
                                placeholder={t('PlaceHolder_Filter_FormDI_To')}
                                variant="outlined"
                                onBlur={handleBlurFilterEditTicketsDetails}
                                onChange={handleChangeFilterEditTicketsDetails}
                              />
                            </div>
                            <Suspense delayMs={500} fallback={<CircularProgress sx={{ color :"#f6c23e"}}/>}>
                              <div className="form-group">
                                <TextField
                                  id="rel_trans_store_idFrom"
                                  name="rel_trans_store_idFrom"
                                  value={filtersTicketsDetails.rel_trans_store_idFrom}
                                  inputProps={{ style: {width: 382}, maxLength: 100 }}
                                  label={errorsFiltersTicketsDetails.rel_trans_store_idFrom!=null?t(errorsFiltersTicketsDetails.rel_trans_store_idFrom):t('Text_Filter_Trans_From')}
                                  placeholder={t('PlaceHolder_Filter_Trans_From')}
                                  variant="outlined"
                                  onBlur={handleBlurFilterEditTicketsDetails}
                                  onChange={handleChangeFilterEditTicketsDetails}
                                  select
                                  SelectProps={{
                                  native: true,
                                  }}>
                                  <option key={0} value=""/>
                                  {trasnporterToSelect.map((option) => (
                                      <option key={option.id} value={option.tradename}>
                                          {option.documentid+" - "+option.tradename}
                                      </option>
                                  ))}
                                </TextField>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  
                                <TextField
                                  id="rel_trans_store_idTo"
                                  name="rel_trans_store_idTo"
                                  value={filtersTicketsDetails.rel_trans_store_idTo}  
                                  inputProps={{ style: {width: 382}, maxLength: 100 }}
                                  label={errorsFiltersTicketsDetails.rel_trans_store_idTo!=null?t(errorsFiltersTicketsDetails.rel_trans_store_idTo):t('Text_Filter_Trans_To')}
                                  placeholder={t('PlaceHolder_Filter_Trans_To')}
                                  variant="outlined"
                                  onBlur={handleBlurFilterEditTicketsDetails}
                                  onChange={handleChangeFilterEditTicketsDetails}
                                  select
                                  SelectProps={{
                                  native: true,
                                  }}>
                                    <option key={0} value=""/>
                                  {trasnporterToSelect.filter(item=>(item.tradename>=filtersTicketsDetails.rel_trans_store_idFrom)).map(option => (
                                    <option key={option.id} value={option.tradename}>
                                        {option.documentid+" - "+option.tradename}
                                    </option>
                                  ))}
                                </TextField>
                              </div>
                              <div className="form-group">
                                <TextField
                                  id="ori_tras_store_idFrom"
                                  name="ori_tras_store_idFrom"
                                  value={filtersTicketsDetails.ori_tras_store_idFrom}
                                  inputProps={{ style: {width: 382}, maxLength: 100 }}
                                  label={errorsFiltersTicketsDetails.ori_tras_store_idFrom!=null?t(errorsFiltersTicketsDetails.ori_tras_store_idFrom):t('Text_Filter_TradeName_From')}
                                  placeholder={t('PlaceHolder_Filter_TradeName_From')}
                                  variant="outlined"
                                  onBlur={handleBlurFilterEditTicketsDetails}
                                  onChange={handleChangeFilterEditTicketsDetails}
                                  select
                                  SelectProps={{
                                  native: true,
                                  }}>
                                  <option key={0} value=""/>
                                  {tradenameToSelect.map((option) => (
                                      <option key={option.id} value={option.tradename}>
                                          {option.documentid+" - "+option.tradename}
                                      </option>
                                  ))}
                                </TextField>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  
                                <TextField
                                  id="ori_tras_store_idTo"
                                  name="ori_tras_store_idTo"
                                  value={filtersTicketsDetails.ori_tras_store_idTo}  
                                  inputProps={{ style: {width: 382}, maxLength: 100 }}
                                  label={errorsFiltersTicketsDetails.ori_tras_store_idTo!=null?t(errorsFiltersTicketsDetails.ori_tras_store_idTo):t('Text_Filter_TradeName_To')}
                                  placeholder={t('PlaceHolder_Filter_TradeName_To')}
                                  variant="outlined"
                                  onBlur={handleBlurFilterEditTicketsDetails}
                                  onChange={handleChangeFilterEditTicketsDetails}
                                  select
                                  SelectProps={{
                                  native: true,
                                  }}>
                                    <option key={0} value=""/>
                                  {tradenameToSelect.filter(item=>(item.tradename>=filtersTicketsDetails.ori_tras_store_idFrom)).map(option => (
                                    <option key={option.id} value={option.tradename}>
                                        {option.documentid+" - "+option.tradename}
                                    </option>
                                  ))}
                                </TextField>
                              </div>
                            </Suspense>
                          </div> 
                          <hr className="sidebar-divider d-none d-md-block"/>
                          <div className="btn-group d-grid d-md-flex justify-content-md-end" role="group">
                            <button className="btn btn-primary btn-lg " type="button" onClick={handleSubmitFilterEditTicketsDetails}>{t('Button_Apply')}</button><> </>
                            <button className="btn btn-outline-secondary btn-lg" type="button" onClick={handleDeleteFiltersTicketsDetails}>{t('Button_Cancel_Filters')}</button>
                          </div>
                        </form>
                      </Box>
                    </Container>
                  </SwipeableDrawer>
                </div>
              }
              <TicketsPDF 
                rowEditTicketsDetails={rowEditTicketsDetails} 
                viewPDF={viewPDF} 
                setViewPDF={setViewPDF}
              />
              <Dialog open={openDeleteRowTicketsDetails} onClose={handleCancelDeleteRowTicketsDetails}>
                <DialogTitle style={{color:"#e74a3b"}}>{rowEditTicketsDetails.enabled?t('Text_Delete'):t('Text_UnDelete')} {rowEditTicketsDetails.id}  - {rowEditTicketsDetails.numberticket} - {rowEditTicketsDetails.vehiclestore_licenseplate} </DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      {rowEditTicketsDetails.enabled?
                      <>
                        {t('Text_Line1')}<br/>
                        {t('Text_Line2')}
                      </>:
                      <>
                       {t('Text_Line3')}<br/>
                       {t('Text_Line4')}
                       </>
                      }
                    </DialogContentText>
                      <TextField
                        autoFocus
                        margin="dense"
                        id="idCode"
                        label={t('Text_Label')}
                        type="text"
                        fullWidth
                        variant="standard"
                        error
                        defaultValue={idCodeDelete}
                        onChange={handleChangeDeleteRowTicketsDetails}/>
                    </DialogContent>
                  <DialogActions>
                  <Button style={{color:"#e74a3b",fontSize: 16 }} onClick={handleCancelDeleteRowTicketsDetails}>{t('Button_Cancel')}</Button>
                  <Button variant="contained" style={{color:"#ffffff", background:"#e74a3b",fontSize: 16}} onClick={handleCloseDeleteRowTicketsDetails}>{rowEditTicketsDetails.enabled?t('Button_Delete'):t('Button_UnDelete')}</Button>
                </DialogActions>
              </Dialog> 
              {/* End Content Row */}
          </>:
            <Loading isLoadingData={isLoadingTicketsDetails}/>
          }
        </div>
        //* End Page Content *//
      ) :  null }
    </>
  )
}

TicketsDetailsFilter.propTypes = { 
  setApplyFiltersTicketsDetails: PropTypes.func.isRequired,
  showModalTicketsDetails: PropTypes.bool.isRequired 
};

export default TicketsDetailsFilter;

