import React, {  useEffect, useCallback, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CircularProgress from '@mui/material/CircularProgress';  
import { SwipeableDrawer } from '@mui/material';
import { Checkbox} from  '@mui/material';
import { Container, Box } from '@mui/material';
import { TextField } from '@mui/material'; 
import { Button } from '@mui/material'; 
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux";  
import "css/generalSettings.css";
import { successErrorAction,warningErrorAction } from "actions/ErrorActions";
import { helpHttp } from "service/HelpHttp";
import TableSorting from "./SortingTables";
import ProvinceSettings from "./ProvinceSettings"
import PropTypes from 'prop-types';    

const initialFilters= {
  "idFrom":"",
  "idTo":"",
  "codeFrom":"",
  "codeTo":"",
  "nameesFrom":"",
  "nameesTo":"",
  "nameenFrom":"",
  "nameenTo":"",
  "codeHexFrom":"",
  "codeHexTo":"",
  "codeCotGroupFrom":"",
	"codeCotGroupTo":"",
  "code_iso2From":"",
  "code_iso2To":"",
  "code_iso3From":"",
  "code_iso3To":"",
  "extphoneFrom":"",
  "extphoneTo":"",
  "extinternetFrom":"",
  "extinternetTo":"",
  "isEnabled":true,
  "isNotEnabled":true
};

const initialState = [{
  "id" : "",
  "namees" : "",
  "nameen" : "",
  "code_iso2" : "",
  "code_iso3" : "",
  "extphone" : "",
  "extinternet" : "",
  "enabled":false,
  "numTotProvinces":0,
  "provinces":[]
}];

const headCells = [
  {
    id: 'id',
    numeric: false,
    disablePadding: false,
    label: 'Text_Code',
    canOrder:true,
  },
  {
    id: 'namees',
    numeric: false,
    disablePadding: false,
    label: 'Text_NameEs',
    canOrder:true,
  },
  {
    id: 'nameen',
    numeric: false,
    disablePadding: false,
    label: 'Text_NameEn',
    canOrder:true,
  },
  {
    id: 'code_iso2',
    numeric: false,
    disablePadding: false,
    label: 'Text_NameCodeIso2',
    canOrder:true,
  },
  {
    id: 'code_iso3',
    numeric: false,
    disablePadding: false,
    label: 'Text_NameCodeIso3',
    canOrder:true,
  },
  {
    id: 'extphone',
    numeric: false,
    disablePadding: false,
    label: 'Text_Extphone',
    canOrder:true,
  },
  {
    id: 'extinternet',
    numeric: false,
    disablePadding: false,
    label: 'Text_Extinternet',
    canOrder:true,
  },
  {
    id: 'enabled',
    numeric: false,
    disablePadding: false,
    label: 'Text_Enabled',
    canOrder:false,
  },
  {
    id: 'provinces',
    numeric: false,
    disablePadding: false,
    label: 'Text_Provinces',
    canOrder:false,
  }
];

const CountrySettings = ({showCountrySettings, setShowModalCountrySettings,setIndexShowSettings,setShowModalGeneralSettings,setShowModalEmployeeSettings,isEmployee,setShowModalStoreSettings,isStore,setShowModalFormSettings,isForm,setShowModalContractSettings,isContract,setShowModalTicketsSettings,isWweigVehicles,setShowModalPurchasesDeliverySettings,isPurchasesDelivery,setShowModalShippingsSettings,isShipping}) => {
  
  const {t} = useTranslation(['listDataGeneralSettings']);
  const token = useSelector((state) => state.loginUser.token); 
  const roles = useSelector((state) => state.loginUser.roles);  
  const ENDPOINT = useSelector((state) => state.variablesUser.ENDPOINT); 
  const LIMITPAGESIZE = useSelector((state) => state.variablesUser.LIMITPAGESIZE); 
  const NON_ERASABLE_COUNTRY_ID = useSelector((state) => state.variablesUser.NON_ERASABLE_COUNTRY_ID); 
  const DELETE_CODE = useSelector((state) => state.formatDIUser.delete_code);  
  const [data, setData] = useState(initialState);
  const [form, setForm] = useState(initialState[0]);
  const [filters, setFilters] = useState(initialFilters);
  const [applyFilters, setApplyFilters] = useState(initialFilters);
  const [numTotReg, setNumTotReg] = useState(0);
  const [numTotRegFilter, setNumTotRegFilter] = useState(0);
  const [errorsFilters, setErrorsFilters] = useState({});
  const [errors, setErrors] = useState({});
  const [inputText, setInputText] = useState("");
  const [openCreateData, setOpenCreateData] = useState(false);
  const [openDeleteRow, setOpenDeleteRow] = useState(false);
  const [openEditRow, setOpenEditRow] = useState(false);
  const [openEditFilter, setOpenEditFilter] = useState(false);
  const [rowEdit, setRowEdit] = useState(initialState[0]);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingError, setIsLoadingError] = useState(false);
  const [idCodeDelete, setIdCodeDelete] = useState(DELETE_CODE);
  const dispatch = useDispatch();
  const [activeOffset, setActiveOffset] = useState(false);
  const [currencyOffset, setCurrencyOffset] = useState(0);
  const [rangeOffset, setRangeOffset] = useState([{}]); 
  const [openCreateProvince,setOpenCreateProvince]=useState(false);  
  
  const keyPress = useCallback(
    (e) => {
      if (e.key === 'Escape' && showCountrySettings) {
        setData(initialState);
        setForm(initialState[0]);
        setFilters(initialFilters);
        setApplyFilters(initialFilters);
        setNumTotReg(0);
        setNumTotRegFilter(0);
        setErrorsFilters({});
        setErrors({});
        setInputText("");
        setOpenCreateData(false);
        setOpenDeleteRow(false);
        setOpenEditRow(false);
        setOpenEditFilter(false);
        setRowEdit(initialState[0]);
        setIsLoading(false);
        setIsLoadingError(false);
        setIdCodeDelete(DELETE_CODE);
        setActiveOffset(false);
        setCurrencyOffset(0);
        setRangeOffset([{}]);
        setOpenCreateProvince(false);
        setIndexShowSettings(0);
        setShowModalCountrySettings(false);
        setShowModalGeneralSettings(true);
        setShowModalEmployeeSettings(isEmployee);
        setShowModalStoreSettings(isStore);
        setShowModalFormSettings(isForm);
        setShowModalTicketsSettings(isWweigVehicles);
        setShowModalContractSettings(isContract);
        setShowModalPurchasesDeliverySettings(isPurchasesDelivery);
        setShowModalShippingsSettings(isShipping);
      }
    },
    [
      setData,
      setForm,
      setFilters,
      setApplyFilters,
      setNumTotReg,
      setNumTotRegFilter,
      setErrorsFilters,
      setErrors,
      setInputText,
      setOpenCreateData,
      setOpenDeleteRow,
      setOpenEditRow,
      setOpenEditFilter,
      setRowEdit,
      setIsLoading,
      setIsLoadingError,
      setIdCodeDelete,
      setActiveOffset,
      setCurrencyOffset,
      setRangeOffset,
      setOpenCreateProvince,
      showCountrySettings, 
      setShowModalCountrySettings,
      setIndexShowSettings,
      setShowModalGeneralSettings,
      setShowModalEmployeeSettings,
      isEmployee,
      setShowModalStoreSettings,
      isStore,
      setShowModalFormSettings,
      isForm,
      setShowModalTicketsSettings,
      isWweigVehicles,
      setShowModalContractSettings,
      isContract,
      setShowModalPurchasesDeliverySettings,
      isPurchasesDelivery,
      setShowModalShippingsSettings,
      isShipping,
      DELETE_CODE
    ]
  );

  const closeModalCountryTypeSettings = () => {    
    setData(initialState);
    setForm(initialState[0]);
    setFilters(initialFilters);
    setApplyFilters(initialFilters);
    setNumTotReg(0);
    setNumTotRegFilter(0);
    setErrorsFilters({});
    setErrors({});
    setInputText("");
    setOpenCreateData(false);
    setOpenDeleteRow(false);
    setOpenEditRow(false);
    setOpenEditFilter(false);
    setRowEdit(initialState[0]);
    setIsLoading(false);
    setIsLoadingError(false);
    setIdCodeDelete(DELETE_CODE);
    setActiveOffset(false);
    setCurrencyOffset(0);
    setRangeOffset([{}]);
    setOpenCreateProvince(false);
    setIndexShowSettings(0);
    setShowModalCountrySettings(false);
    setShowModalGeneralSettings(true);
    setShowModalEmployeeSettings(isEmployee);
    setShowModalStoreSettings(isStore);
    setShowModalFormSettings(isForm);
    setShowModalTicketsSettings(isWweigVehicles);
    setShowModalContractSettings(isContract);
    setShowModalPurchasesDeliverySettings(isPurchasesDelivery); 
    setShowModalShippingsSettings(isShipping);
  };

  const openCreateOrEditData = () => {
    setInputText("");
    setOpenCreateData(true);
  };

  const openEditFilters = () => {
    setFilters(applyFilters);
    setOpenEditFilter(!openEditFilter);
  };

  const handleSubmitFilterEdit = () => {
    setErrorsFilters(validateFormFilter());
    if (Object.keys(errorsFilters).length === 0) {
      setApplyFilters(filters);
      setFilters(initialFilters);
      setErrorsFilters({});
      setOpenEditFilter(false);
    }  else{
      dispatch(warningErrorAction(t('Text_Error_Filter')));
    }
  }
  
  const handleDeleteFilters = () => {
    setErrorsFilters({});
    setFilters(initialFilters);
    setApplyFilters(initialFilters);
    setOpenEditFilter(!openEditFilter);
  };

  const handleCancelFilters = () => {
    setErrorsFilters({});
    setFilters(initialFilters);
    setOpenEditFilter(!openEditFilter);
  };

  const handleChangeEnabledFilter = (e) => {
    setFilters(filters => ({
      ...filters,
      isEnabled: !filters.isEnabled
    }));
  };

  const handleChangeNotEnabledFilter = (e) => {
    setFilters(filters => ({
      ...filters,
      isNotEnabled: !filters.isNotEnabled
    }));
  };
  
  const handleChangeFilterEdit = (e) => {
    const { name, value } = e.target;
    setFilters(filters => ({
      ...filters,
      [name]: value
    }));
  };

  const handleBlurFilterEdit = (e) => {
    setErrorsFilters(validateFormFilter());
    handleChangeFilterEdit(e);
  };

  const toggleDrawerFilterEdit = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    if (event.type === 'keydown') {
      event.preventDefault();
      setErrorsFilters({});
      setFilters(initialFilters);
      setOpenEditFilter(!openEditFilter);
    }
  };

  const validateFormFilter = () => {

    let errorsFilters = {};
    let regexNumber = /^[0-9]+$/;
    let regexInvalidCharacters = /[\\!\\$\\%\\&\\|\\<\\>\\?\\{\\}\\~\\"\\']+/;

    if (filters.idFrom!=="" & !regexNumber.test(filters.idFrom)) {
      errorsFilters.idFrom = 'Text_TextNoNumber';
    }
    if (filters.idTo!=="" & !regexNumber.test(filters.idTo)) {
      errorsFilters.idTo = 'Text_TextNoNumber';
    }
    if (filters.idFrom!=="" & !errorsFilters.idFrom!==undefined){
      if (filters.idTo!=="" & !errorsFilters.idTo!==undefined){
        if (parseInt(filters.idTo)<parseInt(filters.idFrom)){
          errorsFilters.idTo = 'Text_TextGreater_Id';
        }
      }
    }

    if (regexInvalidCharacters.test(filters.nameesFrom)) {
      errorsFilters.nameesFrom = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(filters.nameesTo)) {
      errorsFilters.nameesTo = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(filters.nameenFrom)) {
      errorsFilters.nameenFrom = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(filters.nameenTo)) {
      errorsFilters.nameenTo = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(filters.code_iso2From)) {
      errorsFilters.code_iso2From = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(filters.code_iso2To)) {
      errorsFilters.code_iso2To = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(filters.code_iso3From)) {
      errorsFilters.code_iso3From = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(filters.code_iso3To)) {
      errorsFilters.code_iso3To = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(filters.extphoneFrom)) {
      errorsFilters.extphoneFrom = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(filters.extphoneTo)) {
      errorsFilters.extphoneTo = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(filters.extinternetFrom)) {
      errorsFilters.extinternetFrom = 'Text_TextNoValid';
    } 
    
    if (regexInvalidCharacters.test(filters.extinternetTo)) {
      errorsFilters.extinternetTo = 'Text_TextNoValid';
    } 
    
    if (filters.nameesTo!=="" & filters.nameesTo<filters.nameesFrom){
      errorsFilters.nameesTo = 'Text_TextGreater_Namees';
    }
    if (filters.nameenTo!=="" & filters.nameenTo<filters.nameenFrom){
      errorsFilters.nameenTo = 'Text_TextGreater_Nameen';
    }
    if (filters.code_iso2To!=="" & filters.code_iso2To<filters.code_iso2From){
      errorsFilters.code_iso2To = 'Text_TextGreater_Code_Iso2';
    }
    if (filters.code_iso3To!=="" & filters.code_iso3To<filters.code_iso3From){
      errorsFilters.code_iso3To = 'Text_TextGreater_Code_Iso3';
    }
    if (filters.extphoneTo!=="" & filters.extphoneTo<filters.extphoneFrom){
      errorsFilters.extphoneTo = 'Text_TextGreater_Extphone';
    }
    if (filters.extinternetTo!=="" & filters.extinternetTo<filters.extinternetFrom){
      errorsFilters.extinternetTo = 'Text_TextGreater_Extinternet';
    }
    return errorsFilters;
  }  

  const filteredData = data.filter((el) => {
    if (inputText === '') {
        return el;
    }
    else {
      let dataTranslate=t(el.id+el.namees+el.nameen+el.code_iso2+el.code_iso3+el.extinternet+el.flag) ;
      return dataTranslate.toLowerCase().includes(inputText);
    }
  })

  let inputHandler = (e) => {
    var lowerCase = e.target.value.toLowerCase();
    setInputText(lowerCase);
  };

  const handleChangeDeleteRow = (e) => {
    const value  = e.target.value;
    setIdCodeDelete(value);
  };

  const handleCancelDeleteRow = () => {
    setErrors({});
    setRowEdit(initialState[0]);
    setForm(initialState[0]);
    setIsLoading(false);
    setIsLoadingError(false);
    setIdCodeDelete(DELETE_CODE);
    setOpenDeleteRow(false);
  };

  const handleCloseDeleteRow = (e) => {
    if (idCodeDelete !== DELETE_CODE) {
      dispatch(warningErrorAction(t('Text_InvalidCode')));
      return;
    }
  
    if (e.key !== 'Escape') {
      if (rowEdit.id !== '') {
        const fetchData = async () => {
          setIsLoading(true);
          try {
            const getData = await helpHttp().del(ENDPOINT, `country/deletecounty`, token, rowEdit);
            if (getData.status === "OK") {
              dispatch(successErrorAction(t('Text_ValidCode')));
              dispatch(successErrorAction(t('Text_DeleteField')));
            } else {
              dispatch(warningErrorAction(t(getData.cause)));
            }
          } catch (error) {
            dispatch(warningErrorAction(t('Text_IncorrectData')));
            setIsLoading(false);
          } finally {
            setIsLoading(false);
          }
        };
        fetchData();
      } else {
        dispatch(warningErrorAction(t('Text_InvalidId')));
      }
    }
  
    setIdCodeDelete(DELETE_CODE);
    setOpenDeleteRow(false);
    handleCloseDataEdit();
  };
   
  const handleSubmitDataEdit = (e) => {
    setErrors(validateForm());
    const errorsToCheck = validateForm();
  
    if (Object.keys(errorsToCheck).length === 0 && form.namees !== '' && form.nameen !== '' && form.code_iso2 !== '' && form.code_iso3 !== '' && form.extinternet !== '') {
      const fetchData = async () => {
        setIsLoading(true);
        try {
          const getData = openEditRow
            ? await helpHttp().put(ENDPOINT, `country/updatecountry`, token, form)
            : await helpHttp().post(ENDPOINT, `country/savecountry`, token, form);
  
          if (getData.status === "OK") {
            dispatch(successErrorAction(t('Text_SaveData')));
            handleCloseDataEdit();
          } else {
            dispatch(warningErrorAction(t(getData.cause)));
          }
        } catch (error) {
          dispatch(warningErrorAction(t('Text_IncorrectData')));
          setIsLoading(false);
        } finally {
          setIsLoading(false);
        }
      };
      fetchData();
    } else {
      if (errorsToCheck.code) {
        dispatch(warningErrorAction(t(errorsToCheck.code)));
      } else if (errorsToCheck.namees) {
        dispatch(warningErrorAction(t(errorsToCheck.namees)));
      } else if (errorsToCheck.nameen) {
        dispatch(warningErrorAction(t(errorsToCheck.nameen)));
      } else if (errorsToCheck.code_iso2) {
        dispatch(warningErrorAction(t(errorsToCheck.code_iso2)));
      } else if (errorsToCheck.code_iso3) {
        dispatch(warningErrorAction(t(errorsToCheck.code_iso3)));
      } else if (errorsToCheck.extinternet) {
        dispatch(warningErrorAction(t(errorsToCheck.extinternet)));
      } else {
        dispatch(warningErrorAction(t('Text_IncorrectData')));
      }
    }
  };  

  const handleCloseDataEdit = () => {
    setOpenCreateData(false);
    setOpenEditRow(false);
    setErrors({});
    setRowEdit(openEditRow?rowEdit:initialState[0]);
    setForm(initialState[0]);
    setIsLoading(false);
    setIsLoadingError(false);
    toggleDrawerDataEdit(false);
	};

  const handleChangeEnabled = (e) => {
    setForm(form => ({
      ...form,
      enabled: !form.enabled
    }));
  };

  const handleChangeDataEdit = (e) => {
    const { name, value } = e.target;
    setForm(form => ({
      ...form,
      [name]: value
    }));
  };

  const handleBlurDataEdit = (e) => {
    handleChangeDataEdit(e);
    setErrors(validateForm());
  };
   
  const toggleDrawerDataEdit = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    if (event.type === 'keydown') {
      event.preventDefault()
      handleCloseDataEdit();
    }
  };

  const validateForm = () => {
    let errors = {};
    let regexInvalidCharacters = /[\\!\\$\\%\\&\\|\\<\\>\\?\\{\\}\\~\\"\\']+/;

    if (!form.namees || form.namees==="") {
      errors.namees = 'Text_FieldNameesRequired';
    } else {
      if (regexInvalidCharacters.test(form.namees)) {
        errors.namees = 'Text_TextNoValid';
      } else{
        if (openEditRow){
          if (data.find((key=>(key.namees.toLowerCase()===form.namees.toLowerCase() && key.id!==rowEdit.id)))) {
              errors.namees = 'Text_Country_NameesExist';
            }
          } else {
            if (data.find((key=>key.namees.toLowerCase()===form.namees.toLowerCase()))) {
              errors.namees = 'Text_Country_NameesExist';
          }
        }  
      }  
    }
    if (!form.nameen || form.nameen==="") {
      errors.nameen = 'Text_FieldNameenRequired';
    } else {
      if (regexInvalidCharacters.test(form.nameen)) {
        errors.nameen = 'Text_TextNoValid';
      } else{
        if (openEditRow){
          if (data.find((key=>(key.nameen.toLowerCase()===form.nameen.toLowerCase() && key.id!==rowEdit.id)))) {
            errors.nameen = 'Text_Country_NameenExist';
          }
        } else {
          if (data.find((key=>key.nameen.toLowerCase()===form.nameen.toLowerCase()))) {
            errors.nameen = 'Text_Country_NameenExist';
          }
        }     
      }  
    }
    if (!form.code_iso2 || form.code_iso2==="") {
      errors.code_iso2 = 'Text_FieldCodeIso2Required';
    } else {
      if (regexInvalidCharacters.test(form.code_iso2)) {
        errors.code_iso2 = 'Text_TextNoValid';
      } else{
        if (openEditRow){
          if (data.find((key=>(key.code_iso2.toLowerCase()===form.code_iso2.toLowerCase() && key.id!==rowEdit.id)))) {
            errors.code_iso2 = 'Text_Country_CodeIso2Exist';
          }
        } else {
          if (data.find((key=>key.code_iso2.toLowerCase()===form.code_iso2.toLowerCase()))) {
            errors.code_iso2 = 'Text_Country_CodeIso2Exist';
          }
        }  
      }  
    }
    if (!form.code_iso3 || form.code_iso3==="") {
      errors.code_iso3 = 'Text_FieldCodeIso3Required';
    } else {
      if (regexInvalidCharacters.test(form.code_iso3)) {
        errors.code_iso3 = 'Text_TextNoValid';
      } else{
        if (openEditRow){
          if (data.find((key=>(key.code_iso3.toLowerCase()===form.code_iso3.toLowerCase() && key.id!==rowEdit.id)))) {
            errors.code_iso3 = 'Text_Country_CodeIso3Exist';
          }
        } else {
          if (data.find((key=>key.code_iso3.toLowerCase()===form.code_iso3.toLowerCase()))) {
            errors.code_iso3 = 'Text_Country_CodeIso3Exist';
          }
        }   
      }  
    }

    if (!form.extphone || form.extphone==="") {
      errors.extphone = 'Text_FieldExtPhoneRequired';
    }else {
      if (regexInvalidCharacters.test(form.extphone)) {
        errors.extphone = 'Text_TextNoValid';
      }
    }  

    if (!form.extinternet || form.extinternet==="") {
      errors.extinternet = 'Text_FieldExtInternetRequired';
    } else {
      if (regexInvalidCharacters.test(form.extinternet)) {
        errors.extinternet = 'Text_TextNoValid';
      } else{
      if (openEditRow){
          if (data.find((key=>(key.extinternet.toLowerCase()===form.extinternet.toLowerCase() && key.id!==rowEdit.id)))) {
            errors.extinternet = 'Text_Country_CodeExtInternetExist';
          }
        } else {
          if (data.find((key=>key.extinternet.toLowerCase()===form.extinternet.toLowerCase()))) {
            errors.extinternet = 'Text_Country_CodeExtInternetExist';
          }
        }
      }  
    }
    if (!form.enabled || form.enabled==="") {
      form.enabled=false;
    }
    return errors;
  };

  useEffect(() => {
    if (openEditRow){
      setForm(form => ({
        ...form,
        id : rowEdit.id!==null?rowEdit.id:"",
        namees : rowEdit.namees!==null?rowEdit.namees:"",
        nameen : rowEdit.nameen!==null?rowEdit.nameen:"",
        code_iso2 : rowEdit.code_iso2!==null?rowEdit.code_iso2:"",
        code_iso3 : rowEdit.code_iso3!==null?rowEdit.code_iso3:"",
        extphone : rowEdit.extphone!==null?rowEdit.extphone:"",
        extinternet :rowEdit.extinternet!==null?rowEdit.extinternet:"",
        enabled :rowEdit.enabled!==null?rowEdit.enabled:false,
        numTotProvinces :rowEdit.numTotProvinces!==null?rowEdit.numTotProvinces:0,
        provinces :rowEdit.provinces!==null?rowEdit.provinces:[]
      }));
      
    }else{
      setForm(initialState[0]); 
    }

  },[rowEdit,openEditRow]);

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event && typeof event === 'object' && event.key) {
        keyPress(event);
      }
    };
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [keyPress]);

  useEffect(() => {
    if (numTotRegFilter > LIMITPAGESIZE) {
      setActiveOffset(true);
      const newRangeOffset = [
        {
          value: 0,
          label: " 1 - " + Number(LIMITPAGESIZE).toLocaleString('de-DE'),
        }
      ];
      for (let i = Number(LIMITPAGESIZE); i < numTotRegFilter; i += Number(LIMITPAGESIZE)) {
        newRangeOffset.push({
          value: i,
          label: " " + (i + 1).toLocaleString('de-DE') + " - " + (i + Number(LIMITPAGESIZE)).toLocaleString('de-DE'),
        });
      }
      setRangeOffset(newRangeOffset);
    } else {
      setCurrencyOffset(0);
      setActiveOffset(false);
      setRangeOffset([{}]);
    }
  }, [numTotRegFilter, LIMITPAGESIZE]);

  useEffect(() => {
    let ignore = false;
  
    const fetchnumTotReg = async () => {
      setIsLoading(true);
      try {
        const getData = await helpHttp().get(ENDPOINT, `country/getinformationtablecountry`, token);
        if (getData.err || getData.message === "Load failed") {
          setIsLoadingError(true);
          setData(initialState);
          dispatch(warningErrorAction(t(getData.message === "Load failed" ? 'Text_ConnectionFailied' : 'Text_InvalidCredentials')));
        } else if (!ignore) {
          setNumTotReg(getData);
        }
      } catch (error) {
        setIsLoadingError(true);
        setData(initialState);
        dispatch(warningErrorAction(t('Text_IncorrectData')));
        setIsLoading(false);
      } finally {
        setIsLoading(false);
      }
    };
  
    const fetchnumTotRegFilter = async () => {
      setIsLoading(true);
      try {
        const getData = await helpHttp().post(ENDPOINT, `country/getcountfiltercountries`, token, applyFilters);
        if (getData.err || getData.message === "Load failed") {
          setData(initialState);
          dispatch(warningErrorAction(t(getData.message === "Load failed" ? 'Text_ConnectionFailied' : 'Text_InvalidCredentials')));
        } else if (!ignore) {
          setNumTotRegFilter(getData);
        }
      } catch (error) {
        setData(initialState);
        dispatch(warningErrorAction(t('Text_IncorrectData')));
        setIsLoading(false);
      } finally {
        setIsLoading(false);
      }
    };
  
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const getData = await helpHttp().get3(ENDPOINT, `country/countries`, token, currencyOffset, LIMITPAGESIZE, applyFilters);
        if (getData.err || getData.message === "Load failed") {
          setIsLoadingError(true);
          setData(initialState);
          dispatch(warningErrorAction(t(getData.message === "Load failed" ? 'Text_ConnectionFailied' : 'Text_InvalidCredentials')));
        } else if (!ignore) {
          setData(getData);
        }
      } catch (error) {
        setIsLoadingError(true);
        setData(initialState);
        dispatch(warningErrorAction(t('Text_IncorrectData')));
        setIsLoading(false);
      } finally {
        setIsLoading(false);
      }
    };
  
    if (showCountrySettings) {
      fetchnumTotReg();
      fetchnumTotRegFilter();
      fetchData();
      return () => { ignore = true };
    }
  }, [ENDPOINT, t, token, dispatch, openCreateData, openDeleteRow, openEditRow, rowEdit, showCountrySettings, LIMITPAGESIZE, applyFilters, currencyOffset]);
 
  return (
    <div> 
      {showCountrySettings && ( 
        <div className="container-fluid">
            {/* Search input */}
            <div id="myDIV">
            <h1 className="h1NewStyle mb-2">
            {isLoadingError?<i className="fas fa-solid fa-exclamation fa-2x text-danger"></i>:isLoading?<CircularProgress color={openDeleteRow?"warning":"primary"}/>:<i className="fas fa-solid fa-flag text-warning"></i>} {t('Text_Country_Config')}</h1>
            {roles[0].listmenus.find((key)=>(key.name==='SubMenu_Country_Create'))?
                <div>
                <button type="button" className="buttonCreate" onClick={openCreateOrEditData}>{t('Button_Create')}</button><> </>
                {isEmployee ?
                  <button type="button" className="buttonBack" onClick={closeModalCountryTypeSettings}>{t('Button_Exit')}</button>:
                  <button type="button" className="buttonBack" onClick={closeModalCountryTypeSettings}>{t('Button_Back')}</button>
                }

              </div>:
              <div>
                { isEmployee ?
                  <button type="button" className="buttonBack" onClick={closeModalCountryTypeSettings}>{t('Button_Exit')}</button>:
                  <button type="button" className="buttonBack" onClick={closeModalCountryTypeSettings}>{t('Button_Back')}</button>
                }
              </div>
            }
          </div>  
          <br/>
          <div className="searchStyle">
            <TextField
              id="outlined-basic"
              variant="outlined"
              value={inputText}
              placeholder={t('Text_Country_Search')}
              onChange={inputHandler}
              fullWidth
              label={t('Text_Country_Search')}>
            </TextField>
          </div>
          <br/>
          <div id="myDIV">
            <h1 className="h6NewStyle mb-2">
            {t('Text_Filter')}&nbsp;
                <button type="button" className="buttonFilter" onClick={openEditFilters}><i className="fas fa-solid fa-circle" style={{color:(applyFilters.idFrom!=="" || applyFilters.idTo!=="")?"#32CD32":"#FFFFFF"}}></i> {t('Text_Code')}</button><> </>
                <button type="button" className="buttonFilter" onClick={openEditFilters}><i className="fas fa-solid fa-circle" style={{color:(applyFilters.nameesFrom!=="" || applyFilters.nameesTo!=="")?"#32CD32":"#FFFFFF"}}></i> {t('Text_NameEs')}</button><> </>
                <button type="button" className="buttonFilter" onClick={openEditFilters}><i className="fas fa-solid fa-circle" style={{color:(applyFilters.nameenFrom!=="" || applyFilters.nameenTo!=="")?"#32CD32":"#FFFFFF"}}></i> {t('Text_NameEn')}</button><> </>
                <button type="button" className="buttonFilter" onClick={openEditFilters}><i className="fas fa-solid fa-circle" style={{color:(applyFilters.code_iso2From!=="" || applyFilters.code_iso2To!=="")?"#32CD32":"#FFFFFF"}}></i> {t('Text_NameCodeIso2')}</button><> </>
                <button type="button" className="buttonFilter" onClick={openEditFilters}><i className="fas fa-solid fa-circle" style={{color:(applyFilters.code_iso3From!=="" || applyFilters.code_iso3To!=="")?"#32CD32":"#FFFFFF"}}></i> {t('Text_NameCodeIso3')}</button><> </>
                <button type="button" className="buttonFilter" onClick={openEditFilters}><i className="fas fa-solid fa-circle" style={{color:(applyFilters.extphoneFrom!=="" || applyFilters.extphoneTo!=="")?"#32CD32":"#FFFFFF"}}></i> {t('Text_Extphone')}</button><> </>
                <button type="button" className="buttonFilter" onClick={openEditFilters}><i className="fas fa-solid fa-circle" style={{color:(applyFilters.extinternetFrom!=="" || applyFilters.extinternetTo!=="")?"#32CD32":"#FFFFFF"}}></i> {t('Text_Extinternet')}</button><> </>
                <button type="button" className="buttonFilter" onClick={openEditFilters}><i className="fas fa-solid fa-circle" style={{color:(applyFilters.isEnabled)?"#32CD32":"#FFFFFF"}}></i> {t('Text_Enabled')}</button><> </>
                <button type="button" className="buttonFilter" onClick={openEditFilters}><i className="fas fa-solid fa-circle" style={{color:(applyFilters.isNotEnabled)?"#32CD32":"#FFFFFF"}}></i> {t('Text_Not_Enabled')}</button><> </>
              </h1>
          </div>
          <br/>
          <div className="card shadow mb-4">    
            <div className="card-body">
              <TableSorting
                data={filteredData}
                headCells={headCells}
                numTotRegFilter={numTotRegFilter}
                titleTable={t('Text_Country_Table')}
                setOpenDeleteRow={setOpenDeleteRow}
                setOpenEditRow={setOpenEditRow}
                setRowEdit={setRowEdit}
                showEditButton={roles[0].listmenus.find((key)=>(key.name==='SubMenu_Country_Edit'))?true:false}
                showDeleteButton={roles[0].listmenus.find((key)=>(key.name==='SubMenu_Country_Delete'))?true:false}
                typeTable={6}
                setOpenCreateProvince={setOpenCreateProvince}
                showVisibilityButton={roles[0].listmenus.find((key)=>(key.name==='SubMenu_Settings_Provinces'))?true:false}
                numTotReg={numTotReg}
                activeOffset={activeOffset}
                rangeOffset={rangeOffset}
                currencyOffset={currencyOffset}
                setCurrencyOffset={setCurrencyOffset}
                minvalue={Number(NON_ERASABLE_COUNTRY_ID)}>
              </TableSorting>
            </div>             
          </div>
          {openEditFilter &&
            <div>
              <SwipeableDrawer
                anchor='right'
                open={true}
                onClose={toggleDrawerFilterEdit(false)}
                onOpen={toggleDrawerFilterEdit(true)}>
                <Container >
                  <Box sx={{ bgcolor: '#ffffff',height:'100vh', width: '500px' }}>
                    <br/>
                    <div id="myDIV">
                      <h1 className="h1NewStyle px-4 mb-2">{t('Text_Filter')}</h1>
                    </div>
                    <hr className="sidebar-divider d-none d-md-block"/>
                    <form className="createfilter" onSubmit={handleCancelFilters}>  
                      <div className="form-floating mb-3 px-4 primary">
                        <label className="text-primary">{t('Text_Filter_Code_From')} :</label>
                        <div className="form-group">
                          <TextField
                            id="idFrom" 
                            name="idFrom"
                            value={filters.idFrom}
                            inputProps={{ style: {width: 450}, maxLength: 15 }}
                            label={errorsFilters.idFrom!=null?t(errorsFilters.idFrom):t('PlaceHolder_Filter_Code_From')}
                            placeholder={t('PlaceHolder_Filter_Code_From')}
                            variant="standard"
                            onBlur={handleBlurFilterEdit}
                            onChange={handleChangeFilterEdit}/>
                        </div>
                        <label className="text-primary">{t('Text_Filter_Code_To')} :</label>
                        <div className="form-group">
                          <TextField
                            id="idTo" 
                            name="idTo"
                            value={filters.idTo}
                            inputProps={{ style: {width: 450}, maxLength: 15 }}
                            label={errorsFilters.idTo!=null?t(errorsFilters.idTo):t('PlaceHolder_Filter_Code_to')}
                            placeholder={t('PlaceHolder_Filter_Code_to')}
                            variant="standard"
                            onBlur={handleBlurFilterEdit}
                            onChange={handleChangeFilterEdit}/>
                        </div>

                        <label className="text-primary">{t('Text_Filter_NameEs_From')} :</label>
                        <div className="form-group">
                          <TextField
                            id="nameesFrom" 
                            name="nameesFrom"
                            value={filters.nameesFrom}
                            inputProps={{ style: {width: 450}, maxLength: 100 }}
                            label={errorsFilters.nameesFrom!=null?t(errorsFilters.nameesFrom):t('PlaceHolder_Filter_NameEs_From')}
                            placeholder={t('PlaceHolder_Filter_NameEs_From')}
                            variant="standard"
                            onBlur={handleBlurFilterEdit}
                            onChange={handleChangeFilterEdit}/>
                        </div>
                        <label className="text-primary">{t('Text_Filter_NameEs_To')} :</label>
                        <div className="form-group">
                          <TextField
                            id="nameesTo" 
                            name="nameesTo"
                            value={filters.nameesTo}
                            inputProps={{ style: {width: 450}, maxLength: 100 }}
                            label={errorsFilters.nameesTo!=null?t(errorsFilters.nameesTo):t('PlaceHolder_Filter_NameEs_to')}
                            placeholder={t('PlaceHolder_Filter_NameEs_to')}
                            variant="standard"
                            onBlur={handleBlurFilterEdit}
                            onChange={handleChangeFilterEdit}/>
                        </div>

                        <label className="text-primary">{t('Text_Filter_NameEn_From')} :</label>
                        <div className="form-group">
                          <TextField
                            id="nameenFrom" 
                            name="nameenFrom"
                            value={filters.nameenFrom}
                            inputProps={{ style: {width: 450}, maxLength: 100 }}
                            label={errorsFilters.nameenFrom!=null?t(errorsFilters.nameenFrom):t('PlaceHolder_Filter_NameEn_From')}
                            placeholder={t('PlaceHolder_Filter_NameEn_From')}
                            variant="standard"
                            onBlur={handleBlurFilterEdit}
                            onChange={handleChangeFilterEdit}/>
                        </div>
                        <label className="text-primary">{t('Text_Filter_NameEn_To')} :</label>
                        <div className="form-group">
                          <TextField
                            id="nameenTo" 
                            name="nameenTo"
                            value={filters.nameenTo}
                            inputProps={{ style: {width: 450}, maxLength: 100 }}
                            label={errorsFilters.nameenTo!=null?t(errorsFilters.nameenTo):t('PlaceHolder_Filter_NameEn_to')}
                            placeholder={t('PlaceHolder_Filter_NameEn_to')}
                            variant="standard"
                            onBlur={handleBlurFilterEdit}
                            onChange={handleChangeFilterEdit}/>
                        </div>
                        <label className="text-primary">{t('Text_Filter_Code_iso2_From')} :</label>
                        <div className="form-group">
                          <TextField
                            id="code_iso2From" 
                            name="code_iso2From"
                            value={filters.code_iso2From}
                            inputProps={{ style: {width: 450}, maxLength: 10 }}
                            label={errorsFilters.code_iso2From!=null?t(errorsFilters.code_iso2From):t('PlaceHolder_Filter_Code_iso2_From')}
                            placeholder={t('PlaceHolder_Filter_Code_iso2_From')}
                            variant="standard"
                            onBlur={handleBlurFilterEdit}
                            onChange={handleChangeFilterEdit}/>
                        </div>
                        <label className="text-primary">{t('Text_Filter_Code_iso2_To')} :</label>
                        <div className="form-group">
                          <TextField
                            id="code_iso2To" 
                            name="code_iso2To"
                            value={filters.code_iso2To}
                            inputProps={{ style: {width: 450}, maxLength: 10 }}
                            label={errorsFilters.code_iso2To!=null?t(errorsFilters.code_iso2To):t('PlaceHolder_Filter_Code_iso2_to')}
                            placeholder={t('PlaceHolder_Filter_Code_iso2_to')}
                            variant="standard"
                            onBlur={handleBlurFilterEdit}
                            onChange={handleChangeFilterEdit}/>
                        </div>
                        <label className="text-primary">{t('Text_Filter_Code_iso3_From')} :</label>
                        <div className="form-group">
                          <TextField
                            id="code_iso3From" 
                            name="code_iso3From"
                            value={filters.code_iso3From}
                            inputProps={{ style: {width: 450}, maxLength: 10 }}
                            label={errorsFilters.code_iso3From!=null?t(errorsFilters.code_iso3From):t('PlaceHolder_Filter_Code_iso3_From')}
                            placeholder={t('PlaceHolder_Filter_Code_iso3_From')}
                            variant="standard"
                            onBlur={handleBlurFilterEdit}
                            onChange={handleChangeFilterEdit}/>
                        </div>
                        <label className="text-primary">{t('Text_Filter_Code_iso3_To')} :</label>
                        <div className="form-group">
                          <TextField
                            id="code_iso3To" 
                            name="code_iso3To"
                            value={filters.code_iso3To}
                            inputProps={{ style: {width: 450}, maxLength: 10 }}
                            label={errorsFilters.code_iso3To!=null?t(errorsFilters.code_iso3To):t('PlaceHolder_Filter_Code_iso3_to')}
                            placeholder={t('PlaceHolder_Filter_Code_iso3_to')}
                            variant="standard"
                            onBlur={handleBlurFilterEdit}
                            onChange={handleChangeFilterEdit}/>
                        </div>
                        <label className="text-primary">{t('Text_Filter_Extphone_From')} :</label>
                        <div className="form-group">
                          <TextField
                            id="extphoneFrom" 
                            name="extphoneFrom"
                            value={filters.extphoneFrom}
                            inputProps={{ style: {width: 450}, maxLength: 10 }}
                            label={errorsFilters.extphoneFrom!=null?t(errorsFilters.extphoneFrom):t('PlaceHolder_Filter_Extphone_From')}
                            placeholder={t('PlaceHolder_Filter_Extphone_From')}
                            variant="standard"
                            onBlur={handleBlurFilterEdit}
                            onChange={handleChangeFilterEdit}/>
                        </div>
                        <label className="text-primary">{t('Text_Filter_Extphone_To')} :</label>
                        <div className="form-group">
                          <TextField
                            id="extphoneTo" 
                            name="extphoneTo"
                            value={filters.extphoneTo}
                            inputProps={{ style: {width: 450}, maxLength: 10 }}
                            label={errorsFilters.extphoneTo!=null?t(errorsFilters.extphoneTo):t('PlaceHolder_Filter_Extphone_to')}
                            placeholder={t('PlaceHolder_Filter_Extphone_to')}
                            variant="standard"
                            onBlur={handleBlurFilterEdit}
                            onChange={handleChangeFilterEdit}/>
                        </div>
                        <label className="text-primary">{t('Text_Filter_Extinternet_From')} :</label>
                        <div className="form-group">
                          <TextField
                            id="extinternetFrom" 
                            name="extinternetFrom"
                            value={filters.extinternetFrom}
                            inputProps={{ style: {width: 450}, maxLength: 20 }}
                            label={errorsFilters.extinternetFrom!=null?t(errorsFilters.extinternetFrom):t('PlaceHolder_Filter_Extinternet_From')}
                            placeholder={t('PlaceHolder_Filter_Extinternet_From')}
                            variant="standard"
                            onBlur={handleBlurFilterEdit}
                            onChange={handleChangeFilterEdit}/>
                        </div>
                        <label className="text-primary">{t('Text_Filter_Extinternet_To')} :</label>
                        <div className="form-group">
                          <TextField
                            id="extinternetTo" 
                            name="extinternetTo"
                            value={filters.extinternetTo}
                            inputProps={{ style: {width: 450}, maxLength: 20 }}
                            label={errorsFilters.extinternetTo!=null?t(errorsFilters.extinternetTo):t('PlaceHolder_Filter_Extinternet_to')}
                            placeholder={t('PlaceHolder_Filter_Extinternet_to')}
                            variant="standard"
                            onBlur={handleBlurFilterEdit}
                            onChange={handleChangeFilterEdit}/>
                        </div>
                        <label className="text-primary">{t('Text_Enabled')} :</label>
                        <div className="form-group">
                          <Checkbox
                            id="isEnabled" 
                            name="isEnabled"
                            checked={filters.isEnabled}
                            onChange={handleChangeEnabledFilter}
                            variant="standard"/>
                        </div> 
                        <label className="text-primary">{t('Text_Not_Enabled')} :</label>
                        <div className="form-group">
                          <Checkbox
                            id="isNotEnabled" 
                            name="isNotEnabled"
                            checked={filters.isNotEnabled}
                            onChange={handleChangeNotEnabledFilter}
                            variant="standard"/>
                        </div> 
                        <hr className="sidebar-divider d-none d-md-block"/>
                        <div className="btn-group d-grid d-md-flex justify-content-md-end" role="group">
                          <button className="btn btn-primary btn-lg " type="button" onClick={handleSubmitFilterEdit}>{t('Button_Apply')}</button><> </>
                          <button className="btn btn-outline-secondary btn-lg" type="button" onClick={handleDeleteFilters}>{t('Button_Cancel_Filters')}</button>
                        </div>
                      </div>
                    </form>
                  </Box>
                </Container>
              </SwipeableDrawer>
            </div>
          }
          {(openCreateData || openEditRow) && 
            <div>
              <SwipeableDrawer
                anchor='right'
                open={true}
                onClose={toggleDrawerDataEdit(false)}
                onOpen={toggleDrawerDataEdit(true)}>
                <Container >
                  <Box sx={{ bgcolor: '#ffffff',height:'100vh', width: '500px' }}>
                    <br/>
                    <div id="myDIV">
                      <h1 className="h1NewStyle px-4 mb-2">{openEditRow?t('Text_Country_Edit'):t('Text_Country_Create')}</h1>
                    </div>  
                    <hr className="sidebar-divider d-none d-md-block"/>
                    <form className="createcountry" onSubmit={handleSubmitDataEdit}>  
                      <div className="form-floating mb-3 px-4 primary">
                        <label className="text-primary">{t('Text_Code')} :</label>
                        <div className="form-group">
                          <TextField
                            id="id" 
                            name="id"
                            value={form.id}
                            inputProps={{ style: {width: 200} }}
                            label={t('PlaceHolder_Country_Code')}
                            placeholder={t('PlaceHolder_Country_Code')}
                            variant="standard"
                            disabled/>
                        </div>
                        <label className="text-primary">{t('Text_NameEs')} :</label>
                        <div className="form-group">
                          <TextField
                            id="namees" 
                            name="namees"
                            value={form.namees}
                            inputProps={{ style: {width: 450}, maxLength: 100 }}
                            label={errors.namees!=null?t(errors.namees):t('PlaceHolder_Country_NameEs')}
                            placeholder={t('PlaceHolder_Country_NameEs')}
                            variant="standard"
                            onBlur={handleBlurDataEdit}
                            onChange={handleChangeDataEdit}/>
                        </div>
                        <label className="text-primary">{t('Text_NameEn')} :</label>
                        <div className="form-group">
                          <TextField
                            id="nameen" 
                            name="nameen"
                            value={form.nameen}
                            inputProps={{ style: {width: 450}, maxLength: 100  }}
                            label={errors.nameen!=null?t(errors.nameen):t('PlaceHolder_Country_NameEn')}
                            placeholder={t('PlaceHolder_Country_NameEn')}
                            variant="standard"
                            onBlur={handleBlurDataEdit}
                            onChange={handleChangeDataEdit}/>
                        </div>
                        <label className="text-primary">{t('Text_NameCodeIso2')} :</label>
                        <div className="form-group">
                          <TextField
                            id="code_iso2" 
                            name="code_iso2"
                            value={form.code_iso2}
                            inputProps={{ style: {width: 450}, maxLength: 10 }}
                            label={errors.code_iso2!=null?t(errors.code_iso2):t('PlaceHolder_Country_Code_Iso2')}
                            placeholder={t('PlaceHolder_Country_Code_Iso2')}
                            variant="standard"
                            onBlur={handleBlurDataEdit}
                            onChange={handleChangeDataEdit}/>
                        </div>
                        <label className="text-primary">{t('Text_NameCodeIso3')} :</label>
                        <div className="form-group">
                          <TextField
                            id="code_iso3" 
                            name="code_iso3"
                            value={form.code_iso3}
                            inputProps={{ style: {width: 450}, maxLength: 10 }}
                            label={errors.code_iso3!=null?t(errors.code_iso3):t('PlaceHolder_Country_Code_Iso3')}
                            placeholder={t('PlaceHolder_Country_Code_Iso3')}
                            variant="standard"
                            onBlur={handleBlurDataEdit}
                            onChange={handleChangeDataEdit}/>
                        </div>
                        <label className="text-primary">{t('Text_Extphone')} :</label>
                        <div className="form-group">
                          <TextField
                            id="extphone" 
                            name="extphone"
                            value={form.extphone}
                            inputProps={{ style: {width: 450}, maxLength: 10 }}
                            label={errors.extphone!=null?t(errors.extphone):t('PlaceHolder_Country_Ext_Phone')}
                            placeholder={t('PlaceHolder_Country_Ext_Phone')}
                            variant="standard"
                            onBlur={handleBlurDataEdit}
                            onChange={handleChangeDataEdit}/>
                        </div>
                        <label className="text-primary">{t('Text_Extinternet')} :</label>
                        <div className="form-group">
                          <TextField
                            id="extinternet" 
                            name="extinternet"
                            value={form.extinternet}
                            inputProps={{ style: {width: 450}, maxLength: 20 }}
                            label={errors.extinternet!=null?t(errors.extinternet):t('PlaceHolder_Country_Ext_Internet')}
                            placeholder={t('PlaceHolder_Country_Ext_Internet')}
                            variant="standard"
                            onBlur={handleBlurDataEdit}
                            onChange={handleChangeDataEdit}/>
                        </div>
                        <label className="text-primary">{t('Text_Enabled')} :</label>
                        <div className="form-group">
                          <Checkbox
                            id="enabled" 
                            name="enabled"
                            checked={form.enabled}
                            onChange={handleChangeEnabled}
                            variant="standard"/>
                        </div> 
                       
                        <br/> 
                        <hr className="sidebar-divider d-none d-md-block"/>
                        <div className="btn-group d-grid d-md-flex justify-content-md-end" role="group">
                          <button className="btn btn-primary btn-lg " type="button" onClick={handleSubmitDataEdit}>{openEditRow?t('Button_Update'):t('Button_Create')}</button><> </>
                          <button className="btn btn-outline-secondary btn-lg" type="button" onClick={handleCloseDataEdit}>{t('Button_Cancel')}</button>
                        </div>
                      </div>
                    </form>
                  </Box>
                </Container>
              </SwipeableDrawer>
            </div>
          } 
          <ProvinceSettings 
            openCreateProvince={openCreateProvince}
            setOpenCreateProvince={setOpenCreateProvince} 
            rowEditCountry={rowEdit}
          />
          <Dialog open={openDeleteRow} onClose={handleCancelDeleteRow}>
            <DialogTitle style={{color:"#e74a3b"}}>{t('Text_Delete')} {rowEdit.id}  - {rowEdit.namees} - {rowEdit.nameen}</DialogTitle>
            <DialogContent>
              <DialogContentText>
                {t('Text_Line1')}<br/>
                {t('Text_Line2')}
              </DialogContentText>
              <TextField
                autoFocus
                margin="dense"
                id="idCode"
                label={t('Text_Label')}
                type="text"
                fullWidth
                variant="standard"
                error
                defaultValue={idCodeDelete}
                onChange={handleChangeDeleteRow}/>
            </DialogContent>
            <DialogActions>
              <Button style={{color:"#e74a3b",fontSize: 16 }} onClick={handleCancelDeleteRow}>{t('Button_Cancel')}</Button>
              <Button variant="contained" style={{color:"#ffffff", background:"#e74a3b",fontSize: 16}} onClick={handleCloseDeleteRow}>{t('Button_Delete')}</Button>
            </DialogActions>
          </Dialog>
          {/* End Content Row */}
        </div>
        //* End Page Content *//
      ) }
    </div>
  )
}

CountrySettings.propTypes = {
  showCountrySettings: PropTypes.bool.isRequired,
  setShowModalCountrySettings: PropTypes.func.isRequired,
  setIndexShowSettings: PropTypes.func.isRequired,
  setShowModalGeneralSettings: PropTypes.func.isRequired,
  setShowModalEmployeeSettings: PropTypes.func.isRequired,
  isEmployee: PropTypes.bool.isRequired, 
  setShowModalStoreSettings: PropTypes.func.isRequired, 
  isStore: PropTypes.bool.isRequired, 
  setShowModalFormSettings: PropTypes.func.isRequired, 
  isForm: PropTypes.bool.isRequired, 
  setShowModalTicketsSettings: PropTypes.func.isRequired,
  isWweigVehicles: PropTypes.bool.isRequired,
  setShowModalContractSettings: PropTypes.func.isRequired, 
  isContract: PropTypes.bool.isRequired,
  setShowModalPurchasesDeliverySettings: PropTypes.func.isRequired,
  isPurchasesDelivery: PropTypes.bool.isRequired,
  setShowModalShippingsSettings: PropTypes.func.isRequired,
  isShipping: PropTypes.bool.isRequired

};
  
export default CountrySettings;
