import { useEffect } from 'react';
import { useTranslation } from "react-i18next";
import jsPDF from 'jspdf';
import 'jspdf-autotable'; // Import the jsPDF autoTable plugin
import PropTypes from 'prop-types'; 

const ReportTemplateContractFirst = ({ dataForm, initialStateForm, setDataForm, setViewPDF }) => {
  const { t } = useTranslation(['listDataForms']);
  useEffect(() => {
    const generatePDF = () => {
      const doc = new jsPDF({
        format: 'a4',
        unit: 'pt',
      });
      let numPages = Math.ceil(dataForm.contractCodeLERGroupedDTO.length / 2);

      if (numPages===0){
        numPages=1;
      }
      
      for (let j = 0; j < numPages; j++) {
        // TITLE
        doc.setFont('helvetica', 'normal');
        doc.setFontSize(8);
        doc.setDrawColor(0, 0, 0);
        doc.setLineWidth(1);   
        doc.setFillColor('#DDDDDD');
        doc.rect(40, 20, 215, 65, 'FD');  
        doc.rect(215, 20, 170, 65, 'FD');  
        doc.rect(385, 20, 170, 65, 'FD');
        doc.setFont('helvetica', 'normal');
        doc.setFontSize(8);
        doc.setTextColor('#E74A3B');
        doc.text('FACTURACIÓN:', 390, 30, 'left');
        doc.setFont('helvetica', 'normal');
        doc.setFontSize(8);
        doc.setTextColor('#000000');
        doc.text(`${dataForm.title_opt_tras_tradename}`, 45, 30, 'left');
        doc.text(`${dataForm.title_opt_tras_name}`, 45, 40, 'left');
        doc.text(`${dataForm.title_opt_tras_street}`, 45, 50, 'left');
        doc.text('NIMA:', 45, 60, 'left');
        doc.text(`${dataForm.title_opt_tras_nima}`, 70, 60, 'left');
        doc.text('Nº INSCRIPCIÓN:', 45, 70, 'left');
        doc.text(`${dataForm.title_opt_tras_inscription}`, 115, 70, 'left');
        doc.text(`${dataForm.title2_opt_tras_tradename}`, 390, 40, 'left');
        doc.text(`${dataForm.title2_opt_tras_documentid}`, 390, 50, 'left');
        doc.text(`${dataForm.title2_opt_tras_street_fiscal}`, 390, 60, 'left');
        doc.text(`${dataForm.title2_opt_tras_postalcode_fiscal}`, 390, 70, 'left');
        doc.text(`${dataForm.title2_opt_tras_locality_fiscal}`, 390, 80, 'left');
        doc.setFont('helvetica', 'bold');
        doc.setFontSize(10);
        doc.text('CONTRATO DE TRATAMIENTO', 300, 35, 'center');
        doc.text('DE RESIDUOS', 300, 55, 'center');
        doc.setFont('helvetica', 'normal');
        doc.setFontSize(8);
        doc.text('(SEGÚN ARTÍCULO 5 DEL RD 553/2020)', 300, 70, 'center');
        doc.setFont('helvetica', 'normal');
        doc.setFontSize(10);
        doc.setDrawColor(0, 0, 0);
        doc.setLineWidth(1);   
        doc.setFillColor('white');
        doc.rect(40, 85, 515, 13, 'FD');  
        doc.setFont('helvetica', 'bold');
        doc.setFontSize(10);
        doc.text('Número de Contrato:', 45, 95, 'left');
        doc.text('Fecha del Contrato:', 380, 95, 'left');
        doc.setFont('helvetica', 'normal');
        doc.setFontSize(10);
        doc.text(`${dataForm.number_nt}`, 155, 95, 'left');
        doc.text(`${dataForm.date_contract}`, 486, 95, 'left');
    
        // OPT TRAS
        doc.setFont('helvetica', 'normal');
        doc.setFontSize(10);
        doc.setDrawColor(0, 0, 0);
        doc.setLineWidth(1);   
        doc.setFillColor('#DDDDDD');
        doc.rect(40, 98, 515, 12, 'FD');  
        doc.setFillColor('#FFFFFF');
        doc.setLineWidth(0.5);  
        doc.rect(40, 110, 130, 10, 'FD');  
        doc.rect(170, 110, 275, 10, 'FD');  
        doc.rect(445, 110, 40, 10, 'FD'); 
        doc.rect(480, 110, 75, 10, 'FD');
        doc.rect(40, 120, 130, 10, 'FD');  
        doc.rect(40, 130, 130, 10, 'FD');  
        doc.rect(170, 130, 275, 10, 'FD');  
        doc.rect(445, 130, 40, 10, 'FD');  
        doc.rect(480, 130, 75, 10, 'FD');  
        doc.rect(40, 140, 130, 10, 'FD');  
        doc.rect(170, 140, 275, 10, 'FD');  
        doc.rect(445, 140, 40, 10, 'FD'); 
        doc.rect(480, 140, 75, 10, 'FD');  
        doc.rect(40, 150, 170, 10, 'FD');  
        doc.rect(210, 150, 345, 10, 'FD');  
        doc.rect(40, 160, 130, 10, 'FD'); 
        doc.rect(170, 160, 385, 10, 'FD'); 
        doc.rect(40, 170, 130, 10, 'FD'); 
        doc.setLineWidth(1);  
        doc.rect(40, 110, 515, 70, 'S');
        doc.setFont('helvetica', 'bold');
        doc.setFontSize(10);
        doc.text('1. DATOS DE IDENTIFICACIÓN DE LA INSTALACIÓN DE DESTINO DE LOS TRASLADOS', 45, 108, 'left');
        doc.setFont('helvetica', 'bold');
        doc.setFontSize(8);
        doc.text(`RAZÓN SOCIAL:`, 45, 118, 'left');
        doc.text(`DENOMINACIÓN DEL CENTRO:`, 45, 128, 'left');
        doc.text(`DIRECCIÓN DEL CENTRO:`, 45, 138, 'left');
        doc.text(`DIRECCIÓN FISCAL:`, 45, 148, 'left');
        doc.text(`N.º GESTOR RESIDUOS NO PELIGROSOS:`, 45, 158, 'left');
        doc.text(`OPERADOR:`, 45, 168, 'left');
        doc.text(`NIMA INSTALACIÓN:`, 45, 178, 'left');
        doc.text(`CIF:`, 447, 118, 'left');
        doc.text(`CP:`, 447, 138, 'left');
        doc.text(`CP:`, 447, 148, 'left');
        doc.setFont('helvetica', 'normal');
        doc.setFontSize(8);
        doc.text(`${dataForm.opt_tras_tradename}`, 175, 118, 'left');
        doc.text(`${dataForm.opt_tras_documentid}`, 482, 118, 'left');
        doc.text(`${dataForm.opt_tras_name}`, 175, 128, 'left');
        doc.text(`${dataForm.opt_tras_street}`, 175, 138, 'left');
        doc.text(`${dataForm.opt_tras_postalcode}`, 482, 138, 'left');
        doc.text(`${dataForm.opt_tras_street_fiscal}`, 175, 148, 'left');
        doc.text(`${dataForm.opt_tras_postalcode_fiscal}`, 482, 148, 'left');
        doc.text(`${dataForm.opt_tras_opt}`, 215, 158, 'left');
        doc.text(`${dataForm.opt_tras_inscription}`, 175, 168, 'left');
        doc.text(`${dataForm.opt_tras_nima}`, 175, 178, 'left');
    
        // ORI TRAS
        doc.setFont('helvetica', 'normal');
        doc.setFontSize(10);
        doc.setDrawColor(0, 0, 0);
        doc.setLineWidth(1);   
        doc.setFillColor('#DDDDDD');
        doc.rect(40, 180, 515, 12, 'FD');  
        doc.setFillColor('#FFFFFF');
        doc.setLineWidth(0.5);  
        doc.rect(40, 192, 130, 10, 'FD');  
        doc.rect(40, 202, 130, 10, 'FD');  
        doc.rect(40, 212, 130, 10, 'FD');  
        doc.rect(40, 222, 130, 10, 'FD');  
        doc.rect(40, 232, 170, 10, 'FD'); 
        doc.rect(300, 232, 60, 10, 'FD'); 
        doc.rect(445, 192, 50, 10, 'FD'); 
        doc.rect(445, 212, 50, 10, 'FD');  
        doc.rect(445, 222, 40, 10, 'FD'); 
        doc.rect(445, 232, 40, 10, 'FD'); 
        doc.setFillColor('#FFFFFF');//#D0F6FF
        doc.rect(170, 192, 275, 12, 'FD');  
        doc.rect(480, 192, 75, 12, 'FD');  
        doc.rect(170, 202, 385, 10, 'FD'); 
        doc.rect(170, 212, 275, 10, 'FD');  
        doc.rect(480, 212, 75, 10, 'FD');  
        doc.rect(170, 222, 275, 10, 'FD');  
        doc.rect(480, 222, 75, 10, 'FD'); 
        doc.rect(210, 232, 95, 10, 'FD');  
        doc.rect(360, 232, 85, 10, 'FD'); 
        doc.rect(480, 232, 75, 10, 'FD');  
        doc.setLineWidth(1);  
        doc.rect(40, 192, 515, 50, 'S');
        doc.setFont('helvetica', 'bold');
        doc.setFontSize(10);
        doc.text('2. DATOS DE IDENTIFICACIÓN DEL PRODUCTOR O POSEEDOR DEL RESIDUO/ORIGEN DEL TRASLADO.', 45, 190, 'left');
        doc.setFont('helvetica', 'bold');
        doc.setFontSize(8);
        doc.text(`RAZÓN SOCIAL:`, 45, 200, 'left');
        doc.text(`DENOMINACIÓN DEL CENTRO:`, 45, 210, 'left');
        doc.text(`DIRECCIÓN DEL CENTRO:`, 45, 220, 'left');
        doc.text(`DIRECCIÓN FISCAL:`, 45, 230, 'left');
        doc.text(`N.º GESTOR RESIDUOS NO PELIGROSOS:`, 45, 240, 'left');
        doc.text(`CIF:`, 447, 200, 'left');
        doc.text(`CP:`, 447, 220, 'left'); 
        doc.text(`CP:`, 447, 230, 'left');
        doc.text(`OPERADOR:`, 308, 240, 'left');
        doc.text(`NIMA:`, 447, 240, 'left');
        doc.setFont('helvetica', 'normal');
        doc.setFontSize(8);
        doc.text(`${dataForm.ori_tras_tradename}`, 175, 200, 'left');
        doc.text(`${dataForm.ori_tras_documentid}`, 482, 200, 'left');
        doc.text(`${dataForm.ori_tras_name}`, 175, 210, 'left');
        doc.text(`${dataForm.ori_tras_street}`, 175, 220, 'left');
        doc.text(`${dataForm.ori_tras_postalcode}`, 482, 220, 'left');
        doc.text(`${dataForm.ori_tras_street_fiscal}`, 175, 230, 'left');
        doc.text(`${dataForm.ori_tras_postalcode_fiscal}`, 482, 230, 'left');
        doc.text(`${dataForm.ori_tras_opt}`, 215, 240, 'left');
        doc.text(`${dataForm.ori_tras_inscription}`, 362, 240, 'left');
        doc.text(`${dataForm.ori_tras_nima}`, 482, 240, 'left');
          
        // ORI TRAS PERSONS
        doc.setFont('helvetica', 'normal');
        doc.setFontSize(10);
        doc.setDrawColor(0, 0, 0);
        doc.setLineWidth(1);   
        doc.setFillColor('#DDDDDD');
        doc.rect(40, 242, 515, 12, 'FD'); 
        doc.setFillColor('#FFFFFF');
        doc.setLineWidth(0.5);  
        doc.rect(40, 254, 50, 10, 'FD');  
        doc.rect(210, 254, 55, 10, 'FD');  
        doc.rect(437, 254, 28, 10, 'FD'); 
        doc.rect(40, 264, 50, 10, 'FD');  
        doc.rect(210, 264, 55, 10, 'FD');  
        doc.rect(365, 264, 72, 10, 'FD');  
        doc.rect(40, 274, 50, 10, 'FD');  
        doc.rect(437, 264, 118, 20, 'FD');  
        doc.setFillColor('#FFFFFF');//#D0F6FF
        doc.rect(90, 254, 120, 10, 'FD');  
        doc.rect(265, 254, 172, 10, 'FD');  
        doc.rect(465, 254, 90, 10, 'FD'); 
        doc.rect(90, 264, 120, 10, 'FD');  
        doc.rect(265, 264, 100, 10, 'FD');  
        doc.rect(90, 274, 347, 10, 'FD');  
        doc.setLineWidth(1);  
        doc.rect(40, 254, 515, 30, 'S');
        doc.setFont('helvetica', 'bold');
        doc.setFontSize(10);
        doc.text('2.1 DATOS DE LA PERSONA RESPONSABLE', 45, 252, 'left');
        doc.setFont('helvetica', 'bold');
        doc.setFontSize(8);
        doc.text(`NOMBRE:`, 45, 262, 'left');
        doc.text(`CARGO:`, 45, 272, 'left');
        doc.text(`EMAIL:`, 45, 282, 'left');
        doc.text(`APELLIDOS:`, 212, 262, 'left');
        doc.text(`TELÉFONO:`, 212, 272, 'left');
        doc.text(`NIF:`, 440, 262, 'left');
        doc.text(`FECHA Y FIRMA:`, 368, 272, 'left');
        doc.setFont('helvetica', 'normal');
        doc.setFontSize(8);
        doc.text(`${dataForm.ori_tras_contactname_firstaname}`, 92, 262, 'left');
        doc.text(`${dataForm.ori_tras_contactname_lastname}`, 267, 262, 'left');
        doc.text(`${dataForm.ori_tras_contactname_documentid}`, 469, 262, 'left');
        doc.text(`${dataForm.ori_tras_contactname_position}`, 92, 272, 'left');
        doc.text(`${dataForm.ori_tras_contactname_phone}`, 267, 272, 'left');
        doc.text(`${dataForm.ori_tras_contactname_email}`, 92, 282, 'left');

        // CHARAC
        doc.setFont('helvetica', 'normal');
        doc.setFontSize(10);
        doc.setDrawColor(0, 0, 0);
        doc.setLineWidth(1);   
        doc.setFillColor('#DDDDDD');
        doc.rect(40, 284, 515, 12, 'FD'); 
        doc.setFont('helvetica', 'bold');
        doc.setFontSize(10);
        doc.text('3. CARACTERÍSTICAS DEL RESIDUO', 45, 294, 'left');
        doc.setFillColor('#FFFFFF');
        doc.setLineWidth(0.5);  
        doc.rect(40, 296, 515, 10, 'FD');  
        doc.rect(40, 406, 275, 10, 'FD'); 
        doc.rect(40, 416, 275, 10, 'FD'); 
        doc.rect(395, 416, 90, 10, 'FD'); 
        doc.setFillColor('#FFFFFF');//#D0F6FF
        doc.rect(315, 406, 240, 10, 'FD'); 
        doc.rect(315, 416, 80, 10, 'FD'); 
        doc.rect(485, 416, 70, 10, 'FD'); 
        doc.setLineWidth(1);  
        doc.rect(40, 296, 515, 130, 'S');
        doc.setFont('helvetica', 'bold');
        doc.setFontSize(8);
        doc.text('Descripción del residuo para el que se solicita admisión, código LER:', 45, 304, 'left');
        doc.text('Cantidad estimada de residuos que se va a trasladar total obra (Kgr.):', 45, 414, 'left');
        doc.text('Periodicidad estimada de los traslados de residuos (indicar periodo):', 45, 424, 'left');
        doc.text('Duración del contrato:', 397, 424, 'left');
        doc.setFont('helvetica', 'normal');
        doc.setFontSize(6);
        let startIndex = j * 2; // Índice inicial para esta página
        let endIndex = Math.min(startIndex + 2, dataForm.contractCodeLERGroupedDTO.length); // Índice final para esta página (máximo 2 líneas de códigos LERS elementos)
        let counter=0;
        // Ordenar la lista de códigos como cadenas de texto para que se mantenga el orden lexicográfico
        for (let i = startIndex; i < endIndex; i++) {
          // Asegúrate de que los elementos estén en formato string
          let sortedCodes = dataForm.contractCodeLERGroupedDTO[i].codeler_code_List.sort((a, b) => {
              // Ordenamos los códigos numéricamente, pero tratados como cadenas.
              return a.localeCompare(b);
          });

          // Luego imprimimos los códigos ordenados
          for (let k = 0; k < sortedCodes.length; k++) { 
              doc.text(`${sortedCodes[k]}`, 45, 314 + counter, 'left');
              counter = counter + 10;
          }
        }

        doc.setFont('helvetica', 'normal');
        doc.setFontSize(8);
        doc.text(`${dataForm.amount_contract}`, 317, 414, 'left');
        doc.text(`${dataForm.periodicity_contract}`, 317, 424, 'left');
        doc.text(`${dataForm.duration_contract}`, 487, 424, 'left');

        // TREATMENT
        doc.setFont('helvetica', 'normal');
        doc.setFontSize(10);
        doc.setDrawColor(0, 0, 0);
        doc.setLineWidth(1);   
        doc.setFillColor('#DDDDDD');
        doc.rect(40, 426, 515, 24, 'FD'); 
        doc.setFont('helvetica', 'bold');
        doc.setFontSize(8);
        doc.text('4. TRATAMIENTO AL QUE SE VAN A SOMETER LOS RESIDUOS, DE CONFORMIDAD CON LOS ANEXOS II Y III DE LA LEY 7/2022, de', 45, 436, 'left');
        doc.text('8 DE ABRIL, DE RESIDUOS Y SUELOS CONTAMINADOS PARA UNA ECONOMÍA CIRCULAR.', 55, 446, 'left');
        doc.setFillColor('#FFFFFF');
        doc.setLineWidth(0.5);  
        doc.rect(40, 450, 152, 10, 'FD'); 
        doc.rect(192, 450, 175, 10, 'FD'); 
        doc.rect(366, 450, 189, 10, 'FD'); 
        doc.rect(40, 460, 152, 20, 'FD'); 
        doc.rect(192, 460, 175, 20, 'FD'); 
        doc.rect(366, 460, 189, 20, 'FD'); 
        doc.setLineWidth(1);  
        doc.rect(40, 450, 515, 80, 'S');
        doc.setFont('helvetica', 'bold');
        doc.setFontSize(8);
        doc.text('LERS', 116, 458, 'center');
        doc.text('Códigos Residuos (R/D 4 cifras)', 280, 458, 'center');
        doc.text('Códigos Residuos (R/D 2 cifras)', 461, 458, 'center');
        // FOR
        // Controlar el índice de inicio y fin para cada página 
        counter=0; 
        let printLineTreatmenttypes_code=false;
        let printLineProcesstypes_code=false;
        if ((startIndex+1)<dataForm.contractCodeLERGroupedDTO.length && dataForm.contractCodeLERGroupedDTO[startIndex].treatmenttypes_code===dataForm.contractCodeLERGroupedDTO[startIndex+1].treatmenttypes_code){
          if (dataForm.contractCodeLERGroupedDTO[startIndex].treatmenttypes_code!=="DEFAULT_TREATMENT_TYPE"){
            doc.text(`${dataForm.contractCodeLERGroupedDTO[startIndex].treatmenttypes_code}`,197, 473, 'left');
          }
          printLineTreatmenttypes_code=true;
        }
        if ((startIndex+1)<dataForm.contractCodeLERGroupedDTO.length && dataForm.contractCodeLERGroupedDTO[startIndex].processtypes_code===dataForm.contractCodeLERGroupedDTO[startIndex+1].processtypes_code){
          if (dataForm.contractCodeLERGroupedDTO[startIndex].processtypes_code!=="DEFAULT_PROCESS_TYPE"){
            doc.text(`${dataForm.contractCodeLERGroupedDTO[startIndex].processtypes_code}`,371, 473, 'left');
          }
          printLineProcesstypes_code=true;
        }
        for (let i = startIndex; i < endIndex; i++) { 
          doc.text(`${dataForm.contractCodeLERGroupedDTO[i].codeler_code}`,45, 468+counter, 'left');
          if (dataForm.contractCodeLERGroupedDTO[i].treatmenttypes_code!=="DEFAULT_TREATMENT_TYPE" && printLineTreatmenttypes_code===false){
            doc.text(`${dataForm.contractCodeLERGroupedDTO[i].treatmenttypes_code}`,197, 468+counter, 'left');
          }
          if (dataForm.contractCodeLERGroupedDTO[i].processtypes_code!=="DEFAULT_PROCESS_TYPE" && printLineProcesstypes_code===false){
            doc.text(`${dataForm.contractCodeLERGroupedDTO[i].processtypes_code}`,371, 468+counter, 'left');
          }
          counter=counter+10;
        } 
        doc.setFont('helvetica', 'normal');
        doc.setFontSize(6);
        // lista de códigos como cadenas de texto de los treatmenttypes_code_List y processtypes_code_List de los LERS asociados
        // Añadimos todos los códigos de treatmenttypes_code_List
         // Crear un array vacío para almacenar los códigos únicos
        let treatmentTypesLists = [];
        for (let i = startIndex; i < endIndex; i++) {
          for (let k = 0; k < dataForm.contractCodeLERGroupedDTO[i].treatmenttypes_code_List.length; k++) { 
            treatmentTypesLists = treatmentTypesLists.concat(dataForm.contractCodeLERGroupedDTO[i].treatmenttypes_code_List[k]); 
          }
        } 
        // Eliminamos los duplicados. 
        treatmentTypesLists = Array.from(new Set(treatmentTypesLists));
        // Ordenamos los códigos numéricamente, pero tratados como cadenas.
        treatmentTypesLists = treatmentTypesLists.sort((a, b) => {         
          return a.localeCompare(b);
        });
        // Añadimos todos los códigos de processtypes_code_List
        let processTypesLists = [];
        for (let i = startIndex; i < endIndex; i++) {
          for (let k = 0; k < dataForm.contractCodeLERGroupedDTO[i].processtypes_code_List.length; k++) { 
            processTypesLists = processTypesLists.concat(dataForm.contractCodeLERGroupedDTO[i].processtypes_code_List[k]); 
          }
        }
        // Eliminamos los duplicados. 
        processTypesLists = Array.from(new Set(processTypesLists));
        // Ordenamos los códigos numéricamente, pero tratados como cadenas.
        processTypesLists = processTypesLists.sort((a, b) => {         
          return a.localeCompare(b);
        });
        let posYAdd=0;
        let posX=45;
        let maxCode=0;
        let maxString=180;
        if (treatmentTypesLists.length+processTypesLists.length>5){
          maxString=88
        }
        // Luego imprimimos los códigos ordenados
        for (let k = 0; k < treatmentTypesLists.length; k++) { 
          if (maxCode<10){
            doc.text(`${treatmentTypesLists[k].substr(0, maxString)}`, posX, 488 + posYAdd, 'left');
          }
          posYAdd +=10;
          maxCode++;
          if (posYAdd>=50){
            posYAdd=0;
            posX=300;
          }
        } 
        for (let k = 0; k < processTypesLists.length; k++) { 
          if (maxCode<10){
            doc.text(`${processTypesLists[k].substr(0, maxString)}`, posX, 488 + posYAdd, 'left');
          }
          posYAdd +=10;
          maxCode++;
          if (posYAdd>=50){
            posYAdd=0;
            posX=300;
          }
      } 

        // DEST TRAS
        doc.setFont('helvetica', 'normal');
        doc.setFontSize(10);
        doc.setDrawColor(0, 0, 0);
        doc.setLineWidth(1);   
        doc.setFillColor('#DDDDDD');
        doc.rect(40, 530, 515, 10, 'FD');  
        doc.setFillColor('#FFFFFF');
        doc.setLineWidth(0.5);  
        doc.rect(40, 540, 75, 10, 'FD');  
        doc.rect(40, 550, 75, 10, 'FD'); 
        doc.rect(40, 560, 75, 10, 'FD'); 
        doc.rect(40, 570, 75, 10, 'FD'); 
        doc.rect(315, 540, 90, 10, 'FD');  
        doc.rect(315, 550, 50, 10, 'FD'); 
        doc.rect(315, 560, 50, 10, 'FD'); 
        doc.rect(315, 570, 50, 10, 'FD'); 
        doc.setFillColor('#FFFFFF');//#D0F6FF
        doc.rect(115, 540, 200, 10, 'FD'); 
        doc.rect(115, 550, 200, 10, 'FD'); 
        doc.rect(115, 560, 200, 10, 'FD'); 
        doc.rect(115, 570, 200, 10, 'FD'); 
        doc.rect(405, 540, 150, 10, 'FD');  
        doc.rect(365, 550, 190, 10, 'FD'); 
        doc.rect(365, 560, 190, 10, 'FD'); 
        doc.rect(365, 570, 190, 10, 'FD'); 
        doc.setLineWidth(1);  
        doc.rect(40, 540, 515, 40, 'S');
        doc.setFont('helvetica', 'bold');
        doc.setFontSize(8);
        doc.text('5. DATOS RELATIVOS AL OPERADOR DE TRASLADO (solo cumplimentar cuando el solicitante no sea el productor del residuo)', 45, 538, 'left');
        doc.text(`Razón Social:`, 45, 548, 'left');
        doc.text(`Razón Fiscal:`, 45, 558, 'left');
        doc.text(`C.I.F.:`, 45, 568, 'left');
        doc.text(`Nº Inscripción:`, 45, 578, 'left');
        doc.text(`Persona de contacto:`, 320, 548, 'left');
        doc.text(`Cargo:`, 320, 558, 'left');
        doc.text(`email:`, 320, 568, 'left');
        doc.text(`Teléfono:`, 320, 578, 'left');
        doc.setFont('helvetica', 'normal');
        doc.setFontSize(8);
  
        doc.text(`${dataForm.dest_tras_tradename}`, 117, 548, 'left');
        doc.text(`${dataForm.dest_tras_name}`, 117, 558, 'left');
        doc.text(`${dataForm.dest_tras_documentid}`, 117, 568, 'left');
        doc.text(`${dataForm.dest_tras_inscription}`, 117, 578, 'left');
        doc.text(`${dataForm.dest_tras_contactname}`, 407, 548, 'left');
        doc.text(`${dataForm.dest_tras_contactname_position}`, 367, 558, 'left');
        doc.text(`${dataForm.dest_tras_email}`, 367, 568, 'left');
        doc.text(`${dataForm.dest_tras_phone}`, 367, 578, 'left');

        // OTHER INFORMATION
        doc.setFont('helvetica', 'normal');
        doc.setFontSize(10);
        doc.setDrawColor(0, 0, 0);
        doc.setLineWidth(1);   
        doc.setFillColor('#DDDDDD');
        doc.rect(40, 580, 515, 10, 'FD'); 
        doc.rect(40, 610, 515, 10, 'FD'); 
        doc.rect(40, 640, 515, 10, 'FD'); 
        doc.setFillColor('#FFFFFF');//#D0F6FF
        doc.rect(40, 590, 515, 20, 'FD'); 
        doc.rect(40, 620, 515, 20, 'FD'); 
        doc.rect(40, 650, 515, 20, 'FD'); 
        doc.setFont('helvetica', 'bold');
        doc.setFontSize(8);
        doc.text('6. CUALQUIER OTRA INFORMACIÓN QUE SEA RELEVANTE PARA EL ADECUADO TRATAMIENTO DE LOS RESIDUOS', 45, 588, 'left');
        doc.text('7. CONDICIONES DE ACEPTACIÓN', 45, 618, 'left');
        doc.text('8. OBLIGACIÓN DE LAS PARTES EN RELACIÓN CON LA POSIBILIDAD DE RECHAZO DE LOS RESIDUOS POR PARTE DEL DEST.', 45, 648, 'left');

        doc.setFont('helvetica', 'normal');
        doc.setFontSize(7);
        doc.text(`${dataForm.other_information_contract1}`, 45, 597, 'left');
        doc.text(`${dataForm.other_information_contract2}`, 45, 607, 'left');
        doc.text(`${dataForm.conditions_contract1}`, 45, 627, 'left');
        doc.text(`${dataForm.conditions_contract2}`, 45, 637, 'left');
        doc.text(`${dataForm.obligations_contract1}`, 45, 657, 'left');
        doc.text(`${dataForm.obligations_contract2}`, 45, 667, 'left');
        doc.setFont('helvetica', 'normal');
        doc.setFontSize(10);
        doc.setDrawColor(0, 0, 0);
        doc.setLineWidth(1);   
        doc.setFillColor('#FFFFFF');
        doc.rect(40, 670, 515, 90, 'FD');  
        doc.setFontSize(8);
        doc.text('Firma y sello', 150, 690, 'center'); 
        doc.text('Firma y sello', 300, 690, 'center'); 
        doc.text('Firma y sello', 450, 690, 'center'); 
        doc.text('El productor', 150, 700, 'center'); 
        doc.text('Operador de traslado', 300, 700, 'center'); 
        doc.text('La empresa explotadora', 450, 700, 'center'); 
        if (dataForm.signature_opt_tras!==undefined && dataForm.signature_opt_tras!==""){
          doc.addImage(`${dataForm.signature_opt_tras}`, 'PNG', 130, 715, 35, 35);
        } 
        if (dataForm.signature_dest_tras!==undefined && dataForm.signature_dest_tras!==""){
          doc.addImage(`${dataForm.signature_dest_tras}`, 'PNG', 280, 715, 35, 35);
        } 
        if (dataForm.signature_ori_tras!==undefined && dataForm.signature_ori_tras!==""){
          doc.addImage(`${dataForm.signature_ori_tras}`, 'PNG', 430, 715, 35, 35);
        } 


        doc.setFontSize(6);
        doc.text('(cuando el solicitante no sea el productor del residuo)', 300, 710, 'center'); 
        doc.text(`${dataForm.title_opt_tras_tradename}`, 45, 770, 'left'); 
        doc.text(', es Responsable del tratamiento de conformidad con el GDPT y la LOPDGDD, con la finalidad de la gestión de sus datos para el tratamiento de', 170, 770, 'left'); 
        doc.text('residuos y conservar los datos no más tiempo del necesario para ello. No se comunicarán los datos a terceros. Puede ejercer los derechos de acceso, rectificación, portabilidad, supresión,', 45, 780, 'left'); 
        doc.text('limitación u oposición en :', 45, 790, 'left'); 
        doc.text(`${dataForm.title2_street_fiscal}`, 115, 790, 'left');  
        if (j<numPages-1){
          doc.addPage();
        }
      }
      // Open PDF in a new tab
      const pdfBlob = doc.output('blob');
      const url = URL.createObjectURL(pdfBlob);
      window.open(url, '_blank');
      setViewPDF(false);
      setDataForm(initialStateForm);

    };

    if (dataForm) {
      generatePDF(); 
    }
  }, [dataForm, t,setViewPDF, setDataForm, initialStateForm]);
    return null; // This component does not render anything in the DOM
};

ReportTemplateContractFirst.propTypes = {
  dataForm: PropTypes.object.isRequired, 
  setDataForm: PropTypes.func.isRequired,
  setViewPDF: PropTypes.func.isRequired
};

export default ReportTemplateContractFirst;
