import React, {  useEffect, useState,useCallback } from 'react';
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux"; 
import { useTranslation } from "react-i18next" 
import { Checkbox, TextField } from '@mui/material';
import { SwipeableDrawer } from '@mui/material';
import { Container, Box } from '@mui/material';
import { warningErrorAction } from "actions/ErrorActions";
import { helpHttp } from "service/HelpHttp";
import PropTypes from 'prop-types'; 

const initialAddress = {
  "id":"",
  "indexrowaddress":"",
  "street":"", 
  "defaultaddress": true,
  "fiscaladdress": true,
  "defaulttypeaddress":0,
  "otherdata":"",
  "roadtype_id":"",
  "country_id":1,
  "province_id":"",
  "postalcode_id":"",
  "community_id":"",
  "community_namees":"",
  "community_nameen":""
};
 

const initialTypesToSelect = [{
  "id": "",
  "namees": "",
  "nameen": ""
}];

const initialCountriesToSelect = [{
  "id" : 1,
  "namees" : "",
  "nameen" : "",
  "enabled":true
}];

const initialProvincesToSelect = [{
  "id" : "",
  "name" : "",
  "enabled" : true,
  "country_id":1
}];

const initialPostalCodesToSelect = [{
  "id":"",
  "postalcode":"",
  "name":"",
  "postalcodename":"",
  "enabled":true,
  "province_id":""
}];


const typeOfPriority = [
  {
    id: 0,
    "namees":"SIN PRIORIDAD",
    "nameen":"WITHOUT PRIORITY",
  },{
    id: 1,
    "namees":"PRIORIDAD OPERADOR DE TRASLADO",
    "nameen":"TRANSFER OPERATOR PRIORITY",
  },{
    id: 2,
    "namees":"PRIORIDAD ORIGEN DEL TRASLADO (Centro Productor)",
    "nameen":"PRIORITY ORIGIN OF THE TRANSFER (Producer Center)",
  },{
    id: 3,
    "namees":"PRIORIDAD ORIGEN DEL TRASLADO (Empresa Autorizada)",
    "nameen":"PRIORITY ORIGIN OF THE TRANSFER (Authorized Company)",
  },{
    id: 4,
    "namees":"PRIORIDAD DESTINO DEL TRASLADO (Instalación de Destino)",
    "nameen":"TRANSFER DESTINATION PRIORITY (Destination Facility)",
  },{
    id: 5,
    "namees":"PRIORIDAD DESTINO DEL TRASLADO (Empresa Autorizada)",
    "nameen":"PRIORIDAD DESTINO DEL TRASLADO (Empresa Autorizada)",
  },{
    id: 6,
    "namees":"PRIORIDAD TRANSPORTISTA",
    "nameen":"CARRIER PRIORITY",
  },{
    id: 7,
    "namees":"PRIORIDAD SRAP",
    "nameen":"SRAP PRIORITY",
  },
] 


const StoreCreateOrEditAddress = ({openEditRowAddress,setOpenEditRowAddress,openEditNewAddress,setOpenEditNewAddress,formStore,setFormStore,editRowAddress,setEditRowAddress}) => {
  const {t,i18n} = useTranslation(['listDataStores']); 
  const token = useSelector((state) => state.loginUser.token); 
  const ENDPOINT = useSelector((state) => state.variablesUser.ENDPOINT); 
  const DEFAULTCOUNTRY = useSelector((state) => state.formatDIUser.default_country); 
  const DEFAULTPROVINCE = useSelector((state) => state.formatDIUser.default_province); 
  const DEFAULTCOMMUNITY = useSelector((state) => state.formatDIUser.default_community); 
  const [newAddress,setNewAddress]= useState(initialAddress);
  const [errorsAddress, setErrorsAddress] = useState({});
  const [roadTypesToSelect,setRoadTypesToSelect]= useState(initialTypesToSelect);
  const [communityToSelect,setCommunityToSelect]= useState(initialTypesToSelect);
  const [countryToSelect,setCountryToSelect]= useState(initialCountriesToSelect);
  const [provinceToSelect,setProvinceToSelect]= useState(initialProvincesToSelect);
  const [postalCodeToSelect,setPostalCodeToSelect]= useState(initialPostalCodesToSelect);
  const dispatch = useDispatch();

  const keyPress = useCallback(
    e => {
      if (e.key === 'Escape' && openEditRowAddress) {
        setNewAddress(initialAddress);
        setErrorsAddress({});
        setRoadTypesToSelect(initialTypesToSelect);
        setCommunityToSelect(initialTypesToSelect);
        setCountryToSelect(initialCountriesToSelect);
        setProvinceToSelect(initialProvincesToSelect);
        setPostalCodeToSelect(initialPostalCodesToSelect);
        setEditRowAddress(initialAddress);
        setOpenEditNewAddress(false);
        setOpenEditRowAddress(false); 
      }
    },[
      setNewAddress,
      setErrorsAddress,
      setRoadTypesToSelect,
      setCommunityToSelect,
      setCountryToSelect,
      setProvinceToSelect,
      setPostalCodeToSelect,
      openEditRowAddress, 
      setEditRowAddress,
      setOpenEditRowAddress, 
      setOpenEditNewAddress]
  );

  const handleCloseNewAddress = () => {
    setNewAddress(initialAddress);
    setErrorsAddress({});
    setRoadTypesToSelect(initialTypesToSelect);
    setCommunityToSelect(initialTypesToSelect);
    setCountryToSelect(initialCountriesToSelect);
    setProvinceToSelect(initialProvincesToSelect);
    setPostalCodeToSelect(initialPostalCodesToSelect);
    setEditRowAddress(initialAddress);
    setOpenEditNewAddress(false);
    setOpenEditRowAddress(false);
    toggleDrawerNewAddress(false);
  };

  const  handleChangeNewAddress = (e) => {
    const { name, value } = e.target;
    setNewAddress(newAddress => ({
      ...newAddress,
      [name]: value,
    }));   
  };
  
  const handleBlurNewAddress = (e) => {
    handleChangeNewAddress(e);
    setErrorsAddress(validateFormAddress());
  };
  
  const handleChangeSelectRoadType = (event) => {
    setNewAddress(newAddress => ({
      ...newAddress,
      roadtype_id : event.target.value
      
    }));
  };

  const handleBlurSelectRoadType = (e) => {
    handleChangeSelectRoadType(e);
    setErrorsAddress(validateFormAddress());
  };

  const handleChangeSelectCommunity = (event) => {
    setNewAddress(newAddress => ({
      ...newAddress,
      community_id : event.target.value
      
    }));
  };

  const handleBlurSelectCommunity = (e) => {
    handleChangeSelectCommunity(e);
    setErrorsAddress(validateFormAddress());
  };

  const handleChangeSelectPriority = (event) => {
    setNewAddress(newAddress => ({
      ...newAddress,
      defaulttypeaddress : event.target.value
      
    }));
  };

  const handleBlurSelectPriority = (e) => {
    handleChangeSelectPriority(e);
    setErrorsAddress(validateFormAddress());
  };

  const handleChangeSelectCountry = (event) => {
    setNewAddress(newAddress => ({
        ...newAddress,
        country_id: event.target.value,
        province_id:"",
        postalcode_id:""
    })); 
  };

  const handleBlurSelectCountry = (e) => {
      handleChangeSelectCountry(e);
      setErrorsAddress(validateFormAddress());
  };

  const handleChangeSelectProvince = (event) => {
    setNewAddress(newAddress => ({
      ...newAddress,
      province_id :event.target.value,
      postalcode_id:""
    }));
  };

  const handleBlurSelectProvince = (e) => {
    handleChangeSelectProvince(e);
    setErrorsAddress(validateFormAddress());
  };

  const handleChangeSelectPostalCode = (event) => {
    setNewAddress(newAddress => ({
      ...newAddress,
      postalcode_id:event.target.value
    }));
  };

  const handleBlurSelectPostalCode = (e) => {
    handleChangeSelectPostalCode(e);
    setErrorsAddress(validateFormAddress());
  };

  const handleChangeEnabledDefaultAddress = () => {
    setNewAddress(newAddress => ({
      ...newAddress,
      defaultaddress: !newAddress.defaultaddress
    }));
  };

  const handleChangeEnabledFiscaleAddress = () => {
    setNewAddress(newAddress => ({
      ...newAddress,
      fiscaladdress: !newAddress.fiscaladdress
    }));
  };
    
  const toggleDrawerNewAddress  = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    if (event.type === 'keydown') {
      handleCloseNewAddress();
    }
  };

  const validateFormAddress = () => {
    let errorsAddress = {};
    let regexInvalidCharacters = /[\\!\\$\\%\\&\\|\\<\\>\\?\\{\\}\\~\\"\\']+/;

    if (newAddress.roadtype_id===undefined || newAddress.roadtype_id===null ||newAddress.roadtype_id==="" || newAddress.roadtype_id===0 || newAddress.roadtype_id==="0") {
      errorsAddress.roadtype_id = 'Text_RoadTypeRequired';
    }
    if (newAddress.street===""){
        errorsAddress.street = 'Text_StreetRequired';
    } else {
      if (regexInvalidCharacters.test(newAddress.street)) {
        errorsAddress.street = 'Text_TextNoValid';
      }
    } 

    if (regexInvalidCharacters.test(newAddress.otherdata)) {
      errorsAddress.otherdata = 'Text_TextNoValid';
    }

    if (newAddress.country_id===undefined || newAddress.country_id===null ||newAddress.country_id==="" || newAddress.country_id===0 || newAddress.country_id==="0") {
      errorsAddress.country_id = 'Text_CountryRequired';
    }
    if (newAddress.postalcode_id===undefined || newAddress.postalcode_id===null ||newAddress.postalcode_id==="" || newAddress.postalcode_id===0 || newAddress.postalcode_id==="0") {
      errorsAddress.postalcode_id = 'Text_PostalCodeRequired';
    }
    if (!openEditNewAddress){
      if (formStore.addresses.find(item => {return (item.street.trim().toUpperCase()=== newAddress.street.trim().toUpperCase()) })){
        errorsAddress.addresses = 'Text_Store_Address_Exist';
      }
    }else{
      if (formStore.addresses.find((item) => {return (item.indexrowaddress!==newAddress.indexrowaddress && item.street.trim().toUpperCase()=== newAddress.street.trim().toUpperCase() ) })){
        errorsAddress.addresses = 'Text_Store_Address_Exist';
      }
    }  
    return errorsAddress;
  };

  const handleSubmitNewAddress = () => {  
    setErrorsAddress(validateFormAddress());
    let errorsCheckAddress=validateFormAddress();
    if ((Object.keys(errorsCheckAddress).length === 0) 
          && newAddress.roadtype_id!==undefined && newAddress.roadtype_id!==null 
          && newAddress.roadtype_id!=='' && newAddress.roadtype_id!=='0' && newAddress.roadtype_id!==0 
          && newAddress.street!==""
          && newAddress.country_id!==undefined && newAddress.country_id!==null 
          && newAddress.country_id!=='' && newAddress.country_id!=='0' && newAddress.country_id!==0 
          && newAddress.postalcode_id!==undefined && newAddress.postalcode_id!==null 
          && newAddress.postalcode_id!=='' && newAddress.postalcode_id!=='0' && newAddress.postalcode_id!==0 
      ) {
      let defaultaddressFound=newAddress.defaultaddress;
      let fiscaladdressFound=newAddress.fiscaladdress;
      if(newAddress.community_id===undefined || newAddress.community_id===null || newAddress.community_id===""|| 
         newAddress.community_id==="0" || newAddress.community_id===0){
          newAddress.community_id= Number(DEFAULTCOMMUNITY);
      }
      let communityFound = communityToSelect.find( item => item.id === Number(newAddress.community_id));
      let roadTypeFound = roadTypesToSelect.find( item => item.id === Number(newAddress.roadtype_id));
      let postalcodeFound = postalCodeToSelect.find( item => item.id === Number(newAddress.postalcode_id));
      let priorityFound = typeOfPriority.find( item => item.id === Number(newAddress.defaulttypeaddress));

      if (newAddress.length<=1){
        defaultaddressFound=true;
        fiscaladdressFound=true;
      }

      if (roadTypeFound!==undefined && postalcodeFound!==undefined) {
        let addressToAdd={
          "id":newAddress.id,
          "indexrowaddress":openEditNewAddress?newAddress.indexrowaddress:formStore.addresses.length>0?formStore.addresses[formStore.addresses.length-1].indexrowaddress+1:1,
          "street":newAddress.street,
          "defaultaddress": defaultaddressFound,
          "fiscaladdress": fiscaladdressFound,
          "defaulttypeaddress":priorityFound.id,
          "defaulttypeaddress_namees":priorityFound.namees,
          "defaulttypeaddress_nameen":priorityFound.nameen,
          "otherdata":newAddress.otherdata,
          "roadtype_id": roadTypeFound.id,
          "roadtype_namees": roadTypeFound.namees,
          "roadtype_nameen": roadTypeFound.nameen,
          "postalcode_id":postalcodeFound.id,
          "postalcode_postalcodename":postalcodeFound.postalcodename,
          "community_id":communityFound.id,
          "community_namees":communityFound.namees,
          "community_nameen":communityFound.nameen
        }
        if (addressToAdd.defaultaddress){
          formStore.addresses.map((item)=>{
            return item.defaultaddress=false  
          })
          setFormStore(formStore => ({
            ...formStore,
          }))
        }

        if (addressToAdd.fiscaladdress){
          formStore.addresses.map((item)=>{
              return item.fiscaladdress=false  
          })
          setFormStore(formStore => ({
            ...formStore,
          }))
        }

        if (!openEditNewAddress){
          formStore.addresses.push(addressToAdd);
        }else{
          let idx=formStore.addresses.findIndex(((item) => item.indexrowaddress === newAddress.indexrowaddress));
          formStore.addresses[idx]=addressToAdd
        }
        setFormStore(formStore => ({
          ...formStore,
        }))
      }
      handleCloseNewAddress();
    }else{
      if (errorsCheckAddress.roadtype_id){
        dispatch(warningErrorAction(t(errorsCheckAddress.roadtype_id)));
      }
      if (errorsCheckAddress.street){
        dispatch(warningErrorAction(t(errorsCheckAddress.street)));
      }
      if (errorsCheckAddress.postalcode_id){
        dispatch(warningErrorAction(t(errorsCheckAddress.postalcode_id)));
      }
      if (errorsCheckAddress.addresses){
        dispatch(warningErrorAction(t(errorsCheckAddress.addresses)));
      }
    }  
  };

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event && typeof event === 'object' && event.key) {
        keyPress(event);
      }
    };
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [keyPress]);

  useEffect(() => {
    let isMounted = true;
    const fetchDataRoadType = async () => {
      try {
        const getData = i18n.language === "es"
          ? await helpHttp().get(ENDPOINT, `roadtype/roadtypesorderbynamees`, token)
          : await helpHttp().get(ENDPOINT, `roadtype/roadtypesorderbynameen`, token);
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          setRoadTypesToSelect(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };

    const fetchDataCommunity = async () => {
      try {
        const getData = i18n.language === "es"
        ? await helpHttp().get(ENDPOINT, `community/simplecommunitiesbynamees`, token)
        : await helpHttp().get(ENDPOINT, `community/simplecommunitiesbynameen`, token);
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          setCommunityToSelect(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };

    const fetchDataCountry = async () => {
      try {
        const getData = i18n.language === "es"
          ? await helpHttp().get(ENDPOINT, `country/simplecountriesbynamees`, token)
          : await helpHttp().get(ENDPOINT, `country/simplecountriesbynameen`, token);
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          setCountryToSelect(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };

    fetchDataRoadType();
    fetchDataCommunity();
    fetchDataCountry();

    return () => { isMounted = false; };

  }, [ENDPOINT, t, token, dispatch, i18n.language, openEditRowAddress, openEditNewAddress]);
 
  useEffect(() => {
    let isMounted = true;
    const fetchDataProvince = async () => {
      try {
        const getData = newAddress.country_id!==""
        ? await helpHttp().get2(ENDPOINT, `province/simpleprovincesbycountrybyname/`, token,newAddress.country_id)
        : await  helpHttp().get2(ENDPOINT,`province/simpleprovincesbycountrybyname/`,token,"null");
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          setProvinceToSelect(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };
   
    fetchDataProvince();
    return () => { isMounted = false };

  }, [ENDPOINT,t,token,dispatch,i18n,openEditRowAddress,newAddress.country_id]);

  useEffect(() => {
    let isMounted = true;
    const fetchDataPostalCode = async () => {
      try {
        const getData = newAddress.province_id!==""
        ? await helpHttp().get2(ENDPOINT, `postalcode/simplepostalcodesbyprovincebyname/`, token,newAddress.province_id)
        : await  helpHttp().get2(ENDPOINT,`postalcode/simplepostalcodesbycountry/`,token,newAddress.country_id);
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          setPostalCodeToSelect(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };
    fetchDataPostalCode();
    return () => { isMounted = false };
    
  }, [ENDPOINT,t,token,dispatch,i18n,openEditRowAddress,newAddress.country_id,newAddress.province_id]);
 
  useEffect(() => {
    let isMounted = true;
    const fetchDataPostalCode = async () => {
      try {
        const getData = await helpHttp().get2(ENDPOINT, `postalcode/getpostalcodewithprovinceandcountrybyid/`, token, editRowAddress.postalcode_id);
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          setNewAddress(newAddress => ({
            ...newAddress,
            country_id: getData.country_id !== null ? getData.country_id : Number(DEFAULTCOUNTRY),
            province: getData.province_id !== null ? getData.province_id : ""
          }));
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };
    if (openEditNewAddress && editRowAddress.postalcode_id !== "") {
      fetchDataPostalCode();
      setNewAddress(newAddress => ({
        ...newAddress,
        id: editRowAddress.id !== null ? editRowAddress.id : "",
        indexrowaddress: editRowAddress.indexrowaddress !== null ? editRowAddress.indexrowaddress : "",
        defaulttypeaddress: editRowAddress.defaulttypeaddress !== null ? editRowAddress.defaulttypeaddress : 0,
        defaulttypeaddress_namees: editRowAddress.defaulttypeaddress_namees !== null ? editRowAddress.defaulttypeaddress_namees : "",
        defaulttypeaddress_nameen: editRowAddress.defaulttypeaddress_nameen !== null ? editRowAddress.defaulttypeaddress_nameen : "",
        street: editRowAddress.street !== null ? editRowAddress.street : "",
        otherdata: editRowAddress.otherdata !== null ? editRowAddress.otherdata : "",
        defaultaddress: editRowAddress.defaultaddress !== null ? editRowAddress.defaultaddress : false,
        fiscaladdress: editRowAddress.fiscaladdress !== null ? editRowAddress.fiscaladdress : false,
        roadtype_id: editRowAddress.roadtype_id !== null ? editRowAddress.roadtype_id : "",
        postalcode_id: editRowAddress.postalcode_id !== null ? editRowAddress.postalcode_id : "",
        community_id: editRowAddress.community_id !== null ? editRowAddress.community_id : Number(DEFAULTCOMMUNITY),
        community_namees: editRowAddress.community_namees !== null ? editRowAddress.community_namees : "",
        community_nameen: editRowAddress.community_nameen !== null ? editRowAddress.community_nameen : "",
      }));
    } else {
      setNewAddress(initialAddress);
    }
    return () => {isMounted = false; };

  }, [ENDPOINT, t, token, dispatch, editRowAddress, openEditNewAddress, DEFAULTCOUNTRY, DEFAULTCOMMUNITY]);

 
  return(
    <>
      {openEditRowAddress && 
        <div>
          <SwipeableDrawer
            anchor='right'
            open={true}
            onClose={toggleDrawerNewAddress(false)}
            onOpen={toggleDrawerNewAddress(true)}>
            <Container >
              <Box sx={{ bgcolor: '#ffffff',height:'100vh', width: '500px' }}>
                <br/>
                <div id="myDIV">
                  <h1 className="h1NewStyle px-4 mb-2">{openEditNewAddress?t('Text_Address_Edit'):t('Text_Address_New')}</h1>
                </div>  
                <hr className="sidebar-divider d-none d-md-block"/>
                <form className="createGroupSection" onSubmit={handleCloseNewAddress}>  
                  <div className="form-floating mb-3 px-4 primary">
                    <label className="text-primary">{t('Text_RoadType')} :</label>
                    <div className="form-group">
                    <TextField
                      id="roadtype_id"
                      name="roadtype_id"
                      value={ (newAddress.roadtype_id === undefined || newAddress.roadtype_id ===null || newAddress.roadtype_id ===""
                            || newAddress.roadtype_id ==="0" || newAddress.roadtype_id ===0)?"":newAddress.roadtype_id}
                      inputProps={{ style: {width: 100}, maxLength: 100, }}
                      variant="outlined"
                      onBlur={handleBlurSelectRoadType}
                      onChange={handleChangeSelectRoadType}
                      helperText={errorsAddress.roadtype_id!=null?t(errorsAddress.roadtype_id):""}
                      select
                      SelectProps={{
                          native: true,
                          value: (newAddress.roadtype_id === undefined || newAddress.roadtype_id ===null || newAddress.roadtype_id ===""
                              || newAddress.roadtype_id ==="0" || newAddress.roadtype_id ===0)?"": newAddress.roadtype_id
                      }}>
                      <option key={0} value=""/>
                      {roadTypesToSelect.map((option) => (
                          <option key={option.id} value={option.id}>
                              {i18n.language==="es"?option.namees:option.nameen}
                          </option>
                      ))}
                    </TextField>  
                    </div>
                    <label className="text-primary">{t('Text_Address')} :</label>
                    <div className="form-group">
                      <TextField
                        id="street" 
                        name="street"
                        value={newAddress.street}
                        inputProps={{ style: {width: 450}, maxLength: 250 }}
                        label={errorsAddress.street!=null?t(errorsAddress.street):t('PlaceHolder_Store_Address')}
                        placeholder={t('PlaceHolder_Store_Address')}
                        variant="standard"
                        onBlur={handleBlurNewAddress}
                        onChange={handleChangeNewAddress}/>
                    </div>
                    <label className="text-primary">{t('Text_Address_Aditional')} :</label>
                    <div className="form-group">
                      <TextField
                        id="otherdata" 
                        name="otherdata"
                        value={newAddress.otherdata}
                        inputProps={{ style: {width: 450}, maxLength: 100  }}
                        label={errorsAddress.door!=null?t(errorsAddress.door):t('PlaceHolder_Store_Address_OtherData')}
                        placeholder={t('PlaceHolder_Store_Address_OtherData')}
                        variant="standard"
                        onBlur={handleBlurNewAddress}
                        onChange={handleChangeNewAddress}/>
                    </div>

                    <label className="text-primary">{t('Text_Country')} :</label>
                    <div className="form-group">
                    <TextField
                       id="country_id"
                        name="country_id"
                        value={ (newAddress.country_id === undefined || newAddress.country_id ===null || newAddress.country_id ===""
                              || newAddress.country_id ==="0" || newAddress.country_id ===0)?Number(DEFAULTCOUNTRY):newAddress.country_id}
                        inputProps={{ style: {width: 400}, maxLength: 100 }}
                        variant="outlined"
                        onBlur={handleBlurSelectCountry}
                        onChange={handleChangeSelectCountry}
                        helperText={errorsAddress.country_id!=null?t(errorsAddress.country_id):""}
                        select
                        SelectProps={{
                          native: true,
                          value: (newAddress.country_id === undefined || newAddress.country_id ===null || newAddress.country_id ===""
                               || newAddress.country_id ==="0" || newAddress.country_id ===0)?Number(DEFAULTCOUNTRY): newAddress.country_id
                        }}>
                        {countryToSelect.map((option) => (
                        <option key={option.id} value={option.id}>
                            {i18n.language==="es"?option.namees:option.nameen}
                        </option>
                        ))}
                    </TextField>
                    </div>

                    <label className="text-primary">{t('Text_Province')} :</label>
                    <div className="form-group">
                      {provinceToSelect.length===0?
                        <label className="text-danger">{t('Text_ProvinceNotExist')}</label>:
                        <TextField
                            id="province_id"
                            name="province_id"
                            value={ (newAddress.province_id === undefined || newAddress.province_id ===null || newAddress.province_id ===""
                                 || newAddress.province_id ==="0" || newAddress.province_id ===0)?Number(DEFAULTPROVINCE):newAddress.province_id}
                            inputProps={{ style: {width: 400 }, maxLength: 100 }}
                            variant="outlined"
                            onBlur={handleBlurSelectProvince}
                            onChange={handleChangeSelectProvince}
                            helperText={errorsAddress.province_id!=null?t(errorsAddress.province_id):""}
                            select
                            SelectProps={{
                                native: true,
                                value: (newAddress.province_id === undefined || newAddress.province_id ===null || newAddress.province_id ===""
                                     || newAddress.province_id ==="0" || newAddress.province_id ===0)?Number(DEFAULTPROVINCE): newAddress.province_id
                            }}>
                            <option key={0} value=""/>
                            {provinceToSelect.map((option) => (
                            <option key={option.id} value={option.id}>
                                {option.name}
                            </option>
                            ))}
                        </TextField>
                    }
                    </div>

                    <label className="text-primary">{t('Text_PostalCode')} :</label>
                    <div className="form-group">
                    {postalCodeToSelect.length===0?
                      <label className="text-danger">{t('Text_PostalCodeNotExist')}</label>:
                      <TextField
                          id="postalcode_id"
                          name="postalcode_id"
                          value={ (newAddress.postalcode_id === undefined || newAddress.postalcode_id ===null || newAddress.postalcode_id ===""
                                || newAddress.postalcode_id ==="0" || newAddress.postalcode_id ===0)?"":newAddress.postalcode_id}
                          inputProps={{ style: {width: 400 }, maxLength: 100 }}
                          variant="outlined"
                          onBlur={handleBlurSelectPostalCode}
                          onChange={handleChangeSelectPostalCode}
                          helperText={errorsAddress.postalcode_id!=null?t(errorsAddress.postalcode_id):""}
                          select
                          SelectProps={{
                            native: true,
                            value: (newAddress.postalcode_id === undefined || newAddress.postalcode_id ===null || newAddress.postalcode_id ===""
                                 || newAddress.postalcode_id ==="0" || newAddress.postalcode_id ===0)?"": newAddress.postalcode_id
                          }}>
                          <option key={0} value=""/>
                          {postalCodeToSelect.map((option) => (
                          <option key={option.id} value={option.id}>
                              {option.postalcodename}
                          </option>
                          ))}
                      </TextField>
                    }
                    </div>

                    <label className="text-primary">{t('Text_Community')} :</label>
                    <div className="form-group">
                      <TextField
                        id="community_id"
                        name="community_id"
                        value={ (newAddress.community_id === undefined || newAddress.community_id ===null || newAddress.community_id ===""
                              || newAddress.community_id ==="0" || newAddress.community_id ===0)?Number(DEFAULTCOMMUNITY):newAddress.community_id}
                        inputProps={{ style: {width: 400}, maxLength: 100, }}
                        variant="outlined"
                        onBlur={handleBlurSelectCommunity}
                        onChange={handleChangeSelectCommunity}
                        helperText={errorsAddress.community_id!=null?t(errorsAddress.community_id):""}
                        select
                        SelectProps={{
                            native: true,
                            value: (newAddress.community_id === undefined || newAddress.community_id ===null || newAddress.community_id ===""
                                || newAddress.community_id ==="0" || newAddress.community_id ===0)?Number(DEFAULTCOMMUNITY): newAddress.community_id
                        }}>
                        {communityToSelect.map((option) => (
                            <option key={option.id} value={option.id}>
                                {i18n.language==="es"?option.namees:option.nameen}
                            </option>
                        ))}
                      </TextField>  
                    </div>

                    <label className="text-primary">{t('Text_Address_Priority')} :</label>
                    <div className="form-group">
                      <TextField
                        id="defaulttypeaddress"
                        name="defaulttypeaddress"
                        value={ (newAddress.defaulttypeaddress === undefined || newAddress.defaulttypeaddress ===null || newAddress.defaulttypeaddress ===""
                              || newAddress.defaulttypeaddress ==="0" || newAddress.defaulttypeaddress ===0)?0:newAddress.defaulttypeaddress}
                        inputProps={{ style: {width: 400}, maxLength: 100, }}
                        variant="outlined"
                        onBlur={handleBlurSelectPriority}
                        onChange={handleChangeSelectPriority}
                        helperText={errorsAddress.defaulttypeaddress!=null?t(errorsAddress.defaulttypeaddress):""}
                        select
                        SelectProps={{
                            native: true,
                            value: (newAddress.defaulttypeaddress === undefined || newAddress.defaulttypeaddress ===null || newAddress.defaulttypeaddress ===""
                                || newAddress.defaulttypeaddress ==="0" || newAddress.defaulttypeaddress ===0)?0: newAddress.defaulttypeaddress
                        }}>
                        {typeOfPriority.map((option) => (
                            <option key={option.id} value={option.id}>
                                {i18n.language==="es"?option.namees:option.nameen}
                            </option>
                        ))}
                      </TextField>  
                    </div>

                    <div className="form-group">
                      <label className="text-primary">{t('Text_Address_IsDefault')} :</label>
                      <Checkbox  
                        style={{color:"#4e73df"}} 
                        checked={newAddress.defaultaddress} 
                        onChange={handleChangeEnabledDefaultAddress} 
                      />
                      <label className="text-primary">{t('Text_Address_IsFiscal')} :</label>
                      <Checkbox  style={{color:"#4e73df"}} 
                      checked={newAddress.fiscaladdress} 
                      onChange={handleChangeEnabledFiscaleAddress} />
                    </div>
                    <hr className="sidebar-divider d-none d-md-block"/>
                    <div className="btn-group d-grid d-md-flex justify-content-md-end" role="group">
                      <button className="btn btn-primary btn-lg " type="button" onClick={handleSubmitNewAddress}>{openEditNewAddress?t('Button_Update'):t('Button_Create')}</button><> </>
                      <button className="btn btn-outline-secondary btn-lg" type="button" onClick={handleCloseNewAddress}>{t('Button_Cancel')}</button>
                    </div>
                  </div>
                </form>
              </Box>
            </Container>
          </SwipeableDrawer>
        </div>
      }  
    </>
  )
}

StoreCreateOrEditAddress.propTypes = {

  openEditRowAddress: PropTypes.bool.isRequired,
  setOpenEditRowAddress: PropTypes.func.isRequired,
  openEditNewAddress: PropTypes.bool.isRequired,
  setOpenEditNewAddress: PropTypes.func.isRequired,
  formStore: PropTypes.object.isRequired,
  setFormStore: PropTypes.func.isRequired, 
  setEditRowAddress: PropTypes.func.isRequired
};

export default StoreCreateOrEditAddress;