import React, {  useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux"; 
import { useTranslation } from "react-i18next"  
import { SwipeableDrawer } from '@mui/material';
import { Container, Box } from '@mui/material';
import { TextField } from '@mui/material';
import "css/generalSettings.css";
import { warningErrorAction } from "actions/ErrorActions";
import { helpHttp } from "service/HelpHttp";
import { Loading } from "components/utils/Loading";
import CircularProgress from '@mui/material/CircularProgress';
import PropTypes from 'prop-types';
import TableSortingFormCodeLER from 'pages/forms/editOrNewFormDI/SortingTablesFormCodeLER'; 

const initialFiltersCodeLER= {
  "idFrom":"",
  "idTo":"",
  "codeFrom":"",
  "codeTo":"",
  "nameesFrom":"",
  "nameesTo":"",
  "nameenFrom":"",
  "nameenTo":"",
  "codeHexFrom":"",
  "codeHexTo":"",
  "codeCotGroupFrom":"",
	"codeCotGroupTo":"",
  "code_iso2From":"",
  "code_iso2To":"",
  "code_iso3From":"",
  "code_iso3To":"",
  "extphoneFrom":"",
  "extphoneTo":"",
  "extinternetFrom":"",
  "extinternetTo":"",
  "isEnabled":true,
  "isNotEnabled":true
};
 
const initialStateCodeLER = [{
  "id": "",
  "code":"",
  "namees": "",
  "nameen": ""
  
}];
      
const FormSearchCodeLers = ({ showModalSearchCodeLERS, setShowModalSearchCodeLERS,setFormForm}) => {
  const {t,i18n} = useTranslation(['listDataForms']);
  const token = useSelector((state) => state.loginUser.token);  
  const ENDPOINT = useSelector((state) => state.variablesUser.ENDPOINT); 
  const LIMITPAGESIZE = useSelector((state) => state.variablesUser.LIMITPAGESIZE); 
  const [dataCodeLER, setDataCodeLER] = useState(initialStateCodeLER);
  const [isLoadingErrorCodeLER, setIsLoadingErrorCodeLER] = useState(false);
  const [isLoadingCodeLER, setIsLoadingCodeLER] = useState(false);  
  const [inputTextCodeLER, setInputTextCodeLER] = useState("");
  const [applyFiltersCodeLER, setApplyFiltersCodeLER]= useState(initialFiltersCodeLER);
  const [filtersCodeLER, setFiltersCodeLER]= useState(initialFiltersCodeLER);
  const [openEditFilterCodeLER,setOpenEditFilterCodeLER]= useState(false);
  const [errorsFiltersCodeLER, setErrorsFiltersCodeLER] = useState({});
  const [numTotRegFilter,setNumTotRegFilter]= useState(0);
  const [numTotRegCodeLER,setNumTotRegCodeLER]= useState(0);
  const [activeOffsetCodeLER, setActiveOffsetCodeLER]= useState(false);
  const [currencyOffsetCodeLER, setCurrencyOffsetCodeLER] = useState(0);
  const [rangeOffsetCodeLER, setRangeOffsetCodeLER] = useState([{}]); 
  const dispatch = useDispatch(); 
   
  const handleCloseDataEditCodeLER = () => {
    setDataCodeLER(initialStateCodeLER);
    setIsLoadingErrorCodeLER(false);
    setIsLoadingCodeLER(false);
    setInputTextCodeLER("");
    setApplyFiltersCodeLER(initialFiltersCodeLER);
    setFiltersCodeLER(initialFiltersCodeLER);
    setOpenEditFilterCodeLER(false);
    setErrorsFiltersCodeLER({});
    setNumTotRegFilter(0);
    setNumTotRegCodeLER(0);
    setActiveOffsetCodeLER(false);
    setCurrencyOffsetCodeLER(0);
    setRangeOffsetCodeLER([{}]); 
    setShowModalSearchCodeLERS(false)
	};

  const toggleDrawerSearchFormCodeLERs  = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    if (event.type === 'keydown') {
      handleCloseDataEditCodeLER();
    }
  };

  let inputHandlerCodeLER = (e) => {
    var lowerCase = e.target.value.toLowerCase();
    setInputTextCodeLER(lowerCase);
  };

  const openEditFiltersCodeLER = () => {
    setFiltersCodeLER(applyFiltersCodeLER);
    setOpenEditFilterCodeLER(!openEditFilterCodeLER);
  };

  const toggleDrawerFilterEditCodeLER = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    if (event.type === 'keydown') {
      event.preventDefault();
      handleCloseDataEditCodeLER();
    }
  };

  const handleCancelFiltersCodeLER = () => {
    setErrorsFiltersCodeLER({});
    setFiltersCodeLER(initialFiltersCodeLER);
    setOpenEditFilterCodeLER(!openEditFilterCodeLER);
  };

  const handleChangeFilterEditCodeLER = (e) => {
    const { name, value } = e.target;
    setFiltersCodeLER(filtersCodeLER => ({
      ...filtersCodeLER,
      [name]: value
    }));
  };

  const handleBlurFilterEditCodeLER = (e) => {
    setErrorsFiltersCodeLER(validateFormFilterCodeLER());
    handleChangeFilterEditCodeLER(e);
  };
 
  const handleSubmitFilterEditCodeLER = () => {
    setErrorsFiltersCodeLER(validateFormFilterCodeLER());
    if (Object.keys(errorsFiltersCodeLER).length === 0) {
      setApplyFiltersCodeLER(filtersCodeLER);
      setFiltersCodeLER(initialFiltersCodeLER);
      setErrorsFiltersCodeLER({});
      setOpenEditFilterCodeLER(false);
    }  else{
      dispatch(warningErrorAction(t('Text_Error_Filter')));
    }
  }
  const handleDeleteFiltersCodeLER = () => {
    setErrorsFiltersCodeLER({});
    setFiltersCodeLER(initialFiltersCodeLER);
    setApplyFiltersCodeLER(initialFiltersCodeLER);
    setOpenEditFilterCodeLER(!openEditFilterCodeLER);
  };

  const validateFormFilterCodeLER = () => {
    let errorsFiltersCodeLER = {};
    let regexNumber = /^[0-9]+$/;
    let regexInvalidCharacters = /[\\!\\$\\%\\&\\|\\<\\>\\?\\{\\}\\~\\"\\']+/;

    if (filtersCodeLER.idFrom!=="" & !regexNumber.test(filtersCodeLER.idFrom)) {
      errorsFiltersCodeLER.idFrom = 'Text_TextNoNumber';
    }
    if (filtersCodeLER.idTo!=="" & !regexNumber.test(filtersCodeLER.idTo)) {
      errorsFiltersCodeLER.idTo = 'Text_TextNoNumber';
    }
    if (filtersCodeLER.idFrom!=="" & !errorsFiltersCodeLER.idFrom!==undefined){
      if (filtersCodeLER.idTo!=="" & !errorsFiltersCodeLER.idTo!==undefined){
        if (parseInt(filtersCodeLER.idTo)<parseInt(filtersCodeLER.idFrom)){
          errorsFiltersCodeLER.idTo = 'Text_TextGreater_Id';
        }
      }
    }
    if (regexInvalidCharacters.test(filtersCodeLER.nameesFrom)) {
      errorsFiltersCodeLER.nameesFrom = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(filtersCodeLER.nameesTo)) {
      errorsFiltersCodeLER.nameesTo = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(filtersCodeLER.nameenFrom)) {
      errorsFiltersCodeLER.nameenFrom = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(filtersCodeLER.nameenTo)) {
      errorsFiltersCodeLER.nameenTo = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(filtersCodeLER.codeFrom)) {
      errorsFiltersCodeLER.codeFrom = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(filtersCodeLER.codeTo)) {
      errorsFiltersCodeLER.codeTo = 'Text_TextNoValid';
    } 
    if (filtersCodeLER.nameesTo!=="" & filtersCodeLER.nameesTo<filtersCodeLER.nameesFrom){
      errorsFiltersCodeLER.nameesTo = 'Text_TextGreater_Namees';
    }
    if (filtersCodeLER.nameenTo!=="" & filtersCodeLER.nameenTo<filtersCodeLER.nameenFrom){
      errorsFiltersCodeLER.nameenTo = 'Text_TextGreater_Nameen';
    }
    if (filtersCodeLER.codeTo!=="" & filtersCodeLER.codeTo<filtersCodeLER.codeFrom){
      errorsFiltersCodeLER.codeTo = 'Text_TextGreater_CodeLer';
    }
    return errorsFiltersCodeLER;
  }  

  const filteredDataCodeLER = dataCodeLER.filter((el) => {
    if (inputTextCodeLER === '') {
        return el;
    }
    else {
      let dataTranslate=t(el.id+el.namees+el.nameen+el.code) ;
      return dataTranslate.toLowerCase().includes(inputTextCodeLER);
    }
  })

  useEffect(() => {
    let isMounted = true;

    const fetchnumTotRegCodeLER = async () => {
      try {
        setIsLoadingCodeLER(true);
        const getData = await helpHttp().get(ENDPOINT, `codeler/getinformationtablecodeler`, token);
        if (getData.err) {
          setDataCodeLER(initialStateCodeLER);
          dispatch(warningErrorAction(t(getData.message === "Load failed" ? 'Text_ConnectionFailied' : 'Text_InvalidCredentials')));
        } else if (isMounted) {
          setNumTotRegCodeLER(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData')));
        setIsLoadingCodeLER(false);
      }
      if (isMounted) {
        setIsLoadingCodeLER(false);
      } 
    };

    const fetchnumTotRegFilter = async () => {
      setIsLoadingCodeLER(true);
      try {
        const getData = await helpHttp().post(ENDPOINT, `codeler/getcountfiltercodelers`, token, applyFiltersCodeLER);
        if (getData.err || getData.message === "Load failed") {
          setDataCodeLER(initialStateCodeLER);
          dispatch(warningErrorAction(t(getData.message === "Load failed" ? 'Text_ConnectionFailied' : 'Text_InvalidCredentials')));
        } else if (isMounted) {
          setNumTotRegFilter(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData')));
        setIsLoadingCodeLER(false);
      }
      if (isMounted) {
        setIsLoadingCodeLER(false);
      }
    };

    const fetchDataCodeLER = async () => {
      setIsLoadingCodeLER(true);
      try {
        const getData = await helpHttp().get3(ENDPOINT,`codeler/codelers`,token,currencyOffsetCodeLER,LIMITPAGESIZE,applyFiltersCodeLER);
        if (getData.err || getData.message === "Load failed") {
          setDataCodeLER(initialStateCodeLER);
          dispatch(warningErrorAction(t(getData.message === "Load failed" ? 'Text_ConnectionFailied' : 'Text_InvalidCredentials')));
        } else if (isMounted) {
          setDataCodeLER(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData')));
        setIsLoadingCodeLER(false);
      }  
      if (isMounted) {
        setIsLoadingCodeLER(false);
      }
    };
    
    if (showModalSearchCodeLERS){
      fetchnumTotRegCodeLER();
      fetchnumTotRegFilter();
      fetchDataCodeLER();
    }
    return () => { isMounted = false };
  }, [ENDPOINT,t,i18n,token,dispatch,LIMITPAGESIZE,applyFiltersCodeLER,currencyOffsetCodeLER,setIsLoadingCodeLER,showModalSearchCodeLERS]);

  return(
    <>
      {showModalSearchCodeLERS && 
        <div>
          <SwipeableDrawer
            anchor='right'
            open={true}
            onClose={toggleDrawerSearchFormCodeLERs(false)}
            onOpen={toggleDrawerSearchFormCodeLERs(true)}>
            <Container >
              <Box sx={{ bgcolor: '#ffffff',height:'1300px', width: '900px'}}>
                <div id="myDIV">
                  <h1 className="h1NewStyle mb-2">
                  {isLoadingErrorCodeLER?<i className="fas fa-solid fa-exclamation fa-2x text-danger"></i>:isLoadingCodeLER?<CircularProgress color={"primary"}/>:<i className="fas fa-address-card text-warning"></i>} {t('Text_FORM_CodeLERs_Search')}</h1>
                    <div>
                      <button type="button" className="buttonCreate" onClick={handleCloseDataEditCodeLER}>{t('Button_Exit')}</button><> </>
                    </div> 
                </div>  
                <br/>
                <div className="searchStyle">
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    value={inputTextCodeLER}
                    placeholder={t('Text_FORM_CodeLER_Search')} 
                    onChange={inputHandlerCodeLER}
                    fullWidth
                    label={t('Text_FORM_CodeLER_Search')}>
                  </TextField>
                </div>
                <br/>
                <div id="myDIV">
                  <h1 className="h6NewStyle mb-2">
                    {t('Text_Filter')}&nbsp;
                      <button type="button" className="buttonFilter" onClick={openEditFiltersCodeLER}><i className="fas fa-solid fa-circle" style={{color:(applyFiltersCodeLER.idFrom!=="" || applyFiltersCodeLER.idTo!=="")?"#32CD32":"#FFFFFF"}}></i> {t('Text_Code')}</button><> </>
                      <button type="button" className="buttonFilter" onClick={openEditFiltersCodeLER}><i className="fas fa-solid fa-circle" style={{color:(applyFiltersCodeLER.codeFrom!=="" || applyFiltersCodeLER.codeTo!=="")?"#32CD32":"#FFFFFF"}}></i> {t('Text_FORM_CodeLER_Code')}</button><> </>
                      <button type="button" className="buttonFilter" onClick={openEditFiltersCodeLER}><i className="fas fa-solid fa-circle" style={{color:(applyFiltersCodeLER.nameesFrom!=="" || applyFiltersCodeLER.nameesTo!=="")?"#32CD32":"#FFFFFF"}}></i> {t('Text_FORM_CodeLER_NameEs')}</button><> </>
                      <button type="button" className="buttonFilter" onClick={openEditFiltersCodeLER}><i className="fas fa-solid fa-circle" style={{color:(applyFiltersCodeLER.nameenFrom!=="" || applyFiltersCodeLER.nameenTo!=="")?"#32CD32":"#FFFFFF"}}></i> {t('Text_FORM_CodeLER_NameEn')}</button><> </> 
                      
                  </h1>
                </div>
                <br/>
                {!isLoadingCodeLER ?
                  <>
                    <div className="card shadow mb-4">    
                      <div className="card-body">
                        <TableSortingFormCodeLER
                          dataCodeLER={filteredDataCodeLER}
                          numTotRegFilter={ numTotRegFilter}
                          titleTable={t('Text_FORM_CodeLER_Table')}
                          numTotRegCodeLER={numTotRegCodeLER}
                          activeOffsetCodeLER={activeOffsetCodeLER}
                          rangeOffsetCodeLER={rangeOffsetCodeLER}
                          currencyOffsetCodeLER={currencyOffsetCodeLER}
                          setCurrencyOffsetCodeLER={setCurrencyOffsetCodeLER}
                          setFormForm={setFormForm}
                          handleCloseDataEditCodeLER={handleCloseDataEditCodeLER}
                        >
                        </TableSortingFormCodeLER>
                      </div>             
                    </div>
                    {openEditFilterCodeLER &&
                      <div>
                      <SwipeableDrawer
                        anchor='right'
                        open={true}
                        onClose={toggleDrawerFilterEditCodeLER(false)}
                        onOpen={toggleDrawerFilterEditCodeLER(true)}>
                        <Container >
                          <Box sx={{ bgcolor: '#ffffff',height:'100vh', width: '850' }}>
                            <br/>
                              <div id="myDIV">
                                <h1 className="h1NewStyle px-4 mb-2">{t('Text_Filter')}</h1>
                              </div>
                              <hr className="sidebar-divider d-none d-md-block"/>
                              <form className="newfilterformstore" onSubmit={handleCancelFiltersCodeLER}>  
                                <div className="form-floating mb-3 px-4 primary">
                                  <div className="form-group">
                                    <TextField
                                      id="idFrom" 
                                      name="idFrom"
                                      value={filtersCodeLER.idFrom}
                                      inputProps={{ style: {width: 400}, maxLength: 15 }}
                                      label={errorsFiltersCodeLER.idFrom!=null?t(errorsFiltersCodeLER.idFrom):t('Text_Filter_Code_From')}
                                      placeholder={t('PlaceHolder_Filter_Code_From')}
                                      variant="outlined"
                                      onBlur={handleBlurFilterEditCodeLER}
                                      onChange={handleChangeFilterEditCodeLER}/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  
                                    <TextField
                                      id="idTo" 
                                      name="idTo"
                                      value={filtersCodeLER.idTo}
                                      inputProps={{ style: {width: 400}, maxLength: 15 }}
                                      label={errorsFiltersCodeLER.idTo!=null?t(errorsFiltersCodeLER.idTo):t('Text_Filter_Code_To')}
                                      placeholder={t('PlaceHolder_Filter_Code_to')}
                                      variant="outlined"
                                      onBlur={handleBlurFilterEditCodeLER}
                                      onChange={handleChangeFilterEditCodeLER}/>
                                  </div>
                                  <div className="form-group">
                                    <TextField
                                      id="codeFrom" 
                                      name="codeFrom"
                                      value={filtersCodeLER.codeFrom}
                                      inputProps={{ style: {width: 400}, maxLength: 10 }}
                                      label={errorsFiltersCodeLER.codeFrom!=null?t(errorsFiltersCodeLER.codeFrom):t('Text_Filter_CodeLer_From')}
                                      placeholder={t('PlaceHolder_Filter_CodeLer_From')}
                                      variant="outlined"
                                      onBlur={handleBlurFilterEditCodeLER}
                                      onChange={handleChangeFilterEditCodeLER}/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <TextField
                                      id="codeTo" 
                                      name="codeTo"
                                      value={filtersCodeLER.codeTo}
                                      inputProps={{ style: {width: 400}, maxLength: 10}}
                                      label={errorsFiltersCodeLER.codeTo!=null?t(errorsFiltersCodeLER.codeTo):t('Text_Filter_CodeLer_To')}
                                      placeholder={t('PlaceHolder_Filter_CodeLer_to')}
                                      variant="outlined"
                                      onBlur={handleBlurFilterEditCodeLER}
                                      onChange={handleChangeFilterEditCodeLER}/>
                                  </div>
                                  <div className="form-group">
                                    <TextField
                                      id="nameesFrom" 
                                      name="nameesFrom"
                                      value={filtersCodeLER.nameesFrom}
                                      inputProps={{ style: {width: 400}, maxLength: 100 }}
                                      label={errorsFiltersCodeLER.nameesFrom!=null?t(errorsFiltersCodeLER.nameesFrom):t('Text_Filter_NameEs_From')}
                                      placeholder={t('PlaceHolder_Filter_NameEs_From')}
                                      variant="outlined"
                                      onBlur={handleBlurFilterEditCodeLER}
                                      onChange={handleChangeFilterEditCodeLER}/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  
                                    <TextField
                                      id="nameesTo" 
                                      name="nameesTo"
                                      value={filtersCodeLER.nameesTo}
                                      inputProps={{ style: {width: 400}, maxLength: 100 }}
                                      label={errorsFiltersCodeLER.nameesTo!=null?t(errorsFiltersCodeLER.nameesTo):t('Text_Filter_NameEs_To')}
                                      placeholder={t('PlaceHolder_Filter_NameEs_to')}
                                      variant="outlined"
                                      onBlur={handleBlurFilterEditCodeLER}
                                      onChange={handleChangeFilterEditCodeLER}/>
                                  </div>
                                  <div className="form-group">
                                    <TextField
                                      id="nameenFrom" 
                                      name="nameenFrom"
                                      value={filtersCodeLER.nameenFrom}
                                      inputProps={{ style: {width: 400}, maxLength: 100 }}
                                      label={errorsFiltersCodeLER.nameenFrom!=null?t(errorsFiltersCodeLER.nameenFrom):t('Text_Filter_NameEn_From')}
                                      placeholder={t('PlaceHolder_Filter_NameEn_From')}
                                      variant="outlined"
                                      onBlur={handleBlurFilterEditCodeLER}
                                      onChange={handleChangeFilterEditCodeLER}/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  
                                    <TextField
                                      id="nameenTo" 
                                      name="nameenTo"
                                      value={filtersCodeLER.nameenTo}
                                      inputProps={{ style: {width: 400}, maxLength: 100 }}
                                      label={errorsFiltersCodeLER.nameenTo!=null?t(errorsFiltersCodeLER.nameenTo):t('Text_Filter_NameEn_To')}
                                      placeholder={t('PlaceHolder_Filter_NameEn_to')}
                                      variant="outlined"
                                      onBlur={handleBlurFilterEditCodeLER}
                                      onChange={handleChangeFilterEditCodeLER}/>
                                  </div>  
                                </div>
                                <hr className="sidebar-divider d-none d-md-block"/>
                                <div className="btn-group d-grid d-md-flex justify-content-md-end" role="group">
                                  <button className="btn btn-primary btn-lg " type="button" onClick={handleSubmitFilterEditCodeLER}>{t('Button_Apply')}</button><> </>
                                  <button className="btn btn-outline-secondary btn-lg" type="button" onClick={handleDeleteFiltersCodeLER}>{t('Button_Cancel_Filters')}</button>
                                </div>
                            </form>
                          </Box>
                        </Container>
                      </SwipeableDrawer>
                    </div>
                  }  
                  
                  {/* End Content Row */}
                  </>:
                    <Loading isLoadingData={isLoadingCodeLER}/>
                }
                
                
              </Box>
            </Container>
          </SwipeableDrawer>
        </div>
      }  
    </>
  )
}

FormSearchCodeLers.propTypes = {
  showModalSearchCodeLERS: PropTypes.bool.isRequired,
  setShowModalSearchCodeLERS: PropTypes.func.isRequired,
  setFormForm: PropTypes.func.isRequired
};

export default FormSearchCodeLers;

