import React, {useState }from 'react';
import DashboardIcon from '@mui/icons-material/Dashboard';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next" 
import { Link } from 'react-router-dom';
import { Navigate } from 'react-router-dom';  
import { useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify'; 
import { TodayIs } from 'components/utils/TodayIs';
import ImportScript from 'components/utils/ImportScript';
import Footer from "pages/main/Footer";
import Alerts from 'pages/alerts/Alerts';
import Messages from 'pages/messages/Messages'; 
import FeedIcon from '@mui/icons-material/Feed';
import TicketsDetailsFilter from "pages/ticketsDetails/TicketsDetailsFilter";

const initialFiltersTicketsDetails= {
   "idFrom":"",
    "idTo":"",
    "defaultStoreIdFrom":"",
    "defaultStoreIdTo":"",
    "useraccountIdFrom":"",
    "useraccountIdTo":"",
    "isFinished":false,
    "isNotFinished":true,
    "numberticketFrom":"",
    "numberticketTo":"",
    "start_date_ticketFrom":null,
	"start_date_ticketTo":null,
    "end_date_ticketFrom":null,
    "end_date_tickettTo":null,
    "vehiclestore_licenseplateFrom":"",
    "vehiclestore_licenseplateTo":"",
    "rel_trans_store_idFrom":"",
    "rel_trans_store_idTo":"",
    "ori_tras_store_idFrom":"",
    "ori_tras_store_idTo":"",
    "numberDIFROM":"",
    "numberDITO":"",
};

const HomeTicketsDetails = () => {
    const {t,i18n} = useTranslation(['listDataTickets']); 
    const enabled = useSelector((state) => state.loginUser.enabled);
    const roles = useSelector((state) => state.loginUser.roles);  
    const defaultStore = useSelector((state) => state.loginUser.defaultStore);  
    const [applyFiltersTicketsDetails, setApplyFiltersTicketsDetails]= useState(initialFiltersTicketsDetails);
    const [showModalTicketsDetails, setShowModalTicketsDetails] = useState(true); 
    
    const scriptUrl = '/js/sb-admin-2.min.js'; 
    const navigate = useNavigate();  
 
    const handleCloseTicketsEditData = () => {
        setApplyFiltersTicketsDetails(initialFiltersTicketsDetails);
        setShowModalTicketsDetails(false);
        navigate("/home");
        return () => {};
    };
    
    function changeSelectedLanguage(){
        i18n.language==="es"?i18n.changeLanguage("en"):i18n.changeLanguage("es");        
    };
        
    return (
    <>
        <ImportScript resourceUrl={scriptUrl} />
        <ToastContainer/>
        {enabled===false ? <Navigate to="/home"></Navigate>:
        <div id="page-top">

            {/* Page Wrapper */}
            <div id="wrapper">

                {/* Sidebar */}
                <ul className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="accordionSidebar">

                    {/* Sidebar - Brand */}
                    <Link className="sidebar-brand d-flex align-items-center justify-content-center" to="/home">
                        <div className="sidebar-brand-icon rotate-n-15">
                            <DashboardIcon/>
                        </div>
                        <div className="sidebar-brand-text mx-3">{t('Text_Title')}</div>
                    </Link>

                    {/* Divider */}
                    <hr className="sidebar-divider my-0"/>

                    {/* Nav Item - Dashboard */}
                    <li className="nav-item active">
                        <div className="nav-link">
                            <i className="fas fa-fw fa-tachometer-alt"></i>
                            <span>{t('Menu_Main2')}</span></div>
                    </li>

                    {/* Divider */}
                    <hr className="sidebar-divider"/>
                    {/* Sidebar - INFORMATION FILTROS APLICADOS */}
                    <li className="nav-item active">
                        <Link className="nav-link" to="/ticketsdetails">
                            <FeedIcon/>
                            <span> {t('Text_Information')}</span>
                        </Link>
                    </li>
                    <div className={applyFiltersTicketsDetails.idFrom===''?"sidebar-heading":"sidebar-heading text-white"}>   
                        {t('Text_Filter_Code_From')}
                    </div>
                    <li className={applyFiltersTicketsDetails.idFrom===''?"sidebar-heading text-warning":"sidebar-heading text-warning"}>
                        { applyFiltersTicketsDetails.idFrom==='' ? t('Text_Filter_Not_Apply'): applyFiltersTicketsDetails.idFrom}
                    </li>
                    <div className={applyFiltersTicketsDetails.idTo===''?"sidebar-heading":"sidebar-heading text-white"}>   
                        {t('Text_Filter_Code_To')}
                    </div>
                    <li className={applyFiltersTicketsDetails.idTo===''?"sidebar-heading text-warning":"sidebar-heading text-warning"}>
                        { applyFiltersTicketsDetails.idTo==='' ? t('Text_Filter_Not_Apply'): applyFiltersTicketsDetails.idTo}
                    </li>
                    
                    {roles[0].listmenus.find((key)=>(key.name==="SubMenu_Settings_TicketsDetails_Store_Master"))&&
                        <>
                            <div className={applyFiltersTicketsDetails.defaultStoreIdFrom===''?"sidebar-heading":"sidebar-heading text-white"}>   
                                {t('Text_Filter_Stores_From')}
                            </div>
                            <li className={applyFiltersTicketsDetails.defaultStoreIdFrom===''?"sidebar-heading text-warning":"sidebar-heading text-warning"}>
                                { applyFiltersTicketsDetails.defaultStoreIdFrom==='' ? t('Text_Filter_Not_Apply'): applyFiltersTicketsDetails.defaultStoreIdFrom}
                            </li>
                            <div className={applyFiltersTicketsDetails.defaultStoreIdTo===''?"sidebar-heading":"sidebar-heading text-white"}>   
                                {t('Text_Filter_Stores_To')}
                            </div>
                            <li className={applyFiltersTicketsDetails.defaultStoreIdTo===''?"sidebar-heading text-warning":"sidebar-heading text-warning"}>
                                { applyFiltersTicketsDetails.defaultStoreIdTo==='' ? t('Text_Filter_Not_Apply'): applyFiltersTicketsDetails.defaultStoreIdTo}
                            </li>
                        </>
                    }
                    {(roles[0].listmenus.find((key)=>(key.name==="SubMenu_Settings_TicketsDetails_Store_Master"))
                        || roles[0].listmenus.find((key)=>(key.name==="SubMenu_Settings_TicketsDetails_User_Master"))) &&
                        <>
                            <div className={applyFiltersTicketsDetails.useraccountIdFrom===''?"sidebar-heading":"sidebar-heading text-white"}> 
                                {t('Text_Filter_Useraccount_From')}
                            </div>
                            <li className={applyFiltersTicketsDetails.useraccountIdFrom===''?"sidebar-heading text-warning":"sidebar-heading text-warning"}>
                                { applyFiltersTicketsDetails.useraccountIdFrom==='' ? t('Text_Filter_Not_Apply'): applyFiltersTicketsDetails.useraccountIdFrom}
                            </li>
                            <div className={applyFiltersTicketsDetails.useraccountIdTo===''?"sidebar-heading":"sidebar-heading text-white"}> 
                                {t('Text_Filter_Useraccount_To')}
                            </div>
                            <li className={applyFiltersTicketsDetails.useraccountIdTo===''?"sidebar-heading text-warning":"sidebar-heading text-warning"}>
                                { applyFiltersTicketsDetails.useraccountIdTo==='' ? t('Text_Filter_Not_Apply'): applyFiltersTicketsDetails.useraccountIdTo}
                            </li>
                        </>
                    }
                    
                    <div className={applyFiltersTicketsDetails.numberticketFrom===''?"sidebar-heading":"sidebar-heading text-white"}>
                        {t('Text_Filter_NumberTicket_From')}
                    </div>
                    <li className={applyFiltersTicketsDetails.numberticketFrom===''?"sidebar-heading text-warning":"sidebar-heading text-warning"}>
                        { applyFiltersTicketsDetails.numberticketFrom==='' ? t('Text_Filter_Not_Apply'): applyFiltersTicketsDetails.numberticketFrom}
                    </li>
                    <div className={applyFiltersTicketsDetails.numberticketTo===''?"sidebar-heading":"sidebar-heading text-white"}>
                        {t('Text_Filter_NumberTicket_To')}
                    </div>
                    <li className={applyFiltersTicketsDetails.numberticketTo===''?"sidebar-heading text-warning":"sidebar-heading text-warning"}>
                        { applyFiltersTicketsDetails.numberticketTo==='' ? t('Text_Filter_Not_Apply'): applyFiltersTicketsDetails.numberticketTo}
                    </li>
 
                    <div className={applyFiltersTicketsDetails.start_date_ticketFrom===null?"sidebar-heading":"sidebar-heading text-white"}>
                        {t('Text_Filter_StartTicket_From')}
                    </div>
                    <li className={applyFiltersTicketsDetails.start_date_ticketFrom===null?"sidebar-heading text-warning":"sidebar-heading text-warning"}>
                        { applyFiltersTicketsDetails.start_date_ticketFrom===null ? t('Text_Filter_Not_Apply'): new Date(applyFiltersTicketsDetails.start_date_ticketFrom).toLocaleDateString(i18n.language==="es"?'es-ES':'en-US')}
                    </li>                    
                    <div className={applyFiltersTicketsDetails.start_date_ticketTo===null?"sidebar-heading":"sidebar-heading text-white"}>
                        {t('Text_Filter_StartTicket_To')}
                    </div>
                    <li className={applyFiltersTicketsDetails.start_date_ticketTo===null?"sidebar-heading text-warning":"sidebar-heading text-warning"}>
                        { applyFiltersTicketsDetails.start_date_ticketTo===null ? t('Text_Filter_Not_Apply'): new Date(applyFiltersTicketsDetails.start_date_ticketTo).toLocaleDateString(i18n.language==="es"?'es-ES':'en-US')}
                    </li>

                    <div className={applyFiltersTicketsDetails.end_date_ticketFrom===null?"sidebar-heading":"sidebar-heading text-white"}>
                        {t('Text_Filter_EndTicket_From')}
                    </div>
                    <li className={applyFiltersTicketsDetails.end_date_ticketFrom===null?"sidebar-heading text-warning":"sidebar-heading text-warning"}>
                        { applyFiltersTicketsDetails.end_date_ticketFrom===null ? t('Text_Filter_Not_Apply'): new Date(applyFiltersTicketsDetails.end_date_ticketFrom).toLocaleDateString(i18n.language==="es"?'es-ES':'en-US')}
                    </li>                    
                    <div className={applyFiltersTicketsDetails.end_date_tickettTo===null?"sidebar-heading":"sidebar-heading text-white"}>
                        {t('Text_Filter_EndTicket_To')}
                    </div>
                    <li className={applyFiltersTicketsDetails.end_date_tickettTo===null?"sidebar-heading text-warning":"sidebar-heading text-warning"}>
                        { applyFiltersTicketsDetails.end_date_tickettTo===null ? t('Text_Filter_Not_Apply'): new Date(applyFiltersTicketsDetails.end_date_tickettTo).toLocaleDateString(i18n.language==="es"?'es-ES':'en-US')}
                    </li>

                    <div className={applyFiltersTicketsDetails.vehiclestore_licenseplateFrom===''?"sidebar-heading":"sidebar-heading text-white"}>
                        {t('Text_Filter_vehicles_From')}
                    </div>
                    <li className={applyFiltersTicketsDetails.vehiclestore_licenseplateFrom===''?"sidebar-heading text-warning":"sidebar-heading text-warning"}>
                        { applyFiltersTicketsDetails.vehiclestore_licenseplateFrom==='' ? t('Text_Filter_Not_Apply'): applyFiltersTicketsDetails.vehiclestore_licenseplateFrom}
                    </li>
                    <div className={applyFiltersTicketsDetails.vehiclestore_licenseplateTo===''?"sidebar-heading":"sidebar-heading text-white"}>
                        {t('Text_Filter_vehicles_To')}
                    </div>
                    <li className={applyFiltersTicketsDetails.vehiclestore_licenseplateTo===''?"sidebar-heading text-warning":"sidebar-heading text-warning"}>
                        { applyFiltersTicketsDetails.vehiclestore_licenseplateTo==='' ? t('Text_Filter_Not_Apply'): applyFiltersTicketsDetails.vehiclestore_licenseplateTo}
                    </li>

                    <div className={applyFiltersTicketsDetails.numberDIFROM===''?"sidebar-heading":"sidebar-heading text-white"}>
                        {t('Text_Filter_FormDI_From')}
                    </div>
                    <li className={applyFiltersTicketsDetails.numberDIFROM===''?"sidebar-heading text-warning":"sidebar-heading text-warning"}>
                        { applyFiltersTicketsDetails.numberDIFROM==='' ? t('Text_Filter_Not_Apply'): applyFiltersTicketsDetails.numberDIFROM}
                    </li>
                    <div className={applyFiltersTicketsDetails.numberDITO===''?"sidebar-heading":"sidebar-heading text-white"}>
                        {t('Text_Filter_FormDI_To')}
                    </div>
                    <li className={applyFiltersTicketsDetails.numberDITO===''?"sidebar-heading text-warning":"sidebar-heading text-warning"}>
                        { applyFiltersTicketsDetails.numberDITO==='' ? t('Text_Filter_Not_Apply'): applyFiltersTicketsDetails.numberDITO}
                    </li>

                    <div className={applyFiltersTicketsDetails.rel_trans_store_idFrom===''?"sidebar-heading":"sidebar-heading text-white"}>
                        {t('Text_Filter_Trans_From')}
                    </div>
                    <li className={applyFiltersTicketsDetails.rel_trans_store_idFrom===''?"sidebar-heading text-warning":"sidebar-heading text-warning"}>
                        { applyFiltersTicketsDetails.rel_trans_store_idFrom==='' ? t('Text_Filter_Not_Apply'): applyFiltersTicketsDetails.rel_trans_store_idFrom}
                    </li>
                    <div className={applyFiltersTicketsDetails.rel_trans_store_idTo===''?"sidebar-heading":"sidebar-heading text-white"}>
                        {t('Text_Filter_Trans_To')}
                    </div>
                    <li className={applyFiltersTicketsDetails.rel_trans_store_idTo===''?"sidebar-heading text-warning":"sidebar-heading text-warning"}>
                        { applyFiltersTicketsDetails.rel_trans_store_idTo==='' ? t('Text_Filter_Not_Apply'): applyFiltersTicketsDetails.rel_trans_store_idTo}
                    </li>

                    <div className={applyFiltersTicketsDetails.ori_tras_store_idFrom===''?"sidebar-heading":"sidebar-heading text-white"}>
                        {t('Text_Filter_TradeName_From')}
                    </div>
                    <li className={applyFiltersTicketsDetails.ori_tras_store_idFrom===''?"sidebar-heading text-warning":"sidebar-heading text-warning"}>
                        { applyFiltersTicketsDetails.ori_tras_store_idFrom==='' ? t('Text_Filter_Not_Apply'): applyFiltersTicketsDetails.ori_tras_store_idFrom}
                    </li>
                    <div className={applyFiltersTicketsDetails.ori_tras_store_idTo===''?"sidebar-heading":"sidebar-heading text-white"}>
                        {t('Text_Filter_TradeName_To')}
                    </div>
                    <li className={applyFiltersTicketsDetails.ori_tras_store_idTo===''?"sidebar-heading text-warning":"sidebar-heading text-warning"}>
                        { applyFiltersTicketsDetails.ori_tras_store_idTo==='' ? t('Text_Filter_Not_Apply'): applyFiltersTicketsDetails.ori_tras_store_idTo}
                    </li>
                      

                    <li className="sidebar-heading text-warning">
                    &nbsp;
                    </li>
                    {/* Divider */}
                    <hr className="sidebar-divider"/>
                    
                    {/* Heading */}
                    {/* Nav Item - Admin Collapse Menu */}
                    {roles[0].listmenus.find((key)=>(key.name==="Menu_Settings"))&&
                        <li className="nav-item">
                            <Link className="nav-link collapsed" to={`/ticketsdetails`} data-toggle="collapse" data-target="#collapseAdmin"
                                aria-expanded="true" aria-controls="collapseAdmin">
                                <SettingsIcon/>
                                <span>{t('Menu_Settings')}</span>
                            </Link>
                            <div id="collapseAdmin" className="collapse" aria-labelledby="headingAdmin"
                                data-parent="#accordionSidebar">
                                <div className="bg-white py-2 collapse-inner rounded">
                                    <h6 className="collapse-header">{t('Custom_Menu_Settings')}</h6>
                                     
                                    <Link className="collapse-item" to="/ticketsdetails" onClick={changeSelectedLanguage}>{i18n.language==="es"?t('SubMenu_Settings_ChangeToEnglish'):t('SubMenu_Settings_ChangeToSpanish')}</Link>
                                             
                                </div>
                            </div>
                        </li>
                    }
                    {/* Nav Item - Charts */}                  
                    <li className="nav-item">
                        <Link className="nav-link" to={`/home`} onClick={handleCloseTicketsEditData}>
                            <LogoutIcon/>
                            <span>{t('Button_Back')}</span>
                        </Link>
                    </li>

                    {/* Divider */}
                    <hr className="sidebar-divider d-none d-md-block"/>

                    {/* Sidebar Toggler (Sidebar) */}
                    <div className="text-center d-none d-md-inline">
                        <button className="rounded-circle border-0" id="sidebarToggle"></button>
                    </div>

          
                </ul>
                {/* End of Sidebar */}

                {/* Content Wrapper */}
                <div id="content-wrapper" className="d-flex flex-column">

                {/* Main Content */}
                <div id="content">

                    {/* Topbar  */}
                    <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">

                        {/* Sidebar Toggle (Topbar)  */}
                        <button id="sidebarToggleTop" className="btn btn-link d-md-none rounded-circle mr-3">
                            <i className="fa fa-bars"></i>
                        </button>
                        
                        {/* Topbar Navbar */}
                        <ul className="navbar-nav ml-auto">
                            <div className="topbar-divider d-none d-sm-block"></div>
                                <div className="text-center">
                                    <br/>
                                    {t('Text_StoreSelected')}&nbsp;{ defaultStore.tradename===null ? t('Text_StoreEmpty'):defaultStore.tradename }
                                </div>      
                                
                            <div className="topbar-divider d-none d-sm-block"></div>
                            {/* Nav Item - Alerts */}
                            <Alerts/>
                            {/* Nav Item - Messages */}
                            <Messages/>
                            <div className="topbar-divider d-none d-sm-block"></div>
                            {/* Nav Item - User Information */}
                            <li className="nav-item dropdown no-arrow mx-1">
                                <br/>
                                <span className="mr-2 d-none d-lg-inline text-gray-600 small">
                                    <TodayIs/>  
                                </span>
                            </li>
                        </ul>
                    </nav>
                    {/* End of Topbar */}
                    <TicketsDetailsFilter 
                        applyFiltersTicketsDetails={applyFiltersTicketsDetails}
                        setApplyFiltersTicketsDetails={setApplyFiltersTicketsDetails}
                        showModalTicketsDetails={showModalTicketsDetails}  
                    />

                {/* Show Modals */}
                 
                </div>
                {/* End of Main Content */}

                {/* Footer */}
                    <Footer/>
                {/* End of Footer */}

            </div>
            {/* End of Content Wrapper */}

          </div>
          {/* End of Page Wrapper */}

      </div>}
      {/* End of Body */}

    </>
  )
};

export default HomeTicketsDetails;
