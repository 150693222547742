import { useEffect } from 'react';
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import jsPDF from 'jspdf';
import 'jspdf-autotable'; // Import the jsPDF autoTable plugin
import PropTypes from 'prop-types'; 
import RECICLED from "components/photos/purchaseDelivery/recicled.png";

const ReportPurchseDelivery = ({ dataPurchaseDelivery, initialStatePurchaseDelivery, setDataPurchaseDelivery, setViewPDF }) => {
  const { t, i18n } = useTranslation(['listDataForms']);
  const headerPrinterPurchase = useSelector((state) => state.loginUser.defaultticketsformat.headerPrinterPurchase);
 
  // Función para formatear el número
  function formatNumber(num) {
    return num.toLocaleString('es-ES', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });
  }

  function formatNumberEuro(num) {
    return num.toLocaleString('es-ES', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    }) + ' €';
  }


  function getDateFormated(getDate) {
    const meses = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
    const hoy = (getDate !==null && getDate !=="")? new Date(getDate):new Date();
    const dia = hoy.getDate();
    const mes = meses[hoy.getMonth()];
    const año = hoy.getFullYear();
    return `${dia} de ${mes} de ${año}`;
  }

  useEffect(() => {
    const generatePDF = () => {
      // Importa jsPDF
      const doc = new jsPDF({
        orientation: 'p', // 'p' para vertical, 'l' para horizontal
        unit: 'mm', // Unidad en milímetros
        format: [145, 175] // Ancho y alto en milímetros
      });  
      if(dataPurchaseDelivery.linespurchasedelivery && dataPurchaseDelivery.linespurchasedelivery.length > 0){
        let numLinesPerPurchase=15;
        let totalPurchase=0;
        let totalTax=0;
        let numPages = Math.ceil(dataPurchaseDelivery.linespurchasedelivery.length / numLinesPerPurchase);
        if (numPages===0){
          numPages=1;
        } 
        for (let j = 0; j < numPages; j++) { 
          let yOffset = 19; // Margen superior para comenzar a imprimir
          doc.addImage(RECICLED, 'PNG', 22, 10, 5, 5);
          doc.setFont('helvetica', 'bold');
          doc.setFontSize(16);
          doc.text("ALBARÁN", 100, yOffset-4, 'left');
          doc.setFont('helvetica', 'normal');
          doc.setFontSize(10);
          doc.text(`${dataPurchaseDelivery.id}`, 100, yOffset+2, 'left');
          doc.text(`${dataPurchaseDelivery.ownnumberpurchase.substr(0, 20)}`, 100, yOffset+6, 'left');
          doc.setFont('helvetica', 'bold');
          doc.setFontSize(6);
          // Recorrer el contenido de headerPrinter
          if (headerPrinterPurchase && headerPrinterPurchase.length > 0) {
            // Dividimos en líneas
            let counter=0;
            headerPrinterPurchase.split('\n').forEach((line) => {
              if (counter===0){
                doc.setFont('helvetica', 'bold');
                doc.setFontSize(7);
              }else{
                doc.setFont('helvetica', 'normal');
                doc.setFontSize(6);
              }
              counter++;
              doc.text(line, 25, yOffset, 'center');
              yOffset += 2;
            });
          }
          yOffset = 38;
          doc.setFont('helvetica', 'normal');
          doc.setFontSize(7);
          doc.text("He recibido de D.", 5, yOffset, 'left');
          doc.text("Camión nº ", 5, yOffset+4, 'left');
          doc.text("GRNP N.º ", 72, yOffset+4, 'left');
          doc.text("Obra ", 5, yOffset+8, 'left');
          doc.text("Dirección ", 5, yOffset+12, 'left');
          const newDate = (dataPurchaseDelivery.datepurchase!==undefined && dataPurchaseDelivery.datepurchase!==null && dataPurchaseDelivery.datepurchase!=="")?getDateFormated(dataPurchaseDelivery.datepurchase):"";
          doc.text(`${newDate}`, 140, yOffset-8, 'right');
         // Establecer el estilo de la línea punteada
          doc.setLineDash([0.2, 0.2], 0); // [2, 2] define el patrón de los puntos: 2px línea, 2px espacio
          // Dibujar la línea punteada
          doc.line(25, yOffset, 140, yOffset); // Dibuja una línea punteada desde (10, 20) hasta (200, 20)
          doc.line(19, yOffset+4, 70, yOffset+4); // Dibuja una línea punteada desde (10, 20) hasta (200, 20)
          doc.line(85, yOffset+4, 140, yOffset+4); // Dibuja una línea punteada desde (10, 20) hasta (200, 20)
          doc.line(12, yOffset+8, 140, yOffset+8); // Dibuja una línea punteada desde (10, 20) hasta (200, 20)
          doc.line(17, yOffset+12, 140, yOffset+12); // Dibuja una línea punteada desde (10, 20) hasta (200, 20)
          doc.text(`${dataPurchaseDelivery.ori_tras_tradename.substr(0, 85)}`, 25, yOffset-1, 'left');
          doc.text(`${dataPurchaseDelivery.vehiclestore_licenseplate.substr(0, 35)}`, 19, yOffset+3, 'left');
          doc.text(`${dataPurchaseDelivery.rel_trans_pygr.substr(0, 35)}`, 85, yOffset+3, 'left');
          doc.text(`${dataPurchaseDelivery.ori_tras_workplace.substr(0, 122)}`, 12, yOffset+7, 'left');
          doc.text(`${dataPurchaseDelivery.ori_tras_street.substr(0, 125)}`, 17, yOffset+11, 'left');
          doc.setLineDash([]);
          doc.setFillColor('#FFFFFF');
          doc.setLineWidth(0.3);  
          doc.rect(5, 52, 20, 4, 'FD');  
          doc.rect(25, 52, 80, 4, 'FD'); 
          doc.rect(100, 52, 20, 4, 'FD');  
          doc.rect(120, 52, 20, 4, 'FD');

          doc.setFont('helvetica', 'bold');
          doc.setFontSize(7);
          doc.text("CANTIDAD", 15, 55, 'center');
          doc.text("RESIDUO", 60,55, 'center');
          doc.text("PRECIO", 110, 55, 'center');
          doc.text("IMPORTE",130, 55, 'center');
          doc.setFont('helvetica', 'normal');
          doc.setFontSize(7);
          let startIndex = j * numLinesPerPurchase; // Índice inicial para esta página
          let endIndex = Math.min(startIndex + numLinesPerPurchase, dataPurchaseDelivery.linespurchasedelivery.length); // Índice final para esta página (máximo 10 líneas de RESIDUOS elementos)
          let addyOffset=0;
          yOffset=59;
          for (let i = startIndex; i < endIndex; i++) {
            if (dataPurchaseDelivery.linespurchasedelivery[i].article_namees.length>154){
              addyOffset=10;
            }  else{
              if (dataPurchaseDelivery.linespurchasedelivery[i].article_namees.length>77){
                addyOffset=7;
              }else{
                addyOffset=4;
              } 
            }
            doc.setFillColor('#FFFFFF');
            doc.setLineWidth(0.2);  
            doc.rect(5, yOffset-3, 20, addyOffset, 'FD');  
            doc.rect(25, yOffset-3, 80, addyOffset, 'FD'); 
            doc.rect(100, yOffset-3, 20, addyOffset, 'FD');  
            doc.rect(120, yOffset-3, 20, addyOffset, 'FD');
            if (i<(numLinesPerPurchase-1)){
              //doc.setLineWidth(0.3);  
              //doc.line(5, yOffset-3, 5, yOffset+addyOffset);
              //doc.line(25, yOffset-3, 25, yOffset+addyOffset);
              //doc.line(100, yOffset-3, 100, yOffset+addyOffset);
              //doc.line(120, yOffset-3, 120, yOffset+addyOffset);
              //doc.line(140, yOffset-3, 140, yOffset+addyOffset);
            } else{
              //doc.setLineWidth(0.3);  
              //doc.line(5, yOffset-3, 5, yOffset+addyOffset-3);
              //doc.line(25, yOffset-3, 25, yOffset+addyOffset-3);
              //doc.line(100, yOffset-3, 100, yOffset+addyOffset-3);
              //doc.line(120, yOffset-3, 120, yOffset+addyOffset-3);
              //doc.line(140, yOffset-3, 140, yOffset+addyOffset-3);
              //doc.line(5, yOffset+addyOffset-3, 140, yOffset+addyOffset-3);

            }
            doc.setFont('helvetica', 'normal');
            doc.setFontSize(7);
            doc.text(`${formatNumber(dataPurchaseDelivery.linespurchasedelivery[i].amount)}`, 24, yOffset, 'right');
            doc.text(`${formatNumberEuro(dataPurchaseDelivery.linespurchasedelivery[i].estimatedprice*(1-(dataPurchaseDelivery.linespurchasedelivery[i].discount/100)))}`, 118, yOffset, 'right');
            doc.text(`${formatNumberEuro(dataPurchaseDelivery.linespurchasedelivery[i].amount*dataPurchaseDelivery.linespurchasedelivery[i].estimatedprice*(1-(dataPurchaseDelivery.linespurchasedelivery[i].discount/100)))}`, 138, yOffset, 'right');
            totalPurchase+=dataPurchaseDelivery.linespurchasedelivery[i].amount*dataPurchaseDelivery.linespurchasedelivery[i].estimatedprice*(1-(dataPurchaseDelivery.linespurchasedelivery[i].discount/100));
            if (!dataPurchaseDelivery.linespurchasedelivery[i].taxincluded){
              totalTax+=(dataPurchaseDelivery.linespurchasedelivery[i].amount*dataPurchaseDelivery.linespurchasedelivery[i].estimatedprice*(1-(dataPurchaseDelivery.linespurchasedelivery[i].discount/100)))*(dataPurchaseDelivery.linespurchasedelivery[i].tax/100);
            }
            doc.setFont('helvetica', 'normal');
            doc.setFontSize(6);
            if (dataPurchaseDelivery.linespurchasedelivery[i].article_namees.length>154){
                doc.text(`${dataPurchaseDelivery.linespurchasedelivery[i].article_namees.substr(0, 78)}`, 26, yOffset, 'left');
                doc.text(`${dataPurchaseDelivery.linespurchasedelivery[i].article_namees.substr(78,75)}`, 26, yOffset+3, 'left');
                doc.text(`${dataPurchaseDelivery.linespurchasedelivery[i].article_namees.substr(153,78)}`, 26, yOffset+6, 'left'); 
            }  else{
              if (dataPurchaseDelivery.linespurchasedelivery[i].article_namees.length>77){
                doc.text(`${dataPurchaseDelivery.linespurchasedelivery[i].article_namees.substr(0, 77)}`, 26, yOffset, 'left');
                doc.text(`${dataPurchaseDelivery.linespurchasedelivery[i].article_namees.substr(77)}`, 26, yOffset+3, 'left'); 
              }else{
                doc.text(`${dataPurchaseDelivery.linespurchasedelivery[i].article_namees.substr(0, 77)}`, 26, yOffset, 'left'); 
              } 
            } 
            yOffset+=addyOffset;  
          } 
          
          if (j===(numPages-1)){
            //for (let i = (endIndex-startIndex); i < numLinesPerPurchase; i++) {
            //  doc.setFillColor('#FFFFFF');
            //  doc.setLineWidth(0.2);  
            //  doc.rect(5, yOffset-3, 20, 4, 'FD');  
            //  doc.rect(25, yOffset-3, 80, 4, 'FD'); 
            //  doc.rect(100, yOffset-3, 20, 4, 'FD');  
            //  doc.rect(120, yOffset-3, 20, 4, 'FD'); 
            //  doc.setLineWidth(0.3);  
            //  doc.line(5, yOffset-3, 5, yOffset+1);
            //  doc.line(25, yOffset-3, 25, yOffset+1);
            //  doc.line(100, yOffset-3, 100, yOffset+1);
            //  doc.line(120, yOffset-3, 120, yOffset+1);
            //  doc.line(140, yOffset-3, 140, yOffset+1); 
            //  yOffset+=4;
            //}  
            doc.setFillColor('#FFFFFF');
            doc.setLineWidth(0.3);  
            doc.line(5, yOffset-3, 140, yOffset-3);
            doc.rect(120, yOffset-3, 20, 4, 'FD');
            yOffset+=4;
            doc.rect(120, yOffset-3, 20, 4, 'FD');
            yOffset+=4;
            doc.rect(120, yOffset-3, 20, 4, 'FD');
            yOffset+=4;
            doc.rect(120, yOffset-3, 20, 4, 'FD');
            doc.setFont('helvetica', 'normal');
            doc.setFontSize(7);
            yOffset-=12;
            doc.text("SUBTOTAL", 118, yOffset, 'right');
            doc.setFontSize(6);
            doc.text("% IGIC", 118, yOffset+8, 'right');
            doc.setFont('helvetica', 'bold');
            doc.setFontSize(7);
            doc.text("TOTAL", 118, yOffset+12, 'right');
            doc.setFont('helvetica', 'normal');
            doc.setFontSize(7);
            doc.text(`${formatNumberEuro(totalPurchase)}`, 138, yOffset, 'right');
            doc.text(`${formatNumberEuro(totalTax)}`, 138, yOffset+8, 'right');
            doc.setFont('helvetica', 'bold');
            doc.setFontSize(7);
            doc.text(`${formatNumberEuro(totalPurchase+totalTax)}`, 138, yOffset+12, 'right');

            yOffset=140
            doc.setFont('helvetica', 'normal');
            doc.setFontSize(6);
            doc.text("Despachado por", 8, yOffset+3, 'left');
            doc.text("Conforme Cliente", 60, yOffset+3, 'center');
            doc.setFont('helvetica', 'normal');
            doc.setFontSize(6);
            doc.text("DNI nº", 42, yOffset+16, 'left');
            doc.text(`${dataPurchaseDelivery.ori_tras_documentid.substr(0, 30)}`, 50, yOffset+15, 'left');  
            doc.setLineDash([0.2, 0.2], 0); // [2, 2] define el patrón de los puntos: 2px línea, 2px espacio
            // Dibujar la línea punteada
            doc.line(48, yOffset+16, 78, yOffset+16); // Dibuja una línea punteada desde (10, 20) hasta (200, 20)
            doc.setLineDash([]);
          
          }
          doc.setFont('helvetica', 'bold');
          doc.setFontSize(5.3);
          doc.text("*Este documento no tiene valor como  `Certificado Acreditativo de Gestión de Residuos de la Construcción'  según el art. 7.c. del Real Decreto 105/2008", 5, 165, 'left');
          if (j<numPages-1){
            doc.addPage();
          }
      } 
    }
    // Open PDF in a new tab
    const pdfBlob = doc.output('blob');
    const url = URL.createObjectURL(pdfBlob);
    window.open(url, '_blank');
    setViewPDF(false);
    setDataPurchaseDelivery(initialStatePurchaseDelivery);
      
  };

  if (dataPurchaseDelivery) {
    generatePDF();
  }
}, [dataPurchaseDelivery, t,i18n,setViewPDF, setDataPurchaseDelivery, initialStatePurchaseDelivery,headerPrinterPurchase]);
  return null; // This component does not render anything in the DOM
};

ReportPurchseDelivery.propTypes = {
  dataPurchaseDelivery: PropTypes.object.isRequired, 
  setDataPurchaseDelivery: PropTypes.func.isRequired,
  setViewPDF: PropTypes.func.isRequired
};

export default ReportPurchseDelivery;
