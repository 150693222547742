import React, {useState, useEffect} from 'react' 
import { useTheme } from '@mui/material/styles';
import { useTranslation } from "react-i18next"
import { TextField } from '@mui/material'; 
import { Toolbar,TableFooter,TablePagination,TableSortLabel,Table,TableBody,TableCell,TableContainer, TableHead,TableRow,Paper,Typography } from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton'; 
import PanToolAltIcon from '@mui/icons-material/PanToolAlt'; 
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import PropTypes from 'prop-types';

const headCellsFormDI = [
  { id: 'ownformdi_id', numeric: false, disablePadding: false, label: 'Text_Code', canOrder: true },
  { id: 'preiddoc_sheet', numeric: false, disablePadding: false, label: 'Text_FORM_PREIDDOC_SHEET', canOrder: true },
  { id: 'sufiddoc_sheet', numeric: false, disablePadding: false, label: 'Text_FORM_SUFIDDOC_SHEET', canOrder: true },
  { id: 'number_dt', numeric: false, disablePadding: false, label: 'Text_FORM_NT_SHEET', canOrder: true },
  { id: 'date_sheet', numeric: false, disablePadding: false, label: 'Text_FORM_DATE', canOrder: true },
  { id: 'rel_trans_tradename', numeric: false, disablePadding: false, label: 'Text_FORM_REL_TRANS', canOrder: true },
  { id: 'rel_trans_licenseplate', numeric: false, disablePadding: false, label: 'Text_FORM_LICENSEPLATE', canOrder: true }
];

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
};

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
  
function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
  
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
          return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, t } = props;
  const createSortHandler = (property) => (event) => { onRequestSort(event, property); };
  return (
    <TableHead>
      <TableRow>
        <TableCell
          key='edit'
          align='center'
          padding='normal'
          sortDirection={false}
        >
          <Typography style={{color:"#4e73df", fontSize: 14}} >
            {t('Button_Select')}
          </Typography>
        </TableCell>
        {headCellsFormDI.map((headCell, indexhead) => (
          <TableCell
            key={indexhead}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.canOrder ?(
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              <Typography style={{color:"#4e73df", fontSize: 14}} >  
                {t(headCell.label)}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </Typography>
            </TableSortLabel>):
              <Typography style={{color:"#4e73df", fontSize: 14}} >  
                {t(headCell.label)}
              </Typography>}
          </TableCell>
        ))} 
         
      </TableRow>
    </TableHead>
  );
}

const EnhancedTableToolbar = (props) => {
  const { textHead, textReg, numTotRegFilter,numTotRegFormDI, textOf  } = props;
    return (
      <Toolbar>
        <Typography style={{flex: '1 1 100%',color:"#4e73df", fontSize: 28}} id="tableTitle" component="div">
          {textHead}
        </Typography>
        <Typography style={{flexShrink: 0, ml: 2.5, color:"#4e73df", fontSize: 18}} id="tableTitle" component="div">
          {textReg} {(numTotRegFilter).toLocaleString('de-DE')} {textOf} {(numTotRegFormDI).toLocaleString('de-DE')}
        </Typography>
    </Toolbar>
    );
};

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;
  const {t} = useTranslation(['listDataTickets']);

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label={t('Text_First_Page')}
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label={t('Text_Previous_Page')}
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label={t('Text_Next_Page')}
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label={t('Text_Last_Page')}
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const TableSortingPurchaseDeliveryFormDI =(props)=>{
  const { 
    dataFormDI,
    editRowArticles,
    setEditRowArticles,
    numTotRegFilter,
    titleTable,
    numTotRegFormDI,
    activeOffsetFormDI,
    rangeOffsetFormDI,
    currencyOffsetFormDI,
    setCurrencyOffsetFormDI,
    handleCloseDataEditFormDI,
    formPurchaseDelivery,
    setFormPurchaseDelivery
  } = props;
  const {t,i18n} = useTranslation(['listDataTickets']);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [emptyRows, setEmptyRows] = useState(page > 0 ? Math.max(0, (1 + page) * rowsPerPage - dataFormDI.length) : 0);
  const [countData,setCountData] = useState(dataFormDI.length);
   
  useEffect(
    () => {
      if (dataFormDI.length!==countData || dataFormDI.length<=rowsPerPage){
        setPage(0);
      }  
      setCountData(dataFormDI.length);
      setEmptyRows(page > 0 ? Math.max(0, (1 + page) * rowsPerPage - dataFormDI.length) : 0);  
    },
    [dataFormDI,page,setEmptyRows,rowsPerPage,countData]
  );

  const handleChangeOffset = (event) => {
    setCurrencyOffsetFormDI(event.target.value);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const selectFormDI = (row) => { 
    let idx=formPurchaseDelivery.linespurchasedelivery.findIndex(((item) => item.indexrowarticle === editRowArticles.indexrowarticle));
     let articleToAdd={
      "id":editRowArticles.id,
      "indexrowarticle":editRowArticles.indexrowarticle,
      "purchase_id":editRowArticles.purchase_id,
      "article_id":editRowArticles.article_id,
      "article_code":editRowArticles.article_code,
      "article_namees":editRowArticles.article_namees,
      "article_nameen":editRowArticles.article_nameen,
      "datepurchase":editRowArticles.datepurchase,
      "estimatedprice":editRowArticles.estimatedprice,
      "amount":editRowArticles.amount,
      "discount":editRowArticles.discount,
      "tax":editRowArticles.tax,
      "taxincluded": editRowArticles.taxincluded,
      "total": editRowArticles.total,
      "ticketweight":editRowArticles.ticketweight,
      "formdi_id":row.id,
      "ownformdi_id":row.ownformdi_id
      
    }
    formPurchaseDelivery.linespurchasedelivery[idx]=articleToAdd
    setEditRowArticles(editRowArticles => ({
      ...editRowArticles,
      formdi_id:row.id,
      ownformdi_id:row.ownformdi_id
    }));
    setFormPurchaseDelivery(formPurchaseDelivery => ({
      ...formPurchaseDelivery
    }));
    handleCloseDataEditFormDI();
  };
     
  const listItems= (
  stableSort(dataFormDI, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, keyData) => {
    return (
      <TableRow hover tabIndex={-1} key={keyData}>
         {(row.id!=="" && row.id!==null ) &&
          <TableCell align="center">
            <IconButton  size="large"  style={{color:"#4e73df", fontSize: 14}}  onClick={()=>selectFormDI(row)}>
              <PanToolAltIcon/>
            </IconButton>
          </TableCell>
        }
        <TableCell align="left">
          <Typography style={{color:"#4e73df", fontSize: 14}} >
            {row.ownformdi_id}
          </Typography>  
        </TableCell> 
        <TableCell align="left">
          <Typography style={{color:"#000000", fontSize: 14}} >
            {row.preiddoc_sheet}
          </Typography>
        </TableCell>
        <TableCell align="left">
          <Typography style={{color:"#000000", fontSize: 14}} >
            {row.sufiddoc_sheet}
          </Typography>
        </TableCell>
        <TableCell align="left">
          <Typography style={{color:"#000000", fontSize: 14}} >
            {row.number_dt}
          </Typography>
        </TableCell>
        <TableCell align="right">
          <Typography style={{color:"#000000", fontSize: 14}} >
            {row.date_sheet!==""?new Date(row.date_sheet).toLocaleDateString(i18n.language==="es"?'es-ES':'en-US'):""}
           </Typography>
        </TableCell>
        <TableCell align="left">
          <Typography style={{color:"#000000", fontSize: 14}} >
            {row.rel_trans_tradename}
          </Typography>
        </TableCell>
        <TableCell align="left">
          <Typography style={{color:"#000000", fontSize: 14}} >
            {t(row.rel_trans_licenseplate)}
          </Typography>
        </TableCell> 
      </TableRow>
    );
  }));
  
  return(
    <>
      <Box sx={{ width: '100%' }}> 
        <EnhancedTableToolbar textHead={t(titleTable)} textReg={t('Text_Num_Reg')} numTotRegFilter={numTotRegFilter} numTotRegFormDI={numTotRegFormDI} textOf={t('Text_Of')}/>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size='small'>
              <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} t={t}  i18n={i18n}/>
              <TableBody>    
                {listItems}
                {emptyRows > 0 && (
                <TableRow style={{ height: 33  * emptyRows}}>
                  <TableCell colSpan={16} />
                </TableRow>
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                {activeOffsetFormDI && (
                    <TableCell colSpan={2}>
                      <Box sx={{ flexShrink: 0, p: 1}}>
                        <div className="form-group">
                          <TextField
                            id="currencyOffsetFormDI"
                            name="currencyOffsetFormDI"
                            value={currencyOffsetFormDI}
                            inputProps={{ style: {maxLength: 100, color:"#4e73df",fontSize:14}}}
                            placeholder={t('Text_Range_Offset')}
                            variant="standard"
                            onChange={handleChangeOffset}
                            helperText={t('Text_Range_Offset')}
                            select
                            SelectProps={{
                              native: true,
                              value: currencyOffsetFormDI
                            }}>
                            {rangeOffsetFormDI.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                          ))}
                          </TextField>
                        </div>
                      </Box>  
                    </TableCell>
                  )}
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    colSpan={16}
                    count={countData}
                    rowsPerPage={rowsPerPage}
                    page={countData===0?0:page}
                    labelRowsPerPage={t('Text_Rows_Per_Page')}
                    slotProps={{
                      inputProps: {
                        'aria-label': t('Text_Rows_Per_Page'),
                      },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
      </Box>
    </>
  )
}

TableSortingPurchaseDeliveryFormDI.propTypes = {
  dataFormDI: PropTypes.array.isRequired,
  setEditRowArticles: PropTypes.func.isRequired,
  numTotRegFilter: PropTypes.number.isRequired,
  titleTable : PropTypes.string.isRequired, 
  activeOffsetFormDI: PropTypes.bool.isRequired,
  rangeOffsetFormDI: PropTypes.array.isRequired,
  currencyOffsetFormDI: PropTypes.number.isRequired,
  setCurrencyOffsetFormDI: PropTypes.func.isRequired,
  handleCloseDataEditFormDI: PropTypes.func.isRequired,
  setFormPurchaseDelivery: PropTypes.func.isRequired
};
  
export default TableSortingPurchaseDeliveryFormDI;
