import React, {  useEffect, useState, useCallback } from 'react';
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux"; 
import { useTranslation } from "react-i18next" 
import { useNavigate } from 'react-router-dom'; 
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { Box } from '@mui/material'; 
import { TextField } from '@mui/material';
import { Typography} from '@mui/material';
import { Button } from '@mui/material';
import { CardActions } from '@mui/material'; 
import { Grid } from '@mui/material';
import { Checkbox } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress'; 
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent'; 
import DialogTitle from '@mui/material/DialogTitle';
import Card from '@mui/material/Card'; 
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import SignatureCanvas from 'react-signature-canvas';
import IconButton from '@mui/material/IconButton'; 
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import "css/generalSettings.css"; 
import { successErrorAction,warningErrorAction } from "actions/ErrorActions"; 
import { helpHttp } from "service/HelpHttp";
import { Loading } from "components/utils/Loading"; 
import { NumericFormat } from 'react-number-format'; 
import ShippingSearchStores from "pages/shipping/editOrNewShipping/ShippingSearchStores";
import ShippingSearchStoresAddresses from "pages/shipping/editOrNewShipping/ShippingSearchStoresAddress";
import ShippingSearchVehicles from "pages/shipping/editOrNewShipping/ShippingSearchVehicles" ;
import image1 from "components/photos/formdi/tosign.png"; 

import PropTypes from 'prop-types';  

const initialStateShipping = {
  "id" : "",
  "ownshipping_id" : "",
  "defaultStore_id":0, 
  "useraccount_id":0,
  "defaultformatnumber":1, 
  "number_shipping":"",
  "date_shipping":"",
  
  "rel_trans_store_id":0, 
  "rel_trans_documentid":"",
  "rel_trans_tradename":"",
  "rel_trans_street":"",

  "vehiclestore_id":0, 
  "vehiclestore_licenseplate":"", 
  "vehiclestore_documentid":"",
  "vehiclestore_defaultdriver":"",
  
  "opt_tras_store_id":0, 
  "opt_tras_documentid":"",
  "opt_tras_tradename":"",
  "opt_tras_street":"",

  "dest_tras_store_id":0, 
  "dest_tras_documentid":"",
  "dest_tras_tradename":"",
  "dest_tras_street":"",
  
  "weight":0, 
  "price_weight":0, 
  "service_1":"",
  "price_service_1":0, 
  "service_2":"",
  "price_service_2":0, 
  "service_3":"",
  "price_service_3":0, 
  "destine_street":"",
  "tax":0, 
  "taxincluded":false,
  "observations":"",
  "signature_driver":"",
  "signature_charger":""
};
   
const ShippingEditOrCreate = ({id, showModalShippingsSettings}) => {
  const {t,i18n} = useTranslation(['listDataShippings']);
  const token = useSelector((state) => state.loginUser.token);  
  const roles = useSelector((state) => state.loginUser.roles);  
  const defaultStore = useSelector((state) => state.loginUser.defaultStore)
  const useraccount_id = useSelector((state) => state.loginUser.useraccount_id);  
  const defaulttypesdi = useSelector((state) => state.loginUser.defaulttypesdi);  
  const ENDPOINT = useSelector((state) => state.variablesUser.ENDPOINT);  
  //const signature_default = useSelector((state) => state.formatDIUser.signature_default);
  
  const [formShipping, setFormShipping] = useState(initialStateShipping);
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [isLoadingErrorData, setIsLoadingErrorData] = useState(false);
  const [errorsForm, setErrorsForm] = useState({});
  const [showModalSearchVehicles, setShowModalSearchVehicles] = useState(false); 
  const [showModalSearchStores, setShowModalSearchStores] = useState(false); 
  const [showModalSearchStoresAddress, setShowModalSearchStoresAddress] = useState(false);  
  const [tetxtSearch, setTetxtSearch] = useState("Rel_Trans");  
  const [openSignDriver,setOpenSignDriver]= useState(false); 
  const [openSignCharger,setOpenSignCharger]= useState(false);
  const [signDriverCanvas, setSignDriverCanvas]= useState(); 
  const [signChargerCanvas, setSignChargerCanvas]= useState(); 
  
  const dispatch = useDispatch();
  const navigate = useNavigate(); 
  
  const keyPress = useCallback(
    e => {
      if (e.key === 'Escape' && showModalShippingsSettings) {
        setFormShipping(initialStateShipping);
        setIsLoadingData(false);
        setIsLoadingErrorData(false);
        setErrorsForm({});
        setShowModalSearchVehicles(false);
        setShowModalSearchStores(false);
        setTetxtSearch("Rel_Trans");
        setShowModalSearchStoresAddress(false);
        setOpenSignDriver(false); 
        setOpenSignCharger(false);
        setSignDriverCanvas();
        setSignChargerCanvas();
        navigate("/shipping");
        return () => {};
      }
    },
    [
      setFormShipping,
      setIsLoadingData,
      setIsLoadingErrorData,
      setErrorsForm, 
      setShowModalSearchVehicles,
      setShowModalSearchStores,
      setTetxtSearch,
      setShowModalSearchStoresAddress,
      setOpenSignDriver,
      setOpenSignCharger,
      setSignDriverCanvas,
      setSignChargerCanvas,
      navigate,
      showModalShippingsSettings]
  );
  
  const handleCloseDataEditShipping = () => {
    setFormShipping(initialStateShipping);
    setIsLoadingData(false);
    setIsLoadingErrorData(false);
    setErrorsForm({}); 
    setShowModalSearchVehicles(false);
    setShowModalSearchStores(false);
    setTetxtSearch("Rel_Trans");
    setShowModalSearchStoresAddress(false);
    setOpenSignDriver(false); 
    setOpenSignCharger(false);
    setSignDriverCanvas();
    setSignChargerCanvas();
    navigate("/shipping");
    return () => {};
	};
    
  const selectModalSignature = (id) => {
    switch (id) {
      case 1:   
        setOpenSignDriver(true);  
        break;
      case 2:    
        setOpenSignCharger(true);
        break;
      default:
        break;
    } 
  };

  const deleteSignature = (id) => {
    switch (id) {
      case 1:   
        setFormShipping(formShipping => ({
          ...formShipping,
          signature_driver: "",
        }));
        break;
      case 2:    
        setFormShipping(formShipping => ({
          ...formShipping,
          signature_charger: "",
        }));
        break;
      default:
        break;
    } 
  };
  
  const handleCancelSignDriver = () => {
    signDriverCanvas.clear();
    setOpenSignDriver(false);
  };

  const handleDeleteSignDriver = () => {
    signDriverCanvas.clear();
    setFormShipping(formShipping => ({
      ...formShipping,
      signature_driver: "",
    }));
    setOpenSignDriver(false);
  };
  const handleSubmitSignDriver = () => {
    const dataURL = signDriverCanvas.getTrimmedCanvas().toDataURL('image/png');
    setFormShipping(formShipping => ({
      ...formShipping,
      signature_driver:dataURL,
    }));
    setOpenSignDriver(false);
  };
  
  const handleCancelSignCharger = () => {
    signChargerCanvas.clear();
    setOpenSignCharger(false);
  };

  const handleDeleteSignCharger = () => {
    signChargerCanvas.clear();
    setFormShipping(formShipping => ({
      ...formShipping,
      signature_charger: "",
    }));
    setOpenSignCharger(false);
  };
  const handleSubmitSignCharger = () => {
    const dataURL = signChargerCanvas.getTrimmedCanvas().toDataURL('image/png');
    setFormShipping(formShipping => ({
      ...formShipping,
      signature_charger:dataURL,
    }));
    setOpenSignCharger(false);
  };

  const handleChangeDataEditShipping = (e) => {
    const { name, value } = e.target;
    setFormShipping(formShipping => ({
      ...formShipping,
      [name]: value,
    }));
  };

  const handleBlurDataEditShipping = (e) => {
    handleChangeDataEditShipping(e);
    setErrorsForm(validateFormShipping());
  };

  const handleChangeSelectDate = (event) => {
    setFormShipping(formShipping => ({
      ...formShipping,
      date_shipping: event!==null?new Date(event):null
    }));
  };

  const handleBlurSelectDate = (e) => {
    handleChangeSelectDate(e);
    setErrorsForm(validateFormShipping());
  };
  
  const withValueCap = (inputObj) => {
    const { value } = inputObj;
    if (value <= 1000000) return true;
    return false;
  };

  const withValueCap1000 = (inputObj) => {
    const { value } = inputObj;
    if (value <= 100) return true;
    return false;
  };

  const handleChangeDataEditShippingWeight = (e) => {
    const { name, value } = e.target;
    let newValue=value!==null?Number((value.toLocaleString()).replaceAll(",","").replaceAll("kg","").replaceAll("€","").replaceAll("%","").replaceAll(" ","")):0;
    setFormShipping(formShipping => ({
      ...formShipping,
      [name]: newValue,
    }));
  };

  const handleBlurDataEditShippingWeight = (e) => {
    handleChangeDataEditShippingWeight(e);
    setErrorsForm(validateFormShipping());
  };

  const deleteLicensePlate = (event) => {
    setFormShipping(formShipping => ({
      ...formShipping,
      vehiclestore_id:0,
      vehiclestore_licenseplate: "",
      vehiclestore_documentid:"",
      vehiclestore_defaultdriver: ""
       
    }));
    setErrorsForm(validateFormShipping());
  };

  const searchVehicles = (event) => {
    setShowModalSearchVehicles(true);
  };

  const deleteStoreOptTras = (event) => {
    setFormShipping(formShipping => ({
      ...formShipping,
      opt_tras_store_id:0,
      opt_tras_documentid: "",
      opt_tras_tradename:"",
      opt_tras_street: ""
       
    }));
    setErrorsForm(validateFormShipping());
  };

  const searchStoreOptTras = (event) => {
    setTetxtSearch("Opt_Tras")
    setShowModalSearchStores(true);
  };

  const deleteAddresStoreOptTras = (event) => {
    setFormShipping(formShipping => ({
      ...formShipping,
      opt_tras_street: ""
       
    }));
    setErrorsForm(validateFormShipping());
  };

  const searchAddresStoreOptTras = (event) => {
    setTetxtSearch("Opt_Tras")
    setShowModalSearchStoresAddress(true);
  };

  const deleteStoreDestTras = (event) => {
    setFormShipping(formShipping => ({
      ...formShipping,
      dest_tras_store_id:0,
      dest_tras_documentid: "",
      dest_tras_tradename:"",
      dest_tras_street: ""
       
    }));
    setErrorsForm(validateFormShipping());
  };

  const searchStoreDestTras = (event) => {
    setTetxtSearch("Dest_Tras")
    setShowModalSearchStores(true);
  };

  const deleteAddresStoreDestTras = (event) => {
    setFormShipping(formShipping => ({
      ...formShipping,
      dest_tras_street: ""
       
    }));
    setErrorsForm(validateFormShipping());
  };

  const searchAddresStoreDestTras = (event) => {
    setTetxtSearch("Dest_Tras")
    setShowModalSearchStoresAddress(true);
  };

  const deleteStoreRelTrans = (event) => {
    setFormShipping(formShipping => ({
      ...formShipping,
      rel_trans_store_id:0,
      rel_trans_documentid: "",
      rel_trans_tradename:"" 
    }));
    setErrorsForm(validateFormShipping());
  };

  const searchStoreRelTrans = (event) => {
    setTetxtSearch("Rel_Trans")
    setShowModalSearchStores(true);
  };

  const deleteAddresStoreRelTrans = (event) => {
    setFormShipping(formShipping => ({
      ...formShipping,
      rel_trans_street: ""
       
    }));
    setErrorsForm(validateFormShipping());
  };

  const searchAddresStoreRelTrans = (event) => {
    setTetxtSearch("Rel_Trans")
    setShowModalSearchStoresAddress(true);
  };

  const deleteADriverStoreRelTrans = (event) => {
    setFormShipping(formShipping => ({
      ...formShipping,
      vehiclestore_defaultdriver:"",
      vehiclestore_documentid: ""
    }));
    setErrorsForm(validateFormShipping());
  };

  const handleChangeTaxIncluded = (event) => {
    setFormShipping(formShipping => ({
      ...formShipping,
      taxincluded: !formShipping.taxincluded
    }));
  };
 
  const validateFormShipping = () => {
    let errorsForm = {};
    let regexInvalidCharacters = /[\\!\\$\\%\\&\\|\\<\\>\\?\\{\\}\\~\\"\\']+/;
     
    if (regexInvalidCharacters.test(formShipping.number_shipping)) {
      errorsForm.number_shipping = 'Text_TextNoValid';
    }
    if (formShipping.date_shipping==="") {
      errorsForm.date_shipping = 'Text_FieldDateShippingRequired';
    }


    if (regexInvalidCharacters.test(formShipping.number_shipping)) {
      errorsForm.number_shipping = 'Text_TextNoValid';
    }
    
    if (regexInvalidCharacters.test(formShipping.rel_trans_documentid)) {
      errorsForm.rel_trans_documentid = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formShipping.rel_trans_tradename)) {
      errorsForm.rel_trans_tradename = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formShipping.rel_trans_street)) {
      errorsForm.rel_trans_street = 'Text_TextNoValid';
    }

    if (regexInvalidCharacters.test(formShipping.vehiclestore_licenseplate)) {
      errorsForm.vehiclestore_licenseplate = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formShipping.vehiclestore_documentid)) {
      errorsForm.vehiclestore_documentid = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formShipping.vehiclestore_defaultdriver)) {
      errorsForm.vehiclestore_defaultdriver = 'Text_TextNoValid';
    }else{
      if (formShipping.vehiclestore_licenseplate==="") {
        errorsForm.vehiclestore_licenseplate = 'Text_FieldLicensePlateShippingRequired';
      }
    }

     
    if (regexInvalidCharacters.test(formShipping.opt_tras_documentid)) {
      errorsForm.opt_tras_documentid = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formShipping.opt_tras_tradename)) {
      errorsForm.opt_tras_tradename = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formShipping.opt_tras_street)) {
      errorsForm.opt_tras_street = 'Text_TextNoValid';
    }

    if (regexInvalidCharacters.test(formShipping.dest_tras_documentid)) {
      errorsForm.dest_tras_documentid = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formShipping.dest_tras_tradename)) {
      errorsForm.dest_tras_tradename = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formShipping.dest_tras_street)) {
      errorsForm.dest_tras_street = 'Text_TextNoValid';
    }

    if (regexInvalidCharacters.test(formShipping.service_1)) {
      errorsForm.service_1 = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formShipping.service_2)) {
      errorsForm.service_2 = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formShipping.service_3)) {
      errorsForm.service_3 = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formShipping.observations)) {
      errorsForm.observations = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(formShipping.destine_street)) {
      errorsForm.destine_street = 'Text_TextNoValid';
    } 
    return errorsForm;
  };

  const handleConfirmFetchDataEditShipping = () => {
    
    const fetchData = async () => {
      setIsLoadingData(true);
      let isMounted = true;
      try {
        const getData = id !== "0"
          ? await helpHttp().put(ENDPOINT, `shipping/updateshipping`, token, formShipping)
          : await helpHttp().post(ENDPOINT, `shipping/saveshipping`, token, formShipping);
        if (getData.status === "OK") {
          dispatch(successErrorAction(t('Text_SaveData')));
        } else {
          dispatch(warningErrorAction(t(getData.cause)));
        }
        handleCloseDataEditShipping();
      } catch (error) {
        if (isMounted){
          dispatch(warningErrorAction(t('Text_IncorrectData'))); 
        } 
      }   
      return () => { isMounted = false;};
    }; 
    fetchData(); 
    setIsLoadingData(false); 
  };
 
  const handleSubmitDataEditShipping = () => {
    const errors = validateFormShipping();
    setErrorsForm(errors);
    if (Object.keys(errors).length === 0 && formShipping.date_shipping !== ''
        && formShipping.vehiclestore_licenseplate !== '' ) {
        handleConfirmFetchDataEditShipping();
    } else {
      if (errors.date_shipping) {
        dispatch(warningErrorAction(t(errors.date_shipping)));
      } else{
        if (errors.vehiclestore_licenseplate) {
          dispatch(warningErrorAction(t(errors.vehiclestore_licenseplate)));
        } else{
          dispatch(warningErrorAction(t('Text_IncorrectData'))); 
        }
      }
    }
  }; 

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event && typeof event === 'object' && event.key) {
        keyPress(event);
      }
    };
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [keyPress]);

  useEffect(() => {
    let isMounted = true;
    const fetchExistLicensePlate = async () => {
      try {
        const getData = await helpHttp().get2(ENDPOINT,`shipping/getexistvehicleylicenseplate/`,token,formShipping.vehiclestore_licenseplate);
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          if (getData>0 && getData!==formShipping.vehiclestore_licenseplate){
            fetchLicensePlate();
          }
        }
      } catch (error) {
          dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };
    const fetchLicensePlate = async () => {
      try {
          const getData = await helpHttp().get2(ENDPOINT,`shipping/getvehiclebylicenseplate/`,token,formShipping.vehiclestore_licenseplate);
          if (getData.err) {
              dispatch(warningErrorAction(t('Text_InvalidCredentials')));
          } else if (isMounted) {
              if (getData!==undefined && getData!==null && getData!==""){
                setFormShipping(formShipping => ({
                  ...formShipping,
                  vehiclestore_id: getData.id,
                  vehiclestore_defaultdriver: getData.defaultdriver,
                  rel_trans_store_id: (getData.store_id===undefined 
                                        || getData.store_id===null  
                                        || getData.store_id===""
                                        || getData.store_id==="0"
                                        || getData.store_id===0)?
                                        0:getData.store_id 
              }));
              if (formShipping.rel_trans_documentid===undefined 
                || formShipping.rel_trans_documentid===null
                || formShipping.rel_trans_documentid===""
              ){
                setFormShipping(formShipping => ({
                  ...formShipping,
                  rel_trans_documentid:getData.store_documentid,
                  rel_trans_tradename: getData.store_tradename
                }))
              }
              if (formShipping.rel_trans_tradename===undefined 
                || formShipping.rel_trans_tradename===null
                || formShipping.rel_trans_tradename===""
              ){
                setFormShipping(formShipping => ({
                  ...formShipping,
                  rel_trans_tradename: getData.store_tradename
                }))
              }
              setFormShipping(formShipping => ({
                ...formShipping,
              }))
            }
          }         
      } catch (error) {
          dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    }; 
    if (formShipping.vehiclestore_licenseplate!==""){
      fetchExistLicensePlate();
    } 
    return () => { isMounted = false };
  }, [ENDPOINT,t,token,dispatch,formShipping.vehiclestore_licenseplate,formShipping.rel_trans_tradename,formShipping.rel_trans_documentid]);
 
  useEffect(() => {
    let isMounted = true;
    const fetchExistNumberDocumentOptTras = async () => {
      try { 
        const getData = await helpHttp().get2(ENDPOINT,`shipping/getexiststorebynumberdocument/`,token,formShipping.opt_tras_documentid);
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          if (getData>0 && getData!==formShipping.opt_tras_store_id){
            fetchStoreNumberDocumentOptTras();
          }
        }
      } catch (error) {
          dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };
    const fetchStoreNumberDocumentOptTras = async () => {
      try {
          const getData = await helpHttp().get5(ENDPOINT,`shipping/getstorebynumberdocumentpriority`,token,formShipping.opt_tras_documentid,1);
          if (getData.err) {
              dispatch(warningErrorAction(t('Text_InvalidCredentials')));
          } else if (isMounted) {
            if (getData!==undefined && getData!==null && getData!==""){
              setFormShipping(formShipping => ({
                ...formShipping,
                opt_tras_store_id: getData.store_id, 
                opt_tras_tradename: getData.tradename,
                opt_tras_street: (getData.street!==undefined && getData.street!==null && getData.street.street!==undefined && getData.street.street!==null)?getData.street.street:"" 
            }));
          }  
        }         
      } catch (error) {
          dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };
    if (formShipping.opt_tras_documentid!==""){
      fetchExistNumberDocumentOptTras();
    } 
    return () => { isMounted = false };
  }, [ENDPOINT,t,token,dispatch,formShipping.opt_tras_documentid,formShipping.opt_tras_store_id]);

  useEffect(() => {
    let isMounted = true;
    const fetchExistNumberDocumentDestTras = async () => {
      try { 
        const getData = await helpHttp().get2(ENDPOINT,`shipping/getexiststorebynumberdocument/`,token,formShipping.dest_tras_documentid);
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          if (getData>0 && getData!==formShipping.dest_tras_store_id){
            fetchStoreNumberDocumentDestTras();
          }
        }
      } catch (error) {
          dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };
    const fetchStoreNumberDocumentDestTras = async () => {
      try {
          const getData = await helpHttp().get5(ENDPOINT,`shipping/getstorebynumberdocumentpriority`,token,formShipping.dest_tras_documentid,4);
          if (getData.err) {
              dispatch(warningErrorAction(t('Text_InvalidCredentials')));
          } else if (isMounted) {
            if (getData!==undefined && getData!==null && getData!==""){
              setFormShipping(formShipping => ({
                ...formShipping,
                dest_tras_store_id: getData.store_id, 
                dest_tras_tradename: getData.tradename,
                dest_tras_street: (getData.street!==undefined && getData.street!==null && getData.street.street!==undefined && getData.street.street!==null)?getData.street.street:"" 
            }));
          }  
        }         
      } catch (error) {
          dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };
    if (formShipping.dest_tras_documentid!==""){
      fetchExistNumberDocumentDestTras();
    } 
    return () => { isMounted = false };
  }, [ENDPOINT,t,token,dispatch,formShipping.dest_tras_store_id,formShipping.dest_tras_documentid]);

  useEffect(() => {
    let isMounted = true;
    const fetchExistNumberDocumentRelTrans = async () => {
      try { 
        const getData = await helpHttp().get2(ENDPOINT,`shipping/getexiststorebynumberdocument/`,token,formShipping.rel_trans_documentid);
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          if (getData>0 && getData!==formShipping.rel_trans_store_id){
            fetchStoreNumberDocumentRelTrans();
          }
        }
      } catch (error) {
          dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };
    const fetchStoreNumberDocumentRelTrans = async () => {
      try {
          const getData = await helpHttp().get5(ENDPOINT,`shipping/getstorebynumberdocumentpriority`,token,formShipping.rel_trans_documentid,6);
          if (getData.err) {
              dispatch(warningErrorAction(t('Text_InvalidCredentials')));
          } else if (isMounted) {
            if (getData!==undefined && getData!==null && getData!==""){
              setFormShipping(formShipping => ({
                ...formShipping,
                rel_trans_store_id: getData.store_id, 
                rel_trans_tradename: getData.tradename 
            }));
          }  
        }         
      } catch (error) {
          dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };
    if (formShipping.rel_trans_documentid!==""){
      fetchExistNumberDocumentRelTrans();
    } 
    return () => { isMounted = false };
  }, [ENDPOINT,t,token,dispatch,formShipping.rel_trans_store_id,formShipping.rel_trans_documentid]);

  useEffect(() => {
    let isMounted = true;
    const fetchDataShipping = async () => {
      setIsLoadingData(true);
      try {
        const getData = await  helpHttp().get2(ENDPOINT,`shipping/getshippingbyid/`,token,id);
        if (getData.err) {
          setFormShipping(initialStateShipping);
          setIsLoadingErrorData(true);
          dispatch(warningErrorAction(t(getData.message === "Load failed" ? 'Text_ConnectionFailied' : 'Text_InvalidCredentials')));
        } else if (isMounted) {
          setFormShipping(getData);
        }  
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData')));
        setIsLoadingData(false);
      }  
    };

    if (id!=="0"){
      if (formShipping.id===""){
        fetchDataShipping(); 
        setIsLoadingData(false);
      }
    }else{
      setFormShipping(initialStateShipping);
      setFormShipping(formShipping => ({
        ...formShipping,
        defaultStore_id:defaultStore.id,
        useraccount_id:useraccount_id,
        defaultformatnumber:defaulttypesdi.id
      }));
      setIsLoadingData(false);
    }
    return () => { isMounted = false };
  }, [ENDPOINT,dispatch,id,t,token,formShipping.id,defaulttypesdi,defaultStore.id,useraccount_id,isLoadingData]);  

 return (
      <>
        {showModalShippingsSettings ? ( 
          <div className="container-fluid">
            <Box sx={{ bgcolor: '#ffffff', width: '1200px'}}>
              <form className="createform" onSubmit={handleCloseDataEditShipping}>  
                <div className="row"> 
                  <div className="col-xl-12 mb-4"> 
                    <div  className="card border-info shadow">
                      <div className="card-body"> 
                        <div className="row no-gutters align-items-center">
                          <div className="col mr-2">
                            <div id="myDIV">
                              <h1 className="h9NewStyle mb-2">
                                  {isLoadingErrorData?
                                  <i className="fas fa-solid fa-exclamation fa-2x text-danger"/>:
                                  (isLoadingData?
                                  <CircularProgress sx={{ color : "#f6c23e"}}/>:
                                  <i className="fas fa-solid fa-truck text-warning"></i>)} 
                                  &nbsp;&nbsp;{id==="0"?t('Text_SHIPPING_New'):t('Text_SHIPPING_Edit')+': '+formShipping.ownshipping_id}
                              </h1>
                              <div>
                              {isLoadingData?
                                <CircularProgress sx={{ color : "#f6c23e"}}/>:
                                  <>
                                    <button type="button" className="buttonCreate" onClick={handleSubmitDataEditShipping}>
                                      {id==="0"?t('Button_Create'):t('Button_Update')}
                                    </button><> </>
                                    <button type="button" className="buttonBack" onClick={handleCloseDataEditShipping}>{t('Button_Cancel')}</button>
                                  </>
                                }
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {!isLoadingData ?
                <>
                  <div className="row"> 
                    <div className="col-xl-12 mb-4"> 
                    <div  className="card border-info shadow">
                        <div className="card-body"> 
                          <div className="row no-gutters align-items-center">
                            <div className="row p-2"> 
                              <div className="form-group">   
                                <label className="text-primary">{t('Text_SHIPPING_NUMBER')} :</label>
                                &nbsp;&nbsp;&nbsp;&nbsp; 
                                <TextField
                                  id="number_shipping" 
                                  name="number_shipping"
                                  value={formShipping.number_shipping}
                                  inputProps={{ style: {width: i18n.language==='es'?170:170}, maxLength: 20 }}
                                  label={errorsForm.number_shipping!=null?t(errorsForm.number_shipping):t('Text_SHIPPING_NUMBER')}
                                  placeholder={t('Text_SHIPPING_NUMBER')}
                                  variant="standard"
                                  onBlur={handleBlurDataEditShipping}
                                  onChange={handleChangeDataEditShipping}
                                />
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                  <label className="text-primary">{t('Text_SHIPPING_Date')} :</label>
                                  &nbsp;&nbsp;&nbsp;&nbsp; 
                                  <DesktopDatePicker
                                    id="date_shipping" 
                                    name="date_shipping"
                                    value={ formShipping.date_shipping!== null ? new Date(formShipping.date_shipping):null}
                                    format={i18n.language==="es"?"dd/MM/yyyy":"MM/dd/yyyy"}
                                    inputProps={{ style: {width: 200}, maxLength: 100 }}
                                    label={errorsForm.date_shipping!=null?t(errorsForm.date_shipping):t('Text_SHIPPING_Date')}
                                    onBlur={handleBlurSelectDate}
                                    onChange={handleChangeSelectDate}
                                    slotProps={{
                                      textField: {
                                        placeholder: i18n.language==="es"?"dd/MM/yyyy":"MM/dd/yyyy",
                                        sx: {
                                          maxLength: 100,
                                          width: 200
                                        
                                        }
                                      },
                                    }}
                                  />
                                </LocalizationProvider>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <label className="text-primary">{t('Text_SHIPPING_LICENSEPLATE')} :</label>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <TextField
                                  id="vehiclestore_licenseplate" 
                                  name="vehiclestore_licenseplate"
                                  value={formShipping.vehiclestore_licenseplate}
                                  inputProps={{ style: {width: i18n.language==='es'?230:230}, maxLength: 50 }}
                                  label={errorsForm.vehiclestore_licenseplate!=null?t(errorsForm.vehiclestore_licenseplate):t('Placeholder_SHIPPING_LICENSEPLATE')}
                                  placeholder={t('Placeholder_SHIPPING_LICENSEPLATE')}
                                  variant="standard"
                                  onBlur={handleBlurDataEditShipping}
                                  onChange={handleChangeDataEditShipping}
                                />
                                &nbsp;&nbsp;
                                <IconButton  size="small"  style={{color:"#e74a3b", fontSize: 12}} onClick={deleteLicensePlate}>
                                    <DeleteIcon/>
                                </IconButton>
                                &nbsp;&nbsp;
                                {roles[0].listmenus.find((key)=>(key.name==='SubMenu_Form_View_Stores'))&&
                                    <IconButton  size="small"  style={{color:"#4e73df", fontSize: 12}} onClick={searchVehicles}>
                                        <SearchIcon/>
                                    </IconButton>
                                }
                              </div>
                            </div>
                          </div>
                          <div className="row p-2"> 
                            <div className="form-group">  
                              <label className="text-primary">{t('Text_SHIPPING_DEST_TRAS')} :</label>
                              &nbsp;&nbsp;&nbsp;&nbsp; 
                              <TextField
                                id="dest_tras_tradename" 
                                name="dest_tras_tradename"
                                value={formShipping.dest_tras_tradename}
                                inputProps={{ style: {width: i18n.language==='es'?595:400}, maxLength: 100 }}
                                label={errorsForm.dest_tras_tradename!=null?t(errorsForm.dest_tras_tradename):t('Placeholder_SHIPPING_DEST_TRAS')}
                                placeholder={t('Placeholder_SHIPPING_DEST_TRAS')}
                                variant="standard"
                                onBlur={handleBlurDataEditShipping}
                                onChange={handleChangeDataEditShipping}
                              />
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              <label className="text-primary">{t('Text_SHIPPING_CIF')} :</label>
                              &nbsp;&nbsp;&nbsp;&nbsp; 
                              <TextField
                                id="dest_tras_documentid" 
                                name="dest_tras_documentid"
                                value={formShipping.dest_tras_documentid}
                                inputProps={{ style: {width: i18n.language==='es'?230:230}, maxLength: 100 }}
                                label={errorsForm.dest_tras_documentid!=null?t(errorsForm.dest_tras_documentid):t('Placeholder_SHIPPING_CIF')}
                                placeholder={t('Placeholder_SHIPPING_CIF')}
                                variant="standard"
                                onBlur={handleBlurDataEditShipping}
                                onChange={handleChangeDataEditShipping}
                              />
                              &nbsp;&nbsp;
                              <IconButton  size="small"  style={{color:"#e74a3b", fontSize: 12}} onClick={deleteStoreDestTras}>
                                <DeleteIcon/>
                              </IconButton>
                              &nbsp;&nbsp;
                              {roles[0].listmenus.find((key)=>(key.name==='SubMenu_Shipping_View_Stores'))&&
                                <IconButton  size="small"  style={{color:"#4e73df", fontSize: 12}} onClick={searchStoreDestTras}>
                                  <SearchIcon/>
                                </IconButton>
                              }  
                              <label className="text-primary">{t('Text_SHIPPING_STREET')} :</label>
                              &nbsp;&nbsp;&nbsp;&nbsp; 
                              <TextField
                                id="dest_tras_street" 
                                name="dest_tras_street"
                                value={formShipping.dest_tras_street}
                                inputProps={{ style: {width: i18n.language==='es'?988:988}, maxLength: 255 }}
                                label={errorsForm.dest_tras_street!=null?t(errorsForm.dest_tras_street):t('Placeholder_SHIPPING_STREET')}
                                placeholder={t('Placeholder_SHIPPING_STREET')}
                                variant="standard"
                                onBlur={handleBlurDataEditShipping}
                                onChange={handleChangeDataEditShipping}
                              />
                               &nbsp;&nbsp;
                              <IconButton  size="small"  style={{color:"#e74a3b", fontSize: 12}} onClick={deleteAddresStoreDestTras}>
                                <DeleteIcon/>
                              </IconButton>
                              &nbsp;&nbsp;
                              {roles[0].listmenus.find((key)=>(key.name==='SubMenu_Shipping_View_Stores'))&&
                                <IconButton  size="small"  style={{color:formShipping.dest_tras_documentid===""?"#858796":"#4e73df", fontSize: 12}} disabled={formShipping.dest_tras_documentid===""} onClick={searchAddresStoreDestTras}>
                                  <SearchIcon/>
                                </IconButton>
                              } 
                            </div>
                          </div>

                          <div className="row p-2"> 
                            <div className="form-group">  
                              <label className="text-primary">{t('Text_SHIPPING_OPT_TRAS')} :</label>
                              &nbsp;&nbsp;&nbsp;&nbsp; 
                              <TextField
                                id="opt_tras_tradename" 
                                name="opt_tras_tradename"
                                value={formShipping.opt_tras_tradename}
                                inputProps={{ style: {width: i18n.language==='es'?591:400}, maxLength: 100 }}
                                label={errorsForm.opt_tras_tradename!=null?t(errorsForm.opt_tras_tradename):t('Placeholder_SHIPPING_OPT_TRAS')}
                                placeholder={t('Placeholder_SHIPPING_OPT_TRAS')}
                                variant="standard"
                                onBlur={handleBlurDataEditShipping}
                                onChange={handleChangeDataEditShipping}
                              />
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              <label className="text-primary">{t('Text_SHIPPING_CIF')} :</label>
                              &nbsp;&nbsp;&nbsp;&nbsp; 
                              <TextField
                                id="opt_tras_documentid" 
                                name="opt_tras_documentid"
                                value={formShipping.opt_tras_documentid}
                                inputProps={{ style: {width: i18n.language==='es'?230:230}, maxLength: 100 }}
                                label={errorsForm.opt_tras_documentid!=null?t(errorsForm.opt_tras_documentid):t('Placeholder_SHIPPING_CIF')}
                                placeholder={t('Placeholder_SHIPPING_CIF')}
                                variant="standard"
                                onBlur={handleBlurDataEditShipping}
                                onChange={handleChangeDataEditShipping}
                              />
                              &nbsp;&nbsp;
                              <IconButton  size="small"  style={{color:"#e74a3b", fontSize: 12}} onClick={deleteStoreOptTras}>
                                <DeleteIcon/>
                              </IconButton>
                              &nbsp;&nbsp;
                              {roles[0].listmenus.find((key)=>(key.name==='SubMenu_Shipping_View_Stores'))&&
                                <IconButton  size="small"  style={{color:"#4e73df", fontSize: 12}} onClick={searchStoreOptTras}>
                                  <SearchIcon/>
                                </IconButton>
                              }  
                              <label className="text-primary">{t('Text_SHIPPING_STREET')} :</label>
                              &nbsp;&nbsp;&nbsp;&nbsp; 
                              <TextField
                                id="opt_tras_street" 
                                name="opt_tras_street"
                                value={formShipping.opt_tras_street}
                                inputProps={{ style: {width: i18n.language==='es'?988:988}, maxLength: 255 }}
                                label={errorsForm.opt_tras_street!=null?t(errorsForm.opt_tras_street):t('Placeholder_SHIPPING_STREET')}
                                placeholder={t('Placeholder_SHIPPING_STREET')}
                                variant="standard"
                                onBlur={handleBlurDataEditShipping}
                                onChange={handleChangeDataEditShipping}
                              />
                               &nbsp;&nbsp;
                              <IconButton  size="small"  style={{color:"#e74a3b", fontSize: 12}} onClick={deleteAddresStoreOptTras}>
                                <DeleteIcon/>
                              </IconButton>
                              &nbsp;&nbsp;
                              {roles[0].listmenus.find((key)=>(key.name==='SubMenu_Shipping_View_Stores'))&&
                                <IconButton  size="small"  style={{color:formShipping.opt_tras_documentid===""?"#858796":"#4e73df", fontSize: 12}} disabled={formShipping.opt_tras_documentid===""} onClick={searchAddresStoreOptTras}>
                                  <SearchIcon/>
                                </IconButton>
                              } 
                            </div>
                          </div>

                          <div className="row p-2"> 
                            <div className="form-group">  
                              <label className="text-primary">{t('Text_SHIPPING_REL_TRANS')} :</label>
                              &nbsp;&nbsp;&nbsp;&nbsp; 
                              <TextField
                                id="rel_trans_tradename" 
                                name="rel_trans_tradename"
                                value={formShipping.rel_trans_tradename}
                                inputProps={{ style: {width: i18n.language==='es'?642:642}, maxLength: 100 }}
                                label={errorsForm.rel_trans_tradename!=null?t(errorsForm.rel_trans_tradename):t('Placeholder_SHIPPING_REL_TRANS')}
                                placeholder={t('Placeholder_SHIPPING_REL_TRANS')}
                                variant="standard"
                                onBlur={handleBlurDataEditShipping}
                                onChange={handleChangeDataEditShipping}
                              />
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              <label className="text-primary">{t('Text_SHIPPING_CIF')} :</label>
                              &nbsp;&nbsp;&nbsp;&nbsp; 
                              <TextField
                                id="rel_trans_documentid" 
                                name="rel_trans_documentid"
                                value={formShipping.rel_trans_documentid}
                                inputProps={{ style: {width: i18n.language==='es'?230:230}, maxLength: 100 }}
                                label={errorsForm.rel_trans_documentid!=null?t(errorsForm.rel_trans_documentid):t('Placeholder_SHIPPING_CIF')}
                                placeholder={t('Placeholder_SHIPPING_CIF')}
                                variant="standard"
                                onBlur={handleBlurDataEditShipping}
                                onChange={handleChangeDataEditShipping}
                              />
                              &nbsp;&nbsp;
                              <IconButton  size="small"  style={{color:"#e74a3b", fontSize: 12}} onClick={deleteStoreRelTrans}>
                                <DeleteIcon/>
                              </IconButton>
                              &nbsp;&nbsp;
                              {roles[0].listmenus.find((key)=>(key.name==='SubMenu_Shipping_View_Stores'))&&
                                <IconButton  size="small"  style={{color:"#4e73df", fontSize: 12}} onClick={searchStoreRelTrans}>
                                  <SearchIcon/>
                                </IconButton>
                              }  
                              <label className="text-primary">{t('Text_SHIPPING_REL_TRANS_STREET')} :</label>
                              &nbsp;&nbsp;&nbsp;&nbsp; 
                              <TextField
                                id="rel_trans_street" 
                                name="rel_trans_street"
                                value={formShipping.rel_trans_street}
                                inputProps={{ style: {width: i18n.language==='es'?957:988}, maxLength: 255 }}
                                label={errorsForm.rel_trans_street!=null?t(errorsForm.rel_trans_street):t('Placeholder_SHIPPING_REL_TRANS_STREET')}
                                placeholder={t('Placeholder_SHIPPING_REL_TRANS_STREET')}
                                variant="standard"
                                onBlur={handleBlurDataEditShipping}
                                onChange={handleChangeDataEditShipping}
                              />
                               &nbsp;&nbsp;
                              <IconButton  size="small"  style={{color:"#e74a3b", fontSize: 12}} onClick={deleteAddresStoreRelTrans}>
                                <DeleteIcon/>
                              </IconButton>
                              &nbsp;&nbsp;
                              {roles[0].listmenus.find((key)=>(key.name==='SubMenu_Shipping_View_Stores'))&&
                                <IconButton  size="small"  style={{color:formShipping.rel_trans_documentid===""?"#858796":"#4e73df", fontSize: 12}} disabled={formShipping.rel_trans_documentid===""} onClick={searchAddresStoreRelTrans}> 
                                   <SearchIcon/>
                                </IconButton>
                              } 
                              <label className="text-primary">{t('Text_SHIPPING_DRIVER')} :</label>
                              &nbsp;&nbsp;&nbsp;&nbsp; 
                              <TextField
                                id="vehiclestore_defaultdriver" 
                                name="vehiclestore_defaultdriver"
                                value={formShipping.vehiclestore_defaultdriver}
                                inputProps={{ style: {width: i18n.language==='es'?646:400}, maxLength: 100 }}
                                label={errorsForm.vehiclestore_defaultdriver!=null?t(errorsForm.vehiclestore_defaultdriver):t('Placeholder_SHIPPING_DRIVER')}
                                placeholder={t('Placeholder_SHIPPING_DRIVER')}
                                variant="standard"
                                onBlur={handleBlurDataEditShipping}
                                onChange={handleChangeDataEditShipping}
                              />
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              <label className="text-primary">{t('Text_SHIPPING_NIF')} :</label>
                              &nbsp;&nbsp;&nbsp;&nbsp; 
                              <TextField
                                id="vehiclestore_documentid" 
                                name="vehiclestore_documentid"
                                value={formShipping.vehiclestore_documentid}
                                inputProps={{ style: {width: i18n.language==='es'?230:230}, maxLength: 100 }}
                                label={errorsForm.vehiclestore_documentid!=null?t(errorsForm.vehiclestore_documentid):t('Placeholder_SHIPPING_NIF')}
                                placeholder={t('Placeholder_SHIPPING_NIF')}
                                variant="standard"
                                onBlur={handleBlurDataEditShipping}
                                onChange={handleChangeDataEditShipping}
                              />
                              &nbsp;&nbsp;
                              <IconButton  size="small"  style={{color:"#e74a3b", fontSize: 12}} onClick={deleteADriverStoreRelTrans}>
                                <DeleteIcon/>
                              </IconButton>
                             
                            </div>
                          </div>
                          <div className="row p-2"> 
                            <div className="col-4 text-right">
                            </div> 
                            <div className="col-1 text-right">
                              <label className="text-primary">{t('Text_SHIPPING_WEIGHT')} :</label>
                            </div>  
                            <div className="col-2 text-left">
                              <NumericFormat 
                                thousandSeparator={','} 
                                decimalSeparator={'.'} 
                                decimalScale= {0}
                                fixedDecimalScale= {true}
                                allowNegative= {false}
                                isAllowed={withValueCap}
                                id="weight" 
                                name="weight"
                                suffix={" kg"}
                                value={formShipping.weight}
                                style={{width:'100%', textAlign:'right', paddingRight:5}} 
                                onBlur={handleBlurDataEditShippingWeight}
                                onChange={handleChangeDataEditShippingWeight}
                              />  
                            </div>
                            <div className="col-1 text-right">
                              <label className="text-primary">{t('Text_SHIPPING_WEIGHT_PRICE')} :</label>
                            </div>  
                            <div className="col-2 text-left">
                              <NumericFormat 
                                thousandSeparator={','} 
                                decimalSeparator={'.'} 
                                decimalScale= {0}
                                fixedDecimalScale= {true}
                                allowNegative= {false}
                                isAllowed={withValueCap}
                                id="price_weight" 
                                name="price_weight"
                                suffix={" €"}
                                value={formShipping.price_weight}
                                style={{width:'100%',  textAlign:'right', paddingRight:5}} 
                                onBlur={handleBlurDataEditShippingWeight}
                                onChange={handleChangeDataEditShippingWeight}
                              />
                            </div>  
                            
                            <div className="col-2 text-right">
                              <NumericFormat 
                                thousandSeparator={','} 
                                decimalSeparator={'.'} 
                                decimalScale= {0}
                                fixedDecimalScale= {true}
                                allowNegative= {false}
                                isAllowed={withValueCap}
                                id="totalweight" 
                                name="totalweight"
                                suffix={" €"}
                                value={formShipping.price_weight*formShipping.weight}
                                style={{width:'100%', textAlign:'right', paddingRight:5}} 
                                disabled
                              />
                            </div>
                          </div>
                          <div className="row p-2"> 
                            <div className="col-1 text-right">
                              <label className="text-primary">{t('Text_SHIPPING_SERVICE')}</label>
                            </div>
                            <div className="col-9 text-left">
                              <TextField
                                id="service_1" 
                                name="service_1"
                                value={formShipping.service_1}
                                style={{width:'100%', textAlign:'right', paddingRight:5}} 
                                inputProps={{ maxLength: 100 }}
                                label={errorsForm.service_1!=null?t(errorsForm.service_1):t('Placeholder_SHIPPING_SERVICE')}
                                placeholder={t('Placeholder_SHIPPING_SERVICE')}
                                variant="standard"
                                onBlur={handleBlurDataEditShipping}
                                onChange={handleChangeDataEditShipping}
                              /> 
                            </div>  
                            <div className="col-2 text-right">
                              <NumericFormat 
                                thousandSeparator={','} 
                                decimalSeparator={'.'} 
                                decimalScale= {0}
                                fixedDecimalScale= {true}
                                allowNegative= {false}
                                isAllowed={withValueCap}
                                id="price_service_1" 
                                name="price_service_1"
                                suffix={" €"}
                                value={formShipping.price_service_1}
                                style={{width:'100%', textAlign:'right', paddingRight:5}} 
                                onBlur={handleBlurDataEditShippingWeight}
                                onChange={handleChangeDataEditShippingWeight}
                              />
                            </div> 
                          </div>
                          <div className="row p-2"> 
                            <div className="col-1 text-right">
                            </div>
                            <div className="col-9 text-left">
                              <TextField
                                id="service_2" 
                                name="service_2"
                                value={formShipping.service_2}
                                style={{width:'100%', textAlign:'right', paddingRight:5}} 
                                inputProps={{ maxLength: 100 }}
                                label={errorsForm.service_2!=null?t(errorsForm.service_2):t('Placeholder_SHIPPING_SERVICE')}
                                placeholder={t('Placeholder_SHIPPING_SERVICE')}
                                variant="standard"
                                onBlur={handleBlurDataEditShipping}
                                onChange={handleChangeDataEditShipping}
                              /> 
                            </div>  
                            <div className="col-2 text-right">
                              <NumericFormat 
                                thousandSeparator={','} 
                                decimalSeparator={'.'} 
                                decimalScale= {0}
                                fixedDecimalScale= {true}
                                allowNegative= {false}
                                isAllowed={withValueCap}
                                id="price_service_2" 
                                name="price_service_2"
                                suffix={" €"}
                                value={formShipping.price_service_2}
                                style={{width:'100%', textAlign:'right', paddingRight:5}} 
                                onBlur={handleBlurDataEditShippingWeight}
                                onChange={handleChangeDataEditShippingWeight}
                              />
                            </div> 
                          </div>
                          <div className="row p-2"> 
                            <div className="col-1 text-right">
                            </div>
                            <div className="col-9 text-left">
                              <TextField
                                id="service_3" 
                                name="service_3"
                                value={formShipping.service_3}
                                style={{width:'100%', textAlign:'right', paddingRight:5}} 
                                inputProps={{ maxLength: 100 }}
                                label={errorsForm.service_3!=null?t(errorsForm.service_3):t('Placeholder_SHIPPING_SERVICE')}
                                placeholder={t('Placeholder_SHIPPING_SERVICE')}
                                variant="standard"
                                onBlur={handleBlurDataEditShipping}
                                onChange={handleChangeDataEditShipping}
                              /> 
                            </div>  
                            <div className="col-2 text-right">
                              <NumericFormat 
                                thousandSeparator={','} 
                                decimalSeparator={'.'} 
                                decimalScale= {0}
                                fixedDecimalScale= {true}
                                allowNegative= {false}
                                isAllowed={withValueCap}
                                id="price_service_3" 
                                name="price_service_3"
                                suffix={" €"}
                                value={formShipping.price_service_3}
                                style={{width:'100%', textAlign:'right', paddingRight:5}} 
                                onBlur={handleBlurDataEditShippingWeight}
                                onChange={handleChangeDataEditShippingWeight}
                              />
                            </div> 
                          </div> 

                           <div className="row p-2"> 
                            <div className="col-1 text-right">
                              <label className="text-primary">{t('Text_SHIPPING_DESTINATION')}</label>
                            </div>
                            <div className="col-7 text-left">
                              <TextField
                                id="destine_street" 
                                name="destine_street"
                                value={formShipping.destine_street}
                                style={{width:'100%', textAlign:'right', paddingRight:5}} 
                                inputProps={{ maxLength: 100 }}
                                label={errorsForm.destine_street!=null?t(errorsForm.destine_street):t('Placeholder_SHIPPING_DESTINATION')}
                                placeholder={t('Placeholder_SHIPPING_DESTINATION')}
                                variant="standard"
                                onBlur={handleBlurDataEditShipping}
                                onChange={handleChangeDataEditShipping}
                              />  
                            </div>  
                            <div className="col-1 text-right">
                              <label className="text-primary">{t('Text_SHIPPING_TAX')} :</label>
                            </div> 
                            <div className="col-2 text-right">
                              <NumericFormat 
                                thousandSeparator={','} 
                                decimalSeparator={'.'} 
                                decimalScale= {2}
                                fixedDecimalScale= {true}
                                allowNegative= {false}
                                isAllowed={withValueCap1000}
                                id="tax" 
                                name="tax"
                                suffix={" %"}
                                value={formShipping.tax}
                                style={{width:'100%', textAlign:'right', paddingRight:5}} 
                                onBlur={handleBlurDataEditShippingWeight}
                                onChange={handleChangeDataEditShippingWeight}
                              />
                            </div> 
                            <div className="col-1 text-right">
                              <Checkbox  
                                style={{color:"#4e73df", fontSize: 20}} 
                                id="taxincluded"
                                name="taxincluded"
                                checked={formShipping.taxincluded} 
                                onChange={handleChangeTaxIncluded}  
                              /> 
                            </div>
                          </div>  
                          <div className="row p-2"> 
                            <div className="col-9 text-right">
                            </div>
                            <div className="col-1 text-right">
                              <label className="text-primary">{t('Text_SHIPPING_TOTAL')} :</label>
                            </div>
                            <div className="col-2 text-right">
                              <NumericFormat 
                                thousandSeparator={','} 
                                decimalSeparator={'.'} 
                                decimalScale= {0}
                                fixedDecimalScale= {true}
                                allowNegative= {false}
                                isAllowed={withValueCap}
                                id="totalamount" 
                                name="totalamount"
                                suffix={" €"}
                                value={((formShipping.weight*formShipping.price_weight)+formShipping.price_service_1+formShipping.price_service_2+formShipping.price_service_3)
                                    *(formShipping.taxincluded?1:(1+(formShipping.tax/100)))}
                                style={{width:'100%', textAlign:'right', paddingRight:5}} 
                                disabled
                              />
                            </div> 
                          </div>  
                          <div className="row p-2"> 
                            <div className="col-2 text-left">
                              <label className="text-primary">{t('Text_SHIPPING_OBSERVATIONS')} :</label>
                            </div> 
                            <div className="col-10 text-left">
                              <TextField
                                id="observations" 
                                name="observations"
                                value={formShipping.observations}
                                style={{width:'100%', textAlign:'right', paddingRight:5}} 
                                inputProps={{ maxLength: 100 }}
                                label={errorsForm.observations!=null?t(errorsForm.observations):t('Placeholder_SHIPPING_OBSERVATIONS')}
                                placeholder={t('Placeholder_SHIPPING_OBSERVATIONS')}
                                variant="standard"
                                onBlur={handleBlurDataEditShipping}
                                onChange={handleChangeDataEditShipping}
                              />            
                            </div> 
                          </div> 
                          <br/>
 

                         

                          <Grid container spacing={4} justify="center">
                              <Grid item xs={4} sm={4} md={4} key={1}>
                                <Card sx={{ width: 250 }}>
                                  <CardMedia
                                    sx={{ height: 150 }}
                                    image={(formShipping.signature_driver!==undefined && formShipping.signature_driver!=="")?formShipping.signature_driver:image1}
                                    title={t("Text_Signature_driver")}
                                  />
                                  <CardContent>
                                    <Typography style={{color:"#067330", fontSize: 14, textAlign:'center'}}>
                                      {t( "Text_Signature_driver")}
                                    </Typography>
                                  </CardContent>
                                  <CardActions>
                                    <Button size="small" onClick={(e) => selectModalSignature(1)}>{t('Button_Sign')}</Button>
                                    <Button size="small" onClick={(e) => deleteSignature(1)}>{t('Button_Delete')}</Button>
                                  </CardActions>
                                </Card>
                              </Grid>
                              <Grid item xs={4} sm={4} md={4} key={2}>
                                <Card sx={{ width: 250 }}>
                                  <CardMedia
                                    sx={{ height: 150 }}
                                    image={(formShipping.signature_charger!==undefined && formShipping.signature_charger!=="")?formShipping.signature_charger:image1}
                                    title={t("Text_Signature_charger")}
                                  />
                                  <CardContent>
                                    <Typography style={{color:"#067330", fontSize: 14, textAlign:'center'}}>
                                      {t("Text_Signature_charger")}
                                    </Typography>
                                  </CardContent>
                                  <CardActions>
                                    <Button size="small" onClick={(e) => selectModalSignature(2)}>{t('Button_Sign')}</Button>
                                    <Button size="small" onClick={(e) => deleteSignature(2)}>{t('Button_Delete')}</Button>
                                  </CardActions>
                                </Card>
                              </Grid> 
                            </Grid>
                        </div>   
                      </div>
                    </div>
                </div>
              </>:
                <Loading isLoadingData={isLoadingData}/>
              }
            </form>
          </Box>
          <ShippingSearchStores
            showModalSearchStores={showModalSearchStores}
            setShowModalSearchStores={setShowModalSearchStores}
            selectStores={tetxtSearch}
            setFormShipping={setFormShipping}
          />
          <ShippingSearchStoresAddresses
            showModalSearchStoresAddress={showModalSearchStoresAddress}
            setShowModalSearchStoresAddress={setShowModalSearchStoresAddress}
            formShipping={formShipping}
            setFormShipping={setFormShipping}
            selectStores={tetxtSearch}
          />
          <ShippingSearchVehicles
            showModalSearchVehicles={showModalSearchVehicles}
            setShowModalSearchVehicles={setShowModalSearchVehicles}
            setFormShipping={setFormShipping}
            formShipping={formShipping}
          /> 
          <br/>
          <Dialog open={openSignDriver} onClose={handleCancelSignDriver}>
            <DialogTitle style={{color:"#4e73df", textAlign:'center'}}>{t('Text_Signature_driver')} </DialogTitle>
              <DialogContent>
                <div  style={{border:'2px solid #4e73df', width: 250, height: 150 }}>
                  <SignatureCanvas
                    penColor="black"
                    canvasProps={{ width: 250, height: 150, className: 'sigCanvas' }}
                    ref={dataOptTras=>setSignDriverCanvas(dataOptTras)}
                  />
                </div>
              </DialogContent>
              <DialogActions>
                <Button style={{color:"#4e73df",fontSize: 16 }} onClick={handleCancelSignDriver}>{t('Button_Cancel')}</Button>
                <Button style={{color:"#4e73df",fontSize: 16 }} onClick={handleDeleteSignDriver}>{t('Button_Delete')}</Button>
                <Button variant="contained" style={{color:"#ffffff", background:"#4e73df",fontSize: 16}} onClick={handleSubmitSignDriver}>{t('Button_Sign')}</Button>
              </DialogActions>
          </Dialog>

          <Dialog open={openSignCharger} onClose={handleCancelSignCharger}>
            <DialogTitle style={{color:"#4e73df", textAlign:'center'}}>{t('Text_Signature_charger')} </DialogTitle>
              <DialogContent>
                <div  style={{border:'2px solid #4e73df', width: 250, height: 150 }}>
                  <SignatureCanvas
                    penColor="black"
                    canvasProps={{ width: 250, height: 150, className: 'sigCanvas' }}
                    ref={dataDestTras=>setSignChargerCanvas(dataDestTras)}
                  />
                </div>
              </DialogContent>
              <DialogActions>
                <Button style={{color:"#4e73df",fontSize: 16 }} onClick={handleCancelSignCharger}>{t('Button_Cancel')}</Button>
                <Button style={{color:"#4e73df",fontSize: 16 }} onClick={handleDeleteSignCharger}>{t('Button_Delete')}</Button>
                <Button variant="contained" style={{color:"#ffffff", background:"#4e73df",fontSize: 16}} onClick={handleSubmitSignCharger}>{t('Button_Sign')}</Button>
              </DialogActions>
          </Dialog> 
        </div>
      //* End Page Content *//
    ) :  null }
  </>
  )    
}

ShippingEditOrCreate.propTypes = {
  id: PropTypes.string.isRequired,
  showModalShippingsSettings: PropTypes.bool.isRequired
};

export default ShippingEditOrCreate;