import React, {  useEffect, useCallback, useState } from 'react';
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next" 
import { SwipeableDrawer } from '@mui/material';
import { Checkbox} from  '@mui/material';
import { Container, Box } from '@mui/material';
import { TextField } from '@mui/material'; 
import CircularProgress from '@mui/material/CircularProgress';
import "css/generalSettings.css"; 
import { successErrorAction,warningErrorAction } from "actions/ErrorActions";
import { helpHttp } from "service/HelpHttp";
import TableSortingListMenu from './SortingTablesListMenu';  
import PropTypes from 'prop-types'; 

const initialFiltersListMenu= {
  "idFrom":"",
  "idTo":"",
  "codeFrom":"",
  "codeTo":"",
  "nameesFrom":"",
  "nameesTo":"",
  "nameenFrom":"",
  "nameenTo":"",
  "codeHexFrom":"",
  "codeHexTo":"",
  "codeCotGroupFrom":"",
	"codeCotGroupTo":"",
  "code_iso2From":"",
  "code_iso2To":"",
  "code_iso3From":"",
  "code_iso3To":"",
  "extphoneFrom":"",
  "extphoneTo":"",
  "extinternetFrom":"",
  "extinternetTo":"",
  "isEnabled":true,
  "isNotEnabled":true
};

const initialStateListMenu = [{
  "id" : "",
  "indexrowlistmenu" : 0,
  "name" : "",
  "namees" : "",
  "nameen" : "",
  "enabled":false, 
}];

const headCellsListMenu = [
  {
    id: 'id',
    numeric: false,
    disablePadding: false,
    label: 'Text_Code',
    canOrder:true,
  },
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Text_ListMenu',
    canOrder:true,
  },
  {
    id: 'namees',
    numeric: false,
    disablePadding: false,
    label: 'Text_ListMenu_Namees',
    canOrder:true,
  },
  {
    id: 'nameen',
    numeric: false,
    disablePadding: false,
    label: 'Text_ListMenu_Nameen',
    canOrder:true,
  },
  {
    id: 'enabled',
    numeric: false,
    disablePadding: false,
    label: 'Text_Enabled',
    canOrder:false,
  }
];

const ListMenuSettings = ({ openListMenu,setOpenListMenu,rowEditRoleUser,setRowEdit}) => {
  const {t,i18n} = useTranslation(['listDataGeneralSettings']);
  const token = useSelector((state) => state.loginUser.token); 
  const roles = useSelector((state) => state.loginUser.roles);  
  const ENDPOINT = useSelector((state) => state.variablesUser.ENDPOINT); 
  const LIMITPAGESIZE = useSelector((state) => state.variablesUser.LIMITPAGESIZE); 
  const [dataListMenu, setDataListMenu] = useState(initialStateListMenu);
  const [formListMenu, setFormListMenu] = useState(initialStateListMenu[0]);
  const [filtersListMenu, setFiltersListMenu]= useState(initialFiltersListMenu);
  const [applyFiltersListMenu, setApplyFiltersListMenu]= useState(initialFiltersListMenu);
  const [numTotRegLitsMenuByRoleUser,setNumTotRegLitsMenuByRoleUser]= useState(0);
  const [numTotRegFilter,setNumTotRegFilter]= useState(0);
  const [errorsFiltersListMenu, setErrorsFiltersListMenu] = useState({});
  const [inputTextListMenu, setInputTextListMenu] = useState("");
  const [openEditFilterListMenu,setOpenEditFilterListMenu]= useState(false);
  const [rowEditListMenu,setRowEditListMenu]= useState(initialStateListMenu[0]);
  const [isLoadingListMenu, setIsLoadingListMenu] = useState(true);
  const [isLoadingErrorListMenu, setIsLoadingErrorListMenu] = useState(false); 
  const dispatch = useDispatch();
  const [activeOffset, setActiveOffset]= useState(false);
  const [currencyOffset, setCurrencyOffset] = useState(1);
  const [rangeOffset, setRangeOffset] = useState([{}]);
  
  const keyPress = useCallback(
    e => {
      if (e.key === 'Escape' && openListMenu) {
        setDataListMenu(initialStateListMenu);
        setFormListMenu(initialStateListMenu[0]);
        setFiltersListMenu(initialFiltersListMenu);
        setApplyFiltersListMenu(initialFiltersListMenu);
        setNumTotRegLitsMenuByRoleUser(0);
        setNumTotRegFilter(0);
        setErrorsFiltersListMenu({});
        setInputTextListMenu("");
        setOpenEditFilterListMenu(false);
        setRowEditListMenu(initialStateListMenu[0]);
        setIsLoadingListMenu(false);
        setIsLoadingErrorListMenu(false);
        setActiveOffset(false);
        setCurrencyOffset(1);
        setRangeOffset([{}]);
        setRowEdit(initialStateListMenu[0]);
        setOpenListMenu(false);
      }
    },
    [ setDataListMenu,
      setFormListMenu,
      setFiltersListMenu,
      setApplyFiltersListMenu,
      setNumTotRegLitsMenuByRoleUser,
      setNumTotRegFilter,
      setErrorsFiltersListMenu,
      setInputTextListMenu,
      setOpenEditFilterListMenu,
      setRowEditListMenu,
      setIsLoadingListMenu,
      setIsLoadingErrorListMenu,
      setActiveOffset,
      setCurrencyOffset,
      setRangeOffset,
      openListMenu,
      setOpenListMenu,
      setRowEdit]
  );

  const closeModalListMenuSettings = () => {
    setDataListMenu(initialStateListMenu);
    setFormListMenu(initialStateListMenu[0]);
    setFiltersListMenu(initialFiltersListMenu);
    setApplyFiltersListMenu(initialFiltersListMenu);
    setNumTotRegLitsMenuByRoleUser(0);
    setNumTotRegFilter(0);
    setErrorsFiltersListMenu({});
    setInputTextListMenu("");
    setOpenEditFilterListMenu(false);
    setRowEditListMenu(initialStateListMenu[0]);
    setIsLoadingListMenu(false);
    setIsLoadingErrorListMenu(false);
    setActiveOffset(false);
    setCurrencyOffset(1);
    setRangeOffset([{}]);
    setRowEdit(initialStateListMenu[0]);
    setOpenListMenu(false);
  };


  const openEditFiltersListMenu = () => {
    setFiltersListMenu(applyFiltersListMenu);
    setOpenEditFilterListMenu(!openEditFilterListMenu);
  };

  const handleSubmitFilterEditListMenu = () => {
    setErrorsFiltersListMenu(validateFormFilterListMenu());
    if (Object.keys(errorsFiltersListMenu).length === 0) {
      setApplyFiltersListMenu(filtersListMenu);
      setFiltersListMenu(initialFiltersListMenu);
      setErrorsFiltersListMenu({});
      setOpenEditFilterListMenu(false);
    }  else{
      dispatch(warningErrorAction(t('Text_Error_Filter')));
    }
  }

  const handleDeleteFiltersListMenu = () => {
    setErrorsFiltersListMenu({});
    setFiltersListMenu(initialFiltersListMenu);
    setApplyFiltersListMenu(initialFiltersListMenu);
    setOpenEditFilterListMenu(!openEditFilterListMenu);
  };

  const handleCancelFiltersListMenu = () => {
    setErrorsFiltersListMenu({});
    setFiltersListMenu(initialFiltersListMenu);
    setOpenEditFilterListMenu(!openEditFilterListMenu);
  };

  const handleChangeEnabledFilterListMenu = (e) => {
    setFiltersListMenu(filtersListMenu => ({
      ...filtersListMenu,
      isEnabled: !filtersListMenu.isEnabled
    }));
  };

  const handleChangeNotEnabledFilterListMenu = (e) => {
    setFiltersListMenu(filtersListMenu => ({
      ...filtersListMenu,
      isNotEnabled: !filtersListMenu.isNotEnabled
    }));
  };

  const handleChangeFilterEditListMenu = (e) => {
    const { name, value } = e.target;
    setFiltersListMenu(filtersListMenu => ({
      ...filtersListMenu,
      [name]: value
    }));
  };

  const handleBlurFilterEditListMenu = (e) => {
    setErrorsFiltersListMenu(validateFormFilterListMenu());
    handleChangeFilterEditListMenu(e);
  };

  const toggleDrawerFilterEditListMenu = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    if (event.type === 'keydown') {
      event.preventDefault();
      setErrorsFiltersListMenu({});
      setFiltersListMenu(initialFiltersListMenu);
      setOpenEditFilterListMenu(!openEditFilterListMenu);
    }
  };
  
  const validateFormFilterListMenu = () => {

    let errorsFiltersListMenu = {};
    let regexNumber = /^[0-9]+$/; 
    let regexInvalidCharacters = /[\\!\\$\\%\\&\\|\\<\\>\\?\\{\\}\\~\\"\\']+/;

    if (filtersListMenu.idFrom!=="" & !regexNumber.test(filtersListMenu.idFrom)) {
      errorsFiltersListMenu.idFrom = 'Text_TextNoNumber';
    }
    if (filtersListMenu.idTo!=="" & !regexNumber.test(filtersListMenu.idTo)) {
      errorsFiltersListMenu.idTo = 'Text_TextNoNumber';
    }
    if (filtersListMenu.idFrom!=="" & !errorsFiltersListMenu.idFrom!==undefined){
      if (filtersListMenu.idTo!=="" & !errorsFiltersListMenu.idTo!==undefined){
        if (parseInt(filtersListMenu.idTo)<parseInt(filtersListMenu.idFrom)){
          errorsFiltersListMenu.idTo = 'Text_TextGreater_Id';
        }
      }
    }
    if (regexInvalidCharacters.test(filtersListMenu.nameesFrom)) {
      errorsFiltersListMenu.nameesFrom = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(filtersListMenu.nameesTo)) {
      errorsFiltersListMenu.nameesTo = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(filtersListMenu.nameenFrom)) {
      errorsFiltersListMenu.nameenFrom = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(filtersListMenu.nameenTo)) {
      errorsFiltersListMenu.nameenTo = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(filtersListMenu.codeFrom)) {
      errorsFiltersListMenu.codeFrom = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(filtersListMenu.codeTo)) {
      errorsFiltersListMenu.codeTo = 'Text_TextNoValid';
    } 
    if (filtersListMenu.codeTo!=="" & filtersListMenu.codeTo<filtersListMenu.codeFrom){
      errorsFiltersListMenu.codeTo = 'Text_TextGreater_CodeTranslate';
    }
    if (filtersListMenu.nameesTo!=="" & filtersListMenu.nameesTo<filtersListMenu.nameesFrom){
      errorsFiltersListMenu.nameesTo = 'Text_TextGreater_Namees';
    }
    if (filtersListMenu.nameenTo!=="" & filtersListMenu.nameenTo<filtersListMenu.nameenFrom){
      errorsFiltersListMenu.nameenTo = 'Text_TextGreater_Nameen';
    }
    
    
    return errorsFiltersListMenu;
  }  

  const filteredDataListMenu = dataListMenu.filter((el) => {
    if (inputTextListMenu === '') {
        return el;
    }
    else {
        let dataTranslate=t(el.id+el.name+el.namees+el.nameen) ;
        return dataTranslate.toLowerCase().includes(inputTextListMenu);
    }
  })

  let inputHandlerListMenu = (e) => {
    var lowerCase = e.target.value.toLowerCase();
    setInputTextListMenu(lowerCase);
  };
 
  const toggleDrawerListMenu = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    if (event.type === 'keydown' ) {
      closeModalListMenuSettings();
      return;
    }
  };
 
  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event && typeof event === 'object' && event.key) {
        keyPress(event);
      }
    };
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [keyPress]);

  useEffect(() => {
    if (openListMenu){
      setFormListMenu(formListMenu => ({
        ...formListMenu,
        id : rowEditListMenu.id!==null?rowEditListMenu.id:"",
        indexrowlistmenu : rowEditListMenu.indexrowlistmenu!==null?rowEditListMenu.indexrowlistmenu:0,
        name :rowEditListMenu.name!==null?rowEditListMenu.name:"",
        namees :rowEditListMenu.namees!==null?rowEditListMenu.namees:"",
        nameen :rowEditListMenu.nameen!==null?rowEditListMenu.nameen:"",
        enabled :rowEditListMenu.enabled!==null?rowEditListMenu.enabled:false
      }));
    }else{
      setFormListMenu(initialStateListMenu[0]);
    }
  },[rowEditListMenu,openListMenu]);
  
  useEffect(() => {
    if (numTotRegFilter > LIMITPAGESIZE) {
      setActiveOffset(true);
      const newRangeOffset = [
        {
          value: 0,
          label: " 1 - " + Number(LIMITPAGESIZE).toLocaleString('de-DE'),
        }
      ];
      for (let i = Number(LIMITPAGESIZE); i < numTotRegFilter; i += Number(LIMITPAGESIZE)) {
        newRangeOffset.push({
          value: i,
          label: " " + (i + 1).toLocaleString('de-DE') + " - " + (i + Number(LIMITPAGESIZE)).toLocaleString('de-DE'),
        });
      }
      setRangeOffset(newRangeOffset);
    } else {
      setCurrencyOffset(0);
      setActiveOffset(false);
      setRangeOffset([{}]);
    }
  }, [numTotRegFilter, LIMITPAGESIZE]);

  useEffect(() => {
    let ignore = false;
    if (rowEditRoleUser===undefined || rowEditRoleUser.id===undefined || rowEditRoleUser.id==="" || rowEditRoleUser.id==="0" || rowEditRoleUser.id===0) return;
   
    const fetchnumTotRegByRoleUser = async () => {
      setIsLoadingListMenu(true);
      try {
        const getData = await helpHttp().get2(ENDPOINT, `listmenu/getinformationtablelistmenubyrolerser/`, token,rowEditRoleUser.id);
        if (getData.err || getData.message === "Load failed") {
          setDataListMenu(initialStateListMenu);
          dispatch(warningErrorAction(t(getData.message === "Load failed" ? 'Text_ConnectionFailied' : 'Text_InvalidCredentials')));
        } else if (!ignore) {
          setNumTotRegLitsMenuByRoleUser(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData')));
        setIsLoadingListMenu(false);
      } 
      if (!ignore) {
        setIsLoadingListMenu(false);
      } 
    };
  
    const fetchnumTotRegFilter = async () => {
      setIsLoadingListMenu(true);
      try {
        const getData = await helpHttp().post2(ENDPOINT, `listmenu/getcountfilterlistmenus`, token, rowEditRoleUser.id,applyFiltersListMenu);
        if (getData.err || getData.message === "Load failed") {
          setDataListMenu(initialStateListMenu);
          dispatch(warningErrorAction(t(getData.message === "Load failed" ? 'Text_ConnectionFailied' : 'Text_InvalidCredentials')));
        } else if (!ignore) {
          setNumTotRegFilter(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData')));
        setIsLoadingListMenu(false);
      } 
      if (!ignore) {
        setIsLoadingListMenu(false);
      }
    };
  
    const fetchData = async () => {
      setIsLoadingListMenu(true);
      try {
        const getData = await helpHttp().get4(ENDPOINT, `listmenu/getlistmenubyroleuser`, token, rowEditRoleUser.id,currencyOffset, LIMITPAGESIZE, applyFiltersListMenu);
        if (getData.err || getData.message === "Load failed") {
          setDataListMenu(initialStateListMenu);
          dispatch(warningErrorAction(t(getData.message === "Load failed" ? 'Text_ConnectionFailied' : 'Text_InvalidCredentials')));
        } else if (!ignore) {
          setDataListMenu(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData')));
        setIsLoadingListMenu(false);
      } 
      if (!ignore) {
        setIsLoadingListMenu(false);
      }
    };
  
    if (openListMenu) {
      fetchnumTotRegByRoleUser();
      fetchnumTotRegFilter();
      fetchData();
      return () => { ignore = true; };
    }
  }, [ENDPOINT,t,token,dispatch,openListMenu,rowEditRoleUser,rowEditListMenu,LIMITPAGESIZE,applyFiltersListMenu,currencyOffset]);

  const handleSubmitModifyListMenu = () => {
    let ignore = false;
    const fetchData = async () => {
      setIsLoadingListMenu(true);
    try {
      const getData = await helpHttp().put2(ENDPOINT,`listmenu/updateroleruserlistmenu`,token,rowEditRoleUser.id,dataListMenu);
      if (getData.status === "OK") {
          dispatch(successErrorAction(t('Text_SaveData')));
          closeModalListMenuSettings();
        } else {
          dispatch(warningErrorAction(t(getData.cause)));
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData')));
        setIsLoadingListMenu(false);
      } 
      if (!ignore) {
        setIsLoadingListMenu(false);
      }
    };
    fetchData();
    return () => { ignore = true; };
  };

  return (
    <>
      {openListMenu && 
       <div>
          <SwipeableDrawer
            anchor='right'
            open={true}
            onClose={toggleDrawerListMenu(false)}
            onOpen={toggleDrawerListMenu(true)}
          >
            <Container >
              <Box sx={{ bgcolor: '#ffffff',height:'100vh', width: '1100px' }}>
              <br/>
              <div id="myDIV">
                <h1 className="h1NewStyle mb-2">
                {isLoadingErrorListMenu?<i className="fas fa-solid fa-exclamation fa-2x text-danger"></i>:isLoadingListMenu?<CircularProgress color="primary"/>:rowEditRoleUser.name}</h1>
                <div>
                {roles[0].listmenus.find((key)=>(key.name==='SubMenu_ListMenu_Create'))?
                <div>
                  <button type="button" className="buttonCreate" onClick={handleSubmitModifyListMenu}>{t('Button_Update')}</button><> </>
                  <button type="button" className="buttonBack" onClick={closeModalListMenuSettings}>{t('Button_Back')}</button>
                </div>:
                <div>
                  <button type="button" className="buttonBack" onClick={closeModalListMenuSettings}>{t('Button_Back')}</button>
                </div>}
                </div>
              </div>
              <div id="myDIV">  
                <h1 className="h1NewStyle mb-2">
                  {i18n.language==="es"?rowEditRoleUser.namees:rowEditRoleUser.nameen}
                </h1>  
              </div>  
              <br/>
              <div className="searchStyle">
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  value={inputTextListMenu}
                  placeholder={t('Text_ListMenu_Search')}
                  onChange={inputHandlerListMenu}
                  fullWidth
                  label={t('Text_ListMenu_Search')}>
                </TextField>
              </div>
              <br/>
              <div id="myDIV">
                <h1 className="h6NewStyle mb-2">
                {t('Text_Filter')}&nbsp;
                    <button type="button" className="buttonFilter" onClick={openEditFiltersListMenu}><i className="fas fa-solid fa-circle" style={{color:(applyFiltersListMenu.idFrom!=="" || applyFiltersListMenu.idTo!=="")?"#32CD32":"#FFFFFF"}}></i> {t('Text_Code')}</button><> </>
                    <button type="button" className="buttonFilter" onClick={openEditFiltersListMenu}><i className="fas fa-solid fa-circle" style={{color:(applyFiltersListMenu.codeFrom!=="" || applyFiltersListMenu.codeTo!=="")?"#32CD32":"#FFFFFF"}}></i> {t('Text_CodeTranslate')}</button><> </>
                    <button type="button" className="buttonFilter" onClick={openEditFiltersListMenu}><i className="fas fa-solid fa-circle" style={{color:(applyFiltersListMenu.nameesFrom!=="" || applyFiltersListMenu.nameesTo!=="")?"#32CD32":"#FFFFFF"}}></i> {t('Text_NameEs')}</button><> </>
                    <button type="button" className="buttonFilter" onClick={openEditFiltersListMenu}><i className="fas fa-solid fa-circle" style={{color:(applyFiltersListMenu.nameenFrom!=="" || applyFiltersListMenu.nameenTo!=="")?"#32CD32":"#FFFFFF"}}></i> {t('Text_NameEn')}</button><> </>
                    <button type="button" className="buttonFilter" onClick={openEditFiltersListMenu}><i className="fas fa-solid fa-circle" style={{color:(applyFiltersListMenu.isEnabled)?"#32CD32":"#FFFFFF"}}></i> {t('Text_Enabled')}</button><> </>
                    <button type="button" className="buttonFilter" onClick={openEditFiltersListMenu}><i className="fas fa-solid fa-circle" style={{color:(applyFiltersListMenu.isNotEnabled)?"#32CD32":"#FFFFFF"}}></i> {t('Text_Not_Enabled')}</button><> </>
                  </h1>
              </div>
              <br/>
              <hr className="sidebar-divider d-none d-md-block"/>
              <div className="card shadow mb-4">    
              <div className="card-body">
                <TableSortingListMenu
                  dataListMenu={filteredDataListMenu}
                  headCellsListMenu={headCellsListMenu}
                  numTotRegFilter={numTotRegFilter}
                  titleTable={t('Text_ListMenu_Table')}
                  setFormListMenu={setFormListMenu}
                  numTotRegLitsMenuByRoleUser={numTotRegLitsMenuByRoleUser}
                  activeOffset={activeOffset}
                  rangeOffset={rangeOffset}
                  currencyOffset={currencyOffset}
                  setCurrencyOffset={setCurrencyOffset}
                  formListMenu={formListMenu}>  
                </TableSortingListMenu>
              </div>             
            </div>
        
            </Box>
            </Container>
          </SwipeableDrawer>
          {openEditFilterListMenu &&
            <div>
              <SwipeableDrawer
                anchor='right'
                open={true}
                onClose={toggleDrawerFilterEditListMenu(false)}
                onOpen={toggleDrawerFilterEditListMenu(true)}>
                <Container >
                  <Box sx={{ bgcolor: '#ffffff',height:'100vh', width: '500px' }}>
                    <br/>
                    <div id="myDIV">
                      <h1 className="h1NewStyle px-4 mb-2">{t('Text_Filter')}</h1>
                    </div>
                    <hr className="sidebar-divider d-none d-md-block"/>
                    <form className="createfilter" onSubmit={handleCancelFiltersListMenu}>  
                      <div className="form-floating mb-3 px-4 primary">
                        <label className="text-primary">{t('Text_Filter_Code_From')} :</label>
                        <div className="form-group">
                          <TextField
                            id="idFrom" 
                            name="idFrom"
                            value={filtersListMenu.idFrom}
                            inputProps={{ style: {width: 450}, maxLength: 15 }}
                            label={errorsFiltersListMenu.idFrom!=null?t(errorsFiltersListMenu.idFrom):t('PlaceHolder_Filter_Code_From')}
                            placeholder={t('PlaceHolder_Filter_Code_From')}
                            variant="standard"
                            onBlur={handleBlurFilterEditListMenu}
                            onChange={handleChangeFilterEditListMenu}/>
                        </div>
                        <label className="text-primary">{t('Text_Filter_Code_To')} :</label>
                        <div className="form-group">
                          <TextField
                            id="idTo" 
                            name="idTo"
                            value={filtersListMenu.idTo}
                            inputProps={{ style: {width: 450}, maxLength: 15 }}
                            label={errorsFiltersListMenu.idTo!=null?t(errorsFiltersListMenu.idTo):t('PlaceHolder_Filter_Code_to')}
                            placeholder={t('PlaceHolder_Filter_Code_to')}
                            variant="standard"
                            onBlur={handleBlurFilterEditListMenu}
                            onChange={handleChangeFilterEditListMenu}/>
                        </div>
                        <label className="text-primary">{t('Text_Filter_CodeTranslate_From')} :</label>
                        <div className="form-group">
                          <TextField
                            id="codeFrom" 
                            name="codeFrom"
                            value={filtersListMenu.codeFrom}
                            inputProps={{ style: {width: 450}, maxLength: 100 }}
                            label={errorsFiltersListMenu.codeFrom!=null?t(errorsFiltersListMenu.codeFrom):t('PlaceHolder_Filter_CodeTranslate_From')}
                            placeholder={t('PlaceHolder_Filter_CodeTranslate_From')}
                            variant="standard"
                            onBlur={handleBlurFilterEditListMenu}
                            onChange={handleChangeFilterEditListMenu}/>
                        </div>
                        <label className="text-primary">{t('Text_Filter_CodeTranslate_to')} :</label>
                        <div className="form-group">
                          <TextField
                            id="codeTo" 
                            name="codeTo"
                            value={filtersListMenu.codeTo}
                            inputProps={{ style: {width: 450}, maxLength: 100 }}
                            label={errorsFiltersListMenu.codeTo!=null?t(errorsFiltersListMenu.codeTo):t('PlaceHolder_Filter_CodeTranslate_to')}
                            placeholder={t('PlaceHolder_Filter_CodeTranslate_to')}
                            variant="standard"
                            onBlur={handleBlurFilterEditListMenu}
                            onChange={handleChangeFilterEditListMenu}/>
                        </div>

                        <label className="text-primary">{t('Text_Filter_NameEs_From')} :</label>
                        <div className="form-group">
                          <TextField
                            id="nameesFrom" 
                            name="nameesFrom"
                            value={filtersListMenu.nameesFrom}
                            inputProps={{ style: {width: 450}, maxLength: 100 }}
                            label={errorsFiltersListMenu.nameesFrom!=null?t(errorsFiltersListMenu.nameesFrom):t('PlaceHolder_Filter_NameEs_From')}
                            placeholder={t('PlaceHolder_Filter_NameEs_From')}
                            variant="standard"
                            onBlur={handleBlurFilterEditListMenu}
                            onChange={handleChangeFilterEditListMenu}/>
                        </div>
                        <label className="text-primary">{t('Text_Filter_NameEs_To')} :</label>
                        <div className="form-group">
                          <TextField
                            id="nameesTo" 
                            name="nameesTo"
                            value={filtersListMenu.nameesTo}
                            inputProps={{ style: {width: 450}, maxLength: 100 }}
                            label={errorsFiltersListMenu.nameesTo!=null?t(errorsFiltersListMenu.nameesTo):t('PlaceHolder_Filter_NameEs_to')}
                            placeholder={t('PlaceHolder_Filter_NameEs_to')}
                            variant="standard"
                            onBlur={handleBlurFilterEditListMenu}
                            onChange={handleChangeFilterEditListMenu}/>
                        </div>

                        <label className="text-primary">{t('Text_Filter_NameEn_From')} :</label>
                        <div className="form-group">
                          <TextField
                            id="nameenFrom" 
                            name="nameenFrom"
                            value={filtersListMenu.nameenFrom}
                            inputProps={{ style: {width: 450}, maxLength: 100 }}
                            label={errorsFiltersListMenu.nameenFrom!=null?t(errorsFiltersListMenu.nameenFrom):t('PlaceHolder_Filter_NameEn_From')}
                            placeholder={t('PlaceHolder_Filter_NameEn_From')}
                            variant="standard"
                            onBlur={handleBlurFilterEditListMenu}
                            onChange={handleChangeFilterEditListMenu}/>
                        </div>
                        <label className="text-primary">{t('Text_Filter_NameEn_To')} :</label>
                        <div className="form-group">
                          <TextField
                            id="nameenTo" 
                            name="nameenTo"
                            value={filtersListMenu.nameenTo}
                            inputProps={{ style: {width: 450}, maxLength: 100 }}
                            label={errorsFiltersListMenu.nameenTo!=null?t(errorsFiltersListMenu.nameenTo):t('PlaceHolder_Filter_NameEn_to')}
                            placeholder={t('PlaceHolder_Filter_NameEn_to')}
                            variant="standard"
                            onBlur={handleBlurFilterEditListMenu}
                            onChange={handleChangeFilterEditListMenu}/>
                        </div>

                        <label className="text-primary">{t('Text_Enabled')} :</label>
                        <div className="form-group">
                          <Checkbox
                            id="isEnabled" 
                            name="isEnabled"
                            checked={filtersListMenu.isEnabled}
                            onChange={handleChangeEnabledFilterListMenu}
                            variant="standard"/>

                        </div> 
                        <label className="text-primary">{t('Text_Not_Enabled')} :</label>
                        <div className="form-group">
                          <Checkbox
                            id="isNotEnabled" 
                            name="isNotEnabled"
                            checked={filtersListMenu.isNotEnabled}
                            onChange={handleChangeNotEnabledFilterListMenu}
                            variant="standard"/>
                        </div> 
                        <hr className="sidebar-divider d-none d-md-block"/>
                        <div className="btn-group d-grid d-md-flex justify-content-md-end" role="group">
                          <button className="btn btn-primary btn-lg " type="button" onClick={handleSubmitFilterEditListMenu}>{t('Button_Apply')}</button><> </>
                          <button className="btn btn-outline-secondary btn-lg" type="button" onClick={handleDeleteFiltersListMenu}>{t('Button_Cancel_Filters')}</button>
                        </div>
                      </div>
                    </form>
                  </Box>
                </Container>
              </SwipeableDrawer>
            </div>
          }  
 
        </div>
      } 
    </>
  )
}

ListMenuSettings.propTypes = {
  openListMenu: PropTypes.bool.isRequired,
  setOpenListMenu: PropTypes.func.isRequired,
  rowEditRoleUser: PropTypes.object.isRequired,
  setRowEdit: PropTypes.func.isRequired 
};

export default ListMenuSettings;