import React, {  useEffect, useState, useCallback } from 'react';
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux"; 
import { useTranslation } from "react-i18next" 
import { useNavigate } from 'react-router-dom'; 
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Card from '@mui/material/Card'; 
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { Button, CardActions } from '@mui/material';
import SignatureCanvas from 'react-signature-canvas';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IconButton from '@mui/material/IconButton'; 
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import CircularProgress from '@mui/material/CircularProgress'; 
import { Grid } from '@mui/material';
import { Box } from '@mui/material'; 
import { TextField } from '@mui/material';
import { Typography} from '@mui/material';
import "css/generalSettings.css"; 
import { successErrorAction,warningErrorAction } from "actions/ErrorActions"; 
import { helpHttp } from "service/HelpHttp";
import { Loading } from "components/utils/Loading";
import { NumericFormat } from 'react-number-format';
import { makeStyles } from '@mui/styles'; 
import FormCreateOrEditOptTras  from "pages/forms/editOrNewFormDI/FormOptTrasEdit";
import FormCreateOrEditOriTras  from "pages/forms/editOrNewFormDI/FormOriTrasEdit";
import FormCreateOrEditAutTras  from "pages/forms/editOrNewFormDI/FormAutTrasEdit";
import FormCreateOrEditDestTras from "pages/forms/editOrNewFormDI/FormDestTrasEdit";
import FormCreateOrEditAutDest  from "pages/forms/editOrNewFormDI/FormAutDestEdit";
import FormSearchCodeLers  from "pages/forms/editOrNewFormDI/FormSearchCodeLers";
import FormSearchDescriptions  from "pages/forms/editOrNewFormDI/FormSearchDescriptions";
import FormCreateOrEditRelTrans  from "pages/forms/editOrNewFormDI/FormRelTransEdit";
import FormCreateOrEditRespProd  from "pages/forms/editOrNewFormDI/FormRespProdEdit";
import FormDIShareEdit from "pages/forms/editOrNewFormDI/FormSharedEdit";
import image1 from "components/photos/formdi/tosign.png"; 

const initialStateForm = {
  "id" : "",
  "ownformdi_id" : "", 
  "defaultStore_id":0, 
  "useraccount_id":0,

  "defaultformatnumber":1,
  "isFinished":false,
  "isScanned":false,
  "preiddoc_sheet" : "",
  "sufiddoc_sheet" : 0,
  "number_dt":"",
  "date_sheet":"",
  
  "formopttrasstore_id": 0,
  "opt_tras_store_id" : 0,
  "opt_tras_documnettype_id" : 0,
  "opt_tras_documentid" : "",
  "opt_tras_tradename" : "",
  "opt_tras_name" : "",
  "opt_tras_contactname" : "",
  "opt_tras_roadtype_id" : 0,
  "opt_tras_street" : "",
  "opt_tras_country_id":0,
  "opt_tras_province_id":0,
  "opt_tras_postalcode_id":0,
  "opt_tras_community_id":0,
  "opt_tras_phone":"",
  "opt_tras_email":"",
  "opt_tras_nima":"",
  "opt_tras_cnae":"",
  "opt_tras_inscription":"",
  "opt_tras_opt":"",
  "opt_tras_licenseplate":"",
  "opt_tras_economicactivity":"",
 
  "formoritrasstore_id": 0,
  "ori_tras_store_id" : 0,
  "ori_tras_documnettype_id" : 0,
  "ori_tras_documentid" : "",
  "ori_tras_tradename" : "",
  "ori_tras_name" : "",
  "ori_tras_contactname" : "",
  "ori_tras_roadtype_id" : "",
  "ori_tras_street" : "",
  "ori_tras_country_id":0,
  "ori_tras_province_id":0,
  "ori_tras_postalcode_id":0,
  "ori_tras_community_id":0,
  "ori_tras_phone":"",
  "ori_tras_email":"",
  "ori_tras_nima":"",
  "ori_tras_cnae":"",
  "ori_tras_inscription":"",
  "ori_tras_opt":"",
  "ori_tras_licenseplate":"",
  "ori_tras_economicactivity":"",
  
  "aut_tras_store_id" : 0,
  "aut_tras_documnettype_id" : 0,
  "aut_tras_documentid" : "",
  "aut_tras_tradename" : "",
  "aut_tras_name" : "",
  "aut_tras_contactname" : "",
  "aut_tras_roadtype_id" : "",
  "aut_tras_street" : "",
  "aut_tras_country_id":0,
  "aut_tras_province_id":0,
  "aut_tras_postalcode_id":0,
  "aut_tras_community_id":0,
  "aut_tras_phone":"",
  "aut_tras_email":"",
  "aut_tras_nima":"",
  "aut_tras_cnae":"",
  "aut_tras_inscription":"",
  "aut_tras_opt":"",
  "aut_tras_licenseplate":"",
  "aut_tras_economicactivity":"",
  
  "formdesttrasstore_id": 0,
  "dest_tras_store_id" : 0,
  "dest_tras_documnettype_id" : 0,
  "dest_tras_documentid" : "",
  "dest_tras_tradename" : "",
  "dest_tras_name" : "",
  "dest_tras_contactname" : "",
  "dest_tras_roadtype_id" : "",
  "dest_tras_street" : "",
  "dest_tras_country_id":0,
  "dest_tras_province_id":0,
  "dest_tras_postalcode_id":0,
  "dest_tras_community_id":0,
  "dest_tras_phone":"",
  "dest_tras_email":"",
  "dest_tras_nima":"",
  "dest_tras_cnae":"",
  "dest_tras_inscription":"",
  "dest_tras_opt":"",
  "dest_tras_licenseplate":"",
  "dest_tras_economicactivity":"",
 
  "aut_dest_store_id" : 0,
  "aut_dest_documnettype_id" : 0,
  "aut_dest_documentid" : "",
  "aut_dest_tradename" : "",
  "aut_dest_name" : "",
  "aut_dest_contactname" : "",
  "aut_dest_roadtype_id" : "",
  "aut_dest_street" : "",
  "aut_dest_country_id":0,
  "aut_dest_province_id":0,
  "aut_dest_postalcode_id":0,
  "aut_dest_community_id":0,
  "aut_dest_phone":"",
  "aut_dest_email":"",
  "aut_dest_nima":"",
  "aut_dest_cnae":"",
  "aut_dest_inscription":"",
  "aut_dest_opt":"",
  "aut_dest_licenseplate":"",
  "aut_dest_economicactivity":"",

  "codeler_id" : 0,
  "codeler_code" : "",
  "codeler_namees" : "",
  "codeler_nameen" : "",
  "description_id" : 0,
  "description_code" : "",
  "description_namees" : "",
  "description_nameen" : "",
  
  "treatmenttypeorigin_id" : 0,
  "treatmenttypeorigin_code" : "",
  "treatmenttypeorigin_namees" : "",
  "treatmenttypeorigin_nameen" : "",
 
  "treatmenttypedestine_id" : 0,
  "treatmenttypedestine_code" : "",
  "treatmenttypedestine_namees" : "",
  "treatmenttypedestine_nameen" : "",
  
  "processtypepeorigin_id" : 0,
  "processtypepeorigin_code" : "",
  "processtypepeorigin_namees" : "",
  "processtypepeorigin_nameen" : "",

  "processtypedestine_id" : 0,
  "processtypedestine_code" : "",
  "processtypedestine_namees" : "",
  "processtypedestine_nameen" : "",
  
  "dangerousness_id" : 0,
  "dangerousness_namees" : "",
  "dangerousness_nameen" : "",

  "charac_danger" : "",
  "amount" : 0,

  "formreltransstore_id": 0,
  "rel_trans_store_id" : 0,
  "rel_trans_documnettype_id" : 0,
  "rel_trans_documentid" : "",
  "rel_trans_tradename" : "",
  "rel_trans_name" : "",
  "rel_trans_contactname" : "",
  "rel_trans_roadtype_id" : "",
  "rel_trans_street" : "",
  "rel_trans_country_id":0,
  "rel_trans_province_id":0,
  "rel_trans_postalcode_id":0,
  "rel_trans_community_id":0,
  "rel_trans_phone":"",
  "rel_trans_email":"",
  "rel_trans_nima":"",
  "rel_trans_cnae":"",
  "rel_trans_inscription":"",
  "rel_trans_opt":"",
  "rel_trans_licenseplate":"",
  "rel_trans_economicactivity":"",
  
  "formrespprodstore_id": 0,
  "resp_prod_store_id" : 0,
  "resp_prod_documnettype_id" : 0,
  "resp_prod_documentid" : "",
  "resp_prod_tradename" : "",
  "resp_prod_name" : "",
  "resp_prod_contactname" : "",
  "resp_prod_roadtype_id" : "",
  "resp_prod_street" : "",
  "resp_prod_country_id":0,
  "resp_prod_province_id":0,
  "resp_prod_postalcode_id":0,
  "resp_prod_community_id":0,
  "resp_prod_phone":"",
  "resp_prod_email":"",
  "resp_prod_nima":"",
  "resp_prod_cnae":"",
  "resp_prod_inscription":"",
  "resp_prod_opt":"",
  "resp_prod_licenseplate":"",
  "resp_prod_economicactivity":"",

  "deliver_date":"",
  "delivery_quantity":0,
  "accept":"Text_NOT_REPORTING",
  "date_accepted":"",
  "observations":"",
  "date_refund":"",
  "reason_refund":"",
  "action_refund":"",

  "opt_tras_shared":false,
  "ori_tras_shared":false,
  "aut_tras_shared":false,
  "dest_tras_shared":false,
  "aut_dest_shared":false,
  "rel_trans_shared":false,
  "resp_prod_shared":false,

  "sendbyemail_form_opt_tras":false,
  "sendbyemail_form_ori_tras":false,
  "sendbyemail_form_aut_tras":false,
  "sendbyemail_form_dest_tras":false,
  "sendbyemail_form_aut_dest":false,
  "sendbyemail_form_rel_trans":false,
  "sendbyemail_form_resp_prod":false,

  "signature_opt_tras":"",
  "signature_dest_tras":"",
  "signature_refund":"" 
};

const textAccepted=[
  {"id":1,"name":"Text_NOT_REPORTING"},
  {"id":2,"name":"Text_FORM_IsAccept"},
  {"id":3,"name":"Text_FORM_IsNotAccept"}];
  
const initialDocumentTypesToSelect = [{
  "id": "",
  "name": ""
}];

const initialTypesToSelect = [{
  "id": "",
  "namees": "",
  "nameen": ""
}];

const initialRDToSelect = [{
  "id": "",
  "code": "",
  "namees": "",
  "nameen": ""
}];

const initialCountriesToSelect = [{
  "id" : 1,
  "namees" : "",
  "nameen" : "",
  "enabled":true
}];
 
const useStyles = makeStyles((theme) => ({
  root: {
    width: '880px',
  },
  backcolor: {
    backgroundColor:  "#F4F4F4"
  },
  heading: {
    fontSize: 14,
    paddingLeft: 6,
    width: '220px',
    color: "#067330"
  },
  secondaryHeading: {
    fontSize: 14,
    width: '600px',
    color: "#000000",
  },
  thirdHeading: {
    fontSize: 14,
    width: '600px',
    color: "#e74a3b",
  },
}));

export const FormEditOrCreate = ({id, showModalFormSettings,typeOfList}) => {
  const {t,i18n} = useTranslation(['listDataForms']);
  const token = useSelector((state) => state.loginUser.token);  
  const roles = useSelector((state) => state.loginUser.roles);  
  const defaultStore = useSelector((state) => state.loginUser.defaultStore)
  const useraccount_id = useSelector((state) => state.loginUser.useraccount_id);  
  const defaulttypesdi = useSelector((state) => state.loginUser.defaulttypesdi);  
  const ENDPOINT = useSelector((state) => state.variablesUser.ENDPOINT); 
  const DEFAULTTYPEOFDOCUMENT = useSelector((state) => state.variablesUser.DEFAULTTYPEOFDOCUMENT); 
  const DEFAULTCOUNTRY = useSelector((state) => state.formatDIUser.default_country); 
  const DEFAULTPROVINCE = useSelector((state) => state.formatDIUser.default_province); 
  const DEFAULTCOMMUNITY = useSelector((state) => state.formatDIUser.default_community); 
  const opt_tras_view = useSelector((state) => state.formatDIUser.opt_tras_view); 
  const opt_tras_expand = useSelector((state) => state.formatDIUser.opt_tras_expand);
  const ori_tras_view = useSelector((state) => state.formatDIUser.ori_tras_view); 
  const ori_tras_expand = useSelector((state) => state.formatDIUser.ori_tras_expand);
  const aut_tras_view = useSelector((state) => state.formatDIUser.aut_tras_view); 
  const aut_tras_expand = useSelector((state) => state.formatDIUser.aut_tras_expand);
  const dest_tras_view = useSelector((state) => state.formatDIUser.dest_tras_view); 
  const dest_tras_expand = useSelector((state) => state.formatDIUser.dest_tras_expand);
  const aut_dest_view = useSelector((state) => state.formatDIUser.aut_dest_view); 
  const aut_dest_expand = useSelector((state) => state.formatDIUser.aut_dest_expand);
  const rel_trans_view = useSelector((state) => state.formatDIUser.rel_trans_view); 
  const rel_trans_expand = useSelector((state) => state.formatDIUser.rel_trans_expand);
  const resp_prod_view = useSelector((state) => state.formatDIUser.resp_prod_view); 
  const resp_prod_expand = useSelector((state) => state.formatDIUser.resp_prod_expand);
  const number_dt_view = useSelector((state) => state.formatDIUser.number_dt_view);
  const code_description_view = useSelector((state) => state.formatDIUser.code_description_view);
  const code_treatmenttype_origin_view = useSelector((state) => state.formatDIUser.code_treatmenttype_origin_view);
  const code_treatmenttype_destine_view = useSelector((state) => state.formatDIUser.code_treatmenttype_destine_view);
  const code_processtype_origin_view = useSelector((state) => state.formatDIUser.code_processtype_origin_view);
  const code_processtype_destine_view = useSelector((state) => state.formatDIUser.code_processtype_destine_view);
  const code_dangerousness_view = useSelector((state) => state.formatDIUser.code_dangerousness_view);
  const charac_danger_view = useSelector((state) => state.formatDIUser.charac_danger_view);
  const date_refund_view = useSelector((state) => state.formatDIUser.date_refund_view);
  const reason_refund_view = useSelector((state) => state.formatDIUser.reason_refund_view);
  const action_refund_view = useSelector((state) => state.formatDIUser.action_refund_view);
  const number_dt_handle = useSelector((state) => state.formatDIUser.number_dt_handle);
  const code_description_handle = useSelector((state) => state.formatDIUser.code_description_handle);
  const code_treatmenttype_origin_handle = useSelector((state) => state.formatDIUser.code_treatmenttype_origin_handle);
  const code_treatmenttype_destine_handle = useSelector((state) => state.formatDIUser.code_treatmenttype_destine_handle);
  const code_processtype_origin_handle = useSelector((state) => state.formatDIUser.code_processtype_origin_handle);
  const code_processtype_destine_handle = useSelector((state) => state.formatDIUser.code_processtype_destine_handle);
  const code_dangerousness_handle = useSelector((state) => state.formatDIUser.code_dangerousness_handle);
  const charac_danger_handle = useSelector((state) => state.formatDIUser.charac_danger_handle);
  const date_refund_handle = useSelector((state) => state.formatDIUser.date_refund_handle);
  const reason_refund_handle = useSelector((state) => state.formatDIUser.reason_refund_handle);
  const action_refund_handle = useSelector((state) => state.formatDIUser.action_refund_handle);
  const share_form_view = useSelector((state) => state.formatDIUser.share_form_view);
  const share_form_opt_tras = useSelector((state) => state.formatDIUser.share_form_opt_tras);
  const share_form_ori_tras = useSelector((state) => state.formatDIUser.share_form_ori_tras);
  const share_form_aut_tras = useSelector((state) => state.formatDIUser.share_form_aut_tras);
  const share_form_dest_tras = useSelector((state) => state.formatDIUser.share_form_dest_tras);
  const share_form_aut_dest = useSelector((state) => state.formatDIUser.share_form_aut_dest);
  const share_form_rel_trans = useSelector((state) => state.formatDIUser.share_form_rel_trans);
  const share_form_resp_prod = useSelector((state) => state.formatDIUser.share_form_resp_prod);

  const sendbyemail_form_view = useSelector((state) => state.formatDIUser.sendbyemail_form_view);
  const sendbyemail_form_opt_tras = useSelector((state) => state.formatDIUser.sendbyemail_form_opt_tras);
  const sendbyemail_form_ori_tras = useSelector((state) => state.formatDIUser.sendbyemail_form_ori_tras);
  const sendbyemail_form_aut_tras = useSelector((state) => state.formatDIUser.sendbyemail_form_aut_tras);
  const sendbyemail_form_dest_tras = useSelector((state) => state.formatDIUser.sendbyemail_form_dest_tras);
  const sendbyemail_form_aut_dest = useSelector((state) => state.formatDIUser.sendbyemail_form_aut_dest);
  const sendbyemail_form_rel_trans = useSelector((state) => state.formatDIUser.sendbyemail_form_rel_trans);
  const sendbyemail_form_resp_prod = useSelector((state) => state.formatDIUser.sendbyemail_form_resp_prod);
  const signature_default = useSelector((state) => state.formatDIUser.signature_default);
  const signature_default_des_tras = useSelector((state) => state.formatDIUser.signature_default_des_tras);

  const [formFrom, setFormForm] = useState(initialStateForm);
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [isLoadingErrorData, setIsLoadingErrorData] = useState(false);
  const [errorsForm, setErrorsForm] = useState({});
  const [documentTypesToSelect,setDocumentTypesToSelect]= useState(initialDocumentTypesToSelect); 
  const [roadTypesToSelect,setRoadTypesToSelect]= useState(initialTypesToSelect);
  const [communityToSelect,setCommunityToSelect]= useState(initialTypesToSelect);
  const [countryToSelect,setCountryToSelect]= useState(initialCountriesToSelect);
  const [treatmenttypeToSelect,setTreatmenttypeToSelect]= useState(initialRDToSelect);
  const [processtypeToSelect,setProcesstypeToSelect]= useState(initialRDToSelect);
  const [dangerousness,setDangerousness]= useState(initialTypesToSelect); 
  const [expanded, setExpanded] =  useState(false);
  const [showModalSearchCodeLERS, setShowModalSearchCodeLERS] =  useState(false);
  const [showModalSearchDescriptions, setShowModalSearchDescriptions] =  useState(false); 
  const [openShareFormDI, setOpenShareFormDI] =  useState(false);  
  
  const [openSignOpt_Tras,setOpenSignOpt_Tras]= useState(false); 
  const [openSignDest_Tras,setOpenSignDest_Tras]= useState(false);
  const [openRefund,setOpenRefund]= useState(false);
  
  const [signOptTrasCanvas, setSignOptTrasCanvas]= useState(); 
  const [signDestTrasCanvas, setSignDestTrasCanvas]= useState(); 
  const [signRefundCanvas, setSignRefundCanvas]= useState();  

  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate(); 
 
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const keyPress = useCallback(
    e => {
      if (e.key === 'Escape' && showModalFormSettings) {
       
        setFormForm(initialStateForm);
        setFormForm(formFrom => ({
          ...formFrom
        }));
        setIsLoadingData(false);
        setIsLoadingErrorData(false);
        setErrorsForm({});
        setDocumentTypesToSelect(initialDocumentTypesToSelect);
        setRoadTypesToSelect(initialTypesToSelect);
        setCommunityToSelect(initialTypesToSelect);
        setCountryToSelect(initialCountriesToSelect); 
        setTreatmenttypeToSelect(initialRDToSelect);
        setProcesstypeToSelect(initialRDToSelect);
        setDangerousness(initialTypesToSelect);
        setExpanded(false);
        setOpenShareFormDI(false);
        setShowModalSearchCodeLERS(false);
        setShowModalSearchDescriptions(false); 
        setOpenSignOpt_Tras(false); 
        setOpenSignDest_Tras(false);
        setSignOptTrasCanvas();
        setSignDestTrasCanvas();
        setSignRefundCanvas(); 
        setOpenRefund(false);
        if (formFrom.id!==undefined && formFrom.id!=="" && formFrom.id!=="0" && formFrom.id!==0){
          navigate(typeOfList==="1"?"/formdi":"/formdiended") ;
        }else{
          navigate("/home"); 
        }
        return () => {};
      }
    },
    [
      setFormForm,
      setIsLoadingData,
      setIsLoadingErrorData,
      setErrorsForm,
      setDocumentTypesToSelect,
      setRoadTypesToSelect,
      setCommunityToSelect,
      setCountryToSelect,
      setTreatmenttypeToSelect,
      setProcesstypeToSelect,
      setDangerousness,
      setExpanded,
      setShowModalSearchCodeLERS,
      setShowModalSearchDescriptions, 
      setOpenSignOpt_Tras,
      setOpenSignDest_Tras,
      setOpenRefund,
      setSignOptTrasCanvas,
      setSignDestTrasCanvas,
      setSignRefundCanvas,
      setOpenShareFormDI,  
      showModalFormSettings,
      navigate,
      typeOfList,
      formFrom.id]
  );
  
  const handleCloseDataEditForm = () => {
    
    setFormForm(initialStateForm);
    setFormForm(formFrom => ({
      ...formFrom 
    }));
    setIsLoadingData(false);
    setIsLoadingErrorData(false);
    setErrorsForm({});
    setDocumentTypesToSelect(initialDocumentTypesToSelect);
    setRoadTypesToSelect(initialTypesToSelect);
    setCommunityToSelect(initialTypesToSelect);
    setCountryToSelect(initialCountriesToSelect); 
    setTreatmenttypeToSelect(initialRDToSelect);
    setProcesstypeToSelect(initialRDToSelect);
    setDangerousness(initialTypesToSelect);
    setExpanded(false);
    setShowModalSearchCodeLERS(false);
    setShowModalSearchDescriptions(false);  
    setOpenSignOpt_Tras(false); 
    setOpenSignDest_Tras(false);
    setOpenRefund(false);
    setSignOptTrasCanvas();
    setSignDestTrasCanvas();
    setSignRefundCanvas(); 
    setOpenShareFormDI(false);
    if (formFrom.id!==undefined && formFrom.id!=="" && formFrom.id!=="0" && formFrom.id!==0){
      navigate(typeOfList==="1"?"/formdi":"/formdiended") ;
    }else{
      navigate("/home"); 
    }
    return () => {};
	};

  const selectModalSignature = (id) => {
    switch (id) {
      case 1:   
        setOpenSignOpt_Tras(true);  
        break;
      case 2:    
        setOpenSignDest_Tras(true);
        break;
      case 3:    
        setOpenRefund(true);
        break; 
      default:
        break;
    } 
  };

  const deleteSignature = (id) => {
    switch (id) {
      case 1:   
        setFormForm(formFrom => ({
          ...formFrom,
          signature_opt_tras: "",
        })); 
        break;
      case 2:    
        
        setFormForm(formFrom => ({
          ...formFrom,
          signature_dest_tras: "",
        }));
        break;
      case 3:    
        setFormForm(formFrom => ({
          ...formFrom,
          signature_refund: "",
        }));
        break; 
      default:
        break;
    } 
  };
  
  const handleCancelSignOptTras = () => {
    signOptTrasCanvas.clear();
    setOpenSignOpt_Tras(false);
  };
  const handleDeleteSignOptTras = () => {
    signOptTrasCanvas.clear();
    setFormForm(formFrom => ({
      ...formFrom,
      signature_opt_tras: "",
    }));
    setOpenSignOpt_Tras(false);
  };
  const handleSubmitSignOptTras = () => {
    const dataURL = signOptTrasCanvas.getTrimmedCanvas().toDataURL('image/png');
    setFormForm(formFrom => ({
      ...formFrom,
      signature_opt_tras:dataURL,
    }));
    setOpenSignOpt_Tras(false);
  };
  
  const handleCancelSignDestTras = () => {
    signDestTrasCanvas.clear();
    setOpenSignDest_Tras(false);
  };
  const handleDeleteSignDestTras = () => {
    signDestTrasCanvas.clear();
    setFormForm(formFrom => ({
      ...formFrom,
      signature_dest_tras: "",
    }));
    setOpenSignDest_Tras(false);
  };
  const handleSubmitSignDestTras = () => {
    const dataURL = signDestTrasCanvas.getTrimmedCanvas().toDataURL('image/png');
    setFormForm(formFrom => ({
      ...formFrom,
      signature_dest_tras:dataURL,
    }));
    setOpenSignDest_Tras(false);
  };

  const handleCancelSignRefund = () => {
    signRefundCanvas.clear();
    setOpenRefund(false);
  };
  const handleDeleteSignRefund = () => {
     setFormForm(formFrom => ({
      ...formFrom,
      signature_refund: "",
    }));
    signRefundCanvas.clear();
    setOpenRefund(false);
  };
  const handleSubmitSignRefund = () => {
    const dataURL = signRefundCanvas.getTrimmedCanvas().toDataURL('image/png');
    setFormForm(formFrom => ({
      ...formFrom,
      signature_refund:dataURL,
    }));
    setOpenRefund(false);
  };
  
  const handleChangeDataEditFormAmount = (e) => {
    const { name, value } = e.target;
    let newValue=value!==null?Number((value.toLocaleString()).replaceAll(",","").replaceAll(" kg","")):0;
    setFormForm(formFrom => ({
      ...formFrom,
      [name]: newValue,
    }));
  };

  const handleBlurDataEditFormAmount = (e) => {
    handleChangeDataEditFormAmount(e);
    setErrorsForm(validateFormForm());
  };
    
  const handleChangeDataEditForm = (e) => {
    const { name, value } = e.target;
    setFormForm(formFrom => ({
      ...formFrom,
      [name]: value,
    }));
  };

  const handleBlurDataEditForm = (e) => {
    handleChangeDataEditForm(e);
    setErrorsForm(validateFormForm());
  };

  const handleChangeSelectDate = (event) => {
    setFormForm(formFrom => ({
      ...formFrom,
      date_sheet: event!==null?new Date(event):null
    }));
  };

  const handleBlurSelectDate = (e) => {
    handleChangeSelectDate(e);
    setErrorsForm(validateFormForm());
  };

  const deleteDataCodeLer = (event) => {
    setFormForm(formFrom => ({
      ...formFrom,
      codeler_id:0,
      codeler_code: "",
      codeler_namees:"",
      codeler_nameen: ""
    }));
  };

  const searchFormCodeLer = (event) => {
    setShowModalSearchCodeLERS(true);
  };

  const deleteDataDescription = (event) => {
    setFormForm(formFrom => ({
      ...formFrom,
      description_id:0,
      description_code: "",
      description_namees:"",
      description_nameen: ""
    }));
  };

  const searchFormDescription = (event) => {
    setShowModalSearchDescriptions(true);
  };

  const withValueCap = (inputObj) => {
    const { value } = inputObj;
    if (value <= 10000000000) return true;
    return false;
  };

  const handleChangeSelectDateDeliver = (event) => {
    setFormForm(formFrom => ({
      ...formFrom,
      deliver_date: event!==null?new Date(event):null
    }));
  };

  const handleBlurSelectDateDeliver = (e) => {
    handleChangeSelectDateDeliver(e);
    setErrorsForm(validateFormForm());
  };

  const handleChangeSelectDateAccepted = (event) => {
    setFormForm(formFrom => ({
      ...formFrom,
      date_accepted: event!==null?new Date(event):null
    }));
  };

  const handleBlurSelectDateAccepted = (e) => {
    handleChangeSelectDateAccepted(e);
    setErrorsForm(validateFormForm());
  };

  const handleChangeSelectDateRefund = (event) => {
    setFormForm(formFrom => ({
      ...formFrom,
      date_refund: event!==null?new Date(event):null
    }));
  };

  const handleBlurSelectDateRefund = (e) => {
    handleChangeSelectDateRefund(e);
    setErrorsForm(validateFormForm());
  };

  
  const handleChangeSelectAccepted = (event) => {
    setFormForm(formFrom => ({
        ...formFrom,
        accept : event.target.value 
    }));
  };

  const handleBlurSelectAccepted = (e) => {
      handleChangeSelectAccepted(e);
      setErrorsForm(validateFormForm());
  };

  const handleChangeSelectTreatmentTypeOrigin = (event) => {
    setFormForm(formFrom => ({
      ...formFrom,
      treatmenttypeorigin_id:event.target.value
    }));
  };

  const handleBlurSelectTreatmentTypeOrigin = (e) => {
    handleChangeSelectTreatmentTypeOrigin(e);
  };

  const handleChangeSelectTreatmentTypeDestine = (event) => {
    setFormForm(formFrom => ({
      ...formFrom,
      treatmenttypedestine_id:event.target.value

    }));
  };

  const handleBlurSelectTreatmentTypeDestine = (e) => {
    handleChangeSelectTreatmentTypeDestine(e);
  };

  const handleChangeSelectProcessTypeOrigin = (event) => {
    setFormForm(formFrom => ({
      ...formFrom,
      processtypepeorigin_id:event.target.value
    }));
  };

  const handleBlurSelectProcessTypeOrigin = (e) => {
    handleChangeSelectProcessTypeOrigin(e);
  };

  const handleChangeSelectProcessTypeDestine = (event) => {
    setFormForm(formFrom => ({
      ...formFrom,
      processtypedestine_id:event.target.value
    }));
  };

  const handleBlurSelectProcessTypeDestine = (e) => {
    handleChangeSelectProcessTypeDestine(e);
  };

  const handleChangeSelectDangerousness = (event) => {
    setFormForm(formFrom => ({
      ...formFrom,
      dangerousness_id:event.target.value
    }));
  };

  const handleBlurSelectDangerousness = (e) => {
    handleChangeSelectDangerousness(e);
  };

  const validateFormForm = () => {
    let errorsForm = {};
    let regexInvalidCharacters = /[\\!\\$\\%\\&\\|\\<\\>\\?\\{\\}\\~\\"\\']+/;
    let regexEmail = /[\\!\\$\\%\\&\\|\\<\\>\\?\\{\\}\\~\\"\\']+/;
    
    if (!formFrom.preiddoc_sheet || formFrom.preiddoc_sheet==="") {
      errorsForm.preiddoc_sheet = 'Text_Field_Preiddoc_Sheet_Required'; 
    } else {
      if (regexInvalidCharacters.test(formFrom.preiddoc_sheet)) {
        errorsForm.preiddoc_sheet = 'Text_TextNoValid';
      }
    }  

    if (regexInvalidCharacters.test(formFrom.number_dt)) {
      errorsForm.number_dt = 'Text_TextNoValid';
    }
    
    if (regexInvalidCharacters.test(formFrom.opt_tras_documentid)) {
      errorsForm.opt_tras_documentid = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.opt_tras_tradename)) {
      errorsForm.opt_tras_tradename = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.opt_tras_name)) {
      errorsForm.opt_tras_name = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.opt_tras_contactname)) {
      errorsForm.opt_tras_contactname = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.opt_tras_street)) {
      errorsForm.opt_tras_street = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.opt_tras_phone)) {
      errorsForm.opt_tras_phone = 'Text_TextNoValid';
    }
    if (formFrom.opt_tras_email!=="" && regexEmail.test(formFrom.opt_tras_email)) {
      errorsForm.opt_tras_email = 'Text_Email_Invalid';
    }
    if (regexInvalidCharacters.test(formFrom.opt_tras_nima)) {
      errorsForm.opt_tras_nima = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.opt_tras_cnae)) {
      errorsForm.opt_tras_cnae = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.opt_tras_inscription)) {
      errorsForm.opt_tras_inscription = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.opt_tras_opt)) {
      errorsForm.opt_tras_opt = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.opt_tras_licenseplate)) {
      errorsForm.opt_tras_licenseplate = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.opt_tras_economicactivity)) {
      errorsForm.opt_tras_economicactivity = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.ori_tras_documentid)) {
      errorsForm.ori_tras_documentid = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.ori_tras_tradename)) {
      errorsForm.ori_tras_tradename = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.ori_tras_name)) {
      errorsForm.ori_tras_name = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.ori_tras_contactname)) {
      errorsForm.ori_tras_contactname = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.ori_tras_street)) {
      errorsForm.ori_tras_street = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.ori_tras_phone)) {
      errorsForm.ori_tras_phone = 'Text_TextNoValid';
    }
    if (formFrom.ori_tras_email!=="" && regexEmail.test(formFrom.ori_tras_email)) {
      errorsForm.ori_tras_email = 'Text_Email_Invalid';
    }
    if (regexInvalidCharacters.test(formFrom.ori_tras_nima)) {
      errorsForm.ori_tras_nima = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.ori_tras_cnae)) {
      errorsForm.ori_tras_cnae = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.ori_tras_inscription)) {
      errorsForm.ori_tras_inscription = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.ori_tras_opt)) {
      errorsForm.ori_tras_opt = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.ori_tras_licenseplate)) {
      errorsForm.ori_tras_licenseplate = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.ori_tras_economicactivity)) {
      errorsForm.ori_tras_economicactivity = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.aut_tras_documentid)) {
      errorsForm.aut_tras_documentid = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.aut_tras_tradename)) {
      errorsForm.aut_tras_tradename = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.aut_tras_name)) {
      errorsForm.aut_tras_name = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.aut_tras_contactname)) {
      errorsForm.aut_tras_contactname = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.aut_tras_street)) {
      errorsForm.aut_tras_street = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.aut_tras_phone)) {
      errorsForm.aut_tras_phone = 'Text_TextNoValid';
    }
    if (formFrom.aut_tras_email!=="" && regexEmail.test(formFrom.aut_tras_email)) {
      errorsForm.aut_tras_email = 'Text_Email_Invalid';
    }
    if (regexInvalidCharacters.test(formFrom.aut_tras_nima)) {
      errorsForm.aut_tras_nima = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.aut_tras_cnae)) {
      errorsForm.aut_tras_cnae = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.aut_tras_inscription)) {
      errorsForm.aut_tras_inscription = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.aut_tras_opt)) {
      errorsForm.aut_tras_opt = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.aut_tras_licenseplate)) {
      errorsForm.aut_tras_licenseplate = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.aut_tras_economicactivity)) {
      errorsForm.aut_tras_economicactivity = 'Text_TextNoValid';
    }

    if (regexInvalidCharacters.test(formFrom.dest_tras_documentid)) {
      errorsForm.dest_tras_documentid = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.dest_tras_tradename)) {
      errorsForm.dest_tras_tradename = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.dest_tras_name)) {
      errorsForm.dest_tras_name = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.dest_tras_contactname)) {
      errorsForm.dest_tras_contactname = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.dest_tras_street)) {
      errorsForm.dest_tras_street = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.dest_tras_phone)) {
      errorsForm.dest_tras_phone = 'Text_TextNoValid';
    }
    if (formFrom.dest_tras_email!=="" && regexEmail.test(formFrom.dest_tras_email)) {
      errorsForm.dest_tras_email = 'Text_Email_Invalid';
    }
    if (regexInvalidCharacters.test(formFrom.dest_tras_nima)) {
      errorsForm.dest_tras_nima = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.dest_tras_cnae)) {
      errorsForm.dest_tras_cnae = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.dest_tras_inscription)) {
      errorsForm.dest_tras_inscription = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.dest_tras_opt)) {
      errorsForm.dest_tras_opt = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.dest_tras_licenseplate)) {
      errorsForm.dest_tras_licenseplate = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.dest_tras_economicactivity)) {
      errorsForm.dest_tras_economicactivity = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.aut_dest_documentid)) {
      errorsForm.aut_dest_documentid = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.aut_dest_tradename)) {
      errorsForm.aut_dest_tradename = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.aut_dest_name)) {
      errorsForm.aut_dest_name = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.aut_dest_contactname)) {
      errorsForm.aut_dest_contactname = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.aut_dest_street)) {
      errorsForm.aut_dest_street = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.aut_dest_phone)) {
      errorsForm.aut_dest_phone = 'Text_TextNoValid';
    }
    if (formFrom.aut_dest_email!=="" && regexEmail.test(formFrom.aut_dest_email)) {
      errorsForm.aut_dest_email = 'Text_Email_Invalid';
    }
    if (regexInvalidCharacters.test(formFrom.aut_dest_nima)) {
      errorsForm.aut_dest_nima = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.aut_dest_cnae)) {
      errorsForm.aut_dest_cnae = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.aut_dest_inscription)) {
      errorsForm.aut_dest_inscription = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.aut_dest_opt)) {
      errorsForm.aut_dest_opt = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.aut_dest_licenseplate)) {
      errorsForm.aut_dest_licenseplate = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.aut_dest_economicactivity)) {
      errorsForm.aut_dest_economicactivity = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.rel_trans_documentid)) {
      errorsForm.rel_trans_documentid = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.rel_trans_tradename)) {
      errorsForm.rel_trans_tradename = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.rel_trans_name)) {
      errorsForm.rel_trans_name = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.rel_trans_contactname)) {
      errorsForm.rel_trans_contactname = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.rel_trans_street)) {
      errorsForm.rel_trans_street = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.rel_trans_phone)) {
      errorsForm.rel_trans_phone = 'Text_TextNoValid';
    }
    if (formFrom.rel_trans_email!=="" && regexEmail.test(formFrom.rel_trans_email)) {
      errorsForm.rel_trans_email = 'Text_Email_Invalid';
    }
    if (regexInvalidCharacters.test(formFrom.rel_trans_nima)) {
      errorsForm.rel_trans_nima = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.rel_trans_cnae)) {
      errorsForm.rel_trans_cnae = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.rel_trans_inscription)) {
      errorsForm.rel_trans_inscription = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.rel_trans_opt)) {
      errorsForm.rel_trans_opt = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.rel_trans_licenseplate)) {
      errorsForm.rel_trans_licenseplate = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.rel_trans_economicactivity)) {
      errorsForm.rel_trans_economicactivity = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.resp_prod_documentid)) {
      errorsForm.resp_prod_documentid = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.resp_prod_tradename)) {
      errorsForm.resp_prod_tradename = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.resp_prod_name)) {
      errorsForm.resp_prod_name = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.resp_prod_contactname)) {
      errorsForm.resp_prod_contactname = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.resp_prod_street)) {
      errorsForm.resp_prod_street = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.resp_prod_phone)) {
      errorsForm.resp_prod_phone = 'Text_TextNoValid';
    }
    if (formFrom.resp_prod_email!=="" && regexEmail.test(formFrom.resp_prod_email)) {
      errorsForm.resp_prod_email = 'Text_Email_Invalid';
    }
    if (regexInvalidCharacters.test(formFrom.resp_prod_nima)) {
      errorsForm.resp_prod_nima = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.resp_prod_cnae)) {
      errorsForm.resp_prod_cnae = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.resp_prod_inscription)) {
      errorsForm.resp_prod_inscription = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.resp_prod_opt)) {
      errorsForm.resp_prod_opt = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.resp_prod_licenseplate)) {
      errorsForm.resp_prod_licenseplate = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.resp_prod_economicactivity)) {
      errorsForm.resp_prod_economicactivity = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.codeler_code)) {
      errorsForm.codeler_code = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.description_code)) {
      errorsForm.description_code = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.charac_danger)) {
      errorsForm.charac_danger = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.observations)) {
      errorsForm.observations = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.reason_refund)) {
      errorsForm.reason_refund = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.action_refund)) {
      errorsForm.action_refund = 'Text_TextNoValid';
    }
    return errorsForm;
  };

  const handleConfirmFetchDataEditForm = () => { 
    let isMounted = true;
    const fetchData = async () => {
      setIsLoadingData(true);
      try {
        const getData = id !== "0"
          ? await helpHttp().put(ENDPOINT, `form/updateformdi`, token, formFrom)
          : await helpHttp().post(ENDPOINT, `form/saveformdi`, token, formFrom);
        if (getData.status === "OK") {
          dispatch(successErrorAction(t('Text_SaveData')));
        } else {
          dispatch(warningErrorAction(t(getData.cause)));
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData')));
        setIsLoadingData(false);
      } 
      if (isMounted) {
        setIsLoadingData(false);
      } 
    };
    fetchData();
    handleCloseDataEditForm();
    return () => { isMounted = false;};
  };
 
  const handleSubmitDataEditForm = () => {
    const errors = validateFormForm();
    setErrorsForm(errors);
    if (Object.keys(errors).length === 0 && formFrom.preiddoc_sheet !== '') {
       
      const hasDocumentId = [
        formFrom.opt_tras_documentid,
        formFrom.ori_tras_documentid,
        formFrom.aut_tras_documentid,
        formFrom.dest_tras_documentid,
        formFrom.aut_dest_documentid,
        formFrom.rel_trans_documentid,
        formFrom.resp_prod_documentid,
      ].some(id => id !== '');
  
      if (share_form_view || sendbyemail_form_view) {
        hasDocumentId ? setOpenShareFormDI(true) : handleConfirmFetchDataEditForm();
      } else {
        handleConfirmFetchDataEditForm();
      }
    } else {
      const errorMessage = errors.preiddoc_sheet ? t(errors.preiddoc_sheet) : t('Text_Field_Sheet_Invalid');
      dispatch(warningErrorAction(errorMessage));
    }
  }; 

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event && typeof event === 'object' && event.key) {
        keyPress(event);
      }
    };
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [keyPress]);

  useEffect(() => {
    let isMounted = true;

    const fetchDataRoadType = async () => {
      try {
        const getData = i18n.language === "es"
          ? await helpHttp().get(ENDPOINT, `roadtype/roadtypesorderbynamees`, token)
          : await helpHttp().get(ENDPOINT, `roadtype/roadtypesorderbynameen`, token);
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          setRoadTypesToSelect(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };

    const fetchDataCommunity = async () => {
      try {
        const getData = i18n.language === "es"
        ? await helpHttp().get(ENDPOINT, `community/simplecommunitiesbynamees`, token)
        : await helpHttp().get(ENDPOINT, `community/simplecommunitiesbynameen`, token);
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          setCommunityToSelect(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };

    const fetchDataCountry = async () => {
      try {
        const getData = i18n.language === "es"
          ? await helpHttp().get(ENDPOINT, `country/simplecountriesbynamees`, token)
          : await helpHttp().get(ENDPOINT, `country/simplecountriesbynameen`, token);
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          setCountryToSelect(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };

    const fetchDataDocumentType = async () => {
      try {
        const getData = await helpHttp().get(ENDPOINT, `documenttype/simpledocumenttypesbyname`, token);
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          setDocumentTypesToSelect(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };

    const fetchDataTreatmentType = async () => {
      try {
        const getData = await helpHttp().get(ENDPOINT, `treatmenttype/treatmenttypesorderbycode`, token);
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          setTreatmenttypeToSelect(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };

    const fetchDataProcessType = async () => {
      try {
        const getData = await helpHttp().get(ENDPOINT, `processtype/processtypesorderbycode`, token);
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          setProcesstypeToSelect(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };

    const fetchDataDangerousness = async () => {
      try {
        const getData = i18n.language==='es' 
          ?await helpHttp().get(ENDPOINT, `dangerousness/dangerousnessorderbynamees`, token)
          :await helpHttp().get(ENDPOINT, `dangerousness/dangerousnessorderbynameen`, token);
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          setDangerousness(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };

    if (formFrom.id!==null){
      fetchDataDocumentType();
      fetchDataRoadType();
      fetchDataCommunity();
      fetchDataCountry();
      fetchDataTreatmentType();
      fetchDataProcessType();
      fetchDataDangerousness();

    }
    return () => { isMounted = false };
  }, [ENDPOINT,t,token,dispatch,i18n,roles,formFrom.id,showModalFormSettings]);

  useEffect(() => {
    let isMounted = true;
    const fetchExistCodeLer = async () => {
      try {
        const getData = await helpHttp().get2(ENDPOINT,`form/getexistcodelerbycode/`,token,formFrom.codeler_code);
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
          
        } else if (isMounted) {
          if (getData!==undefined && getData!==null && getData!=="" && getData>0){
            fetchCodeLerCode();
          }else{
            setFormForm(formFrom => ({
              ...formFrom,
              codeler_id: 0,
              codeler_namees: "",
              codeler_nameen: "" 
            }));
            
          }
        }  
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };
    const fetchCodeLerCode = async () => {
      try {
        const getData = await helpHttp().get2(ENDPOINT,`form/getformcodelerfindbycode/`,token,formFrom.codeler_code);
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
           
        } else if (isMounted) {
          if (getData!==undefined && getData!==null && getData!==""){
            setFormForm(formFrom => ({
                ...formFrom,
                codeler_id: getData.id, 
                codeler_namees: getData.namees,
                codeler_nameen: getData.nameen    
              }));
        }  
        }  
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };
    if (formFrom.codeler_code!==""){
      fetchExistCodeLer();
    }
    return () => { isMounted = false };
}, [ENDPOINT,t,token,dispatch,formFrom.codeler_code,setIsLoadingErrorData,setFormForm]);

useEffect(() => {
  let isMounted = true;
  const fetchExistDescription = async () => {
    try {
      const getData = await helpHttp().get2(ENDPOINT,`form/getexistdescriptionbycode/`,token,formFrom.description_code);
      if (getData.err) {
        dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        
      } else if (isMounted) {
        if (getData!==undefined && getData!==null && getData!=="" && getData>0){
          fetchDescriptionCode();
        }else{
          setFormForm(formFrom => ({
            ...formFrom,
            description_id: 0,
            description_namees: "",
            description_nameen: "" 
          }));
          
        }
      }  
    } catch (error) {
      dispatch(warningErrorAction(t('Text_IncorrectData'))); 
    }
  };
  const fetchDescriptionCode = async () => {
    try {
      const getData = await helpHttp().get2(ENDPOINT,`form/getformdescriptionfindbycode/`,token,formFrom.description_code);
      if (getData.err) {
        dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        
      } else if (isMounted) {
        if (getData!==undefined && getData!==null && getData!==""){
          setFormForm(formFrom => ({
            ...formFrom,
            description_id: getData.id, 
            description_namees: getData.namees,
            description_nameen: getData.nameen
          }));
      }  
      }  
    } catch (error) {
      dispatch(warningErrorAction(t('Text_IncorrectData'))); 
    }
  };
  if (formFrom.description_code!==""){
    fetchExistDescription();
  }
  return () => { isMounted = false };
  }, [ENDPOINT,t,token,dispatch,formFrom.description_code,setIsLoadingErrorData,setFormForm]);

  useEffect(() => {
    let isMounted = true;
    const fetchDataForm = async () => {
      setIsLoadingData(true);
      try {
        const getData = await  helpHttp().get2(ENDPOINT,`form/getformdibyId/`,token,id);
        if (getData.err) {
          setFormForm(initialStateForm);
          setIsLoadingErrorData(true);
          dispatch(warningErrorAction(t(getData.message === "Load failed" ? 'Text_ConnectionFailied' : 'Text_InvalidCredentials')));
        } else if (isMounted) {
           setFormForm(getData);
        }  
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData')));
        setIsLoadingData(false);
      }
      if (isMounted) {
        setIsLoadingData(false);
      } 
    };

    if (id!=="0"){
      if (formFrom.id===""){
        fetchDataForm();
      }

    }else{
      setFormForm(initialStateForm);
      setFormForm(formFrom => ({
        ...formFrom,
        ownformdi_id:0,
        defaultStore_id:defaultStore.id,
        useraccount_id:useraccount_id,
        defaultformatnumber:defaulttypesdi.id,
        isFinished:false,
        isScanned:false,
        preiddoc_sheet:defaulttypesdi.default_preiddoc_sheet,
        sufiddoc_sheet:defaulttypesdi.default_sufiddoc_sheet!==undefined && defaulttypesdi.default_sufiddoc_sheet!=="" && defaulttypesdi.default_sufiddoc_sheet!=="0" && defaulttypesdi.default_sufiddoc_sheet!==0?defaulttypesdi.default_sufiddoc_sheet:0,
        number_dt:defaulttypesdi.default_number_dt,

        formopttrasstore_id:0,
        opt_tras_store_id:defaulttypesdi.default_opt_tras_id,
        opt_tras_documnettype_id:defaulttypesdi.default_opt_tras_documnettype_id!==0?defaulttypesdi.default_opt_tras_documnettype_id:Number(DEFAULTTYPEOFDOCUMENT),
        opt_tras_documentid:defaulttypesdi.default_opt_tras_documentid,
        opt_tras_tradename:defaulttypesdi.default_opt_tras_tradename,
        opt_tras_name:defaulttypesdi.default_opt_tras_name,
        opt_tras_contactname:defaulttypesdi.default_opt_tras_contactname,
        opt_tras_roadtype_id:defaulttypesdi.default_opt_tras_roadtype_id,
        opt_tras_street:defaulttypesdi.default_opt_tras_street,
        opt_tras_country_id:defaulttypesdi.default_opt_tras_country_id!==0?defaulttypesdi.default_opt_tras_country_id:Number(DEFAULTCOUNTRY),
        opt_tras_province_id:defaulttypesdi.default_opt_tras_province_id!==0?defaulttypesdi.default_opt_tras_province_id:Number(DEFAULTPROVINCE),
        opt_tras_postalcode_id:defaulttypesdi.default_opt_tras_postalcode_id,
        opt_tras_community_id:defaulttypesdi.default_opt_tras_community_id!==0?defaulttypesdi.default_opt_tras_community_id:Number(DEFAULTCOMMUNITY),
        opt_tras_phone:defaulttypesdi.default_opt_tras_phone,
        opt_tras_email:defaulttypesdi.default_opt_tras_email,
        opt_tras_nima:defaulttypesdi.default_opt_tras_nima,
        opt_tras_cnae:defaulttypesdi.default_opt_tras_cnae,
        opt_tras_inscription:defaulttypesdi.default_opt_tras_inscription,
        opt_tras_opt:defaulttypesdi.default_opt_tras_opt,
        opt_tras_licenseplate:defaulttypesdi.default_opt_tras_licenseplate,
        opt_tras_economicactivity:defaulttypesdi.default_opt_tras_economicactivity,
        
        formoritrasstore_id:0,
        ori_tras_store_id:defaulttypesdi.default_ori_tras_id,
        ori_tras_documnettype_id:defaulttypesdi.default_ori_tras_documnettype_id!==0?defaulttypesdi.default_ori_tras_documnettype_id:Number(DEFAULTTYPEOFDOCUMENT),
        ori_tras_documentid:defaulttypesdi.default_ori_tras_documentid,
        ori_tras_tradename:defaulttypesdi.default_ori_tras_tradename,
        ori_tras_name:defaulttypesdi.default_ori_tras_name,
        ori_tras_contactname:defaulttypesdi.default_ori_tras_contactname,
        ori_tras_roadtype_id:defaulttypesdi.default_ori_tras_roadtype_id,
        ori_tras_street:defaulttypesdi.default_ori_tras_street,
        ori_tras_country_id:defaulttypesdi.default_ori_tras_country_id!==0?defaulttypesdi.default_ori_tras_country_id:Number(DEFAULTCOUNTRY),
        ori_tras_province_id:defaulttypesdi.default_ori_tras_province_id!==0?defaulttypesdi.default_ori_tras_province_id:Number(DEFAULTPROVINCE),
        ori_tras_postalcode_id:defaulttypesdi.default_ori_tras_postalcode_id,
        ori_tras_community_id:defaulttypesdi.default_ori_tras_community_id!==0?defaulttypesdi.default_ori_tras_community_id:Number(DEFAULTCOMMUNITY),
        ori_tras_phone:defaulttypesdi.default_ori_tras_phone,
        ori_tras_email:defaulttypesdi.default_ori_tras_email,
        ori_tras_nima:defaulttypesdi.default_ori_tras_nima,
        ori_tras_cnae:defaulttypesdi.default_ori_tras_cnae,
        ori_tras_inscription:defaulttypesdi.default_ori_tras_inscription,
        ori_tras_opt:defaulttypesdi.default_ori_tras_opt,
        ori_tras_licenseplate:defaulttypesdi.default_ori_tras_licenseplate,
        ori_tras_economicactivity:defaulttypesdi.default_ori_tras_economicactivity,

        aut_tras_store_id:defaulttypesdi.default_aut_tras_id,
        aut_tras_documnettype_id:defaulttypesdi.default_aut_tras_documnettype_id!==0?defaulttypesdi.default_aut_tras_documnettype_id:Number(DEFAULTTYPEOFDOCUMENT),
        aut_tras_documentid:defaulttypesdi.default_aut_tras_documentid,
        aut_tras_tradename:defaulttypesdi.default_aut_tras_tradename,
        aut_tras_name:defaulttypesdi.default_aut_tras_name,
        aut_tras_contactname:defaulttypesdi.default_aut_tras_contactname,
        aut_tras_roadtype_id:defaulttypesdi.default_aut_tras_roadtype_id,
        aut_tras_street:defaulttypesdi.default_aut_tras_street,
        aut_tras_country_id:defaulttypesdi.default_aut_tras_country_id!==0?defaulttypesdi.default_aut_tras_country_id:Number(DEFAULTCOUNTRY),
        aut_tras_province_id:defaulttypesdi.default_aut_tras_province_id!==0?defaulttypesdi.default_aut_tras_province_id:Number(DEFAULTPROVINCE),
        aut_tras_postalcode_id:defaulttypesdi.default_aut_tras_postalcode_id,
        aut_tras_community_id:defaulttypesdi.default_aut_tras_community_id!==0?defaulttypesdi.default_aut_tras_community_id:Number(DEFAULTCOMMUNITY),
        aut_tras_phone:defaulttypesdi.default_aut_tras_phone,
        aut_tras_email:defaulttypesdi.default_aut_tras_email,
        aut_tras_nima:defaulttypesdi.default_aut_tras_nima,
        aut_tras_cnae:defaulttypesdi.default_aut_tras_cnae,
        aut_tras_inscription:defaulttypesdi.default_aut_tras_inscription,
        aut_tras_opt:defaulttypesdi.default_aut_tras_opt,
        aut_tras_licenseplate:defaulttypesdi.default_aut_tras_licenseplate,
        aut_tras_economicactivity:defaulttypesdi.default_aut_tras_economicactivity,

        formdesttrasstore_id:0,
        dest_tras_store_id:defaulttypesdi.default_dest_tras_id,
        dest_tras_documnettype_id:defaulttypesdi.default_dest_tras_documnettype_id!==0?defaulttypesdi.default_dest_tras_documnettype_id:Number(DEFAULTTYPEOFDOCUMENT),
        dest_tras_documentid:defaulttypesdi.default_dest_tras_documentid,
        dest_tras_tradename:defaulttypesdi.default_dest_tras_tradename,
        dest_tras_name:defaulttypesdi.default_dest_tras_name,
        dest_tras_contactname:defaulttypesdi.default_dest_tras_contactname,
        dest_tras_roadtype_id:defaulttypesdi.default_dest_tras_roadtype_id,
        dest_tras_street:defaulttypesdi.default_dest_tras_street,
        dest_tras_country_id:defaulttypesdi.default_dest_tras_country_id!==0?defaulttypesdi.default_dest_tras_country_id:Number(DEFAULTCOUNTRY),
        dest_tras_province_id:defaulttypesdi.default_dest_tras_province_id!==0?defaulttypesdi.default_dest_tras_province_id:Number(DEFAULTPROVINCE),
        dest_tras_postalcode_id:defaulttypesdi.default_dest_tras_postalcode_id,
        dest_tras_community_id:defaulttypesdi.default_dest_tras_community_id!==0?defaulttypesdi.default_dest_tras_community_id:Number(DEFAULTCOMMUNITY),
        dest_tras_phone:defaulttypesdi.default_dest_tras_phone,
        dest_tras_email:defaulttypesdi.default_dest_tras_email,
        dest_tras_nima:defaulttypesdi.default_dest_tras_nima,
        dest_tras_cnae:defaulttypesdi.default_dest_tras_cnae,
        dest_tras_inscription:defaulttypesdi.default_dest_tras_inscription,
        dest_tras_opt:defaulttypesdi.default_dest_tras_opt,
        dest_tras_licenseplate:defaulttypesdi.default_dest_tras_licenseplate,
        dest_tras_economicactivity:defaulttypesdi.default_dest_tras_economicactivity,

        aut_dest_store_id:defaulttypesdi.default_aut_dest_id,
        aut_dest_documnettype_id:defaulttypesdi.default_aut_dest_documnettype_id!==0?defaulttypesdi.default_aut_dest_documnettype_id:Number(DEFAULTTYPEOFDOCUMENT),
        aut_dest_documentid:defaulttypesdi.default_aut_dest_documentid,
        aut_dest_tradename:defaulttypesdi.default_aut_dest_tradename,
        aut_dest_name:defaulttypesdi.default_aut_dest_name,
        aut_dest_contactname:defaulttypesdi.default_aut_dest_contactname,
        aut_dest_roadtype_id:defaulttypesdi.default_aut_dest_roadtype_id,
        aut_dest_street:defaulttypesdi.default_aut_dest_street,
        aut_dest_country_id:defaulttypesdi.default_aut_dest_country_id!==0?defaulttypesdi.default_aut_dest_country_id:Number(DEFAULTCOUNTRY),
        aut_dest_province_id:defaulttypesdi.default_aut_dest_province_id!==0?defaulttypesdi.default_aut_dest_province_id:Number(DEFAULTPROVINCE),
        aut_dest_postalcode_id:defaulttypesdi.default_aut_dest_postalcode_id,
        aut_dest_community_id:defaulttypesdi.default_aut_dest_community_id!==0?defaulttypesdi.default_aut_dest_community_id:Number(DEFAULTCOMMUNITY),
        aut_dest_phone:defaulttypesdi.default_aut_dest_phone,
        aut_dest_email:defaulttypesdi.default_aut_dest_email,
        aut_dest_nima:defaulttypesdi.default_aut_dest_nima,
        aut_dest_cnae:defaulttypesdi.default_aut_dest_cnae,
        aut_dest_inscription:defaulttypesdi.default_aut_dest_inscription,
        aut_dest_opt:defaulttypesdi.default_aut_dest_opt,
        aut_dest_licenseplate:defaulttypesdi.default_aut_dest_licenseplate,
        aut_dest_economicactivity:defaulttypesdi.default_aut_dest_economicactivity,

        codeler_id:defaulttypesdi.default_codeler_id,
        codeler_code:defaulttypesdi.default_codeler_code,
        codeler_namees:defaulttypesdi.default_codeler_namees,
        codeler_nameen:defaulttypesdi.default_codeler_nameen,
        description_id:defaulttypesdi.default_description_id,
        description_code:defaulttypesdi.default_description_code,
        description_namees:defaulttypesdi.default_description_namees,
        description_nameen:defaulttypesdi.default_description_nameen,

        treatmenttypeorigin_id:defaulttypesdi.default_treatmenttypeorigin_id,
        treatmenttypeorigin_code:defaulttypesdi.default_treatmenttypeorigin_code,
        treatmenttypeorigin_namees:defaulttypesdi.default_treatmenttypeorigin_namees,
        treatmenttypeorigin_nameen:defaulttypesdi.default_treatmenttypeorigin_nameen,

        treatmenttypedestine_id:defaulttypesdi.default_treatmenttypedestine_id,
        treatmenttypedestine_code:defaulttypesdi.default_treatmenttypedestine_code,
        treatmenttypedestine_namees:defaulttypesdi.default_treatmenttypedestine_namees,
        treatmenttypedestine_nameen:defaulttypesdi.default_treatmenttypedestine_nameen,

        processtypepeorigin_id:defaulttypesdi.default_processtypeorigin_id,
        processtypepeorigin_code:defaulttypesdi.default_processtypeorigin_code,
        processtypepeorigin_namees:defaulttypesdi.default_processtypeorigin_namees,
        processtypepeorigin_nameen:defaulttypesdi.default_processtypeorigin_nameen,

        processtypedestine_id:defaulttypesdi.default_processtypedestine_id,
        processtypedestine_code:defaulttypesdi.default_processtypedestine_code,
        processtypedestine_namees:defaulttypesdi.default_processtypedestine_namees,
        processtypedestine_nameen:defaulttypesdi.default_processtypedestine_nameen,

        dangerousness_id:defaulttypesdi.default_dangerousness_id,
        dangerousness_namees:defaulttypesdi.default_dangerousness_namees,
        dangerousness_nameen:defaulttypesdi.default_dangerousness_nameen,

        formreltransstore_id:0,
        rel_trans_store_id:defaulttypesdi.default_rel_trans_id,
        rel_trans_documnettype_id:defaulttypesdi.default_rel_trans_documnettype_id!==0?defaulttypesdi.default_rel_trans_documnettype_id:Number(DEFAULTTYPEOFDOCUMENT),
        rel_trans_documentid:defaulttypesdi.default_rel_trans_documentid,
        rel_trans_tradename:defaulttypesdi.default_rel_trans_tradename,
        rel_trans_name:defaulttypesdi.default_rel_trans_name,
        rel_trans_contactname:defaulttypesdi.default_rel_trans_contactname,
        rel_trans_roadtype_id:defaulttypesdi.default_rel_trans_roadtype_id,
        rel_trans_street:defaulttypesdi.default_rel_trans_street,
        rel_trans_country_id:defaulttypesdi.default_rel_trans_country_id!==0?defaulttypesdi.default_rel_trans_country_id:Number(DEFAULTCOUNTRY),
        rel_trans_province_id:defaulttypesdi.default_rel_trans_province_id!==0?defaulttypesdi.default_rel_trans_province_id:Number(DEFAULTPROVINCE),
        rel_trans_postalcode_id:defaulttypesdi.default_rel_trans_postalcode_id,
        rel_trans_community_id:defaulttypesdi.default_rel_trans_community_id!==0?defaulttypesdi.default_rel_trans_community_id:Number(DEFAULTCOMMUNITY),
        rel_trans_phone:defaulttypesdi.default_rel_trans_phone,
        rel_trans_email:defaulttypesdi.default_rel_trans_email,
        rel_trans_nima:defaulttypesdi.default_rel_trans_nima,
        rel_trans_cnae:defaulttypesdi.default_rel_trans_cnae,
        rel_trans_inscription:defaulttypesdi.default_rel_trans_inscription,
        rel_trans_opt:defaulttypesdi.default_rel_trans_opt,
        rel_trans_licenseplate:defaulttypesdi.default_rel_trans_licenseplate,
        rel_trans_economicactivity:defaulttypesdi.default_resp_prod_economicactivity,

        formrespprodstore_id:0,
        resp_prod_store_id:defaulttypesdi.default_resp_prod_id,
        resp_prod_documnettype_id:defaulttypesdi.default_resp_prod_documnettype_id!==0?defaulttypesdi.default_resp_prod_documnettype_id:Number(DEFAULTTYPEOFDOCUMENT),
        resp_prod_documentid:defaulttypesdi.default_resp_prod_documentid,
        resp_prod_tradename:defaulttypesdi.default_resp_prod_tradename,
        resp_prod_name:defaulttypesdi.default_resp_prod_name,
        resp_prod_contactname:defaulttypesdi.default_resp_prod_contactname,
        resp_prod_roadtype_id:defaulttypesdi.default_resp_prod_roadtype_id,
        resp_prod_street:defaulttypesdi.default_resp_prod_street,
        resp_prod_country_id:defaulttypesdi.default_resp_prod_country_id!==0?defaulttypesdi.default_resp_prod_country_id:Number(DEFAULTCOUNTRY),
        resp_prod_province_id:defaulttypesdi.default_resp_prod_province_id!==0?defaulttypesdi.default_resp_prod_province_id:Number(DEFAULTPROVINCE),
        resp_prod_postalcode_id:defaulttypesdi.default_resp_prod_postalcode_id,
        resp_prod_community_id:defaulttypesdi.default_resp_prod_community_id!==0?defaulttypesdi.default_resp_prod_community_id:Number(DEFAULTCOMMUNITY),
        resp_prod_phone:defaulttypesdi.default_resp_prod_phone,
        resp_prod_email:defaulttypesdi.default_resp_prod_email,
        resp_prod_nima:defaulttypesdi.default_resp_prod_nima,
        resp_prod_cnae:defaulttypesdi.default_resp_prod_cnae,
        resp_prod_inscription:defaulttypesdi.default_resp_prod_inscription,
        resp_prod_opt:defaulttypesdi.default_resp_prod_opt,
        resp_prod_licenseplate:defaulttypesdi.default_resp_prod_licenseplate,
        resp_prod_economicactivity:defaulttypesdi.default_resp_prod_economicactivity,

        opt_tras_shared:share_form_opt_tras,
        ori_tras_shared:share_form_ori_tras,
        aut_tras_shared:share_form_aut_tras,
        dest_tras_shared:share_form_dest_tras,
        aut_dest_shared:share_form_aut_dest,
        rel_trans_shared:share_form_rel_trans,
        resp_prod_shared:share_form_resp_prod,

        sendbyemail_form_opt_tras:sendbyemail_form_opt_tras,
        sendbyemail_form_ori_tras:sendbyemail_form_ori_tras,
        sendbyemail_form_aut_tras:sendbyemail_form_aut_tras,
        sendbyemail_form_dest_tras:sendbyemail_form_dest_tras,
        sendbyemail_form_aut_dest:sendbyemail_form_aut_dest,
        sendbyemail_form_rel_trans:sendbyemail_form_rel_trans,
        sendbyemail_form_resp_prod:sendbyemail_form_resp_prod,

        signature_opt_tras:signature_default,
        signature_dest_tras:signature_default_des_tras,
        signature_refund:""

      }));

      setIsLoadingData(false);
    }
    return () => { isMounted = false };
  }, [ENDPOINT,dispatch,id,t,token,formFrom.id,
    share_form_opt_tras,share_form_ori_tras,share_form_aut_tras,share_form_dest_tras,share_form_aut_dest,share_form_rel_trans,share_form_resp_prod,
    sendbyemail_form_opt_tras,sendbyemail_form_ori_tras,sendbyemail_form_aut_tras,sendbyemail_form_dest_tras,sendbyemail_form_aut_dest,sendbyemail_form_rel_trans,sendbyemail_form_resp_prod,
    DEFAULTTYPEOFDOCUMENT,DEFAULTCOMMUNITY,DEFAULTCOUNTRY,DEFAULTPROVINCE,defaulttypesdi,defaultStore.id,useraccount_id,isLoadingData,signature_default,signature_default_des_tras]);  
 
  return (
      <>
        {showModalFormSettings ? ( 
          <div className="container-fluid">
            <Box sx={{ bgcolor: '#ffffff', width: '920px'}}>
              <form className="createform" onSubmit={handleCloseDataEditForm}>  
                <div className="row"> 
                  <div className="col-xl-12 mb-4"> 
                    <div  className="card border-info shadow">
                      <div className="card-body"> 
                        <div className="row no-gutters align-items-center">
                          <div className="col mr-2">
                            <div id="myDIV">
                              <h1 className="h9NewStyle mb-2">
                                  {isLoadingErrorData?
                                  <i className="fas fa-solid fa-exclamation fa-2x text-danger"/>:
                                  (isLoadingData?
                                  <CircularProgress sx={{ color : "#f6c23e"}}/>:
                                  <i className="fas fa-regular fa-clipboard text-warning"></i>)} 
                                  &nbsp;&nbsp;{id==="0"?t('Text_FORM_New'):t('Text_FORM_Edit')+': '+formFrom.ownformdi_id}
                              </h1>
                              <div>
                              {isLoadingData?
                                <CircularProgress sx={{ color : "#f6c23e"}}/>:
                                  <>
                                    <button type="button" className="buttonCreate" onClick={handleSubmitDataEditForm}>
                                      {id==="0"?t('Button_Create'):t('Button_Update')}
                                    </button><> </>
                                    <button type="button" className="buttonBack" onClick={handleCloseDataEditForm}>{t('Button_Cancel')}</button>
                                  </>
                                }
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {!isLoadingData ?
                <>
                  <div className="row"> 
                    <div className="col-xl-12 mb-4"> 
                    <div  className="card border-info shadow">
                        <div className="card-body"> 
                          <div className="row no-gutters align-items-center">
                            <div className="row p-2"> 
                              <div className="form-group">   
                                <h6 className="h7NewStyle mb-2">{t('Text_FORM_Line1')}</h6>
                                <h6 className="h8NewStyle mb-2">{t('Text_FORM_Line2')}</h6>
                                {/* Divider */}
                                <hr className="sidebar-divider d-none d-md-block"/>
                                <label className="text-primary">{t('Text_FORM_NUM_DI')} :</label>
                                &nbsp;&nbsp;&nbsp;&nbsp; 
                                <TextField
                                  id="preiddoc_sheet" 
                                  name="preiddoc_sheet"
                                  value={formFrom.preiddoc_sheet}
                                  inputProps={{ style: {width: 360}, maxLength: 20 }}
                                  label={errorsForm.preiddoc_sheet!=null?t(errorsForm.preiddoc_sheet):t('PlaceHolder_FORM_PREIDDOC_SHEET')}
                                  placeholder={t('PlaceHolder_FORM_PREIDDOC_SHEET')}
                                  variant="standard"
                                  onBlur={handleBlurDataEditForm}
                                  onChange={handleChangeDataEditForm}/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <label className="text-primary"> / </label>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <NumericFormat 
                                  thousandSeparator={''} 
                                  decimalSeparator={'.'} 
                                  decimalScale= {0}
                                  fixedDecimalScale= {true}
                                  allowNegative= {false}
                                  isAllowed={withValueCap}
                                  id="sufiddoc_sheet" 
                                  name="sufiddoc_sheet"
                                  value={formFrom.sufiddoc_sheet}
                                  label={errorsForm.sufiddoc_sheet!=null?t(errorsForm.sufiddoc_sheet):t('PlaceHolder_FORM_SUFIDDOC_SHEET')}
                                  placeholder={t('PlaceHolder_FORM_SUFIDDOC_SHEET')}
                                  style={{width: 360}}
                                  customInput={TextField}
                                  onBlur={handleBlurDataEditFormAmount}
                                  onChange={handleChangeDataEditFormAmount}
                                />
                              </div>
                            </div>
                            <div className="row p-2"> 
                              {number_dt_view &&
                                <div className="form-group">   
                                  <label className="text-primary">{t('Text_FORM_NT_SHEET')} :</label>
                                  &nbsp;&nbsp;&nbsp;&nbsp; 
                                  <TextField
                                    id="number_dt" 
                                    name="number_dt"
                                    value={formFrom.number_dt}
                                    inputProps={{ style: {width: i18n.language==='es'?293:305}, maxLength: 20 }}
                                    label={errorsForm.number_dt!=null?t(errorsForm.number_dt):t('PlaceHolder_FORM_PREIDDOC_SHEET')}
                                    placeholder={t('PlaceHolder_FORM_PREIDDOC_SHEET')}
                                    variant="standard"
                                    onBlur={handleBlurDataEditForm}
                                    onChange={handleChangeDataEditForm}
                                    disabled={!number_dt_handle}/>
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </div>
                              }
                              <div className="form-group">   
                              <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <label className="text-primary">{t('Text_FORM_DATE')} :</label>
                                &nbsp;&nbsp;&nbsp;&nbsp; 
                                <DesktopDatePicker
                                  id="date_sheet" 
                                  name="date_sheet"
                                  value={ formFrom.date_sheet!== null ? new Date(formFrom.date_sheet):null}
                                  format={i18n.language==="es"?"dd/MM/yyyy":"MM/dd/yyyy"}
                                  inputProps={{ style: {width: 250}, maxLength: 100 }}
                                  label={errorsForm.date_sheet!=null?t(errorsForm.date_sheet):t('PlaceHolder_FORM_DATE')}
                                  onBlur={handleBlurSelectDate}
                                  onChange={handleChangeSelectDate}
                                  slotProps={{
                                    textField: {
                                      placeholder: i18n.language==="es"?"dd/MM/yyyy":"MM/dd/yyyy",
                                      sx: {
                                        maxLength: 100,
                                        width: 250
                                      
                                      }
                                    },
                                  }}
                                />
                              </LocalizationProvider>
                                  
                              </div>
                            </div>
                              {opt_tras_view && 
                                <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} >
                                    <AccordionSummary
                                      expandIcon={<ExpandMoreIcon />}
                                      aria-controls="panel1bh-content"
                                      id="panel1bh-header"
                                      disabled={!opt_tras_expand}
                                      className={classes.backcolor}
                                    >
                                      <Typography className={classes.heading}>{t('Text_FORM_OPT_TRAS')} :</Typography>
                                      <Typography className={classes.secondaryHeading}>
                                        {formFrom.opt_tras_tradename!==undefined && formFrom.opt_tras_tradename!==null && formFrom.opt_tras_tradename!==""?formFrom.opt_tras_tradename:t('Text_NOT_REPORTING')}
                                        {formFrom.opt_tras_documentid!=="" && formFrom.opt_tras_tradename===""?<span className={classes.thirdHeading}> - {t('Text_Field_Tradename_Sheet_Required')} !!</span>:""}
                                        {errorsForm.opt_tras_email!==undefined && errorsForm.opt_tras_email!==""?<span className={classes.thirdHeading}>  -  {t('Text_Field_Email_Sheet_Invalid')} !!</span>:""}
                                        {(errorsForm.opt_tras_documentid!==undefined && errorsForm.opt_tras_documentid!=="") ||
                                        (errorsForm.opt_tras_tradename!==undefined && errorsForm.opt_tras_tradename!=="") ||
                                        (errorsForm.opt_tras_name!==undefined && errorsForm.opt_tras_name!=="") ||
                                        (errorsForm.opt_tras_contactname!==undefined && errorsForm.opt_tras_contactname!=="") ||
                                        (errorsForm.opt_tras_street!==undefined && errorsForm.opt_tras_street!=="") ||
                                        (errorsForm.opt_tras_phone!==undefined && errorsForm.opt_tras_phone!=="") ||
                                        (errorsForm.opt_tras_nima!==undefined && errorsForm.opt_tras_nima!=="") ||
                                        (errorsForm.opt_tras_cnae!==undefined && errorsForm.opt_tras_cnae!=="") ||
                                        (errorsForm.opt_tras_inscription!==undefined && errorsForm.opt_tras_inscription!=="") ||
                                        (errorsForm.opt_tras_opt!==undefined && errorsForm.opt_tras_opt!=="") ||
                                        (errorsForm.opt_tras_economicactivity!==undefined && errorsForm.opt_tras_economicactivity!=="") ||
                                        (errorsForm.opt_tras_licenseplate!==undefined && errorsForm.opt_tras_licenseplate!==""
                                        )
                                        ?<span className={classes.thirdHeading}>  -  {t('Text_Field_Character_Sheet_Invalid')} !!</span>:""}
                                      </Typography>
                                    </AccordionSummary>
                                 
                                    <AccordionDetails>
                                      <FormCreateOrEditOptTras
                                          formFrom={formFrom}
                                          setFormForm={setFormForm}
                                          showModalFormSettings={showModalFormSettings}
                                          setIsLoadingErrorData={setIsLoadingErrorData}
                                          errorsForm={errorsForm}
                                          setErrorsForm={setErrorsForm}
                                          validateFormForm={validateFormForm}
                                          documentTypesToSelect={documentTypesToSelect}
                                          roadTypesToSelect={roadTypesToSelect}
                                          communityToSelect={communityToSelect}
                                          countryToSelect={countryToSelect}
                                          expanded={expanded}
                                        />
                                    </AccordionDetails>
                                
                               </Accordion>
                            }
                            {ori_tras_view && 
                              <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls="panel2bh-content"
                                  id="panel2bh-header"
                                  disabled={!ori_tras_expand}
                                  className={classes.backcolor}
                                >
                                    <Typography className={classes.heading}>{t('Text_FORM_ORI_TRAS')} : <span>{t('Text_FORM_ORI_TRAS_PROD')}</span></Typography>
                                    <Typography className={classes.secondaryHeading}>
                                      {formFrom.ori_tras_tradename!==undefined && formFrom.ori_tras_tradename!==null && formFrom.ori_tras_tradename!==""?formFrom.ori_tras_tradename:t('Text_NOT_REPORTING')}
                                      {formFrom.ori_tras_documentid!=="" && formFrom.ori_tras_tradename===""?<span className={classes.thirdHeading}> - {t('Text_Field_Tradename_Sheet_Required')} !!</span>:""}
                                      {errorsForm.ori_tras_email!==undefined && errorsForm.ori_tras_email!==""?<span className={classes.thirdHeading}>  -  {t('Text_Field_Email_Sheet_Invalid')} !!</span>:""}
                                      {(errorsForm.ori_tras_documentid!==undefined && errorsForm.ori_tras_documentid!=="") ||
                                      (errorsForm.ori_tras_tradename!==undefined && errorsForm.ori_tras_tradename!=="") ||
                                      (errorsForm.ori_tras_name!==undefined && errorsForm.ori_tras_name!=="") ||
                                      (errorsForm.ori_tras_contactname!==undefined && errorsForm.ori_tras_contactname!=="") ||
                                      (errorsForm.ori_tras_street!==undefined && errorsForm.ori_tras_street!=="") ||
                                      (errorsForm.ori_tras_phone!==undefined && errorsForm.ori_tras_phone!=="") ||
                                      (errorsForm.ori_tras_nima!==undefined && errorsForm.ori_tras_nima!=="") ||
                                      (errorsForm.ori_tras_cnae!==undefined && errorsForm.ori_tras_cnae!=="") ||
                                      (errorsForm.ori_tras_inscription!==undefined && errorsForm.ori_tras_inscription!=="") ||
                                      (errorsForm.ori_tras_opt!==undefined && errorsForm.ori_tras_opt!=="") ||
                                      (errorsForm.ori_tras_economicactivity!==undefined && errorsForm.ori_tras_economicactivity!=="") ||
                                      (errorsForm.ori_tras_licenseplate!==undefined && errorsForm.ori_tras_licenseplate!=="")
                                      ?<span className={classes.thirdHeading}>  -  {t('Text_Field_Character_Sheet_Invalid')} !!</span>:""}
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <FormCreateOrEditOriTras
                                      formFrom={formFrom}
                                      setFormForm={setFormForm}
                                      showModalFormSettings={showModalFormSettings}
                                      setIsLoadingErrorData={setIsLoadingErrorData}
                                      errorsForm={errorsForm}
                                      setErrorsForm={setErrorsForm}
                                      validateFormForm={validateFormForm}
                                      documentTypesToSelect={documentTypesToSelect}
                                      roadTypesToSelect={roadTypesToSelect}
                                      communityToSelect={communityToSelect}
                                      countryToSelect={countryToSelect} 
                                      expanded={expanded}
                                  />
                                </AccordionDetails>
                              </Accordion>
                            }
                            {aut_tras_view && 
                              <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls="panel3bh-content"
                                  id="panel3bh-header"
                                  disabled={!aut_tras_expand}
                                  className={classes.backcolor}
                                >
                                    <Typography className={classes.heading}>{t('Text_FORM_ORI_TRAS')} : <span>{t('Text_FORM_ORI_TRAS_EMPR')}</span></Typography>
                                    <Typography className={classes.secondaryHeading}>
                                      {formFrom.aut_tras_tradename!==undefined && formFrom.aut_tras_tradename!==null && formFrom.aut_tras_tradename!==""?formFrom.aut_tras_tradename:t('Text_NOT_REPORTING')}
                                      {formFrom.aut_tras_documentid!=="" && formFrom.aut_tras_tradename===""?<span className={classes.thirdHeading}> - {t('Text_Field_Tradename_Sheet_Required')} !!</span>:""}
                                      {errorsForm.aut_tras_email!==undefined && errorsForm.aut_tras_email!==""?<span className={classes.thirdHeading}>  -  {t('Text_Field_Email_Sheet_Invalid')} !!</span>:""}
                                      {(errorsForm.aut_tras_documentid!==undefined && errorsForm.aut_tras_documentid!=="") ||
                                      (errorsForm.aut_tras_tradename!==undefined && errorsForm.aut_tras_tradename!=="") ||
                                      (errorsForm.aut_tras_name!==undefined && errorsForm.aut_tras_name!=="") ||
                                      (errorsForm.aut_tras_contactname!==undefined && errorsForm.aut_tras_contactname!=="") ||
                                      (errorsForm.aut_tras_street!==undefined && errorsForm.aut_tras_street!=="") ||
                                      (errorsForm.aut_tras_phone!==undefined && errorsForm.aut_tras_phone!=="") ||
                                      (errorsForm.aut_tras_nima!==undefined && errorsForm.aut_tras_nima!=="") ||
                                      (errorsForm.aut_tras_cnae!==undefined && errorsForm.aut_tras_cnae!=="") ||
                                      (errorsForm.aut_tras_inscription!==undefined && errorsForm.aut_tras_inscription!=="") ||
                                      (errorsForm.aut_tras_opt!==undefined && errorsForm.aut_tras_opt!=="") ||
                                      (errorsForm.aut_tras_economicactivity!==undefined && errorsForm.aut_tras_economicactivity!=="") ||
                                      (errorsForm.aut_tras_licenseplate!==undefined && errorsForm.aut_tras_licenseplate!=="")
                                      ?<span className={classes.thirdHeading}>  -  {t('Text_Field_Character_Sheet_Invalid')} !!</span>:""}
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <FormCreateOrEditAutTras
                                      formFrom={formFrom}
                                      setFormForm={setFormForm}
                                      showModalFormSettings={showModalFormSettings}
                                      setIsLoadingErrorData={setIsLoadingErrorData}
                                      errorsForm={errorsForm}
                                      setErrorsForm={setErrorsForm}
                                      validateFormForm={validateFormForm}
                                      documentTypesToSelect={documentTypesToSelect}
                                      roadTypesToSelect={roadTypesToSelect}
                                      communityToSelect={communityToSelect}
                                      countryToSelect={countryToSelect} 
                                      expanded={expanded}
                                  />
                                </AccordionDetails>
                              </Accordion>
                            }
                            {dest_tras_view && 
                              <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls="panel4bh-content"
                                  id="panel4bh-header"
                                  disabled={!dest_tras_expand}
                                  className={classes.backcolor}
                                >
                                    <Typography className={classes.heading}>{t('Text_FORM_DEST_TRAS')} : <span>{t('Text_FORM_DEST_TRAS_PROD')}</span></Typography>
                                    <Typography className={classes.secondaryHeading}>
                                      {formFrom.dest_tras_tradename!==undefined && formFrom.dest_tras_tradename!==null && formFrom.dest_tras_tradename!==""?formFrom.dest_tras_tradename:t('Text_NOT_REPORTING')}
                                      {formFrom.dest_tras_documentid!=="" && formFrom.dest_tras_tradename===""?<span className={classes.thirdHeading}> - {t('Text_Field_Tradename_Sheet_Required')} !!</span>:""}
                                      {errorsForm.dest_tras_email!==undefined && errorsForm.dest_tras_email!==""?<span className={classes.thirdHeading}>  -  {t('Text_Field_Email_Sheet_Invalid')} !!</span>:""}
                                      {(errorsForm.dest_tras_documentid!==undefined && errorsForm.dest_tras_documentid!=="") ||
                                      (errorsForm.dest_tras_tradename!==undefined && errorsForm.dest_tras_tradename!=="") ||
                                      (errorsForm.dest_tras_name!==undefined && errorsForm.dest_tras_name!=="") ||
                                      (errorsForm.dest_tras_contactname!==undefined && errorsForm.dest_tras_contactname!=="") ||
                                      (errorsForm.dest_tras_street!==undefined && errorsForm.dest_tras_street!=="") ||
                                      (errorsForm.dest_tras_phone!==undefined && errorsForm.dest_tras_phone!=="") ||
                                      (errorsForm.dest_tras_nima!==undefined && errorsForm.dest_tras_nima!=="") ||
                                      (errorsForm.dest_tras_cnae!==undefined && errorsForm.dest_tras_cnae!=="") ||
                                      (errorsForm.dest_tras_inscription!==undefined && errorsForm.dest_tras_inscription!=="") ||
                                      (errorsForm.dest_tras_opt!==undefined && errorsForm.dest_tras_opt!=="") ||
                                      (errorsForm.dest_tras_economicactivity!==undefined && errorsForm.dest_tras_economicactivity!=="") ||
                                      (errorsForm.dest_tras_licenseplate!==undefined && errorsForm.dest_tras_licenseplate!=="")
                                      ?<span className={classes.thirdHeading}>  -  {t('Text_Field_Character_Sheet_Invalid')} !!</span>:""}
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <FormCreateOrEditDestTras
                                      formFrom={formFrom}
                                      setFormForm={setFormForm}
                                      showModalFormSettings={showModalFormSettings}
                                      setIsLoadingErrorData={setIsLoadingErrorData}
                                      errorsForm={errorsForm}
                                      setErrorsForm={setErrorsForm}
                                      validateFormForm={validateFormForm}
                                      documentTypesToSelect={documentTypesToSelect}
                                      roadTypesToSelect={roadTypesToSelect}
                                      communityToSelect={communityToSelect}
                                      countryToSelect={countryToSelect}
                                      expanded={expanded} 
                                  />
                                </AccordionDetails>
                              </Accordion>
                            }
                            {aut_dest_view &&   
                                <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                                  <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel5bh-content"
                                    id="panel5bh-header"
                                    disabled={!aut_dest_expand}
                                    className={classes.backcolor}
                                  >
                                      <Typography className={classes.heading}>{t('Text_FORM_DEST_TRAS')} : <span>{t('Text_FORM_DEST_TRAS_EMPR')}</span></Typography>
                                      <Typography className={classes.secondaryHeading}>
                                        {formFrom.aut_dest_tradename!==undefined && formFrom.aut_dest_tradename!==null && formFrom.aut_dest_tradename!==""?formFrom.aut_dest_tradename:t('Text_NOT_REPORTING')}
                                        {formFrom.aut_dest_documentid!=="" && formFrom.aut_dest_tradename===""?<span className={classes.thirdHeading}> - {t('Text_Field_Tradename_Sheet_Required')} !!</span>:""}
                                        {errorsForm.aut_dest_email!==undefined && errorsForm.aut_dest_email!==""?<span className={classes.thirdHeading}>  -  {t('Text_Field_Email_Sheet_Invalid')} !!</span>:""}
                                        {(errorsForm.aut_dest_documentid!==undefined && errorsForm.aut_dest_documentid!=="") ||
                                        (errorsForm.aut_dest_tradename!==undefined && errorsForm.aut_dest_tradename!=="") ||
                                        (errorsForm.aut_dest_name!==undefined && errorsForm.aut_dest_name!=="") ||
                                        (errorsForm.aut_dest_contactname!==undefined && errorsForm.aut_dest_contactname!=="") ||
                                        (errorsForm.aut_dest_street!==undefined && errorsForm.aut_dest_street!=="") ||
                                        (errorsForm.aut_dest_phone!==undefined && errorsForm.aut_dest_phone!=="") ||
                                        (errorsForm.aut_dest_nima!==undefined && errorsForm.aut_dest_nima!=="") ||
                                        (errorsForm.aut_dest_cnae!==undefined && errorsForm.aut_dest_cnae!=="") ||
                                        (errorsForm.aut_dest_inscription!==undefined && errorsForm.aut_dest_inscription!=="") ||
                                        (errorsForm.aut_dest_opt!==undefined && errorsForm.aut_dest_opt!=="") ||
                                        (errorsForm.aut_dest_economicactivity!==undefined && errorsForm.aut_dest_economicactivity!=="") ||
                                        (errorsForm.aut_dest_licenseplate!==undefined && errorsForm.aut_dest_licenseplate!=="")
                                        ?<span className={classes.thirdHeading}>  -  {t('Text_Field_Character_Sheet_Invalid')} !!</span>:""}
                                      </Typography>
                                  </AccordionSummary>
                                  <AccordionDetails>
                                    <FormCreateOrEditAutDest
                                        formFrom={formFrom}
                                        setFormForm={setFormForm}
                                        showModalFormSettings={showModalFormSettings}
                                        setIsLoadingErrorData={setIsLoadingErrorData}
                                        errorsForm={errorsForm}
                                        setErrorsForm={setErrorsForm}
                                        validateFormForm={validateFormForm}
                                        documentTypesToSelect={documentTypesToSelect}
                                        roadTypesToSelect={roadTypesToSelect}
                                        communityToSelect={communityToSelect}
                                        countryToSelect={countryToSelect} 
                                        expanded={expanded}
                                    />
                                  </AccordionDetails>
                                </Accordion>
                              } 
                            <div className="row p-2"> 
                              <div className="form-group">   
                                <h6 className="h7NewStyle mb-2">{t('Text_FORM_CHARAC')}</h6>
                                {/* Divider */}
                                <hr className="sidebar-divider d-none d-md-block"/>
                                <label className="text-primary">{t('Text_FORM_CODE_LER')} :</label>
                                &nbsp;&nbsp;&nbsp;&nbsp; 
                                <TextField
                                  id="codeler_code" 
                                  name="codeler_code"
                                  value={formFrom.codeler_code}
                                  inputProps={{ style: {width: 180}, maxLength: 50 }}
                                  label={errorsForm.codeler_code!=null?t(errorsForm.codeler_code):t('PlaceHolder_FORM_CODE_LER')}
                                  placeholder={t('PlaceHolder_FORM_CODE_LER')}
                                  variant="standard"
                                  onBlur={handleBlurDataEditForm}
                                  onChange={handleChangeDataEditForm}/>
                                &nbsp;&nbsp;&nbsp;&nbsp; 
                                <TextField
                                  id={i18n.language==='es'?"codeler_namees":"codeler_nameen"}
                                  name={i18n.language==='es'?"codeler_namees":"codeler_nameen"}
                                  value={i18n.language==='es'?formFrom.codeler_namees:formFrom.codeler_nameen}
                                  inputProps={{ style: {width:i18n.language==='es'?295:315}, maxLength: 200 }}
                                  label={t('Text_FORM_CODE_DESCRIPTION_TITLE')}
                                  placeholder={t('Text_FORM_CODE_DESCRIPTION_TITLE')}
                                  variant="standard"
                                  onBlur={handleBlurDataEditForm}
                                  onChange={handleChangeDataEditForm}
                                  disabled/>
                                &nbsp;&nbsp;
                                <IconButton  size="small"  style={{color:"#e74a3b", fontSize: 12}} onClick={deleteDataCodeLer}>
                                    <DeleteIcon/>
                                </IconButton>
                                &nbsp;&nbsp;
                                {roles[0].listmenus.find((key)=>(key.name==='SubMenu_Form_View_CodeLERS'))&&
                                    <IconButton  size="small"  style={{color:"#4e73df", fontSize: 12}} onClick={searchFormCodeLer}>
                                        <SearchIcon/>
                                    </IconButton>
                                }
                              </div>
                            </div>
                            {code_description_view && 
                              <div className="row p-2"> 
                                <div className="form-group">   
                                  <label className="text-primary">{t('Text_FORM_CODE_DESCRIPTION')} :</label>
                                  &nbsp;&nbsp;&nbsp;&nbsp; 
                                  <TextField
                                    id="description_code" 
                                    name="description_code"
                                    value={formFrom.description_code}
                                    inputProps={{ style: {width: 225}, maxLength: 50 }}
                                    label={errorsForm.description_code!=null?t(errorsForm.description_code):t('PlaceHolder_FORM_CODE_DESCRIPTION')}
                                    placeholder={t('PlaceHolder_FORM_CODE_DESCRIPTION')}
                                    variant="standard"
                                    onBlur={handleBlurDataEditForm}
                                    onChange={handleChangeDataEditForm}
                                    disabled={!code_description_handle}/>
                                  &nbsp;&nbsp;&nbsp;&nbsp; 
                                  <TextField
                                    id={i18n.language==='es'?"description_namees":"description_nameen"}
                                    name={i18n.language==='es'?"description_namees":"description_nameen"}
                                    value={i18n.language==='es'?formFrom.description_namees:formFrom.description_nameen}
                                    inputProps={{ style: {width:i18n.language==='es'?392:408}, maxLength: 200 }}
                                    label={t('Text_FORM_CODE_DESCRIPTION_TITLE')}
                                    placeholder={t('Text_FORM_CODE_DESCRIPTION_TITLE')}
                                    variant="standard"
                                    onBlur={handleBlurDataEditForm}
                                    onChange={handleChangeDataEditForm}
                                    disabled/>
                                  &nbsp;&nbsp;
                                  {code_description_handle&&
                                    <>
                                      <IconButton  size="small"  style={{color:"#e74a3b", fontSize: 12}} onClick={deleteDataDescription}>
                                          <DeleteIcon/>
                                      </IconButton>
                                      &nbsp;&nbsp;
                                      {roles[0].listmenus.find((key)=>(key.name==='SubMenu_Form_View_Descriptions'))&&
                                          <IconButton  size="small"  style={{color:"#4e73df", fontSize: 12}} onClick={searchFormDescription}>
                                              <SearchIcon/>
                                          </IconButton>
                                      }
                                    </>
                                  }
                                </div>
                              </div>
                            }  
                            {(code_treatmenttype_origin_view ||  code_processtype_origin_view) && 
                              <div className="row p-2"> 
                                  <div className="form-group">   
                                    { code_treatmenttype_origin_view &&
                                      <>
                                        <label className="text-primary">{t('Text_FORM_CODE_TREATMENT_ORIGIN')} :</label>
                                        &nbsp;&nbsp;&nbsp;&nbsp; 
                                        <TextField
                                          id="treatmenttypeorigin_id"
                                          name="treatmenttypeorigin_id"
                                          value={ (formFrom.treatmenttypeorigin_id === undefined || formFrom.treatmenttypeorigin_id ===null || formFrom.treatmenttypeorigin_id ===""
                                                || formFrom.treatmenttypeorigin_id ==="0" || formFrom.treatmenttypeorigin_id ===0)?0:formFrom.treatmenttypeorigin_id}
                                          inputProps={{ style: {width: (code_treatmenttype_origin_view &&  code_processtype_origin_view)?i18n.language==='es'?130:140:i18n.language==='es'?580:590}, maxLength: 100, }}
                                          variant="outlined"
                                          onBlur={handleBlurSelectTreatmentTypeOrigin}
                                          onChange={handleChangeSelectTreatmentTypeOrigin}
                                          disabled={!code_treatmenttype_origin_handle}
                                          select
                                          SelectProps={{
                                            native: true,
                                            value: (formFrom.treatmenttypeorigin_id === undefined || formFrom.treatmenttypeorigin_id ===null || formFrom.treatmenttypeorigin_id ===""
                                                || formFrom.treatmenttypeorigin_id ==="0" || formFrom.treatmenttypeorigin_id ===0)?0: formFrom.treatmenttypeorigin_id
                                          }}>
                                          <option key={0} value=""/>
                                          {treatmenttypeToSelect.map((option) => (
                                          <option key={option.id} value={option.id}>
                                              {i18n.language==="es"?option.code+" - "+option.namees.substring(0,80):option.code+" - "+option.nameen.substring(0,80)}
                                          </option>
                                          ))}
                                        </TextField>
                                        {code_processtype_origin_view && <>  &nbsp;&nbsp;&nbsp;&nbsp;</> }
                                      </>
                                    }
                                    { code_processtype_origin_view &&
                                      <>
                                        <label className="text-primary">{t('Text_FORM_CODE_PROCESS_ORIGIN')} :</label>
                                        &nbsp;&nbsp;&nbsp;&nbsp; 
                                        <TextField
                                          id="processtypepeorigin_id"
                                          name="processtypepeorigin_id"
                                          value={ (formFrom.processtypepeorigin_id === undefined || formFrom.processtypepeorigin_id ===null || formFrom.processtypepeorigin_id ===""
                                                || formFrom.processtypepeorigin_id ==="0" || formFrom.processtypepeorigin_id ===0)?0:formFrom.processtypepeorigin_id}
                                          inputProps={{ style: {width: (code_treatmenttype_origin_view &&  code_processtype_origin_view)?i18n.language==='es'?130:140:i18n.language==='es'?580:590}, maxLength: 100, }}
                                          variant="outlined"
                                          onBlur={handleBlurSelectProcessTypeOrigin}
                                          onChange={handleChangeSelectProcessTypeOrigin}
                                          disabled={!code_processtype_origin_handle}
                                          select
                                          SelectProps={{
                                            native: true,
                                            value: (formFrom.processtypepeorigin_id === undefined || formFrom.processtypepeorigin_id ===null || formFrom.processtypepeorigin_id ===""
                                                || formFrom.processtypepeorigin_id ==="0" || formFrom.processtypepeorigin_id ===0)?0: formFrom.processtypepeorigin_id
                                          }}>
                                          <option key={0} value=""/>
                                          {processtypeToSelect.map((option) => (
                                          <option key={option.id} value={option.id}>
                                              {i18n.language==="es"?option.code+" - "+option.namees.substring(0,80):option.code+" - "+option.nameen.substring(0,80)}
                                          </option>
                                          ))}
                                        </TextField>
                                      </>
                                    }
                                  </div>
                                </div>
                                
                            }
                            {(code_treatmenttype_destine_view ||  code_processtype_destine_view) && 
                              <div className="row p-2"> 
                                  <div className="form-group">   
                                    { code_treatmenttype_destine_view &&
                                      <>
                                        <label className="text-primary">{t('Text_FORM_CODE_TREATMENT_DESTINE')} :</label>
                                        &nbsp;&nbsp;&nbsp;&nbsp; 
                                        <TextField
                                          id="treatmenttypedestine_id"
                                          name="treatmenttypedestine_id"
                                          value={ (formFrom.treatmenttypedestine_id === undefined || formFrom.treatmenttypedestine_id ===null || formFrom.treatmenttypedestine_id ===""
                                                || formFrom.treatmenttypedestine_id ==="0" || formFrom.treatmenttypedestine_id ===0)?0:formFrom.treatmenttypedestine_id}
                                          inputProps={{ style: {width: (code_treatmenttype_destine_view &&  code_processtype_destine_view)?i18n.language==='es'?119:104:i18n.language==='es'?569:554}, maxLength: 100, }}
                                          variant="outlined"
                                          onBlur={handleBlurSelectTreatmentTypeDestine}
                                          onChange={handleChangeSelectTreatmentTypeDestine}
                                          disabled={!code_treatmenttype_destine_handle}
                                          select
                                          SelectProps={{
                                            native: true,
                                            value: (formFrom.treatmenttypedestine_id === undefined || formFrom.treatmenttypedestine_id ===null || formFrom.treatmenttypedestine_id ===""
                                                || formFrom.treatmenttypedestine_id ==="0" || formFrom.treatmenttypedestine_id ===0)?0: formFrom.treatmenttypedestine_id
                                          }}>
                                          <option key={0} value=""/>
                                          {treatmenttypeToSelect.map((option) => (
                                          <option key={option.id} value={option.id}>
                                              {i18n.language==="es"?option.code+" - "+option.namees.substring(0,80):option.code+" - "+option.nameen.substring(0,80)}
                                          </option>
                                          ))}
                                        </TextField>
                                        {code_processtype_destine_view && <>&nbsp;&nbsp;&nbsp;&nbsp;</> }
                                      </>
                                    }
                                    { code_processtype_destine_view &&
                                      <>
                                        <label className="text-primary">{t('Text_FORM_CODE_PROCESS_DESTINE')} :</label>
                                        &nbsp;&nbsp;&nbsp;&nbsp; 
                                        <TextField
                                          id="processtypedestine_id"
                                          name="processtypedestine_id"
                                          value={ (formFrom.processtypedestine_id === undefined || formFrom.processtypedestine_id ===null || formFrom.processtypedestine_id ===""
                                                || formFrom.processtypedestine_id ==="0" || formFrom.processtypedestine_id ===0)?0:formFrom.processtypedestine_id}
                                          inputProps={{ style: {width: (code_treatmenttype_destine_view &&  code_processtype_destine_view)?i18n.language==='es'?119:104:i18n.language==='es'?569:553}, maxLength: 100, }}
                                          variant="outlined"
                                          onBlur={handleBlurSelectProcessTypeDestine}
                                          onChange={handleChangeSelectProcessTypeDestine}
                                          disabled={!code_processtype_destine_handle}
                                          select
                                          SelectProps={{
                                            native: true,
                                            value: (formFrom.processtypedestine_id === undefined || formFrom.processtypedestine_id ===null || formFrom.processtypedestine_id ===""
                                                || formFrom.processtypedestine_id ==="0" || formFrom.processtypedestine_id ===0)?0: formFrom.processtypedestine_id
                                          }}>
                                          <option key={0} value=""/>
                                          {processtypeToSelect.map((option) => (
                                          <option key={option.id} value={option.id}>
                                              {i18n.language==="es"?option.code+" - "+option.namees.substring(0,80):option.code+" - "+option.nameen.substring(0,80)}
                                          </option>
                                          ))}
                                        </TextField>
                                      </>
                                    }
                                  </div>
                                </div>
                            }
                            {code_dangerousness_view && 
                              <div className="row p-2"> 
                                <div className="form-group">   
                                  <label className="text-primary">{t('Text_FORM_CODE_DANGEROUSNESS')} :</label>
                                  &nbsp;&nbsp;&nbsp;&nbsp; 
                                  <TextField
                                    id="dangerousness_id"
                                    name="dangerousness_id"
                                    value={ (formFrom.dangerousness_id === undefined || formFrom.dangerousness_id ===null || formFrom.dangerousness_id ===""
                                          || formFrom.dangerousness_id ==="0" || formFrom.dangerousness_id ===0)?0:formFrom.dangerousness_id}
                                    inputProps={{ style: {width: i18n.language==='es'?628:618}, maxLength: 100, }}
                                    variant="outlined"
                                    onBlur={handleBlurSelectDangerousness}
                                    onChange={handleChangeSelectDangerousness}
                                    disabled={!code_dangerousness_handle}
                                    select
                                    SelectProps={{
                                      native: true,
                                      value: (formFrom.dangerousness_id === undefined || formFrom.dangerousness_id ===null || formFrom.dangerousness_id ===""
                                          || formFrom.dangerousness_id ==="0" || formFrom.dangerousness_id ===0)?0: formFrom.dangerousness_id
                                    }}>
                                    <option key={0} value=""/>
                                    {dangerousness.map((option) => (
                                    <option key={option.id} value={option.id}>
                                        {i18n.language==="es"?option.namees.substring(0,90):option.nameen.substring(0,90)}
                                    </option>
                                    ))}
                                  </TextField>
                                </div>
                              </div>
                            }
                            
                            {charac_danger_view && 
                              <div className="row p-2"> 
                                <div className="form-group">   
                                  <label className="text-primary">{t('Text_FORM_CHARAC_DANGER')} :</label>
                                  &nbsp;&nbsp;&nbsp;&nbsp; 
                                  <TextField
                                    id="charac_danger" 
                                    name="charac_danger"
                                    value={formFrom.charac_danger}
                                    inputProps={{ style: {width: i18n.language==='es'?615:602}, maxLength: 200 }}
                                    label={errorsForm.charac_danger!=null?t(errorsForm.charac_danger):t('PlaceHolder_FORM_CHARAC_DANGER')}
                                    placeholder={t('PlaceHolder_FORM_CHARAC_DANGER')}
                                    variant="standard"
                                    onBlur={handleBlurDataEditForm}
                                    onChange={handleChangeDataEditForm}
                                    disabled={!charac_danger_handle}/>
                                </div>
                              </div>
                            }
                            
                            <div className="row p-2"> 
                              <div className="form-group">
                                <label className="text-primary">{t('Text_FORM_AMOUNT')} :</label>
                                &nbsp;&nbsp;&nbsp;&nbsp; 
                                <NumericFormat 
                                  thousandSeparator={','} 
                                  decimalSeparator={'.'} 
                                  decimalScale= {2}
                                  fixedDecimalScale= {true}
                                  allowNegative= {false}
                                  isAllowed={withValueCap}
                                  id="amount" 
                                  name="amount"
                                  suffix={" kg"}
                                  value={formFrom.amount}
                                  label={errorsForm.amount!=null?t(errorsForm.amount):t('PlaceHolder_FORM_AMOUNT')}
                                  placeholder={t('PlaceHolder_FORM_AMOUNT')}
                                  style={{width: 200}}
                                  customInput={TextField}
                                  onBlur={handleBlurDataEditFormAmount}
                                  onChange={handleChangeDataEditFormAmount}
                                />
                              </div>
                            </div>
                           
                            {rel_trans_view && 
                              <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls="panel6bh-content"
                                  id="panel6bh-header"
                                  disabled={!rel_trans_expand}
                                  className={classes.backcolor}
                                >
                                    <Typography className={classes.heading}>{t('Text_FORM_Carrier')} : </Typography>
                                    <Typography className={classes.secondaryHeading}>
                                      {formFrom.rel_trans_tradename!==undefined && formFrom.rel_trans_tradename!==null && formFrom.rel_trans_tradename!==""?formFrom.rel_trans_tradename:t('Text_NOT_REPORTING')}
                                      {formFrom.rel_trans_documentid!=="" && formFrom.rel_trans_tradename===""?<span className={classes.thirdHeading}> - {t('Text_Field_Tradename_Sheet_Required')} !!</span>:""}
                                      {errorsForm.rel_trans_email!==undefined && errorsForm.rel_trans_email!==""?<span className={classes.thirdHeading}>  -  {t('Text_Field_Email_Sheet_Invalid')} !!</span>:""}
                                      {(errorsForm.rel_trans_documentid!==undefined && errorsForm.rel_trans_documentid!=="") ||
                                      (errorsForm.rel_trans_tradename!==undefined && errorsForm.rel_trans_tradename!=="") ||
                                      (errorsForm.rel_trans_name!==undefined && errorsForm.rel_trans_name!=="") ||
                                      (errorsForm.rel_trans_contactname!==undefined && errorsForm.rel_trans_contactname!=="") ||
                                      (errorsForm.rel_trans_street!==undefined && errorsForm.rel_trans_street!=="") ||
                                      (errorsForm.rel_trans_phone!==undefined && errorsForm.rel_trans_phone!=="") ||
                                      (errorsForm.rel_trans_nima!==undefined && errorsForm.rel_trans_nima!=="") ||
                                      (errorsForm.rel_trans_cnae!==undefined && errorsForm.rel_trans_cnae!=="") ||
                                      (errorsForm.rel_trans_inscription!==undefined && errorsForm.rel_trans_inscription!=="") ||
                                      (errorsForm.rel_trans_opt!==undefined && errorsForm.rel_trans_opt!=="") ||
                                      (errorsForm.rel_trans_economicactivity!==undefined && errorsForm.rel_trans_economicactivity!=="") ||
                                      (errorsForm.rel_trans_licenseplate!==undefined && errorsForm.rel_trans_licenseplate!=="")
                                      ?<span className={classes.thirdHeading}>  -  {t('Text_Field_Character_Sheet_Invalid')} !!</span>:""}
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <FormCreateOrEditRelTrans
                                      formFrom={formFrom}
                                      setFormForm={setFormForm}
                                      showModalFormSettings={showModalFormSettings}
                                      setIsLoadingErrorData={setIsLoadingErrorData}
                                      errorsForm={errorsForm}
                                      setErrorsForm={setErrorsForm}
                                      validateFormForm={validateFormForm}
                                      documentTypesToSelect={documentTypesToSelect}
                                      roadTypesToSelect={roadTypesToSelect}
                                      communityToSelect={communityToSelect}
                                      countryToSelect={countryToSelect} 
                                      expanded={expanded}
                                  />
                                </AccordionDetails>
                              </Accordion>
                            }
                            {resp_prod_view && 
                              <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls="panel7bh-content"
                                  id="panel7bh-header"
                                  disabled={!resp_prod_expand}
                                  className={classes.backcolor}
                                >
                                    <Typography className={classes.heading}>{t('Text_FORM_SRAP')} : <span>{t('Text_FORM_SRAP_DESCRIPTION')}</span></Typography>
                                    <Typography className={classes.secondaryHeading}>
                                      {formFrom.resp_prod_tradename!==undefined && formFrom.resp_prod_tradename!==null && formFrom.resp_prod_tradename!==""?formFrom.resp_prod_tradename:t('Text_NOT_REPORTING')}
                                      {formFrom.resp_prod_documentid!=="" && formFrom.resp_prod_tradename===""?<span className={classes.thirdHeading}> - {t('Text_Field_Tradename_Sheet_Required')} !!</span>:""}
                                      {errorsForm.resp_prod_email!==undefined && errorsForm.resp_prod_email!==""?<span className={classes.thirdHeading}>  -  {t('Text_Field_Email_Sheet_Invalid')} !!</span>:""}
                                      {(errorsForm.resp_prod_documentid!==undefined && errorsForm.resp_prod_documentid!=="") ||
                                      (errorsForm.resp_prod_tradename!==undefined && errorsForm.resp_prod_tradename!=="") ||
                                      (errorsForm.resp_prod_name!==undefined && errorsForm.resp_prod_name!=="") ||
                                      (errorsForm.resp_prod_contactname!==undefined && errorsForm.resp_prod_contactname!=="") ||
                                      (errorsForm.resp_prod_street!==undefined && errorsForm.resp_prod_street!=="") ||
                                      (errorsForm.resp_prod_phone!==undefined && errorsForm.resp_prod_phone!=="") ||
                                      (errorsForm.resp_prod_nima!==undefined && errorsForm.resp_prod_nima!=="") ||
                                      (errorsForm.resp_prod_cnae!==undefined && errorsForm.resp_prod_cnae!=="") ||
                                      (errorsForm.resp_prod_inscription!==undefined && errorsForm.resp_prod_inscription!=="") ||
                                      (errorsForm.resp_prod_opt!==undefined && errorsForm.resp_prod_opt!=="") ||
                                      (errorsForm.resp_prod_economicactivity!==undefined && errorsForm.resp_prod_economicactivity!=="") ||
                                      (errorsForm.resp_prod_licenseplate!==undefined && errorsForm.resp_prod_licenseplate!=="")
                                      ?<span className={classes.thirdHeading}>  -  {t('Text_Field_Character_Sheet_Invalid')} !!</span>:""}
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <FormCreateOrEditRespProd
                                      formFrom={formFrom}
                                      setFormForm={setFormForm}
                                      showModalFormSettings={showModalFormSettings}
                                      setIsLoadingErrorData={setIsLoadingErrorData}
                                      errorsForm={errorsForm}
                                      setErrorsForm={setErrorsForm}
                                      validateFormForm={validateFormForm}
                                      documentTypesToSelect={documentTypesToSelect}
                                      roadTypesToSelect={roadTypesToSelect}
                                      communityToSelect={communityToSelect}
                                      countryToSelect={countryToSelect} 
                                      expanded={expanded}
                                  />
                                </AccordionDetails>
                              </Accordion>
                            }
                            <div className="row p-2"> 
                              <div className="form-group">   
                                <h6 className="h7NewStyle mb-2">{t('Text_FORM_ACCEPTED')}</h6>
                                {/* Divider */}
                                <hr className="sidebar-divider d-none d-md-block"/>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                  <label className="text-primary">{t('Text_FORM_Deliver_date')} :</label>
                                  &nbsp;&nbsp;&nbsp;&nbsp; 
                                  <DesktopDatePicker
                                    id="deliver_date" 
                                    name="deliver_date"
                                    value={ formFrom.deliver_date!== null ? new Date(formFrom.deliver_date): null}
                                    label={errorsForm.deliver_date!=null?t(errorsForm.deliver_date):t('PlaceHolder_FORM_Deliver_date')}
                                    format={i18n.language==="es"?"dd/MM/yyyy":"MM/dd/yyyy"}
                                    onBlur={handleBlurSelectDateDeliver}
                                    onChange={handleChangeSelectDateDeliver}
                                    slotProps={{
                                      textField: {
                                        placeholder: i18n.language==="es"?"dd/MM/yyyy":"MM/dd/yyyy",
                                        sx: {
                                          color: '#1565c0',
                                          maxLength: 250,
                                          width: 300
                                         
                                        }
                                      },
                                    }}
                                  />
                                </LocalizationProvider>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <label className="text-primary">{t('Text_FORM_Delivery_quantity')} :</label>
                                &nbsp;&nbsp;&nbsp;&nbsp; 
                                <NumericFormat 
                                  thousandSeparator={','} 
                                  decimalSeparator={'.'} 
                                  decimalScale= {2}
                                  fixedDecimalScale= {true}
                                  allowNegative= {false}
                                  isAllowed={withValueCap}
                                  id="delivery_quantity" 
                                  name="delivery_quantity"
                                  value={formFrom.delivery_quantity}
                                  label={errorsForm.delivery_quantity!=null?t(errorsForm.delivery_quantity):t('PlaceHolder_FORM_Delivery_quantity')}
                                  placeholder={t('PlaceHolder_FORM_Delivery_quantity')}
                                  style={{width: i18n.language==='es'?195:272}}
                                  customInput={TextField}
                                  onBlur={handleBlurDataEditFormAmount}
                                  onChange={handleChangeDataEditFormAmount}
                                />
                              </div>
                            </div>

                            <div className="row p-2"> 
                              <div className="form-group">   
                               <label className="text-primary">{t('Text_FORM_Accept')} :</label>
                                &nbsp;&nbsp;&nbsp;&nbsp; 
                                <TextField
                                  id="accept"
                                  name="accept"
                                  value={ (formFrom.accept === undefined || formFrom.accept ===null || formFrom.accept ==="")?t("Text_NOT_REPORTING"):formFrom.accept}
                                  inputProps={{ style: {width: 250}, maxLength: 100, }}
                                  variant="outlined"
                                  onBlur={handleBlurSelectAccepted}
                                  onChange={handleChangeSelectAccepted}
                                  helperText={errorsForm.accept!=null?t(errorsForm.accept):""}
                                  select
                                  SelectProps={{
                                      native: true,
                                      value: (formFrom.accept === undefined || formFrom.accept ===null || formFrom.accept ==="")?t("Text_NOT_REPORTING"): formFrom.accept
                                  }}>
                                  {textAccepted.map((option) => (
                                      <option key={option.id} value={option.name}>
                                          {t(option.name)}
                                      </option>
                                  ))}
                                </TextField>  
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                  <label className="text-primary">{t('Text_FORM_Date_Accepted')} :</label>
                                  &nbsp;&nbsp;&nbsp;&nbsp; 
                                  <DesktopDatePicker
                                    id="date_accepted" 
                                    name="date_accepted"
                                    value={ formFrom.date_accepted!== null ? new Date(formFrom.date_accepted): null}
                                    format={i18n.language==="es"?"dd/MM/yyyy":"MM/dd/yyyy"}
                                    label={errorsForm.date_accepted!=null?t(errorsForm.date_accepted):t('PlaceHolder_FORM_Date_Accepted')}
                                    onBlur={handleBlurSelectDateAccepted}
                                    onChange={handleChangeSelectDateAccepted}
                                    slotProps={{
                                      textField: {
                                        placeholder: i18n.language==="es"?"dd/MM/yyyy":"MM/dd/yyyy",
                                        sx: {
                                          
                                          maxLength: '100',
                                          width: i18n.language==='es'?180:200  
                                        }
                                      },
                                    }}
                                  />
                                </LocalizationProvider>
                              </div>
                            </div>
                            {reason_refund_view  && 
                              <div className="row p-2"> 
                                <div className="form-group">
                                  <label className="text-primary">{t('Text_FORM_Reason_Refund')} :</label>
                                  &nbsp;&nbsp;&nbsp;&nbsp; 
                                  <TextField
                                    id="reason_refund" 
                                    name="reason_refund"
                                    value={formFrom.reason_refund}
                                    inputProps={{ style: {width: i18n.language==='es'?705:693}, maxLength: 100 }}
                                    label={errorsForm.reason_refund!=null?t(errorsForm.reason_refund):t('PlaceHolder_FORM_Reason_Refund')}
                                    placeholder={t('PlaceHolder_FORM_Reason_Refund')}
                                    variant="standard"
                                    onBlur={handleBlurDataEditForm}
                                    onChange={handleChangeDataEditForm}
                                    disabled={!reason_refund_handle}/>
                                </div>
                              </div>
                            }  
                            {(action_refund_view || date_refund_view) && 
                              <div className="row p-2"> 
                                {action_refund_view && 
                                  <div className="form-group">
                                    <label className="text-primary">{t('Text_FORM_Action_Refund')} :</label>
                                    &nbsp;&nbsp;&nbsp;&nbsp; 
                                    <TextField
                                      id="action_refund" 
                                      name="action_refund"
                                      value={formFrom.action_refund}
                                      inputProps={{ style: {width: i18n.language==='es'?260:280}, maxLength: 100 }}
                                      label={errorsForm.action_refund!=null?t(errorsForm.action_refund):t('PlaceHolder_FORM_Action_Refund')}
                                      placeholder={t('PlaceHolder_FORM_Action_Refund')}
                                      variant="standard"
                                      onBlur={handleBlurDataEditForm}
                                      onChange={handleChangeDataEditForm}
                                      disabled={!action_refund_handle}
                                      />
                                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
                                  </div>
                                }
                                {date_refund_view && 
                                  <div className="form-group">
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <label className="text-primary">{t('Text_FORM_Refund')} :</label>
                                    &nbsp;&nbsp;&nbsp;&nbsp; 
                                    <DesktopDatePicker
                                      id="date_refund" 
                                      name="date_refund"
                                      value={ formFrom.date_refund!== null ? new Date(formFrom.date_refund): null}
                                      format={i18n.language==="es"?"dd/MM/yyyy":"MM/dd/yyyy"}
                                      label={errorsForm.date_refund!=null?t(errorsForm.date_refund):t('PlaceHoldert_FORM_Refund')}
                                      onBlur={handleBlurSelectDateRefund}
                                      onChange={handleChangeSelectDateRefund}
                                      disabled={!date_refund_handle}
                                      slotProps={{
                                        textField: {
                                          placeholder: i18n.language==="es"?"dd/MM/yyyy":"MM/dd/yyyy",
                                          sx: {
                                            maxLength: '100',
                                            width: i18n.language==='es'?180:200  
                                          }
                                        },
                                      }}
                                    />
                                  </LocalizationProvider>
                                </div>
                              }  
                            </div>
                          }
                            <div className="row p-2"> 
                              <div className="form-group">   
                                <label className="text-primary">{t('Text_FORM_Observations')} :</label>
                                &nbsp;&nbsp;&nbsp;&nbsp; 
                                <TextField
                                  id="observations" 
                                  name="observations"
                                  value={formFrom.observations}
                                  inputProps={{ style: {width: i18n.language==='es'?712:725}, maxLength: 250 }}
                                  label={errorsForm.observations!=null?t(errorsForm.observations):t('PlaceHolder_FORM_Observations')}
                                  placeholder={t('PlaceHolder_FORM_Observations')}
                                  variant="standard"
                                  onBlur={handleBlurDataEditForm}
                                  onChange={handleChangeDataEditForm}/>
                              </div>
                            </div>
                            <Grid container spacing={4} justify="center">
                              <Grid item xs={4} sm={4} md={4} key={1}>
                                <Card sx={{ width: 250 }}>
                                  <CardMedia
                                    sx={{ height: 150 }}
                                    image={(formFrom.signature_opt_tras!==undefined && formFrom.signature_opt_tras!=="")?formFrom.signature_opt_tras:image1}
                                    title={t("Text_Signature_Opt_Tras")}
                                  />
                                  <CardContent>
                                    <Typography style={{color:"#067330", fontSize: 14, textAlign:'center'}}>
                                      {t( "Text_Signature_Opt_Tras")}
                                    </Typography>
                                  </CardContent>
                                  <CardActions>
                                    <Button size="small" onClick={(e) => selectModalSignature(1)}>{t('Button_Sign')}</Button>
                                    <Button size="small" onClick={(e) => deleteSignature(1)}>{t('Button_Delete')}</Button>
                                  </CardActions>
                                </Card>
                              </Grid>
                              <Grid item xs={4} sm={4} md={4} key={2}>
                                <Card sx={{ width: 250 }}>
                                  <CardMedia
                                    sx={{ height: 150 }}
                                    image={(formFrom.signature_dest_tras!==undefined && formFrom.signature_dest_tras!=="")?formFrom.signature_dest_tras:image1}
                                    title={t("Text_Signature_Dest_Tras")}
                                  />
                                  <CardContent>
                                    <Typography style={{color:"#067330", fontSize: 14, textAlign:'center'}}>
                                      {t("Text_Signature_Dest_Tras")}
                                    </Typography>
                                  </CardContent>
                                  <CardActions>
                                    <Button size="small" onClick={(e) => selectModalSignature(2)}>{t('Button_Sign')}</Button>
                                    <Button size="small" onClick={(e) => deleteSignature(2)}>{t('Button_Delete')}</Button>
                                  </CardActions>
                                </Card>
                              </Grid>
                              <Grid item xs={4} sm={4} md={4} key={3}>
                                <Card sx={{ width: 250 }}>
                                  <CardMedia
                                    sx={{ height: 150 }}
                                    image={formFrom.signature_refund!==undefined && formFrom.signature_refund!==""?formFrom.signature_refund:image1}
                                    title={t("Text_Signature_Refund")}
                                  />
                                  <CardContent>
                                    <Typography style={{color:"#067330", fontSize: 14, textAlign:'center'}}>
                                      {t("Text_Signature_Refund")}
                                    </Typography>
                                  </CardContent>
                                  <CardActions>
                                    <Button size="small" onClick={(e) => selectModalSignature(3)}>{t('Button_Sign')}</Button>
                                    <Button size="small" onClick={(e) => deleteSignature(3)}>{t('Button_Delete')}</Button>
                                  </CardActions>
                                </Card>
                              </Grid>
                            </Grid>
                          </div>
                        </div>   
                      </div>
                    </div>
                </div>
              </>:
                <Loading isLoadingData={isLoadingData}/>
              }
            </form>
          </Box>
          <br/>
          <FormSearchCodeLers
            showModalSearchCodeLERS={showModalSearchCodeLERS}
            setShowModalSearchCodeLERS={setShowModalSearchCodeLERS} 
            setFormForm={setFormForm}
          />
           <FormSearchDescriptions
            showModalSearchDescriptions={showModalSearchDescriptions}
            setShowModalSearchDescriptions={setShowModalSearchDescriptions} 
            setFormForm={setFormForm}
          />
           <FormDIShareEdit
            openShareFormDI={openShareFormDI}
            setOpenShareFormDI={setOpenShareFormDI} 
            formFrom={formFrom}
            setFormForm={setFormForm} 
            setIsLoadingData={setIsLoadingData} 
            handleCloseDataEditForm={handleCloseDataEditForm}
            handleConfirmFetchDataEditForm={handleConfirmFetchDataEditForm}
          /> 
          <Dialog open={openSignOpt_Tras} onClose={handleCancelSignOptTras}>
            <DialogTitle style={{color:"#4e73df", textAlign:'center'}}>{t('Text_Signature_Opt_Tras')} </DialogTitle>
              <DialogContent>
                <div  style={{border:'2px solid #4e73df', width: 250, height: 150 }}>
                  <SignatureCanvas
                    penColor="black"
                    canvasProps={{ width: 250, height: 150, className: 'sigCanvas' }}
                    ref={dataOptTras=>setSignOptTrasCanvas(dataOptTras)}
                  />
                </div>
              </DialogContent>
              <DialogActions>
                <Button style={{color:"#4e73df",fontSize: 16 }} onClick={handleCancelSignOptTras}>{t('Button_Cancel')}</Button>
                <Button style={{color:"#4e73df",fontSize: 16 }} onClick={handleDeleteSignOptTras}>{t('Button_Delete')}</Button>
                <Button variant="contained" style={{color:"#ffffff", background:"#4e73df",fontSize: 16}} onClick={handleSubmitSignOptTras}>{t('Button_Sign')}</Button>
              </DialogActions>
          </Dialog>

          <Dialog open={openSignDest_Tras} onClose={handleCancelSignDestTras}>
            <DialogTitle style={{color:"#4e73df", textAlign:'center'}}>{t('Text_Signature_Dest_Tras')} </DialogTitle>
              <DialogContent>
                <div  style={{border:'2px solid #4e73df', width: 250, height: 150 }}>
                  <SignatureCanvas
                    penColor="black"
                    canvasProps={{ width: 250, height: 150, className: 'sigCanvas' }}
                    ref={dataDestTras=>setSignDestTrasCanvas(dataDestTras)}
                  />
                </div>
              </DialogContent>
              <DialogActions>
                <Button style={{color:"#4e73df",fontSize: 16 }} onClick={handleCancelSignDestTras}>{t('Button_Cancel')}</Button>
                <Button style={{color:"#4e73df",fontSize: 16 }} onClick={handleDeleteSignDestTras}>{t('Button_Delete')}</Button>
                <Button variant="contained" style={{color:"#ffffff", background:"#4e73df",fontSize: 16}} onClick={handleSubmitSignDestTras}>{t('Button_Sign')}</Button>
              </DialogActions>
          </Dialog>

          <Dialog open={openRefund} onClose={handleCancelSignRefund}>
            <DialogTitle style={{color:"#4e73df", textAlign:'center'}}>{t('Text_Signature_Refund')} </DialogTitle>
              <DialogContent>
                <div  style={{border:'2px solid #4e73df', width: 250, height: 150 }}>
                  <SignatureCanvas
                    penColor="black"
                    canvasProps={{ width: 250, height: 150, className: 'sigCanvas' }}
                    ref={dataRefund=>setSignRefundCanvas(dataRefund)}
                  />
                </div>
              </DialogContent>
              <DialogActions>
                <Button style={{color:"#4e73df",fontSize: 16 }} onClick={handleCancelSignRefund}>{t('Button_Cancel')}</Button>
                <Button style={{color:"#4e73df",fontSize: 16 }} onClick={handleDeleteSignRefund}>{t('Button_Delete')}</Button>
                <Button variant="contained" style={{color:"#ffffff", background:"#4e73df",fontSize: 16}} onClick={handleSubmitSignRefund}>{t('Button_Sign')}</Button>
              </DialogActions>
          </Dialog>
        </div>
      //* End Page Content *//
    ) :  null }
  </>
  )    
}