import React from 'react';
import { useSelector } from "react-redux";  
import { useTranslation } from "react-i18next" 
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';  
import { Checkbox } from '@mui/material'; 
import "css/generalSettings.css";   
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import PropTypes from 'prop-types'; 

const FormDIShareEdit = ({ openShareFormDI,setOpenShareFormDI,formFrom,setFormForm,setIsLoadingData,handleCloseDataEditForm,handleConfirmFetchDataEditForm}) => {
    const { t } = useTranslation(['listDataForms']);
    const share_form_handle = useSelector((state) => state.formatDIUser.share_form_handle);
    const sendbyemail_form_handle = useSelector((state) => state.formatDIUser.sendbyemail_form_handle);

    const handleCloseShareFormDI = (e) => {
      setOpenShareFormDI(false);
      setIsLoadingData(false); 
      handleConfirmFetchDataEditForm();
      handleCloseDataEditForm(); 
    };
    
    const handleChangeEnabledDefaultOptions = (e) => {
      const { name, checked } = e.target;
      setFormForm(formFrom => ({
        ...formFrom,
        [name]: checked,
      }));
  };

    return (
        <Dialog  PaperProps={{
            sx: {
              minWidth: 900 
            }
            }}
          open={openShareFormDI} onClose={handleCloseShareFormDI}
        >
          <DialogTitle style={{color:"#067330", fontSize: 24}}>{t('Text_Share_Form')}</DialogTitle>
          <DialogContent dividers>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 850 }} size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell  style={{color:"#067330", fontSize: 16}} ></TableCell>
                    <TableCell style={{color:"#067330", fontSize: 16}} align="left">{t('Text_FORM_Share')}</TableCell>
                    <TableCell style={{color:"#067330", fontSize: 16}} align="left">{t('Text_FORM_Send')}</TableCell>
                    <TableCell style={{color:"#067330", fontSize: 16}} align="left">{t('Text_FORM_CIF')}</TableCell>
                    <TableCell style={{color:"#067330", fontSize: 16}} align="left">{t('Text_FORM_TRADENAME')}</TableCell> 
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell  style={{color:"#067330", fontSize: 16}}  align="left">{t('Text_FORM_OPT_TRAS')}</TableCell>
                    <TableCell align="center">
                      <Checkbox  
                          id="opt_tras_shared"
                          name="opt_tras_shared"
                          style={{color:share_form_handle?"#067330":"#858796", fontSize: 16}} 
                          checked={formFrom.opt_tras_shared} 
                          onChange={handleChangeEnabledDefaultOptions} 
                          disabled={!share_form_handle}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <Checkbox  
                          id="sendbyemail_form_opt_tras"
                          name="sendbyemail_form_opt_tras"
                          style={{color:sendbyemail_form_handle?"#067330":"#858796", fontSize: 16}} 
                          checked={formFrom.sendbyemail_form_opt_tras} 
                          onChange={handleChangeEnabledDefaultOptions} 
                          disabled={!sendbyemail_form_handle}
                      />
                    </TableCell>
                    <TableCell align="left">{formFrom.opt_tras_documentid}</TableCell>
                    <TableCell align="left">{formFrom.opt_tras_tradename}</TableCell> 
                  </TableRow>
                  <TableRow>
                    <TableCell  style={{color:"#067330", fontSize: 16}}  align="left">{t('Text_FORM_ORI_TRAS')}</TableCell>
                    <TableCell align="center">
                      <Checkbox  
                        id="ori_tras_shared"
                        name="ori_tras_shared"
                        style={{color:share_form_handle?"#067330":"#858796", fontSize: 16}} 
                        checked={formFrom.ori_tras_shared} 
                        onChange={handleChangeEnabledDefaultOptions} 
                        disabled={!share_form_handle}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <Checkbox  
                        id="sendbyemail_form_ori_tras"
                        name="sendbyemail_form_ori_tras"
                        style={{color:sendbyemail_form_handle?"#067330":"#858796", fontSize: 16}} 
                        checked={formFrom.sendbyemail_form_ori_tras} 
                        onChange={handleChangeEnabledDefaultOptions} 
                        disabled={!sendbyemail_form_handle}
                      />
                    </TableCell>
                    <TableCell align="left">{formFrom.ori_tras_documentid}</TableCell>
                    <TableCell align="left">{formFrom.ori_tras_tradename}</TableCell> 
                  </TableRow>
                  <TableRow>
                    <TableCell  style={{color:"#067330", fontSize: 16}}  align="left">{t('Text_FORM_ORI_TRAS_EMPR')}</TableCell>
                    <TableCell align="center">
                      <Checkbox  
                        id="aut_tras_shared"
                        name="aut_tras_shared"
                        style={{color:share_form_handle?"#067330":"#858796", fontSize: 16}} 
                        checked={formFrom.aut_tras_shared} 
                        onChange={handleChangeEnabledDefaultOptions} 
                        disabled={!share_form_handle}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <Checkbox  
                        id="sendbyemail_form_aut_tras"
                        name="sendbyemail_form_aut_tras"
                        style={{color:sendbyemail_form_handle?"#067330":"#858796", fontSize: 16}} 
                        checked={formFrom.sendbyemail_form_aut_tras} 
                        onChange={handleChangeEnabledDefaultOptions} 
                        disabled={!sendbyemail_form_handle}
                      />
                    </TableCell>
                    <TableCell align="left">{formFrom.aut_tras_documentid}</TableCell>
                    <TableCell align="left">{formFrom.aut_tras_tradename}</TableCell> 
                  </TableRow>
                  <TableRow>
                    <TableCell  style={{color:"#067330", fontSize: 16}}  align="left">{t('Text_FORM_DEST_TRAS')}</TableCell>
                    <TableCell align="center">
                      <Checkbox  
                        id="dest_tras_shared"
                        name="dest_tras_shared"
                        style={{color:share_form_handle?"#067330":"#858796", fontSize: 16}} 
                        checked={formFrom.dest_tras_shared} 
                        onChange={handleChangeEnabledDefaultOptions} 
                        disabled={!share_form_handle}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <Checkbox  
                        id="sendbyemail_form_dest_tras"
                        name="sendbyemail_form_dest_tras"
                        style={{color:sendbyemail_form_handle?"#067330":"#858796", fontSize: 16}} 
                        checked={formFrom.sendbyemail_form_dest_tras} 
                        onChange={handleChangeEnabledDefaultOptions} 
                        disabled={!sendbyemail_form_handle}
                      />
                    </TableCell>
                    <TableCell align="left">{formFrom.dest_tras_documentid}</TableCell>
                    <TableCell align="left">{formFrom.dest_tras_tradename}</TableCell> 
                  </TableRow>
                  <TableRow>
                    <TableCell  style={{color:"#067330", fontSize: 16}}  align="left">{t('Text_FORM_Carrier')}</TableCell>
                    <TableCell align="center">
                      <Checkbox  
                        id="aut_dest_shared"
                        name="aut_dest_shared"
                        style={{color:share_form_handle?"#067330":"#858796", fontSize: 16}} 
                        checked={formFrom.aut_dest_shared} 
                        onChange={handleChangeEnabledDefaultOptions} 
                        disabled={!share_form_handle}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <Checkbox  
                        id="sendbyemail_form_aut_dest"
                        name="sendbyemail_form_aut_dest"
                        style={{color:sendbyemail_form_handle?"#067330":"#858796", fontSize: 16}} 
                        checked={formFrom.sendbyemail_form_aut_dest} 
                        onChange={handleChangeEnabledDefaultOptions} 
                        disabled={!sendbyemail_form_handle}
                      />
                    </TableCell>
                    <TableCell align="left">{formFrom.aut_dest_documentid}</TableCell>
                    <TableCell align="left">{formFrom.aut_dest_tradename}</TableCell> 
                  </TableRow>
                  <TableRow>
                    <TableCell  style={{color:"#067330", fontSize: 16}}  align="left">{t('Text_FORM_OPT_TRAS')}</TableCell> 
                    <TableCell align="center">
                      <Checkbox  
                        id="rel_trans_shared"
                        name="rel_trans_shared"
                        style={{color:share_form_handle?"#067330":"#858796", fontSize: 16}} 
                        checked={formFrom.rel_trans_shared} 
                        onChange={handleChangeEnabledDefaultOptions} 
                        disabled={!share_form_handle}
                      />
                    </TableCell> 
                    <TableCell align="center">
                      <Checkbox  
                        id="sendbyemail_form_rel_trans"
                        name="sendbyemail_form_rel_trans"
                        style={{color:sendbyemail_form_handle?"#067330":"#858796", fontSize: 16}} 
                        checked={formFrom.sendbyemail_form_rel_trans} 
                        onChange={handleChangeEnabledDefaultOptions} 
                        disabled={!sendbyemail_form_handle}
                      />
                    </TableCell>
                    <TableCell align="left">{formFrom.rel_trans_documentid}</TableCell>
                    <TableCell align="left">{formFrom.rel_trans_tradename}</TableCell> 
                  </TableRow>
                  <TableRow>
                    <TableCell  style={{color:"#067330", fontSize: 16}}  align="left">{t('Text_FORM_SRAP')}</TableCell> 
                    <TableCell align="center">
                      <Checkbox  
                        id="resp_prod_shared"
                        name="resp_prod_shared"
                        style={{color:share_form_handle?"#067330":"#858796", fontSize: 16}}
                        checked={formFrom.resp_prod_shared} 
                        onChange={handleChangeEnabledDefaultOptions} 
                        disabled={!share_form_handle}
                      />
                    </TableCell> 
                    <TableCell align="center">
                      <Checkbox  
                        id="sendbyemail_form_resp_prod"
                        name="sendbyemail_form_resp_prod"
                        style={{color:sendbyemail_form_handle?"#067330":"#858796", fontSize: 16}} 
                        checked={formFrom.sendbyemail_form_resp_prod} 
                        onChange={handleChangeEnabledDefaultOptions} 
                        disabled={!sendbyemail_form_handle}
                      />
                    </TableCell>
                    <TableCell align="right">{formFrom.resp_prod_documentid}</TableCell>
                    <TableCell align="right">{formFrom.resp_prod_tradename}</TableCell> 
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContent>
          <DialogActions>
            <button type="button" className="buttonSendShared" onClick={handleCloseShareFormDI}>
              {t('Button_Shared')}
            </button>
          </DialogActions>
        </Dialog> 
      )
    }
    
    FormDIShareEdit.propTypes = {
      openShareFormDI: PropTypes.bool.isRequired,
      setOpenShareFormDI: PropTypes.func.isRequired,
      formFrom: PropTypes.object.isRequired,
      setFormForm: PropTypes.func.isRequired,
      setIsLoadingData: PropTypes.func.isRequired,
      handleCloseDataEditForm: PropTypes.func.isRequired,
      handleConfirmFetchDataEditForm: PropTypes.func.isRequired
    };
    
    export default FormDIShareEdit;