import { useEffect } from 'react';
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import jsPDF from 'jspdf';
import 'jspdf-autotable'; // Import the jsPDF autoTable plugin
import PropTypes from 'prop-types'; 

const ReportTemplateShipping = ({ dataShipping, initialStatePurchaseDelivery, setDataShipping, setViewPDF }) => {
  const { t, i18n } = useTranslation(['listDataShippings']);
  const headerPrinterShipping = useSelector((state) => state.loginUser.defaultticketsformat.headerPrinterPurchase);
 
  // Función para formatear el número
  function formatNumber(num) {
    return num.toLocaleString('es-ES', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });
  }

  function formatNumberEuro(num) {
    return num.toLocaleString('es-ES', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    }) + ' €';
  }
 
  function getDateFormated(getDate) {
    //let newDate=(getDate !==null && getDate !=="")? new Date(getDate).toLocaleDateString(i18n.language === "es" ? "es-ES" : "en-US"): new Date().toLocaleDateString(i18n.language === "es" ? "es-ES" : "en-US");
    const meses = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
    const hoy = (getDate !==null && getDate !=="")? new Date(getDate):new Date();
    const dia = hoy.getDate();
    const mes = meses[hoy.getMonth()];
    const año = hoy.getFullYear();
    return `${dia} / ${mes} / ${año}`;
  }

  useEffect(() => {
    const generatePDF = () => {
      // Importa jsPDF
      const doc = new jsPDF({
        orientation: 'p', // 'p' para vertical, 'l' para horizontal
        unit: 'mm', // Unidad en milímetros
        format: [210, 297] // Ancho y alto en milímetros
      });  

      let counter=0;
      let yOffset = 10; // Margen superior para comenzar a imprimir

      if (headerPrinterShipping && headerPrinterShipping.length > 0) {
        // Dividimos en líneas
       
        headerPrinterShipping.split('\n').forEach((line) => {
          if (counter===0){
            doc.setFont('helvetica', 'bold');
            doc.setFontSize(10);
          }else{
            doc.setFont('helvetica', 'normal');
            doc.setFontSize(7);
          }
          counter++;
          doc.text(line, 40, yOffset, 'center');
          yOffset += 3;
        });
      }  
      doc.setLineDash([]);
      doc.setFillColor('#FFFFFF');
      doc.setLineWidth(0.3);  
      doc.rect(85, yOffset-3, 75, 0, 'FD'); 
      doc.rect(160, yOffset+30, 45, 5, 'FD'); 
      doc.rect(160, yOffset+35, 45, 34, 'FD'); 
      
      yOffset = 16;
      doc.setFont('helvetica', 'bold');
      doc.setFontSize(14);
      doc.text('ALBARÁN/CARTA DE PORTES N.º', 85, yOffset, 'left');
      doc.setFont('helvetica', 'normal');
      doc.setFontSize(9);
      doc.text('Orden FOM/2861/2012BOE nº 5 de 5 de enero de 2013', 85, yOffset+4, 'left');
      doc.setFont('helvetica', 'bold');
      doc.setFontSize(14);
      const formatted_id = dataShipping.ownshipping_id.toString().padStart(6, '0');
      doc.text(`${formatted_id}`, 195, yOffset, 'right');
      yOffset = 34;
      doc.setFont('helvetica', 'normal');
      doc.setFontSize(10);
      doc.text('CAMIÓN/Matrícula: ', 85, yOffset-4, 'left');
      doc.text(`${dataShipping.vehiclestore_licenseplate.substr(0, 20)}`, 117, yOffset-5, 'left');
      doc.text('Fecha: ', 165, yOffset-4, 'left');
      const newDate = (dataShipping.date_shipping!==undefined && dataShipping.date_shipping!==null && dataShipping.date_shipping!=="")?getDateFormated(dataShipping.date_shipping):"";
      doc.text(`${newDate}`, 205, yOffset-5, 'right');
      doc.text('Destinatario/Cliente: ', 10, yOffset+5, 'left');
      doc.text(`${dataShipping.dest_tras_tradename.substr(0, 54)}`, 43, yOffset+4, 'left');
      doc.text('CIF: ', 165, yOffset+5, 'left');
      doc.text(`${dataShipping.dest_tras_documentid.substr(0, 15)}`, 173, yOffset+4, 'left');
      doc.text('Domicilio: ', 10, yOffset+10, 'left');
      doc.text(`${dataShipping.dest_tras_street.substr(0, 84)}`, 26, yOffset+9, 'left');
      doc.text('Empresa Cargadora: ', 10, yOffset+15, 'left');
      doc.text(`${dataShipping.opt_tras_tradename.substr(0, 44)}`, 44, yOffset+14, 'left');
      doc.text('CIF: ', 165, yOffset+15, 'left');
      doc.text(`${dataShipping.opt_tras_documentid.substr(0, 15)}`, 173, yOffset+14, 'left');
      doc.text('Domicilio: ', 10, yOffset+20, 'left');
      doc.text(`${dataShipping.opt_tras_street.substr(0, 90)}`, 26, yOffset+19, 'left');
      doc.text('Transportista: ', 10, yOffset+25, 'left');
      doc.text(`${dataShipping.rel_trans_tradename.substr(0, 38)}`, 32, yOffset+24, 'left');
      doc.text('D.N.I.: ', 115, yOffset+25, 'left');
      doc.text(`${dataShipping.rel_trans_documentid.substr(0, 19)}`, 126, yOffset+24, 'left');
      doc.text('Origen Carga: ', 10, yOffset+30, 'left');
      doc.text(`${dataShipping.rel_trans_street.substr(0, 64)}`, 33, yOffset+29, 'left');
      doc.text('Conductor: ', 10, yOffset+35, 'left');
      doc.text(`${dataShipping.vehiclestore_defaultdriver.substr(0, 38)}`, 28, yOffset+34, 'left');
      doc.text('D.N.I.: ', 115, yOffset+35, 'left');
      doc.text(`${dataShipping.vehiclestore_documentid.substr(0, 15)}`, 126, yOffset+34, 'left');
      doc.text('Peso: ', 10, yOffset+40, 'left');
      if (dataShipping.weight!=0){
        doc.text(`${formatNumber(dataShipping.weight)}`, 24, yOffset+39, 'left');
      }
      doc.text('Precio: ', 80, yOffset+40, 'left');
      if (dataShipping.price_weight!=0){
        doc.text(`${formatNumberEuro(dataShipping.price_weight)}`, 95, yOffset+39, 'left');
      }
      if (dataShipping.weight*dataShipping.price_weight!=0){
        doc.text(`${formatNumberEuro(dataShipping.weight*dataShipping.price_weight)}`, 203, yOffset+39, 'right');
      }
      doc.text('Servicios: ', 10, yOffset+45, 'left');
      doc.text(`${dataShipping.service_1.substr(0, 62)}`, 26, yOffset+44, 'left');
      if (dataShipping.weight*dataShipping.price_service_1!=0){
        doc.text(`${formatNumberEuro(dataShipping.price_service_1)}`, 203, yOffset+44, 'right');
      }  
      doc.text(`${dataShipping.service_2.substr(0, 62)}`, 26, yOffset+49, 'left');
      if (dataShipping.weight*dataShipping.price_service_2!=0){
        doc.text(`${formatNumberEuro(dataShipping.price_service_2)}`, 203, yOffset+49, 'right');
      }  
      doc.text(`${dataShipping.service_3.substr(0, 62)}`, 26, yOffset+54, 'left');
      if (dataShipping.weight*dataShipping.price_service_3!=0){
        doc.text(`${formatNumberEuro(dataShipping.price_service_3)}`, 203, yOffset+54, 'right');
      }
      doc.text('Obra Destino: ', 10, yOffset+60, 'left');
      doc.text(`${dataShipping.destine_street.substr(0, 63)}`, 33, yOffset+59, 'left');
      let totalIgic=0;
      if (!dataShipping.taxincluded){
        totalIgic=((dataShipping.weight*dataShipping.price_weight)+dataShipping.price_service_1+dataShipping.price_service_2+dataShipping.price_service_3)*(dataShipping.tax/100)
      }
      let totalAmount=(dataShipping.weight*dataShipping.price_weight)+dataShipping.price_service_1+dataShipping.price_service_2+dataShipping.price_service_3+totalIgic;
      doc.setFont('helvetica', 'normal');
      doc.setFontSize(8);
      if (dataShipping.tax!=0){
        doc.text(`${formatNumber(dataShipping.tax)}`, 180, yOffset+59, 'right');
      }  
      if (totalIgic!=0){
        doc.text(`${formatNumberEuro(totalIgic)}`, 203, yOffset+59, 'right');
      }   
      doc.text('Recibí Conforme: ', 10, yOffset+67, 'left');
      doc.text('Encargado Obra: ', 10, yOffset+70, 'left');
      doc.text('El Chófer: ', 90, yOffset+70, 'left');
      doc.text('El Cargador: ', 120, yOffset+70, 'left');
      doc.setFont('helvetica', 'normal');
      doc.setFontSize(8);
      doc.text('I..G.I.C.', 161, yOffset+60, 'left');
      doc.text('%', 182, yOffset+60, 'left');
      doc.setFont('helvetica', 'bold');
      doc.setFontSize(12);
      doc.text('IMPORTES', 173, yOffset+34, 'left');
      doc.text('TOTAL', 159, yOffset+66, 'right');
      if (totalAmount!=0){
        doc.text(`${formatNumberEuro(totalAmount)}`, 203, yOffset+65, 'right');
      }  
      doc.setFont('helvetica', 'normal');
      doc.setFontSize(8);
      doc.text('El transportista no se hace responsable, en ningún momento de los daños que pudieran ocasionar a la mercancía, así como los daños y perjuicios causados', 10, yOffset+110, 'left');
      doc.text('a tuberías, cables y otros similares que no sean visibles.', 10, yOffset+114, 'left');
      

      doc.setLineDash([0.2, 0.2], 0); // [2, 2] define el patrón de los puntos: 2px línea, 2px espacio
      doc.line(117, yOffset-4, 160, yOffset-4); // Dibuja una línea punteada desde (10, 20) hasta (200, 20)
      doc.line(180, yOffset-4, 205, yOffset-4); // Dibuja una línea punteada desde (10, 20) hasta (200, 20)
      doc.line(43, yOffset+5, 160, yOffset+5); // Dibuja una línea punteada desde (10, 20) hasta (200, 20)
      doc.line(173, yOffset+5, 205, yOffset+5); // Dibuja una línea punteada desde (10, 20) hasta (200, 20)
      doc.line(26, yOffset+10, 205, yOffset+10); // Dibuja una línea punteada desde (10, 20) hasta (200, 20)
      doc.line(44, yOffset+15, 160, yOffset+15); // Dibuja una línea punteada desde (10, 20) hasta (200, 20)
      doc.line(173, yOffset+15, 205, yOffset+15); // Dibuja una línea punteada desde (10, 20) hasta (200, 20)
      doc.line(26, yOffset+20, 205, yOffset+20); // Dibuja una línea punteada desde (10, 20) hasta (200, 20)
      doc.line(32, yOffset+25, 110, yOffset+25); // Dibuja una línea punteada desde (10, 20) hasta (200, 20)
      doc.line(126, yOffset+25, 160, yOffset+25); // Dibuja una línea punteada desde (10, 20) hasta (200, 20)
      doc.line(33, yOffset+30, 160, yOffset+30); // Dibuja una línea punteada desde (10, 20) hasta (200, 20)
      doc.line(28, yOffset+35, 110, yOffset+35); // Dibuja una línea punteada desde (10, 20) hasta (200, 20)
      doc.line(126, yOffset+35, 160, yOffset+35); // Dibuja una línea punteada desde (10, 20) hasta (200, 20)
      doc.line(20, yOffset+40, 75, yOffset+40); // Dibuja una línea punteada desde (10, 20) hasta (200, 20)
      doc.line(92, yOffset+40, 205, yOffset+40); // Dibuja una línea punteada desde (10, 20) hasta (200, 20)
      doc.line(26, yOffset+45, 205, yOffset+45); // Dibuja una línea punteada desde (10, 20) hasta (200, 20)
      doc.line(10, yOffset+50, 205, yOffset+50); // Dibuja una línea punteada desde (10, 20) hasta (200, 20)
      doc.line(10, yOffset+55, 205, yOffset+55); // Dibuja una línea punteada desde (10, 20) hasta (200, 20)
      doc.line(33, yOffset+60, 160, yOffset+60); // Dibuja una línea punteada desde (10, 20) hasta (200, 20)
      doc.line(171, yOffset+60, 180, yOffset+60); // Dibuja una línea punteada desde (10, 20) hasta (200, 20)
      doc.line(185, yOffset+60, 205, yOffset+60); // Dibuja una línea punteada desde (10, 20) hasta (200, 20)
      doc.line(160, yOffset+66, 205, yOffset+66); // Dibuja una línea punteada desde (10, 20) hasta (200, 20)
      if (dataShipping.signature_driver!==undefined && dataShipping.signature_driver!==""){
        doc.addImage(`${dataShipping.signature_driver}`, 'PNG', 80, yOffset+75, 20, 20);
      } 
      if (dataShipping.signature_charger!==undefined && dataShipping.signature_charger!==""){
        doc.addImage(`${dataShipping.signature_charger}`, 'PNG', 115, yOffset+75, 20, 20);
      } 
    // Open PDF in a new tab
    const pdfBlob = doc.output('blob');
    const url = URL.createObjectURL(pdfBlob);
    window.open(url, '_blank');
    setViewPDF(false);
    setDataShipping(initialStatePurchaseDelivery);
      
  };

  if (dataShipping) {
    generatePDF();
  }
}, [dataShipping, t,i18n,setViewPDF, setDataShipping, initialStatePurchaseDelivery,headerPrinterShipping]);
  return null; // This component does not render anything in the DOM
};

ReportTemplateShipping.propTypes = {
  dataShipping: PropTypes.object.isRequired, 
  setDataShipping: PropTypes.func.isRequired,
  setViewPDF: PropTypes.func.isRequired
};

export default ReportTemplateShipping;
