import React, {  useEffect, useState,useCallback } from 'react';
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next"  
import { SwipeableDrawer } from '@mui/material';
import { Container, Box } from '@mui/material';
import { warningErrorAction } from "actions/ErrorActions";
import { TextField } from '@mui/material'; 
import { helpHttp } from "service/HelpHttp";
import PropTypes from 'prop-types';  

const initialTreatmentType = [{
  "id": "",
  "indexrowtreatmenttypes":"",
  "treatmenttype_id":"",
  "treatmenttype_code": "",
  "treatmenttype_namees": "",
  "treatmenttype_nameen": ""
}];
  


const ContractCreateTreatmentTypes = ({openEditRowTreatmentTypes,setOpenEditRowTreatmentTypes,newCodeLER,setNewCodeLER}) => {
  const {t,i18n} = useTranslation(['listDataContracts']);
  const token = useSelector((state) => state.loginUser.token); 
  const ENDPOINT = useSelector((state) => state.variablesUser.ENDPOINT); 
  const [newTreatmentType,setNewTreatmentType]= useState(initialTreatmentType[0]);
  const [treatmentTypeToSelect,setTreatmentTypeToSelect]= useState(initialTreatmentType);
  const [errorsTreatmentType, setErrorsTreatmentType] = useState({}); 
  const dispatch = useDispatch();

  const keyPress = useCallback(
    e => {
      if (e.key === 'Escape' && openEditRowTreatmentTypes) {
        setNewTreatmentType(initialTreatmentType[0]);
        setErrorsTreatmentType({});
        setOpenEditRowTreatmentTypes(false); 
        setTreatmentTypeToSelect(initialTreatmentType);
      }
    },[
      setNewTreatmentType,
      setErrorsTreatmentType, 
      setTreatmentTypeToSelect,
      openEditRowTreatmentTypes,
      setOpenEditRowTreatmentTypes]
  );

  const handleCloseNewTraetmentType = () => {
    setNewTreatmentType(initialTreatmentType[0]);
    setErrorsTreatmentType({}); 
    setTreatmentTypeToSelect(initialTreatmentType);
    setOpenEditRowTreatmentTypes(false); 
  };

  const handleChangeNewTreatmentType = (e) => {
    const { name, value } = e.target;
    setNewTreatmentType(newTreatmentType => ({
      ...newTreatmentType,
      [name]: value,
    }));   
  };
  
  const handleBlurNewTreatmentType = (e) => {
    handleChangeNewTreatmentType(e);
    setErrorsTreatmentType(validateFormTreatmentType());
  };
   

  const toggleDrawerNewTreatmentType  = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    if (event.type === 'keydown') {
      handleCloseNewTraetmentType();
    }
  };

  const validateFormTreatmentType = () => {
    let errorsTreatmentType = {};
    let regexInvalidCharacters = /[\\!\\$\\%\\&\\|\\<\\>\\?\\{\\}\\~\\"\\']+/;

    if (newTreatmentType.treatmenttype_code===""){
      errorsTreatmentType.treatmenttype_code = 'Text_CODELER_code';
    }else {
      if (regexInvalidCharacters.test(newTreatmentType.code)) {
        errorsTreatmentType.treatmenttype_code = 'Text_TextNoValid';
      }
    }
  
    if (newTreatmentType.treatmenttype_namees===""){
        errorsTreatmentType.treatmenttype_namees = 'Text_TREATMENTTYPE_NOT_SELECTED';
    }else {
      if (regexInvalidCharacters.test(newTreatmentType.treatmenttype_namees)) {
        errorsTreatmentType.treatmenttype_namees = 'Text_TextNoValid';
      }
    } 

    if (newTreatmentType.treatmenttype_nameen===""){
      errorsTreatmentType.treatmenttype_nameen = 'Text_TREATMENTTYPE_SELECTED';
    }else {
      if (regexInvalidCharacters.test(newTreatmentType.treatmenttype_nameen)) {
        errorsTreatmentType.treatmenttype_nameen = 'Text_TextNoValid';
      }
    }  
    if (newCodeLER.treatmenttypes.find((item) => {return (item.treatmenttype_code.trim()=== newTreatmentType.treatmenttype_code.trim() ) })){
      errorsTreatmentType.treatmenttype_code = 'Text_TREATMENTTYPE_SELECTED';
    } 
    
    return errorsTreatmentType;
  };

  const handleChangeSelectTreatmentType = (event) => { 
    let foundCode="";
    let foundNamees="";
    let foundNameen="";
    if (event.target.value!==""){
      foundCode=treatmentTypeToSelect.find((key=>key.treatmenttype_id===Number(event.target.value))).treatmenttype_code;
      foundNamees=treatmentTypeToSelect.find((key=>key.treatmenttype_id===Number(event.target.value))).treatmenttype_namees;
      foundNameen=treatmentTypeToSelect.find((key=>key.treatmenttype_id===Number(event.target.value))).treatmenttype_nameen;
    }
    setNewTreatmentType(newTreatmentType => ({
        ...newTreatmentType,
        treatmenttype_id:event.target.value,
        treatmenttype_code:foundCode,
        treatmenttype_namees:foundNamees,
        treatmenttype_nameen:foundNameen
    }));
  };

  const handleBlurSelectTreatmentType = (e) => {
    handleChangeSelectTreatmentType(e);
  };


  const handleSubmitNewTreatmentType = () => {  

    setErrorsTreatmentType(validateFormTreatmentType());
    let errorsCheckTreatmentType=validateFormTreatmentType();

    if ((Object.keys(errorsCheckTreatmentType).length === 0) 
          && newTreatmentType.treatmenttype_id!==''  
          && newTreatmentType.treatmenttype_code!==''  
          && newTreatmentType.treatmenttype_namees!=='' 
          && newTreatmentType.treatmenttype_nameen!=='' ) {

      let treatmnetTypeToAdd={
        "id":"",
        "indexrowtreatmenttypes":newCodeLER.treatmenttypes.length>0?newCodeLER.treatmenttypes[newCodeLER.treatmenttypes.length-1].indexrowtreatmenttypes+1:1,    
        "treatmenttype_id":newTreatmentType.treatmenttype_id,
        "treatmenttype_code":newTreatmentType.treatmenttype_code,
        "treatmenttype_namees":newTreatmentType.treatmenttype_namees,
        "treatmenttype_nameen":newTreatmentType.treatmenttype_nameen 
      }
       
      newCodeLER.treatmenttypes.push(treatmnetTypeToAdd);
      setNewCodeLER(newCodeLER => ({
        ...newCodeLER,
      }))
      handleCloseNewTraetmentType();
    } else {
      if (errorsCheckTreatmentType.treatmenttype_code){
        dispatch(warningErrorAction(t(errorsCheckTreatmentType.treatmenttype_code)));
      }
      if (errorsCheckTreatmentType.treatmenttype_namees){
        dispatch(warningErrorAction(t(errorsCheckTreatmentType.treatmenttype_namees)));
      }
      if (errorsCheckTreatmentType.treatmenttype_nameen){
        dispatch(warningErrorAction(t(errorsCheckTreatmentType.treatmenttype_nameen)));
      }
    }  
  };

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event && typeof event === 'object' && event.key) {
        keyPress(event);
      }
    };
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [keyPress]);


  useEffect(() => {
    let isMounted = true;

    const fetchDataTreatmentType = async () => {
      try {
        const getData = await helpHttp().get(ENDPOINT, `contract/treatmenttypesorderbycode`, token);
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          setTreatmentTypeToSelect(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    }; 
    if (openEditRowTreatmentTypes){
      fetchDataTreatmentType(); 
    }
    return () => { isMounted = false };
  }, [ENDPOINT,t,token,dispatch,i18n,openEditRowTreatmentTypes]);


  return(
    <>
      {openEditRowTreatmentTypes && 
        <div>
          <SwipeableDrawer
            anchor='right'
            open={true}
            onClose={toggleDrawerNewTreatmentType(false)}
            onOpen={toggleDrawerNewTreatmentType(true)}>
            <Container >
              <Box sx={{ bgcolor: '#ffffff',height:'100vh', width: '850px' }}>
                <br/>
                <div id="myDIV">
                  <h1 className="h1NewStyle px-4 mb-2">{t('Text_TREATMENTTYPE_NEW')}</h1>
                </div>  
                <hr className="sidebar-divider d-none d-md-block"/>
                <form className="createGroupSection" onSubmit={handleCloseNewTraetmentType}>  
                  <div className="form-floating mb-3 px-4 primary">
                    <label className="text-primary">{t('PlaceHolder_CONTRACT_TREATMENTTYPE')} :</label>
                    <div className="form-group">
                      <TextField
                          id="treatmenttype_id"
                          name="treatmenttype_id"
                          value={ (newTreatmentType.treatmenttype_id === undefined || newTreatmentType.treatmenttype_id ===null || newTreatmentType.treatmenttype_id ==="")?"":newTreatmentType.treatmenttype_id}
                          inputProps={{ style: {width: 750}, maxLength: 100 }}
                          variant="outlined"
                          onBlur={handleBlurSelectTreatmentType}
                          onChange={handleChangeSelectTreatmentType}
                          select
                          SelectProps={{
                            native: true,
                            value: (newTreatmentType.treatmenttype_id === undefined || newTreatmentType.treatmenttype_id ===null || newTreatmentType.treatmenttype_id ==="")?"": newTreatmentType.treatmenttype_id
                          }}>
                          <option key={0} value=""/>
                          {treatmentTypeToSelect.map((option) => (
                          <option key={option.treatmenttype_id} value={option.treatmenttype_id}>
                              {i18n.language==="es"?option.treatmenttype_code+" - "+option.treatmenttype_namees.substring(0,120):option.treatmenttype_code+" - "+option.treatmenttype_nameen.substring(0,120)}
                          </option>
                          ))}
                        </TextField>
                    </div>
                    <label className="text-primary">{t('Text_CONTRACT_TREATMENTTYPE')} :</label>
                    <div className="form-group">
                      <TextField
                        id="treatmenttype_code" 
                        name="treatmenttype_code"
                        value={newTreatmentType.treatmenttype_code}
                        inputProps={{ style: {width: 800}, maxLength: 255 }}
                        label={errorsTreatmentType.treatmenttype_code!=null?t(errorsTreatmentType.treatmenttype_code):t('Text_CONTRACT_TREATMENTTYPE')}
                        placeholder={t('Text_CONTRACT_TREATMENTTYPE')}
                        variant="standard"
                        onBlur={handleBlurNewTreatmentType}
                        onChange={handleChangeNewTreatmentType}
                        disabled/>
                    </div>
                    <label className="text-primary">{t('Text_CONTRACT_TREATMENTTYPE_TITLEES')} :</label>
                    <div className="form-group">
                      <TextField
                        id="namees" 
                        name="namees"
                        value={newTreatmentType.treatmenttype_namees}
                        inputProps={{ style: {width: 800}, maxLength: 255 }}
                        label={errorsTreatmentType.treatmenttype_namees!=null?t(errorsTreatmentType.treatmenttype_namees):t('Text_CONTRACT_TREATMENTTYPE_TITLEES')}
                        placeholder={t('Text_CONTRACT_TREATMENTTYPE_TITLEES')}
                        variant="standard"
                        onBlur={handleBlurNewTreatmentType}
                        onChange={handleChangeNewTreatmentType}
                        disabled/>
                    </div>

                    <label className="text-primary">{t('Text_CONTRACT_TREATMENTTYPE_TITLEEN')} :</label>
                    <div className="form-group">
                      <TextField
                        id="nameen" 
                        name="nameen"
                        value={newTreatmentType.treatmenttype_nameen}
                        inputProps={{ style: {width: 800}, maxLength: 255 }}
                        label={errorsTreatmentType.treatmenttype_nameen!=null?t(errorsTreatmentType.treatmenttype_nameen):t('Text_CONTRACT_TREATMENTTYPE_TITLEEN')}
                        placeholder={t('Text_CONTRACT_TREATMENTTYPE_TITLEEN')}
                        variant="standard"
                        onBlur={handleBlurNewTreatmentType}
                        onChange={handleChangeNewTreatmentType}
                        disabled/>
                    </div>
                   
      
                    <hr className="sidebar-divider d-none d-md-block"/>
                    <div className="btn-group d-grid d-md-flex justify-content-md-end" role="group">
                      <button className="btn btn-primary btn-lg " type="button" onClick={handleSubmitNewTreatmentType}>{t('Button_Select')}</button><> </>
                      <button className="btn btn-outline-secondary btn-lg" type="button" onClick={handleCloseNewTraetmentType}>{t('Button_Cancel')}</button>
                    </div>
                  </div>
                </form>
              </Box>
            </Container>
          </SwipeableDrawer>
        </div>
      }  
    </>
  )
}

ContractCreateTreatmentTypes.propTypes = {
  openEditRowTreatmentTypes: PropTypes.bool.isRequired,
  setOpenEditRowTreatmentTypes: PropTypes.func.isRequired,  
  newCodeLER: PropTypes.object.isRequired,
  setNewCodeLER: PropTypes.func.isRequired, 
};

export default ContractCreateTreatmentTypes;