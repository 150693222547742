import React, {  useEffect,useCallback,useState } from 'react';
import { useSelector } from "react-redux"; 
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next"  
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material'; 
import { TextField } from '@mui/material';
import { Typography} from '@mui/material'; 
import { Checkbox } from '@mui/material'; 
import { NumericFormat } from 'react-number-format'; 
import { makeStyles } from '@mui/styles'; 
import "css/generalSettings.css";  
import { successErrorAction, warningErrorAction } from "actions/ErrorActions";
import { helpHttp } from "service/HelpHttp"; 
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IconButton from '@mui/material/IconButton'; 
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import TypeDICreateOrEditOptTras from "./TypesDIOptTrasEdit";
import TypeDICreateOrEditOriTras from "./TypesDIOriTrasEdit";
import TypeDICreateOrEditAutTras from "./TypesDIAutTrasEdit";
import TypeDICreateOrEditDestTras from "./TypesDIDestTrasEdit";
import TypeDICreateOrEditAutDest from "./TypesDIAutDestEdit";
import TypeDICreateOrEditRelTrans from "./TypesDIRelTransEdit";
import TypeDICreateOrEditRespProd from "./TypesDIRespProdEdit";
import TypesDISearchCodeLers from "./TypesDISearchCodeLers";
import TypesDISearchDescriptions from "./TypesDISearchDescriptions";
import PropTypes from 'prop-types'; 

const initialState = [{
  "id" : "",
  "namees" : "",
  "nameen" : "",
  "default_preiddoc_sheet" : "",
  "default_sufiddoc_sheet" : 0,
  "default_number_dt": "",
  "formatnumber":1,

  "default_opt_tras_id" : 0,
  "default_opt_tras_documnettype_id" : 0,
  "default_opt_tras_documentid" : "",
  "default_opt_tras_tradename" : "",
  "default_opt_tras_name" : "",
  "default_opt_tras_contactname" : "",
  "default_opt_tras_roadtype_id" : 0,
  "default_opt_tras_street" : "",
  "default_opt_tras_country_id" : 0,
  "default_opt_tras_province_id" : 0,
  "default_opt_tras_postalcode_id" : 0,
  "default_opt_tras_community_id" : 0,
  "default_opt_tras_phone" : "",
  "default_opt_tras_email" : "",
  "default_opt_tras_nima" : "",
  "default_opt_tras_cnae" : "",
  "default_opt_tras_inscription" : "",
  "default_opt_tras_opt" : "",
  "default_opt_tras_licenseplate" : "",
  "default_opt_tras_economicactivity" : "",
    
  "default_ori_tras_id" : 0,
  "default_ori_tras_documnettype_id" : 0,
  "default_ori_tras_documentid" : "",
  "default_ori_tras_tradename" : "",
  "default_ori_tras_name" : "",
  "default_ori_tras_contactname" : "",
  "default_ori_tras_roadtype_id" : 0,
  "default_ori_tras_street" : "",
  "default_ori_tras_country_id" : 0,
  "default_ori_tras_province_id" : 0,
  "default_ori_tras_postalcode_id" : 0,
  "default_ori_tras_community_id" : 0,
  "default_ori_tras_phone" : "",
  "default_ori_tras_email" : "",
  "default_ori_tras_nima" : "",
  "default_ori_tras_cnae" : "",
  "default_ori_tras_inscription" : "",
  "default_ori_tras_opt" : "",
  "default_ori_tras_licenseplate" : "",
  "default_ori_tras_economicactivity" : "",

  "default_aut_tras_id" : 0,
  "default_aut_tras_documnettype_id" : 0,
  "default_aut_tras_documentid" : "",
  "default_aut_tras_tradename" : "",
  "default_aut_tras_name" : "",
  "default_aut_tras_contactname" : "",
  "default_aut_tras_roadtype_id" : 0,
  "default_aut_tras_street" : "",
  "default_aut_tras_country_id" : 0,
  "default_aut_tras_province_id" : 0,
  "default_aut_tras_postalcode_id" : 0,
  "default_aut_tras_community_id" : 0,
  "default_aut_tras_phone" : "",
  "default_aut_tras_email" : "",
  "default_aut_tras_nima" : "",
  "default_aut_tras_cnae" : "",
  "default_aut_tras_inscription" : "",
  "default_aut_tras_opt" : "",
  "default_aut_tras_licenseplate" : "",
  "default_aut_tras_economicactivity" : "",

  "default_dest_tras_id" : 0,
  "default_dest_tras_documnettype_id" : 0,
  "default_dest_tras_documentid" : "",
  "default_dest_tras_tradename" : "",
  "default_dest_tras_name" : "",
  "default_dest_tras_contactname" : "",
  "default_dest_tras_roadtype_id" : 0,
  "default_dest_tras_street" : "",
  "default_dest_tras_country_id" : 0,
  "default_dest_tras_province_id" : 0,
  "default_dest_tras_postalcode_id" : 0,
  "default_dest_tras_community_id" : 0,
  "default_dest_tras_phone" : "",
  "default_dest_tras_email" : "",
  "default_dest_tras_nima" : "",
  "default_dest_tras_cnae" : "",
  "default_dest_tras_inscription" : "",
  "default_dest_tras_opt" : "",
  "default_dest_tras_licenseplate" : "",
  "default_dest_tras_economicactivity" : "",

  "default_aut_dest_id" : 0,
  "default_aut_dest_documnettype_id" : 0,
  "default_aut_dest_documentid" : "",
  "default_aut_dest_tradename" : "",
  "default_aut_dest_name" : "",
  "default_aut_dest_contactname" : "",
  "default_aut_dest_roadtype_id" : 0,
  "default_aut_dest_street" : "",
  "default_aut_dest_country_id" : 0,
  "default_aut_dest_province_id" : 0,
  "default_aut_dest_postalcode_id" : 0,
  "default_aut_dest_community_id" : 0,
  "default_aut_dest_phone" : "",
  "default_aut_dest_email" : "",
  "default_aut_dest_nima" : "",
  "default_aut_dest_cnae" : "",
  "default_aut_dest_inscription" : "",
  "default_aut_dest_opt" : "",
  "default_aut_dest_licenseplate" : "",
  "default_aut_dest_economicactivity" : "",
 
  "default_codeler_id" : 0,
  "default_codeler_code" : "",
  "default_codeler_namees" : "",
  "default_codeler_nameen" : "",
  "default_description_id" : 0,
  "default_description_code" : "",
  "default_description_namees" : "",
  "default_description_nameen" : "",
  "default_treatmenttypeorigin_id" : 0,
  "default_treatmenttypeorigin_code" : "",
  "default_treatmenttypeorigin_namees" : "",
  "default_treatmenttypeorigin_nameen" : "",
  "default_treatmenttypedestine_id" : 0,
  "default_treatmenttypedestine_code" : "",
  "default_treatmenttypedestine_namees" : "",
  "default_treatmenttypedestine_nameen" : "",
  "default_processtypeorigin_id" : 0,
  "default_processtypeorigin_code" : "",
  "default_processtypeorigin_namees" : "",
  "default_processtypeorigin_nameen" : "",
  "default_processtypedestine_id" : 0,
  "default_processtypedestine_code" : "",
  "default_processtypedestine_namees" : "",
  "default_processtypedestine_nameen" : "",
  "default_dangerousness_id" : 0,
  "default_dangerousness_namees" : "",
  "default_dangerousness_nameen" : "",

  "default_resp_prod_id" : 0,
  "default_resp_prod_documnettype_id" : 0,
  "default_resp_prod_documentid" : "",
  "default_resp_prod_tradename" : "",
  "default_resp_prod_name" : "",
  "default_resp_prod_contactname" : "",
  "default_resp_prod_roadtype_id" : 0,
  "default_resp_prod_street" : "",
  "default_resp_prod_country_id" : 0,
  "default_resp_prod_province_id" : 0,
  "default_resp_prod_postalcode_id" : 0,
  "default_resp_prod_community_id" : 0,
  "default_resp_prod_phone" : "",
  "default_resp_prod_email" : "",
  "default_resp_prod_nima" : "",
  "default_resp_prod_cnae" : "",
  "default_resp_prod_inscription" : "",
  "default_resp_prod_opt" : "",
  "default_resp_prod_licenseplate" : "",
  "default_resp_prod_economicactivity" : "",

  "default_rel_trans_id" : 0,
  "default_rel_trans_documnettype_id" : 0,
  "default_rel_trans_documentid" : "",
  "default_rel_trans_tradename" : "",
  "default_rel_trans_name" : "",
  "default_rel_trans_contactname" : "",
  "default_rel_trans_roadtype_id" : 0,
  "default_rel_trans_street" : "",
  "default_rel_trans_country_id" : 0,
  "default_rel_trans_province_id" : 0,
  "default_rel_trans_postalcode_id" : 0,
  "default_rel_trans_community_id" : 0,
  "default_rel_trans_phone" : "",
  "default_rel_trans_email" : "",
  "default_rel_trans_nima" : "",
  "default_rel_trans_cnae" : "",
  "default_rel_trans_inscription" : "",
  "default_rel_trans_opt" : "",
  "default_rel_trans_licenseplate" : "",
  "default_rel_trans_economicactivity" : "",
  "default_contract_view_aut_tras" :false,
  "default_contract_view_aut_dest" :false, 
  "default_conditions_contract":"",
  "default_obligations_contract":"",
  "limitcodelerscontract" : 20,
  "limitprocesstypecontract" : 20,
  "limitreatmenttypecontract" : 20,

  "formatDIDTO":{
    "id" : 1,
    "typesdi_id" :1,
    "opt_tras_view" :true,
    "opt_tras_expand" :true,
    "opt_tras_handle" :true,
    "opt_tras_view_name" :true,
    "opt_tras_view_contactname" :true,
    "opt_tras_view_address" :true,
    "opt_tras_view_country" :true,
    "opt_tras_view_community" :true,
    "opt_tras_view_phone" :true,
    "opt_tras_view_email" :true,
    "opt_tras_view_nima" :true,
    "opt_tras_view_cnae" :false,
    "opt_tras_view_inscription" :true,
    "opt_tras_view_opt" :true,
    "opt_tras_view_licenseplate" :false,
    "opt_tras_view_economicactivity" :false,
    
    "opt_tras_handle_documnettype" :true,
    "opt_tras_handle_documentid" :true,
    "opt_tras_handle_tradename" :true,
    "opt_tras_handle_name" :true,
    "opt_tras_handle_contactname" :true,
    "opt_tras_handle_address" :true,
    "opt_tras_handle_phone" :true,
    "opt_tras_handle_email" :true,
    "opt_tras_handle_nima" :true,
    "opt_tras_handle_cnae" :false,
    "opt_tras_handle_inscription" :true,
    "opt_tras_handle_opt" :true,
    "opt_tras_handle_licenseplate" :false,
    "opt_tras_handle_economicactivity" :false,
    
    "ori_tras_view" :true,
    "ori_tras_expand" :true,
    "ori_tras_handle" :true,
    "ori_tras_view_name" :true,
    "ori_tras_view_contactname" :true,
    "ori_tras_view_address" :true,
    "ori_tras_view_country" :true,
    "ori_tras_view_community" :true,
    "ori_tras_view_phone" :true,
    "ori_tras_view_email" :true, 
    "ori_tras_view_nima" :true,
    "ori_tras_view_cnae" :true,
    "ori_tras_view_inscription" :true,
    "ori_tras_view_opt" :false,
    "ori_tras_view_licenseplate" :false,
    "ori_tras_view_economicactivity" :false,

    "ori_tras_handle_documnettype" :true,
    "ori_tras_handle_documentid" :true,
    "ori_tras_handle_tradename" :true,
    "ori_tras_handle_name" :true,
    "ori_tras_handle_contactname" :true,
    "ori_tras_handle_address" :true,
    "ori_tras_handle_phone" :true,
    "ori_tras_handle_email" :true,
    "ori_tras_handle_nima" :true,
    "ori_tras_handle_cnae" :true,
    "ori_tras_handle_inscription" :true,
    "ori_tras_handle_opt" :false,
    "ori_tras_handle_licenseplate" :false,
    "ori_tras_handle_economicactivity" :false,

    "aut_tras_view" :true,
    "aut_tras_expand" :true,
    "aut_tras_handle" :true,
    "aut_tras_view_name" :true,
    "aut_tras_view_contactname" :true,
    "aut_tras_view_address" :true,
    "aut_tras_view_country" :true, 
    "aut_tras_view_community" :true,
    "aut_tras_view_phone" :true,
    "aut_tras_view_email" :true,
    "aut_tras_view_nima" :true,
    "aut_tras_view_cnae" :false,
    "aut_tras_view_inscription" :true,
    "aut_tras_view_opt" :false,
    "aut_tras_view_licenseplate" :false,
    "aut_tras_view_economicactivity" :false,

    "aut_tras_handle_documnettype" :true,
    "aut_tras_handle_documentid" :true,
    "aut_tras_handle_tradename" :true,
    "aut_tras_handle_name" :true,
    "aut_tras_handle_contactname" :true,
    "aut_tras_handle_address" :true,
    "aut_tras_handle_phone" :true,
    "aut_tras_handle_email" :true,
    "aut_tras_handle_nima" :true,
    "aut_tras_handle_cnae" :false,
    "aut_tras_handle_inscription" :true,
    "aut_tras_handle_opt" :false,
    "aut_tras_handle_licenseplate" :false,
    "aut_tras_handle_economicactivity" :false,

    "dest_tras_view" :true,
    "dest_tras_expand" :true,
    "dest_tras_handle" :true,
    "dest_tras_view_name" :true,
    "dest_tras_view_contactname" :true,
    "dest_tras_view_address" :true,
    "dest_tras_view_country" :true, 
    "dest_tras_view_community" :true,
    "dest_tras_view_phone" :true,
    "dest_tras_view_email" :true,
    "dest_tras_view_nima" :true,
    "dest_tras_view_cnae" :false,
    "dest_tras_view_inscription" :true,
    "dest_tras_view_opt" :false,
    "dest_tras_view_licenseplate" :false,
    "dest_tras_view_economicactivity" :false,

    "dest_tras_handle_documnettype" :true,
    "dest_tras_handle_documentid" :true,
    "dest_tras_handle_tradename" :true,
    "dest_tras_handle_name" :true,
    "dest_tras_handle_contactname" :true,
    "dest_tras_handle_address" :true,
    "dest_tras_handle_phone" :true,
    "dest_tras_handle_email" :true,
    "dest_tras_handle_nima" :true,
    "dest_tras_handle_cnae" :false,
    "dest_tras_handle_inscription" :true,
    "dest_tras_handle_opt" :false,
    "dest_tras_handle_licenseplate" :false,
    "dest_tras_handle_economicactivity" :false,
  
    "aut_dest_view" :true,
    "aut_dest_expand" :true,
    "aut_dest_handle" :true,
    "aut_dest_view_name" :true,
    "aut_dest_view_contactname" :true,
    "aut_dest_view_address" :true,
    "aut_dest_view_country" :true, 
    "aut_dest_view_community" :true,
    "aut_dest_view_phone" :true,
    "aut_dest_view_email" :true,
    "aut_dest_view_nima" :true,
    "aut_dest_view_cnae" :false,
    "aut_dest_view_inscription" :true,
    "aut_dest_view_opt" :false,
    "aut_dest_view_licenseplate" :false,
    "aut_dest_view_economicactivity" :false,

    "aut_dest_handle_documnettype" :true,
    "aut_dest_handle_documentid" :true,
    "aut_dest_handle_tradename" :true,
    "aut_dest_handle_name" :true,
    "aut_dest_handle_contactname" :true,
    "aut_dest_handle_address" :true,
    "aut_dest_handle_phone" :true,
    "aut_dest_handle_email" :true,
    "aut_dest_handle_nima" :true,
    "aut_dest_handle_cnae" :false,
    "aut_dest_handle_inscription" :true,
    "aut_dest_handle_opt" :false,
    "aut_dest_handle_licenseplate" :false,
    "aut_dest_handle_economicactivity" :false,

    "rel_trans_view" :true,
    "rel_trans_expand" :true,
    "rel_trans_handle" :true,
    "rel_trans_view_name" :true,
    "rel_trans_view_contactname" :true,
    "rel_trans_view_address" :true,
    "rel_trans_view_country" :true, 
    "rel_trans_view_community" :true,
    "rel_trans_view_phone" :true,
    "rel_trans_view_email" :true,
    "rel_trans_view_nima" :true,
    "rel_trans_view_cnae" :false,
    "rel_trans_view_inscription" :true,
    "rel_trans_view_opt" :false,
    "rel_trans_view_licenseplate" :true,
    "rel_trans_view_economicactivity" :false,

    "rel_trans_handle_documnettype" :true,
    "rel_trans_handle_documentid" :true,
    "rel_trans_handle_tradename" :true,
    "rel_trans_handle_name" :true,
    "rel_trans_handle_contactname" :true,
    "rel_trans_handle_address" :true,
    "rel_trans_handle_phone" :true,
    "rel_trans_handle_email" :true,
    "rel_trans_handle_nima" :true,
    "rel_trans_handle_cnae" :false,
    "rel_trans_handle_inscription" :true,
    "rel_trans_handle_opt" :false,
    "rel_trans_handle_licenseplate" :true,
    "rel_trans_handle_economicactivity" :false,

    "resp_prod_view" :true,
    "resp_prod_expand" :true,
    "resp_prod_handle" :true,
    "resp_prod_view_name" :true,
    "resp_prod_view_contactname" :true,
    "resp_prod_view_address" :true,
    "resp_prod_view_country" :true, 
    "resp_prod_view_community" :true,
    "resp_prod_view_phone" :true,
    "resp_prod_view_email" :true,
    "resp_prod_view_nima" :true,
    "resp_prod_view_cnae" :false,
    "resp_prod_view_inscription" :true,
    "resp_prod_view_opt" :false,
    "resp_prod_view_licenseplate" :false,
    "resp_prod_view_economicactivity" :false,

    "resp_prod_handle_documnettype" :true,
    "resp_prod_handle_documentid" :true,
    "resp_prod_handle_tradename" :true,
    "resp_prod_handle_name" :true,
    "resp_prod_handle_contactname" :true,
    "resp_prod_handle_address" :true,
    "resp_prod_handle_phone" :true,
    "resp_prod_handle_email" :true,
    "resp_prod_handle_nima" :true,
    "resp_prod_handle_cnae" :false,
    "resp_prod_handle_inscription" :true,
    "resp_prod_handle_opt" :false,
    "resp_prod_handle_licenseplate" :false,
    "resp_prod_handle_economicactivity" :false,

    "number_dt_view" :true,
    "code_description_view" :true,
    "code_treatmenttype_origin_view" :false,
    "code_treatmenttype_destine_view" :true,
    "code_processtype_origin_view" :false,
    "code_processtype_destine_view" :false,
    "code_dangerousness_view" :true,
    "charac_danger_view" :false,
    "date_refund_view" :false,
    "reason_refund_view" :false,
    "action_refund_view" :false,
    "number_dt_handle" :true,
    "code_description_handle" :true,
    "code_treatmenttype_origin_handle" :false,
    "code_treatmenttype_destine_handle" :true,
    "code_processtype_origin_handle" :false,
    "code_processtype_destine_handle" :false,
    "code_dangerousness_handle" :true,
    "charac_danger_handle" :false,
    "date_refund_handle" :false,
    "reason_refund_handle" :false,
    "action_refund_handle" :false,

    "share_form_view":true,
    "share_form_handle" :true,
    "share_form_opt_tras" :true,
    "share_form_ori_tras" :true,
    "share_form_aut_tras" :true,
    "share_form_dest_tras" :true,
    "share_form_aut_dest" :true,
    "share_form_rel_trans" :true,
    "share_form_resp_prod" :true,

    "sendbyemail_form_view":true,
    "sendbyemail_form_handle" :true,
    "sendbyemail_form_opt_tras" :true,
    "sendbyemail_form_ori_tras" :true,
    "sendbyemail_form_aut_tras" :true,
    "sendbyemail_form_dest_tras" :true,
    "sendbyemail_form_aut_dest" :true,
    "sendbyemail_form_rel_trans" :true,
    "sendbyemail_form_resp_prod" :true,

    "filenameexcel":"",
    "delete_code":"",
    "delete_code_employee":"",
    "delete_code_store":"",
    "delete_code_formdi":"",
    "default_ext_phone":1,
    "default_country":1,
    "default_province":30,
    "default_community":5,
   "limitemailperstore":5,
    "limitaddressperstore":15,
    "limitbankaccountperstore":5,
    "limitphoneperemployee":5,
    "limitemailperemployee":5,
    "limitaddressperemployee":5,
    "limitbankaccountperemployee":5,
    "signature_default":"",
    "signature_default_des_tras":"",
  }
}];

const initialDocumentTypesToSelect = [{
  "id": "",
  "name": ""
}];

const initialRDToSelect = [{
  "id" : 0,
  "code" : "",
  "namees" : "",
  "nameen" : "" 
}];

const initialTypesToSelect = [{
  "id" : 0, 
  "namees" : "",
  "nameen" : "" 
}];

const initialCountriesToSelect = [{
  "id" : 1,
  "namees" : "",
  "nameen" : "",
  "enabled":true
}];

const useStyles = makeStyles((theme) => ({
  root: {
    width: '880px',
  },
  backcolor: {
    backgroundColor:  "#F4F4F4"
  },
  heading: {
    fontSize: 14,
    paddingLeft: 6,
    width: '420px',
    color: "#067330"
  },
  secondaryHeading: {
    fontSize: 14,
    width: '1050px',
    color: "#000000",
  },
  thirdHeading: {
    fontSize: 14,
    width: '600px',
    color: "#e74a3b",
  },
}));

const EditOrCreateTypesDIFormat = ({ id, showModalTypesDISettings}) => {
  const {t,i18n} = useTranslation(['listDataGeneralSettings']);
  const token = useSelector((state) => state.loginUser.token);  
  const useraccount_id = useSelector((state) => state.loginUser.useraccount_id);  
  const ENDPOINT = useSelector((state) => state.variablesUser.ENDPOINT); 
  const roles = useSelector((state) => state.loginUser.roles);  
  const [formFrom, setFormForm] = useState(initialState[0]);
  const [errorsForm, setErrorsForm] = useState({});
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate(); 
  const [showModalSearchCodeLERS, setShowModalSearchCodeLERS] =  useState(false);
  const [showModalSearchDescriptions, setShowModalSearchDescriptions] =  useState(false); 
  const [documentTypesToSelect,setDocumentTypesToSelect]= useState(initialDocumentTypesToSelect); 
  const [roadTypesToSelect,setRoadTypesToSelect]= useState(initialTypesToSelect);
  const [communityToSelect,setCommunityToSelect]= useState(initialTypesToSelect);
  const [countryToSelect,setCountryToSelect]= useState(initialCountriesToSelect);
  const [treatmentTypeToSelect,setTreatmentTypeToSelect]= useState(initialRDToSelect);
  const [processTypeToSelect,setProcessTypeToSelect]= useState(initialRDToSelect);
  const [dangerousnessToSelect,setDangerousnessToSelect]= useState(initialTypesToSelect);
  const [foundNameesTypesDI, setFoundNameesTypesDI ]= useState(false);
  const [foundNameenTypesDI, setFoundNameenTypesDI ]= useState(false);

  const [expanded, setExpanded] =  useState(false);
  
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const keyPress = useCallback(
    e => {
      if (e.key === 'Escape' && showModalTypesDISettings) {
        setFormForm(initialState[0]);
        setErrorsForm({}); 
        setShowModalSearchCodeLERS(false);
        setShowModalSearchDescriptions(false);
        setDocumentTypesToSelect(initialDocumentTypesToSelect);
        setRoadTypesToSelect(initialTypesToSelect);
        setCommunityToSelect(initialTypesToSelect);
        setCountryToSelect(initialCountriesToSelect);
        setTreatmentTypeToSelect(initialRDToSelect);
        setProcessTypeToSelect(initialRDToSelect);
        setDangerousnessToSelect(initialTypesToSelect);
        setFoundNameesTypesDI(false);
        setFoundNameenTypesDI(false);
        setExpanded(false);
        navigate("/formatdi") 
        return () => {};
      }
    },
    [ setFormForm,
      setErrorsForm,
      setShowModalSearchCodeLERS,
      setShowModalSearchDescriptions,
      setDocumentTypesToSelect,
      setRoadTypesToSelect,
      setCommunityToSelect,
      setCountryToSelect,
      setTreatmentTypeToSelect,
      setProcessTypeToSelect,
      setDangerousnessToSelect,
      setFoundNameesTypesDI,
      setFoundNameenTypesDI,
      setExpanded,
      showModalTypesDISettings, 
      navigate
    ]
  );

  const cancelModalEditOrCreateTypesDIFormat = () => { 
    setFormForm(initialState[0]);
    setErrorsForm({}); 
    setShowModalSearchCodeLERS(false);
    setShowModalSearchDescriptions(false);
    setDocumentTypesToSelect(initialDocumentTypesToSelect);
    setRoadTypesToSelect(initialTypesToSelect);
    setCommunityToSelect(initialTypesToSelect);
    setCountryToSelect(initialCountriesToSelect);
    setTreatmentTypeToSelect(initialRDToSelect);
    setProcessTypeToSelect(initialRDToSelect);
    setDangerousnessToSelect(initialTypesToSelect);
    setFoundNameesTypesDI(false);
    setFoundNameenTypesDI(false);
    setExpanded(false);
    navigate("/formatdi") ;
    return () => {};
  };

  const handleChangeDataEdit = (e) => {
    const { name, value } = e.target;
    setFormForm(formFrom => ({
      ...formFrom,
      [name]: value
    }));
  };
 
  const handleBlurDataEdit = (e) => {
    handleChangeDataEdit(e);
    setErrorsForm(validateForm());
  };
      
  const handleChangeDataEditNumer = (e) => {
    const { name, value } = e.target;
    let newValue=value!==null?Number((value.toLocaleString()).replaceAll(",","")):0;
    setFormForm(formFrom => ({
      ...formFrom,
      [name]: newValue,
    }));
  };

  const handleBlurDataEditNumber = (e) => {
    handleChangeDataEditNumer(e);
    setErrorsForm(validateForm());
  };

  const withValueCap = (inputObj) => {
    const { value } = inputObj;
    if (value >= 1 && value <= 3) return true;
    return false;
  };

  const withValueCap2 = (inputObj) => {
    const { value } = inputObj;
    if (value <= 10000000000) return true;
    return false;
  };

  const withValueCap20 = (inputObj) => {
    const { value } = inputObj;
    if (value >= 1 && value <= 30) return true;
    return false;
  };

  const deleteDataCodeLer = (event) => {
    setFormForm(formFrom => ({
      ...formFrom,
      default_codeler_id:0,
      default_codeler_code: "",
      default_codeler_namees:"",
      default_codeler_nameen: ""
    }));
  };

  const searchFormCodeLer = (event) => {
    setShowModalSearchCodeLERS(true);
  };

  const deleteDataDescription = (event) => {
    setFormForm(formFrom => ({
      ...formFrom,
      default_description_id:0,
      default_description_code: "",
      default_description_namees:"",
      default_description_nameen: ""
    }));
  };

  const searchFormDescription = (event) => {
    setShowModalSearchDescriptions(true);
  };

  const handleChangeEnabledDefaultOptions = (e) => {
    const { name,checked } = e.target; 
    setFormForm(formFrom => ({
      ...formFrom,
        [name]: checked
    }));
  };
  
  const validateForm = () => {
    let errorsForm = {};
    let regexInvalidCharacters = /[\\!\\$\\%\\&\\|\\<\\>\\?\\{\\}\\~\\"\\']+/;
    let regexEmail = /[\\!\\$\\%\\&\\|\\<\\>\\?\\{\\}\\~\\/\\"\\']+/;
    
    if (!formFrom.namees || formFrom.namees==="") {
      errorsForm.namees = 'Text_FieldNameesRequired';
    } else {
      if (regexInvalidCharacters.test(formFrom.namees)) {
        errorsForm.namees = 'Text_TextNoValid';
      } else{
        if (foundNameesTypesDI){
          errorsForm.namees='Text_TypesDI_NameesExist';
        } 
      }  
    }
 
    if (!formFrom.nameen || formFrom.nameen==="") {
      errorsForm.nameen = 'Text_FieldNameenRequired';
    } else {
      if (regexInvalidCharacters.test(formFrom.nameen)) {
        errorsForm.nameen = 'Text_TextNoValid';
      } else{
        if (foundNameenTypesDI){
          errorsForm.nameen='Text_TypesDI_NameenExist';
        } 
      }  
    }
 
    if (regexInvalidCharacters.test(formFrom.default_prdefault_number_dteiddoc_sheet)) {
      errorsForm.default_number_dt = 'Text_TextNoValid';
    }

    if (regexInvalidCharacters.test(formFrom.default_preiddoc_sheet)) {
      errorsForm.default_preiddoc_sheet = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.default_opt_tras_documentid)) {
      errorsForm.default_opt_tras_documentid = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.default_opt_tras_tradename)) {
      errorsForm.default_opt_tras_tradename = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.default_opt_tras_name)) {
      errorsForm.default_opt_tras_name = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.default_opt_tras_contactname)) {
      errorsForm.default_opt_tras_contactname = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.default_opt_tras_street)) {
      errorsForm.default_opt_tras_street = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.default_opt_tras_number)) {
      errorsForm.default_opt_tras_number = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.default_opt_tras_floor)) {
      errorsForm.default_opt_tras_floor = 'Text_TextNoValid';
    }  
    if (regexInvalidCharacters.test(formFrom.default_opt_tras_door)) {
      errorsForm.default_opt_tras_door = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.default_opt_tras_phone)) {
      errorsForm.default_opt_tras_phone = 'Text_TextNoValid';
    }
    if (formFrom.default_opt_tras_email!=="" && !regexEmail.test(formFrom.default_opt_tras_email)) {
      errorsForm.default_opt_tras_email = 'Text_Email_Invalid';
    }
    if (regexInvalidCharacters.test(formFrom.default_opt_tras_nima)) {
      errorsForm.default_opt_tras_nima = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.default_opt_tras_cnae)) {
      errorsForm.default_opt_tras_cnae = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.default_opt_tras_inscription)) {
      errorsForm.default_opt_tras_inscription = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.default_opt_tras_opt)) {
      errorsForm.default_opt_tras_opt = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.default_opt_tras_licenseplate)) {
      errorsForm.default_opt_tras_licenseplate = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.default_opt_tras_economicactivity)) {
      errorsForm.default_opt_tras_economicactivity = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.default_ori_tras_documentid)) {
      errorsForm.default_ori_tras_documentid = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.default_ori_tras_tradename)) {
      errorsForm.default_ori_tras_tradename = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.default_ori_tras_name)) {
      errorsForm.default_ori_tras_name = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.default_ori_tras_contactname)) {
      errorsForm.default_ori_tras_contactname = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.default_ori_tras_street)) {
      errorsForm.default_ori_tras_street = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.default_ori_tras_number)) {
      errorsForm.default_ori_tras_number = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.default_ori_tras_floor)) {
      errorsForm.default_ori_tras_floor = 'Text_TextNoValid';
    }  
    if (regexInvalidCharacters.test(formFrom.default_ori_tras_door)) {
      errorsForm.default_ori_tras_door = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.default_ori_tras_phone)) {
      errorsForm.default_ori_tras_phone = 'Text_TextNoValid';
    }
    if (formFrom.default_ori_tras_email!=="" && !regexEmail.test(formFrom.default_ori_tras_email)) {
      errorsForm.default_ori_tras_email = 'Text_Email_Invalid';
    }
    if (regexInvalidCharacters.test(formFrom.default_ori_tras_nima)) {
      errorsForm.default_ori_tras_nima = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.default_ori_tras_cnae)) {
      errorsForm.default_ori_tras_cnae = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.default_ori_tras_inscription)) {
      errorsForm.default_ori_tras_inscription = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.default_ori_tras_opt)) {
      errorsForm.default_ori_tras_opt = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.default_ori_tras_licenseplate)) {
      errorsForm.default_ori_tras_licenseplate = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.default_ori_tras_economicactivity)) {
      errorsForm.default_ori_tras_economicactivity = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.default_aut_tras_documentid)) {
      errorsForm.default_aut_tras_documentid = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.default_aut_tras_tradename)) {
      errorsForm.default_aut_tras_tradename = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.default_aut_tras_name)) {
      errorsForm.default_aut_tras_name = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.default_aut_tras_contactname)) {
      errorsForm.default_aut_tras_contactname = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.default_aut_tras_street)) {
      errorsForm.default_aut_tras_street = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.default_aut_tras_number)) {
      errorsForm.default_aut_tras_number = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.default_aut_tras_floor)) {
      errorsForm.default_aut_tras_floor = 'Text_TextNoValid';
    }  
    if (regexInvalidCharacters.test(formFrom.default_aut_tras_door)) {
      errorsForm.default_aut_tras_door = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.default_aut_tras_phone)) {
      errorsForm.default_aut_tras_phone = 'Text_TextNoValid';
    }
    if (formFrom.default_aut_tras_email!=="" && !regexEmail.test(formFrom.default_aut_tras_email)) {
      errorsForm.default_aut_tras_email = 'Text_Email_Invalid';
    }
    if (regexInvalidCharacters.test(formFrom.default_aut_tras_nima)) {
      errorsForm.default_aut_tras_nima = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.default_aut_tras_cnae)) {
      errorsForm.default_aut_tras_cnae = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.default_aut_tras_inscription)) {
      errorsForm.default_aut_tras_inscription = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.default_aut_tras_opt)) {
      errorsForm.default_aut_tras_opt = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.default_aut_tras_licenseplate)) {
      errorsForm.default_aut_tras_licenseplate = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.default_aut_tras_economicactivity)) {
      errorsForm.default_aut_tras_economicactivity = 'Text_TextNoValid';
    }

    if (regexInvalidCharacters.test(formFrom.default_dest_tras_documentid)) {
      errorsForm.default_dest_tras_documentid = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.default_dest_tras_tradename)) {
      errorsForm.default_dest_tras_tradename = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.default_dest_tras_name)) {
      errorsForm.default_dest_tras_name = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.default_dest_tras_contactname)) {
      errorsForm.default_dest_tras_contactname = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.default_dest_tras_street)) {
      errorsForm.default_dest_tras_street = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.default_dest_tras_number)) {
      errorsForm.default_dest_tras_number = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.default_dest_tras_floor)) {
      errorsForm.default_dest_tras_floor = 'Text_TextNoValid';
    }  
    if (regexInvalidCharacters.test(formFrom.default_dest_tras_door)) {
      errorsForm.default_dest_tras_door = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.default_dest_tras_phone)) {
      errorsForm.default_dest_tras_phone = 'Text_TextNoValid';
    }
    if (formFrom.default_dest_tras_email!=="" && !regexEmail.test(formFrom.default_dest_tras_email)) {
      errorsForm.default_dest_tras_email = 'Text_Email_Invalid';
    }
    if (regexInvalidCharacters.test(formFrom.default_dest_tras_nima)) {
      errorsForm.default_dest_tras_nima = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.default_dest_tras_cnae)) {
      errorsForm.default_dest_tras_cnae = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.default_dest_tras_inscription)) {
      errorsForm.default_dest_tras_inscription = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.default_dest_tras_opt)) {
      errorsForm.default_dest_tras_opt = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.default_dest_tras_licenseplate)) {
      errorsForm.default_dest_tras_licenseplate = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.default_dest_tras_economicactivity)) {
      errorsForm.default_dest_tras_economicactivity = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.default_aut_dest_documentid)) {
      errorsForm.default_aut_dest_documentid = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.default_aut_dest_tradename)) {
      errorsForm.default_aut_dest_tradename = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.default_aut_dest_name)) {
      errorsForm.default_aut_dest_name = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.default_aut_dest_contactname)) {
      errorsForm.default_aut_dest_contactname = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.default_aut_dest_street)) {
      errorsForm.default_aut_dest_street = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.default_aut_dest_number)) {
      errorsForm.default_aut_dest_number = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.default_aut_dest_floor)) {
      errorsForm.default_aut_dest_floor = 'Text_TextNoValid';
    }  
    if (regexInvalidCharacters.test(formFrom.default_aut_dest_door)) {
      errorsForm.default_aut_dest_door = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.default_aut_dest_phone)) {
      errorsForm.default_aut_dest_phone = 'Text_TextNoValid';
    }
    if (formFrom.default_aut_dest_email!=="" && !regexEmail.test(formFrom.default_aut_dest_email)) {
      errorsForm.default_aut_dest_email = 'Text_Email_Invalid';
    }
    if (regexInvalidCharacters.test(formFrom.default_aut_dest_nima)) {
      errorsForm.default_aut_dest_nima = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.default_aut_dest_cnae)) {
      errorsForm.default_aut_dest_cnae = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.default_aut_dest_inscription)) {
      errorsForm.default_aut_dest_inscription = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.default_aut_dest_opt)) {
      errorsForm.default_aut_dest_opt = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.default_aut_dest_licenseplate)) {
      errorsForm.default_aut_dest_licenseplate = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.default_aut_dest_economicactivity)) {
      errorsForm.default_aut_dest_economicactivity = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.default_rel_trans_documentid)) {
      errorsForm.default_rel_trans_documentid = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.default_rel_trans_tradename)) {
      errorsForm.default_rel_trans_tradename = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.default_rel_trans_name)) {
      errorsForm.default_rel_trans_name = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.default_rel_trans_contactname)) {
      errorsForm.default_rel_trans_contactname = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.default_rel_trans_street)) {
      errorsForm.default_rel_trans_street = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.default_rel_trans_number)) {
      errorsForm.default_rel_trans_number = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.default_rel_trans_floor)) {
      errorsForm.default_rel_trans_floor = 'Text_TextNoValid';
    }  
    if (regexInvalidCharacters.test(formFrom.default_rel_trans_door)) {
      errorsForm.default_rel_trans_door = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.default_rel_trans_phone)) {
      errorsForm.default_rel_trans_phone = 'Text_TextNoValid';
    }
    if (formFrom.default_rel_trans_email!=="" && !regexEmail.test(formFrom.default_rel_trans_email)) {
      errorsForm.default_rel_trans_email = 'Text_Email_Invalid';
    }
    if (regexInvalidCharacters.test(formFrom.default_rel_trans_nima)) {
      errorsForm.default_rel_trans_nima = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.default_rel_trans_cnae)) {
      errorsForm.default_rel_trans_cnae = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.default_rel_trans_inscription)) {
      errorsForm.default_rel_trans_inscription = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.default_rel_trans_opt)) {
      errorsForm.default_rel_trans_opt = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.default_rel_trans_licenseplate)) {
      errorsForm.default_rel_trans_licenseplate = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.default_rel_trans_economicactivity)) {
      errorsForm.default_rel_trans_economicactivity = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.default_resp_prod_documentid)) {
      errorsForm.default_resp_prod_documentid = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.default_resp_prod_tradename)) {
      errorsForm.default_resp_prod_tradename = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.default_resp_prod_name)) {
      errorsForm.default_resp_prod_name = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.default_resp_prod_contactname)) {
      errorsForm.default_resp_prod_contactname = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.default_resp_prod_street)) {
      errorsForm.default_resp_prod_street = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.default_resp_prod_number)) {
      errorsForm.default_resp_prod_number = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.default_resp_prod_floor)) {
      errorsForm.default_resp_prod_floor = 'Text_TextNoValid';
    }  
    if (regexInvalidCharacters.test(formFrom.default_resp_prod_door)) {
      errorsForm.default_resp_prod_door = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.default_resp_prod_phone)) {
      errorsForm.default_resp_prod_phone = 'Text_TextNoValid';
    }
    if (formFrom.default_resp_prod_email!=="" && !regexEmail.test(formFrom.default_resp_prod_email)) {
      errorsForm.default_resp_prod_email = 'Text_Email_Invalid';
    }
    if (regexInvalidCharacters.test(formFrom.default_resp_prod_nima)) {
      errorsForm.default_resp_prod_nima = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.default_resp_prod_cnae)) {
      errorsForm.default_resp_prod_cnae = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.default_resp_prod_inscription)) {
      errorsForm.default_resp_prod_inscription = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.default_resp_prod_opt)) {
      errorsForm.default_resp_prod_opt = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.default_resp_prod_licenseplate)) {
      errorsForm.default_resp_prod_licenseplate = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.default_resp_prod_economicactivity)) {
      errorsForm.default_resp_prod_economicactivity = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.default_conditions_contract)) {
      errorsForm.default_conditions_contract = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.default_obligations_contract)) {
      errorsForm.default_obligations_contract = 'Text_TextNoValid';
    }
    
    return errorsForm;
  };

  const handleSubmitDataEditTypeDI = () => {
    const errors = validateForm();
    setErrorsForm(errors);
    if ((Object.keys(errors).length === 0) && formFrom.namees!=='' && formFrom.nameen!=='' ) {
      const fetchData = async () => {
        let isMounted = true;
        try {
          const getData = (formFrom.id === undefined || formFrom.id === "" || formFrom.id === "0" || formFrom.id === 0)
            ? await helpHttp().post2(ENDPOINT, `typesdi/savetypesdi`, token,useraccount_id,formFrom)
            : await helpHttp().put2(ENDPOINT, `typesdi/updatetypesdi`, token, useraccount_id,formFrom);
          if (getData.status === "OK") {
            dispatch(successErrorAction(t('Text_SaveData')));
            cancelModalEditOrCreateTypesDIFormat();
          } else {
            if (isMounted ) {
              dispatch(warningErrorAction(t(getData.cause)));
            }
          }
        } catch (error) {
          dispatch(warningErrorAction(t('Text_IncorrectData'))); 
        }
        return () => { isMounted = false;};
      };
     
      fetchData();
    } else {
      if (errors.namees){
        dispatch(warningErrorAction(t(errors.namees)));
      }else{
        if (errors.nameen){
          dispatch(warningErrorAction(t(errors.nameen)));
        }else{
          dispatch(warningErrorAction(t('Text_Field_Sheet_Invalid')));
        }   
      }
    }
  };

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event && typeof event === 'object' && event.key) {
        keyPress(event);
      }
    };
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [keyPress]);

  useEffect(() => {
    let isMounted = true;
    const fetchExistCodeLer = async () => {
      try {
        const getData = await helpHttp().get2(ENDPOINT,`form/getexistcodelerbycode/`,token,formFrom.default_codeler_code);
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
          
        } else if (isMounted) {
          if (getData!==undefined && getData!==null && getData!=="" && getData>0){
            fetchCodeLerCode();
          }else{
            setFormForm(formFrom => ({
              ...formFrom,
              default_codeler_id: 0,
              default_codeler_namees: "",
              default_codeler_nameen: "" 
            }));
            
          }
        }  
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };
    const fetchCodeLerCode = async () => {
      try {
        const getData = await helpHttp().get2(ENDPOINT,`form/getformcodelerfindbycode/`,token,formFrom.default_codeler_code);
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
           
        } else if (isMounted) {
          if (getData!==undefined && getData!==null && getData!==""){
            setFormForm(formFrom => ({
                ...formFrom,
                default_codeler_id: getData.id, 
                default_codeler_namees: getData.namees,
                default_codeler_nameen: getData.nameen    
              }));
        }  
        }  
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };
    if (formFrom.default_codeler_code!==""){
      fetchExistCodeLer();
    }
    return () => { isMounted = false };
  }, [ENDPOINT,t,token,dispatch,formFrom.default_codeler_code,setFormForm]);

  useEffect(() => {
    let isMounted = true;
    const fetchExistDescription = async () => {
      try {
        const getData = await helpHttp().get2(ENDPOINT,`form/getexistdescriptionbycode/`,token,formFrom.default_description_code);
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
          
        } else if (isMounted) {
          if (getData!==undefined && getData!==null && getData!=="" && getData>0){
            fetchDescriptionCode();
          }else{
            setFormForm(formFrom => ({
              ...formFrom,
              default_description_id: 0,
              default_description_namees: "",
              default_description_nameen: "" 
            }));
            
          }
        }  
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };
    const fetchDescriptionCode = async () => {
      try {
        const getData = await helpHttp().get2(ENDPOINT,`form/getformdescriptionfindbycode/`,token,formFrom.default_description_code);
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
          
        } else if (isMounted) {
          if (getData!==undefined && getData!==null && getData!==""){
            setFormForm(formFrom => ({
              ...formFrom,
              default_description_id: getData.id, 
              default_description_namees: getData.namees,
              default_description_nameen: getData.nameen
            }));
        }  
        }  
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };
    if (formFrom.default_description_code!==""){
      fetchExistDescription();
    }
    return () => { isMounted = false };
    }, [ENDPOINT,t,token,dispatch,formFrom.default_description_code,setFormForm]);

  useEffect(() => {
    let isMounted = true;
    
    const fetchDataRoadType = async () => {
      try {
        const getData = i18n.language === "es"
          ? await helpHttp().get(ENDPOINT, `roadtype/roadtypesorderbynamees`, token)
          : await helpHttp().get(ENDPOINT, `roadtype/roadtypesorderbynameen`, token);
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          setRoadTypesToSelect(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };
 
    const fetchDataDocumentType = async () => {
      try {
        const getData = await helpHttp().get(ENDPOINT, `documenttype/simpledocumenttypesbyname`, token);
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          setDocumentTypesToSelect(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };

    const fetchDataCommunity = async () => {
      try {
        const getData = i18n.language === "es"
        ? await helpHttp().get(ENDPOINT, `community/simplecommunitiesbynamees`, token)
        : await helpHttp().get(ENDPOINT, `community/simplecommunitiesbynameen`, token);
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          setCommunityToSelect(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };

    const fetchDataCountry = async () => {
      try {
        const getData = i18n.language === "es"
          ? await helpHttp().get(ENDPOINT, `country/simplecountriesbynamees`, token)
          : await helpHttp().get(ENDPOINT, `country/simplecountriesbynameen`, token);
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          setCountryToSelect(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };
   
    const fetchDataTreatmentType = async () => {
      try {
        const getData = await helpHttp().get(ENDPOINT, `treatmenttype/treatmenttypesorderbycode`, token);
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          setTreatmentTypeToSelect(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };

    const fetchDataProcessType = async () => {
      try {
        const getData = await helpHttp().get(ENDPOINT, `processtype/processtypesorderbycode`, token);
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          setProcessTypeToSelect(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };

    const fetchDataDangerousness = async () => {
      try {
        const getData = i18n.language==='es' 
          ?await helpHttp().get(ENDPOINT, `dangerousness/dangerousnessorderbynamees`, token)
          :await helpHttp().get(ENDPOINT, `dangerousness/dangerousnessorderbynameen`, token);
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          setDangerousnessToSelect(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };
  
    if (formFrom.id!==null ){
      fetchDataRoadType();
      fetchDataDocumentType();
      fetchDataCommunity();
      fetchDataCountry();
      fetchDataTreatmentType();
      fetchDataProcessType();
      fetchDataDangerousness();

      return () => { isMounted = false };
    }
  }, [ENDPOINT, t, i18n, token, dispatch, formFrom.id,showModalTypesDISettings]);

  useEffect(() => {
    let isMounted = true;
    const fetchExistNamees = async () => {
      try {
        const getData = await helpHttp().get2(ENDPOINT, `typesdi/getExistTypesdinamees/`, token,formFrom.namees);
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          setFoundNameesTypesDI(Number(getData)!==Number(formFrom.id) && getData>0)
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };
    if (formFrom.namees!==""){
      fetchExistNamees();
    }
    return () => { isMounted = false };
  }, [ENDPOINT,t,token,dispatch,i18n,formFrom.id,formFrom.namees]);

  useEffect(() => {
    let isMounted = true;
    const fetchExistNameen = async () => {
      try {
        const getData = await helpHttp().get2(ENDPOINT, `typesdi/getExistTypesdinameen/`, token,formFrom.nameen);
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          setFoundNameenTypesDI(getData!==formFrom.id && getData>0)
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };
    if (formFrom.nameen!==""){
      fetchExistNameen();
    }
    return () => { isMounted = false };
  }, [ENDPOINT,t,token,dispatch,i18n,formFrom.id,formFrom.nameen]);

  useEffect(() => {
    let isMounted = true;
    const fetchDataTypesDI = async () => {
      try {
        const getData = await helpHttp().get2(ENDPOINT, `typesdi/searchtypesdibyid/`, token, id);
        if (getData.err || getData.message === "Load failed") {
          if (isMounted) {
            setFormForm(initialState[0]);
            dispatch(warningErrorAction(t(getData.message === "Load failed" ? 'Text_ConnectionFailied' : 'Text_InvalidCredentials')));
          }
        } else if (isMounted) {
          setFormForm(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };
    if (id !== "0") {
      if (formFrom.id === "") {
        fetchDataTypesDI();
      }
    } else {
      setFormForm(initialState[0]); 
      setFormForm(formFrom => ({
        ...formFrom,
        default_conditions_contract:t('Text_CONTRACT_CONDITIONS_INPUT'),
        default_obligations_contract:t('Text_CONTRACT_OBLLIGATIONS_INPUT')
      }));
    }
    return () => { isMounted = false };
  
  }, [ENDPOINT,dispatch,id,t,token,formFrom.id]); 
 
  
  return (
    <>
      {showModalTypesDISettings && (
        <div className="container-fluid">
          <Box sx={{ bgcolor: '#ffffff',height:'1300px', width: '1600px'}}>
            <form className="createemployee" onSubmit={cancelModalEditOrCreateTypesDIFormat}>  
              <div className="row"> 
                <div className="col-xl-12 mb-4"> 
                  <div  className={"card border-info shadow"}>
                    <div className="card-body"> 
                      <div className="row no-gutters align-items-center">
                        <div className="col mr-2">
                          <div id="myDIV">  
                            <h1 className={"h2NewStyle mb-2"}>
                              <i className="fas fa-regular fa-address-card fa text-warning"></i>&nbsp;&nbsp;{formFrom.namees}
                            </h1>
                            <div>
                              <button type="button" className="buttonCreate" onClick={handleSubmitDataEditTypeDI}>
                              {(id===undefined || id==="" || id==="0" || id===0)?t('Button_Create'):t('Button_Update')}
                              </button><> </>
                              <button type="button" className="buttonBack" onClick={cancelModalEditOrCreateTypesDIFormat}>{t('Button_Cancel')}</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row"> 
                <div className="col-xl-12 mb-4"> 
                  <div  className={"card border-info shadow"}>
                    <div className="card-body"> 
                      <div className="row no-gutters align-items-center">
                       
                        <div className="row p-2"> 
                          <div className="form-group">   
                            <h6 className="h7NewStyle mb-2">{t('Text_FORM_INFORMATION')}</h6>
                            {/* Divider */}
                            <hr className="sidebar-divider d-none d-md-block"/>
                            <label className="text-primary">{t('Text_TypesDI_NameEs')} :</label>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <TextField
                              id="namees" 
                              name="namees"
                              value={formFrom.namees}
                              inputProps={{ style: {width: i18n.language==='es'?469:487}, maxLength: 100 }}
                              label={errorsForm.namees!=null?t(errorsForm.namees):t('PlaceHolder_TypesDI_NameEs')}
                              placeholder={t('PlaceHolder_TypesDI_NameEs')}
                              variant="standard"
                              onBlur={handleBlurDataEdit}
                              onChange={handleChangeDataEdit}
                            />
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <label className="text-primary">{t('Text_TypesDI_NameEn')} :</label>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <TextField
                              id="nameen" 
                              name="nameen"
                              value={formFrom.nameen}
                              inputProps={{ style: {width: i18n.language==='es'?469:487}, maxLength: 100 }}
                              label={errorsForm.nameen!=null?t(errorsForm.nameen):t('PlaceHolder_TypesDI_NameEn')}
                              placeholder={t('PlaceHolder_TypesDI_NameEn')}
                              variant="standard"
                              onBlur={handleBlurDataEdit}
                              onChange={handleChangeDataEdit}
                            />
                          </div>
                        </div>
                        <div className="row p-2"> 
                          <div className="form-group">  
                            <label className="text-primary">{t('Text_TypesDI_Format')} :</label>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <NumericFormat 
                              thousandSeparator={','} 
                              decimalSeparator={'.'} 
                              decimalScale= {0}
                              fixedDecimalScale= {true}
                              allowNegative= {false}
                              isAllowed={withValueCap}
                              id="formatnumber" 
                              name="formatnumber"
                              value={formFrom.formatnumber}
                              label={errorsForm.formatnumber!=null?t(errorsForm.formatnumber):t('Text_TypesDI_Format')}
                              placeholder={t('Text_TypesDI_Format')}
                              style={{width: 100}}
                              customInput={TextField}
                              variant="standard"
                              onBlur={handleBlurDataEdit}
                              onChange={handleChangeDataEdit}
                            />
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <label className="text-primary">{t('Text_TypesDI_Prefix')} :</label>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <TextField
                              id="default_preiddoc_sheet" 
                              name="default_preiddoc_sheet"
                              value={formFrom.default_preiddoc_sheet}
                              inputProps={{ style: {width: 200}, maxLength: 20 }}
                              label={errorsForm.default_preiddoc_sheet!=null?t(errorsForm.default_preiddoc_sheet):t('PlaceHolder_TypesDI_Prefix')}
                              placeholder={t('PlaceHolder_TypesDI_Prefix')}
                              variant="standard"
                              onBlur={handleBlurDataEdit}
                              onChange={handleChangeDataEdit}
                            />
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <label className="text-primary">{t('Text_TypesDI_Sufix')} :</label>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <NumericFormat 
                              thousandSeparator={''} 
                              decimalSeparator={'.'} 
                              decimalScale= {0}
                              fixedDecimalScale= {true}
                              allowNegative= {false}
                              isAllowed={withValueCap2}
                              id="default_sufiddoc_sheet" 
                              name="default_sufiddoc_sheet"
                              value={formFrom.default_sufiddoc_sheet}
                              label={errorsForm.default_sufiddoc_sheet!=null?t(errorsForm.default_sufiddoc_sheet):t('PlaceHolder_TypesDI_Sufix')}
                              placeholder={t('PlaceHolder_TypesDI_Sufix')}
                              style={{width: i18n.language==='es'?200:174}}
                              variant="standard"
                              customInput={TextField}
                              onBlur={handleBlurDataEditNumber}
                              onChange={handleChangeDataEditNumer}
                            />
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <label className="text-primary">{t('Text_TypesDI_FormatNT')} :</label>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <TextField
                              id="default_number_dt" 
                              name="default_number_dt"
                              value={formFrom.default_number_dt}
                              inputProps={{ style: {width: 200}, maxLength: 20 }}
                              label={errorsForm.default_number_dt!=null?t(errorsForm.default_number_dt):t('Text_TypesDI_FormatNT')}
                              placeholder={t('Text_TypesDI_FormatNT')}
                              variant="standard"
                              onBlur={handleBlurDataEdit}
                              onChange={handleChangeDataEdit}
                            />
                          </div>
                        </div>
                        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header" 
                            className={classes.backcolor}
                          >
                            <Typography className={classes.heading}>{t('Text_FORM_OPT_TRAS')} :</Typography>
                            <Typography className={classes.secondaryHeading}>
                              {formFrom.default_opt_tras_tradename!==undefined && formFrom.default_opt_tras_tradename!==null && formFrom.default_opt_tras_tradename!==""?formFrom.default_opt_tras_tradename:t('Text_NOT_REPORTING')}
                              {formFrom.default_opt_tras_documentid!=="" && formFrom.default_opt_tras_tradename===""?<span className={classes.thirdHeading}> - {t('Text_Field_Tradename_Sheet_Required')} !!</span>:""}
                              {errorsForm.default_opt_tras_email!==undefined && errorsForm.default_opt_tras_email!==""?<span className={classes.thirdHeading}>  -  {t('Text_Field_Email_Sheet_Invalid')} !!</span>:""}
                              {(errorsForm.default_opt_tras_documentid!==undefined && errorsForm.default_opt_tras_documentid!=="") ||
                              (errorsForm.default_opt_tras_tradename!==undefined && errorsForm.default_opt_tras_tradename!=="") ||
                              (errorsForm.default_opt_tras_name!==undefined && errorsForm.default_opt_tras_name!=="") ||
                              (errorsForm.default_opt_tras_contactname!==undefined && errorsForm.default_opt_tras_contactname!=="") ||
                              (errorsForm.default_opt_tras_street!==undefined && errorsForm.default_opt_tras_street!=="") ||
                              (errorsForm.default_opt_tras_phone!==undefined && errorsForm.default_opt_tras_phone!=="") ||
                              (errorsForm.default_opt_tras_nima!==undefined && errorsForm.default_opt_tras_nima!=="") ||
                              (errorsForm.default_opt_tras_cnae!==undefined && errorsForm.default_opt_tras_cnae!=="") ||
                              (errorsForm.default_opt_tras_inscription!==undefined && errorsForm.default_opt_tras_inscription!=="") ||
                              (errorsForm.default_opt_tras_opt!==undefined && errorsForm.default_opt_tras_opt!=="") ||
                              (errorsForm.default_opt_tras_economicactivity!==undefined && errorsForm.default_opt_tras_economicactivity!=="") ||
                              (errorsForm.default_opt_tras_licenseplate!==undefined && errorsForm.default_opt_tras_licenseplate!==""
                              )
                              ?<span className={classes.thirdHeading}>  -  {t('Text_Field_Character_Sheet_Invalid')} !!</span>:""}
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <TypeDICreateOrEditOptTras
                                formFrom={formFrom}
                                setFormForm={setFormForm}
                                openEditRow={true}
                                errorsForm={errorsForm}
                                setErrorsForm={setErrorsForm}
                                validateForm={validateForm}
                                documentTypesToSelect={documentTypesToSelect}
                                roadTypesToSelect={roadTypesToSelect}
                                communityToSelect={communityToSelect}
                                countryToSelect={countryToSelect}
                                expanded={expanded}
                              />
                          </AccordionDetails>
                        </Accordion>

                        <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2bh-content"
                            id="panel2bh-header"
                            className={classes.backcolor}
                          >
                            <Typography className={classes.heading}>{t('Text_FORM_ORI_TRAS')} : <span>{t('Text_FORM_ORI_TRAS_PROD')}</span></Typography>
                            <Typography className={classes.secondaryHeading}>
                              {formFrom.default_ori_tras_tradename!==undefined && formFrom.default_ori_tras_tradename!==null && formFrom.default_ori_tras_tradename!==""?formFrom.default_ori_tras_tradename:t('Text_NOT_REPORTING')}
                              {formFrom.default_ori_tras_documentid!=="" && formFrom.default_ori_tras_tradename===""?<span className={classes.thirdHeading}> - {t('Text_Field_Tradename_Sheet_Required')} !!</span>:""}
                              {errorsForm.default_ori_tras_email!==undefined && errorsForm.default_ori_tras_email!==""?<span className={classes.thirdHeading}>  -  {t('Text_Field_Email_Sheet_Invalid')} !!</span>:""}
                              {(errorsForm.default_ori_tras_documentid!==undefined && errorsForm.default_ori_tras_documentid!=="") ||
                              (errorsForm.default_ori_tras_tradename!==undefined && errorsForm.default_ori_tras_tradename!=="") ||
                              (errorsForm.default_ori_tras_name!==undefined && errorsForm.default_ori_tras_name!=="") ||
                              (errorsForm.default_ori_tras_contactname!==undefined && errorsForm.default_ori_tras_contactname!=="") ||
                              (errorsForm.default_ori_tras_street!==undefined && errorsForm.default_ori_tras_street!=="") ||
                              (errorsForm.default_ori_tras_phone!==undefined && errorsForm.default_ori_tras_phone!=="") ||
                              (errorsForm.default_ori_tras_nima!==undefined && errorsForm.default_ori_tras_nima!=="") ||
                              (errorsForm.default_ori_tras_cnae!==undefined && errorsForm.default_ori_tras_cnae!=="") ||
                              (errorsForm.default_ori_tras_inscription!==undefined && errorsForm.default_ori_tras_inscription!=="") ||
                              (errorsForm.default_ori_tras_opt!==undefined && errorsForm.default_ori_tras_opt!=="") ||
                              (errorsForm.default_ori_tras_economicactivity!==undefined && errorsForm.default_ori_tras_economicactivity!=="") ||
                              (errorsForm.default_ori_tras_licenseplate!==undefined && errorsForm.default_ori_tras_licenseplate!=="")
                              ?<span className={classes.thirdHeading}>  -  {t('Text_Field_Character_Sheet_Invalid')} !!</span>:""}
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <TypeDICreateOrEditOriTras
                                formFrom={formFrom}
                                setFormForm={setFormForm}
                                openEditRow={true}
                                errorsForm={errorsForm}
                                setErrorsForm={setErrorsForm}
                                validateForm={validateForm}
                                documentTypesToSelect={documentTypesToSelect}
                                roadTypesToSelect={roadTypesToSelect}
                                communityToSelect={communityToSelect}
                                countryToSelect={countryToSelect}
                                expanded={expanded}
                              />
                          </AccordionDetails>
                        </Accordion>

                        <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel3bh-content"
                            id="panel3bh-header"
                            className={classes.backcolor}
                          >
                            <Typography className={classes.heading}>{t('Text_FORM_ORI_TRAS')} : <span>{t('Text_FORM_ORI_TRAS_EMPR')}</span></Typography>
                            <Typography className={classes.secondaryHeading}>
                              {formFrom.default_aut_tras_tradename!==undefined && formFrom.default_aut_tras_tradename!==null && formFrom.default_aut_tras_tradename!==""?formFrom.default_aut_tras_tradename:t('Text_NOT_REPORTING')}
                              {formFrom.default_aut_tras_documentid!=="" && formFrom.default_aut_tras_tradename===""?<span className={classes.thirdHeading}> - {t('Text_Field_Tradename_Sheet_Required')} !!</span>:""}
                              {errorsForm.default_aut_tras_email!==undefined && errorsForm.default_aut_tras_email!==""?<span className={classes.thirdHeading}>  -  {t('Text_Field_Email_Sheet_Invalid')} !!</span>:""}
                              {(errorsForm.default_aut_tras_documentid!==undefined && errorsForm.default_aut_tras_documentid!=="") ||
                              (errorsForm.default_aut_tras_tradename!==undefined && errorsForm.default_aut_tras_tradename!=="") ||
                              (errorsForm.default_aut_tras_name!==undefined && errorsForm.default_aut_tras_name!=="") ||
                              (errorsForm.default_aut_tras_contactname!==undefined && errorsForm.default_aut_tras_contactname!=="") ||
                              (errorsForm.default_aut_tras_street!==undefined && errorsForm.default_aut_tras_street!=="") ||
                              (errorsForm.default_aut_tras_phone!==undefined && errorsForm.default_aut_tras_phone!=="") ||
                              (errorsForm.default_aut_tras_nima!==undefined && errorsForm.default_aut_tras_nima!=="") ||
                              (errorsForm.default_aut_tras_cnae!==undefined && errorsForm.default_aut_tras_cnae!=="") ||
                              (errorsForm.aut_tras_inscription!==undefined && errorsForm.aut_tras_inscription!=="") ||
                              (errorsForm.default_aut_tras_inscription!==undefined && errorsForm.default_aut_tras_inscription!=="") ||
                              (errorsForm.default_aut_tras_economicactivity!==undefined && errorsForm.default_aut_tras_economicactivity!=="") ||
                              (errorsForm.default_aut_tras_licenseplate!==undefined && errorsForm.default_aut_tras_licenseplate!=="")
                              ?<span className={classes.thirdHeading}>  -  {t('Text_Field_Character_Sheet_Invalid')} !!</span>:""}
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <TypeDICreateOrEditAutTras
                                formFrom={formFrom}
                                setFormForm={setFormForm}
                                openEditRow={true}
                                errorsForm={errorsForm}
                                setErrorsForm={setErrorsForm}
                                validateForm={validateForm}
                                documentTypesToSelect={documentTypesToSelect}
                                roadTypesToSelect={roadTypesToSelect}
                                communityToSelect={communityToSelect}
                                countryToSelect={countryToSelect}
                                expanded={expanded}
                              />
                          </AccordionDetails>
                        </Accordion>

                        <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel4bh-content"
                            id="panel4bh-header"
                            className={classes.backcolor}
                          >
                            <Typography className={classes.heading}>{t('Text_FORM_DEST_TRAS')} : <span>{t('Text_FORM_DEST_TRAS_PROD')}</span></Typography>
                            <Typography className={classes.secondaryHeading}>
                              {formFrom.default_dest_tras_tradename!==undefined && formFrom.default_dest_tras_tradename!==null && formFrom.default_dest_tras_tradename!==""?formFrom.default_dest_tras_tradename:t('Text_NOT_REPORTING')}
                              {formFrom.default_dest_tras_documentid!=="" && formFrom.default_dest_tras_tradename===""?<span className={classes.thirdHeading}> - {t('Text_Field_Tradename_Sheet_Required')} !!</span>:""}
                              {errorsForm.default_dest_tras_email!==undefined && errorsForm.default_dest_tras_email!==""?<span className={classes.thirdHeading}>  -  {t('Text_Field_Email_Sheet_Invalid')} !!</span>:""}
                              {(errorsForm.default_dest_tras_documentid!==undefined && errorsForm.default_dest_tras_documentid!=="") ||
                              (errorsForm.default_dest_tras_tradename!==undefined && errorsForm.default_dest_tras_tradename!=="") ||
                              (errorsForm.default_dest_tras_name!==undefined && errorsForm.default_dest_tras_name!=="") ||
                              (errorsForm.default_dest_tras_contactname!==undefined && errorsForm.default_dest_tras_contactname!=="") ||
                              (errorsForm.default_dest_tras_street!==undefined && errorsForm.default_dest_tras_street!=="") ||
                              (errorsForm.default_dest_tras_phone!==undefined && errorsForm.default_dest_tras_phone!=="") ||
                              (errorsForm.default_dest_tras_nima!==undefined && errorsForm.default_dest_tras_nima!=="") ||
                              (errorsForm.default_dest_tras_cnae!==undefined && errorsForm.default_dest_tras_cnae!=="") ||
                              (errorsForm.dest_tras_inscription!==undefined && errorsForm.dest_tras_inscription!=="") ||
                              (errorsForm.default_dest_tras_inscription!==undefined && errorsForm.default_dest_tras_inscription!=="") ||
                              (errorsForm.default_dest_tras_economicactivity!==undefined && errorsForm.default_dest_tras_economicactivity!=="") ||
                              (errorsForm.default_dest_tras_licenseplate!==undefined && errorsForm.default_dest_tras_licenseplate!=="")
                              ?<span className={classes.thirdHeading}>  -  {t('Text_Field_Character_Sheet_Invalid')} !!</span>:""}
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <TypeDICreateOrEditDestTras
                                formFrom={formFrom}
                                setFormForm={setFormForm}
                                openEditRow={true}
                                errorsForm={errorsForm}
                                setErrorsForm={setErrorsForm}
                                validateForm={validateForm}
                                documentTypesToSelect={documentTypesToSelect}
                                roadTypesToSelect={roadTypesToSelect}
                                communityToSelect={communityToSelect}
                                countryToSelect={countryToSelect}
                                expanded={expanded}
                              />
                          </AccordionDetails>
                        </Accordion>

                        <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel5bh-content"
                            id="panel5bh-header"
                            className={classes.backcolor}
                          >
                            <Typography className={classes.heading}>{t('Text_FORM_DEST_TRAS')} : <span>{t('Text_FORM_DEST_TRAS_EMPR')}</span></Typography>
                            <Typography className={classes.secondaryHeading}>
                              {formFrom.default_aut_dest_tradename!==undefined && formFrom.default_aut_dest_tradename!==null && formFrom.default_aut_dest_tradename!==""?formFrom.default_aut_dest_tradename:t('Text_NOT_REPORTING')}
                              {formFrom.default_aut_dest_documentid!=="" && formFrom.default_aut_dest_tradename===""?<span className={classes.thirdHeading}> - {t('Text_Field_Tradename_Sheet_Required')} !!</span>:""}
                              {errorsForm.default_aut_dest_email!==undefined && errorsForm.default_aut_dest_email!==""?<span className={classes.thirdHeading}>  -  {t('Text_Field_Email_Sheet_Invalid')} !!</span>:""}
                              {(errorsForm.default_aut_dest_documentid!==undefined && errorsForm.default_aut_dest_documentid!=="") ||
                              (errorsForm.default_aut_dest_tradename!==undefined && errorsForm.default_aut_dest_tradename!=="") ||
                              (errorsForm.default_aut_dest_name!==undefined && errorsForm.default_aut_dest_name!=="") ||
                              (errorsForm.default_aut_dest_contactname!==undefined && errorsForm.default_aut_dest_contactname!=="") ||
                              (errorsForm.default_aut_dest_street!==undefined && errorsForm.default_aut_dest_street!=="") ||
                              (errorsForm.default_aut_dest_phone!==undefined && errorsForm.default_aut_dest_phone!=="") ||
                              (errorsForm.default_aut_dest_nima!==undefined && errorsForm.default_aut_dest_nima!=="") ||
                              (errorsForm.default_aut_dest_cnae!==undefined && errorsForm.default_aut_dest_cnae!=="") ||
                              (errorsForm.aut_dest_inscription!==undefined && errorsForm.aut_dest_inscription!=="") ||
                              (errorsForm.default_aut_dest_inscription!==undefined && errorsForm.default_aut_dest_inscription!=="") ||
                              (errorsForm.default_aut_dest_economicactivity!==undefined && errorsForm.default_aut_dest_economicactivity!=="") ||
                              (errorsForm.default_aut_dest_licenseplate!==undefined && errorsForm.default_aut_dest_licenseplate!=="")
                              ?<span className={classes.thirdHeading}>  -  {t('Text_Field_Character_Sheet_Invalid')} !!</span>:""}
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <TypeDICreateOrEditAutDest
                                formFrom={formFrom}
                                setFormForm={setFormForm}
                                openEditRow={true}
                                errorsForm={errorsForm}
                                setErrorsForm={setErrorsForm}
                                validateForm={validateForm}
                                documentTypesToSelect={documentTypesToSelect}
                                roadTypesToSelect={roadTypesToSelect}
                                communityToSelect={communityToSelect}
                                countryToSelect={countryToSelect}
                                expanded={expanded}
                              />
                          </AccordionDetails>
                        </Accordion>

                        <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel6bh-content"
                            id="panel6bh-header"
                            className={classes.backcolor}
                          >
                            <Typography className={classes.heading}>{t('Text_FORM_Carrier')} : </Typography>
                            <Typography className={classes.secondaryHeading}>
                              {formFrom.default_rel_trans_tradename!==undefined && formFrom.default_rel_trans_tradename!==null && formFrom.default_rel_trans_tradename!==""?formFrom.default_rel_trans_tradename:t('Text_NOT_REPORTING')}
                              {formFrom.default_rel_trans_documentid!=="" && formFrom.default_rel_trans_tradename===""?<span className={classes.thirdHeading}> - {t('Text_Field_Tradename_Sheet_Required')} !!</span>:""}
                              {errorsForm.default_rel_trans_email!==undefined && errorsForm.default_rel_trans_email!==""?<span className={classes.thirdHeading}>  -  {t('Text_Field_Email_Sheet_Invalid')} !!</span>:""}
                              {(errorsForm.default_rel_trans_documentid!==undefined && errorsForm.default_rel_trans_documentid!=="") ||
                              (errorsForm.default_rel_trans_tradename!==undefined && errorsForm.default_rel_trans_tradename!=="") ||
                              (errorsForm.default_rel_trans_name!==undefined && errorsForm.default_rel_trans_name!=="") ||
                              (errorsForm.default_rel_trans_contactname!==undefined && errorsForm.default_rel_trans_contactname!=="") ||
                              (errorsForm.default_rel_trans_street!==undefined && errorsForm.default_rel_trans_street!=="") ||
                              (errorsForm.default_rel_trans_phone!==undefined && errorsForm.default_rel_trans_phone!=="") ||
                              (errorsForm.default_rel_trans_nima!==undefined && errorsForm.default_rel_trans_nima!=="") ||
                              (errorsForm.default_rel_trans_cnae!==undefined && errorsForm.default_rel_trans_cnae!=="") ||
                              (errorsForm.rel_trans_inscription!==undefined && errorsForm.rel_trans_inscription!=="") ||
                              (errorsForm.default_rel_trans_inscription!==undefined && errorsForm.default_rel_trans_inscription!=="") ||
                              (errorsForm.default_rel_trans_economicactivity!==undefined && errorsForm.default_rel_trans_economicactivity!=="") ||
                              (errorsForm.default_rel_trans_licenseplate!==undefined && errorsForm.default_rel_trans_licenseplate!=="")
                              ?<span className={classes.thirdHeading}>  -  {t('Text_Field_Character_Sheet_Invalid')} !!</span>:""}
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <TypeDICreateOrEditRelTrans
                                formFrom={formFrom}
                                setFormForm={setFormForm}
                                openEditRow={true}
                                errorsForm={errorsForm}
                                setErrorsForm={setErrorsForm}
                                validateForm={validateForm}
                                documentTypesToSelect={documentTypesToSelect}
                                roadTypesToSelect={roadTypesToSelect}
                                communityToSelect={communityToSelect}
                                countryToSelect={countryToSelect}
                                expanded={expanded}
                              />
                          </AccordionDetails>
                        </Accordion>

                        <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel7bh-content"
                            id="panel7bh-header"
                            className={classes.backcolor}
                          >
                            <Typography className={classes.heading}>{t('Text_FORM_SRAP')} : <span>{t('Text_FORM_SRAP_DESCRIPTION')}</span></Typography>
                            <Typography className={classes.secondaryHeading}>
                              {formFrom.default_resp_prod_tradename!==undefined && formFrom.default_resp_prod_tradename!==null && formFrom.default_resp_prod_tradename!==""?formFrom.default_resp_prod_tradename:t('Text_NOT_REPORTING')}
                              {formFrom.default_resp_prod_documentid!=="" && formFrom.default_resp_prod_tradename===""?<span className={classes.thirdHeading}> - {t('Text_Field_Tradename_Sheet_Required')} !!</span>:""}
                              {errorsForm.default_resp_prod_email!==undefined && errorsForm.default_resp_prod_email!==""?<span className={classes.thirdHeading}>  -  {t('Text_Field_Email_Sheet_Invalid')} !!</span>:""}
                              {(errorsForm.default_resp_prod_documentid!==undefined && errorsForm.default_resp_prod_documentid!=="") ||
                              (errorsForm.default_resp_prod_tradename!==undefined && errorsForm.default_resp_prod_tradename!=="") ||
                              (errorsForm.default_resp_prod_name!==undefined && errorsForm.default_resp_prod_name!=="") ||
                              (errorsForm.default_resp_prod_contactname!==undefined && errorsForm.default_resp_prod_contactname!=="") ||
                              (errorsForm.default_resp_prod_street!==undefined && errorsForm.default_resp_prod_street!=="") ||
                              (errorsForm.default_resp_prod_phone!==undefined && errorsForm.default_resp_prod_phone!=="") ||
                              (errorsForm.default_resp_prod_nima!==undefined && errorsForm.default_resp_prod_nima!=="") ||
                              (errorsForm.default_resp_prod_cnae!==undefined && errorsForm.default_resp_prod_cnae!=="") ||
                              (errorsForm.resp_prod_inscription!==undefined && errorsForm.resp_prod_inscription!=="") ||
                              (errorsForm.default_resp_prod_inscription!==undefined && errorsForm.default_resp_prod_inscription!=="") ||
                              (errorsForm.default_resp_prod_economicactivity!==undefined && errorsForm.default_resp_prod_economicactivity!=="") ||
                              (errorsForm.default_resp_prod_licenseplate!==undefined && errorsForm.default_resp_prod_licenseplate!=="")
                              ?<span className={classes.thirdHeading}>  -  {t('Text_Field_Character_Sheet_Invalid')} !!</span>:""}
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <TypeDICreateOrEditRespProd
                                formFrom={formFrom}
                                setFormForm={setFormForm}
                                openEditRow={true}
                                errorsForm={errorsForm}
                                setErrorsForm={setErrorsForm}
                                validateForm={validateForm}
                                documentTypesToSelect={documentTypesToSelect}
                                roadTypesToSelect={roadTypesToSelect}
                                communityToSelect={communityToSelect}
                                countryToSelect={countryToSelect}
                                expanded={expanded}
                              />
                          </AccordionDetails>
                        </Accordion>
                        <div className="row p-2"> 
                          <div className="form-group">  
                            <h6 className="h7NewStyle mb-2">{t('Text_FORM_CHARAC')}</h6>
                            {/* Divider */}
                            <hr className="sidebar-divider d-none d-md-block"/>
                            <label className="text-primary">{t('Text_FORM_CODE_LER')} :</label>
                            &nbsp;&nbsp;&nbsp;&nbsp; 
                            <TextField
                              id="default_codeler_code" 
                              name="default_codeler_code"
                              value={formFrom.default_codeler_code}
                              inputProps={{ style: {width: 180}, maxLength: 50 }}
                              label={errorsForm.default_codeler_code!=null?t(errorsForm.default_codeler_code):t('PlaceHolder_FORM_CODE_LER')}
                              placeholder={t('PlaceHolder_FORM_CODE_LER')}
                              variant="standard"
                              onBlur={handleBlurDataEdit}
                              onChange={handleChangeDataEdit}/>
                            &nbsp;&nbsp;&nbsp;&nbsp; 
                            <TextField
                              id={i18n.language==='es'?"default_codeler_namees":"default_codeler_nameen"}
                              name={i18n.language==='es'?"default_codeler_namees":"default_codeler_nameen"}
                              value={i18n.language==='es'?formFrom.default_codeler_namees:formFrom.default_codeler_nameen}
                              inputProps={{ style: {width:i18n.language==='es'?950:970}, maxLength: 250 }}
                              label={t('Text_FORM_CODE_DESCRIPTION_TITLE')}
                              placeholder={t('Text_FORM_CODE_DESCRIPTION_TITLE')}
                              variant="standard"
                              onBlur={handleBlurDataEdit}
                              onChange={handleChangeDataEdit}
                              disabled/>
                            &nbsp;&nbsp;
                            <IconButton  size="small"  style={{color:"#e74a3b", fontSize: 12}} onClick={deleteDataCodeLer}>
                                <DeleteIcon/>
                            </IconButton>
                            &nbsp;&nbsp;
                            {roles[0].listmenus.find((key)=>(key.name==='SubMenu_Form_View_CodeLERS'))&&
                                <IconButton  size="small"  style={{color:"#4e73df", fontSize: 12}} onClick={searchFormCodeLer}>
                                    <SearchIcon/>
                                </IconButton>
                            }
                          </div>
                        </div>
                        <div className="row p-2"> 
                          <div className="form-group">
                            <label className="text-primary">{t('Text_FORM_CODE_DESCRIPTION')} :</label>
                            &nbsp;&nbsp;&nbsp;&nbsp; 
                            <TextField
                              id="default_description_code" 
                              name="default_description_code"
                              value={formFrom.default_description_code}
                              inputProps={{ style: {width: 225}, maxLength: 50 }}
                              label={errorsForm.default_description_code!=null?t(errorsForm.default_description_code):t('PlaceHolder_FORM_CODE_DESCRIPTION')}
                              placeholder={t('PlaceHolder_FORM_CODE_DESCRIPTION')}
                              variant="standard"
                              onBlur={handleBlurDataEdit}
                              onChange={handleChangeDataEdit}
                            />
                            &nbsp;&nbsp;&nbsp;&nbsp; 
                            <TextField
                              id={i18n.language==='es'?"default_description_namees":"default_description_nameen"}
                              name={i18n.language==='es'?"default_description_namees":"default_description_nameen"}
                              value={i18n.language==='es'?formFrom.default_description_namees:formFrom.default_description_nameen}
                              inputProps={{ style: {width:i18n.language==='es'?1050:1066}, maxLength: 250 }}
                              label={t('Text_FORM_CODE_DESCRIPTION_TITLE')}
                              placeholder={t('Text_FORM_CODE_DESCRIPTION_TITLE')}
                              variant="standard"
                              onBlur={handleBlurDataEdit}
                              onChange={handleChangeDataEdit}
                              disabled/>
                            &nbsp;&nbsp;
                            <IconButton  size="small"  style={{color:"#e74a3b", fontSize: 12}} onClick={deleteDataDescription}>
                              <DeleteIcon/>
                            </IconButton>
                            &nbsp;&nbsp;
                            <IconButton  size="small"  style={{color:"#4e73df", fontSize: 12}} onClick={searchFormDescription}>
                                <SearchIcon/>
                            </IconButton>           
                          </div>
                        </div>
                        <div className="row p-2"> 
                          <div className="form-group">
                            <label className="text-primary">{t('Text_FORM_CODE_TREATMENT_ORIGIN')} :</label>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <TextField
                              id="default_treatmenttypeorigin_id"
                              name="default_treatmenttypeorigin_id"
                              value={ (formFrom.default_treatmenttypeorigin_id === undefined || formFrom.default_treatmenttypeorigin_id ===null || formFrom.default_treatmenttypeorigin_id ===""
                                    || formFrom.default_treatmenttypeorigin_id ==="0" || formFrom.default_treatmenttypeorigin_id ===0)?0:formFrom.default_treatmenttypeorigin_id}
                              inputProps={{ style: {width: i18n.language==='es'?450:460}, maxLength: 100 }}
                              variant="outlined"
                              onBlur={handleBlurDataEdit}
                              onChange={handleChangeDataEdit}
                              select
                              SelectProps={{
                                native: true,
                                value: (formFrom.default_treatmenttypeorigin_id === undefined || formFrom.default_treatmenttypeorigin_id ===null || formFrom.default_treatmenttypeorigin_id ===""
                                    || formFrom.default_treatmenttypeorigin_id ==="0" || formFrom.default_treatmenttypeorigin_id ===0)?0: formFrom.default_treatmenttypeorigin_id
                              }}>
                              <option key={0} value=""/>
                              {treatmentTypeToSelect.map((option) => (
                              <option key={option.id} value={option.id}>
                                  {i18n.language==="es"?option.code+" - "+option.namees.substring(0,100):option.code+" - "+option.nameen.substring(0,100)}
                              </option>
                              ))}
                            </TextField>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <label className="text-primary">{t('Text_FORM_CODE_PROCESS_ORIGIN')} :</label>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <TextField
                              id="default_processtypeorigin_id"
                              name="default_processtypeorigin_id"
                              value={ (formFrom.default_processtypeorigin_id === undefined || formFrom.default_processtypeorigin_id ===null || formFrom.default_processtypeorigin_id ===""
                                    || formFrom.default_processtypeorigin_id ==="0" || formFrom.default_processtypeorigin_id ===0)?0:formFrom.default_processtypeorigin_id}
                              inputProps={{ style: {width: i18n.language==='es'?450:461}, maxLength: 100 }}
                              variant="outlined"
                              onBlur={handleBlurDataEdit}
                              onChange={handleChangeDataEdit}
                              select
                              SelectProps={{
                                native: true,
                                value: (formFrom.default_processtypeorigin_id === undefined || formFrom.default_processtypeorigin_id ===null || formFrom.default_processtypeorigin_id ===""
                                    || formFrom.default_processtypeorigin_id ==="0" || formFrom.default_processtypeorigin_id ===0)?0: formFrom.default_processtypeorigin_id
                              }}>
                              <option key={0} value=""/>
                              {processTypeToSelect.map((option) => (
                              <option key={option.id} value={option.id}>
                                  {i18n.language==="es"?option.code+" - "+option.namees.substring(0,100):option.code+" - "+option.nameen.substring(0,100)}
                              </option>
                              ))}
                            </TextField>
                          </div>
                        </div>

                        <div className="row p-2"> 
                          <div className="form-group">
                          <label className="text-primary">{t('Text_FORM_CODE_DANGEROUSNESS')} :</label>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <TextField
                              id="default_dangerousness_id"
                              name="default_dangerousness_id"
                              value={ (formFrom.default_dangerousness_id === undefined || formFrom.default_dangerousness_id ===null || formFrom.default_dangerousness_id ===""
                                    || formFrom.default_dangerousness_id ==="0" || formFrom.default_dangerousness_id ===0)?0:formFrom.default_dangerousness_id}
                              inputProps={{ style: {width: 170}, maxLength: 100 }}
                              variant="outlined"
                              onBlur={handleBlurDataEdit}
                              onChange={handleChangeDataEdit}
                              select
                              SelectProps={{
                                native: true,
                                value: (formFrom.default_dangerousness_id === undefined || formFrom.default_dangerousness_id ===null || formFrom.default_dangerousness_id ===""
                                    || formFrom.default_dangerousness_id ==="0" || formFrom.default_dangerousness_id ===0)?0: formFrom.default_dangerousness_id
                              }}>
                              <option key={0} value=""/>
                              {dangerousnessToSelect.map((option) => (
                              <option key={option.id} value={option.id}>
                                  {i18n.language==="es"?option.namees:option.nameen}
                              </option>
                              ))}
                            </TextField>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <label className="text-primary">{t('Text_FORM_CODE_TREATMENT_DESTINE')} :</label>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <TextField
                              id="default_treatmenttypedestine_id"
                              name="default_treatmenttypedestine_id"
                              value={ (formFrom.default_treatmenttypedestine_id === undefined || formFrom.default_treatmenttypedestine_id ===null || formFrom.default_treatmenttypedestine_id ===""
                                    || formFrom.default_treatmenttypedestine_id ==="0" || formFrom.default_treatmenttypedestine_id ===0)?0:formFrom.default_treatmenttypedestine_id}
                              inputProps={{ style: {width: i18n.language==='es'?210:190}, maxLength: 100 }}
                              variant="outlined"
                              onBlur={handleBlurDataEdit}
                              onChange={handleChangeDataEdit}
                              select
                              SelectProps={{
                                native: true,
                                value: (formFrom.default_treatmenttypedestine_id === undefined || formFrom.default_treatmenttypedestine_id ===null || formFrom.default_treatmenttypedestine_id ===""
                                    || formFrom.default_treatmenttypedestine_id ==="0" || formFrom.default_treatmenttypedestine_id ===0)?0: formFrom.default_treatmenttypedestine_id
                              }}>
                              <option key={0} value=""/>
                              {treatmentTypeToSelect.map((option) => (
                              <option key={option.id} value={option.id}>
                                  {i18n.language==="es"?option.code+" - "+option.namees.substring(0,100):option.code+" - "+option.nameen.substring(0,100)}
                              </option>
                              ))}
                            </TextField>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <label className="text-primary">{t('Text_FORM_CODE_PROCESS_DESTINE')} :</label>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <TextField
                              id="default_processtypedestine_id"
                              name="default_processtypedestine_id"
                              value={ (formFrom.default_processtypedestine_id === undefined || formFrom.default_processtypedestine_id ===null || formFrom.default_processtypedestine_id ===""
                                    || formFrom.default_processtypedestine_id ==="0" || formFrom.default_processtypedestine_id ===0)?0:formFrom.default_processtypedestine_id}
                              inputProps={{ style: {width: i18n.language==='es'?210:190}, maxLength: 100 }}
                              variant="outlined"
                              onBlur={handleBlurDataEdit}
                              onChange={handleChangeDataEdit}
                              select
                              SelectProps={{
                                native: true,
                                value: (formFrom.default_processtypedestine_id === undefined || formFrom.default_processtypedestine_id ===null || formFrom.default_processtypedestine_id ===""
                                    || formFrom.default_processtypedestine_id ==="0" || formFrom.default_processtypedestine_id ===0)?0: formFrom.default_processtypedestine_id
                              }}>
                              <option key={0} value=""/>
                              {processTypeToSelect.map((option) => (
                              <option key={option.id} value={option.id}>
                                  {i18n.language==="es"?option.code+" - "+option.namees.substring(0,100):option.code+" - "+option.nameen.substring(0,100)}
                              </option>
                              ))}
                            </TextField>
                          </div>
                        </div>
                        
                          <div className="row p-2"> 
                            <div className="form-group">  
                              <h6 className="h7NewStyle mb-2">{t('Text_CONTRACT_CHARAC')}</h6>
                              {/* Divider */}
                              <hr className="sidebar-divider d-none d-md-block"/>
                              <div className="row  px-2">
                                <label className="text-primary">{t('Text_CONTRAT_CONDITIONS')} :</label>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <TextField
                                  id="default_conditions_contract" 
                                  name="default_conditions_contract"
                                  value={formFrom.default_conditions_contract}
                                  inputProps={{ style: {width: 1140}, maxLength: 255 }}
                                  label={errorsForm.default_conditions_contract!=null?t(errorsForm.default_conditions_contract):t('PlaceHolder_CONTRAT_CONDITIONS')}
                                  placeholder={t('PlaceHolder_CONTRAT_CONDITIONS')}
                                  variant="standard"
                                  onBlur={handleBlurDataEdit}
                                  onChange={handleChangeDataEdit}
                                />
                            </div>
                          </div>

                          <div className="row p-2"> 
                            <div className="form-group">  
                                <div className="row  px-2">
                                <label className="text-primary">{t('Text_CONTRACT_OBLLIGATIONS')} :</label>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <TextField
                                  id="default_obligations_contract" 
                                  name="default_obligations_contract"
                                  value={formFrom.default_obligations_contract}
                                  inputProps={{ style: {width: 1140}, maxLength: 255 }}
                                  label={errorsForm.default_obligations_contract!=null?t(errorsForm.default_obligations_contract):t('PlaceHolder_CONTRACT_OBLLIGATIONS')}
                                  placeholder={t('PlaceHolder_CONTRACT_OBLLIGATIONS')}
                                  variant="standard"
                                  onBlur={handleBlurDataEdit}
                                  onChange={handleChangeDataEdit}
                                />
                              </div>
                            </div>

                            <div className="row p-2"> 
                              <div className="form-group"> 
                                <label className="text-primary">{t('Text_CONTRACT_DI_VIEW_AUT_TRAS')} :</label>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <Checkbox  
                                  id="default_contract_view_aut_tras"
                                  name="default_contract_view_aut_tras"
                                  style={{color:"#4e73df", fontSize: 16, paddingLeft:2}} 
                                  checked={formFrom.default_contract_view_aut_tras} 
                                  onChange={handleChangeEnabledDefaultOptions} 
                                />
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <label className="text-primary">{t('Text_CONTRACT_DI_VIEW_AUT_DEST')} :</label>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <Checkbox  
                                  id="default_contract_view_aut_dest"
                                  name="default_contract_view_aut_dest"
                                  style={{color:"#4e73df", fontSize: 16, paddingLeft:2}} 
                                  checked={formFrom.default_contract_view_aut_dest} 
                                  onChange={handleChangeEnabledDefaultOptions} 
                                />
                              </div>
                            </div> 

                            <div className="row p-2"> 
                              <div className="form-group"> 
                                <label className="text-primary">{t('Text_CONTRACT_MAX_CODE_LER')} :</label>
                                &nbsp;&nbsp;&nbsp;&nbsp; 
                                <NumericFormat 
                                  thousandSeparator={','} 
                                  decimalSeparator={'.'} 
                                  decimalScale= {0}
                                  fixedDecimalScale= {true}
                                  allowNegative= {false}
                                  isAllowed={withValueCap20}
                                  id="limitcodelerscontract" 
                                  name="limitcodelerscontract"
                                  value={formFrom.limitcodelerscontract}
                                  label={errorsForm.limitcodelerscontract!=null?t(errorsForm.limitcodelerscontract):t('PlaceHolder_CONTRACT_MAX_CODE_LER')}
                                  placeholder={t('PlaceHolder_CONTRACT_MAX_CODE_LER')}
                                  style={{width: 150}}
                                  customInput={TextField}
                                  onBlur={handleBlurDataEditNumber}
                                  onChange={handleChangeDataEditNumer}
                                />
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <label className="text-primary">{t('Text_CONTRACT_MAX_PROCESSESTYPE')} :</label>
                                &nbsp;&nbsp;&nbsp;&nbsp; 
                                <NumericFormat 
                                  thousandSeparator={','} 
                                  decimalSeparator={'.'} 
                                  decimalScale= {0}
                                  fixedDecimalScale= {true}
                                  allowNegative= {false}
                                  isAllowed={withValueCap20}
                                  id="limitprocesstypecontract" 
                                  name="limitprocesstypecontract"
                                  value={formFrom.limitprocesstypecontract}
                                  label={errorsForm.limitprocesstypecontract!=null?t(errorsForm.limitprocesstypecontract):t('PlaceHolder_CONTRACT_MAX_PROCESSESTYPE')}
                                  placeholder={t('PlaceHolder_CONTRACT_MAX_PROCESSESTYPE')}
                                  style={{width: 150}}
                                  customInput={TextField}
                                  onBlur={handleBlurDataEditNumber}
                                  onChange={handleChangeDataEditNumer}
                                />
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <label className="text-primary">{t('Text_CONTRACT_MAX_TREATMENTTYPE')} :</label>
                                &nbsp;&nbsp;&nbsp;&nbsp; 
                                <NumericFormat 
                                  thousandSeparator={','} 
                                  decimalSeparator={'.'} 
                                  decimalScale= {0}
                                  fixedDecimalScale= {true}
                                  allowNegative= {false}
                                  isAllowed={withValueCap20}
                                  id="limitreatmenttypecontract" 
                                  name="limitreatmenttypecontract"
                                  value={formFrom.limitreatmenttypecontract}
                                  label={errorsForm.limitreatmenttypecontract!=null?t(errorsForm.limitreatmenttypecontract):t('PlaceHolder_CONTRACT_MAX_TREATMENTTYPE')}
                                  placeholder={t('PlaceHolder_CONTRACT_MAX_TREATMENTTYPE')}
                                  style={{width: 150}}
                                  customInput={TextField}
                                  onBlur={handleBlurDataEditNumber}
                                  onChange={handleChangeDataEditNumer}
                                />
                              </div>
                            </div> 


                          </div> 
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </Box>
          <br/>
          <TypesDISearchCodeLers
            showModalSearchCodeLERS={showModalSearchCodeLERS}
            setShowModalSearchCodeLERS={setShowModalSearchCodeLERS} 
            setFormForm={setFormForm}
          />
            <TypesDISearchDescriptions
            showModalSearchDescriptions={showModalSearchDescriptions}
            setShowModalSearchDescriptions={setShowModalSearchDescriptions} 
            setFormForm={setFormForm}
          />
        </div>
      )} 
    </>
  )
}

EditOrCreateTypesDIFormat.propTypes = {
  id: PropTypes.string.isRequired,
  showModalTypesDISettings: PropTypes.bool.isRequired
};

export default EditOrCreateTypesDIFormat;