import React, {  useEffect, useState, useCallback } from 'react';  
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux"; 
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom'; 
import { NumericFormat } from 'react-number-format';
import { TextField } from '@mui/material';
import { Typography } from '@mui/material';
import { Button } from '@mui/material';
import { Box } from '@mui/material'; 
import { helpHttp } from "service/HelpHttp";
import { successErrorAction, warningErrorAction } from "actions/ErrorActions"; 
import IconButton from '@mui/material/IconButton'
import ScaleIcon from '@mui/icons-material/Scale';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import "css/generalSettings.css";  
import { experimentalStyled as styled } from '@mui/material/styles'; 
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';  
import TicketsSearchStores from "pages/tickets/ticketsEdit/TicketsSearchStores";
import TicketsSearchStoresAddresses from "pages/tickets/ticketsEdit/TicketsSearchStoresAddress";
import TicketsSearchVehicles from "pages/tickets/ticketsEdit/TicketsSearchVehicles"; 
import InvoiceTickets from "pages/tickets/ticketsEdit/invoiceTicketsEdit/InvoiceTickets";
import TicketsSearchArticles from "pages/tickets/ticketsEdit/TicketsSearchArticles";
import TicketsSelectArticle from "pages/tickets/ticketsEdit/TicketsSelectArticle";  
import TicketsSelectArticleWithOutWeight from "pages/tickets/ticketsEdit/TicketsSelectArticlesWithOutWeight";  
import TicketsSearchDI from "pages/tickets/ticketsEdit/TicketsSearchDI";  

import PropTypes from 'prop-types';

const initialState = [{
  "id" : "",
  "defaultStore_id":0, 
  "tradename": "",
  "useraccount_id":0,
  "username": "",
  "defaultformatnumber":1,
  "isfinished":false, 
  "numberticket":0, 
  "purchasenote":0, 
  "ownnumbertickes":0, 
  "start_date_ticket": "",
  "end_date_ticket": "", 
  "vehiclestore_id": 0,
  "vehiclestore_licenseplate": "",
  "vehiclestore_tara": 0,
  "vehiclestore_pma": 0,
  "vehiclestore_checkpma": true,
  "vehiclestore_defaultdriver": "",
  "vehiclestore_store_id": 0,
  "rel_trans_store_id": 0,
  "rel_trans_documnettype_id": 0,
  "rel_trans_documentid": "",
  "rel_trans_documnettype_name": "",
  "rel_trans_tradename": "",
  "rel_trans_name": "",
  "rel_trans_contacperson": "",
  "rel_trans_roadtype_id": 0,
  "rel_trans_street": "",
  "rel_trans_country_id": 1,
  "rel_trans_province_id": 30,
  "rel_trans_postalcode_id": 0,
  "ori_tras_store_id": 0,
  "ori_tras_documnettype_id": 0,
  "ori_tras_documnettype_name": "",
  "ori_tras_documentid": "",
  "ori_tras_tradename": "",
  "ori_tras_name": "",
  "ori_tras_contacperson": "",
  "ori_tras_roadtype_id": 0,
  "ori_tras_street": "",
  "ori_tras_country_id": 1,
  "ori_tras_province_id": 30,
  "ori_tras_postalcode_id": 0,
  "observations": "",
  "totalstartweight": 0,
  "totalendweight": 0,
  "newweightreading":0,
  "enabled":true,
  "linestickets":[],
  "linesticketsWithOutWeight":[]
}];

const initialProvincesToSelect = [{
  "id" : "",
  "name" : "",
  "enabled" : true,
  "country_id":1
}];

const initialPostalCodesToSelect = [{
  "id":"",
  "postalcode":"",
  "name":"",
  "postalcodename":"",
  "enabled":true,
  "province_id":30
}];

const initialArticlesToSelect = [{
  "id": 0,
  "indexrowarticle":0, 
  "tickets_id":0,
  "article_id":0,
  "article_code": "",
  "article_namees": "",
  "article_nameen": "",
  "start_date_ticket": "",
  "end_date_ticket": "",
  "firstweight":0,
  "secondweight":0, 
  "estimatedprice":0, 
  "discount":0, 
  "tax":0, 
  "taxincluded":false ,
  "ticketweight":true,
  "formdi_id":0,
  "ownformdi_id":0
}];
 
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  ...theme.applyStyles('dark', {
    backgroundColor: '#1A2027',
  }),
}));

const TicketsEditOrCreate = ({showModalTicketsSettings}) => {
  const {t,i18n} = useTranslation(['listDataTickets']); 
  const token = useSelector((state) => state.loginUser.token); 
  const roles = useSelector((state) => state.loginUser.roles); 
  const defaultStore = useSelector((state) => state.loginUser.defaultStore)
  const useraccount_id = useSelector((state) => state.loginUser.useraccount_id);  
  const ENDPOINT = useSelector((state) => state.variablesUser.ENDPOINT);  
  const ENDPOINTPRINTER = useSelector((state) => state.loginUser.defaultticketsformat.endpoinrprinter);
  const DEFAULTCOUNTRY = useSelector((state) => state.formatDIUser.default_country); 
  const DEFAULTPROVINCE = useSelector((state) => state.formatDIUser.default_province); 
  const ENDPOINTWEIGHT = useSelector((state) => state.loginUser.defaultticketsformat.endpointweight);
  const LIMITSARTICLESPERWEIGHT = useSelector((state) => state.loginUser.defaultticketsformat.limitsarticleperweight);
  const scale_protocol = useSelector((state) => state.loginUser.defaultticketsformat.scale_protocol); 
  const scale_com = useSelector((state) => state.loginUser.defaultticketsformat.scale_com); 
  const scale_delay = useSelector((state) => state.loginUser.defaultticketsformat.scale_delay); 
  const scale_baud_rate = useSelector((state) => state.loginUser.defaultticketsformat.scale_baud_rate); 
  const scale_parity = useSelector((state) => state.loginUser.defaultticketsformat.scale_parity); 
  const scale_data_bits = useSelector((state) => state.loginUser.defaultticketsformat.scale_data_bits); 
  const scale_stop_bits = useSelector((state) => state.loginUser.defaultticketsformat.scale_stop_bits); 
  const scale_DTR = useSelector((state) => state.loginUser.defaultticketsformat.scale_DTR); 
  const scale_RTS = useSelector((state) => state.loginUser.defaultticketsformat.scale_RTS); 
  const scale_answer = useSelector((state) => state.loginUser.defaultticketsformat.scale_answer); 
  const scale_get = useSelector((state) => state.loginUser.defaultticketsformat.scale_get); 
  const numberoftickets = useSelector((state) => state.loginUser.defaultticketsformat.numberoftickets); 
  const headerPrinter = useSelector((state) => state.loginUser.defaultticketsformat.headerPrinter);
  const printerticket_type = useSelector((state) => state.loginUser.defaultticketsformat.printerticket_type);
  const printerticket_interface = useSelector((state) => state.loginUser.defaultticketsformat.printerticket_interface);
  const printerticket_driver = useSelector((state) => state.loginUser.defaultticketsformat.printerticket_driver);
  const articlesButtonsList = useSelector((state) => state.loginUser.defaultticketsformat.articlesButtonsList); 
  const dispatch = useDispatch();
  const navigate = useNavigate();  
  const [form, setForm] = useState(initialState[0]);
  const [errorsForm, setErrorsForm] = useState({});
  const [provinceToSelect,setProvinceToSelect]= useState(initialProvincesToSelect);
  const [postalCodeToSelect,setPostalCodeToSelect]= useState(initialPostalCodesToSelect);
  const [showModalSearchStores, setShowModalSearchStores] = useState(false); 
  const [showModalSearchStoresAddress, setShowModalSearchStoresAddress] = useState(false);  
  const [showModalSearchVehicles, setShowModalSearchVehicles] = useState(false);  
  const [showModalSearchDI, setShowModalSearchDI] = useState(false);  
  const [tetxtSearch, setTetxtSearch] = useState("Rel_Trans");   
  const [showModalSearchArticles, setShowModalSearchArticles] = useState(false); 
  const [editRowArticles, setEditRowArticles] = useState(initialArticlesToSelect);   
  const [unlockData,setUnlockData]=useState(false);  
  const [unlockDataChangeState,setUnlockDataChangeState]=useState(false);  
  const [submitTickets,setSubmitTickets]=useState(false);
  const [refeshData,setRefeshData]=useState(false);
  
  const [configPrinter, setConfigPrinter] = useState({
    protocol: scale_protocol,
    comunication: scale_com,
    delay: scale_delay,
    baudRate: scale_baud_rate,
    parity: scale_parity,
    dataBits: scale_data_bits,
    stopBits: scale_stop_bits,
    dtr: scale_DTR,
    rts: scale_RTS,
    answer: scale_answer,
    getfetch: scale_get
  });
 
  const keyPress = useCallback(
    e => {
      if (e.key === 'Escape' && showModalTicketsSettings) {
       
        setErrorsForm({});
        setForm(form => ({
          ...form,
          linestickets: [],
          linesticketsWithOutWeight:[]

        }));
        setForm(initialState[0]);
        setProvinceToSelect(initialProvincesToSelect);
        setPostalCodeToSelect(initialPostalCodesToSelect);
        setShowModalSearchStores(false);
        setShowModalSearchStoresAddress(false);
        setShowModalSearchVehicles(false);
        setShowModalSearchDI(false);
        setTetxtSearch("Rel_Trans ");   
        setEditRowArticles(initialArticlesToSelect); 
        setSubmitTickets(false); 
        setRefeshData(false);
        setUnlockData(false);
        setShowModalSearchArticles(false);
        setUnlockDataChangeState(false); 
        setConfigPrinter({});
        navigate("/home") 
        return () => {};
      }
    },
    [
      setForm,
      setErrorsForm,
      setProvinceToSelect,
      setPostalCodeToSelect,
      setShowModalSearchStores,
      setShowModalSearchStoresAddress,
      setShowModalSearchVehicles,
      setShowModalSearchDI,
      setTetxtSearch,  
      setEditRowArticles, 
      setUnlockData, 
      setShowModalSearchArticles,
      setUnlockDataChangeState,
      setConfigPrinter,
      showModalTicketsSettings,  
      setSubmitTickets,
      setRefeshData,
      navigate]
  );
  
  const handleCloseDataEditTickets = () => {
    setErrorsForm({});
    setForm(form => ({
      ...form,
      linestickets: [],
      linesticketsWithOutWeight:[]
    }));
    setForm(initialState[0]);
    setProvinceToSelect(initialProvincesToSelect);
    setPostalCodeToSelect(initialPostalCodesToSelect);
    setShowModalSearchStores(false);
    setShowModalSearchStoresAddress(false);
    setShowModalSearchVehicles(false);
    setShowModalSearchDI(false);
    setTetxtSearch("Rel_Trans");   
    setEditRowArticles(initialArticlesToSelect);   
    setUnlockData(false); 
    setShowModalSearchArticles(false);
    setUnlockDataChangeState(false);
    setConfigPrinter({});
    setSubmitTickets(false); 
    setRefeshData(false);
    navigate("/home")  
	};

  const beginDataEditTickets = () => {
    setErrorsForm({});
    setForm(initialState[0]);
    setForm(form => ({
      ...form,
      id:"",
      defaultStore_id:defaultStore.id,
      useraccount_id:useraccount_id,
      isfinished:false,
      rel_trans_country_id: Number(DEFAULTCOUNTRY),
      rel_trans_province_id:  Number(DEFAULTPROVINCE),
      rel_trans_postalcode_id: 0,
      ori_tras_country_id: Number(DEFAULTCOUNTRY),
      ori_tras_province_id: Number(DEFAULTPROVINCE),
      ori_tras_postalcode_id: 0,
      linestickets: [],
      linesticketsWithOutWeight:[]
    }));
    setEditRowArticles(editRowArticles => ({
      ...editRowArticles,
      id:0,
      indexrowarticle:0,
      tickets_id:0,
      article_id: 0,
      article_code: "",
      article_namees: "",
      article_nameen: "",
      start_date_ticket: "",
      end_date_ticket: "",
      firstweight: 0,
      secondweight:0,
      estimatedprice:0,
      discount:0,
      tax:0,
      taxincluded:false,
      ticketweight:true,
      formdi_id:0,
      ownformdi_id:0
    }));   
    setShowModalSearchStores(false);
    setShowModalSearchStoresAddress(false);
    setShowModalSearchVehicles(false);
    setShowModalSearchDI(false);
    setShowModalSearchArticles(false);
    setTetxtSearch("Rel_Trans");
    setSubmitTickets(true); 
    setRefeshData(true);
	};

  const formatNumber = (num) => {
    num = (num == null || num === "" || isNaN(Number(num))) ? 0 : num;
    return num.toLocaleString('es-ES', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    }) + ' Kg';
  };

  const withValueCap = (inputObj) => {
    const { value } = inputObj;
    if (value <= 1000000) return true;
    return false;
  };

  const handleChangeTickets = (e) => {
    const { name, value } = e.target;
    setForm(form => ({
      ...form,
      [name]: value,
    }));
  };

  const handleBlurTickets = (e) => {
    handleChangeTickets(e);
    setErrorsForm(validateTickets());
  };

  const searchVehicles = (event) => { 
    setShowModalSearchVehicles(true);
  };

  const deleteDataVehicles = (event) => {
    setForm(form => ({
      ...form,
      vehiclestore_id:0,
      vehiclestore_licenseplate: "",
      vehiclestore_tara:0,
      vehiclestore_pma: 0,
      vehiclestore_checkpma: false,
      vehiclestore_defaultdriver: "",
      vehiclestore_store_id: 0,
      totalstartweight:0
    })); 
    setEditRowArticles(editRowArticles => ({
      ...editRowArticles,
      article_id: 0,
      article_code: "",
      article_namees: "",
      article_nameen: "",
      start_date_ticket: "",
      end_date_ticket: "",
      firstweight: 0,
      secondweight:0 , 
      estimatedprice:0,
      discount:0,
      tax:0,
      taxincluded:false,
      ticketweight:true,
      formdi_id:0,
      ownformdi_id:0
    })); 
    setUnlockData(false);
    setUnlockDataChangeState(false);
    setErrorsForm(validateTickets());
  };

  const searchStoreRelTrans = (event) => {
    setTetxtSearch("Rel_Trans")
    setShowModalSearchStores(true);
  };

  const searchStoreOriTras = (event) => {
    setTetxtSearch("Ori_Tras")
    setShowModalSearchStores(true);
  };

  const deleteDataStoreRelTrans = (event) => {
    setForm(form => ({
      ...form,
      rel_trans_store_id: 0,
      rel_trans_documnettype_id: 0,
      rel_trans_documnettype_name: "",
      rel_trans_documentid: "",
      rel_trans_tradename: "",
      rel_trans_name: "",
      rel_trans_contacperson: "",
      rel_trans_roadtype_id: 0,
      rel_trans_street: "",
      rel_trans_country_id: Number(DEFAULTCOUNTRY),
      rel_trans_province_id:  Number(DEFAULTPROVINCE),
      rel_trans_postalcode_id: 0,
    }));  
    setErrorsForm(validateTickets());
  };

  const deleteDataStoreOriTras = (event) => {
    setForm(form => ({
      ...form,
      ori_tras_store_id: 0,
      ori_tras_documnettype_id: 0,
      ori_tras_documnettype_name: "",
      ori_tras_documentid: "",
      ori_tras_tradename: "",
      ori_tras_name: "",
      ori_tras_contacperson: "",
      ori_tras_roadtype_id: 0,
      ori_tras_street: "",
      ori_tras_country_id: Number(DEFAULTCOUNTRY),
      ori_tras_province_id:  Number(DEFAULTPROVINCE),
      ori_tras_postalcode_id: 0,
    }));  
    setErrorsForm(validateTickets());
  };

  const deleteDataStoreAddressOriTras = (event) => {
    setForm(form => ({
      ...form,
      ori_tras_roadtype_id: 0,
      ori_tras_street: "",
      ori_tras_country_id: Number(DEFAULTCOUNTRY),
      ori_tras_province_id:  Number(DEFAULTPROVINCE),
      ori_tras_postalcode_id: 0,
    }));  
    setErrorsForm(validateTickets());
  };

  const searchStoreAddress = (event) => {
    setShowModalSearchStoresAddress(true);
  };
 
  const selectArticle = (post) => {
    if (post.button_article_ticketweight){
      setEditRowArticles(editRowArticles => ({
        ...editRowArticles,
        article_id: post.button_article_id,
        article_code: post.button_article_code,
        article_namees: post.button_article_namees,
        article_nameen: post.button_article_nameen,
        start_date_ticket: "",
        end_date_ticket: "",
        firstweight: 0,
        secondweight:unlockData?form.vehiclestore_tara:0,
        estimatedprice: post.button_article_estimatedprice ,
        discount: 0,
        tax: post.button_article_tax ,
        taxincluded: post.button_article_taxincluded,
        ticketweight:post.button_article_ticketweight,
        formdi_id:0,
        ownformdi_id:0
      }));
      setForm(form => ({
        ...form,
        totalendweight:unlockData?form.vehiclestore_tara:0
      }))
    }else{
      if(form.linesticketsWithOutWeight.length>=LIMITSARTICLESPERWEIGHT){ 
        dispatch(warningErrorAction(t('Text_LIMITARTICESPERWEIGHT')));
      }else {
        let articleToAdd={
          id:"",
          indexrowarticle:form.linesticketsWithOutWeight.length>0?form.linesticketsWithOutWeight[form.linesticketsWithOutWeight.length-1].indexrowarticle+1:1,  
          tickets_id:0,
          article_id: post.button_article_id,
          article_code: post.button_article_code,
          article_namees: post.button_article_namees,
          article_nameen: post.button_article_nameen,
          start_date_ticket: "",
          end_date_ticket: "",
          firstweight: 1,
          secondweight:0,
          estimatedprice: post.button_article_estimatedprice ,
          discount: 0,
          tax: post.button_article_tax ,
          taxincluded: post.button_article_taxincluded,
          ticketweight:post.button_article_ticketweight,
          formdi_id:0,
          ownformdi_id:0
        }
        form.linesticketsWithOutWeight.push(articleToAdd);
        setForm(form => ({
          ...form
        }))
      }
    }
   
  };
  
  const validateTickets = () => {
    let errorsForm = {};
    //let regexNumber = /^[0-9]+$/;
    let regexInvalidCharacters = /[\\!\\$\\%\\&\\|\\<\\>\\?\\{\\}\\~\\"\\']+/;
    
    if (regexInvalidCharacters.test(form.rel_trans_documentid)) {
      errorsForm.rel_trans_documentid = 'Text_TextNoValid';
    }
    
    if (regexInvalidCharacters.test(form.ori_tras_documentid)) {
      errorsForm.ori_tras_documentid = 'Text_TextNoValid';
    }
   
    if (regexInvalidCharacters.test(form.ori_tras_street)) {
      errorsForm.ori_tras_street = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(form.observations)) {
      errorsForm.observations = 'Text_TextNoValid';
    } 

    if ( !form.vehiclestore_licenseplate ||  form.vehiclestore_licenseplate==="") {
      errorsForm.vehiclestore_licenseplate = 'Text_FieldVehicleStoreLicensePlateRequired';
    } else {
      if (regexInvalidCharacters.test(form.vehiclestore_licenseplate)) {
        errorsForm.vehiclestore_licenseplate = 'Text_TextNoValid';
      }  
    }

    if (!form.rel_trans_tradename ||  form.rel_trans_tradename==="") {
      errorsForm.rel_trans_tradename = 'Text_FieldRelTrasTradenameRequired';
    } else {
      if (regexInvalidCharacters.test(form.rel_trans_tradename)) {
        errorsForm.rel_trans_tradename = 'Text_TextNoValid';
      }  
    }

    if (!form.ori_tras_tradename || form.ori_tras_tradename==="") {
      errorsForm.ori_tras_tradename = 'Text_FieldOriTrasTradenameRequired';
    } else {
      if (regexInvalidCharacters.test(form.ori_tras_tradename)) {
        errorsForm.ori_tras_tradename = 'Text_TextNoValid';
      }  
    }

    if (editRowArticles.article_code===undefined  || editRowArticles.article_code==="") {
        errorsForm.linestickets = 'Text_FieldArticlesRequired';
    }

    if (editRowArticles.article_id===undefined  || editRowArticles.article_id==="" || editRowArticles.article_id==="0" || editRowArticles.article_id===0) {
      errorsForm.linestickets = 'Text_FieldArticlesRequired';
  }

    if (form.totalstartweight===undefined  || form.totalstartweight===0) {
      errorsForm.totalstartweight = 'Text_FieldTtotalIsNull';
    }

     return errorsForm;
  };

  const openExternalWeigh = () => {
    // CÓDIGO LECTURA EXTERNA PESA
    const fetchData = async () => {
      try {
        const getData = await helpHttp().postWeigt(ENDPOINTWEIGHT,`api/config`,configPrinter);
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_ERROR_WEIGHT'))); 
        } else {
          if (getData.portData!==undefined && getData.portData!==null & getData.portData!==""){
            handleChangeArticleWeight(getData.portData); 
          }
        }       
      } catch (error) {
        dispatch(warningErrorAction(t('Text_ERROR_WEIGHT'))); 
      }
    };
    fetchData();
  }; 
  const handleChangeArticleWeight = (value) => {
    let newValue=value!==null?Number((value.toLocaleString()).replaceAll(",","")):0;
    setEditRowArticles(editRowArticles => ({
      ...editRowArticles,
      firstweight: newValue
    })); 
    setForm(form => ({
     ...form,
     totalstartweight:newValue
    }));
  };

  const handleSubmitDataEditTickets = () => {
    const errors = validateTickets();
    setErrorsForm(errors);
    if ((Object.keys(errors).length === 0) 
        && form.vehiclestore_licenseplate!=='' 
        && form.rel_trans_tradename!=='' 
        && form.totalstartweight!==0
        && editRowArticles.article_id!==0
        && editRowArticles.article_code!==""
        && form.ori_tras_tradename!==''   ) {

      const fetchDataPrintTicket = async (dataToPrint) => {
        let isMounted = true;
        try {
          let respondeData={
            "headerPrinter":headerPrinter,
            "numberoftickets":numberoftickets,
            "printerticket_type":printerticket_type,
            "printerticket_interface":printerticket_interface,
            "printerticket_driver":printerticket_driver,
            "start_date_ticket":dataToPrint.start_date_ticket,
            "numberticket":(dataToPrint.numberticket.toString() || ''),
            "ori_tras_tradename": (dataToPrint.ori_tras_tradename || '') ,
            "rel_trans_tradename": (dataToPrint.rel_trans_tradename || ''),
            "vehiclestore_licenseplate":(dataToPrint.vehiclestore_licenseplate || ''), 
            "ori_tras_street":(dataToPrint.ori_tras_street || ''), 
            "linestickets": [],
            "linesticketsWithOutWeight":[]
          } 
          dataToPrint.linestickets.forEach((line) => {
            let formattedFirstWeight = formatNumber(line.firstweight);
            let formattedSecondWeight = line.secondweight!==0?formatNumber(line.secondweight):"";
            let formattedNetoWeight = line.secondweight!==0?formatNumber(line.firstweight - line.secondweight):"";
            let lineToAdd={
              "article_code":(line.article_code || ''),
              "article_namees":(line.article_namees || ''),
              "start_date_ticket":line.start_date_ticket,
              "end_date_ticket":line.end_date_ticket,
              "firstweight":formattedFirstWeight,
              "secondweight": formattedSecondWeight,
              "totalweight": formattedNetoWeight
            }
            respondeData.linestickets.push(lineToAdd); 
          });
          dataToPrint.linesticketsWithOutWeight.forEach((line) => {
            let formattedFirstWeight = formatNumber(line.firstweight);
            let formattedSecondWeight = line.secondweight!==0?formatNumber(line.secondweight):"";
            let formattedNetoWeight = line.secondweight!==0?formatNumber(line.firstweight - line.secondweight):"";
            let lineToAdd={
              "article_code":(line.article_code.substring(0, 33) || ''),
              "article_namees":(line.article_namees.substring(0, 33) || ''),
              "firstweight":formattedFirstWeight,
              "secondweight": formattedSecondWeight,
              "totalweight": formattedNetoWeight
            }
            respondeData.linesticketsWithOutWeight.push(lineToAdd); 
          });

          const getData = await helpHttp().sendTickets(ENDPOINTPRINTER, `api/print-ticket`,{respondeData}); 
          if (getData.message === "OK") {
            dispatch(successErrorAction(t('Text_PRINTER_SEND'))); 
          } else {
            if (isMounted ) {
              dispatch(warningErrorAction(t('Text_PRINTER_ERROR_CONECT')));
            }
          }
        } catch (error) {
          dispatch(warningErrorAction(t('Text_PRINTER_ERROR_CONECT')));
        }
        return () => { isMounted = false;};
      };

      const fetchData = async () => {
        let isMounted = true;
        try {
          const getData = (form.id === undefined || form.id === "" || form.id === "0" || form.id === 0)
            && await helpHttp().post(ENDPOINT, `tickets/saveticket`, token,form);
          if (getData.status === "OK") {
            if (getData.response.id!==undefined && getData.response.id!=="" && getData.response.id!=="0"  && getData.response.id!==0 ){
              fetchDataPrintTicket(getData.response);
            }
            setRefeshData(true);
            dispatch(successErrorAction(t('Text_SaveData'))); 
          } else {
            if (isMounted ) {
              dispatch(warningErrorAction(t(getData.cause)));
            }
          }
        } catch (error) {
          dispatch(warningErrorAction(t('Text_IncorrectData'))); 
        }
        return () => { isMounted = false;};
      };

      const fetchData2 = async () => {
        let isMounted = true;
        try {
          const getData = (form.id === undefined || form.id === "" || form.id === "0" || form.id === 0)
            && await helpHttp().post(ENDPOINT, `tickets/saveticketandpurchase`, token,form);
          if (getData.status === "OK") {
            if (getData.response ){
              fetchDataPrintTicket(getData.response);
            }
            setRefeshData(true);
            dispatch(successErrorAction(t('Text_SaveData')));
          } else {
            if (isMounted ) {
              dispatch(warningErrorAction(t(getData.cause)));
            }
          }
        } catch (error) {
          dispatch(warningErrorAction(t('Text_IncorrectData'))); 
        }
        return () => { isMounted = false;};
      };
      if(unlockDataChangeState && editRowArticles.firstweight<form.totalendweight){
        dispatch(warningErrorAction(t('Text_FieldTtotalIsless'))); 
      }else{
        let newArticleToAdd={
          "id": 0,
          "indexrowarticle":0, 
          "tickets_id":0,
          "article_id":editRowArticles.article_id,
          "article_code": editRowArticles.article_code,
          "article_namees": editRowArticles.article_namees,
          "article_nameen": editRowArticles.article_nameen,
          "start_date_ticket": "",
          "end_date_ticket": "",
          "firstweight":editRowArticles.firstweight,
          "secondweight":!unlockDataChangeState?0:editRowArticles.secondweight,
          "estimatedprice": editRowArticles.estimatedprice ,
          "discount": editRowArticles.discount ,
          "tax": editRowArticles.tax ,
          "taxincluded": editRowArticles.taxincluded,
          "ticketweight":editRowArticles.ticketweight,
          "formdi_id":editRowArticles.formdi_id,
          "ownformdi_id":editRowArticles.ownformdi_id
        }
        form.linestickets.push(newArticleToAdd);
        if(!unlockDataChangeState){
          setForm(form => ({
            ...form,
            totalendweight:0
          }));
          fetchData();
        }else{
          setForm(form => ({
            ...form,
            totalendweight:form.totalendweight
          }));
          fetchData2();
        }
        form.linestickets.splice(1);
        beginDataEditTickets();
      }
      
    } else {
      if (errors.vehiclestore_licenseplate){
        dispatch(warningErrorAction(t(errors.vehiclestore_licenseplate)));
      }else{
        if (errors.rel_trans_tradename){
          dispatch(warningErrorAction(t(errors.rel_trans_tradename)));
        }else{
          if (errors.ori_tras_tradename){
            dispatch(warningErrorAction(t(errors.ori_tras_tradename)));
          }else{
            if (errors.linestickets){
              dispatch(warningErrorAction(t(errors.linestickets)));
            }else{
              if (errors.totalstartweight){
                dispatch(warningErrorAction(t(errors.totalstartweight)));
              }else{
                dispatch(warningErrorAction(t('Text_IncorrectData')));
              } 
            }
          }
        }   
      }
    }
  };
                
  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event && typeof event === 'object' && event.key) {
        keyPress(event);
      }
    };
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [keyPress]);

  useEffect(() => {
    let isMounted = true;
    const fetchExistNumberDocumentRelTrans = async () => {
      try {
        const getData = await helpHttp().get2(ENDPOINT,`ticketsgetdata/getstorebynumberdocument/`,token,form.rel_trans_documentid);
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          if (getData>0 && getData!==form.rel_trans_store_id){
            fetchStoreNumberDocumentRelTrans();
          }
        }
      } catch (error) {
          dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };

    const fetchStoreNumberDocumentRelTrans = async () => {
      try {
          const getData = await helpHttp().get5(ENDPOINT,`ticketsgetdata/getstorebynumberdocumentpriority`,token,form.rel_trans_documentid,6);
          if (getData.err) {
              dispatch(warningErrorAction(t('Text_InvalidCredentials')));
          } else if (isMounted) {
              if (getData!==undefined && getData!==null && getData!==""){
                  setForm(form => ({
                      ...form,
                      rel_trans_store_id: getData.rel_trans_store_id,
                      rel_trans_documnettype_id: (getData.rel_trans_documnettype_id===undefined 
                                              || getData.rel_trans_documnettype_id===null  
                                              || getData.rel_trans_documnettype_id===""
                                              || getData.rel_trans_documnettype_id==="0"
                                              || getData.rel_trans_documnettype_id===0)?
                                              0:getData.rel_trans_documnettype_id,
                      rel_trans_documentid: getData.rel_trans_documentid,
                      rel_trans_documnettype_name: getData.rel_trans_documnettype_name,
                      rel_trans_tradename: getData.rel_trans_tradename,
                      rel_trans_name: getData.rel_trans_name,
                      rel_trans_contacperson: getData.rel_trans_contacperson,
                      rel_trans_roadtype_id: (getData.rel_trans_roadtype_id===undefined 
                                            || getData.rel_trans_roadtype_id===null  
                                            || getData.rel_trans_roadtype_id===""
                                            || getData.rel_trans_roadtype_id==="0"
                                            || getData.rel_trans_roadtype_id===0)?
                                            0:getData.rel_trans_roadtype_id,
                      rel_trans_street: getData.rel_trans_street,
                      rel_trans_country_id: (getData.rel_trans_country_id===undefined 
                                            || getData.rel_trans_country_id===null  
                                            || getData.rel_trans_country_id===""
                                            || getData.rel_trans_country_id==="0"
                                            || getData.rel_trans_country_id===0)?
                                            Number(DEFAULTCOUNTRY):getData.rel_trans_country_id,
                      rel_trans_province_id: (getData.rel_trans_province_id===undefined 
                                            || getData.rel_trans_province_id===null  
                                            || getData.rel_trans_province_id===""
                                            || getData.rel_trans_province_id==="0"
                                            || getData.rel_trans_province_id===0)?
                                            Number(DEFAULTPROVINCE):getData.rel_trans_province_id,
                      rel_trans_postalcode_id: (getData.rel_trans_postalcode_id===undefined 
                                                || getData.rel_trans_postalcode_id===null  
                                                || getData.rel_trans_postalcode_id===""
                                                || getData.rel_trans_postalcode_id==="0"
                                                || getData.rel_trans_postalcode_id===0)?
                                                "":getData.rel_trans_postalcode_id 
                  }));
              }  
          }         
        } catch (error) {
            dispatch(warningErrorAction(t('Text_IncorrectData'))); 
        }
    };
    if (form.rel_trans_documentid!==""){
      fetchExistNumberDocumentRelTrans();
    } 
    return () => { isMounted = false };
}, [ENDPOINT,t,token,dispatch,form.rel_trans_store_id,form.rel_trans_documentid,errorsForm,DEFAULTCOUNTRY,DEFAULTPROVINCE,setForm]);

  useEffect(() => {
    let isMounted = true;
    const fetchExistNumberDocumentOriTras = async () => {
      try {
        const getData = await helpHttp().get2(ENDPOINT,`ticketsgetdata/getstorebynumberdocument/`,token,form.ori_tras_documentid);
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          if (getData>0 && getData!==form.ori_tras_documentid){
            fetchStoreNumberDocumentOriTras();
          }
        }
      } catch (error) {
          dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };
    const fetchStoreNumberDocumentOriTras = async () => {
      try {
          const getData = await helpHttp().get5(ENDPOINT,`ticketsgetdata/getstorebynumberdocumentpriority`,token,form.ori_tras_documentid,2);
          if (getData.err) {
              dispatch(warningErrorAction(t('Text_InvalidCredentials')));
          } else if (isMounted) {
              if (getData!==undefined && getData!==null && getData!==""){
                  setForm(form => ({
                      ...form,
                      ori_tras_store_id: getData.rel_trans_store_id,
                      ori_tras_documnettype_id: (getData.rel_trans_documnettype_id===undefined 
                                              || getData.rel_trans_documnettype_id===null  
                                              || getData.rel_trans_documnettype_id===""
                                              || getData.rel_trans_documnettype_id==="0"
                                              || getData.rel_trans_documnettype_id===0)?
                                              0:getData.rel_trans_documnettype_id,
                      ori_tras_documnettype_name: getData.rel_trans_documnettype_name,
                      ori_tras_documentid: getData.rel_trans_documentid,
                      ori_tras_tradename: getData.rel_trans_tradename,
                      ori_tras_name: getData.rel_trans_name,
                      ori_tras_contacperson: getData.rel_trans_contacperson
            }));
          }  
        }         
      } catch (error) {
          dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };
    if (form.ori_tras_documentid!==""){
      fetchExistNumberDocumentOriTras();
    } 
    return () => { isMounted = false };
  }, [ENDPOINT,t,token,dispatch,form.ori_tras_documentid,form.ori_tras_tradename,errorsForm,DEFAULTCOUNTRY,DEFAULTPROVINCE,setForm]);

  useEffect(() => {
    let isMounted = true;
    const fetchExistLicensePlate = async () => {
      try {
        const getData = await helpHttp().get2(ENDPOINT,`ticketsgetdata/getexistvehicleylicenseplate/`,token,form.vehiclestore_licenseplate);
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          if (getData>0 && getData!==form.vehiclestore_licenseplate){
            fetchLicensePlate();
          }
        }
      } catch (error) {
          dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };
    const fetchLicensePlate = async () => {
      try {
          const getData = await helpHttp().get2(ENDPOINT,`ticketsgetdata/getvehiclebylicenseplate/`,token,form.vehiclestore_licenseplate);
          if (getData.err) {
              dispatch(warningErrorAction(t('Text_InvalidCredentials')));
          } else if (isMounted) {
              if (getData!==undefined && getData!==null && getData!==""){
                setForm(form => ({
                  ...form,
                  vehiclestore_id: getData.id,
                  vehiclestore_tara: getData.tara,
                  vehiclestore_pma:  getData.pma,
                  vehiclestore_checkpma: getData.checkpma,
                  vehiclestore_defaultdriver: getData.defaultdriver,
                  vehiclestore_store_id: (getData.store_id===undefined 
                                        || getData.store_id===null  
                                        || getData.store_id===""
                                        || getData.store_id==="0"
                                        || getData.store_id===0)?
                                        0:getData.store_id,
                  totalendweight:getData.checkpma?0:getData.tara
              }));
              if (getData.checkpma){
                setEditRowArticles(editRowArticles => ({
                  ...editRowArticles,
                  secondweight:0,
                  formdi_id:0,
                  ownformdi_id:0
                }));
                setUnlockData(false); 
                setUnlockDataChangeState(false);
              }else{
                setEditRowArticles(editRowArticles => ({
                  ...editRowArticles,
                  secondweight:getData.tara,
                  formdi_id:0,
                  ownformdi_id:0
                }));
                setUnlockData(true);
                setUnlockDataChangeState(true);
              }
              if (form.rel_trans_documentid===undefined || form.rel_trans_documentid===null || form.rel_trans_documentid==="" ){
                if (form.rel_trans_tradename===undefined || form.rel_trans_tradename===null || form.rel_trans_tradename===""){
                  setForm(form => ({
                    ...form,
                    rel_trans_documentid: getData.store_documentid 
                  }));
                }
              } 
              setForm(form => ({
                ...form,
              }))
            }
          }         
      } catch (error) {
          dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    }; 
    if (form.vehiclestore_licenseplate!==""){
      fetchExistLicensePlate();
    } 
    return () => { isMounted = false };
  }, [ENDPOINT,t,token,dispatch,form.vehiclestore_licenseplate,form.rel_trans_documentid,form.rel_trans_tradename,errorsForm,DEFAULTCOUNTRY,DEFAULTPROVINCE,setForm,form.vehiclestore_checkpma,form.linestickets]);
 
  useEffect(() => {
    let isMounted = true;
    const fetchDataProvince = async () => {
      try {
        const getData = await helpHttp().get2(ENDPOINT,`ticketsgetdata/simpleprovincesbycountrybyname/`,token,DEFAULTCOUNTRY);
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          setProvinceToSelect(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };
    if (showModalTicketsSettings){
      fetchDataProvince();
    }

    return () => { isMounted = false };     
  }, [ENDPOINT,t,token,dispatch,i18n,showModalTicketsSettings,DEFAULTCOUNTRY]);

  useEffect(() => {
    let isMounted = true;
    const fetchDataPostalCode = async () => {
      try {
        const getData = form.ori_tras_province_id!==""
            ? await helpHttp().get2(ENDPOINT,`ticketsgetdata/simplepostalcodesbyprovincebyname/`,token,form.ori_tras_province_id)
            : await helpHttp().get2(ENDPOINT,`ticketsgetdata/simplepostalcodesbycountry/`,token,DEFAULTCOUNTRY);
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          setPostalCodeToSelect(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };
    if (showModalTicketsSettings){
      fetchDataPostalCode(); 
    }
    if (form.id === "") { 
      setForm(form => ({
        ...form,
        defaultStore_id:defaultStore.id,
        useraccount_id:useraccount_id,
        isfinished:false,
        rel_trans_country_id: Number(DEFAULTCOUNTRY),
        rel_trans_province_id:  Number(DEFAULTPROVINCE),
        rel_trans_postalcode_id: 0,
        ori_tras_country_id: Number(DEFAULTCOUNTRY),
        ori_tras_province_id: Number(DEFAULTPROVINCE),
        ori_tras_postalcode_id: 0,
        linestickets: [] ,
        linesticketsWithOutWeight:[]
      }));
    }
    return () => { isMounted = false };  
  }, [ENDPOINT,t,token,dispatch,i18n,showModalTicketsSettings,form.ori_tras_province_id,DEFAULTCOUNTRY,DEFAULTPROVINCE,form.id,defaultStore.id,useraccount_id]);
 
  return (
    <div>
      {showModalTicketsSettings && (
        <div className="container-fluid">
          <Box sx={{ bgcolor: '#ffffff',width: '1800px'}}>
            <form className="createtickets" onSubmit={handleCloseDataEditTickets}>  
              <div className="row"> 
                <div className="col-xl-12 mb-4"> 
                  <div  className="card border-info shadow">
                    <div className="card-body"> 
                      <div className="row no-gutters align-items-center">
                        <div className="col mr-2">
                          <div id="myDIV">
                            <h1 className="h1NewStyle mb-2">
                              <i className="fas fa-solid fa-weight text-primary"></i> {t('Text_TICKETS_TITLE')}
                            </h1>
                            <div>
                              <button type="button" className="buttonBack" onClick={handleCloseDataEditTickets}>
                                {t('Button_Exit')}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <br/>

              <div className="row">
                <div className="col-xl-12 mb-4"> 
                  <div className="card border-info shadow">
                    <div className="card-body">
                      <div className="row justify-content-center">
                        {/* SELECT */}
                        <div className="col-6 mb-2">
                          <div className="card border-info shadow">
                            <div className="card-header 2 bg-primary text-white text-center text-bold">
                              <h4><i className="fas fa-duotone fa-truck text-warning"></i> {t('Text_TICKETS_TITLE')}</h4>
                            </div>
                            <div className="card-body">
                              <div className="container">
                                <div className="row p-0"> 
                                  <div className="form-group">   
                                    <label className="text-primary">{t('Text_TICKETS_LICENSEPLATE')} :</label>
                                    &nbsp;&nbsp;&nbsp;&nbsp; 
                                    <TextField
                                      id="vehiclestore_licenseplate" 
                                      name="vehiclestore_licenseplate"
                                      value={form.vehiclestore_licenseplate}
                                      inputProps={{ style: {width: i18n.language==='es'?161:121}, maxLength: 100 }}
                                      style={{ color:"#4e73df", fontSize: 12, textAlign:'right'}} 
                                      label={errorsForm.vehiclestore_licenseplate!=null?t(errorsForm.vehiclestore_licenseplate):t('Text_TICKETS_LICENSEPLATE')}
                                      placeholder={t('Text_TICKETS_LICENSEPLATE')}
                                      variant="standard"
                                      onBlur={handleBlurTickets}
                                      onChange={handleChangeTickets}
                                    /> 
                                    &nbsp;&nbsp;
                                    <IconButton  size="small"  style={{color:"#e74a3b", fontSize: 12}} onClick={deleteDataVehicles}>
                                        <DeleteIcon/>
                                    </IconButton>
                                    &nbsp;&nbsp;
                                    {roles[0].listmenus.find((key)=>(key.name==='SubMenu_Settings_Tickets_Store_Master'))&&
                                        <IconButton  size="small"  style={{color:"#4e73df", fontSize: 12}} onClick={searchVehicles}>
                                            <SearchIcon/>
                                        </IconButton>
                                    }
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <label className="text-primary">{t('Text_TICKETS_CIF_TRANS')} :</label>
                                    &nbsp;&nbsp;&nbsp;&nbsp; 
                                    <TextField
                                      id="rel_trans_documentid" 
                                      name="rel_trans_documentid"
                                      value={form.rel_trans_documentid}
                                      inputProps={{ style: {width: i18n.language==='es'?161:122}, maxLength: 100 }}
                                      label={errorsForm.rel_trans_documentid!=null?t(errorsForm.rel_trans_documentid):t('Text_TICKETS_CIF_TRANS')}
                                      style={{ color:"#4e73df", fontSize: 12, textAlign:'right'}} 
                                      placeholder={t('Text_TICKETS_CIF_TRANS')}
                                      variant="standard"
                                      onBlur={handleBlurTickets}
                                      onChange={handleChangeTickets}
                                    /> 
                                    &nbsp;&nbsp;
                                    <IconButton  size="small"  style={{color:"#e74a3b", fontSize: 12}} onClick={deleteDataStoreRelTrans}>
                                        <DeleteIcon/>
                                    </IconButton>
                                    &nbsp;&nbsp;
                                    {roles[0].listmenus.find((key)=>(key.name==='SubMenu_Settings_Tickets_Store_Master'))&&
                                      <IconButton  size="small"  style={{color:"#4e73df", fontSize: 12}} onClick={searchStoreRelTrans}>
                                          <SearchIcon/>
                                      </IconButton>
                                    }
                                  </div>
                                </div>
                                <div className="row p-0"> 
                                  <div className="form-group">   
                                      <label className="text-primary">{t('Text_TICKETS_TRADENAME_TRANS')} :</label>
                                      &nbsp;&nbsp;&nbsp;&nbsp; 
                                      <TextField
                                        id="rel_trans_tradename" 
                                        name="rel_trans_tradename"
                                        value={form.rel_trans_tradename}
                                        inputProps={{ style: {width: i18n.language==='es'?641:653}, maxLength: 100 }} 
                                        label={errorsForm.rel_trans_tradename!=null?t(errorsForm.rel_trans_tradename):t('Text_TICKETS_TRADENAME_TRANS')}
                                        style={{ color:"#4e73df", fontSize: 12, textAlign:'right'}} 
                                        placeholder={t('Text_TICKETS_TRADENAME_TRANS')}
                                        variant="standard"
                                        onBlur={handleBlurTickets}
                                        onChange={handleChangeTickets}
                                      /> 
                                  </div>
                                </div>
                                <div className="row p-0"> 
                                  <Typography style={{color:"#067330", fontSize: 16}} >  
                                      {t('Text_TICKETS_TRADENAME_STORE')} 
                                  </Typography>
                                </div>
                                <hr className="sidebar-divider d-none d-md-block"/>
                                <div className="row p-0"> 
                                  <div className="form-group">    
                                    <label className="text-primary">{t('Text_TICKETS_CIF_STORE')} :</label>
                                      &nbsp;&nbsp;&nbsp;&nbsp; 
                                      <TextField
                                        id="ori_tras_documentid" 
                                        name="ori_tras_documentid"
                                        value={form.ori_tras_documentid}
                                        inputProps={{ style: {width: i18n.language==='es'?200:200}, maxLength: 100 }}
                                        style={{ color:"#4e73df", fontSize: 12, textAlign:'right'}} 
                                        label={errorsForm.ori_tras_documentid!=null?t(errorsForm.ori_tras_documentid):t('Text_TICKETS_CIF_STORE')}
                                        placeholder={t('Text_TICKETS_CIF_STORE')}
                                        variant="standard"
                                        onBlur={handleBlurTickets}
                                        onChange={handleChangeTickets}
                                      /> 
                                    &nbsp;&nbsp;
                                    <IconButton  size="small"  style={{color:"#e74a3b", fontSize: 12}} onClick={deleteDataStoreOriTras}>
                                        <DeleteIcon/>
                                    </IconButton>
                                    &nbsp;&nbsp;
                                    {roles[0].listmenus.find((key)=>(key.name==='SubMenu_Settings_Tickets_Store_Master'))&&
                                      <IconButton  size="small"  style={{color:"#4e73df", fontSize: 12}} onClick={searchStoreOriTras}>
                                          <SearchIcon/>
                                      </IconButton>
                                    } 
                                  </div>
                                </div> 
                                <div className="row p-0"> 
                                  <div className="form-group">   
                                    <label className="text-primary">{t('Text_TICKETS_NAME_STORE')} :</label>
                                      &nbsp;&nbsp;&nbsp;&nbsp; 
                                      <TextField
                                        id="ori_tras_tradename" 
                                        name="ori_tras_tradename"
                                        value={form.ori_tras_tradename}
                                        inputProps={{ style: {width: i18n.language==='es'?655:673}, maxLength: 100 }} 
                                        style={{ color:"#4e73df", fontSize: 12, textAlign:'right'}} 
                                        label={errorsForm.ori_tras_tradename!=null?t(errorsForm.ori_tras_tradename):t('Text_TICKETS_NAME_STORE')}
                                        placeholder={t('Text_TICKETS_NAME_STORE')}
                                        variant="standard"
                                        onBlur={handleBlurTickets}
                                        onChange={handleChangeTickets} 
                                      />  
                                  </div>                              
                                </div>  
                                <div className="row p-0"> 
                                  <div className="form-group">   
                                    <label className="text-primary">{t('Text_TICKETS_TRADENAME_STORE_ADDRESS')} :</label>
                                      &nbsp;&nbsp;&nbsp;&nbsp; 
                                      <TextField
                                        id="ori_tras_street" 
                                        name="ori_tras_street"
                                        value={form.ori_tras_street}
                                        inputProps={{ style: {width: i18n.language==='es'?652:598}, maxLength: 100 }} 
                                        style={{ color:"#4e73df", fontSize: 12, textAlign:'right'}} 
                                        label={errorsForm.ori_tras_street!=null?t(errorsForm.ori_tras_street):t('Placeholder_TICKETS_TRADENAME_STORE_ADDRESS')}
                                        placeholder={t('Placeholder_TICKETS_TRADENAME_STORE_ADDRESS')}
                                        variant="standard"
                                        onBlur={handleBlurTickets}
                                        onChange={handleChangeTickets} 
                                      />  
                                      &nbsp;&nbsp;
                                      <IconButton  size="small"  style={{color:"#e74a3b", fontSize: 12}} onClick={deleteDataStoreAddressOriTras}>
                                          <DeleteIcon/>
                                      </IconButton>
                                      &nbsp;&nbsp;
                                      {roles[0].listmenus.find((key)=>(key.name==='SubMenu_Settings_Tickets_Store_Master'))&&
                                        <IconButton  size="small"  style={{color:"#4e73df", fontSize: 12}} onClick={searchStoreAddress}>
                                          <SearchIcon/>
                                        </IconButton>
                                      } 
                                  </div>                              
                                </div>  
                                <div className="row p-0"> 
                                  <div className="form-group">   
                                    <label className="text-primary">{t('Text_TICKETS_TRADENAME_STORE_PROVINCE')} :</label>
                                    &nbsp;&nbsp;&nbsp;&nbsp; 
                                    <TextField
                                      id="ori_tras_province_id"
                                      name="ori_tras_province_id"
                                      value={ (form.ori_tras_province_id === undefined || form.ori_tras_province_id ===null || form.ori_tras_province_id ===""
                                          || form.ori_tras_province_id ==="0" || form.ori_tras_province_id ===0)?Number(DEFAULTPROVINCE):form.ori_tras_province_id}
                                      inputProps={{ style: {width: i18n.language==='es'?227:235}, maxLength: 100 }}
                                      variant="outlined"
                                      onBlur={handleBlurTickets}
                                      onChange={handleChangeTickets}
                                      helperText={errorsForm.ori_tras_province_id!=null && errorsForm.ori_tras_province_id!==""?t(errorsForm.ori_tras_province_id):""}
                                      select
                                      SelectProps={{
                                          native: true,
                                          value: (form.ori_tras_province_id === undefined || form.ori_tras_province_id ===null || form.ori_tras_province_id ===""
                                              || form.ori_tras_province_id ==="0" || form.ori_tras_province_id ===0)?Number(DEFAULTPROVINCE): form.ori_tras_province_id
                                      }}>
                                      <option key={0} value=""/>
                                      {provinceToSelect.map((option) => (
                                      <option key={option.id} value={option.id}>
                                          {option.name}
                                      </option>
                                      ))}
                                  </TextField>
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  <label className="text-primary">{t('Text_TICKETS_TRADENAME_STORE_POSTALCODE')} :</label>
                                  &nbsp;&nbsp;&nbsp;&nbsp; 
                                  <TextField
                                    id="ori_tras_postalcode_id"
                                    name="ori_tras_postalcode_id"
                                    value={ (form.ori_tras_postalcode_id === undefined || form.ori_tras_postalcode_id ===null || form.ori_tras_postalcode_id ===""
                                        || form.ori_tras_postalcode_id ==="0" || form.ori_tras_postalcode_id ===0)?"":form.ori_tras_postalcode_id}
                                    inputProps={{ style: {width: i18n.language==='es'?227:235}, maxLength: 100 }}
                                    variant="outlined"
                                    onBlur={handleBlurTickets}
                                    onChange={handleChangeTickets}
                                    helperText={errorsForm.ori_tras_postalcode_id!=null && errorsForm.ori_tras_postalcode_id!==""?t(errorsForm.ori_tras_postalcode_id):""}
                                    select
                                    SelectProps={{
                                    native: true,
                                    value: (form.ori_tras_postalcode_id === undefined || form.ori_tras_postalcode_id ===null || form.ori_tras_postalcode_id ===""
                                        || form.ori_tras_postalcode_id ==="0" || form.ori_tras_postalcode_id ===0)?"": form.ori_tras_postalcode_id
                                    }}>
                                    <option key={0} value=""/>
                                    {postalCodeToSelect.map((option) => (
                                    <option key={option.id} value={option.id}>
                                        {option.postalcodename}
                                    </option>
                                    ))}
                                  </TextField>
                                  </div>
                                </div>
                                <div className="row p-0"> 
                                  <div className="form-group">   
                                    <label className="text-primary">{t('Text_TICKETS_TRADENAME_STORE_OTHERS')} :</label>
                                      &nbsp;&nbsp;&nbsp;&nbsp; 
                                      <TextField
                                        id="observations" 
                                        name="observations"
                                        value={form.observations}
                                        inputProps={{ style: {width: i18n.language==='es'?637:650}, maxLength: 100 }} 
                                        style={{ color:"#4e73df", fontSize: 12, textAlign:'right'}} 
                                        label={errorsForm.observations!=null?t(errorsForm.observations):t('Placeholder_TICKETS_TRADENAME_STORE_OTHERS')}
                                        placeholder={t('Placeholder_TICKETS_TRADENAME_STORE_OTHERS')}
                                        variant="standard"
                                        onBlur={handleBlurTickets}
                                        onChange={handleChangeTickets}
                                      />  
                                  </div>                              
                                </div>  
                                <TicketsSelectArticle
                                   editRowArticles={editRowArticles}
                                   setEditRowArticles={setEditRowArticles}
                                   form={form}
                                   setForm={setForm}
                                   setErrorsForm={setErrorsForm}
                                   validateTickets={validateTickets}
                                   unlockData={unlockData}
                                   unlockDataChangeState={unlockDataChangeState}
                                   setUnlockDataChangeState={setUnlockDataChangeState}
                                   setShowModalSearchArticles={setShowModalSearchArticles}
                                   configPrinter={configPrinter}
                                   setShowModalSearchDI={setShowModalSearchDI}
                                />
                                {(form.linesticketsWithOutWeight!==undefined && form.linesticketsWithOutWeight.length>0) &&
                                  <TicketsSelectArticleWithOutWeight
                                    dataListTicketsDetails={form.linesticketsWithOutWeight}
                                    form={form}
                                    setForm={setForm}
                                  />
                                }
                                <hr className="sidebar-divider d-none d-md-block"/>                                
                                <div className="btn-group d-grid d-md-flex justify-content-md-end" role="group">
                                  <button className="btn btn-primary btn-lg " type="button"  onClick={handleSubmitDataEditTickets}>{t('Button_Add')}</button><> </>
                                </div>
                                <hr className="sidebar-divider d-none d-md-block"/>
                                <Box sx={{ flexGrow: 1 }}>
                                  <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                                    {articlesButtonsList.map((post, index) => (
                                      <Grid item xs={2} sm={4} md={4} key={index}>
                                        <Item>
                                          <Button variant="contained" style={{color:"#ffffff", background:post.button_color,fontSize: 16}} onClick={()=>selectArticle(post)}>
                                            {i18n.language==='es'?post.button_namees:post.button_nameen}
                                          </Button>
                                        </Item>
                                      </Grid>
                                    ))}
                                  </Grid>
                                </Box>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* END SELECT */}
                        {/* SCALE */}
                        <div className="col-6 mb-2">
                          <div className="card border-info shadow">
                            <div className="card-header 2 bg-primary text-white text-center text-bold">
                              <h4>{t('Text_TICKETS_SCALES')}</h4>
                            </div>
                            <div className="card-body">
                              <div className="row align-items-center">
                                <div className="col-4 mb-2">
                                </div>
                                <div className="col-6 mb-2">
                                  <NumericFormat 
                                    thousandSeparator={','} 
                                    decimalSeparator={'.'} 
                                    decimalScale= {0}
                                    fixedDecimalScale= {true}
                                    allowNegative= {false}
                                    isAllowed={withValueCap}
                                    id="firstweight" 
                                    name="firstweight"
                                    value={editRowArticles.firstweight}
                                    style={{width:300, color:"red", background:"black",fontSize: 38, textAlign:'right', paddingRight:10}} 
                                    disabled
                                  /> 
                                  </div>
                                  <div className="col-2 mb-2">
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <IconButton 
                                      style={{color:"#ffffff", background:"#4e73df"}} 
                                      aria-label="weight vehicles" onClick={openExternalWeigh}>
                                      <ScaleIcon />
                                    </IconButton>
                                </div>
                              </div>
                            </div>
                          </div>
                          <br/>
                          <InvoiceTickets 
                            showModalTicketsSettings={showModalTicketsSettings}
                            submitTickets={submitTickets}
                            setSubmitTickets={setSubmitTickets}
                            configPrinter={configPrinter}
                            refeshData={refeshData}
                            setRefeshData={setRefeshData}
                          />
                        </div>
                        {/* END SCALE */}

                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* End Content Row */}
            </form>
          </Box>
          <br/> 
          <TicketsSearchStores
            showModalSearchStores={showModalSearchStores}
            setShowModalSearchStores={setShowModalSearchStores}
            selectStores={tetxtSearch}
            setForm={setForm}
          />
          <TicketsSearchStoresAddresses
            showModalSearchStoresAddress={showModalSearchStoresAddress}
            setShowModalSearchStoresAddress={setShowModalSearchStoresAddress}
            setForm={setForm}
            titleTable={form.ori_tras_tradename}
            store_id={form.ori_tras_store_id}
            priority={2}
          />
          <TicketsSearchVehicles
            showModalSearchVehicles={showModalSearchVehicles}
            setShowModalSearchVehicles={setShowModalSearchVehicles}
            setForm={setForm}
            form={form}
          /> 
          <TicketsSearchArticles
            showModalSearchArticles={showModalSearchArticles}
            setShowModalSearchArticles={setShowModalSearchArticles}
            setEditRowArticles={setEditRowArticles}
            form={form}
            setForm={setForm}
            LIMITSARTICLESPERWEIGHT={LIMITSARTICLESPERWEIGHT}
          /> 
          <TicketsSearchDI
            showModalSearchDI={showModalSearchDI}
            setShowModalSearchDI={setShowModalSearchDI}
            editRowArticles={editRowArticles}
            setEditRowArticles={setEditRowArticles}
            form={form} 
          /> 
          <TicketsSearchArticles
            showModalSearchArticles={showModalSearchArticles}
            setShowModalSearchArticles={setShowModalSearchArticles}
            setEditRowArticles={setEditRowArticles}
            form={form}
            setForm={setForm}
            LIMITSARTICLESPERWEIGHT={LIMITSARTICLESPERWEIGHT}
          /> 
        </div> 
      )}
    </div>
  );
};

TicketsEditOrCreate.propTypes = { 
  showModalTicketsSettings: PropTypes.bool.isRequired 
};

export default TicketsEditOrCreate;